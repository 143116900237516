<template>
  <div class="modal-card" style="width: 200; height: 700px;">
    <header class="modal-card-head">
      <p class="modal-card-title">{{ action }} Child</p>
    </header>
    <section class="modal-card-body">
      <b-field label="Name">
        <b-input
          v-model="name"
          required>
        </b-input>
      </b-field>
      <b-field label="Birth Date">
        <b-datepicker
          placeholder="Click to select..."
          icon="calendar-today"
          v-model="birth_date_parsed"
          :date-formatter="(date) => date.toLocaleDateString()">
        </b-datepicker>
      </b-field>
    </section>
    <footer class="modal-card-foot">
      <button class="button is-success" @click.prevent="confirm()">{{ action }}</button>
      <button class="button" type="button" @click.prevent="$parent.close()">Cancel</button>
    </footer>
  </div>
</template>

<script>
export default {
  props: ["action", "name", "birth_date", "id"],
  data() {
    return {
      birth_date_parsed: new Date(this.birth_date),
    }
  },
  mounted() {
  },
  methods: {
    confirm() {
      if (this.action == "Edit") {
        let child = {
          id: this.id,
          name: this.name,
          birth_date: this.birth_date
        }
        this.$emit('edit', child)
        this.$parent.close()
      }
    }
  }
}
</script>

