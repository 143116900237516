<template>
  <div>
    <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
    <div class="columns header" style="margin-bottom: 0 !important;">
      <div class="column is-8" style="margin-left: 5px; font-size: 20px;">
        <b>Add User</b>
      </div>
      <div class="column is-auto">
        <router-link to="/users" class="button is-primary is-pulled-right" style="margin-left: 10px;" @click.prevent="isSearch = true">
          <i class="fa fa-user"></i> &nbsp;Users
        </router-link>
      </div>
    </div>
    <hr style="margin-top: 0 !important; margin-bottom: 0 !important;" class="has-background-grey-lighter">
    <br>
    <transition name="fade">
      <User :user="selected" v-if="!isSearch" :isAdd="isAdd" />
      <Search :data="data" :searchLoad="searchLoad" @view="getUser" v-if="isSearch" />
    </transition>
  </div>
</template>

<script>
import Search from '../components/users/Search.vue'
import User from '../components/users/User.vue'

export default {
  components: {
    Search,
    User
  },
  data() {
    return {
      isSearch: false,
      data: {},
      searchLoad: false,
      selected: {},
      isAdd: true,
      isLoading: true,
    }
  },
  mounted() {
    this.$editmode.commit('editing', true)
    this.isLoading = false
  },
  methods: {
    addUser() {

    },
    getUser(obj) {
      this.selected = obj
      this.isSearch = false
    },
    postUser(obj) {
      let config = {
        headers: {'Authorization': "Bearer " + this.$store.state.token}
      };
      this.$http.post(`${this.$host}/api/user/save/${this.selected.id}`,
        obj,
        config,
      ).then((response) => {
        let data = response.data.data
        this.$refs.user.successSave()

        //console.log(data)
      }).catch((error) => {
        this.$refs.user.errorSave()

        //console.log(error)
      });
    }
  }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
