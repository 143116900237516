import Vue from 'vue'
import Router from 'vue-router'
import NotFound from './views/NotFound.vue'
import Home from './views/Home.vue'
import Employee from './views/Employee.vue'
import EmployeeAdd from './views/EmployeeAdd.vue'
import Users from './views/Users.vue'
import UserAdd from './views/UsersAdd.vue'
import Position from './views/Position.vue'
import PositionAdd from './views/PositionAdd.vue'
import Settings from './views/Settings.vue'
import ServiceRecord from './views/ServiceRecord.vue'
import Reports from './views/Reports.vue'
import Schedule from './views/Schedule.vue'
import Templates from './views/Templates.vue'
import Holidays from './views/Holidays.vue'
import Leaves from './views/Leaves.vue'
import Payroll from './views/Payroll.vue'
import PayrollManagement from './views/PayrollManagement.vue'
import FinalizePayroll from './views/FinalizePayroll.vue'
import Trainings from './views/Trainings.vue'
import TrainingsManagement from './views/TrainingsManagement.vue'
import EmployeeTrainings from './views/EmployeeTrainings.vue'
import RequestManagement from './views/RequestManagement.vue'
import EmployeeAppraisals from './views/EmployeeAppraisals.vue'
import Relations from './views/Relations.vue'
import EmployeeUpdate from './views/EmployeeUpdate.vue'
import Logs from './views/Logs.vue'
import { role, editmode, store } from './store.js'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'

Vue.use(Buefy)
Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/404',
      name: 'notfound',
      component: NotFound
    },
    {
      path: '*',
      redirect: '/404'
    },
    {
      path: '/home',
      name: 'home',
      component: Home,
      beforeEnter(to, from, next) {
        if (editmode.state.data) {
          Vue.prototype.$dialog.confirm({
            title: 'Discard Changes',
            message: 'Are you sure you want to <b>discard</b> your changes? This action cannot be undone.',
            confirmText: 'Discard',
            type: 'is-danger',
            hasIcon: true,
            onConfirm: () => { editmode.state.data = false, next() }
          })
        }
        else if (!editmode.state.data) {
          next()
        }
      }
    },
    {
      path: '/',
      beforeEnter(to, from, next) {
        if (store.state.isLoggedIn && store.state.token != '' && store.state.token) {
          next('/home')
        }
      }
    },
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/About.vue'),
      beforeEnter(to, from, next) {
        if (editmode.state.data) {
          Vue.prototype.$dialog.confirm({
            title: 'Discard Changes',
            message: 'Are you sure you want to <b>discard</b> your changes? This action cannot be undone.',
            confirmText: 'Discard',
            type: 'is-danger',
            hasIcon: true,
            onConfirm: () => { editmode.state.data = false, next() }
          })
        }
        else if (!editmode.state.data) {
          next()
        }
      }
    },
    {
      path: '/employees',
      name: 'employee',
      component: Employee,
      beforeEnter(to, from, next) {
        if (role.state.permissions['search employee'] == false) {
          next('/404')
        }
        else if (editmode.state.data) {
          Vue.prototype.$dialog.confirm({
            title: 'Discard Changes',
            message: 'Are you sure you want to <b>discard</b> your changes? This action cannot be undone.',
            confirmText: 'Discard',
            type: 'is-danger',
            hasIcon: true,
            onConfirm: () => { editmode.state.data = false, next() }
          })
        }
        else if (!editmode.state.data) {
          next()
        }
      }
    },
    {
      path: '/employees/add',
      name: 'employee-add',
      component: EmployeeAdd,
      // beforeEnter(to, from, next) {
      //   if (editmode.state.data) {
      //     Vue.prototype.$dialog.confirm({
      //       title: 'Discard Changes',
      //       message: 'Are you sure you want to <b>discard</b> your changes? This action cannot be undone.',
      //       confirmText: 'Discard',
      //       type: 'is-danger',
      //       hasIcon: true,
      //       onConfirm: () => { editmode.state.data = false, next() }
      //     })
      //   }
      //   else if (!editmode.state.data) {
      //     next()
      //   }
      // }
    },
    {
      path: '/users',
      name: 'users',
      component: Users,
      beforeEnter(to, from, next) {
        if (role.state.permissions['search user'] == false) {
          next('/404')
        }
        else if (editmode.state.data) {
          Vue.prototype.$dialog.confirm({
            title: 'Discard Changes',
            message: 'Are you sure you want to <b>discard</b> your changes? This action cannot be undone.',
            confirmText: 'Discard',
            type: 'is-danger',
            hasIcon: true,
            onConfirm: function() {
              next()
            }
          })
        }
        else {
          next()
        }
      }
    },
    {
      path: '/position',
      name: 'position',
      component: Position,
      beforeEnter(to, from, next) {
        if (role.state.permissions['search position'] == false) {
          next('/404')
        }
        else if (editmode.state.data) {
          Vue.prototype.$dialog.confirm({
            title: 'Discard Changes',
            message: 'Are you sure you want to <b>discard</b> your changes? This action cannot be undone.',
            confirmText: 'Discard',
            type: 'is-danger',
            hasIcon: true,
            onConfirm: () => { editmode.state.data = false, next() }
          })
        }
        else if (!editmode.state.data) {
          next()
        }
      }
    },
    {
      path: '/position/add',
      name: 'position-add',
      component: PositionAdd,
      beforeEnter(to, from, next) {
        if (role.state.permissions['write position'] == false) {
          next('/404')
        }
        else if (editmode.state.data) {
          Vue.prototype.$dialog.confirm({
            title: 'Discard Changes',
            message: 'Are you sure you want to <b>discard</b> your changes? This action cannot be undone.',
            confirmText: 'Discard',
            type: 'is-danger',
            hasIcon: true,
            onConfirm: () => { editmode.state.data = false, next() }
          })
        }
        else if (!editmode.state.data) {
          next()
        }
      }
    },
    {
      path: '/settings',
      name: 'settings',
      component: Settings,
      beforeEnter(to, from, next) {
        if (editmode.state.data) {
          Vue.prototype.$dialog.confirm({
            title: 'Discard Changes',
            message: 'Are you sure you want to <b>discard</b> your changes? This action cannot be undone.',
            confirmText: 'Discard',
            type: 'is-danger',
            hasIcon: true,
            onConfirm: () => { editmode.state.data = false, next() }
          })
        }
        else if (!editmode.state.data) {
          next()
        }
      }
    },
    {
      path: '/users/add',
      name: 'user-add',
      component: UserAdd,
      beforeEnter(to, from, next) {
        if (role.state.permissions['write user'] == false) {
          next('/404')
        }
        else if (editmode.state.data) {
          Vue.prototype.$dialog.confirm({
            title: 'Discard Changes',
            message: 'Are you sure you want to <b>discard</b> your changes? This action cannot be undone.',
            confirmText: 'Discard',
            type: 'is-danger',
            hasIcon: true,
            onConfirm: function() {
              next()
            }
          })
        }
        else {
          next()
        }
      }
    },
    {
      path: '/service-record',
      name: 'service-record',
      component: ServiceRecord,
      beforeEnter(to, from, next) {
        if (role.state.permissions['search service record'] == false) {
          next('/404')
        }
        else if (editmode.state.data) {
          Vue.prototype.$dialog.confirm({
            title: 'Discard Changes',
            message: 'Are you sure you want to <b>discard</b> your changes? This action cannot be undone.',
            confirmText: 'Discard',
            type: 'is-danger',
            hasIcon: true,
            onConfirm: () => { editmode.state.data = false, next() }
          })
        }
        else if (!editmode.state.data) {
          next()
        }
      }
    },
    {
      path: '/reports',
      name: 'reports',
      component: Reports,
      beforeEnter(to, from, next) {
        if (editmode.state.data) {
          Vue.prototype.$dialog.confirm({
            title: 'Discard Changes',
            message: 'Are you sure you want to <b>discard</b> your changes? This action cannot be undone.',
            confirmText: 'Discard',
            type: 'is-danger',
            hasIcon: true,
            onConfirm: () => { editmode.state.data = false, next() }
          })
        }
        else if (!editmode.state.data) {
          next()
        }
      }
    },
    {
      path: '/schedule',
      // path: '/schedule/:id?',
      name: 'schedule',
      component: Schedule,
      beforeEnter(to, from, next) {
        if (role.state.permissions['read daily time record'] == false && role.state.permissions['search daily time record'] == false && role.state.permissions['write daily time record'] == false) {
          next('/404')
        }
        else if (editmode.state.data) {
          Vue.prototype.$dialog.confirm({
            title: 'Discard Changes',
            message: 'Are you sure you want to <b>discard</b> your changes? This action cannot be undone.',
            confirmText: 'Discard',
            type: 'is-danger',
            hasIcon: true,
            onConfirm: () => { editmode.state.data = false, next() }
          })
        }
        else if (!editmode.state.data) {
          next()
        }
      }
    },
    {
      path: '/schedule/templates',
      name: 'templates',
      component: Templates,
      beforeEnter(to, from, next) {
        if (role.state.permissions['read work schedule template'] == false) {
          next('/404')
        }
        else if (editmode.state.data) {
          Vue.prototype.$dialog.confirm({
            title: 'Discard Changes',
            message: 'Are you sure you want to <b>discard</b> your changes? This action cannot be undone.',
            confirmText: 'Discard',
            type: 'is-danger',
            hasIcon: true,
            onConfirm: () => { editmode.state.data = false, next() }
          })
        }
        else if (!editmode.state.data) {
          next()
        }
      }
    },
    {
      path: '/schedule/holidays',
      name: 'holidays',
      component: Holidays,
      beforeEnter(to, from, next) {
        if (role.state.permissions['read special dates'] == false) {
          next('/404')
        }
        else if (editmode.state.data) {
          Vue.prototype.$dialog.confirm({
            title: 'Discard Changes',
            message: 'Are you sure you want to <b>discard</b> your changes? This action cannot be undone.',
            confirmText: 'Discard',
            type: 'is-danger',
            hasIcon: true,
            onConfirm: () => { editmode.state.data = false, next() }
          })
        }
        else if (!editmode.state.data) {
          next()
        }
      }
    },
    {
      path: '/requests',
      name: 'leaves',
      component: Leaves,
      beforeEnter(to, from, next) {
        if (role.state.permissions['apply request'] == false) {
          next('/404')
        }
        else if (editmode.state.data) {
          Vue.prototype.$dialog.confirm({
            title: 'Discard Changes',
            message: 'Are you sure you want to <b>discard</b> your changes? This action cannot be undone.',
            confirmText: 'Discard',
            type: 'is-danger',
            hasIcon: true,
            onConfirm: () => { editmode.state.data = false, next() }
          })
        }
        else if (!editmode.state.data) {
          next()
        }
      }
    },
    {
      path: '/requests/manage',
      name: 'request-management',
      component: RequestManagement,
      beforeEnter(to, from, next) {
        if (role.state.permissions['search leave ledger'] == false) {
          next('/404')
        }
        else if (editmode.state.data) {
          Vue.prototype.$dialog.confirm({
            title: 'Discard Changes',
            message: 'Are you sure you want to <b>discard</b> your changes? This action cannot be undone.',
            confirmText: 'Discard',
            type: 'is-danger',
            hasIcon: true,
            onConfirm: () => { editmode.state.data = false, next() }
          })
        }
        else if (!editmode.state.data) {
          next()
        }
      }
    },
    {
      path: '/payroll',
      name: 'payroll',
      component: Payroll,
      beforeEnter(to, from, next) {
        if (role.state.permissions['read payroll'] || role.state.permissions['read payroll funding source']) {
            if (editmode.state.data) {
                Vue.prototype.$dialog.confirm({
                    title: 'Discard Changes',
                    message: 'Are you sure you want to <b>discard</b> your changes? This action cannot be undone.',
                    confirmText: 'Discard',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => { editmode.state.data = false, next() }
                })
            }
            else if (!editmode.state.data) {
                next()
            }
        }
        else {
            next()
        }
      }
    },
    {
      path: '/payroll/manage',
      name: 'payroll-management',
      component: PayrollManagement,
      beforeEnter(to, from, next) {
        if (role.state.permissions['write payroll'] || role.state.permissions['write payroll funding source']) {
            if (editmode.state.data) {
                Vue.prototype.$dialog.confirm({
                  title: 'Discard Changes',
                  message: 'Are you sure you want to <b>discard</b> your changes? This action cannot be undone.',
                  confirmText: 'Discard',
                  type: 'is-danger',
                  hasIcon: true,
                  onConfirm: () => { editmode.state.data = false, next() }
                })
              }
              else if (!editmode.state.data) {
                next()
              }
        }
        else {
            next('')
        }
      }
    },
    {
      path: '/payroll/finalize',
      name: 'finalize-payroll',
      component: FinalizePayroll,
      beforeEnter(to, from, next) {
        if (role.state.permissions['write payroll'] || role.state.permissions['write payroll funding source']) {
            if (editmode.state.data) {
            Vue.prototype.$dialog.confirm({
                title: 'Discard Changes',
                message: 'Are you sure you want to <b>discard</b> your changes? This action cannot be undone.',
                confirmText: 'Discard',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => { editmode.state.data = false, next() }
            })
            }
            else if (!editmode.state.data) {
            next()
            }
        }
        else
            next()
      }
    },
    {
      path: '/trainings',
      name: 'trainings',
      component: Trainings,
      beforeEnter(to, from, next) {
        if (role.state.permissions['read training'] == false) {
          next('/404')
        }
        else if (editmode.state.data) {
          Vue.prototype.$dialog.confirm({
            title: 'Discard Changes',
            message: 'Are you sure you want to <b>discard</b> your changes? This action cannot be undone.',
            confirmText: 'Discard',
            type: 'is-danger',
            hasIcon: true,
            onConfirm: () => { editmode.state.data = false, next() }
          })
        }
        else if (!editmode.state.data) {
          next()
        }
      }
    },
    {
      path: '/trainings/manage',
      name: 'trainings-management',
      component: TrainingsManagement,
      beforeEnter(to, from, next) {
        if (role.state.permissions['search training'] == false) {
          next('/404')
        }
        else if (editmode.state.data) {
          Vue.prototype.$dialog.confirm({
            title: 'Discard Changes',
            message: 'Are you sure you want to <b>discard</b> your changes? This action cannot be undone.',
            confirmText: 'Discard',
            type: 'is-danger',
            hasIcon: true,
            onConfirm: () => { editmode.state.data = false, next() }
          })
        }
        else if (!editmode.state.data) {
          next()
        }
      }
    },
    {
      path: '/trainings/employee',
      name: 'employee-trainings',
      component: EmployeeTrainings,
      beforeEnter(to, from, next) {
        if (role.state.permissions['search training'] == false) {
          next('/404')
        }
        else if (editmode.state.data) {
          Vue.prototype.$dialog.confirm({
            title: 'Discard Changes',
            message: 'Are you sure you want to <b>discard</b> your changes? This action cannot be undone.',
            confirmText: 'Discard',
            type: 'is-danger',
            hasIcon: true,
            onConfirm: () => { editmode.state.data = false, next() }
          })
        }
        else if (!editmode.state.data) {
          next()
        }
      }
    },
    {
      path: '/appraisals',
      name: 'employee-appraisals',
      component: EmployeeAppraisals,
      beforeEnter(to, from, next) {
        if (role.state.permissions['search appraisal'] == false) {
          next('/404')
        }
        else if (editmode.state.data) {
          Vue.prototype.$dialog.confirm({
            title: 'Discard Changes',
            message: 'Are you sure you want to <b>discard</b> your changes? This action cannot be undone.',
            confirmText: 'Discard',
            type: 'is-danger',
            hasIcon: true,
            onConfirm: () => { editmode.state.data = false, next() }
          })
        }
        else if (!editmode.state.data) {
          next()
        }
      }
    },
    {
      path: '/relations',
      name: 'relations',
      component: Relations,
      beforeEnter(to, from, next) {
        if (role.state.permissions['search award'] == false || role.state.permissions['search offense'] == false) {
          next('/404')
        }
        else if (editmode.state.data) {
          Vue.prototype.$dialog.confirm({
            title: 'Discard Changes',
            message: 'Are you sure you want to <b>discard</b> your changes? This action cannot be undone.',
            confirmText: 'Discard',
            type: 'is-danger',
            hasIcon: true,
            onConfirm: () => { editmode.state.data = false, next() }
          })
        }
        else if (!editmode.state.data) {
          next()
        }
      }
    },
    {
      path: '/employees/update',
      name: 'employee-update',
      component: EmployeeUpdate,
      beforeEnter(to, from, next) {
        if (editmode.state.data) {
          Vue.prototype.$dialog.confirm({
            title: 'Discard Changes',
            message: 'Are you sure you want to <b>discard</b> your changes? This action cannot be undone.',
            confirmText: 'Discard',
            type: 'is-danger',
            hasIcon: true,
            onConfirm: () => { editmode.state.data = false, next() }
          })
        }
        else if (!editmode.state.data) {
          next()
        }
      }
    },
    {
      path: '/logs',
      name: 'logs',
      component: Logs,
      beforeEnter(to, from, next) {
        if (editmode.state.data) {
          Vue.prototype.$dialog.confirm({
            title: 'Discard Changes',
            message: 'Are you sure you want to <b>discard</b> your changes? This action cannot be undone.',
            confirmText: 'Discard',
            type: 'is-danger',
            hasIcon: true,
            onConfirm: () => { editmode.state.data = false, next() }
          })
        }
        else if (!editmode.state.data) {
          next()
        }
      }
    },
  ]
})
