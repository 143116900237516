<template>
  <div id="app">
    <transition name="fade">
      <ChangePassword v-if="this.$editmode.state.shouldChange && this.$store.state.isLoggedIn && this.$preload.state.progress >= 100" @close="close"/> 
    </transition>
    <Login v-if="!this.$store.state.isLoggedIn" @authenticate="isLoggedIn()" @changepass="changepass"/>
    <Menu v-if="this.$store.state.isLoggedIn && this.$preload.state.progress >= 100"/>
    <Header v-if="this.$store.state.isLoggedIn && this.$preload.state.progress >= 100" @logout="logout()"/>
    <transition name="slide-fade">
      <LoadThings v-if="this.$store.state.isLoggedIn && this.$preload.state.progress < 100" />
    </transition>
    <div id="main" v-if="this.$store.state.isLoggedIn && this.$preload.state.progress >= 100">
      <router-view />
    </div>
  </div>
</template>

<script>
import Header from './components/layout/Header.vue'
import Menu from './components/layout/Menu.vue'
import Login from './components/Login.vue'
import ChangePassword from './components/ChangePassword.vue'
import LoadThings from './components/LoadThings.vue'
import moment from 'moment'

import Vue from 'vue'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'

Vue.use(Buefy)

export default {
  components: {
    Header,
    Menu,
    Login,
    ChangePassword,
    LoadThings
  },
  data() {
    return {
      timeout: '',
    }
  },
  mounted() {
    if (this.$store.state.isLoggedIn) {
      this.$role.commit('getRole')
    }
    // console.log(this.$store.state.token)
    // window.addEventListener("storage", e => {
    //   // exit if no change
    //   if (e.newValue === JSON.stringify(this.$store.state)) {
    //     return
    //   } 
    //   else {
    //     const token = JSON.parse(e.storageArea.vuex).token
    //     if (token == '' || token == null) {
    //       return this.$store.commit('clearToken')
    //     }
    //     this.$store.commit('setToken', token);
    //   }
    // });

    // this.active()
    // document.addEventListener('click', this.active())
  },
  methods: {
    // authenticated
    isLoggedIn() {
      this.$router.push('home')
    },
    changepass(bool) {
      this.$editmode.commit('setShouldChange', true)
    },
    close() {
      this.$editmode.commit('setShouldChange', false)
    }
    // // logout on idle / timeout
    // active() {
    //   clearTimeout(this.timeout)
    //   if (this.$store.state.isLoggedIn == true) {
    //     this.timeout = setTimeout(() => {
    //       this.$store.commit('clearToken')

    //       this.$snackbar.open({
    //         message: 'Logged out due to inactivity.',
    //         indefinite: true,
    //       })
    //     }, 15 * 60000)
    //   }
    // }
  }
}
</script>

<style>
#app, html {
  background-color: #f5f5f5;
  
}
#main {
  background-color: #f5f5f5;
  min-height: 100vh;
}

/* input {
  text-transform: uppercase;
} */

.uppercase {
  text-transform: uppercase;
}

table {
  text-transform: uppercase;
}

.no-transform {
  text-transform: none !important;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.button {
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12) !important;
}
.shadow {
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12) !important;
}

.modal-card-head {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.modal-card-foot {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.input, .select select {
  border-radius: 0px !important;
}

.dialog {
  z-index: 100000000 !important;
}

@media screen and (min-width: 1024px) and (max-width: 1215px)  {
  .modal-card {
    width: 100rem;
    height: 30rem;
  }
}

.modal-card-foot {
  background-color: white !important;
}
</style>

<style lang="scss">
  // Import Bulma's core
@import "~bulma/sass/utilities/_all";

// navbar height
$navbar-height: 4rem !default;

// navbar menu icon color
$navbar-burger-color: white;

// when to set menu to burger
// $navbar-breakpoint: $tablet;

// Set your colors
$primary: #0D47A1;
$info: #0D47A1;
$primary-invert: findColorInvert($primary);
$twitter: #4099FF;
$twitter-invert: findColorInvert($twitter);

// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: (
    "white": ($white, $black),
    "black": ($black, $white),
    "light": ($light, $light-invert),
    "dark": ($dark, $dark-invert),
    "primary": ($primary, $primary-invert),
    "info": ($info, $info-invert),
    "success": (#009688, $success-invert),
    "warning": ($warning, $warning-invert),
    "danger": (#d32f2f, $danger-invert),
    "twitter": ($twitter, $twitter-invert)
);

// Links
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;

// Import Bulma and Buefy styles
@import "~bulma";
@import "~buefy/src/scss/buefy";
</style>
