<template>
  <div>
    <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
    <div class="box">
      <div>
        <b-field>
          <!-- <b-field label="Flexi">
          </b-field> -->
          <button class="button is-primary" @click.prevent="isVerify = true">
            <i class="fa fa-pen"></i> &nbsp;Edit Verification Range
          </button>
        </b-field>
      </div>
      <hr>
      <b>VERIFY DAY FROM:</b> {{ verify_from }}<br>
      <b>VERIFY DAY TO:</b> {{ verify_to }}
    </div>
    <b-modal :active.sync="isVerify" has-modal-card :canCancel=false>
      <transition name="fade">
        <form>
          <div class="modal-card" style="margin-top: 4rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">Verification Range</p>
            </header>
            <section class="modal-card-body">
              <b-field label="From (Day)">
                <b-input type="number" maxlength="2" min="1" :max="verify_to" v-model="verify_from" expanded></b-input>
              </b-field>
              <b-field label="To (Day)">
                <b-input type="number" maxlength="2" min="1" max="31"  v-model="verify_to" expanded></b-input>
              </b-field>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" @click.prevent="saveVerifyFrom">Save</button>
              <button class="button is-danger" @click.prevent="cancelVerify">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: true,
      config: {},
      verify_from: '',
      verify_to: '',
      lunch_hour: '',
      isVerify: false
    }
  },
  mounted() {
    this.config = {
      headers: {'Authorization': "Bearer " + this.$store.state.token}
    };
    this.getSettings()
  },
  methods: {
    getSettings() {
      this.$http.get(`${this.$host}/api/setting/show`,
        this.config
      ).then((response) => {
        let data = response.data
        this.verify_from = data[8].hash_value
        this.verify_to = data[9].hash_value
        this.isLoading = false
      }).catch((error) => {
        this.isLoading = false
        //console.log(error)
      });
    },
    saveVerifyFrom() {
      this.isLoading = true
      this.$http.put(`${this.$host}/api/setting/update/9`,
        { hash_value: this.verify_from },
        this.config
      ).then((response) => {
        this.saveVerifyTo()
        // //console.log(data)
      }).catch((error) => {
        //console.log(error)
        this.isLoading = false
      });
      return false;
    },
    saveVerifyTo() {
      this.$http.put(`${this.$host}/api/setting/update/10`,
        { hash_value: this.verify_to },
        this.config
      ).then((response) => {
        // //console.log(data)
        this.isVerify = false
        this.getSettings()
      }).catch((error) => {
        //console.log(error)
        this.isLoading = false
      });
    },
    cancelVerify() {
      this.isLoading = true
      this.getSettings()
      this.isVerify = false
    }
  }
}
</script>

<style>

</style>
