<template>
  <div class="box">
    <div class="columns">
      <div class="column is-6">
        <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
        <b-field label="Year">
          <b-input v-model="year" placeholder="Year" type="number"/>
        </b-field>
        <b-field label="Prepared By">
          <b-autocomplete
            v-model="name_1"
            :data="filteredName1"
            field="full_name"
            @select="option => {position_1 = option.position}"
            :open-on-focus=false expanded>
          </b-autocomplete>
        </b-field>
        <b-field label="Position">
          <b-input
            v-model="position_1"
            expanded>
          </b-input>
        </b-field>
        <b-field label="Noted By">
          <b-autocomplete
            v-model="name_2"
            :data="filteredName2"
            field="full_name"
            @select="option => {position_2 = option.position}"
            :open-on-focus=false expanded>
          </b-autocomplete>
        </b-field>
        <b-field label="Position">
          <b-input
            v-model="position_2"
            expanded>
          </b-input>
        </b-field>
        <button class="button is-primary" @click.prevent="print()">
          <span><i class="fa fa-print"></i> Print</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      year: '',
      name_1: '',
      position_1: '',
      name_2: '',
      position_2: '',
      suggest: [],
      isLoading: false,
      config: ''
    }
  },
  computed: {
    filteredName1() {
      return this.suggest.filter((option) => {
        return option.full_name
          .toString()
          .toLowerCase()
          .indexOf(this.name_1.toLowerCase()) >= 0
      })
    },
    filteredName2() {
      return this.suggest.filter((option) => {
        return option.full_name
          .toString()
          .toLowerCase()
          .indexOf(this.name_2.toLowerCase()) >= 0
      })
    }
  },
  mounted() {
    this.isLoading = true
    this.config = {
      headers: {'Authorization': "Bearer " + this.$store.state.token}
    };

    this.$http.get(`${this.$host}/api/parameter/service_record/employee`,
      this.config
    ).then((response) => {
      let data = response.data
      this.suggest = data
      for (let i = 0; i < this.suggest.length; i++) {
        this.suggest[i].full_name = this.suggest[i].full_name.toUpperCase();
      }
      this.isLoading = false
    }).catch((error) => {
      //console.log(error)
      this.isLoading = false
    });
  },
  methods: {
    print() {
      this.isLoading = true

      this.$http.get(`${this.$host}/api/report/generate/${this.$role.state.id}`,
        this.config
      ).then((response) => {
        this.isLoading = false
        let token = response.data

        let url = `${this.$host}/api/report/appraisal/${token}?year=${this.year}&name_1=${this.name_1}&position_1=${this.position_1}&name_2=${this.name_2}&position_2=${this.position_2}`
        let win = window.open(url, '_blank')
        win.focus()
      }).catch((error) => {
        //console.log(error)
        this.isLoading = false
      });
    }
  }
}
</script>

<style>

</style>
