<template>
  <div>
    <div class="columns header" style="margin-bottom: 0 !important;">
      <div class="column is-5" style="margin-left: 5px; font-size: 20px; text-transform: uppercase;">
        <b>Holidays/Special Dates</b> 
      </div>
      <div class="column is-auto">
        <button v-if="$role.state.permissions['read work schedule template']" class="button is-primary is-pulled-right is-hidden-mobile" style="margin-left: 10px;" 
        @click.prevent="$router.push('/schedule/templates')">
          <i class="fa fa-calendar-day"></i> &nbsp;Templates
        </button>
        <button v-if="$role.state.permissions['read daily time record']" class="button is-primary is-pulled-right is-hidden-mobile" style="margin-left: 10px;" 
        @click.prevent="toSchedule()">
          <i class="fa fa-user-tie"></i> &nbsp;Schedules
        </button>
        <button v-if="$role.state.permissions['read daily time record']" class="button is-primary is-hidden-tablet" @click.prevent="toSchedule()">
          <i class="fa fa-user-tie"></i> &nbsp;Schedules
        </button>
        <button v-if="$role.state.permissions['read work schedule template']" class="button is-primary is-hidden-tablet" @click.prevent="$router.push('/schedule/templates')">
          <i class="fa fa-calendar-day"></i> &nbsp;Templates
        </button>
      </div>
    </div>
    <hr style="margin-top: 0 !important; margin-bottom: 0 !important;" class="has-background-grey-lighter is-hidden-mobile">
    <br>
    <div>
      <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
      <div class="box">
        <b-field>
          <button v-if="$role.state.permissions['write special dates']" class="button is-success" @click.prevent="addHoliday">
            <i class="fa fa-plus"></i> &nbsp;Holiday/Special Date
          </button>
        </b-field>
        <b-field style="margin-bottom: 0;">
          <b-input v-model="byYear" maxlength="4" placeholder="Year" @input="changeYear" type="number"></b-input>
          <b-input v-model="search_query" placeholder="Search"/>
        </b-field>
        <hr>
        <b-table
        :data="filterHolidays"
        :bordered=true
        :hoverable=true
        :paginated="true"
        :per-page="10">  
          <template slot-scope="props">
            <b-table-column field="reference_date" label="Reference Date" sortable>
              {{ props.row.reference_date }}
            </b-table-column>
            <b-table-column field="reference_time" label="Reference Time" sortable>
              {{ props.row.reference_time }}
            </b-table-column>
            <b-table-column field="type" label="Type" sortable>
              {{ props.row.type }}
            </b-table-column>
            <b-table-column label="Description">
              <span :class="{highlight : props.row.use_description}">{{ props.row.description }}</span>
            </b-table-column>
            <b-table-column label="Group">
              {{ isNull(props.row.group) }}
            </b-table-column>
            <b-table-column label="Fixed">
              {{ yesNo(props.row.is_fixed) }}
            </b-table-column>
            <b-table-column label="Required">
              {{ yesNo(props.row.is_required) }}
            </b-table-column> 
            <b-table-column label="Actions" v-if="$role.state.permissions['write special dates']">
              <button class="button btn-action is-primary is-small" @click.prevent="editRow(props.row)"><i class="fa fa-pen"></i></button>
              <button class="button btn-action is-danger is-small" @click.prevent="deleteRow(props.row)"><i class="fa fa-times"></i></button>
            </b-table-column>
          </template>
          <template slot="empty">
            <b-table-column label="Reference Date">
            </b-table-column>
            <b-table-column label="Reference Time">
            </b-table-column>
            <b-table-column label="Type">
            </b-table-column>
            <b-table-column label="Description">
            </b-table-column>
            <b-table-column label="Group">
            </b-table-column>
            <b-table-column label="Fixed">
            </b-table-column>
            <b-table-column label="Required">
            </b-table-column> 
            <b-table-column label="Actions" v-if="$role.state.permissions['write special dates']">
            </b-table-column>
          </template>
        </b-table>
      </div>
    </div>
    <b-modal :active.sync="isComponentModalActive" has-modal-card :canCancel=false>
      <transition name="fade">
        <form action="">
          <div class="modal-card" style="height: 35.625rem; margin-top: 4rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">{{ state }}</p>
            </header>
            <section class="modal-card-body">
              <i>Time Format: Military Time (ex. 17:00:00)</i>
              <b-field label="Type">
                <b-field>
                  <b-select placeholder="Select Type" v-model="holiday.type" expanded>
                    <option value="special">Special</option>
                    <option value="legal">Legal</option>
                    <option value="suspension">Suspension</option>
                    <option value="flag ceremony">Flag Ceremony</option>
                  </b-select>
                </b-field>
              </b-field>
              <b-field label="Reference Date">
                <b-field>
                  <b-datepicker
                    placeholder="Reference Date"
                    icon="calendar-today"
                    v-model="date"
                    :date-formatter="d => d.toLocaleDateString('en-GB')">
                  </b-datepicker>
                </b-field>
              </b-field>
              <b-field label="Reference Time" v-if="holiday.type == 'suspension' || holiday.type == 'flag ceremony'">
                <b-autocomplete
                  v-model="holiday.reference_time"
                  :data="filteredTime"
                  :open-on-focus=true expanded>
                </b-autocomplete>
              </b-field>
              <b-field label="Group">
                <b-select placeholder="ALL" v-model="holiday.employee_groups_id" expanded>
                  <option :value='null'>ALL</option>
                  <option
                    v-for="option in groups"
                    :value="option.id"
                    :key="option.id">
                    {{ option.code }}
                  </option>
                </b-select>
              </b-field>
              <b-field label="Description" v-if="holiday.type != 'flag ceremony'">
                <b-field>
                  <b-input v-model="holiday.description" expanded></b-input>
                </b-field>
              </b-field>
              <b-field>
                <b-checkbox v-model="holiday.is_fixed"
                  true-value=1
                  false-value=0>
                </b-checkbox>
                <b-field label="Fixed">
                </b-field>
              </b-field>
              <b-field v-if="holiday.type == 'suspension'">
                <b-checkbox v-model="holiday.is_required"
                  true-value=1
                  false-value=0>
                </b-checkbox>
                <b-field label="Required To Timeout">
                </b-field>
              </b-field>
              <b-field v-if="holiday.type == 'suspension'">
                <b-checkbox v-model="holiday.use_description">
                </b-checkbox>
                <b-field label="Use Description">
                </b-field>
              </b-field>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" @click.prevent="save">{{ state }}</button>
              <button class="button is-danger" @click.prevent="cancel">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  data() {
    return {
      isLoading: false,
      config: '',
      holidays: [],
      search_query: '',
      isComponentModalActive: false,
      holiday: {
        is_fixed: 0,
        is_required: 0
      },
      state: '',
      date: '',
      time: '',
      byYear: '',
      groups: []
    }
  },
  mounted() {
    this.config = {
      headers: {'Authorization': "Bearer " + this.$store.state.token}
    };
    this.getHolidays()
  },
  computed: {
    filterHolidays() {
      let i;
      let name_re = new RegExp(this.search_query, 'i')
      let data = []
      for (i in this.holidays) {
        if ([this.holidays[i].reference_date, this.holidays[i].type, this.holidays[i].description].join(' ').match(name_re)) {
          data.push(this.holidays[i])
        }
      }
      return data
    },
    filteredTime() {
      return this.$time.state.time.filter((option) => {
        return option
          .toString()
          .toLowerCase()
          .indexOf(this.time.toLowerCase()) >= 0
      })
    }
  },
  methods: {
    isNull(group) {
      if (group == null) {
        return 'ALL'
      }
      else {
        return group.code
      }
    },
    yesNo(bool) {
      if (bool == 0) {
        return 'No'
      }
      else if (bool == 1) {
        return 'Yes'
      }
      else {
        return bool
      }
    },
    getGroups() {
      this.isLoading = true
      this.$http.get(`${this.$host}/api/group/all`,
        this.config
      ).then((response) => {
        let data = response.data
        this.groups = data
        this.isLoading = false
      }).catch((error) => {
        this.isLoading = false
      });
    },
    changeYear() {
      this.search_query = ''
      if (this.byYear.length == 4) {
        this.isLoading = true
        this.$http.get(`${this.$host}/api/special-dates/all/${this.byYear}`,
          this.config
        ).then((response) => {
          let data = response.data
          this.holidays = data
          this.isLoading = false
        }).catch((error) => {
          this.isLoading = false
        });
      }
      else if (this.byYear.length == 0) {
        this.getHolidays()
      }
    },
    getHolidays() {
      this.isLoading = true
      this.$http.get(`${this.$host}/api/special-dates/all/${this.byYear}`,
        this.config
      ).then((response) => {
        let data = response.data
        this.holidays = data
        this.getGroups()
      }).catch((error) => {
        this.isLoading = false
      });
    },
    toSchedule() {
      // this.$editmode.commit('setSearch', true)
      this.$router.push({ path: '/schedule' })
    },
    addHoliday() {
      this.state = "Add"
      this.holiday.employee_groups_id = null
      this.date = ''
      this.isComponentModalActive = true
    },
    editRow(obj) {
      this.state = 'Edit'
      this.holiday = obj
      if (obj.group) {
        this.holiday.employee_groups_id = obj.group.id
      }
      this.date = new Date(obj.reference_date)
      // this.time = obj.reference_date.substr(11, 18)
      this.isComponentModalActive = true
    },
    deleteRow(obj) {
      this.$dialog.confirm({
        title: 'Delete Holiday/Special Date',
        message: 'Are you sure you want to <b>delete</b>? This action cannot be undone.',
        confirmText: 'Delete',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$http.delete(`${this.$host}/api/special-dates/delete/${obj.id}`,
          this.config,
        ).then((response) => {
          this.$toast.open({
            message: 'Data successfully deleted.',
            type: 'is-success',
            position: 'is-bottom-right',
            duration: 4000
          })
          this.cancel()
        }).catch((error) => {
          this.$toast.open({
            message: 'Error deleting data.',
            type: 'is-danger',
            position: 'is-bottom-right',
            duration: 4000
          })
          this.cancel()
        });
        }
      })
    },
    cancel() {
      this.holiday = {}
      this.isComponentModalActive = false
      // this.date = ''
      // this.time = ''
      this.getHolidays()
    },
    save() {
      if (this.holiday.type == 'suspension' || this.holiday.type == 'flag ceremony') {
        if (this.holiday.reference_time == '' || this.holiday.reference_time == null) {
          this.$toast.open({
            message: 'Reference time is required.',
            type: 'is-danger',
            position: 'is-bottom-right',
            duration: 4000
          })
        }
        else {
          this.saveData()
        }
      }
      else {
        this.saveData()
      }
    },
    saveData() {
      if (this.holiday.id == undefined) {
        this.holiday.id = ''
      }
      if (this.holiday.type != 'suspension') {
        this.holiday.is_required = 0
      }
      if (this.holiday.type == 'special' || this.holiday.type == 'legal') {
        this.holiday.reference_time = null
      }
      if (this.holiday.use_description) {
        this.holiday.description = this.holiday.description.concat(' ^')
      }
      // moment(this.holid).format("YYYY-MM-DD")
      delete this.holiday.created_at
      delete this.holiday.updated_at
      delete this.holiday.group
      this.holiday.reference_date = moment(this.date).format("YYYY-MM-DD")
      this.$http.post(`${this.$host}/api/special-dates/save/${this.holiday.id}`,
        this.holiday,
        this.config,
      ).then((response) => {
        this.$toast.open({
          message: 'Data successfully saved.',
          type: 'is-success',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.cancel()
      }).catch((error) => {
        this.$toast.open({
          message: 'Error saving data.',
          type: 'is-danger',
          position: 'is-bottom-right',
          duration: 4000
        })
        // this.cancel()
      });
    }
  }
}
</script>

<style>
  .highlight {
    font-weight: bold;
  }
</style>
