<template>
  <section>
    <b-modal :active.sync="isComponentModalActive" has-modal-card :canCancel="false">
      <div class="modal-card pos" style="width: 60rem; height: 35.625rem; margin-left: 100px;">
        <header class="modal-card-head">
          <p class="modal-card-title">Search {{ searchTitle }}</p>
          <button style="border: none; background-color: transparent; font-size: 1.25rem" class="button has-text-danger" @click.prevent="$emit('close')">
            <span class="icon"><i class="fa fa-times"></i></span>
          </button>
        </header>
        <section class="modal-card-body" style="overflow-y: scroll;">
          <form @submit.prevent="search()">
            <b-field label="Search">
              <b-field>
                <b-input v-model="search_query.name" :disabled="isLoading" expanded />
                <p class="control">
                  <button class="button is-primary" style="width: 8rem;" type="submit" :disabled="isLoading" @click="search()">SEARCH</button>
                </p>
              </b-field>
            </b-field>
            <b-table
              :data="data"
              :selected.sync="selected"
              :paginated="true"
              :per-page="5"
              focusable
              class="uppercase">
              <template slot-scope="props">
                <b-table-column label="Employee Number" sortable>
                  {{ props.row.employee_number }}
                </b-table-column>
                <b-table-column label="Name" sortable>
                  {{ props.row.first_name }} {{ props.row.middle_name }} {{ props.row.last_name }}
                </b-table-column>
                <!-- <b-table-column label="Date Hired" sortable>
                  {{ props.row.date_hired }}
                </b-table-column> -->
                <b-table-column label="Date of Birth" v-if="!isPayroll" sortable>
                  {{ props.row.birth_date }}
                </b-table-column>
                <!-- <b-table-column label="Division / Office" sortable>
                  {{ props.row.division }}
                </b-table-column> -->
                <b-table-column label="Project" v-if="isPayroll" sortable>
                  {{ props.row.funding_source }}
                </b-table-column>
                <!-- <b-table-column label="Active">
                  {{ isActive(props.row.users.active) }}
                </b-table-column> -->
              </template>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered" v-if="!isPayroll">
                    <p>Start by searching by Employee Number, Employee Name, or Division</p>
                  </div>
                  <div class="content has-text-grey has-text-centered" v-if="isPayroll">
                    <p>Start by searching by Employee Number, Employee Name, or Division, or Funding Source</p>
                  </div>
                </section>
              </template>
            </b-table>
            <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="true"></b-loading>
          </form>
        </section>
        <footer class="modal-card-foot">
          <button class="button is-primary" type="button" @click.prevent="$emit('view', selected)">
            Confirm
          </button>
          <div v-if="isReport">
          </div>
          <div v-else>
          <!-- <button class="button is-danger" type="button" @click="$emit('close', false)">Close</button>  -->
            <router-link to="/employees/add" class="button is-success" @click.prevent="add()" v-if="!isUser && $role.state.permissions['write employee']">
              <i class="fa fa-plus"></i> &nbsp;New Employee
            </router-link>
          <!-- <span>Selected: {{ selected }}</span> -->
          <span style="text-align: right;" class="is-hidden-mobile">
            <b>Active Employees:</b> {{ this.stats.active }}
            <b>Inactive:</b> {{ this.stats['not-active'] }}
            <b>Resigned:</b> {{ this.stats['resigned'] }}
          </span>
          </div>
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>


  export default {
    props: ['searchTitle', 'isUser', 'isPayroll', 'isReport'],
    components: {

    },
    computed: {
      filter: function() {
        var i;
        var name_re = new RegExp(this.search_query.name, 'i')
        var data = []
        for (i in this.data) {
          Object.keys(this.data[i]).forEach((key) => (this.data[i][key] == null) && delete this.data[i][key]); // remove null keys for concat to work
          if ([this.data[i].first_name, this.data[i].middle_name, this.data[i].last_name, this.data[i].employee_number, this.data[i].division].join(' ')
          .match(name_re)) {
            data.push(this.data[i])
          }
        }
        return data
      }
    },
    // mounted() {
    //   let config = {
    //     headers: {'Authorization': "Bearer " + this.$store.state.token}
    //   };

    //   this.$http.get(`${this.$host}/api/employee/search`,
    //     config
    //   ).then((response) => {
    //     let data = response.data.data
    //     this.data = data
    //     this.isLoading = false
    //   }).catch((error) => {
    //     //console.log(error)
    //   });

    //   let config2 = {
    //     headers: {'Authorization': "Bearer " + this.$store.state.token}
    //   };

    //   this.$http.get(`${this.$host}/api/stats/user`,
    //     config2
    //   ).then((response) => {
    //     let data = response.data.data
    //     this.stats = data
    //     this.isLoading = false
    //   }).catch((error) => {
    //     //console.log(error)
    //   });
    // },
    data() {
      return {
        selected: {},
        isComponentModalActive: true,
        data: '',
        search_query: {
          name: ''
        },
        isLoading: false,
        stats: {},
      }
    },
    methods: {
      search() {
        this.isLoading = true

        let config = {
          headers: {'Authorization': "Bearer " + this.$store.state.token},
          params: {
            search_value: this.search_query.name,
          }
        };

        this.$http.get(`${this.$host}/api/employee/search`,
          config
        ).then((response) => {
          let data = response.data.data
          this.data = data
          this.isLoading = false
        }).catch((error) => {
          //console.log(error)
        });
      },
      isActive(bool) {
        if (bool == 1) {
          return 'Active'
        }
        else if (bool == 0) {
          return 'Inactive'
        }
        else {
          return 'Unknown'
        }
      }
    }
  }
</script>

<style scoped>
@media screen and (min-width: 1px) and (max-width: 1215px)  {
  .modal-card {
    margin-left: 0px !important;
    width: 100% !important;
  }
  .pos {
    width: none;
  }
}
.pos {
  margin-top: 70px !important;
}
.modal-card-head {
  height: 50px;
}
</style>
