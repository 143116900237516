<template>
  <div>
    <div class="columns header" style="margin-bottom: 0 !important;">
      <div class="column is-5" style="margin-left: 5px; font-size: 20px; text-transform: uppercase;">
        <b>REQUESTS</b> {{ fullName }}
      </div>
      <div class="column is-auto">
        <button class="button is-primary is-pulled-right is-hidden-mobile" style="margin-left: 10px;" @click.prevent="$router.push('/requests')">
          <i class="fa fa-clipboard-list"></i> &nbsp;My Requests
        </button>
        <button class="button is-primary is-hidden-tablet" @click.prevent="$router.push('/requests')">
          <i class="fa fa-clipboard-list"></i> &nbsp;My Requests
        </button>

        <button class="button is-primary is-pulled-right is-hidden-mobile" style="margin-left: 10px;" @click.prevent="isSearch = true">
          <i class="fa fa-search"></i> &nbsp;Employee Requests
        </button>
        <button class="button is-primary is-hidden-tablet" @click.prevent="isSearch = true">
          <i class="fa fa-search"></i> &nbsp;Employee Requests
        </button>
      </div>
    </div>
    <hr style="margin-top: 0 !important; margin-bottom: 0 !important;" class="has-background-grey-lighter is-hidden-mobile">
    <br>
    <div>
      <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
      <div class="box">
        <b-field grouped group-multiline>
          <button class="button is-success control" @click.prevent="isComponentModalActive = true" :disabled="employee_id == ''"
          v-if="$role.state.permissions['write leave ledger']">
            <i class="fa fa-exchange-alt"></i> &nbsp;Add Manual Request
          </button>
          <button class="button is-primary control" @click.prevent="init()">
            <i class="fa fa-redo-alt"></i> &nbsp;Reset Filters
          </button>
          <button class="button is-primary control" @click.prevent="printSummary" v-if="$role.state.permissions['print leave ledger']">
            <i class="fas fa-print"></i> &nbsp;Print Summary
          </button>
          <button class="button is-primary is-pulled-right is-hidden-mobile" style="margin-left: 10px;" @click.prevent="isRerun = true" :disabled="employee_id == ''"
          v-if="$role.state.permissions['write leave ledger']">
            <i class="fa fa-tasks"></i> &nbsp;Rerun Earnings
          </button>
        </b-field>
        <b-field grouped group-multiline>
          <!-- <b-select placeholder="Filter">
            <option value="All">All</option>
            <option value="Requested">Requested</option>
            <option value="Approved">Approved</option>
            <option value="Disapproved">Disapproved</option>
            <option value="Cancelled">Cancelled</option>
            <option value="Manual">Manual</option>
            <option value="Automatic">Automatic</option>
          </b-select> -->
          <b-select placeholder="Per Page" v-model="perPage">
            <option value="5">5 per page</option>
            <option value="10">10 per page</option>
            <option value="20">20 per page</option>
            <option value="31">31 per page</option>
          </b-select>
          <b-select placeholder="Request Type" v-model="requestType" @input="getRequestsByType(requestType)" style="width: 200px;">
            <option
              v-for="option in allowedRequestTypes"
              :value="option.id"
              :key="option.id">
              {{ option.name }}
            </option>
          </b-select>
          <b-select v-model="perMonth" @input="dtrMonthFilter(perMonth)" placeholder="Month">
            <option value="January">January</option>
            <option value="February">February</option>
            <option value="March">March</option>
            <option value="April">April</option>
            <option value="May">May</option>
            <option value="June">June</option>
            <option value="July">July</option>
            <option value="August">August</option>
            <option value="September">September</option>
            <option value="October">October</option>
            <option value="November">November</option>
            <option value="December">December</option>
          </b-select>
          <b-datepicker
            style="width: 150px;"
            placeholder="date from"
            icon="calendar-today"
            v-model="filterFrom"
            @input="dtrRangeFilter"
            :date-formatter="d => d.toLocaleDateString('en-GB')">
          </b-datepicker>
          <b-datepicker
            style="width: 150px;"
            placeholder="date to"
            icon="calendar-today"
            v-model="filterTo"
            @input="dtrRangeFilter"
            :date-formatter="d => d.toLocaleDateString('en-GB')">
          </b-datepicker>
          <b-select placeholder="Status" @input="statusFilter(byStatus)" v-model="byStatus">
            <option value="request">Request</option>
            <option value="approved">Approved</option>
            <option value="disapproved">Disapproved</option>
            <option value="cancelled">Cancelled</option>
            <option value="automatic">Automatic</option>
            <option value="manual">Manual</option>
          </b-select>
        </b-field>
        <hr style="margin-top: 0; margin-bottom: 10px;">
        <div style="text-align: left; margin-bottom: 10px;">
          <b>BALANCE</b>
        </div>
        <div class="columns">
          <div class="column is-3 has-text-white">
            <div class="shadow">
              <div style="background-color: #d32f2f; padding: 5px 10px 0 10px;"><b>Vacation Leave</b></div>
              <div style="background-color: #d32f2f; font-size: 40px; text-align: center; padding: 5px 10px 10px 10px;">{{ balance['VL'] }} </div>
            </div>
          </div>

          <div class="column is-3 has-text-white">
            <div class="shadow">
              <div style="background-color: #C2185B; padding: 5px 10px 0 10px;"><b>Sick Leave</b></div>
              <div style="background-color: #C2185B; font-size: 40px; text-align: center; padding: 5px 10px 10px 10px;">{{ balance['SL'] }} </div>
            </div>
          </div>

          <div class="column is-3 has-text-white">
            <div class="shadow">
              <div style="background-color: #512DA8; padding: 5px 10px 0 10px;"><b>Forced Leave</b></div>
              <div style="background-color: #512DA8; font-size: 40px; text-align: center; padding: 5px 10px 10px 10px;">{{ balance['FL'] }} </div>
            </div>
          </div>

          <div class="column is-3 has-text-white">
            <div class="shadow">
              <div style="background-color: #0288D1; padding: 5px 10px 0 10px;"><b>Special Privilege Leave</b></div>
              <div style="background-color: #0288D1; font-size: 40px; text-align: center; padding: 5px 10px 10px 10px;">{{ balance['SPL'] }} </div>
            </div>
          </div>
        </div>
        <!-- <b>Requests:</b> -->
        <b-table
        :data="ledger"
        :bordered=false
        :hoverable=true
        :paginated="true"
        :per-page="perPage"
        detailed
        detail-key="id">
          <template slot-scope="props">
            <b-table-column label="Date Filed" :class="{greyed: props.row.is_counted == 0}">
              <b-tag type="is-success is-small" v-if="props.row.is_new">New</b-tag> {{ props.row.date_filed }}
            </b-table-column>
            <b-table-column label="Request" :class="{greyed: props.row.is_counted == 0}">
              {{ props.row.name }}
            </b-table-column>
            <b-table-column label="From" :class="{greyed: props.row.is_counted == 0}">
              {{ props.row.from }}
            </b-table-column>
            <b-table-column label="To" :class="{greyed: props.row.is_counted == 0}">
              {{ props.row.to }}
            </b-table-column>
            <b-table-column label="Credit" :class="{green: props.row.credit > 0, red: props.row.credit < 0, greyed: props.row.is_counted == 0}">
              {{ props.row.credit }}
            </b-table-column>
            <b-table-column label="Status">
              <span style="margin-left: 18px;" v-html="setStatus(props.row.status)"></span>
            </b-table-column>
            <b-table-column label="Remarks" :class="{greyed: props.row.is_counted == 0}">
              {{ props.row.raw.remarks }}
            </b-table-column>
            <b-table-column label="Actions" width="150">
              <button v-if="props.row.status == 'manual' && $role.state.permissions['write leave ledger']" class="button btn-action is-primary is-small" @click.prevent="editRow(props.row.raw)"><i class="fa fa-pen"></i></button>
              <button v-if="props.row.raw.request_type_id != 14 && props.row.raw.request_type_id != 16 && $role.state.permissions['print leave ledger'] && props.row.raw.request_type_id!=17" class="button btn-action is-primary is-small" @click.prevent="printLeave(props.row.id)"><i class="fa fa-print"></i></button>
              <button v-if="props.row.status != 'cancelled'"
              class="button btn-action is-danger is-small" @click.prevent="cancelPrompt(props.row.id)"><i class="fa fa-ban"></i></button>
              <button class="button btn-action is-danger is-small" @click.prevent="deleteRequest(props.row.id)"><i class="fa fa-times"></i></button>
            </b-table-column>
          </template>
          <template slot="detail" slot-scope="props">
            <article class="media">
              <div class="media-content">
                <div class="content">
                  <p>
                    <span v-if="props.row.raw.reason"><b>Reason:</b> {{ reason(props.row.raw.reason) }}<br></span>
                    <span v-if="props.row.raw.venue"><b>Venue:</b> {{ props.row.raw.venue }}<br></span>
                    <b>Reason:</b> {{ props.row.raw.reason }}<br>
                    <b>1st Approver:</b> <span v-html="setStatusFull(props.row.raw.status_approver_1)"></span><br>
                    <b>2nd Approver:</b> <span v-html="setStatusFull(props.row.raw.status_approver_2)"></span><br>
                    <b>Final Approver:</b> <span v-html="setStatusFull(props.row.raw.status_approver_3)"></span><br>
                    <b>Proof:</b> <button v-if="props.row.raw.proof" class="button is-primary is-small" @click.prevent="openProof(props.row.raw.proof)"><span>ATTACHMENT LINK</span></button>
                  </p>
                </div>
              </div>
            </article>
          </template>
          <template slot="empty">
            <b-table-column label="Date Filed">
            </b-table-column>
            <b-table-column label="Request">
            </b-table-column>
            <b-table-column label="From">
            </b-table-column>
            <b-table-column label="To">
            </b-table-column>
            <b-table-column label="Credit">
            </b-table-column>
            <b-table-column label="Status">
            </b-table-column>
            <b-table-column label="Remarks">
            </b-table-column>
            <b-table-column label="Actions">
            </b-table-column>
          </template>
        </b-table>
        <div style="font-size: 15px;">
        <b>Legend:</b>
          <span style="color: orange; margin-right: 5px; margin-left: 5px;"><i class="far fa-clock"></i> Request</span>
          <span style="color: green; margin-right: 5px;"><i class="fas fa-check"></i> Approved</span>
          <span style="color: red; margin-right: 5px;"><i class="fas fa-times"></i> Disapproved</span>
          <span style="color: red; margin-right: 5px;"><i class="fas fa-ban"></i> Cancelled</span>
          <span style="color: #0D47A1; margin-right: 5px;"><i class="fas fa-bullseye"></i> Automatic</span>
          <span style="color: #0D47A1; margin-right: 5px;"><i class="fas fa-wrench"></i> Manual</span>
          <span style="color: purple; margin-right: 5px;"><i class="fas fa-cog"></i> System Generated</span>
        </div>
        <!-- <b-table
        :data="balances"
        :bordered=false
        :hoverable=true
        detailed
        detail-key="id">
          <template slot-scope="props">
            <b-table-column>
              <b>{{ props.row.balance }}</b>
            </b-table-column>
          </template>
          <template slot="detail">
            <article class="media">
              <div class="media-content">
                <div class="content">
                  <p>
                    <b>VL:</b> {{ balance['VL'] }}<br>
                    <b>SL:</b> {{ balance['SL'] }}<br>
                    <b>FL:</b> {{ balance['FL'] }}<br>
                    <b>SEL:</b>: {{ balance['SEL'] }}
                    <b>Balance:</b> 204 days 2 hours 30 minutes (216.35)
                  </p>
                </div>
              </div>
            </article>
          </template>
          <template slot="empty">
            <b-table-column label="">
            </b-table-column>
          </template>
        </b-table> -->
      </div>
      <!-- <div class="box">
        <b-field label="Print"></b-field>
        <hr style="margin-top: 0; margin-bottom: 10px;">
        <b-field grouped group-multiline v-if="$role.state.permissions['print leave ledger']">
          <b-select placeholder="Print" v-model="printType">
            <option value="salary-report">Salary Report</option>
            <option value="leave-balance-report">Leave Balance Report</option>
            <option value="certification-without-pay">Certification Without Pay</option>
            <option value="certification-leave-credits">Certification Leave Credits</option>
          </b-select>
          <button class="button is-primary control" @click.prevent="isPrint = true">
            <i class="fas fa-print"></i> &nbsp;Print
          </button>
        </b-field>
      </div> -->
    </div>
    <b-modal :active.sync="isComponentModalActive" has-modal-card :canCancel=false>
      <transition name="fade">
        <form @submit.prevent="save()" id="requestForm">
          <div class="modal-card" style="height: 35.625rem; margin-top: 4rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">Request</p>
            </header>
            <section class="modal-card-body">
              <b-field label="Request Type">
                <b-field>
                  <b-select placeholder="Request Type" v-model="newLeaves.request_type_id" @input="getCredit" expanded>
                    <!-- eslint-disable -->
                    <option
                      v-for="option in allowedRequestTypes"
                      :value="option.id"
                      :key="option.id">
                      {{ option.name }}
                    </option>
                    <!-- eslint-enable -->
                  </b-select>
                </b-field>
              </b-field>
              <b-field><b>Current Balance:</b> {{ currentBalance }} days</b-field>
              <b-field label="From">
                <b-field>
                  <b-datepicker
                    icon="calendar-today"
                    v-model="newLeaves.from_date"
                    :date-formatter="d => d.toLocaleDateString('en-GB')"
                    @input="getCredit"
                    expanded>
                  </b-datepicker>
                  <b-autocomplete v-if="isTime"
                    v-model="newLeaves.from_time"
                    :data="filteredTimeFrom"
                    :open-on-focus=true expanded
                    placeholder="TIME">
                  </b-autocomplete>
                </b-field>
              </b-field>
              <b-field label="To">
                <b-field>
                  <b-datepicker
                    icon="calendar-today"
                    v-model="newLeaves.to_date"
                    :date-formatter="d => d.toLocaleDateString('en-GB')"
                    @input="getCredit"
                    expanded>
                  </b-datepicker>
                  <b-autocomplete v-if="isTime"
                    v-model="newLeaves.to_time"
                    :data="filteredTimeTo"
                    :open-on-focus=true expanded
                    placeholder="TIME">
                  </b-autocomplete>
                </b-field>
              </b-field>
              <b-field v-if="(newLeaves.request_type_id != 14 && newLeaves.request_type_id != 16 && newLeaves.request_type_id != 17) && !isTime">
                <b-checkbox v-model="isHalfDay"
                  true-value=1
                  false-value=0
                  :disabled="halfDay"
                  @input="getCredit">
                </b-checkbox>
                <b-field label="Half Day">
                </b-field>
              </b-field>
              <span style="color: red" v-html="validationStatus"></span>
              <b-field label="Credit">
                <b-input v-model="newLeaves.credit"></b-input>
              </b-field>
              <b-field label="Reason" v-if="newLeaves.request_type_id == 15">
              </b-field>
              <div v-if="newLeaves.request_type_id == 15">
                <div class="field">
                  <b-radio v-model="newLeaves.reason" native-value="seminar">Seminar/Training/Conference/Meeting</b-radio>
                </div>
                <div class="field">
                  <b-radio v-model="newLeaves.reason" native-value="document">Document Tracking/Pick-up/Delivery</b-radio>
                </div>
                <div class="field">
                  <b-radio v-model="newLeaves.reason" native-value="passport">Renewal of Passport (for official travel/training/scholarship abroad)</b-radio>
                </div>
                <div class="field">
                  <b-radio v-model="newLeaves.reason" native-value="reconciliation">Reconciliation of GSIS/PAG-IBIG Records/Phil Health</b-radio>
                </div>
                <div class="field">
                  <b-radio v-model="newLeaves.reason" native-value="medical">Medical Check-up (for employee concerned only)</b-radio>
                </div>
                <div class="field">
                  <b-radio v-model="newLeaves.reason" native-value="renewal">Renewal of Licenses/Permits of CPAs, Engineers, Lawyers, Medical
                  Practitioners  and Drivers whose Items are under the DOTr-CO Plantilla of
                  Personnel – Filing and Pick- up only and subject to submission of
                  photocopy of license/permit</b-radio>
                </div>
                <div class="field">
                  <b-radio v-model="newLeaves.reason" native-value="wfh">Work From Home</b-radio>
                </div>
                <div class="field">
                  <b-radio v-model="newLeaves.reason" native-value="other">Other</b-radio>
                </div>
                <b-field label="Please specify" v-if="newLeaves.reason == 'other'">
                  <b-input type="textarea" v-model="otherReason" required></b-input>
                </b-field>
              </div><br>
              <b-field label="Venue" v-if="newLeaves.request_type_id == 15">
                <b-input v-model="newLeaves.venue"></b-input>
              </b-field>
              <b-field label="Remarks">
                <b-input type="textarea" v-model="newLeaves.remarks"></b-input>
              </b-field>
              <b-field label="Attach Proof">
                <input type="file" @input="onFileChange" id="proofFile">
              </b-field>
              <b-field>
                <button class="button is-danger is-small" @click.prevent="deleteFile()"><span><i class="fa fa-times"></i> Remove File</span></button>
              </b-field>
              <b-field label="Proof Type">
                <b-input v-model="newLeaves.proof_type"></b-input>
              </b-field>
              <b-field>
                <b-checkbox v-model="newLeaves.is_start"
                  true-value=1
                  false-value=0>
                </b-checkbox>
                <b-field label="Start">
                </b-field>
              </b-field>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" type="submit">Save</button>
              <button class="button is-danger" @click.prevent="cancel">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>
    <b-modal :active.sync="isPrint" has-modal-card :canCancel=false>
      <transition name="fade">
        <form action="">
          <div class="modal-card">
            <header class="modal-card-head">
              <p class="modal-card-title">Signature Field</p>
            </header>
            <section class="modal-card-body">
              <b-field label="Certified By">
                <b-autocomplete
                  v-model="certified_by"
                  :data="filteredSupervisor"
                  field="full_name"
                  @select="option => {position_2 = option.position, division_2 = option.division}"
                  :open-on-focus=false expanded>
                </b-autocomplete>
              </b-field>
              <b-field label="Certifier Position">
                <b-autocomplete
                  v-model="position_2">
                </b-autocomplete>
              </b-field>
              <b-field label="Certifier Division">
                <b-autocomplete
                  v-model="division_2">
                </b-autocomplete>
              </b-field>
              <b-field label="Prepared By" v-if="isSummary == true">
                <b-autocomplete
                  v-model="prepared_by"
                  :data="filteredPrepared"
                  field="full_name"
                  placeholder="Prepared By"
                  :open-on-focus=false expanded>
                </b-autocomplete>
              </b-field>
              <br><br><br>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" @click.prevent="print()">Print</button>
              <button class="button is-danger" type="button" @click="isPrint = false">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>
    <b-modal :active.sync="isCancelPrompt" has-modal-card :canCancel=false>
      <transition name="fade">
        <form @submit.prevent="cancelRequest()">
          <div class="modal-card">
            <header class="modal-card-head">
              <p class="modal-card-title">Cancellation Reason</p>
            </header>
            <section class="modal-card-body">
              <b-field label="Reason">
                <b-input
                  v-model="cancellation_reason" required>
                </b-input>
              </b-field>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" type="submit">Cancel</button>
              <button class="button is-danger" type="button" @click="isCancelPrompt = false">Close</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>
    <b-modal :active.sync="isRerun" has-modal-card :canCancel=false>
      <transition name="fade">
        <form @submit.prevent="reRunEarnings()">
          <div class="modal-card">
            <header class="modal-card-head">
              <p class="modal-card-title">Rerun Earning</p>
            </header>
            <section class="modal-card-body">
               <b-field label="Reference Year">
                <b-input v-model="reRun.refYear" type="number" min=1800 max=2100 style="width: 100px;" required>
                </b-input>
              </b-field>
              <b-field label="Reference Month">
                  <b-select v-model="reRun.refMonth" placeholder="Month" required>
                  <option value=2>January</option>
                  <option value=3>February</option>
                  <option value=4>March</option>
                  <option value=5>April</option>
                  <option value=6>May</option>
                  <option value=7>June</option>
                  <option value=8>July</option>
                  <option value=9>August</option>
                  <option value=10>September</option>
                  <option value=11>October</option>
                  <option value=12>November</option>
                  <option value=1>December</option>
                </b-select>
              </b-field>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" type="submit">Rerun Balance</button>
              <button class="button is-danger" type="button" @click="isRerun = false">Close</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>
    <transition name="fade">
      <EmployeeSearch search-title="Employee" @close="closeSearch" @view="getEmployee" v-if="isSearch" />
    </transition>
  </div>
</template>

<script>
import moment from 'moment'
import EmployeeSearch from '../components/SearchModal.vue'

export default {
  components: {
    EmployeeSearch
  },
  data() {
    return {
      isLoading: false,
      isRerun: false,
      reRun: {
        refYear: new Date().getFullYear(),
        refMonth: new Date().getMonth() + 1
      },
      ledger: [],
      newLeaves: {
        from_time: '',
        to_time: '',
        is_start: 0,
        proof_type: null,
        proof: null,
        ot_json: null
      },
      isComponentModalActive: false,
      balances: [
        {
          balance: 'Balance'
        }
      ],
      balance: {
        VL: 0,
        SL: 0,
        FL: 0,
        SPL: 0
      },
      requestTypes: [],
      config: '',
      isSearch: true,
      employee_id: '',
      fullName: '',
      perPage: 10,
      month: '',
      requestType: null,
      filterFrom: null,
      filterTo: null,
      perMonth: null,
      byStatus: null,
      printType: null,
      isPrint: false,
      employees: [],
      suggest: [],
      certified_by: '',
      prepared_by: '',
      isHalfDay: 0,
      halfDay: true,
      halfDayValue: null,
      isSummary: false,
      proofTypes: [],
      requestForm: new FormData(),
      isTime: false,
      otherReason: null,
      position_2: '',
      division_2: '',
      canSave: false,
      validationStatus: '',
      isCancelPrompt: false,
      cancellation_reason: '',
      cancel_id: null,
    }
  },
  mounted() {
    this.config = {
      headers: {'Authorization': "Bearer " + this.$store.state.token}
    };
    this.getEmployees()
  },
  computed: {
    allowedRequestTypes() {
      var returnArray = this.requestTypes.slice()
      const index = this.requestTypes.findIndex(v => v.id == 4)
      returnArray.splice(index,index>=0?1:0)
      return returnArray;
    },
    filteredSupervisor() {
      return this.suggest.filter((option) => {
        return option.full_name
          .toString()
          .toLowerCase()
          .indexOf(this.certified_by.toLowerCase()) >= 0
      })
    },
    filteredPrepared() {
      return this.suggest.filter((option) => {
        return option.full_name
          .toString()
          .toLowerCase()
          .indexOf(this.prepared_by.toLowerCase()) >= 0
      })
    },
    filteredTimeFrom() {
      return this.$time.state.time.filter((option) => {
        return option
          .toString()
          .toLowerCase()
          .indexOf(this.newLeaves.from_time.toLowerCase()) >= 0
      })
    },
    filteredTimeTo() {
      return this.$time.state.time.filter((option) => {
        return option
          .toString()
          .toLowerCase()
          .indexOf(this.newLeaves.to_time.toLowerCase()) >= 0
      })
    },
    currentBalance() {
      if (this.newLeaves.request_type_id == 1) {
        return this.balance['VL']
      }
      else if (this.newLeaves.request_type_id == 2) {
        return this.balance['SL']
      }
      else if (this.newLeaves.request_type_id == 3) {
        return this.balance['FL']
      }
      else if (this.newLeaves.request_type_id == 4) {
        return this.balance['AWOL']
      }
      else if (this.newLeaves.request_type_id == 5) {
        return this.balance['SPL']
      }
      else if (this.newLeaves.request_type_id == 6) {
        return this.balance['SOPL']
      }
      else if (this.newLeaves.request_type_id == 7) {
        return this.balance['SEL']
      }
      else if (this.newLeaves.request_type_id == 8) {
        return this.balance['PL']
      }
      else if (this.newLeaves.request_type_id == 9) {
        return this.balance['ML']
      }
      else if (this.newLeaves.request_type_id == 10) {
        return this.balance['RL']
      }
      else if (this.newLeaves.request_type_id == 11) {
        return this.balance['MCW']
      }
      else if (this.newLeaves.request_type_id == 12) {
        return this.balance['STL']
      }
      else if (this.newLeaves.request_type_id == 13) {
        return this.balance['VAWC']
      }
      else if (this.newLeaves.request_type_id == 14) {
        return this.balance['VL']
      }
      else if (this.newLeaves.request_type_id == 15) {
        // return this.balance['OB']
        return 0
      }
      else if (this.newLeaves.request_type_id == 16) {
        return this.balance['OT']
      }
      else if (this.newLeaves.request_type_id == 17) {
        return this.balance['SL']
      }
      else {
        return 0
      }
    }
  },
  methods: {
    setStatusFull(status) {
      if (status == 'requested' || status == 'request') {
        return '<span style="color: orange;" title="request" aria-label="request">REQUEST <i class="far fa-clock"></i></span>'
      }
      else if (status == 'approved') {
        return '<span style="color: green" title="approved" aria-label="approved">APPROVED <i class="fas fa-check"></i></span>'
      }
      else if (status == 'disapproved') {
        return '<span style="color: red" title="disapproved" aria-label="disapproved">DISAPPROVED <i class="fas fa-times"></i></span>'
      }
      else if (status == 'cancelled') {
        return '<span style="color: red" title="cancelled" aria-label="cancelled">CANCELLED <i class="fas fa-ban"></i></span>'
      }
      else if (status == 'automatic') {
        return '<span style="color: #0D47A1" title="automatic" aria-label="automatic">AUTOMATIC <i class="fas fa-bullseye"></i></span>'
      }
      else if (status == 'manual') {
        return '<span style="color: #0D47A1" title="manual" aria-label="manual">MANUAL <i class="fas fa-wrench"></i></span>'
      }
      else if (status == 'system') {
        return '<span style="color: purple" title="system" aria-label="manual">SYSTEM GENERATED <i class="fas fa-cog"></i></span>'
      }
      else {
        return status
      }
    },
    reason(reason) {
      if (reason == 'seminar') {
        return 'Seminar/Training/Conference/Meeting'
      }
      else if (reason == 'document') {
        return 'Document Tracking/Pick-up/Delivery'
      }
      else if (reason == 'passport') {
        return 'Renewal of Passport (for official travel/training/scholarship abroad)'
      }
      else if (reason == 'reconciliation') {
        return 'Reconciliation of GSIS/PAG-IBIG Records/Phil Health'
      }
      else if (reason == 'medical') {
        return 'Medical Check-up (for employee concerned only)'
      }
      else if (reason == 'renewal') {
        return 'Renewal of Licenses/Permits of CPAs, Engineers, Lawyers, Medical Practitioners  and Drivers whose Items are under the DOTr-CO Plantilla ofPersonnel – Filing and Pick- up only and subject to submission of photocopy of license/permit'
      }
      else {
        return reason
      }
    },
    printLeave(id) {
      this.$http.get(`${this.$host}/api/report/generate/${id}`,
        this.config
      ).then((response) => {
        this.isLoading = false
        let token = response.data

        let url = `${this.$host}/api/report/leave-application-form/${token}`
        let win = window.open(url, '_blank')
        win.focus()
        this.isPrintCOE = false
      }).catch((error) => {
        //console.log(error)
        this.isLoading = false
      });
    },
    openProof(proof) {
      let url = this.$host + '/storage/proofs/' + proof
      let win = window.open(url, '_blank')
      win.focus()
    },
    onFileChange(e) {
      let file = e.target.files[0]
      this.requestForm.append('proof', file)
    },
    deleteFile() {
      document.getElementById("proofFile").value = null;
      this.requestForm.delete('proof')
    },
    getProofTypes() {
      this.$http.get(`${this.$host}/api/parameter/proof-type/`,
        this.config
      ).then((response) => {
        let data = response.data
        this.proofTypes = data
        //console.log(this.proofTypes)
      }).catch((error) => {
        //console.log(error)
      });
    },
    printSummary() {
      this.isSummary = true
      this.isPrint = true
    },
    cancelPrompt(id) {
      this.cancel_id = id
      this.isCancelPrompt = true
    },
    deleteRequest(id) {
      this.$dialog.confirm({
        title: 'Delete Request',
        message: 'Are you sure you want to <b>delete</b> this request?',
        confirmText: 'Yes',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isLoading = true
          this.$http.put(`${this.$host}/api/leave/admin/delete/${id}`,
            {},
            this.config
          ).then((response) => {
            this.$toast.open({
              message: 'Request deleted.',
              type: 'is-success',
              position: 'is-bottom-right',
              duration: 4000
            })
            this.init()
            this.isLoading = false
          }).catch((error) => {
            this.$toast.open({
              message: 'Error.' + error.response.data,
              type: 'is-danger',
              position: 'is-bottom-right',
              duration: 4000
            })
            this.init()
            this.isLoading = false
          });
        }
      })
    },
    cancelRequest() {
      this.$dialog.confirm({
        title: 'Cancel Request',
        message: 'Are you sure you want to <b>cancel</b> this request?',
        confirmText: 'Yes',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isLoading = true
          this.$http.put(`${this.$host}/api/leave/admin/cancel/${this.cancel_id}`,
            {
              cancellation_reason: this.cancellation_reason
            },
            this.config
          ).then((response) => {
            this.$toast.open({
              message: 'Request cancelled.',
              type: 'is-success',
              position: 'is-bottom-right',
              duration: 4000
            })
            this.cancellation_reason = ''
            this.cancel_id = null
            this.isCancelPrompt = false
            this.init()
            this.isLoading = false
          }).catch((error) => {
            if (error.response.data == 'leave cannot be cancelled') {
              this.$toast.open({
                message: 'Leave cannot be cancelled.',
                type: 'is-danger',
                position: 'is-bottom-right',
                duration: 4000
              })
            }
            this.$toast.open({
              message: 'Error.',
              type: 'is-danger',
              position: 'is-bottom-right',
              duration: 4000
            })
            this.init()
            this.isLoading = false
          });
        }
      })
    },
    reRunEarnings() {
      this.$dialog.confirm({
        title: 'Rerun Earnings',
        message: 'Are you sure you want to <b>rerun</b> this employee\'s earnings?',
        confirmText: 'Yes',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isLoading = true
          var refYear = parseInt(this.reRun.refYear);
          if (this.reRun.refMonth==1) {
            refYear = refYear + 1;
          }
          this.$http.post(`${this.$host}/api/leave/earn/${this.reRun.refMonth}/${refYear}/${this.employee_id}`,{},
            this.config
          ).then((response) => {
            this.$toast.open({
              message: 'Rerun Successful.',
              type: 'is-success',
              position: 'is-bottom-right',
              duration: 4000
            })
            this.cancellation_reason = ''
            this.cancel_id = null
            this.isCancelPrompt = false
            this.init()
            this.isLoading = false
          }).catch((error) => {
            this.$toast.open({
              message: 'Error.' + error.response.data,
              type: 'is-danger',
              position: 'is-bottom-right',
              duration: 4000
            })
            this.init()
            this.isLoading = false
          });
        }
      })
    },
    getTime() {
      if (moment(this.newLeaves.from_date).format("YYYY-MM-DD") == moment(this.newLeaves.to_date).format("YYYY-MM-DD")) {
        this.halfDay = false
      }
      else {
        this.halfDay = true
      }

      this.$http.get(`${this.$host}/api/leave/schedule/${moment(this.newLeaves.from_date).format("YYYY-MM-DD")}/${this.employee_id}`,
        this.config
      ).then((response) => {
        let data = response.data
        this.newLeaves.from_time = data.time_in

        this.$http.get(`${this.$host}/api/leave/schedule/${moment(this.newLeaves.to_date).format("YYYY-MM-DD")}/${this.employee_id}`,
          this.config
        ).then((response) => {
          let data = response.data
          this.newLeaves.to_time = data.time_out
          this.halfDayValue = data.half_day_out
          this.computeCredit()
        }).catch((error) => {
          //console.log(error)
        });

      }).catch((error) => {
        //console.log(error)
      });
    },
    getEmployees() {
      this.$http.get(`${this.$host}/api/parameter/service_record/employee`,
        this.config
      ).then((response) => {
        let data = response.data
        this.suggest = data
        this.isLoading = false
        let i = 0
        for (i in this.suggest) {
          this.suggest[i].full_name = this.suggest[i].full_name.toUpperCase()
        }
      }).catch((error) => {
        //console.log(error)
        this.isLoading = false
      });
    },
    getCredit() {
      if (this.newLeaves.request_type_id == 15 || this.newLeaves.request_type_id == 18 || this.newLeaves.request_type_id == 19) {
        this.isTime = true
      }
      else {
        this.isTime = false
      }

      if (this.newLeaves.from_date && this.newLeaves.to_date && this.newLeaves.request_type_id) {
        this.isLoading = true
        this.getTime()
      }
      else if (this.newLeaves.request_type_id == 15) {
        this.newLeaves.credit = 0
      }
      else {
        this.newLeaves.credit = null
      }
    },
    computeCredit() {
      if (this.isHalfDay == 1) {
        this.newLeaves.to_time = this.halfDayValue
      }
      if (!this.newLeaves.from_time) {
        this.newLeaves.from_time = '00:00:00'
      }
      if (!this.newLeaves.to_time) {
        this.newLeaves.to_time = '00:00:00'
      }
      let config = {
        headers: {
          'Authorization': "Bearer " + this.$store.state.token,
          'Content-Type': 'application/json'
        },
        params: {
          from_date: moment(this.newLeaves.from_date).format("YYYY-MM-DD"),
          from_time: this.newLeaves.from_time,
          to_date: moment(this.newLeaves.to_date).format("YYYY-MM-DD"),
          to_time: this.newLeaves.to_time,
          request_type_id: this.newLeaves.request_type_id,
          is_manual: 1
        }
      }
      this.$http.get(`${this.$host}/api/leave/compute/${this.employee_id}`,
        config
      ).then((response) => {
        let data = response.data
        this.newLeaves.credit = data.credit
        this.newLeaves.ot_json = data.ot_json
        this.canSave = true
        this.validationStatus = ''
        this.isLoading = false
      }).catch((error) => {
        //console.log(error)
        let from = this.newLeaves.from_date
        let to = this.newLeaves.to_date

        if (from > to) { // check if from date is greater than to date
          this.isHalfDay = 0
          this.canSave = false
          this.validationStatus = '<b><i class="fas fa-exclamation-triangle"></i> ERROR:</b> End date is less than start date.'
        }
        else if (error.response.data == 'duplicate request') {
          this.canSave = false
          this.validationStatus = `<b><i class="fas fa-exclamation-triangle"></i> ERROR:</b> "Duplicate request.`
        }
        else {
          this.canSave = false
          this.validationStatus = `<b><i class="fas fa-exclamation-triangle"></i> ERROR:</b> ${error.response.data}.`
          }

        this.isLoading = false
      });
    },
    print() {
      if (this.isSummary == true) {
        this.isLoading = true

        this.$http.get(`${this.$host}/api/report/generate/${this.employee_id}`,
          this.config
        ).then((response) => {
          this.isLoading = false
          let token = response.data

          let url = `${this.$host}/api/report/request-report/${token}?employee=${this.fullName}&certified_by=${this.certified_by}&prepared_by=${this.prepared_by}&position_2=${this.position_2}&division_2=${this.division_2}`
          let win = window.open(url, '_blank')
          win.focus()
          this.isPrint = false
        }).catch((error) => {
          //console.log(error)
          this.isLoading = false
        });
      }
      else {
        let url = `${this.$host}/api/report/${this.printType}?employee=${this.fullName}&certified_by=${this.certified_by}`
        let win = window.open(url, '_blank')
        win.focus()
      }
      this.isSummary = false
      this.isPrint = false
    },
    init() {
      this.requestType = null
      this.perMonth = null
      this.isLoading = true
      this.getRequestTypes()
    },
    getEmployee(obj) {
      this.employee_id = obj.id
      if (obj.name_extension == null || obj.name_extension == 'null') {
        this.fullName = `${obj.first_name} ${obj.last_name}`
      }
      else {
        this.fullName = `${obj.first_name} ${obj.last_name} ${obj.name_extension}`
      }
      this.isSearch = false
      this.init()
    },
    closeSearch() {
      this.isSearch = false
    },
    getRequestTypes() {
      this.$http.get(`${this.$host}/api/parameter/request-type/`,
        this.config
      ).then((response) => {
        let data = response.data
        this.requestTypes = data
        this.getRequests()
      }).catch((error) => {
        //console.log(error)
      });
    },
    getRequests() {
      this.$http.get(`${this.$host}/api/leave/search/${this.employee_id}`,
        this.config
      ).then((response) => {
        let data = response.data
        this.balance = data.balance
        this.ledger = data.ledger
        this.isLoading = false
      }).catch((error) => {
        //console.log(error)
      });
    },
    getRequestsByType(request) {
      this.perMonth = null
      this.filterFrom = null
      this.filterTo = null
      this.byStatus = null
      let config = {
        headers: {
          'Authorization': "Bearer " + this.$store.state.token,
          'Content-Type': 'application/json'
        },
        params: {
          request_type_id: request
        }
      }
      this.isLoading = true
      this.$http.get(`${this.$host}/api/leave/search/${this.employee_id}`,
        config
      ).then((response) => {
        let data = response.data
        this.balance = data.balance
        this.ledger = data.ledger
        this.isLoading = false
      }).catch((error) => {
        //console.log(error)
      });
    },
    dtrMonthFilter(month) {
      this.requestType = null
      this.filterFrom = null
      this.filterTo = null
      this.byStatus = null
      let config = {
        headers: {
          'Authorization': "Bearer " + this.$store.state.token,
          'Content-Type': 'application/json'
        },
        params: {
          month: month
        }
      }
      this.isLoading = true
      this.$http.get(`${this.$host}/api/leave/search/${this.employee_id}`,
        config
      ).then((response) => {
        let data = response.data
        this.balance = data.balance
        this.ledger = data.ledger
        this.isLoading = false
      }).catch((error) => {
        //console.log(error)
      });
    },
    statusFilter(status) {
      this.perMonth = null
      this.requestType = null
      this.filterFrom = null
      this.filterTo = null
      let config = {
        headers: {
          'Authorization': "Bearer " + this.$store.state.token,
          'Content-Type': 'application/json'
        },
        params: {
          status: status
        }
      }
      this.isLoading = true
      this.$http.get(`${this.$host}/api/leave/search/${this.employee_id}`,
        config
      ).then((response) => {
        let data = response.data
        this.balance = data.balance
        this.ledger = data.ledger
        this.isLoading = false
      }).catch((error) => {
        //console.log(error)
      });
    },
    dtrRangeFilter() {
      if (this.filterFrom && this.filterTo) {
        this.requestType = null
        this.perMonth = null
        this.byStatus = null
        let config = {
          headers: {
            'Authorization': "Bearer " + this.$store.state.token,
            'Content-Type': 'application/json'
          },
          params: {
            from: moment(this.filterFrom).format("YYYY-MM-DD"),
            to: moment(this.filterTo).format("YYYY-MM-DD"),
          }
        }
        this.isLoading = true
        this.$http.get(`${this.$host}/api/leave/search/${this.employee_id}`,
          config
        ).then((response) => {
          let data = response.data
          this.balance = data.balance
          this.ledger = data.ledger
          this.isLoading = false
        }).catch((error) => {
          //console.log(error)
        });
      }
    },
    setStatus(status) {
      if (status == 'request') {
        return '<span style="color: orange;"><i class="far fa-clock"></i></span>'
      }
      else if (status == 'approved') {
        return '<span style="color: green"><i class="fas fa-check"></i></span>'
      }
      else if (status == 'disapproved') {
        return '<span style="color: red"><i class="fas fa-times"></i></span>'
      }
      else if (status == 'cancelled') {
        return '<span style="color: red"><i class="fas fa-ban"></i></span>'
      }
      else if (status == 'automatic') {
        return '<span style="color: #0D47A1"><i class="fas fa-bullseye"></i></span>'
      }
      else if (status == 'manual') {
        return '<span style="color: #0D47A1"><i class="fas fa-wrench"></i></span>'
      }
      else if (status == 'system') {
        return '<span style="color: purple"><i class="fas fa-cog"></i></span>'
      }
      else {
        return status
      }
    },
    save() {
      if (!this.newLeaves.id) {
        this.newLeaves.id = ''
        this.newLeaves.employee_id = this.employee_id
      }

      if (this.newLeaves.request_type_id == 15) {
        if (this.newLeaves.reason == 'other') {
          this.requestForm.append('reason', this.otherReason)
        }
        else {
          this.requestForm.append('reason', this.newLeaves.reason)
        }
        this.requestForm.append('venue', this.newLeaves.venue)
      }

      this.newLeaves.from_date = moment(this.newLeaves.from_date).format("YYYY-MM-DD")
      this.newLeaves.to_date = moment(this.newLeaves.to_date).format("YYYY-MM-DD")

      this.requestForm.append('from_time', this.newLeaves.from_time)
      this.requestForm.append('to_time', this.newLeaves.to_time)
      this.requestForm.append('from_date', this.newLeaves.from_date)
      this.requestForm.append('to_date', this.newLeaves.to_date)
      this.requestForm.append('is_start', this.newLeaves.is_start)
      this.requestForm.append('proof_type', this.newLeaves.proof_type)
      this.requestForm.append('credit', this.newLeaves.credit)
      this.requestForm.append('ot_json', this.newLeaves.ot_json)
      this.requestForm.append('remarks', this.newLeaves.remarks)
      this.requestForm.append('request_type_id', this.newLeaves.request_type_id)
      this.requestForm.append('employee_id', this.newLeaves.employee_id)

      this.isLoading = true
      if (this.newLeaves.request_type_id == 15 && (!this.newLeaves.from_time || !this.newLeaves.to_time)) {
        this.$toast.open({
          message: 'Error. No time inputted.',
          type: 'is-danger',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.isLoading = false
      }
      else {
        this.$http.post(`${this.$host}/api/leave/save/${this.newLeaves.id}`,
          this.requestForm,
          this.config,
        ).then((response) => {
          this.$toast.open({
            message: 'Data successfully saved.',
            type: 'is-success',
            position: 'is-bottom-right',
            duration: 4000
          })
          this.isLoading = false
          this.cancel()
        }).catch((error) => {
          this.isLoading = false
          if (error.message == 'approver are missing!') {
            this.$toast.open({
              message: 'Error. No approvers assigned.',
              type: 'is-danger',
              position: 'is-bottom-right',
              duration: 4000
            })
          }
          else if (error.response.data) {
            this.$toast.open({
              message: error.response.data,
              type: 'is-danger',
              position: 'is-bottom-right',
              duration: 4000
            })
          }
          else {
            this.$toast.open({
              message: 'Error saving data.',
              type: 'is-danger',
              position: 'is-bottom-right',
              duration: 4000
            })
          }
          this.newLeaves.from_date = new Date(this.newLeaves.from_date)
          this.newLeaves.to_date = new Date(this.newLeaves.to_date)
        });
      }
    },
    editRow(obj) {
      let arr = ['seminar', 'document', 'passport', 'reconciliation', 'medical', 'renewal']
      let isOther = true
      arr.forEach((type) => {
        if (obj.reason == type) {
          isOther = false
        }
      })

      this.newLeaves = obj
      if (isOther) {
        this.otherReason = obj.reason
        this.newLeaves.reason = 'other'
      }
      this.newLeaves.id = obj.id
      this.newLeaves.from_date = new Date(obj.from.substr(0, 10))
      this.newLeaves.from_time = obj.from.substr(11)
      this.newLeaves.to_date = new Date(obj.to.substr(0, 10))
      this.newLeaves.to_time = obj.from.substr(11)
      this.isComponentModalActive = true
    },
    cancel() {
      this.init()
      this.newLeaves = {
        from_time: '',
        to_time: '',
        is_start: 0,
        proof_type: null
      },
      this.requestForm = new FormData()
      this.isHalfDay = 0
      this.halfDay = true
      this.isComponentModalActive = false
      this.newLeaves.credit = null
    }
  }
}
</script>

<style scoped>
  .greyed {
    color: grey !important;
  }
  .red {
    color: red;
  }
  .green {
    color: green;
  }
</style>
