<template>
  <form @submit.prevent="save(obj)">
    <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="true"></b-loading>
    <div class="modal-card" style="height: 35.625rem; margin-top: 70px !important;">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ state }}</p>
      </header>
      <section class="modal-card-body">
        <b-field>
          <b-checkbox v-model="obj.show_in_reports"
            true-value=1
            false-value=0>
            <b>Show in Reports</b>
          </b-checkbox>
        </b-field>
        <b-field>
          <b-checkbox v-model="obj.date_hired"
            true-value=1
            false-value=null>
            <b>Date Hired</b>
          </b-checkbox>
        </b-field>
        <b-field>
          <b-checkbox v-model="obj.is_uniformed"
            true-value=1
            false-value=0>
            <b>Uniformed</b>
          </b-checkbox>
        </b-field>
        <b-field>
          <b-checkbox v-model="obj.is_timekeeping_exempted"
            true-value=1
            false-value=0>
            <b>Output Based/Timekeeping Exempted</b>
          </b-checkbox>
        </b-field>
        <b-field label="Remarks">
          <!-- <b-input v-model="obj.remarks_id"></b-input> -->
          <b-select placeholder="Remarks" required expanded v-model="obj.remarks_id" @input="checkRemark(obj.remarks_id)">
            <option
              v-for="remark in sorted_remarks"
              :value="remark.id"
              :key="remark.id">
              {{ remark.code }}
            </option>
          </b-select>
        </b-field>
        <b-field label="Cancellation Reason" v-if="isCancel">
          <b-input v-model="obj.cancellation_reason" required expanded></b-input>
        </b-field>
        <b-field label="Other Remarks" v-if="isRemark">
          <b-input v-model="obj.cancellation_reason" expanded></b-input>
        </b-field>
        <b-field label="From">
          <b-datepicker
            placeholder="Click to select..."
            icon="calendar-today"
            v-model="obj.date_from"
            :date-formatter="d => d.toLocaleDateString('en-GB')"
            :date-parser="d => parseDate(d)"
            editable>
          </b-datepicker>
        </b-field>
        <b-field label="To" v-if="!(isCancel || isRestore)">
          <b-datepicker
            placeholder="Click to select..."
            icon="calendar-today"
            v-model="obj.date_to"
            :date-formatter="d => d.toLocaleDateString('en-GB')"
            :date-parser="d => parseDate(d)"
            editable>
          </b-datepicker>
        </b-field>
        <b-field label="Designation" v-if="this.included_fields.includes('designation')">
          <!-- <b-select placeholder="Select a name" expanded v-model="obj.position_id">
            <option
              v-for="position in params.postions"
              :value="position.id"
              :key="position"
              @click="setPosition(position)">
              {{ position.title }}
            </option>
          </b-select> -->
          <b-field>
            <b-input v-model="position" disabled expanded>
            </b-input>
            <p class="control">
              <button class="button is-primary" @click.prevent="isEditPosition = true"><i class="fa fa-bars"></i></button>
            </p>
            <p class="control">
              <button class="button is-danger" @click.prevent="clearPosition"><i class="fa fa-trash"></i></button>
            </p>
          </b-field>
        </b-field>
        <b-field label="Designation (on print)" v-if="this.included_fields.includes('designation')">
          <!-- <b-input v-model="obj.position" required></b-input> -->
          <b-autocomplete
            v-model="obj.position"
            :data="filteredPosition"
            :open-on-focus=false expanded required>
          </b-autocomplete>
        </b-field>
        <b-field label="Status" v-if="this.included_fields.includes('status')">
          <!-- <b-input v-model="obj.employment_statuses_id"></b-input> -->
          <b-select placeholder="Select a name" expanded v-model="obj.employment_statuses_id">
            <option
              v-for="status in params.employment_statuses_id"
              :value="status.id"
              :key="status.id">
              {{ status.code }}
            </option>
          </b-select>
        </b-field>
        <b-field label="Salary" v-if="this.included_fields.includes('salary')">
          <b-input v-model="obj.salary" required></b-input>
        </b-field>
        <b-field label="Station / Place of Assignment" v-if="this.included_fields.includes('station')">
          <b-input v-model="division" disabled></b-input>
        </b-field>
        <b-field label="Station / Place of Assignment (on print)" v-if="this.included_fields.includes('station')">
          <!-- <b-input v-model="obj.division" required></b-input> -->
          <b-autocomplete
            v-model="obj.division"
            :data="filteredDivision"
            :open-on-focus=false expanded required>
          </b-autocomplete>
        </b-field>
        <b-field label="Place of Work" v-if="this.included_fields.includes('place_of_work')">
          <!-- <b-input v-model="obj.place_of_work"></b-input> -->
          <b-autocomplete
            v-model="obj.place_of_work"
            :data="filteredPlace"
            :open-on-focus=false expanded required>
          </b-autocomplete>
        </b-field>
        <b-field label="Supervisor" v-if="this.included_fields.includes('supervisor')">
          <b-input v-model="supervisor" disabled></b-input>
        </b-field>
        <b-field label="Supervisor (on print)">
          <!-- <b-input v-model="obj.supervisor"></b-input> -->
          <b-autocomplete
            v-model="obj.supervisor"
            :data="filteredSupervisor"
            :open-on-focus=false expanded required>
          </b-autocomplete>
        </b-field>
        <b-field label="Date of Separation" v-if="this.included_fields.includes('date_separation')">
          <b-datepicker
            placeholder="Click to select..."
            icon="calendar-today"
            v-model="obj.date_seperation"
            :date-formatter="d => d.toLocaleDateString('en-GB')"
            :date-parser="d => parseDate(d)"
            editable>
          </b-datepicker>
        </b-field>
        <b-field label="Cause of Separation" v-if="this.included_fields.includes('cause_separation')">
          <b-select placeholder="Select Cause of Separation" expanded v-model="cause">
            <option value="Resignation">Resignation</option>
            <option value="Retirement">Retirement</option>
            <option value="Transfer">Transfer</option>
            <option value="Dismissal">Dismissal</option>
            <option value="Death">Death</option>
            <option value="Custom">Custom</option>
          </b-select>
        </b-field>
        <b-field label="Cause of Separation" v-if="this.included_fields.includes('cause_separation') && this.cause == 'Custom'">
          <b-input type="textarea" v-model="obj.cause"
            id="textarea"
            placeholder="Custom cause of separation..."
            rows="3"
            max-rows="6"
          ></b-input>
        </b-field>
        <b-field label="Leave Without pay" v-if="this.included_fields.includes('lwop_from')">
          <b-datepicker
            position="is-top-right"
            placeholder="Leave without pay from..."
            icon="calendar-today"
            v-model="min_awol"
            :date-formatter="d => d.toLocaleDateString('en-GB')"
            :date-parser="d => parseDate(d)"
            editable>
          </b-datepicker>
        </b-field>
        <b-field v-if="this.included_fields.includes('lwop_to')">
          <b-datepicker
            position="is-top-right"
            placeholder="Leave without pay until..."
            icon="calendar-today"
            v-model="max_awol"
            :date-formatter="d => d.toLocaleDateString('en-GB')"
            :date-parser="d => parseDate(d)"
            editable>
          </b-datepicker>
        </b-field>
        <b-field label="Classification" v-if="this.included_fields.includes('classification')">
          <b-select placeholder="Select Classification" v-model="obj.classification" expanded required>
            <option value="Key">Key</option>
            <option value="Technical">Technical</option>
            <option value="Support to the Technical">Support to the Technical</option>
            <option value="Administrative">Administrative</option>
          </b-select>
        </b-field>
        <b-field label="Level" v-if="this.included_fields.includes('level')">
          <b-select placeholder="Select level" v-model="obj.level" expanded required>
            <option value="1st">1st</option>
            <option value="2nd">2nd</option>
            <option value="3rd">3rd</option>
          </b-select>
        </b-field>
        <br>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-success" type="submit">Save</button>
        <button class="button is-danger" type="button" @click="$emit('close')">Cancel</button>
      </footer>
      <b-modal :active.sync="isEditPosition" has-modal-card :canCancel=false>
        <transition name="fade">
          <add-position :data="params.postions" @close="close()" @confirm="setPosition"></add-position>
        </transition>
      </b-modal>
    </div>
  </form>
</template>

<script>
import moment from 'moment'

const addPosition = {
  props: {
    data: Array
  },
  data() {
    return {
      selected: '',
      isDisabled: false,
      search_query: '',
      isLoading: false,
    }
  },
  computed: {
    filter() {
      var i;
      var name_re = new RegExp(this.search_query, 'i')
      var data = []
      for (i in this.data) {
        if ([this.data[i].title, this.data[i].item_number, this.data[i].division.name].join(' ').match(name_re)) {
          data.push(this.data[i])
        }
      }
      return data
    }
  },
  mounted() {
    //console.log(this.data)
  },
  template: `
    <form action="">
      <div class="modal-card pos" style="width: 60rem; height: 35.625rem; margin-left: 100px;">
        <header class="modal-card-head">
          <p class="modal-card-title">Select position</p>
        </header>
        <section class="modal-card-body">
          <b-input v-model="search_query" placeholder="Search Position"/><br>
          <b-table
            :data="filter"
            :striped=true
            :bordered=true
            :hoverable=true
            :selected.sync="selected"
            :paginated="true"
            :per-page="5">
            <template slot-scope="props">
              <b-table-column label="Title" width="150">
                {{ props.row.title }}
              </b-table-column>
              <b-table-column label="Item Number" width="150">
                {{ props.row.item_number }}
              </b-table-column>
              <b-table-column label="Immediate Supervisor" width="150">
                {{ props.row.immediate_supervisor.title }}
              </b-table-column>
              <b-table-column label="Higher Supervisor" width="150">
                {{ props.row.higher_supervisor.title }}
              </b-table-column>
              <b-table-column label="Division" width="150">
                {{ props.row.division.name }}
              </b-table-column>
              <b-table-column label="Salary" width="150">
                {{ props.row.salary }}
              </b-table-column>
            </template>
          </b-table>
        </section>
      <footer class="modal-card-foot">
        <button class="button is-primary" @click.prevent="$emit('confirm', selected)">
          Confirm
        </button>
        <button class="button is-danger" @click.prevent="$emit('close')">
          Cancel
        </button>
      </footer>
      </div>
    </form>
    `
}

export default {
  components: {
    addPosition
  },
  props: ['obj', 'state'],
  data() {
    return {
      params: {
        remarks_id: []
      },
      supervisor: '',
      division: '',
      isEditPosition: false,
      position: '',
      min_awol: '',
      max_awol: '',
      positionParams: [],
      divisionParams: [],
      placeParams: [],
      supervisorParams: [],
      isLoading: false,
      isCancel: false,
      isRemark: false,
      isRestore: false,
      included_fields: '',
      cause: ''
    }
  },
  beforeMount() {
    if (this.obj.date_to == 'Invalid Date') {
      this.obj.date_to = ''
    }
    if (this.obj.date_seperation == 'Invalid Date') {
      this.obj.date_seperation = ''
    }

    if (!this.obj.position) {
      this.obj.position = ''
    }
    if (!this.obj.division) {
      this.obj.division = ''
    }
    if (!this.obj.place_of_work) {
      this.obj.place_of_work = ''
    }
    if (!this.obj.supervisor) {
      this.obj.supervisor = ''
    }
    this.checkRemark(this.obj.remarks_id)
  },
  mounted() {
    setTimeout(() => {
    this.isLoading = true
    if (this.obj.designation) {
      this.position = this.obj.designation.title
      this.supervisor = this.obj.designation.employee.name
      this.division = this.obj.division
    }
    // this.obj.assigned_to = 'Department of Transportation'
    let config = {
      headers: {'Authorization': "Bearer " + this.$store.state.token}
    };

    this.$http.get(`${this.$host}/api/parameter/service_record`,
      config
    ).then((response) => {
      this.params = response.data.data
      //console.log(JSON.stringify(this.params))
      this.getParams()
      // this.searchLoad = false
    }).catch((error) => {
      //console.log(error)
      this.getParams()
    });

    if (this.state == 'EDIT') {
      if (this.obj.awol_at != null) {
        this.min_awol = new Date(this.obj.awol_at.substr(0, 9))
        this.max_awol = new Date(this.obj.awol_at.substr(13, 23))
      }

      let config = {
        headers: {'Authorization': "Bearer " + this.$store.state.token}
      };

      this.$http.get(`${this.$host}/api/position/search/${this.obj.position_id}`,
        config
      ).then((response) => {
        this.division = response.data.data.division.name
        // this.supervisor = response.data.data.supervisor.title
        this.position = response.data.data.title
        this.obj.assigned_supervisor = response.data.data.supervisor.supervisor_id
        this.obj.classification = response.data.data.classification
        this.obj.level = response.data.data.level
        this.getParams()
      }).catch((error) => {
        //console.log(error)
        this.getParams()
      });
    }

    if (this.obj.show_in_reports == '') {
      this.obj.show_in_reports = 0
    }
    if (this.obj.is_uniformed == '') {
      this.obj.is_uniformed = 0
    }
    if (this.obj.date_hired == '') {
      this.obj.date_hired = null
    }


    this.isLoading = true }, 250)
  },
  computed: {
    sorted_remarks() {
      return this.params.remarks_id.sort((a,b) => {
        const codeA = a.code.toUpperCase();
        const codeB = b.code.toUpperCase();
        // //console.log(codeA,codeB);
        let comparison = 0
        if (codeA > codeB)
          comparison = 1
        else if (codeA < codeB) {
          comparison = -1;
        }
        return comparison
      });
    },
    filteredPosition() {
      return this.positionParams.filter((option) => {
        return option
          .toString()
          .toLowerCase()
          .indexOf(this.obj.position.toLowerCase()) >= 0
      })
    },
    filteredDivision() {
      return this.divisionParams.filter((option) => {
        return option
          .toString()
          .toLowerCase()
          .indexOf(this.obj.division.toLowerCase()) >= 0
      })
    },
    filteredPlace() {
      return this.placeParams.filter((option) => {
        return option
          .toString()
          .toLowerCase()
          .indexOf(this.obj.place_of_work.toLowerCase()) >= 0
      })
    },
    filteredSupervisor() {
      return this.supervisorParams.filter((option) => {
        return option
          .toString()
          .toLowerCase()
          .indexOf(this.obj.supervisor.toLowerCase()) >= 0
      })
    }
  },
  methods: {
    checkRemark(id) {
      if (id<=3 || (id>=5 && id<=14) || id==16) {
        this.included_fields = [
          'designation',
          'status',
          'salary',
          'station',
          'place_of_work',
          'supervisor',
          'classification',
          'level'
        ]
        this.isCancel = false
        this.isRemark = false
        this.obj.cancellation_reason = null
        this.obj.date_seperation = null
        this.obj.cause = null
      } else if (id==24) {
        this.included_fields = [
          'lwop_from',
          'lwop_to'
        ]
        this.isCancel = false
        this.isRemark = false
        this.obj.cancellation_reason = null
        this.obj.date_seperation = null
        this.obj.cause = null
      } else if (id==4 || (id>=17 && id<=20) || (id>=22 && id<=25)) {
        this.included_fields = [
          'date_separation',
          'cause_separation'
        ]
        this.isCancel = false
        this.isRemark = false
        this.obj.cancellation_reason = null
      } else if (id == 26) {
        this.included_fields = []
        this.isCancel = true
        this.isRemark = false
        this.isRestore = false
      }
      else if (id == 27) {
        this.isRemark = true
        this.isCancel = false
        this.isRestore = false
      }
      else if (id == 28) {
        this.included_fields = []
        this.isRestore = true
        this.isCancel = false
        this.isRemark = false
      } else {
        this.isReestore = false
        this.isCancel = false
        this.isRemark = false
        this.obj.cancellation_reason = null
      }
    },
    getParams() {
      let config = {
        headers: {'Authorization': "Bearer " + this.$store.state.token}
      };

      this.$http.get(`${this.$host}/api/parameter/service-record/position/`,
        config
      ).then((response) => {
        let data = response.data
        this.positionParams = data.position
        this.divisionParams = data.division
        this.placeParams = data.place_of_work
        this.supervisorParams = data.supervisor
        this.isLoading = false
      }).catch((error) => {
        //console.log(error)
        this.isLoading = false
      });
    },
    getEmployeeFromPosition(id) {
      let config = {
        headers: {'Authorization': "Bearer " + this.$store.state.token}
      };

      this.$http.get(`${this.$host}/api/parameter/service-record/position/${id}`,
        config
      ).then((response) => {
        if (response.data.name) {
          this.supervisor = response.data.name
          this.obj.supervisor = response.data.name
          this.obj.assigned_supervisor = response.data.id
        }
        else {
          this.supervisor = ''
          this.obj.supervisor = ''
          this.obj.assigned_supervisor = ''
        }
        this.isEditPosition = false
      }).catch((error) => {
        //console.log(error)
        this.isEditPosition = false
      });
    },
    parseDate(d) {
      let parts = d.split("/")
      let dt = new Date(parseInt(parts[2], 10),
        parseInt(parts[1], 10) - 1,
        parseInt(parts[0], 10))
      return dt
    },
    save(obj) {
      //console.log("CAUSE: " + this.cause)
      //console.log("OBJ: " + obj)
      if(this.cause != "Custom"){
        obj.cause = this.cause;
      }
      if (this.min_awol == '' || this.max_awol == '') {

      }
      else {
        this.min_awol = moment(this.min_awol).format("YYYY-MM-DD");
        this.max_awol = moment(this.max_awol).format("YYYY-MM-DD");
        obj.awol_at = this.min_awol + ' - ' + this.max_awol;
      }
      if (obj.date_to == '' || !obj.date_to) {
        obj.date_to = null
      }
      //console.log(obj)
      this.$emit('save', obj)
      // //console.log(JSON.stringify(obj))
    },
    setPosition(obj) {
      this.position = obj.title
      this.obj.position = obj.title
      this.obj.position_id = obj.id
      this.division = obj.division.name
      this.obj.division = obj.division.name
      this.obj.assigned_to = obj.division.id
      this.obj.salary = obj.salary
      this.obj.classification = obj.classification
      this.obj.level = obj.level
      this.close(obj.id)
    },
    close(id) {
      this.getEmployeeFromPosition(id)
    },
    clearPosition() {
      this.position = null
      this.obj.position_id = 'none'
    }
  }
}
</script>

<style>
@media screen and (min-width: 1px) and (max-width: 1215px)  {
  .modal-card {
    margin-left: 0px !important;
    width: 100% !important;
  }
  .pos {
    width: none;
  }
}
.pos {
  margin-top: 70px !important;
}
</style>
