<template>
  <div class="box">
    <div>
      <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
      <div style="margin-bottom: 0.75rem;">
        <button class="button is-primary" @click.prevent="printSelected()">
          <span><i class="fa fa-print"></i> Print Selected</span>
        </button>
        <button class="button is-danger" @click.prevent="clearSelected()">
          <span><i class="fa fa-times"></i> Clear Selected</span>
        </button>
      </div>
      <b-field label="">
        <b-select v-model="perPage">
          <option value="10">10 per page</option>
          <option value="50">50 per page</option>
          <option value="100">100 per page</option>
          <option value="1000">1000 per page</option>
        </b-select>
        <b-input v-model="search_query" placeholder="Search Employee" />
      </b-field>
      <hr>
      <b-table
        :data="filter"
        focusable
        :paginated="true"
        :per-page="perPage"
        :checked-rows.sync="checkedRows"
        :bordered="true"
        checkable>
        <template slot-scope="props">
          <b-table-column label="Emp. No.">
            {{ props.row.employee_number }}
          </b-table-column>
          <b-table-column label="First Name">
            {{ props.row.first_name }}
          </b-table-column>
          <b-table-column label="Middle Name">
            {{ props.row.middle_name }}
          </b-table-column>
          <b-table-column label="Last Name">
            {{ props.row.last_name }}
          </b-table-column>
          <b-table-column label="Name Ext.">
            {{ isNull(props.row.name_extension) }}
          </b-table-column>
          <b-table-column label="Date of Birth" width="150">
            {{ props.row.birth_date }}
          </b-table-column>
          <b-table-column label="Division">
            {{ props.row.division }}
          </b-table-column>
          <!-- <b-table-column field="updated_at" label="Updated" sortable centered>
            {{ yesNo(props.row.updated_at) }}
          </b-table-column> -->
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: true,
      search_query: '',
      data: [],
      checkedRows: [],
      perPage: 10
    }
  },
  mounted() {
    let config = {
      headers: {'Authorization': "Bearer " + this.$store.state.token}
    };

    this.$http.get(`${this.$host}/api/employee/search`,
      config
    ).then((response) => {
      let data = response.data.data
      this.data = data
      this.isLoading = false
    }).catch((error) => {
      //console.log(error)
    });
  },
  computed: {
    filter() {
      var i;
      var name_re = new RegExp(this.search_query, 'i')
      var data = []
      for (i in this.data) {
        if ([this.data[i].employee_number, this.data[i].first_name, this.data[i].middle_name, this.data[i].last_name, this.data[i].division].join(' ').match(name_re)) {
          data.push(this.data[i])
        }
      }
      return data
    }
  },
  methods: {
    clearSelected() {
      if (this.checkedRows.length > 0) {
        this.$dialog.confirm ({
          title: 'Clear Selected Rows',
          message: 'Are you sure you want to <b>clear</b> the selected rows? This action cannot be undone.',
          confirmText: 'Confirm',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: () => this.checkedRows = []
        })
      }
    },
    printSelected() {
      if (this.checkedRows.length < 1) {
        this.$toast.open({
          message: 'No data selected.',
          type: 'is-danger',
          position: 'is-bottom-right',
          duration: 4000
        })
      }
      // else if (this.checkedRows.length == 1) {
      //   let url = `${this.$host}/api/report/pds/${this.checkedRows[0].id}`
      //   let win = window.open(url, '_blank')
      //   win.focus()
      // }
      else {
        let config = {
          headers: {'Authorization': "Bearer " + this.$store.state.token}
        };

        this.$http.post(`${this.$host}/api/report/generate/${this.$role.state.id}`,
          {
            employee_id: JSON.stringify(this.checkedRows.map(x => x.id))
          },
          config
        ).then((response) => {
          this.isLoading = false
          let token = response.data

          let url = `${this.$host}/api/report/mass/pds/${token}`
          let win = window.open(url, '_blank')
          win.focus()
          this.isPrint = false
        }).catch((error) => {
          //console.log(error)
          this.isLoading = false
        });
      }
    },
    isNull(val) {
      if (val == 'null') {
        return ''
      }
      else {
        return val
      }
    }
  }
}
</script>
