<template>
  <div>
    <div class="columns header" style="margin-bottom: 0 !important;">
      <div class="column is-5" style="margin-left: 5px; font-size: 20px; text-transform: uppercase;">
        <b>MANAGE PAYROLL</b> {{ fullName }}
        <b-tag type="is-success is-small" v-if="ot_allowed">OT Allowed: {{ ot_allowed }} hours</b-tag>
      </div>
      <div class="column is-auto">
        <button class="button is-primary is-hidden-tablet" style="margin-left: 10px;" @click.prevent="$router.push('/payroll')"
        v-if="$role.state.permissions['write payroll'] || $role.state.permissions['write payroll funding source']">
          <i class="fa fa-user"></i> &nbsp;My Payroll
        </button>
        <button class="button is-primary is-hidden-tablet" style="margin-left: 10px;" @click.prevent="openEncodeOR()"
        v-if="$role.state.permissions['write payroll'] || $role.state.permissions['write payroll funding source']">
          <i class="fa fa-user"></i> &nbsp;Encode OR
        </button>
        <button class="button is-primary is-hidden-tablet" style="margin-left: 10px;" @click.prevent="$router.push('/payroll/finalize')"
        v-if="$role.state.permissions['write payroll'] || $role.state.permissions['write payroll funding source']">
          <i class="fa fa-wallet"></i> &nbsp;Finalize Payroll
        </button>
        <button class="button is-primary is-hidden-tablet" style="margin-left: 10px;" @click.prevent="openRunPayroll()"
        v-if="$role.state.permissions['write payroll'] || $role.state.permissions['write payroll funding source']">
          <i class="fa fa-play"></i> &nbsp;Run Payroll
        </button>
        <button class="button is-primary is-hidden-tablet" style="margin-left: 10px;"
        v-if="$role.state.permissions['write payroll'] || $role.state.permissions['write payroll funding source']"
        @click.prevent="isSearch = true">
          <i class="fa fa-search"></i> &nbsp;Search Employee
        </button>

        <button class="button is-primary is-pulled-right is-hidden-mobile" style="margin-left: 10px;" @click.prevent="openEncodeOR()"
        v-if="$role.state.permissions['write payroll'] || $role.state.permissions['write payroll funding source']">
          <i class="fa fa-play"></i> &nbsp;Encode OR
        </button>
        <button class="button is-primary is-pulled-right is-hidden-mobile" style="margin-left: 10px;"
        v-if="$role.state.permissions['write payroll'] || $role.state.permissions['write payroll funding source']"
        @click.prevent="isSearch = true">
          <i class="fa fa-search"></i> &nbsp;Search Employee
        </button>
        <button class="button is-primary is-pulled-right is-hidden-mobile" style="margin-left: 10px;" @click.prevent="openRunPayroll()"
        v-if="$role.state.permissions['write payroll'] || $role.state.permissions['write payroll funding source']">
          <i class="fa fa-play"></i> &nbsp;Run Payroll
        </button>
        <button class="button is-primary is-pulled-right is-hidden-mobile" style="margin-left: 10px;" @click.prevent="$router.push('/payroll/finalize')"
        v-if="$role.state.permissions['write payroll'] || $role.state.permissions['write payroll funding source']">
          <i class="fa fa-wallet"></i> &nbsp;Finalize Payroll
        </button>
        <button class="button is-primary is-pulled-right is-hidden-mobile" style="margin-left: 10px;" @click.prevent="$router.push('/payroll')"
        v-if="$role.state.permissions['write payroll'] || $role.state.permissions['write payroll funding source']">
          <i class="fa fa-user"></i> &nbsp;My Payroll
        </button>
      </div>
    </div>
    <hr style="margin-top: 0 !important; margin-bottom: 0 !important;" class="has-background-grey-lighter is-hidden-mobile">
    <br>
    <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>

    <div class="box">
      <b-field>
        <button class="button is-primary" @click.prevent="editPayrollSettings()">
          <span><i class="fa fa-cog"></i> Edit Payroll Setting</span>
        </button>&nbsp;
        <button class="button is-primary" @click.prevent="editBankAccount()">
          <span><i class="fa fa-id-card"></i> Edit Bank Account</span>
        </button>&nbsp;
      </b-field>
      <hr>
      <div class="columns">
        <div class="column is-3">
          <b>Bank Account: </b>
          <!-- <span class="disable-select"
          style="text-shadow: 0 0 8px grey; color: transparent; cursor: pointer;">0000-0000-0000-0000-00</span> -->
          <span v-if="!hideBankAccount">
            {{ settings.bank_account }}
            <button class="button is-primary is-small" @click.prevent="viewBankAccount()">
              <span><i class="fa fa-eye"></i></span>
            </button>
          </span>
          <span v-if="!settings.hasBankAccount"></span>
          <br>
          <b>Bank Name: </b> {{ settings.bank_name }} <br>
          <b>Bank Branch: </b> {{ settings.bank_branch }} <br>
          <b>UMID: </b> {{ settings.umid }} <br>
          <b>Date Hired: </b> {{ settings.date_hired }} <br>
          <b>Date Hired Until: </b> {{ settings.date_end_hired }} <br>
        </div>
        <div class="column is-3">
          <span v-if="settings.hasOwnProperty('sss_contribution')"><b>SSS Contribution: </b> {{ settings.sss_contribution }} <br></span>
          <b>Buffer Tax: </b> {{settings.buffer_tax}} <br />
          <b>PAGIBIG Contribution: </b> {{ settings.pagibig_contribution }} <br>
          <span v-if="settings.funding_source !== null"><b>Project: </b> {{ settings.funding_source }} <br></span>
          <b>Has Exemption: </b> {{ yesNo(settings.has_exemption) }} <br>
          <span v-if="settings.ewt_percentage"><b>EWT Percentage: </b> {{ settings.ewt_percentage }}% <br></span>
          <span v-if="settings.gmp_percentage"><b>GMP Percentage: </b> {{ settings.gmp_percentage }}% <br></span>
          <span v-if="settings.gmp_percentage"><b>CVAT Percentage: </b> {{ settings.cvat_percentage }}% <br></span>
        </div>
        <div class="column is-3">
          <b>Part of Payroll: </b> {{ yesNo(settings.is_part_of_payroll) }} <br>
          <b>GSIS Deduction: </b> {{ yesNo(settings.is_gsis_deduction) }} <br>
          <b>PHILHEALTH Deduction: </b> {{ yesNo(settings.is_philhealth_deduction) }} <br>
          <b>PAGIBIG Deduction: </b> {{ yesNo(settings.is_pagibig_deduction) }} <br>
        </div>
        <div class="column is-3">
          <b>Monthly Pay: </b> {{ settings.monthly }}<br>
          <b>Daily Pay: </b> {{ settings.daily }}<br>
          <b>Hourly Pay: </b> {{ settings.hourly }}<br>
          <span v-if="settings.hours_per_month"><b>Hours Per Month: </b> {{ settings.hours_per_month }}<br></span>
        </div>
      </div>
    </div>

    <div class="box" v-if="settings.schedule">
      <b>Schedule: </b> {{ settings.schedule }}<br>
      <div style="overflow-x:auto;">
      <table class="table" style="width: 100%;">
        <thead>
            <th style="text-align: left;padding: 8px;">MONDAY</th>
            <th style="text-align: left;padding: 8px;">TUESDAY</th>
            <th style="text-align: left;padding: 8px;">WEDNESDAY</th>
            <th style="text-align: left;padding: 8px;">THURSDAY</th>
            <th style="text-align: left;padding: 8px;">FRIDAY</th>
            <th style="text-align: left;padding: 8px;">SATURDAY</th>
            <th style="text-align: left;padding: 8px;">SUNDAY</th>
        </thead>
        <tbody>
          <tr>
            <td style="text-align: left;padding: 8px;">{{ settings.mon_schedule }}</td>
            <td style="text-align: left;padding: 8px;">{{ settings.tue_schedule }}</td>
            <td style="text-align: left;padding: 8px;">{{ settings.wed_schedule }}</td>
            <td style="text-align: left;padding: 8px;">{{ settings.thu_schedule }}</td>
            <td style="text-align: left;padding: 8px;">{{ settings.fri_schedule }}</td>
            <td style="text-align: left;padding: 8px;">{{ settings.sat_schedule }}</td>
            <td style="text-align: left;padding: 8px;">{{ settings.sun_schedule }}</td>
          </tr>
        </tbody>
      </table>
      </div>
    </div>
    <div class="box">
      <b>Service Record</b>
      <div style="overflow-x:auto;">
        <table class="table" style="width: 100%;">
          <thead>
            <th>FROM</th>
            <th>TO</th>
            <th>DESIGNATION</th>
            <th>STATUS</th>
            <th>SALARY</th>
            <th>REMARKS</th>
          </thead>
          <tbody>
            <tr v-for="sr in service_records" v-bind:key="sr.id">
              <td>{{sr.date_from}}</td>
              <td>{{sr.date_to}}</td>
              <td>{{sr.position}}</td>
              <td>{{sr.employment_status_description}}</td>
              <td>{{sr.salary}}</td>
              <td>{{sr.remarks_description}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="box">
      <b>Earnings</b>
      <br>
      <b-field>
        <button class="button is-success" @click.prevent="addEarning()">
          <span><i class="fa fa-plus"></i> Add Earning</span>
        </button>&nbsp;
        <b-input v-model="searchEarnings" placeholder="Search"></b-input>
      </b-field>
      <hr>
      <b-table
        :data="filterEarnings"
        :striped=true
        :bordered=true
        :hoverable=true
        :paginated="true"
        :per-page="perPage">
        <template slot-scope="props">
          <b-table-column label="Reference Date">
            {{ props.row.ref_date }}
          </b-table-column>
          <b-table-column label="Earning">
            {{ props.row.earning }}
          </b-table-column>
          <b-table-column label="Earning Frequency">
            {{ props.row.earning_frequency }}
          </b-table-column>
          <b-table-column label="Payroll Type">
            {{ props.row.payroll_type }}
          </b-table-column>
          <b-table-column label="Amount">
            {{ toFormula(props.row.amount, props.row.amount_type) }}
          </b-table-column>
          <b-table-column label="Amount Taxable">
            {{ toFormula(props.row.amount_taxable, props.row.amount_type) }}
          </b-table-column>
          <b-table-column label="Actions" v-if="$role.state.permissions['write payroll'] || $role.state.permissions['write payroll funding source']">
            <button class="button btn-action is-primary is-small" @click.prevent="editEarning(props.row)"><i class="fa fa-pen"></i></button>
            <button class="button btn-action is-danger is-small" @click.prevent="deleteEarning(props.row.id)"><i class="fa fa-trash"></i></button>
          </b-table-column>
        </template>
        <template slot="empty">
          <b-table-column label="Reference Date">
          </b-table-column>
          <b-table-column label="Earning">
          </b-table-column>
          <b-table-column label="Earning Frequency">
          </b-table-column>
          <b-table-column label="Payroll Type">
          </b-table-column>
          <b-table-column label="Amount">
          </b-table-column>
          <b-table-column label="Amount Taxable">
          </b-table-column>
          <b-table-column label="Actions" v-if="$role.state.permissions['write payroll'] || $role.state.permissions['write payroll funding source']">
          </b-table-column>
        </template>
      </b-table>
    </div>

    <div class="box">
      <b>Deductions</b>
      <br>
      <b-field>
        <button class="button is-success" @click.prevent="addDeduction()">
          <span><i class="fa fa-plus"></i> Add Deduction</span>
        </button>&nbsp;
        <b-input v-model="searchDeductions" placeholder="Search"></b-input>
        <b-select placeholder="Active?" v-model="active">
          <option value="all">All</option>
          <option value="1">Active</option>
          <option value="0">Inactive</option>
        </b-select>
      </b-field>
      <hr>
      <b-table
        :data="filterDeductions"
        :striped=true
        :bordered=true
        :hoverable=true
        :paginated="true"
        :per-page="perPage"
        detailed
        :has-detailed-visible="(row) => row.deduction_frequency == 'Diminishing'"
        detail-key="id">
        <template slot-scope="props">
          <b-table-column label="Reference Date">
            {{ props.row.ref_date }}
          </b-table-column>
          <b-table-column label="Deduction">
            {{ props.row.deduction }}
          </b-table-column>
          <b-table-column label="Deduction Frequency">
            {{ props.row.deduction_frequency }}
          </b-table-column>
          <b-table-column label="Payroll Type">
            {{ props.row.payroll_type }}
          </b-table-column>
          <b-table-column label="Amount">
            {{ props.row.amount==null?props.row.monthly:toFormula(props.row.amount, props.row.amount_type) }}
          </b-table-column>
          <b-table-column label="Balance">
            {{ props.row.balance }}
          </b-table-column>
          <b-table-column label="Actions" v-if="$role.state.permissions['write payroll'] || $role.state.permissions['write payroll funding source']">
            <button class="button btn-action is-primary is-small" @click.prevent="editDeduction(props.row)"><i class="fa fa-pen"></i></button>
            <button class="button btn-action is-primary is-small" @click.prevent="renewDeduction(props.row.id)" v-if="props.row.deduction_frequency == 'Diminishing'" :disabled="!props.row.is_active"><i class="fa fa-sync-alt"></i></button>
            <button class="button btn-action is-danger is-small" @click.prevent="deleteDeduction(props.row.id)"><i class="fa fa-trash"></i></button>
            <button class="button btn-action is-danger is-small" @click.prevent="cancelDeduction(props.row.id)" v-if="props.row.is_active == 1"><i class="fa fa-ban"></i></button>
            <button class="button btn-action is-primary is-small" @click.prevent="activeDeduction(props.row.id)" v-if="props.row.is_active == 0"><i class="fas fa-plus-square"></i></button>
          </b-table-column>
        </template>
        <template slot="empty">
          <b-table-column label="Reference Date">
          </b-table-column>
          <b-table-column label="Deduction">
          </b-table-column>
          <b-table-column label="Deduction Frequency">
          </b-table-column>
          <b-table-column label="Payroll Type">
          </b-table-column>
          <b-table-column label="Amount">
          </b-table-column>
          <b-table-column label="Actions" v-if="$role.state.permissions['write payroll'] || $role.state.permissions['write payroll funding source']">
          </b-table-column>
        </template>
        <template slot="detail" slot-scope="props">
          <article class="media">
            <div class="media-content">
              <div class="content">
                <div class="is-hidden-touch">
                  <span style="margin-right: 10px;"><b>Start Date: </b> {{ props.row.start_date }}</span>
                  <span style="margin-right: 10px;"><b>End Date: </b> {{ props.row.end_date }}</span> <br>
                  <span style="margin-right: 10px;"><b>Principal: </b> {{ props.row.principal }}</span>
                  <span style="margin-right: 10px;"><b>Monthly: </b> {{ props.row.monthly }}</span>
                  <span style="margin-right: 10px;"><b>Balance: </b> {{ props.row.balance }}</span>
                  <br>
                </div>
                <div class="is-hidden-desktop">
                  <b>Start Date: </b> {{ props.row.start_date }} <br>
                  <b>End Date: </b> {{ props.row.end_date }} <br>
                  <b>Principal: </b> {{ props.row.principal }} <br>
                  <b>Monthly: </b> {{ props.row.monthly }} <br>
                  <b>Balance: </b> {{ props.row.balance }} <br>
                </div>
                <br>
                <b-table
                  :data="props.row.diminishingledger"
                  :bordered=true
                  :hoverable=true
                  :paginated=true
                  :per-page=5
                  :pagination-simple=true>
                  <template slot-scope="props">
                    <b-table-column label="Reference Date">
                      {{ props.row.ref_date }}
                    </b-table-column>
                     <b-table-column label="Amount">
                      {{ props.row.amount }}
                    </b-table-column>
                    <b-table-column label="Actions" v-if="$role.state.permissions['write payroll'] || $role.state.permissions['write payroll funding source']">
                      <button class="button btn-action is-primary is-small" @click.prevent="editDiminishing(props.row)"><i class="fa fa-pen"></i></button>
                      <button class="button btn-action is-danger is-small" @click.prevent="deleteDiminishing(props.row.id)"><i class="fa fa-trash"></i></button>
                    </b-table-column>
                  </template>
                  <template slot="empty">
                    <b-table-column label="Reference Date">
                    </b-table-column>
                     <b-table-column label="Amount">
                    </b-table-column>
                    <b-table-column label="Actions" v-if="$role.state.permissions['write payroll'] || $role.state.permissions['write payroll funding source']">
                    </b-table-column>
                  </template>
                </b-table>
              </div>
            </div>
          </article>
        </template>
      </b-table>
    </div>

    <div class="box">
        <b>Payroll Records</b>
        <button class="button is-success is-pulled-right" @click.prevent="overpaymentPopup()" v-if="this.settings.date_end_hired != 'Active'">OVERPAYMENT</button>
      <hr>
      <b-field grouped group-multiline>
        <b-select placeholder="Per Page" v-model="perPage">
          <option value="12">12 per page</option>
          <option value="24">24 per page</option>
          <option value="36">36 per page</option>
          <option value="48">48 per page</option>
        </b-select>
        <b-select placeholder="finalized" v-model="is_finalize" @input="getLedger()">
          <option value="1">FINALIZED</option>
          <option value="0">NOT FINALIZED</option>
        </b-select>
        <!-- <b-select placeholder="PERIOD">
          <option value=""></option>
        </b-select> -->
        <b-field>
          <b-input placeholder="Year" type="number" @input="changeYear()" v-model="year"></b-input>
        </b-field>
      </b-field>


      <b-table
        :data="ledger"
        :striped=true
        :bordered=true
        :hoverable=true
        :paginated="true"
        :per-page="perPage">
        <template slot-scope="props">
          <b-table-column label="Period">
            {{ props.row.period }}
          </b-table-column>
          <b-table-column label="Type" field="type" sortable>
            {{ props.row.type }}
          </b-table-column>
          <b-table-column label="Gross Pay">
            {{ props.row.gross_pay }}
          </b-table-column>
          <b-table-column label="Total Deductions">
            {{ props.row.total_deductions }}
          </b-table-column>
          <b-table-column label="Net Pay">
            {{ props.row.net_pay }}
          </b-table-column>
          <b-table-column label="Actions">
            <button class="button btn-action is-primary is-small" @click.prevent="viewPayslip(props.row)"><i class="fa fa-receipt"></i></button>
            <button class="button btn-action is-primary is-small" @click.prevent="deletePayslip(props.row)" v-if="is_finalize==0"><i class="fa fa-trash"></i></button>
            <button class="button btn-action is-primary is-small" @click.prevent="showUpdatePayslip(props.row)" v-if="is_finalize==0 && ($role.state.permissions['update payroll'] || $role.state.permissions['update payroll funding source'])"><i class="fa fa-pen"></i></button>
            <button class="button btn-action is-primary is-small" @click.prevent="unfinalizePayroll(props.row)" v-if="is_finalize==1 && ($role.state.permissions['unfinalize payroll'] || $role.state.permissions['unfinalize payroll funding source'])"><i class="fa fa-ban"></i></button>
          </b-table-column>
        </template>
        <template slot="empty">
          <b-table-column label="Period">
          </b-table-column>
          <b-table-column label="Type" field="type" sortable>
          </b-table-column>
          <b-table-column label="Gross Pay">
          </b-table-column>
          <b-table-column label="Total Deductions">
          </b-table-column>
          <b-table-column label="Net Pay">
          </b-table-column>
          <b-table-column label="Actions">
          </b-table-column>
        </template>
      </b-table>

      <b-modal :active.sync="isRecords" has-modal-card :canCancel=false>
        <transition name="fade">
          <form>
            <div class="modal-card pos" style="width: 60rem; height: 35.625rem; margin-left: 100px;">
              <header class="modal-card-head">
                <p class="modal-card-title">View Payslip</p>
              </header>
              <section class="modal-card-body">
                <div class="columns is-vcentered is-centered" style="font-size: 13px;">
                  <div class="column is-narrow is-hidden-touch" style="padding: 0px !important;">
                    <img src="/img/dotr-logo.png" width="40"/>
                  </div>
                  <div class="column is-narrow">
                    <div>
                      <span style="text-align: center;"><b>Department of Transportation<br></b></span>
                      <span style="text-align: center;"><b>Employee Payslip</b></span>
                    </div>
                  </div>
                </div>
                <hr style="margin-top: 0px; margin-bottom: 10px; background-color: grey">
                <div class="columns" style="font-size: 13px;">
                  <div class="column is-6">
                    <b>Employee Name:</b> {{ payslip.name }} <br>
                    <b>Organization Unit:</b> {{ payslip.division }}
                  </div>
                  <div class="column is-6">
                    <b>Employee Number:</b> {{ payslip.employee_number }} <br>
                    <b>Pay Period:</b> {{ payslip.payroll_period }}
                  </div>
                </div>
                <div class="columns" style="font-size: 13px; min-height: 14.5rem;">
                  <div class="column is-3">
                    <b>Basic Pay</b>
                    <hr style="margin-top: 10px; margin-bottom: 10px; background-color: grey">
                    <table style="border: 0px; width: 100%;">
                      <tr v-for="option in payslip.basic_pay" :key="option.key">
                        <td>{{ option.key }} <a @click.prevent="showDTR(option.dtr)" v-if="option.dtr"><i class="far fa-calendar"></i></a></td>
                        <td style="text-align: right;">{{ option.value }}</td>
                      </tr>
                    </table>
                  </div>
                  <div class="column is-3">
                    <b>Other Earnings</b>
                    <hr style="margin-top: 10px; margin-bottom: 10px; background-color: grey">
                    <table style="border: 0px; width: 100%;">
                      <tr v-for="option in payslip.other_earnings" :key="option.key">
                        <td>{{ option.key }} <a @click.prevent="showDTR(option.dtr)" v-if="option.dtr"><i class="far fa-calendar"></i></a></td>
                        <td style="text-align: right;">{{ option.value }}</td>
                      </tr>
                    </table>
                  </div>
                  <div class="column is-3">
                    <b>Regular Deductions</b>
                    <hr style="margin-top: 10px; margin-bottom: 10px; background-color: grey">
                    <table style="border: 0px; width: 100%;">
                      <tr v-for="option in payslip.regular_deductions" :key="option.key">
                        <div v-if="option.key == 'EWT'">
                          <td>{{ option.key + ' (' + ewt_percentage + '%)'}}</td>
                        </div>
                        <div v-else-if="option.key == 'GMP'">
                           <td>{{ option.key + ' (' + gmp_percentage + '%)'}}</td>
                        </div>
                        <div v-else-if="option.key == 'CVAT'">
                           <td>{{ option.key + ' (' + cvat_percentage + '%)'}}</td>
                        </div>
                        <div v-else>
                          <td>{{ option.key }}</td>
                        </div>
                        <td style="text-align: right;">{{ option.value }}</td>
                      </tr>
                    </table>
                  </div>
                  <div class="column is-3">
                    <b>Loan and Other Deductions</b>
                    <hr style="margin-top: 10px; margin-bottom: 10px; background-color: grey">
                    <table style="border: 0px; width: 100%;">
                      <tr v-for="option in payslip.other_deductions" :key="option.key">
                        <td>{{ option.key }} <a @click.prevent="showDTR(option.dtr)" v-if="option.dtr"><i class="far fa-calendar"></i></a></td>
                        <td style="text-align: right;">{{ option.value }}</td>
                      </tr>
                    </table>
                  </div>
                </div>
                <hr style="margin-top: 0px; margin-bottom: 10px; background-color: grey">
                <div class="columns" style="font-size: 13px;">
                  <div class="column is-3" style="padding-bottom: 0rem;">
                    <b>Gross Pay:</b> {{ payslip.gross_pay }}
                  </div>
                  <div class="column is-3" style="padding-bottom: 0rem;">
                    <b>Total Deduction:</b> {{ payslip.total_deductions }}
                  </div>
                  <div class="column is-3" style="padding-bottom: 0rem;">
                  </div>
                  <div class="column is-3" style="padding-bottom: 0rem;">
                    <b>Net Pay:</b> {{ payslip.net_pay }}
                  </div>
                </div>
                <div class="columns" style="font-size: 13px;" v-if="this.payroll_type !== 'SPECIAL' && employment_status<10 || employment_status>11">
                  <div class="column is-3" style="padding-top: 0rem;">
                  </div>
                  <div class="column is-3" style="padding-top: 0rem;">
                    <b>1 - 15th:</b> {{ payslip['1st_half'] }}
                  </div>
                  <div class="column is-3" style="padding-top: 0rem;">
                  </div>
                  <div class="column is-3" style="padding-top: 0rem;">
                    <b>16 - 31st:</b> {{ payslip['2nd_half'] }}
                  </div>
                </div>
                <hr style="margin-top: 0px; margin-bottom: 10px; background-color: grey">
                <div style="font-size: 12px;">
                  <i>*** This is a system-generated report. If issued without alteration, this does not require a signature. <br>
                  Any data subject for correction/adjustment, please notify Payroll-Treasury Division immediately. ***</i>
                </div>

              </section>
              <footer class="modal-card-foot">
                <button class="button is-primary" v-if="$role.state.permissions['print payslip']" @click.prevent="printPS()"><i class="fa fa-print"></i>&nbsp;Print</button>
                <button class="button is-danger" @click.prevent="isRecords = false">Close</button>
              </footer>
            </div>
          </form>
        </transition>
      </b-modal>
    </div>

    <b-modal :active.sync="isPayrollSetting" has-modal-card :canCancel=false>
      <transition name="fade">
        <form @submit.prevent="savePayrollSettings()">
          <div class="modal-card" style="height: 35.625rem; margin-top: 4rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">Payroll Setting</p>
            </header>
            <section class="modal-card-body">
              <b-field label="Bank Name">
                <b-input
                  v-model="settings.bank_name"
                  expanded>
                </b-input>
              </b-field>
              <b-field label="Bank Branch">
                <b-input
                  v-model="settings.bank_branch"
                  expanded>
                </b-input>
              </b-field>
              <b-field label="UMID">
                <b-input
                  v-model="settings.umid"
                  expanded>
                </b-input>
              </b-field>
              <b-field label="SSS Contribution">
                <b-input
                  v-model="settings.sss_contribution"
                  type="number" step="0.01"
                  expanded>
                </b-input>
              </b-field>
              <b-field label="Buffer Tax">
                <b-input
                  v-model="settings.buffer_tax"
                  type="text" expanded>
                </b-input>
              </b-field>
              <b-field label="PAGIBIG Contribution">
                <b-input
                  v-model="settings.pagibig_contribution"
                  type="number" step="0.01"
                  expanded>
                </b-input>
              </b-field>
              <b-field>
                <b-checkbox true-value="1" false-value="0" v-model="settings.has_exemption">Has Exemption</b-checkbox>
              </b-field>
              <b-field label="EWT Percentage">
                <b-input
                  v-model="settings.ewt_percentage"
                  type="number" step="0.01"
                  expanded>
                </b-input>
              </b-field>
              <b-field label="GMP Percentage">
                <b-input
                  v-model="settings.gmp_percentage"
                  type="number" step="0.01"
                  expanded>
                </b-input>
              </b-field>
              <b-field label="CVAT Percentage">
                <b-input
                  v-model="settings.cvat_percentage"
                  type="number" step="0.01"
                  expanded>
                </b-input>
              </b-field>
              <!-- <b-field label="Hours Per Month" v-if="settings.hours_per_month">
                <b-input
                  v-model="settings.hours_per_month"
                  type="number"
                  expanded>
                </b-input>
              </b-field> -->
              <b-field label="Project">
                <b-select v-model="settings.funding_source_id" expanded>
                  <option v-for="source in sources" :key="source.id" :value="source.id">{{ source.code }}</option>
                </b-select>
              </b-field>
              <b-field>
                <b-checkbox true-value="1" false-value="0" v-model="settings.is_part_of_payroll">Part of Payroll</b-checkbox>
              </b-field>
              <b-field>
                <b-checkbox true-value="1" false-value="0" v-model="settings.is_gsis_deduction">GSIS Deduction</b-checkbox>
              </b-field>
              <b-field>
                <b-checkbox true-value="1" false-value="0" v-model="settings.is_philhealth_deduction">PHILHEALTH Deduction</b-checkbox>
              </b-field>
              <b-field>
                <b-checkbox true-value="1" false-value="0" v-model="settings.is_pagibig_deduction">PAGIBIG Deduction</b-checkbox>
              </b-field>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" type="submit">Save</button>
              <button class="button is-danger" type="button" @click.prevent="closePayrollSettings()">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>

    <b-modal :active.sync="isBankAccount" has-modal-card :canCancel=false>
      <transition name="fade">
        <form @submit.prevent="saveBankAccount()">
          <div class="modal-card" style="max-height: 35.625rem; margin-top: 4rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">Bank Account</p>
            </header>
            <section class="modal-card-body">
              <b-field label="Bank Account">
                <b-input
                  v-model="bank_account"
                  expanded required>
                </b-input>
              </b-field>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" type="submit">Save</button>
              <button class="button is-danger" type="button" @click.prevent="closeBankAccount()">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>

    <b-modal :active.sync="isLogin" has-modal-card :canCancel=false>
      <transition name="fade">
        <form @submit.prevent="login()">
          <div class="modal-card" style="max-height: 35.625rem; margin-top: 4rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">Enter Password</p>
            </header>
            <section class="modal-card-body">
              <b-field label="Password">
                <b-input
                  v-model="password"
                  type="password"
                  expanded required>
                </b-input>
              </b-field>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" type="submit">Login</button>
              <button class="button is-danger" type="button" @click.prevent="closeLogin()">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>

    <b-modal :active.sync="isViewBankAccount" has-modal-card :canCancel=false>
      <transition name="fade">
        <form>
          <div class="modal-card" style="max-height: 35.625rem; margin-top: 4rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">Bank Account</p>
            </header>
            <section class="modal-card-body">
              {{ this.account_num }}
            </section>
            <footer class="modal-card-foot">
              <button class="button is-danger" type="button" @click.prevent="closeViewBankAccount()">Close</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>

    <b-modal :active.sync="isAddEarning" has-modal-card :canCancel=false>
      <transition name="fade">
        <form @submit.prevent="saveEarning()">
          <div class="modal-card" style="height: 35.625rem; margin-top: 4rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">{{ state }} Earning</p>
            </header>
            <section class="modal-card-body">
              <!-- <b-field label="Payroll Type">
                <b-select v-model="earning.payroll_type" required expanded>
                  <option value="Regular">REGULAR</option>
                  <option value="Special">SPECIAL</option>
                </b-select>
              </b-field> -->
              <b>Payroll Type:</b> <span v-if="earning.payroll_type">{{ earning.payroll_type }}</span><br><br>
              <b-field label="Earning" v-if="state == 'Add'">
                <b-select v-model="earningSelected" @input="selectEarning(earningSelected)" expanded required>
                  <option v-for="earning in earningsParams" :key="earning.id" :value="earning">{{ earning.name }}</option>
                </b-select>
              </b-field>
              <b-field label="Reference Date" v-if="earningSelected.earning_frequency != 'Perpetual' && earningSelected.earning_frequency">
                <b-datepicker
                  placeholder="reference date"
                  icon="calendar-today"
                  v-model="earning.ref_date"
                  :date-formatter="d => d.toLocaleDateString('en-GB')"
                  :date-parser="d => parseDate(d)"
                  editable>
                </b-datepicker>
              </b-field>
              <b-field label="Fixed Amount" v-if="earningSelected.amount_type == 'Fixed'">
                <b-input
                  v-model="earning.fixed_amount"
                  type="number" step="0.01"
                  expanded required @input="suggestsTaxableAmount">
                </b-input>
              </b-field>
              <b-field label="Fixed Amount Taxable" v-if="earningSelected.earning_classification == 'Taxable' && earningSelected.amount_type == 'Fixed' && earningSelected.has_special_tax == 0">
                <b-input
                  v-model="earning.fixed_amount_taxable"
                  type="number" step="0.01"
                  expanded required>
                </b-input>
              </b-field>
              <b-field label="Formula Amount" v-if="earningSelected.formula_amount && earningSelected.formula_amount != '[]'">
                <b-field>
                  <b-input
                    v-model="formulaDisplayEarning"
                    disabled expanded required>
                  </b-input>
                  <p class="control">
                    <button class="button is-primary" @click.prevent="openFormulaEarning()">
                      <span><i class="fa fa-calculator"></i></span>
                    </button>
                  </p>
                </b-field>
              </b-field>
              <b-field label="Formula Amount Taxable" v-if="earningSelected.formula_amount_taxable && earningSelected.formula_amount_taxable != '[]'">
                <b-field>
                  <b-input
                    v-model="formulaDisplayTax"
                    disabled expanded required>
                  </b-input>
                  <p class="control">
                    <button class="button is-primary" @click.prevent="openFormulaTax()">
                      <span><i class="fa fa-calculator"></i></span>
                    </button>
                  </p>
                </b-field>
              </b-field>
              <b-field label="Start Date" v-if="earningSelected.id == 30 || earningSelected.id == 11 || earningSelected.earning_id == 30 || earningSelected.earning_id == 11">
                <b-datepicker
                  placeholder="start date"
                  icon="calendar-today"
                  v-model="earning.start_date"
                  :date-formatter="d => d.toLocaleDateString('en-GB')"
                  :date-parser="d => parseDate(d)"
                  editable>
                </b-datepicker>
              </b-field>
              <b-field label="End Date" v-if="earningSelected.id == 30 || earningSelected.id == 11 || earningSelected.earning_id == 30 || earningSelected.earning_id == 11">
                <b-datepicker
                  placeholder="end date"
                  icon="calendar-today"
                  v-model="earning.end_date"
                  :date-formatter="d => d.toLocaleDateString('en-GB')"
                  :date-parser="d => parseDate(d)"
                  editable>
                </b-datepicker>
              </b-field>
              <b-field label="Remarks" v-if="earningSelected.id == 123123123">
                <b-input
                  v-model="earning.remarks"
                  expanded required>
                </b-input>
              </b-field>
              <br>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" type="submit">Save</button>
              <button class="button is-danger" type="button" @click.prevent="closeEarning()">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>

    <b-modal :active.sync="isAddDeduction" has-modal-card :canCancel=false>
      <transition name="fade">
        <form @submit.prevent="saveDeduction()">
          <div class="modal-card" style="height: 35.625rem; margin-top: 4rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">{{ state }} Deduction</p>
            </header>
            <section class="modal-card-body">
              <!-- <b>Payroll Type:</b> <span v-if="deduction.payroll_type">{{ deduction.payroll_type }}</span><br><br> -->
              <!-- <b-field label="Payroll Type">
                <b-select v-model="deduction.payroll_type" required expanded>
                  <option value="Regular">REGULAR</option>
                  <option value="Special">SPECIAL</option>
                </b-select>
              </b-field> -->
              <b-field label="Deduction" v-if="state == 'Add'">
                <b-select v-model="deductionSelected" @input="selectDeduction(deductionSelected)" expanded required>
                  <option v-for="deduction in deductionsParams" :key="deduction.id" :value="deduction">{{ deduction.name }}</option>
                </b-select>
              </b-field>
              <b-field label="Reference Date" v-if="deductionSelected.deduction_frequency != 'Perpetual' && deductionSelected.deduction_frequency != 'Diminishing' && deductionSelected.deduction_frequency">
                <b-datepicker
                  placeholder="reference date"
                  icon="calendar-today"
                  v-model="deduction.ref_date"
                  :date-formatter="d => d.toLocaleDateString('en-GB')"
                  :date-parser="d => parseDate(d)"
                  required
                  editable>
                </b-datepicker>
              </b-field>
              <b-field label="Fixed Amount" v-if="deductionSelected.amount_type == 'Fixed'">
                <b-input
                  v-model="deduction.fixed_amount"
                  type="number" step="0.01"
                  expanded required>
                </b-input>
              </b-field>
              <!-- <b-field label="Fixed Amount Taxable" v-if="deductionSelected.fixed_amount_taxable">
                <b-input
                  v-model="deduction.fixed_amount_taxable"
                  type="number" step="0.01"
                  expanded required>
                </b-input>
              </b-field> -->
              <b-field label="Formula Amount" v-if="deductionSelected.formula_amount && deductionSelected.formula_amount != '[]'">
                <b-field>
                  <b-input
                    v-model="formulaDisplayDeduction"
                    disabled expanded required>
                  </b-input>
                  <p class="control">
                    <button class="button is-primary" @click.prevent="openFormulaDeduction()">
                      <span><i class="fa fa-calculator"></i></span>
                    </button>
                  </p>
                </b-field>
              </b-field>
              <b-field label="Start Date" v-if="deductionSelected.amount_type == 'Amortized'">
                <b-datepicker
                  placeholder="reference date"
                  icon="calendar-today"
                  v-model="deduction.start_date"
                  :date-formatter="d => d.toLocaleDateString('en-GB')"
                  :date-parser="d => parseDate(d)"
                  required
                  editable>
                </b-datepicker>
              </b-field>
              <b-field label="End Date" v-if="deductionSelected.amount_type == 'Amortized'">
                <b-datepicker
                  placeholder="reference date"
                  icon="calendar-today"
                  v-model="deduction.end_date"
                  :date-formatter="d => d.toLocaleDateString('en-GB')"
                  :date-parser="d => parseDate(d)"
                  editable>
                </b-datepicker>
              </b-field>
              <b-field label="Grant Date" v-if="deductionSelected.amount_type == 'Amortized'">
                <b-datepicker
                  placeholder="Grant Date"
                  icon="calendar-today"
                  v-model="deduction.grant_date"
                  :date-formatter="d => d.toLocaleDateString('en-GB')"
                  :date-parser="d => parseDate(d)"
                  editable>
                </b-datepicker>
              </b-field>
              <b-field label="Voucher Number" v-if="deductionSelected.amount_type == 'Amortized'">
                <b-input
                  v-model="deduction.voucher_no"
                  type="text" expanded>
                </b-input>
              </b-field>
              <b-field label="Principal" v-if="deductionSelected.amount_type == 'Amortized'">
                <b-input
                  v-model="deduction.principal"
                  type="number" step="0.01"
                  expanded required>
                </b-input>
              </b-field>
              <b-field label="Monthly" v-if="deductionSelected.amount_type == 'Amortized'">
                <b-input
                  v-model="deduction.monthly"
                  type="number" step="0.01"
                  expanded required>
                </b-input>
              </b-field>
              <b-field label="Accumulated" v-if="deductionSelected.amount_type == 'Amortized' && state == 'Add'">
                <b-input
                  v-model="deduction.initial_balance"
                  type="number" step="0.01"
                  expanded>
                </b-input>
              </b-field>
              <b-field label="Remarks" v-if="deductionSelected.amount_type == 'Amortized'">
                <b-input
                  v-model="deduction.remarks"
                  type="text" expanded>
                </b-input>
              </b-field>
              <br>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" type="submit">Save</button>
              <button class="button is-danger" type="button" @click.prevent="closeDeduction()">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>

    <b-modal :active.sync="isEditDiminishing" has-modal-card :canCancel=false>
      <transition name="fade">
        <form @submit.prevent="saveDiminishing()">
          <div class="modal-card" style="height: 35.625rem; margin-top: 4rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">{{ state }} Diminishing</p>
            </header>
            <section class="modal-card-body">
              <b-field label="Reference Date">
                <b-datepicker
                  placeholder="reference date"
                  icon="calendar-today"
                  v-model="diminishing.ref_date"
                  :date-formatter="d => d.toLocaleDateString('en-GB')"
                  :date-parser="d => parseDate(d)"
                  required
                  editable>
                </b-datepicker>
              </b-field>
              <b-field label="Fixed Amount">
                <b-input
                  v-model="diminishing.amount"
                  type="number" step="0.01"
                  expanded required>
                </b-input>
              </b-field>
              <br>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" type="submit">Save</button>
              <button class="button is-danger" type="button" @click.prevent="closeDiminishing()">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>

    <b-modal :active.sync="isShowDTR" has-modal-card :canCancel=false>
      <transition name="fade">
        <form>
          <div class="modal-card pos" style="width: 60rem; height: 35.625rem; margin-left: 100px;">
            <header class="modal-card-head">
              <p class="modal-card-title">DTR</p>
            </header>
            <section class="modal-card-body">
              <b-select v-model="perPageDTR">
                <option value="5">5 per page</option>
                <option value="10">10 per page</option>
                <option value="15">15 per page</option>
                <option value="20">20 per page</option>
                <option value="31">31 per page</option>
              </b-select>
              <b-table
                :data="dtr.record"
                :striped=true
                :bordered=true
                :hoverable=true
                :paginated="true"
                :per-page="perPageDTR">
                <template slot-scope="props">
                  <b-table-column label="Reference Date">
                    {{ props.row.ref_date }}
                  </b-table-column>
                  <b-table-column label="AM IN">
                    {{ props.row.am.in }}
                  </b-table-column>
                  <b-table-column label="AM OUT">
                    {{ props.row.am.out }}
                  </b-table-column>
                  <b-table-column label="PM IN">
                    {{ props.row.pm.in }}
                  </b-table-column>
                  <b-table-column label="PM OUT">
                    {{ props.row.pm.out }}
                  </b-table-column>
                  <b-table-column label="LATE/UT/HD">
                    {{ props.row.difference }}
                  </b-table-column>
                  <b-table-column label="OT">
                    {{ props.row.ot }}
                  </b-table-column>
                  <b-table-column label="Remarks">
                    {{ props.row.remarks }}
                  </b-table-column>
                </template>
                <template slot="empty">
                  <b-table-column label="Period">
                  </b-table-column>
                </template>
              </b-table>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-danger" @click.prevent="closeDTR()">Close</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>

    <b-modal :active.sync="isRenew" has-modal-card :canCancel=false>
      <transition name="fade">
        <form @submit.prevent="saveRenew()">
          <div class="modal-card" style="max-height: 35.625rem; margin-top: 4rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">Renew</p>
            </header>
            <section class="modal-card-body">
              <b-field label="Accumulated">
                <b-input
                  v-model="renew.initial_balance"
                  type="number"
                  step="0.01"
                  expanded>
                </b-input>
              </b-field>
              <b-field label="Start Date">
                <b-datepicker
                  placeholder="reference date"
                  icon="calendar-today"
                  v-model="renew.start_date"
                  :date-formatter="d => d.toLocaleDateString('en-GB')"
                  :date-parser="d => parseDate(d)"
                  editable>
                </b-datepicker>
              </b-field>
              <b-field label="End Date">
                <b-datepicker
                  placeholder="reference date"
                  icon="calendar-today"
                  v-model="renew.end_date"
                  :date-formatter="d => d.toLocaleDateString('en-GB')"
                  :date-parser="d => parseDate(d)"
                  editable>
                </b-datepicker>
              </b-field>
              <b-field label="Principal">
                <b-input
                  v-model="renew.principal"
                  type="number"
                  expanded
                  step="0.01">
                </b-input>
              </b-field>
              <b-field label="Monthly">
                <b-input
                  v-model="renew.monthly"
                  type="number"
                  step="0.01"
                  expanded>
                </b-input>
              </b-field>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" type="submit">Save</button>
              <button class="button is-danger" type="button" @click.prevent="closeRenew()">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>

    <b-modal :active.sync="isFormulaEarning" has-modal-card>
      <Formula title="Earning" :variables="assignEarningsParams" @input="setFormulaEarning" :formula="earning.formula_amount" />
    </b-modal>

    <b-modal :active.sync="isFormulaTax" has-modal-card>
      <FormulaTax title="Earning" :variables="assignEarningsParams" @input="setFormulaTax" :formula="earning.formula_amount_taxable" />
    </b-modal>

    <b-modal :active.sync="isFormulaDeduction" has-modal-card>
      <FormulaTax title="Deduction" :variables="assignDeductionsParams" @input="setFormulaDeduction" :formula="deduction.formula_amount" />
    </b-modal>

    <b-modal :active.sync="isRunPayroll" has-modal-card :canCancel=false>
      <RunPayroll @refresh="getPayrollSettings()" />
    </b-modal>

    <b-modal :active.sync="isEncodeOR" has-modal-card :canCancel=false>
      <transition name="fade">
        <form @submit.prevent="encodeOR()">
          <div class="modal-card pos" style="height: 35.625rem; margin-left: 100px;">
            <header class="modal-card-head">
              <p class="modal-card-title">Encode OR</p>
            </header>
            <section class="modal-card-body">
              <b-field label="Payroll Group">
                <b-select placeholder="Payroll Group" v-model="orParams.payroll_group" expanded @input="getFinalizeDates">
                  <option value="1">Permanent/PA/Temporary/Co-Term </option>
                  <option value="2">Casuals/Contractual </option>
                  <option value="3">Contract of Service </option>
                  <option value="4">Job Order </option>
                </b-select>
              </b-field>
              <b-field label="Payroll Subgroup" v-if="orParams.payroll_group == '1'">
                <b-select placeholder="Payroll Group" v-model="orParams.payroll_subgroup" expanded @input="getFinalizeDates">
                  <option value="" selected>All</option>
                  <option value="1">Presidential Appointee</option>
                  <option value="2">Permanent</option>
                  <option value="3">Co-Terminus </option>
                  <option value="4">Temporary </option>
                </b-select>
              </b-field>
              <b-field label="Payroll Subgroup" v-if="orParams.payroll_group == '2'">
                <b-select placeholder="Payroll Group" v-model="orParams.payroll_subgroup" expanded @input="getFinalizeDates">
                  <option value="" selected>All</option>
                  <option value="9">Casuals</option>
                  <option value="6">Contractual</option>
                </b-select>
              </b-field>
              <b-field label="Payroll Type">
                <b-select v-model="orParams.payroll_type" expanded required @input="getFinalizeDates">
                  <option value="salary">REGULAR</option>
                  <option value="special">OTHER BENEFITS</option>
                  <option value="voucher">VOUCHER</option>
                </b-select>
              </b-field>
              <b-field label="Earning" v-if="orParams.payroll_type == 'special'">
                <b-select v-model="orParams.payroll_earning" expanded required @input="getFinalizeDates">
                  <option v-for="earning in earnings_list" :key="earning.id" :value="earning.id">{{ earning.code }}</option>
                </b-select>
              </b-field>
              <b-field label="Voucher" expanded required v-if="orParams.payroll_type == 'voucher'">
                <b-select v-model="orParams.voucher" expanded required @input="getFinalizeDates">
                  <option v-for="voucher in vouchers_list" :value="voucher.id" :key="voucher.id">{{ voucher.code }}</option>
                </b-select>
              </b-field>
              <b-field label="Month">
                <b-select v-model="orParams.month" placeholder="Month" expanded  @input="getFinalizeDates">
                  <option value="01">January</option>
                  <option value="02">February</option>
                  <option value="03">March</option>
                  <option value="04">April</option>
                  <option value="05">May</option>
                  <option value="06">June</option>
                  <option value="07">July</option>
                  <option value="08">August</option>
                  <option value="09">September</option>
                  <option value="10">October</option>
                  <option value="11">November</option>
                  <option value="12">December</option>
                </b-select>
              </b-field>
              <b-field label="Year">
                <b-select
                  v-model="orParams.year"
                  placeholder="Select Year"
                  expanded
                  required
                  @input="getFinalizeDates"
                >
                  <option v-for="year in years" :key="year" :value="year">{{year}}</option>
                </b-select>
              </b-field>
              <b-field label="Day" v-if="orParams.payroll_group == 4 && orParams.payroll_type=='salary'">
                <b-select v-model="printParams.day" expanded required @input="getFinalizeDates">
                  <option v-for="day in printParams.days" :key="day" :value="day">
                    {{ day }}
                  </option>
                </b-select>
              </b-field>
              <b-field label="Funding Source" v-if="orParams.payroll_group>1">
                <b-select v-model="orParams.funding_source" expanded @input="getFinalizeDates">
                  <option value="" selected>All</option>
                  <option v-for="source in sources" :key="source.id" :value="source.id">{{ source.code }}</option>
                </b-select>
              </b-field>
              <b-field label="Finalized?">
                <b-radio v-model="orParams.is_finalize" name="Finalized" native-value=1 @input="getFinalizeDates">Yes</b-radio>
                <b-radio v-model="orParams.is_finalize" name="Not yet Finalized" native-value=0>No</b-radio>
              </b-field>
              <b-field label="Date Finalized" v-if="orParams.is_finalize==1">
                <b-select
                  v-model="orParams.finalize_date"
                  placeholder="Select Finalize Date"
                  expanded
                >
                  <option v-for="(obj,finalize_date) in finalize_dates" :key="finalize_date" :value="finalize_date" >{{finalize_date}} ({{ obj.amount | currencyFormat }}) - {{ obj.funding_source }}</option>
                </b-select>
              </b-field>
              <b-field label="Deduction List">
                <b-select
                  v-model="orParams.or_type"
                  placeholder="SELECT DEDUCTIONS"
                  required
                  expanded
                  multiple
                >
                  <option v-for="deduction in filteredDeductionsOR" :key="deduction.id" :value="deduction.code" >{{deduction.code}}</option>
                </b-select>
              </b-field>
              <b-field label="OR Number">
                <b-input v-model="orParams.or_number" expanded>
                </b-input>
              </b-field>
              <b-field label="OR Date">
                <b-datepicker
                  placeholder="Click to select..."
                  icon="calendar-today"
                  v-model="orParams.or_date"
                  :date-formatter="d => d.toLocaleDateString('en-GB')"
                  :date-parser="d => parseDate(d)"
                  editable>
                </b-datepicker>
              </b-field>
              <b-field label="ADA/Check Number">
                <b-input v-model="orParams.check_number" expanded>
                </b-input>
              </b-field>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" type="submit">Run Encode</button>
              <button class="button is-danger" type="button" @click.prevent="isEncodeOR=false">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>

    <b-modal :active.sync="isUpdatePayrollLedger" has-modal-card :canCancel=false>
      <transition name="fade">
        <form @submit.prevent="savePayrollLedgersPopup()">
          <div class="modal-card" style="height: 35.625rem; margin-top: 4rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">Update Payroll ({{ getMonthYear(payroll_ledger.ref_date) }})</p>
            </header>
            <section class="modal-card-body">
              <span v-for="(earning,key) in payroll_ledger.earnings" v-bind:key="earning.id">
                <span v-if="!isObject(earning)">
                  <b-field :label="key">
                    <!-- <b-input v-model="" expanded>
                    </b-input> -->
                    <b-input v-bind:style="{ 'padding-left': '10px','padding-bottom':'10px' }"  v-model="payroll_ledger.earnings[key]" />
                    <button v-bind:style="{ 'padding-left': '10px','padding-top':'5px' }" class="button btn-action is-danger is-small" @click.prevent="cancelEarningLedger(key)"><i class="fa fa-times"></i></button>
                  </b-field>
                </span>
                <span v-else>
                  <span v-for="(salary,salary_key) in earning" v-bind:key="salary_key" horizontal>
                    <span v-if="isObject(salary)">
                      <b-field :label="key" >
                      </b-field>
                        <span v-for="(ob_val,ob_key) in salary" v-bind:key="ob_key" horizontal>
                          <span v-if="!isObject(ob_val) && ob_key !== 'dtr' && ob_key !== 'hours'">
                            <b-field :label="ob_key">
                              <b-input v-bind:style="{ 'padding-left': '10px' }" v-model="payroll_ledger.earnings[key][salary_key][ob_key]"  @input="logger(payroll_ledger)"/>
                              <button v-bind:style="{ 'padding-left': '10px','padding-top':'5px' }" class="button btn-action is-danger is-small" @click.prevent="cancelEarningLedger(key)"><i class="fa fa-times"></i></button>
                            </b-field>
                          </span>
                        </span>
                    </span>
                    <span v-else>
                    <b-field :label="key">
                      <b-field :label="salary_key" horizontal>
                        <b-input v-bind:style="{ 'padding-left': '10px' }" v-model="payroll_ledger.earnings[key][salary_key]" />
                      </b-field>
                      <button v-bind:style="{ 'padding-left': '10px','padding-top':'5px' }" class="button btn-action is-danger is-small" @click.prevent="cancelEarningLedger(key)"><i class="fa fa-times"></i></button>
                    </b-field>
                    </span>
                  </span>
                </span>
              </span>
              <b-field>
                <button class="button btn-action is-success is-small" @click.prevent="showEarningPayrollLedger()">Add Earning</button>
              </b-field>
              <b-field v-if="isAddToPayrollLedgerEarning">
                <b-select v-model="ledgerEarningSelected.key" expanded required>
                  <option v-for="earning in earningsParams" :key="earning.id" :value="earning.code">{{ earning.name }}</option>
                </b-select>
                <b-input v-model="ledgerEarningSelected.amount" placeholder="Amount" expanded required />
                <button class="button btn-action is-success is-small" @click.prevent="addEarningPayrollLedger()"><i class="fa fa-plus"></i></button>
              </b-field>

              <span v-for="(deduction,key) in payroll_ledger.deductions" v-bind:key="deduction.id">
                <span v-if="!isObject(deduction)">
                  <b-field :label="key">
                    <!-- <b-input v-model="" expanded>
                    </b-input> -->
                    <b-input v-bind:style="{ 'padding-left': '10px','padding-bottom':'10px' }" v-model="payroll_ledger.deductions[key]" />
                    <button v-bind:style="{ 'padding-left': '10px','padding-top':'5px' }" class="button btn-action is-danger is-small" @click.prevent="cancelDeductionLedger(key)"><i class="fa fa-times"></i></button>
                  </b-field>
                </span>
                <span v-else>
                  <b-field :label="key">
                    <b-field v-for="(salary,salary_key) in deduction" v-bind:key="salary_key" horizontal>
                      <b-input v-bind:style="{ 'padding-left': '10px' }" v-model="payroll_ledger.deductions[key][salary_key]" />
                    </b-field>
                    <button v-bind:style="{ 'padding-left': '10px','padding-top':'5px' }" class="button btn-action is-danger is-small" @click.prevent="cancelDeductionLedger(key)"><i class="fa fa-times"></i></button>
                  </b-field>
                </span>
              </span>
              <b-field>
                <button class="button btn-action is-success is-small" @click.prevent="showDeductionPayrollLedger()">Add Deduction</button>
              </b-field>
              <b-field v-if="isAddToPayrollLedgerDeduction">
                <b-select v-model="ledgerDeductionSelected.key" expanded required>
                  <option v-for="deduction in deductionsParams" :key="deduction.id" :value="deduction.code">{{ deduction.name }}</option>
                </b-select>
                <b-input v-model="ledgerDeductionSelected.amount" placeholder="Amount" expanded required />
                <button class="button btn-action is-success is-small" @click.prevent="addDeductionPayrollLedger()"><i class="fa fa-plus"></i></button>
              </b-field>
              <b-field label="Reason">
                <b-field>
                  <b-input type="textarea" v-model="payroll_ledger.reason" expanded required ></b-input>
                </b-field>
              </b-field>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" type="submit">Save</button>
              <button class="button is-danger" type="button" @click.prevent="closeUpdatePayrollLedger()">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>

    <b-modal :active.sync="isOverpayment" has-modal-card :canCancel=false>
      <transition name="fade">
        <form @submit.prevent="saveOverpaymentPopup()">
          <div class="modal-card" style="height: 35.625rem; margin-top: 4rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">Overpayment</p>
            </header>
            <section class="modal-card-body">
              <b-field label="Reference Date" expanded>
                <b-field expanded>
                  <b-field expanded>
                  <b-input v-model="overpayment.year" type="number" min="1800" max="2100" placeholder="YEAR" expanded required>
                    </b-input>
                  </b-field>
                  <b-field expanded>
                    <b-select v-model="overpayment.month" placeholder="MONTH" expanded required>
                      <option v-for="month in dateConfig.monthsFixed" :key="month" :value="month">
                        {{ month }}
                      </option>
                    </b-select>
                  </b-field>
                </b-field>
              </b-field>
              <b-field label="Amount">
                <b-input type="number" v-model="overpayment.amount" step="0.01" required />
              </b-field>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" type="submit">Save</button>
              <button class="button is-danger" type="button" @click.prevent="closeOverpaymentPopup()">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>

    <b-modal :active.sync="isUnfinalize" has-modal-card :canCancel=false>
      <transition name="fade">
        <form @submit.prevent="unfinalize()">
          <div class="modal-card" style="max-height: 35.625rem; margin-top: 4rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">Confirm </p>
            </header>
            <section class="modal-card-body">
              <b-field label="Reason">
                <b-input
                  type="textarea"
                  v-model="reason"
                  expanded required>
                </b-input>
              </b-field>
              <b-field label="Password">
                <b-input
                  type="password"
                  v-model="password"
                  expanded required>
                </b-input>
              </b-field>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" type="submit">UNFINALIZE</button>
              <button class="button is-danger" type="button" @click.prevent="closeUnfinalize()">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>

    <b-modal :active.sync="isSavePayrollLedgers" has-modal-card :canCancel=false>
      <transition name="fade">
        <form @submit.prevent="savePayrollLedgers()">
          <div class="modal-card" style="max-height: 35.625rem; margin-top: 4rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">Password</p>
            </header>
            <section class="modal-card-body">
              <b-field label="Password">
                <b-input
                  type="password"
                  v-model="password"
                  expanded required>
                </b-input>
              </b-field>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" type="submit">SAVE</button>
              <button class="button is-danger" type="button" @click.prevent="closeSavePayrollLedgers()">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>

    <b-modal :active.sync="isSaveOverpayment" has-modal-card :canCancel=false>
      <transition name="fade">
        <form @submit.prevent="saveOverpayment()">
          <div class="modal-card" style="max-height: 35.625rem; margin-top: 4rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">Password</p>
            </header>
            <section class="modal-card-body">
              <b-field label="Password">
                <b-input
                  type="password"
                  v-model="password"
                  expanded required>
                </b-input>
              </b-field>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" type="submit">SAVE</button>
              <button class="button is-danger" type="button" @click.prevent="closeSaveOverpayment()">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>

    <transition name="fade">
      <EmployeeSearch search-title="Employee" @close="close" @view="view_employee" v-if="isSearch" :isPayroll=true />
    </transition>
  </div>
</template>

<script>
import EmployeeSearch from '../components/SearchModal.vue'
import RunPayroll from '../components/RunPayroll.vue'
import FormulaEarning from '../components/Formula.vue'
import FormulaTax from '../components/Formula.vue'
import FormulaDeduction from '../components/Formula.vue'
import moment from 'moment'

export default {
  components: {
    EmployeeSearch,
    RunPayroll,
    FormulaEarning,
    FormulaTax,
    FormulaDeduction
  },
  data() {
    return {
      orParams : {
        payroll_group: null,
        payroll_subgroup: null,
        payroll_type: null,
        earnings: null,
        voucher: null,
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear(),
        day: null,
        funding_source: null,
        is_finalize: 0,
        finalize_date: null,
        or_number: '',
        or_date: null,
        check_number: ''
      },
      employment_status: null,
      config: '',
      isSearch: true,
      isLoading: false,
      payroll_setting_id: null,
      employee_id: null,
      fullName: '',
      settings: {},
      hideBankAccount: true,
      account: null,
      perPage: 12,
      ledger: [],
      payslip: [],
      ewt_percentage: 0,
      gmp_percentage: 0,
      cvat_percentage: 0,
      isRecords: false,
      isPayrollSetting: false,
      isBankAccount: false,
      isLogin: false,
      isViewBankAccount: false,
      password: null,
      account_num: null,
      bank_account: null,
      earnings: [],
      earnings_list: [],
      vouchers_list: [],
      earning: {},
      earningsParams: [],
      deductionsParams: [],
      earningSelected: {},
      isAddEarning: false,
      deductions: [],
      deduction: {},
      deductionsParams: [],
      deductionSelected: {},
      isAddDeduction: false,
      sources: [],
      state: '',
      diminishing: {},
      isEditDiminishing: false,
      isRunPayroll: false,
      isEncodeOR: false,
      isFormulaEarning: false,
      isFormulaTax: false,
      isFormulaDeduction: false,
      assignEarningsParams: [],
      assignDeductionsParams: [],
      isOverpayment: false,
      isSaveOverpayment: false,
      overpayment: {},
      searchEarnings: '',
      searchDeductions: '',
      isShowDTR: false,
      dtr: {},
      perPageDTR: 5,
      payroll_id: null,
      ot_allowed: null,
      renew_id: null,
      isRenew: false,
      renew: {},
      year: new Date().getFullYear(),
      is_finalize: 1,
      reason: '',
      isUnfinalize: false,
      isSavePayrollLedgers: false,
      selected_payroll_ledger: '',
      schedule: [],
      service_records: [],
      active: 1,
      finalize_dates: [],
      deductions_list: [],
      isUpdatePayrollLedger: false,
      payroll_ledger: {},
      payroll_type: '',
      months: ['January','February','March','April','May','June','July','August','September','October','November','December'],
      isAddToPayrollLedgerEarning: false,
      isAddToPayrollLedgerDeduction: false,
      ledgerEarningSelected: {
        key: null,
        amount: 0
      },
      ledgerDeductionSelected: {
        key: null,
        amount: 0
      },
      dateConfig: {
        months: [],
        days: [],
        monthsFixed: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
      }
    }
  },
  mounted() {
    //console.log(this.$role.state.permission)
    this.config = {
      headers: {'Authorization': "Bearer " + this.$store.state.token}
    };
    this.getEarningsList()
  },
  computed: {
    getMonthYear() {
      return ref_date => this.months[new Date(ref_date).getMonth()] + ' ' +  new Date(ref_date).getFullYear().toString()
    },
    years() {
      const year = new Date().getFullYear() + 5;
      return Array.from({length: (year-1970) + 1},(value,index)=>year-index)
    },
    filteredDeductionsOR() {
      let deductions =  this.deductions_list.filter((deduction) => {
        return deduction.type=='Loan' || deduction.type=='GSIS LOANS' || deduction.type=='other insurance' || deduction.type=='GSIS Other Deds.' || deduction.type=='Pagibig Group'
      })
      deductions.push({id:'GSIS Contri',code:'GSIS Contribution',type:'Contribution'})
      deductions.push({id:'PHILHEALTH Contri',code:'PHILHEALTH Contribution',type:'Contribution'})
      deductions.push({id:'PAG-IBIG Contri',code:'PAGIBIG Contribution',type:'Contribution'})
      deductions.push({id:'SSS Contri',code:'SSS Contribution',type:'Contribution'})
      deductions.push({id:'Gsis Retro',code:'GSIS Retro',type:'Retro'})
      deductions.push({id:'PhilHealth Retro',code:'PHILHEALTH Retro',type:'Retro'})

      deductions.sort(function(a,b) {
        if (a.code<b.code) return -1
        if (a.code>b.code) return 1
        return 0
      })
      //console.log(deductions)
      return deductions
    },
    filterEarnings() {
      var i;
      var name_re = new RegExp(this.searchEarnings, 'i')
      var data = []
      for (i in this.earnings) {
        if ([this.earnings[i].earning, this.earnings[i].earning_frequency, this.earnings[i].payroll_type].join(' ').match(name_re)) {
          data.push(this.earnings[i])
        }
      }
      return data
    },
    filterSchedule() {
      var i;
      var data = [];
      for (i in this.schedules) {
        if ([this.schedules[i].mon_schedule, this.schedules[i].tue_schedule, this.schedules[i].wed_schedule,
             this.schedules[i].thu_schedule, this.schedules[i].fri_schedule, this.schedules[i].sat_schedule, this.schedules[i].sun_schedule,].join(' ')){
          data.push(this.schedules[i])
        }
      }
      return data
    },
    filterDeductions() {
      var i;
      var name_re = new RegExp(this.searchDeductions, 'i')
      var data = []
      for (i in this.deductions) {
        if ([this.deductions[i].deduction, this.deductions[i].deduction_frequency, this.deductions[i].payroll_type].join(' ').match(name_re)) {
          data.push(this.deductions[i])
        }
      }
      var returnData = [];
      for (i in data) {
        if (data[i].is_active==this.active || this.active=='all') {
          returnData.push(data[i])
        }
      }
      return returnData
    },
    formulaDisplayEarning() {
      if (this.earning.formula_amount && this.earning.formula_amount != "[]") {
        let formula
        let formulaArr
        formulaArr = this.earning.formula_amount.map(x => x = x.code)
        formula = formulaArr.join(' ')
        return formula
      }
    },
    formulaDisplayTax() {
      if (this.earning.formula_amount_taxable && this.earning.formula_amount_taxable != "[]") {
        let formula
        let formulaArr
        formulaArr = this.earning.formula_amount_taxable.map(x => x = x.code)
        formula = formulaArr.join(' ')
        return formula
      }
    },
    formulaDisplayDeduction() {
      if (this.deduction.formula_amount && this.deduction.formula_amount != "[]") {
        let formula
        let formulaArr
        formulaArr = this.deduction.formula_amount.map(x => x = x.code)
        formula = formulaArr.join(' ')
        return formula
      }
    }
  },
  methods: {
    suggestsTaxableAmount() {
      if (this.earningSelected.earning_classification=='Taxable')
        this.earning.fixed_amount_taxable = this.earning.fixed_amount
    },
    changeYear() {
      this.search_query = ''
      if (this.year.length == 4) {
        this.isLoading = true
        this.$http.get(`${this.$host}/api/payroll/search/${this.year}/${this.employee_id}/?is_finalize=${this.is_finalize}`,
          this.config
        ).then((response) => {
          let data = response.data
          this.ledger = data
          this.isLoading = false
        }).catch((error) => {
          this.isLoading = false
        });
      }
      else if (this.year.length == 0) {
        this.getLedger()
      }
    },
    renewDeduction(id) {
      this.renew_id = id
      this.isRenew = true
    },
    saveRenew() {
      this.isLoading = true

      this.$http.post(`${this.$host}/api/payroll/deduction/renew/${this.renew_id}`,
        {
          initial_balance: this.renew.initial_balance,
          start_date: moment(this.renew.start_date).format("YYYY-MM-DD"),
          end_date: moment(this.renew.end_date).format("YYYY-MM-DD"),
          principal: this.renew.principal,
          monthly: this.renew.monthly,
        },
        this.config
      ).then((response) => {
        let data = response.data
        this.isLoading = false
        this.closeRenew()
      }).catch((error) => {
        this.$toast.open({
            message: 'Error.',
            type: 'is-danger',
            position: 'is-bottom-right',
            duration: 4000
          })
        this.isLoading = false
      });
    },
    closeRenew() {
      this.renew_id = null
      this.isRenew = false
      this.getPayrollSettings()
    },
    showDTR(dtr) {
      this.dtr = dtr
      this.isShowDTR = true
    },
    closeDTR() {
      this.dtr = {}
      this.isShowDTR = false
    },
    setFormulaTax(obj, bool) {
      this.earning.formula_amount_taxable = obj
    },
    openFormulaTax() {
      this.isFormulaTax = true
    },
    setFormulaEarning(obj, bool) {
      this.earning.formula_amount = obj
    },
    openFormulaEarning() {
      this.isFormulaEarning = true
    },
    setFormulaDeduction(obj, bool) {
      this.deduction.formula_amount = obj
    },
    openFormulaDeduction() {
      this.isFormulaDeduction = true
    },
    viewPayslip(payroll) {
      this.ewt_percentage = payroll.cos_jo_object.ewt_percentage*100
      this.gmp_percentage = payroll.cos_jo_object.gmp_percentage*100
      this.cvat_percentage = payroll.cos_jo_object.cvat_percentage*100
      this.payslip = payroll.payslip
      this.payroll_id = payroll.id
      this.payroll_type = payroll.payroll_type
      this.employment_status = payroll.employment_status
      this.isRecords = true
    },
    showUpdatePayslip(payroll) {

      this.$http.get(`${this.$host}/api/payroll/payroll_ledger/${payroll.id}`,
        this.config
      ).then((response) => {
        this.payroll_ledger = response.data.payroll_ledger
        //console.log(response)
      })
      //console.log(true)
      //console.log(this.payroll_ledger)
      this.isUpdatePayrollLedger = true
    },
    deletePayslip(payroll) {
      this.$dialog.confirm({
        title: 'Delete Payslip',
        message: "Are you sure you want to delete this payslip? This action cannot be undone.",
        confirmText: 'Delete',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isLoading = true

          this.$http.delete(`${this.$host}/api/payroll/payslip/remove/${payroll.id}`,
            this.config
          ).then((response) => {
            this.$toast.open({
              message: 'Payslip deleted.',
              type: 'is-success',
              position: 'is-bottom-right',
              duration: 4000
            })
            this.$http.get(`${this.$host}/api/payroll/search/${this.year}/${this.employee_id}/?is_finalize=${this.is_finalize}`,
              this.config
            ).then((response) => {
              let data = response.data
              this.ledger = data
              this.isLoading = false
            }).catch((error) => {
              this.isLoading = false
            });
          }).catch((error) => {
            this.$toast.open({
              message: 'Error deleting row.',
              type: 'is-danger',
              position: 'is-bottom-right',
              duration: 4000
            })
            this.isLoading = false
          });
        }
      })
    },
    openRunPayroll() {
      this.isRunPayroll = true
    },
    toFormula(formula, type) {
      if (type == 'Formula' && formula) {
        if(formula.constructor.name == 'Array'){
          return formula.map(x => x = x.code).join(' ')
        }else{
          return formula
        }
      }
      else {
        return formula
      }
    },
    parseDate(d) {
      let parts = d.split("/")
      let dt = new Date(parseInt(parts[2], 10),
        parseInt(parts[1], 10) - 1,
        parseInt(parts[0], 10))
      return dt
    },
    viewBankAccount() {
      this.isLogin = true
    },
    closeViewBankAccount() {
      this.isViewBankAccount = false
      this.account_num = null
    },
    login() {
      this.isLoading = true

      this.$http.post(`${this.$host}/api/payroll/setting/bank-account/${this.payroll_setting_id}`,
        {
          password: this.password
        },
        this.config
      ).then((response) => {
        let data = response.data
        this.account_num = data
        this.isLoading = false
        this.isLogin = false
        this.isViewBankAccount = true
      }).catch((error) => {
        if (error.response.data == 'wrong password') {
          this.$toast.open({
            message: 'Wrong password.',
            type: 'is-danger',
            position: 'is-bottom-right',
            duration: 4000
          })
        }
        this.isLoading = false
      });
    },
    logger(log) {
      //console.log(log)
    },
    closeLogin() {
      this.isLogin = false
      this.password = null
    },
    editBankAccount() {
      this.isBankAccount = true
    },
    saveBankAccount() {
      this.$http.put(`${this.$host}/api/payroll/setting/edit/bank-account/${this.payroll_setting_id}`,
        {
          bank_account: this.bank_account
        },
        this.config,
      ).then((response) => {
        let data = response.data
        this.$toast.open({
          message: 'Bank account updated.',
          type: 'is-success',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.isLoading = false
        this.closeBankAccount()
      }).catch((error) => {
        this.$toast.open({
          message: 'Error saving data.',
          type: 'is-danger',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.isLoading = false
      });
    },
    closeBankAccount() {
      this.isBankAccount = false
      this.getPayrollSettings()
    },
    savePayrollSettings() {
      this.$http.put(`${this.$host}/api/payroll/setting/edit/${this.payroll_setting_id}`,
        this.settings,
        this.config,
      ).then((response) => {
        let data = response.data
        this.$toast.open({
          message: 'Payroll setting updated.',
          type: 'is-success',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.isLoading = false
        this.closePayrollSettings()
      }).catch((error) => {
        this.$toast.open({
          message: 'Error saving data.',
          type: 'is-danger',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.isLoading = false
      });
    },
    editPayrollSettings() {
      this.isPayrollSetting = true
    },
    closePayrollSettings() {
      this.settings = {}
      this.isPayrollSetting = false
      this.getPayrollSettings()
    },
    closeUpdatePayrollLedger() {
      this.isUpdatePayrollLedger = false
      this.getPayrollSettings()
    },
    selectEarning(selected) {
      this.earning = JSON.parse(JSON.stringify(selected))
    },
    addEarning() {
      this.isAddEarning = true
      this.state = 'Add'
    },
    editEarning(obj) {
      this.earningSelected = obj.raw
      this.earningSelected.id = obj.id
      this.earningSelected.earning_frequency = obj.earning_frequency
      this.earningSelected.amount_type = obj.amount_type
      this.earningSelected.earning_classification = obj.earning_classification
      if (obj.amount_taxable) {
        this.earningSelected.earning_classification = 'Taxable'
      }
      else {
        this.earningSelected.earning_classification = ''
      }
      this.earning = obj.raw
      if (obj.ref_date) {
        this.earning.ref_date = new Date(this.earning.ref_date)
      }
      let fixed_amount = this.earning.fixed_amount
      fixed_amount=fixed_amount.replace(/\,/g,'')
      let fixed_amount_taxable = this.earning.fixed_amount_taxable
      fixed_amount_taxable=fixed_amount_taxable.replace(/\,/g,'')
      this.earning.fixed_amount = parseFloat(fixed_amount)
      this.earning.fixed_amount_taxable = parseFloat(fixed_amount_taxable)
      this.isAddEarning = true
      this.state = 'Edit'
    },
    saveEarning() {
      let earningAttach = JSON.parse(JSON.stringify(this.earning))
      earningAttach.employee_id = [this.employee_id]
      earningAttach.earning_id = earningAttach.id
      if (earningAttach.ref_date) {
        earningAttach.ref_date = moment(this.earning.ref_date).format("YYYY-MM-DD")
      }
      if (earningAttach.start_date) {
        earningAttach.start_date = moment(this.earning.start_date).format("YYYY-MM-DD")
      }
      if (earningAttach.end_date) {
        earningAttach.end_date = moment(this.earning.end_date).format("YYYY-MM-DD")
      }
      if (earningAttach.formula_amount && earningAttach.formula_amount != "[]") {
        earningAttach.formula_amount = JSON.stringify(earningAttach.formula_amount.map(x => x = x.id))
      }
      if (earningAttach.formula_amount_taxable && earningAttach.formula_amount_taxable != "[]") {
        earningAttach.formula_amount_taxable = JSON.stringify(earningAttach.formula_amount_taxable.map(x => x = x.id))
      }

      if (this.state == 'Add') {
        this.isLoading = true
        this.$http.post(`${this.$host}/api/payroll/earning/attach/`,
          earningAttach,
          this.config,
        ).then((response) => {
          this.$toast.open({
            message: 'Earning succesfully attached.',
            type: 'is-success',
            position: 'is-bottom-right',
            duration: 4000
          })
          this.isLoading = false
          this.closeEarning()
        }).catch((error) => {
          this.isLoading = false
          this.$toast.open({
            message: 'Error attaching earning.',
            type: 'is-danger',
            position: 'is-bottom-right',
            duration: 4000
          })
        });
      }
      else if (this.state == 'Edit') {
        this.isLoading = true
        this.$http.put(`${this.$host}/api/payroll/earning/employee/edit/${this.earningSelected.id}`,
          earningAttach,
          this.config,
        ).then((response) => {
          this.$toast.open({
            message: 'Earning succesfully attached.',
            type: 'is-success',
            position: 'is-bottom-right',
            duration: 4000
          })
          this.isLoading = false
          this.closeEarning()
        }).catch((error) => {
          this.isLoading = false
          this.$toast.open({
            message: 'Error attaching earning.',
            type: 'is-danger',
            position: 'is-bottom-right',
            duration: 4000
          })
        });
      }
    },
    deleteEarning(id) {
      this.$dialog.confirm({
        title: 'Delete Earning',
        message: "Are you sure you want to delete this row? This action cannot be undone.",
        confirmText: 'Delete',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isLoading = true

          this.$http.delete(`${this.$host}/api/payroll/earning/remove/${id}`,
            this.config
          ).then((response) => {
            this.$toast.open({
              message: 'Row deleted.',
              type: 'is-success',
              position: 'is-bottom-right',
              duration: 4000
            })
            this.isLoading = false
            this.getPayrollSettings()
          }).catch((error) => {
            this.$toast.open({
              message: 'Error deleting row.',
              type: 'is-danger',
              position: 'is-bottom-right',
              duration: 4000
            })
            this.isLoading = false
          });
        }
      })
    },
    closeEarning() {
      this.earning = {}
      this.earningSelected = {}
      this.isAddEarning = false
      this.getPayrollSettings()
    },
    selectDeduction(selected) {
      this.deduction = JSON.parse(JSON.stringify(selected))
      if (this.state == 'Add' && this.deductionSelected.amount_type == 'Amortized') {
        this.deduction.initial_balance = 0
      }
    },
    addDeduction() {
      this.isAddDeduction = true
      this.state = 'Add'
    },
    editDeduction(obj) {
      this.deductionSelected = obj.raw
      this.deductionSelected.id = obj.id
      this.deductionSelected.deduction_frequency = obj.deduction_frequency
      this.deductionSelected.amount_type = obj.amount_type
      this.deduction = obj.raw
      if (obj.ref_date) {
        this.deduction.ref_date = new Date(this.deduction.ref_date)
      }
      if (obj.start_date) {
        this.deduction.start_date = new Date(this.deduction.start_date)
      }
      if (obj.end_date) {
        this.deduction.end_date = new Date(this.deduction.end_date)
      }
      this.isAddDeduction = true
      this.state = 'Edit'
    },
    saveDeduction() {
      let deductionAttach = JSON.parse(JSON.stringify(this.deduction))
      deductionAttach.employee_id = [this.employee_id]
      deductionAttach.deduction_id = deductionAttach.id
      if (deductionAttach.ref_date) {
        deductionAttach.ref_date = moment(this.deduction.ref_date).format("YYYY-MM-DD")
      }
      if (deductionAttach.start_date) {
        deductionAttach.start_date = moment(this.deduction.start_date).format("YYYY-MM-DD")
      }
      if (deductionAttach.end_date) {
        deductionAttach.end_date = moment(this.deduction.end_date).format("YYYY-MM-DD")
      }
      if (deductionAttach.grant_date) {
        deductionAttach.grant_date = moment(this.deduction.grant_date).format("YYYY-MM-DD")
      }
      if (deductionAttach.formula_amount && deductionAttach.formula_amount != "[]") {
        deductionAttach.formula_amount = JSON.stringify(deductionAttach.formula_amount.map(x => x = x.id))
      }
      deductionAttach.payroll_type = 'Regular'

      if (this.state == 'Add') {
        this.isLoading = true
        this.$http.post(`${this.$host}/api/payroll/deduction/attach/`,
          deductionAttach,
          this.config,
        ).then((response) => {
          this.$toast.open({
            message: 'Deduction succesfully attached.',
            type: 'is-success',
            position: 'is-bottom-right',
            duration: 4000
          })
          this.isLoading = false
          this.closeDeduction()
        }).catch((error) => {
          this.isLoading = false
          this.$toast.open({
            message: 'Error attaching deduction.',
            type: 'is-danger',
            position: 'is-bottom-right',
            duration: 4000
          })
        });
      }
      else if (this.state == 'Edit') {
        this.isLoading = true
        this.$http.put(`${this.$host}/api/payroll/deduction/employee/edit/${this.deductionSelected.id}`,
          deductionAttach,
          this.config,
        ).then((response) => {
          this.$toast.open({
            message: 'Deduction succesfully attached.',
            type: 'is-success',
            position: 'is-bottom-right',
            duration: 4000
          })
          this.isLoading = false
          this.closeDeduction()
        }).catch((error) => {
          this.isLoading = false
          this.$toast.open({
            message: 'Error attaching deduction.',
            type: 'is-danger',
            position: 'is-bottom-right',
            duration: 4000
          })
        });
      }
    },
    deleteDeduction(id) {
      this.$dialog.confirm({
        title: 'Delete Deduction',
        message: "Are you sure you want to delete this row? This action cannot be undone.",
        confirmText: 'Delete',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isLoading = true

          this.$http.delete(`${this.$host}/api/payroll/deduction/remove/${id}`,
            this.config
          ).then((response) => {
            this.$toast.open({
              message: 'Row deleted.',
              type: 'is-success',
              position: 'is-bottom-right',
              duration: 4000
            })
            this.isLoading = false
            this.getPayrollSettings()
          }).catch((error) => {
            this.$toast.open({
              message: 'Error deleting row.',
              type: 'is-danger',
              position: 'is-bottom-right',
              duration: 4000
            })
            this.isLoading = false
          });
        }
      })
    },
    cancelDeduction(id) {
      this.$dialog.confirm({
        title: 'Deactivate Deduction',
        message: "Are you sure you want to deactivate this deduction? This action cannot be undone.",
        confirmText: 'Deactivate Deduction',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isLoading = true

          this.$http.post(`${this.$host}/api/payroll/deduction/cancel/${id}`,null,
            this.config
          ).then((response) => {
            this.$toast.open({
              message: 'Deduction Deactivated.',
              type: 'is-success',
              position: 'is-bottom-right',
              duration: 4000
            })
            this.isLoading = false
            this.getPayrollSettings()
          }).catch((error) => {
            this.$toast.open({
              message: 'Error cancelling deduction.',
              type: 'is-danger',
              position: 'is-bottom-right',
              duration: 4000
            })
            this.isLoading = false
          });
        }
      })
    },
    activeDeduction(id) {
      this.$dialog.confirm({
        title: 'Activate Deduction',
        message: "Are you sure you want to Activate this deduction? This action cannot be undone.",
        confirmText: 'Activate',
        type: 'is-success',
        hasIcon: true,
        onConfirm: () => {
          this.isLoading = true

          this.$http.post(`${this.$host}/api/payroll/deduction/set-active/${id}`,null,
            this.config
          ).then((response) => {
            this.$toast.open({
              message: 'Deduction Activated.',
              type: 'is-success',
              position: 'is-bottom-right',
              duration: 4000
            })
            this.isLoading = false
            this.getPayrollSettings()
          }).catch((error) => {
            this.$toast.open({
              message: 'Error updating deduction.',
              type: 'is-danger',
              position: 'is-bottom-right',
              duration: 4000
            })
            this.isLoading = false
          });
        }
      })
    },
    closeDeduction() {
      this.deduction = {}
      this.deductionSelected = {}
      this.isAddDeduction = false
      this.getPayrollSettings()
    },
    deleteDiminishing(id) {
      this.$dialog.confirm({
        title: 'Delete Row',
        message: "Are you sure you want to delete this row? This action cannot be undone.",
        confirmText: 'Delete',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isLoading = true

          this.$http.delete(`${this.$host}/api/payroll/diminishing/delete/${id}`,
            this.config
          ).then((response) => {
            this.$toast.open({
              message: 'Row deleted.',
              type: 'is-success',
              position: 'is-bottom-right',
              duration: 4000
            })
            this.isLoading = false
            this.getPayrollSettings()
          }).catch((error) => {
            this.$toast.open({
              message: 'Error deleting row.',
              type: 'is-danger',
              position: 'is-bottom-right',
              duration: 4000
            })
            this.isLoading = false
          });
        }
      })
    },
    editDiminishing(obj) {
      this.diminishing = JSON.parse(JSON.stringify(obj))
      this.diminishing.ref_date = new Date(obj.ref_date)
      this.isEditDiminishing = true
      this.state = 'Edit'
    },
    closeDiminishing() {
      this.isEditDiminishing = false
      this.getPayrollSettings()
    },
    saveDiminishing() {
      this.isLoading = true
      this.$http.put(`${this.$host}/api/payroll/diminishing/edit/${this.diminishing.id}`,
        {
          ref_date: moment(this.diminishing.ref_date).format("YYYY-MM-DD"),
          amount: this.diminishing.amount
        },
        this.config,
      ).then((response) => {
        this.$toast.open({
          message: 'Diminishing saved.',
          type: 'is-success',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.closeDiminishing()
      }).catch((error) => {
        this.isLoading = false
        this.$toast.open({
          message: 'Error saving data.',
          type: 'is-danger',
          position: 'is-bottom-right',
          duration: 4000
        })
      });
    },
    view_employee(selected) {
      this.payroll_setting_id = selected.payroll_setting_id
      this.employee_id = selected.id
      this.employment_status = selected.employment_status
      if (selected.name_extension == null) {
        this.fullName = `${selected.first_name} ${selected.last_name}`
      }
      else {
        this.fullName = `${selected.first_name} ${selected.last_name} ${selected.name_extension}`
      }
      if (this.payroll_setting_id != undefined && this.employee_id != undefined) {
        this.isSearch = false
        this.getPayrollSettings()
        this.getEarningsParams()
        this.getServiceRecords()
      }
    },
    getServiceRecords() {
      this.isLoading = true;
      this.$http.get(`${this.$host}/api/payroll/service-record/${this.employee_id}`,
        this.config
      ).then((response) => {
        let data = response.data
        this.service_records = data;
        this.isLoading = false;
      });
    },
    close() {
      if (!this.payroll_setting_id) {
        this.$router.push({ path: '/payroll' })
        this.isSearch = false
      }
      else {
        this.isSearch = false
      }
    },
    getPayrollSettings() {
      this.isLoading = true

      this.$http.get(`${this.$host}/api/payroll/setting/search/${this.payroll_setting_id}`,
        this.config
      ).then((response) => {
        let data = response.data
        this.settings = data
        if (this.settings.hasBankAccount) {
          this.hideBankAccount = false
        }
        else {
          this.hideBankAccount = true
        }
        this.ot_allowed = data.ot_allowed
        this.getLedger()
      }).catch((error) => {
        this.isLoading = false
      });
    },
    getLedger() {
      this.isLoading = true
      let url = ''

      if (this.year != '') {
        url = `${this.$host}/api/payroll/search/${this.year}/${this.employee_id}/?is_finalize=${this.is_finalize}`
      }
      else {
        url = `${this.$host}/api/payroll/search/2020/${this.employee_id}/?is_finalize=${this.is_finalize}`
      }
      this.$http.get(url,
        this.config
      ).then((response) => {
        let data = response.data
        this.ledger = data
        this.getEarnings()
      }).catch((error) => {
        this.isLoading = false
      });
    },
    getEarnings() {
      this.$http.get(`${this.$host}/api/payroll/earning/search/${this.employee_id}`,
        this.config
      ).then((response) => {
        let data = response.data
        this.earnings = data
        this.getEarningsParams()
      }).catch((error) => {
        this.isLoading = false
      });
    },
    getEarningsParams() {
      this.$http.get(`${this.$host}/api/parameter/payroll/employee/earnings`,
        this.config
      ).then((response) => {
        let data = response.data
        this.earningsParams = data
        this.getDeductions()
      }).catch((error) => {
        this.isLoading = false
      });
    },
    getDeductions() {
      this.$http.get(`${this.$host}/api/payroll/deduction/search/${this.employee_id}`,
        this.config
      ).then((response) => {
        let data = response.data
        this.deductions = data
        this.getDeductionsParams()
      }).catch((error) => {
        this.isLoading = false
      });
    },
    getDeductionsParams() {
      this.$http.get(`${this.$host}/api/parameter/payroll/employee/deductions`,
        this.config
      ).then((response) => {
        let data = response.data
        this.deductionsParams = data
        this.getFundingSource()
      }).catch((error) => {
        this.isLoading = false
      });
    },
    getFundingSource() {
      this.$http.get(`${this.$host}/api/payroll/funding-source/show/`,
        this.config
      ).then((response) => {
        let data = response.data
        this.sources = data
        this.getAssignEarningsParams()
      }).catch((error) => {
        this.isLoading = false
      });
    },
    getAssignEarningsParams() {
      this.$http.get(`${this.$host}/api/parameter/payroll/earnings`,
        this.config
      ).then((response) => {
        let data = response.data
        this.assignEarningsParams = data
        this.isLoading = false
        this.getAssignDeductionsParams()
      }).catch((error) => {
        this.isLoading = false
      });
    },
    getAssignDeductionsParams() {
      this.$http.get(`${this.$host}/api/parameter/payroll/deductions`,
        this.config
      ).then((response) => {
        let data = response.data
        this.assignDeductionsParams = data
        this.isLoading = false
        this.getRecords()
      }).catch((error) => {
        this.isLoading = false
      });
    },
    getRecords() {
      this.isLoading = false
    },
    yesNo(bool) {
      if (bool == 1) {
        return 'YES'
      }
      else if (bool == 0) {
        return 'NO'
      }
      else {
        return ''
      }
    },
    printPS() {
      this.$http.post(`${this.$host}/api/report/generate/${this.payroll_id}`,
        {},
        this.config
      ).then((response) => {
        this.isLoading = false
        let token = response.data

        let url = `${this.$host}/api/report/payroll/payslip/${token}`
        let win = window.open(url, '_blank')
        win.focus()
        this.isPrint = false
      }).catch((error) => {
        this.isLoading = false
      });
    },
    openEncodeOR() {
      this.isEncodeOR = true;
    },
    getFinalizeDates() {
      var params = {
        params: this.orParams
      }

      if (this.orParams.payroll_group!=4){
        this.orParams.day = '';
      }
      if (this.orParams.is_finalize==1) {
        var config = Object.assign(this.config,params)
        this.isLoading=true
        this.$http.get(`${this.$host}/api/parameter/payroll/finalize_dates`,
          config,
        ).then((response) => {
          this.finalize_dates = response.data.data;
          this.isLoading=false
        }).catch((error) => {
          this.isLoading=false
        });
      }
    },
    getEarningsList() {
      this.isLoading = true;
      this.$http.get(`${this.$host}/api/parameter/payroll/run`,
        this.config
      ).then((response) => {
        let data = response.data
        this.earnings_list = data.earnings
        this.vouchers_list = data.vouchers
        this.deductions_list = data.deductions
        this.isLoading = false
      }).catch((error) => {
        this.isLoading = false
      });
    },
    encodeOR() {
      this.isLoading = true
      this.$http.post(`${this.$host}/api/payroll/or`,
        this.orParams,
        this.config
      ).then((response) => {
        this.isLoading = false
        this.$toast.open({
          message: response.data.message,
          type: 'is-success',
          position: 'is-bottom-right',
          duration: 4000
        })
      }).catch((error) => {
        this.isLoading = false
      });
    },
    unfinalizePayroll(payroll) {
      this.$dialog.confirm({
        title: 'Unfinalize Payroll',
        message: "Are you sure you want to unfinalize this record? This action cannot be undone.",
        confirmText: 'Unfinalize',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$dialog.confirm({
            title: 'Unfinalize Payroll',
            message: "Are you sure <b>really</b> you want to <b>unfinalize</b> this payroll?",
            confirmText: 'Yes',
            type: 'is-success',
            hasIcon: false,
              onConfirm: () => {
                this.isUnfinalize = true,
                this.selected_payroll_ledger = payroll
              }
          })
        }
      })
    },
    unfinalize() {
      //console.log(this.selected_payroll_ledger)
      let payroll = this.selected_payroll_ledger

      this.$http.post(`${this.$host}/api/payroll/payslip/unfinalize/${payroll.id}`,
      {
        password: this.password,
        reason: this.reason,
      },
        this.config
      ).then((response) => {
        this.$toast.open({
          message: 'Payroll Unfinalized.',
          type: 'is-success',
          position: 'is-bottom-right',
          duration: 4000,
        })
        this.$http.get(`${this.$host}/api/payroll/search/${this.year}/${this.employee_id}/?is_finalize=${this.is_finalize}`,
          this.config
        ).then((response) => {
          let data = response.data
          this.ledger = data
          this.isLoading = false
          this.closeUnfinalize()
        }).catch((error) => {
          this.$toast.open({
            message: 'Wrong password.',
            type: 'is-danger',
            position: 'is-bottom-right',
            duration: 4000
          })
          this.isLoading = false
        });
      }).catch((error) => {
        this.$toast.open({
          message: 'Wrong password.',
          type: 'is-danger',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.isLoading = false
      });
    },
    closeUnfinalize() {
      this.isUnfinalize = false
      this.password = ''
    },
    isObject(earning) {
      // //console.log(this.deductionsParams)
      return typeof earning==='object'
    },
    showEarningPayrollLedger() {
      this.isAddToPayrollLedgerEarning = true
    },
    showDeductionPayrollLedger() {
      this.isAddToPayrollLedgerDeduction = true
    },
    addEarningPayrollLedger() {
      //console.log(this.ledgerEarningSelected.key)
      this.payroll_ledger.earnings[this.ledgerEarningSelected.key] = this.ledgerEarningSelected.amount
      this.isAddToPayrollLedgerEarning = false
    },
    addDeductionPayrollLedger() {
      // //console.log(this.ledgerDeductionSelected)
      let parameters = [].concat(this.ledgerDeductionSelected, this.payroll_ledger)
      this.isLoading = true
      this.$http.post(`${this.$host}/api/payroll/payroll_ledger/check-employee-deductions`,
        parameters,
        this.config
      ).then((response) => {
        if(response.data.is_diminishing == true && response.data.is_loan_available == true){
          if(response.data.start_date <= response.data.ref_date){
            if(response.data.amount <= response.data.balance){
              this.payroll_ledger.deductions[this.ledgerDeductionSelected.key] = this.ledgerDeductionSelected.amount
              this.isAddToPayrollLedgerDeduction = false
            }else{
              alert("The amount you entered is more than the remaining balance.")
            }
          }else{
            alert("This loan is only active for "+response.data.active_for+".")
          }
        }else{
          alert("Please create "+response.data.key+" for this employee first.")
        }
        this.isLoading = false
        this.$toast.open({
          message: response.data.message,
          type: 'is-success',
          position: 'is-bottom-right',
          duration: 4000
        })
      }).catch((error) => {
        this.isLoading = false
      });
    },
    savePayrollLedgersPopup() {
      this.$dialog.confirm({
        title: 'Save Payroll',
        message: "Are you sure you want to save this record? This action cannot be undone.",
        confirmText: 'Edit',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$dialog.confirm({
            title: 'Save Payroll',
            message: "Are you <b>really</b> sure you want to <b>save</b> this payroll?",
            confirmText: 'Yes',
            type: 'is-success',
            hasIcon: false,
              onConfirm: () => {
                this.isSavePayrollLedgers = true
              }
          })
        }
      })
    },
    closeSavePayrollLedgers() {
      this.isSavePayrollLedgers = false
      this.password = ''
      this.closeUpdatePayrollLedger()
    },
    savePayrollLedgers() {
      this.isLoading = true
      this.$http.post(`${this.$host}/api/payroll/payroll_ledger/save`,
        {
          password: this.password,
          ledger: this.payroll_ledger,
        },
        // this.payroll_ledger,
        this.config
      ).then((response) => {
        this.isLoading = false
        this.$toast.open({
          message: response.data.message,
          type: 'is-success',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.closeSavePayrollLedgers()
      }).catch((error) => {
        this.$toast.open({
          message: 'Wrong password.',
          type: 'is-danger',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.isLoading = false
      });
    },
    cancelEarningLedger(key) {
      this.$delete(this.payroll_ledger.earnings, key)
    },
    cancelDeductionLedger(key) {
      this.$delete(this.payroll_ledger.deductions, key)
    },
    overpaymentPopup(){
      this.isOverpayment = true
    },
    closeOverpaymentPopup() {
      this.isOverpayment = false
    },
    closeSaveOverpayment(){
      this.isSaveOverpayment = false
      this.password = ''
    },
    saveOverpaymentPopup(){
      this.$dialog.confirm({
        title: 'Save Overpayment',
        message: "Are you sure you want to edit this record? This action cannot be undone.",
        confirmText: 'Edit',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$dialog.confirm({
            title: 'Save Overpayment',
            message: "Are you <b>really</b> sure you want to <b>edit</b> payroll?",
            confirmText: 'Yes',
            type: 'is-success',
            hasIcon: false,
              onConfirm: () => {
                this.isSaveOverpayment = true
              }
          })
        }
      })
    },
    view(){
      //console.log(this.ledger[0].cos_jo_object.ewt_percentage);
    },
    saveOverpayment(){
      this.isLoading = true
      this.$http.post(`${this.$host}/api/payroll/overpayment/save`,
        {
          employee_id: this.employee_id,
          password: this.password,
          ledger: this.overpayment,
        },
        this.config
      ).then((response) => {
        this.isLoading = false
        this.$toast.open({
          message: response.data.message,
          type: 'is-success',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.closeSaveOverpayment()
        this.isOverpayment = false
      }).catch((error) => {
        this.$toast.open({
          message: 'Wrong password.',
          type: 'is-danger',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.isLoading = false
      });
    }
  }
}
</script>

<style>
.disable-select {
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}
</style>
