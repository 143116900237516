<template>
    <div>
      <div class="card">
        <div class="card-header"><p class="card-header-title">{{ header }}</p></div>
        <div class="card-content has-text-primary"><div class="content"><i class="fa fa-user-tie"></i> 67</div></div>
      </div>
    </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'dashboard',
  props: {
    header: String
  },
  components: {
    
  }
}
</script>

<style scoped>
  .content {
    text-align: center;
    font-size: 50px;
  }
</style>

