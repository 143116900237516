<template>
  <div>
    <div class="columns header" style="margin-bottom: 0 !important;">
      <div class="column is-8" style="margin-left: 5px; font-size: 20px; text-transform: uppercase;">
        <b>Add Position</b>
      </div>
      <div class="column is-auto">
        <router-link to="/position" class="button is-primary is-pulled-right" style="margin-left: 10px;" @click.prevent="isSearch = true">
          <i class="fa fa-sign"></i> &nbsp;Positions
        </router-link>
        <!-- <router-link to="/users/add" class="button is-success is-pulled-right" @click="add()">
          <i class="fa fa-plus"></i> &nbsp;New User
        </router-link> -->
      </div>
    </div>
    <hr style="margin-top: 0 !important; margin-bottom: 0 !important;" class="has-background-grey-lighter">
    <br>
    <!-- <hr style="margin-top: 0 !important; margin-bottom: 0 !important;" class="has-background-grey-lighter"> -->
    <transition name="fade">
      <PositionForm :data="selected" v-if="!isSearch" :isAdd="isAdd"/>
    </transition>
  </div>
</template>

<script>
import Search from '../components/position/Search.vue'
import PositionForm from '../components/position/PositionForm.vue'

export default {
  components: {
    Search,
    PositionForm
  },
  data() {
    return {
      isSearch: false,
      data: {},
      searchLoad: true,
      selected: {},
      name: '',
      isAdd: true
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
