<template>
  <div class="box">
    <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
    <div>
      <b-field>
        <b-select placeholder="Date" v-model="perDate" @input="getPHILHEALTH()">
          <option
            v-for="date in dates"
            :value="date"
            :key="date">
            {{ date }}
          </option>
        </b-select>&nbsp;
        <button class="button is-success" @click.prevent="isAddDate = true" v-if="$role.state.permissions['write philhealth']">
          <span><i class="fa fa-plus"></i> Add Date</span>
        </button>
      </b-field>
    </div>
    <hr>
    <b-field class="is-pulled-right is-hidden-touch">
      <b-field style="margin-top: 5px;" label="Contribution Percentage:"></b-field>&nbsp;
      <b-input v-model="contributionPercent" disabled></b-input>
      <button class="button is-primary" @click.prevent="editPercentage()" v-if="$role.state.permissions['write philhealth']" :disabled="!perDate">
        <span><i class="fa fa-pen"></i></span>
      </button>
    </b-field>
    <b-field>
      <b-select placeholder="Per Page" v-model="perPage">
        <option value="5">5 per page</option>
        <option value="10">10 per page</option>
        <option value="20">20 per page</option>
        <option value="50">50 per page</option>
      </b-select>&nbsp;
      <button class="button is-success" @click.prevent="addPHILHEALTH()" v-if="$role.state.permissions['write philhealth']" :disabled="!perDate">
        <span><i class="fa fa-plus"></i> Add PHILHEALTH</span>
      </button>&nbsp;
      <button class="button is-danger" @click.prevent="deleteTable()" v-if="$role.state.permissions['write philhealth']" :disabled="!perDate">
        <span><i class="fa fa-trash"></i> Delete Table</span>
      </button>
    </b-field>
    <b-field class="is-hidden-desktop">
      <b-input v-model="contributionPercent" disabled></b-input>
      <button class="button is-primary" @click.prevent="editPercentage()" v-if="$role.state.permissions['write philhealth']" :disabled="!perDate">
        <span><i class="fa fa-pen"></i></span>
      </button>
    </b-field>
    <b-table
      :data="philhealthes"
      :striped=true
      :bordered=true
      :hoverable=true
      :paginated="true"
      :per-page="perPage">
      <template slot-scope="props">
        <b-table-column label="Minimum Salary">
          {{ props.row.minimum_salary }}
        </b-table-column>
        <b-table-column label="Maximum Salary">
          {{ props.row.maximum_salary }}
        </b-table-column>
        <b-table-column label="Salary Base">
          {{ props.row.salary_base }}
        </b-table-column>
        <b-table-column label="Monthly">
          <span>{{ calculateMonthly(props.row.minimum_salary, props.row.maximum_salary, props.row.salary_base) }}</span>
        </b-table-column>
        <b-table-column label="Actions">
          <button class="button btn-action is-primary is-small" @click.prevent="addPHILHEALTH(props.row)"><i class="fa fa-pen"></i></button>
          <button class="button btn-action is-danger is-small" @click.prevent="deletePHILHEALTH(props.row)"><i class="fa fa-trash"></i></button>
        </b-table-column>
      </template>
      <template slot="empty">
        <b-table-column label="Minimum Salary">
        </b-table-column>
        <b-table-column label="Maximum Salary">
        </b-table-column>
        <b-table-column label="Salary Base">
        </b-table-column>
        <b-table-column label="Monthly">
        </b-table-column>
        <b-table-column label="Actions">
        </b-table-column>
      </template>
    </b-table>

    <b-modal :active.sync="isAddDate" has-modal-card :canCancel=false>
      <transition name="fade">
        <form action="">
          <div class="modal-card" style="height: 33rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">Add Date</p>
            </header>
            <section class="modal-card-body">
              <b-field label="Date">
                <b-datepicker
                  icon="calendar-today"
                  v-model="date"
                  :date-formatter="d => d.toLocaleDateString('en-GB')"
                  expanded required>
                </b-datepicker>
              </b-field><br><br>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" @click.prevent="saveDate()">Save</button>
              <button class="button is-danger" type="button" @click.prevent="closeDate()">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>

    <b-modal :active.sync="isSave" has-modal-card :canCancel=false>
      <transition name="fade">
        <form @submit.prevent="save()">
          <div class="modal-card" style="height: 33rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">{{ state }} PHILHEALTH</p>
            </header>
            <section class="modal-card-body">
              <b-field label="Minimum Salary">
                <b-input
                  v-model="philhealth.minimum_salary"
                  type="number"
                  step="0.01"
                  min="0.01"
                  expanded>
                </b-input>
              </b-field>
              <b-field label="Maximum Salary">
                <b-input
                  v-model="philhealth.maximum_salary"
                  type="number"
                  step="0.01"
                  min="0.01"
                  expanded>
                </b-input>
              </b-field>
              <b-field label="Salary Base">
                <b-input
                  v-model="philhealth.salary_base"
                  type="number"
                  step="0.01"
                  min="0.00"
                  expanded required>
                </b-input>
              </b-field>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" type="submit">Save</button>
              <button class="button is-danger" type="button" @click.prevent="close()">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>

    <b-modal :active.sync="isPercent" has-modal-card :canCancel=false>
      <transition name="fade">
        <form @submit.prevent="savePercentage()">
          <div class="modal-card">
            <header class="modal-card-head">
              <p class="modal-card-title">Edit Contribution Percentage</p>
            </header>
            <section class="modal-card-body">
              <b-field label="Contribution Percentage">
                <b-input
                  v-model="contribution_percentage"
                  type="number"
                  step="0.01"
                  min="0.00"
                  expanded required>
                </b-input>
              </b-field>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" type="submit">Save</button>
              <button class="button is-danger" type="button" @click.prevent="closePercentage()">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  data() {
    return {
      config: '',
      isLoading: false,
      perDate: null,
      dates: [],
      state: '',
      isSave: false,
      isAddDate: false,
      date: new Date(),
      philhealthes: [],
      philhealth: {},
      contribution_percentage: 0.00,
      isPercent: false,
      perPage: 5
    }
  },
  mounted() {
    this.config = {
      headers: {'Authorization': "Bearer " + this.$store.state.token}
    };

    this.getDate()
  },
  computed: {
    contributionPercent() {
      return this.contribution_percentage + "%"
    }
  },
  methods: {
    calculateMonthly(min, max, base) {
      let percent = this.contribution_percentage * 0.01
      let minimum_salary
      let maximum_salary

      if (min) {
        minimum_salary = Math.round(min * percent)
      }
      else {
        minimum_salary = Math.round(base * percent)
      }

      if (max) {
        maximum_salary = Math.round(max * percent)
      }
      else {
        maximum_salary = Math.round(base * percent)
      }

      if (!min) {
        return minimum_salary
      }
      else if(!max) {
        return maximum_salary
      }
      else {
        return `${minimum_salary} TO ${maximum_salary}`
      }
    },
    getDate() {
      this.isLoading = true

      this.$http.get(`${this.$host}/api/parameter/payroll`,
        this.config
      ).then((response) => {
        let data = response.data
        this.dates = data.philhealth
        this.perDate = this.dates[this.dates.length - 1]

        this.$http.get(`${this.$host}/api/payroll/philhealth/search/${this.perDate}`,
          this.config
        ).then((response) => {
          let data = response.data
          this.philhealthes = data.array
          this.contribution_percentage = data.contribution_percentage
          this.isLoading = false
        }).catch((error) => {
          this.isLoading = false
          //console.log(error)
        });
      }).catch((error) => {
        this.isLoading = false
        //console.log(error)
      });
    },
    addDate() {

    },
    saveDate() {
      this.isLoading = true

      this.$http.post(`${this.$host}/api/payroll/philhealth/save/`,
        {
          effective_date: moment(this.date).format("YYYY-MM-DD"),
          maximum_salary: null,
          minimum_salary: null,
          salary_base: 0.00,
          contribution_percentage: 0.00
        },
        this.config,
      ).then((response) => {
        let data = response.data.data
        this.$toast.open({
          message: 'Data successfully saved.',
          type: 'is-success',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.isLoading = false
        this.closeDate()
      }).catch((error) => {
        this.$toast.open({
          message: 'Error saving data.',
          type: 'is-danger',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.isLoading = false
      });
    },
    closeDate() {
      this.getDate()
      this.isAddDate = false
      this.date = new Date()
    },
    getPHILHEALTH() {
      this.isLoading = true

      this.$http.get(`${this.$host}/api/payroll/philhealth/search/${this.perDate}`,
        this.config
      ).then((response) => {
        let data = response.data
        this.philhealthes = data.array
        this.contribution_percentage = data.contribution_percentage
        this.isLoading = false
      }).catch((error) => {
        this.isLoading = false
        //console.log(error)
      });
    },
    addPHILHEALTH(obj) {
      if (obj) {
        this.philhealth = obj
        this.state = 'Edit'
      }
      else {
        this.state = 'Add'
      }

      this.isSave = true
    },
    save() {
      this.isLoading = true

      let id = ''
      if (this.philhealth.id) {
        id = this.philhealth.id
      }

      if (parseFloat(this.philhealth.minimum_salary) > parseFloat(this.philhealth.maximum_salary)) {
        this.$toast.open({
          message: 'Error: Minimum salary is greater than max salary.',
          type: 'is-danger',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.isLoading = false
      }
      else {
        this.$http.post(`${this.$host}/api/payroll/philhealth/save/${id}`,
          {
            effective_date: this.perDate,
            maximum_salary: this.philhealth.maximum_salary,
            minimum_salary: this.philhealth.minimum_salary,
            salary_base: this.philhealth.salary_base,
            contribution_percentage: this.contribution_percentage
          },
          this.config,
        ).then((response) => {
          let data = response.data.data
          this.$toast.open({
            message: 'Data successfully saved.',
            type: 'is-success',
            position: 'is-bottom-right',
            duration: 4000
          })
          //console.log(data)
          this.isLoading = false
          this.close()
        }).catch((error) => {
          this.$toast.open({
            message: 'Error saving data.',
            type: 'is-danger',
            position: 'is-bottom-right',
            duration: 4000
          })
          this.isLoading = false
        });
      }
    },
    deletePHILHEALTH(obj) {
      this.$dialog.confirm({
        title: 'Delete Table',
        message: "Are you sure you want to delete this row? This action cannot be undone.",
        confirmText: 'Delete',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isLoading = true

          this.$http.delete(`${this.$host}/api/payroll/philhealth/delete/${obj.id}`,
            this.config
          ).then((response) => {
            this.$toast.open({
              message: 'Row deleted.',
              type: 'is-success',
              position: 'is-bottom-right',
              duration: 4000
            })
            this.isLoading = false
            this.getDate()
          }).catch((error) => {
            this.$toast.open({
              message: 'Error deleting row.',
              type: 'is-danger',
              position: 'is-bottom-right',
              duration: 4000
            })
            this.isLoading = false
          });
        }
      })
    },
    deleteTable() {
      this.$dialog.confirm({
        title: 'Delete Table',
        message: "Are you sure you want to delete this table? This action cannot be undone.",
        confirmText: 'Delete',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isLoading = true

          this.$http.delete(`${this.$host}/api/payroll/philhealth/remove/${this.perDate}`,
            this.config
          ).then((response) => {
            this.$toast.open({
              message: 'PHILHEALTH table deleted.',
              type: 'is-success',
              position: 'is-bottom-right',
              duration: 4000
            })
            this.isLoading = false
            this.getPHILHEALTH()
            this.getDate()
          }).catch((error) => {
            this.$toast.open({
              message: 'Error deleting table.',
              type: 'is-danger',
              position: 'is-bottom-right',
              duration: 4000
            })
            this.isLoading = false
          });
        }
      })
    },
    editPercentage() {
      this.isPercent = true
    },
    savePercentage() {
      this.isLoading = true

      this.$http.put(`${this.$host}/api/payroll/philhealth/change/${this.perDate}`,
        {
          contribution_percentage: this.contribution_percentage
        },
        this.config,
      ).then((response) => {
        let data = response.data.data
        this.$toast.open({
          message: 'Data successfully saved.',
          type: 'is-success',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.isLoading = false
        this.closePercentage()
      }).catch((error) => {
        this.$toast.open({
          message: 'Error saving data.',
          type: 'is-danger',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.isLoading = false
      });
    },
    closePercentage() {
      this.isPercent = false
      this.getPHILHEALTH()
    },
    close() {
      this.getPHILHEALTH()
      this.isSave = false
      this.philhealth = {

      }
    }
  }
}
</script>

<style>

</style>
