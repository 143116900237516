<template>
  <div class="box">
    <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="true"></b-loading>
    <div class="content columns">
      <div class="column is-4">
        <b-field label="USER INFORMATION">
        </b-field>
        <b-field label="Employee Information" v-if="!isAdd">
        </b-field>
        {{ user.employee_number }}
        {{ user.last_name }}
        {{ user.first_name }}
        {{ user.middle_name }}
        {{ user.name_extension }}
        <!-- <b-field>
          <button class="button is-primary" @click.prevent="showEmployee" :disabled="isDisabled">
            <i class="fa fa-user-tie"></i> &nbsp;Select Employee
          </button>
        </b-field> -->
        <b-field label="Username">
          <b-input class="no-transform" v-model="user.username" :disabled="isDisabled"></b-input>
        </b-field>
        <b-field label="Email">
          <b-input class="no-transform" v-model="user.email" :disabled="isDisabled"></b-input>
        </b-field>
        <b-field label="Password">
          <b-input type="password" v-model="user.password" :disabled="isDisabled"></b-input>
        </b-field>
        <b-field label="ROLE">
        </b-field>
        <b-select placeholder="Select Role" v-model="user.roles[0]" :disabled="isDisabled" @input="getPermissions(user.roles[0])" expanded>
          <option value="Administrator">
            Administrator
          </option>
          <option value="CSD">
            CSD
          </option>
          <option value="Employee">
            Employee
          </option>
          <option value="Human Resource">
            Human Resource
          </option>
          <option value="Payroll">
            Payroll
          </option>
          <option value="Kiosk">
            Kiosk
          </option>
        </b-select><br>
        <b-radio v-model="user.active"
          native-value="1" :disabled="isDisabled">
          Active
        </b-radio>
        <b-radio v-model="user.active"
          native-value="0" :disabled="isDisabled">
          Inactive
        </b-radio>
      </div>
      <div class="column is-auto">
        <b-field label="PERMISSIONS">
        </b-field>
        <!-- <b-checkbox v-model="user.permissions"
          native-value="read users" :disabled="isDisabled">
          Read User
        </b-checkbox><br> -->
        <b-field>
          <b-select @input="addPermission()" placeholder="Select Permission" v-model="selectedPermission" expanded :disabled="isDisabled">
            <optgroup v-for="(permissions, key) in parameters.permissions" :label="key | ucwords" v-bind:key="key">
              <option
                v-for="option in permissions"
                :value="option"
                :key="option">
                {{ option }}
              </option>
            </optgroup>
            <!-- <optgroup label="Position">
              <option
                v-for="option in parameters.permissions.position"
                :value="option"
                :key="option">
                {{ option }}
              </option>
            </optgroup>
            <optgroup label="Salary Grade">
              <option
                v-for="option in parameters.permissions['salary grade']"
                :value="option"
                :key="option">
                {{ option }}
              </option>
            </optgroup>
            <optgroup label="Security">
              <option
                v-for="option in parameters.permissions.security"
                :value="option"
                :key="option">
                {{ option }}
              </option>
            </optgroup>
            <optgroup label="Service Record">
              <option
                v-for="option in parameters.permissions['service record']"
                :value="option"
                :key="option">
                {{ option }}
              </option>
            </optgroup>
            <optgroup label="Special Dates">
              <option
                v-for="option in parameters.permissions['special dates']"
                :value="option"
                :key="option">
                {{ option }}
              </option>
            </optgroup>
            <optgroup label="Activity Log">
              <option
                v-for="option in parameters.permissions['activity log']"
                :value="option"
                :key="option">
                {{ option }}
              </option>
            </optgroup>
            <optgroup label="Time Log">
              <option
                v-for="option in parameters.permissions['time log']"
                :value="option"
                :key="option">
                {{ option }}
              </option>
            </optgroup>
            <optgroup label="Daily Time Record">
              <option
                v-for="option in parameters.permissions['daily time record']"
                :value="option"
                :key="option">
                {{ option }}
              </option>
            </optgroup>
            <optgroup label="User">
              <option
                v-for="option in parameters.permissions.user"
                :value="option"
                :key="option">
                {{ option }}
              </option>
            </optgroup>
            <optgroup label="Schedule Template">
              <option
                v-for="option in parameters.permissions['work schedule template']"
                :value="option"
                :key="option">
                {{ option }}
              </option>
            </optgroup>
            <optgroup label="Schedule">
              <option
                v-for="option in parameters.permissions.schedule"
                :value="option"
                :key="option">
                {{ option }}
              </option>
            </optgroup>
            <optgroup label="Request">
              <option
                v-for="option in parameters.permissions.request"
                :value="option"
                :key="option">
                {{ option }}
              </option>
            </optgroup>
            <optgroup label="Leave Ledger">
              <option
                v-for="option in parameters.permissions['leave ledger']"
                :value="option"
                :key="option">
                {{ option }}
              </option>
            </optgroup>
            <optgroup label="Training">
              <option
                v-for="option in parameters.permissions.training"
                :value="option"
                :key="option">
                {{ option }}
              </option>
            </optgroup>
            <optgroup label="Appraisal">
              <option
                v-for="option in parameters.permissions.appraisal"
                :value="option"
                :key="option">
                {{ option }}
              </option>
            </optgroup>
            <optgroup label="Award">
              <option
                v-for="option in parameters.permissions.award"
                :value="option"
                :key="option">
                {{ option }}
              </option>
            </optgroup>
            <optgroup label="Offense">
              <option
                v-for="option in parameters.permissions.offense"
                :value="option"
                :key="option">
                {{ option }}
              </option>
            </optgroup>
            <optgroup label="Payroll">
              <option
                v-for="option in parameters.permissions.payroll"
                :value="option"
                :key="option">
                {{ option }}
              </option>
            </optgroup>
            <optgroup label="Payslip">
              <option
                v-for="option in parameters.permissions.payslip"
                :value="option"
                :key="option">
                {{ option }}
              </option>
            </optgroup>
            <optgroup label="Tax">
              <option
                v-for="option in parameters.permissions.tax"
                :value="option"
                :key="option">
                {{ option }}
              </option>
            </optgroup>
            <optgroup label="GSIS">
              <option
                v-for="option in parameters.permissions.gsis"
                :value="option"
                :key="option">
                {{ option }}
              </option>
            </optgroup>
            <optgroup label="SSS">
              <option
                v-for="option in parameters.permissions.sss"
                :value="option"
                :key="option">
                {{ option }}
              </option>
            </optgroup>
            <optgroup label="PHILHEALTH">
              <option
                v-for="option in parameters.permissions.philhealth"
                :value="option"
                :key="option">
                {{ option }}
              </option>
            </optgroup>
            <optgroup label="PAG-IBIG">
              <option
                v-for="option in parameters.permissions.pagibig"
                :value="option"
                :key="option">
                {{ option }}
              </option>
            </optgroup>
            <optgroup label="Kiosk">
              <option
                v-for="option in parameters.permissions.kiosk"
                :value="option"
                :key="option">
                {{ option }}
              </option>
            </optgroup>
            <optgroup label="Template">
              <option
                v-for="option in parameters.permissions.template"
                :value="option"
                :key="option">
                {{ option }}
              </option>
            </optgroup> -->
          </b-select>
        </b-field>
        <b-field grouped group-multiline>
          <div class="control" v-for="permission in user.permissions" :key="permission" :value="permission">
            <b-tag v-if="showPermission" type="is-primary" closable @close="removePermission(permission)" :disabled="isDisabled">{{ permission }}</b-tag>
          </div>
        </b-field>
      </div>
    </div>
    <br>
    <button class="button is-success" @click.prevent="isDisabled ? edit() : save()" v-if="!isAdd && $role.state.permissions['write user']">
      {{ editSave }}
    </button>
    <button class="button is-success" @click.prevent="save()" v-if="isAdd && $role.state.permissions['write user']">
      Save
    </button>
    <button class="button is-danger" @click.prevent="cancelPrompt" :disabled="cancelDisabled" v-if="!isAdd && $role.state.permissions['write user']">
      Cancel
    </button>
    <transition name="fade">
      <EmployeeSearch search-title="Employee" @close="closeEmployee" @view="setEmpId" v-if="isEmployeeSearch" :isUser=true />
    </transition>
  </div>
</template>

<script>
import EmployeeSearch from '../SearchModal.vue'

export default {
  props: ['user', 'isAdd', 'currentUsername', 'currentEmail'],
  components: {
    EmployeeSearch
  },
  filters: {
    ucwords: function (value) {
        if (!value) return ''
        value = value.toLowerCase().replace(/\b[a-z]/g, function(letter) {
            return letter.toUpperCase()
        });
        return value;
    }
  },
  data() {
    return {
      cancelDisabled: true,
      isDisabled: true,
      editSave: 'Edit',
      isEmployeeSearch: false,
      newUser: {
        employee_id: '',
        username: '',
        email: '',
        password: '',
        roles: [],
        permissions: [],
      },
      parameters: {
        roles: {},
        permissions: {}
      },
      selectedPermission: null,
      isLoading: false,
      showPermission: true
    }
  },
  beforeMount() {
    if (this.user == null) {
      this.isDisabled = false
      this.user.permissions = []
    }
  },
  mounted() {
    if (this.isAdd == true) {
      this.isDisabled = false
      this.user.employee_number = ''
      this.user.permissions = []
      this.user.id = ''
      this.user.roles = [null]
    }
    // this.currentUsername = this.user.username
    // this.currentEmail = this.user.email
    this.getParams()
  },
  methods: {
    addPermission() {
      if (this.user.permissions.find(x => x === this.selectedPermission) == undefined) {
        this.user.permissions.push(this.selectedPermission)
      }
    },
    getPermissions(role) {
      this.user.permissions = this.parameters.roles[role]
      this.isLoading = true
      this.showPermission = false
      setTimeout(() => {
        this.isLoading = false
        this.showPermission = true
      }, 1000)
    },
    removePermission(permission) {
      this.user.permissions = this.user.permissions.filter(x => x !== permission)
      this.isLoading = true
      this.showPermission = false
      this.selectedPermission = null
      setTimeout(() => {
        this.isLoading = false
        this.showPermission = true
      }, 200)
    },
    getParams() {
      let config = {
        headers: {'Authorization': "Bearer " + this.$store.state.token}
      };

      this.$http.get(`${this.$host}/api/parameter/user`,
        config
      ).then((response) => {
        let data = response.data
        this.parameters = data
      }).catch((error) => {
        //console.log(error)
      });
    },
    setEmpId(obj) {
      this.user.employee_number = obj.employee_number
      this.newUser.employee_number = obj.employee_number
      this.closeEmployee()
    },
    showEmployee() {
      this.isEmployeeSearch = true
    },
    closeEmployee() {
      this.isEmployeeSearch = false
    },
    cancel() {
      this.$editmode.commit('editing', false)
      this.editSave = "Edit"
      this.isDisabled = true
      this.cancelDisabled= true
      this.$emit('reset', this.user.id)
    },
    cancelPrompt() {
      this.$dialog.confirm({
        title: 'Discard Changes',
        message: 'Are you sure you want to <b>discard</b> your changes? This action cannot be undone.',
        confirmText: 'Discard',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.cancel()
      })
    },
    edit() {
      this.$editmode.commit('editing', true)
      this.editSave = "Save"
      this.isDisabled = false
      this.cancelDisabled = false
    },
    save() {
      this.$dialog.confirm({
        title: 'Save',
        message: '<b>Save</b> your changes?',
        confirmText: 'Save',
        type: 'is-success',
        hasIcon: true,
        onConfirm: () => this.confirmSave()
      })
    },
    confirmSave() {
      delete this.user.created_at
      delete this.user.update_at
      if (this.user.username == this.currentUsername) {
        delete this.user.username
      }
      if (this.user.email == this.currentEmail) {
        delete this.user.email
      }

      this.isLoading = true
      let config = {
        headers: {'Authorization': "Bearer " + this.$store.state.token}
      };
      this.$http.post(`${this.$host}/api/user/save/${this.user.id}`,
        this.user,
        config,
      ).then((response) => {
        let data = response.data.data
        this.user.username = data.username
        this.user.email = data.email
        this.currentUsername = data.username
        this.currentEmail = data.email
        this.$editmode.commit('editing', false)
        if (this.isAdd) {
          this.$editmode.commit('setNewUserId', response.data.data.id)
          this.$editmode.commit('setUserAdd', true)
          this.$router.push('/users')
        }
        this.successSave()

        //console.log(data)
        this.isLoading = false
      }).catch((error) => {
        this.errorSave()

        //console.log(error)
        this.isLoading = false
      });
      //console.log(JSON.stringify(this.user))
    },
    errorSave() {
      this.editSave = "Save"
      this.isDisabled = false
      this.cancelDisabled = false
      this.$toast.open({
        message: 'Error saving data. Please make sure to fill the required fields.',
        type: 'is-danger',
        position: 'is-bottom-right',
        duration: 4000
      })
    },
    successSave() {
      this.$editmode.commit('editing', false)
      this.editSave = "Edit"
      this.isDisabled = true
      this.cancelDisabled = true
      this.$toast.open({
        message: 'User successfully saved.',
        type: 'is-success',
        position: 'is-bottom-right',
        duration: 4000
      })
    }
  }
}
</script>

<style scoped>
.no-transform {
  text-transform: none;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
