<template>
  <div class="main">
    <div class="center">
      <img src="/img/dotr-logo.png"/>
      <progress class="progress is-primary is-small" :value="this.$preload.state.progress" max="100">{{ this.$preload.state.progress }}%</progress>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>

img {
  width: 200px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}
.center {
  width: 25%;
  position: fixed;
  margin-top: 0 !important;
  margin: 0;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 2147483646;
}

.main {
  top: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background: white;
  z-index: 2147483645;
}

.progress {
  height: 8px !important;
}

.progress::-webkit-progress-value {
  transition: width 0.5s ease;
}

@media screen and (min-width: 1px) and (max-width: 1215px)  {
  .center {
    width: 60%;
  }
}

</style>