<template>
  <div>
    <button class="button is-primary" @click.prevent="addWork()" :disabled="isDisabled">
      Add Work Experience
    </button>
    <b-table
      :data="data"
      focusable>  
      <template slot-scope="props">
        <b-table-column label="Position">
          {{ props.row.position }}
        </b-table-column>
        <b-table-column label="Date From">
          {{ dateFormat(props.row.date_from) }}
        </b-table-column>
        <b-table-column label="Date To">
          {{ dateFormatNull(props.row.date_to) }}
        </b-table-column>
        <b-table-column label="Company">
          {{ props.row.company }}
        </b-table-column>
        <b-table-column label="Government">
          {{ yesNo(props.row.is_government_service) }}
        </b-table-column>
        <b-table-column label="Sector">
          {{ props.row.sector }}
        </b-table-column>
        <b-table-column label="Salary">
          {{ props.row.salary }}
        </b-table-column>
        <b-table-column label="Pay Grade">
          {{ props.row.pay_grade }}
        </b-table-column>
        <b-table-column label="Status of Appointment">
          {{ props.row.status_of_appointment }}
        </b-table-column>
        <b-table-column label="Actions">
          <button class="button is-small btn-action is-primary" @click.prevent="editRow(props.row)" :disabled="isDisabled"><i class="fa fa-pen"></i></button>
          <button class="button is-small btn-action is-danger" @click.prevent="deleteRow(props.row)" :disabled="isDisabled"><i class="fa fa-trash"></i></button>
        </b-table-column>
      </template>
      <template slot="empty">
        <b-table-column label="Position">
        </b-table-column>
        <b-table-column label="Date From">
        </b-table-column>
        <b-table-column label="Date To">
        </b-table-column>
        <b-table-column label="Company">
        </b-table-column>
        <b-table-column label="Government">
        </b-table-column>
        <b-table-column label="Sector">
        </b-table-column>
        <b-table-column label="Salary">
        </b-table-column>
        <b-table-column label="Pay Grade">
        </b-table-column>
        <b-table-column label="Status of Appointment">
        </b-table-column>
        <b-table-column label="Actions">
        </b-table-column>
      </template>
    </b-table>
    <b-modal :active.sync="isComponentModalActive" has-modal-card :canCancel=false>
      <transition name="fade">
        <addedit-form :obj="work" :state="state" @save="saveWork" @cancel="cancel"></addedit-form>
      </transition>
    </b-modal>
  </div>
</template>

<script>
const addeditForm = {
  props: {
    obj: Object,
    state: String
  },
  template: `
  <form @submit.prevent="$emit('save', obj)">
    <div class="modal-card" style="height: 35.625rem; margin-top: 4rem;">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ state }}</p>
      </header>
      <section class="modal-card-body">
        <b-field label="Position">
          <b-autocomplete
            v-model="obj.position"
            :data="filteredPosition"
            placeholder="Position"
            required>
          </b-autocomplete>
        </b-field>
        <b-field label="Date From">
          <b-datepicker
            placeholder="Click to select..."
            icon="calendar-today"
            v-model="obj.date_from"
            :date-formatter="d => d.toLocaleDateString('en-GB')"
            :date-parser="d => parseDate(d)"
            editable
            required>
          </b-datepicker>
        </b-field>
        <b-field label="Date To">
          <b-datepicker
            placeholder="Click to select..."
            icon="calendar-today"
            v-model="obj.date_to"
            :date-formatter="d => d.toLocaleDateString('en-GB')"
            :date-parser="d => parseDate(d)"
            editable>
          </b-datepicker>
        </b-field>
        <b-field label="Company">
          <b-autocomplete
            v-model="obj.company"
            :data="filteredCompany"
            placeholder="Company"
            required>
          </b-autocomplete>
        </b-field>
        <b-field label="Government">
          <b-select v-model="obj.is_government_service">
            <option value="1">Yes</option>
            <option value="0">No</option>
          </b-select>
        </b-field>
        <b-field label="Sector">
          <b-input v-model="obj.sector" required></b-input>
        </b-field>
        <b-field label="Salary">
          <b-input v-model="obj.salary" required></b-input>
        </b-field>
        <b-field label="Pay Grade">
          <b-input v-model="obj.pay_grade" required></b-input>
        </b-field>
        <b-field label="Status of Appointment">
          <b-autocomplete
            v-model="obj.status_of_appointment"
            :data="filteredStatus"
            placeholder="Status of Appointment">
          </b-autocomplete>
        </b-field>
        <br><br>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-success" type="submit">{{ state }}</button>
        <button class="button is-danger" @click.prevent="$emit('cancel')">Cancel</button>
      </footer>
    </div>
  </form>`,
  computed: {
    filteredPosition() {
      return this.$suggestions.state.workexperiences.position.filter((option) => {
        return option
          .toString()
          .toLowerCase()
          .indexOf(this.obj.position.toLowerCase()) >= 0
      })
    },
    filteredCompany() {
      return this.$suggestions.state.workexperiences.company.filter((option) => {
        return option
          .toString()
          .toLowerCase()
          .indexOf(this.obj.company.toLowerCase()) >= 0
      })
    },
    filteredStatus() {
      return this.$suggestions.state.workexperiences.status_of_appointment.filter((option) => {
        return option
          .toString()
          .toLowerCase()
          .indexOf(this.obj.status_of_appointment.toLowerCase()) >= 0
      })
    },
  },
  methods: {
    parseDate(d) {
      let parts = d.split("/")
      let dt = new Date(parseInt(parts[2], 10),
        parseInt(parts[1], 10) - 1,
        parseInt(parts[0], 10))
      return dt
    },
  }
}

import VueSimpleSuggest from 'vue-simple-suggest'
import '../../../css/styles.css'

export default {
  props: ["isDisabled", "data"],
  data() {
    return {
      isComponentModalActive: false,
      work: {},
      state: '',
      previousData: {}
    }
  },
  components: {
    addeditForm,
    VueSimpleSuggest
  },
  methods: {
    dateFormat(d) {
      d = new Date(d)
      return d.toLocaleDateString('en-GB')
    },
    dateFormatNull(d) {
      if (!d) {
        return 'Present'
      }
      else {
        d = new Date(d)
        return d.toLocaleDateString('en-GB')
      }
    },
    returnData() {
      let work = {
        workexperiences: this.data
      }
      this.$emit('work', work)
    },
    yesNo(bool) {
      if (bool == 1) {
        return 'Yes'
      }
      else {
        return 'No'
      }
    },
    addWork() {
      this.work = {
        id: null,
        position: '',
        date_from: new Date(),
        date_to: new Date(),
        company: '',
        is_government_service: '',
        sector: '',
        salary: '',
        pay_grade: '',
        status_of_appointment: ''
      }
      this.state = 'Add'
      this.isComponentModalActive = true
    },
    saveWork(obj) {
      if (this.state == 'Add') {
        this.data.push({
          id: obj.id,
          position: obj.position,
          date_from: obj.date_from,
          date_to: obj.date_to,
          company: obj.company,
          is_government_service: obj.is_government_service,
          sector: obj.sector,
          salary: obj.salary,
          pay_grade: obj.pay_grade,
          status_of_appointment: obj.status_of_appointment
        });
      }
      this.close()
    },
    close() {
      this.isComponentModalActive = false
    },
    cancel() {
      this.isComponentModalActive = false
      if (this.state == 'Edit') {
        let index = this.data.findIndex(x => x.id === this.previousData.id)
        this.data[index] = this.previousData
      }
    },
    editRow(obj) {
      this.work = obj
      this.work.date_from = new Date(obj.date_from)
      if (obj.date_to) { 
        this.work.date_to = new Date(obj.date_to)
      }
      this.state = 'Edit'
      this.previousData = JSON.parse(JSON.stringify(obj))
      this.isComponentModalActive = true
    },
    deleteRow(obj) {
      this.data = this.data.filter(x => {
        return x != obj;
      })
    },
  }
}
</script>
