<template>
  <div class="box">
    <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
    <div>
      <button class="button is-primary" @click.prevent="assign()" v-if="$role.state.permissions['write payroll'] || $role.state.permissions['write payroll funding source']">
        <span><i class="fa fa-user-plus"></i> Assign Payroll Setting</span>
      </button>
    </div>

    <b-modal :active.sync="isAssign" has-modal-card :canCancel=false>
      <transition name="fade">
        <form @submit.prevent="save()">
          <div class="modal-card pos" style="width: 60rem; height: 35.625rem; margin-left: 100px;">
            <header class="modal-card-head">
              <p class="modal-card-title">Assign Payroll Setting</p>
            </header>
            <section class="modal-card-body">
              <b-field label="Selected">
                <button class="button is-primary" @click.prevent="isSelect = true" v-if="$role.state.permissions['write payroll'] || $role.state.permissions['write payroll funding source']">
                   <span><i class="fa fa-user-plus"></i> Select Employee</span>
                </button>
              </b-field>
              <!-- <b-input type="textarea" v-model="selected" disabled></b-input> -->
              <div style="overflow: auto; max-height: 10rem;">
                <b-tag v-for="emp in checkedRows" :key="emp.id" type="is-primary" closable @close="removeSelected(emp)"
                style="margin-right: 5px; margin-top:4px;">
                  {{ emp.fullname }}
                </b-tag>
              </div>
              <br>
              <b-field label="Bank Name">
                <b-input
                  v-model="setting.bank_name"
                  expanded>
                </b-input>
              </b-field>
              <b-field label="Bank Branch">
                <b-input
                  v-model="setting.bank_branch"
                  expanded>
                </b-input>
              </b-field>
              <b-field label="GMP Percentage">
                <b-input
                  v-model="setting.gmp_percentage"
                  expanded>
                </b-input>
              </b-field>
              <b-field label="EWT Percentage">
                <b-input
                  v-model="setting.ewt_percentage"
                  expanded>
                </b-input>
              </b-field>
              <b-field label="CVAT Percentage">
                <b-input
                  v-model="setting.cvat_percentage"
                  expanded>
                </b-input>
              </b-field>
              <b-field label="Buffer Tax">
                <b-input
                  v-model="setting.buffer_tax"
                  expanded>
                </b-input>
              </b-field>
              <b-field label="Funding Source">
                <b-select v-model="setting.funding_source_id" expanded>
                  <option v-for="source in sources" :key="source.id" :value="source.id">{{ source.code }}</option>
                </b-select>
              </b-field>
              <b-field label="Part of Payroll? ">
                <b-radio v-model="setting.is_part_of_payroll"
                    name="is_part_of_payroll"
                    native-value="1">
                    Yes
                </b-radio>
                <b-radio v-model="setting.is_part_of_payroll"
                    name="is_part_of_payroll"
                    native-value="0">
                    No
                </b-radio>
              </b-field>
              <b-field label="Has GSIS Deduction? ">
                <b-radio v-model="setting.is_gsis_deduction"
                    name="is_gsis_deduction"
                    native-value="1">
                    Yes
                </b-radio>
                <b-radio v-model="setting.is_gsis_deduction"
                    name="is_gsis_deduction"
                    native-value="0">
                    No
                </b-radio>
              </b-field>
              <b-field label="Has PHILHEALTH Deduction? ">
                <b-radio v-model="setting.is_philhealth_deduction"
                    name="is_philhealth_deduction"
                    native-value="1">
                    Yes
                </b-radio>
                <b-radio v-model="setting.is_philhealth_deduction"
                    name="is_philhealth_deduction"
                    native-value="0">
                    No
                </b-radio>
              </b-field>
              <b-field label="Has PAGIBIG Deduction? ">
                <b-radio v-model="setting.is_pagibig_deduction"
                    name="is_pagibig_deduction"
                    native-value="1">
                    Yes
                </b-radio>
                <b-radio v-model="setting.is_pagibig_deduction"
                    name="is_pagibig_deduction"
                    native-value="0">
                    No
                </b-radio>
              </b-field>
              <b-field label="Has Exemption? ">
                <b-radio v-model="setting.has_exemption"
                    name="has_exemption"
                    native-value="1">
                    Yes
                </b-radio>
                <b-radio v-model="setting.has_exemption"
                    name="has_exemption"
                    native-value="0">
                    No
                </b-radio>
              </b-field>
              <br>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" type="submit">Save</button>
              <button class="button is-danger" type="button" @click.prevent="close()">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>

    <b-modal :active.sync="isSelect" has-modal-card :canCancel=false>
      <transition name="fade">
        <form @submit.prevent="select()">
          <div class="modal-card pos" style="width: 60rem; height: 35.625rem; margin-left: 100px;">
            <header class="modal-card-head">
              <p class="modal-card-title">Assign Payroll Setting</p>
            </header>
            <section class="modal-card-body">
              <b-field label="Filters"></b-field>
              <b-field grouped group-multiline>
                <b-select v-model="sortValue" @input="getList()">
                  <option value="all">ALL</option>
                  <option value="has_sss">WITH SSS</option>
                  <option value="has_no_sss">WITHOUT SSS</option>
                  <option value="is_part_of_payroll">PART OF PAYROLL</option>
                  <option value="is_not_part_of_payroll">NOT PART OF PAYROLL</option>
                  <option value="with_active_service_record">WITH ACTIVE SERVICE RECORD</option>
                  <option value="without_active_service_record">WITHOUT ACTIVE SERVICE RECORD</option>
                </b-select>
                <b-select v-model="perPage">
                  <option value="10">10 per page</option>
                  <option value="50">50 per page</option>
                  <option value="100">100 per page</option>
                  <option value="1000">1000 per page</option>
                </b-select>
                <b-input v-model="search_list" placeholder="Search Employee" expanded/>
              </b-field>
              <br>
              <b-table
                :data="filterList"
                :striped=true
                :bordered=true
                :hoverable=true
                :paginated="true"
                :per-page="perPage"
                :checked-rows.sync="checkedRows"
                checkable>
                <template slot-scope="props">
                  <b-table-column label="Emp. No." width="100">
                    {{ props.row.employee_number }}
                  </b-table-column>
                  <b-table-column label="Full Name">
                    {{ props.row.fullname }}
                  </b-table-column>
                  <b-table-column label="Position">
                    {{ props.row.position }}
                  </b-table-column>
                  <b-table-column label="Division">
                    {{ props.row.division }}
                  </b-table-column>
                </template>
                <template slot="empty">

                </template>
              </b-table>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" type="submit">Select</button>
              <button class="button is-danger" type="button" @click.prevent="closeSelect()">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      config: '',
      isLoading: false,
      isAssign: false,
      isSelect: false,
      setting: {},
      list: [],
      search_list: '',
      checkedRows: [],
      selectFilter: 'employees',
      sortValue: 'all',
      perPage: 10,
      sources: [],
    }
  },
  mounted() {
    this.config = {
      headers: {'Authorization': "Bearer " + this.$store.state.token}
    };

    this.getList()
    this.getFundingSource()
  },
  computed: {
    filterList() {
      var i;
      var name_re = new RegExp(this.search_list, 'i')
      var data = []
      for (i in this.list) {
        if ([this.list[i].employee_number, this.list[i].employee_number, this.list[i].fullname, this.list[i].position, this.list[i].division].join(' ').match(name_re)) {
          data.push(this.list[i])
        }
      }
      return data
    },
    // selected() {
    //   return this.checkedRows.map(x => x == this.checkedRows[0] ? x.fullname : ' ' + x.fullname)
    // }
  },
  methods: {
    assign() {
      this.getList()
      this.isAssign = true
    },
    close() {
      this.getList()
      this.getFundingSource()
      this.isAssign = false
      this.setting = {
        is_part_of_payroll: 0,
        is_gsis_deduction: 0,
        is_philhealth_deduction: 0,
        is_pagibig_deduction: 0,
        has_exemption: 0,
        gmp_percentage: 3,
        ewt_percentage: 5,
        cvat_percentage: 5
      }
    },
    getList() {
      this.isLoading = true

      this.$http.get(`${this.$host}/api/payroll/setting/list/?sort=${this.sortValue}`,
        this.config
      ).then((response) => {
        let data = response.data
        this.list = data
        this.isLoading = false
      }).catch((error) => {
        this.isLoading = false
        //console.log(error)
      });
    },
    getFundingSource() {
      this.isLoading = true

      this.$http.get(`${this.$host}/api/payroll/funding-source/show/`,
        this.config
      ).then((response) => {
        let data = response.data
        this.sources = data
        this.checkedRows = []
        this.isLoading = false
      }).catch((error) => {
        this.isLoading = false
        //console.log(error)
      });
    },
    closeSelect() {
      this.isSelect = false
    },
    select() {
      this.isSelect = false
    },
    removeSelected(emp) {
      this.checkedRows = this.checkedRows.filter(x => x !== emp)
    },
    save() {
      //console.log(this.setting)
      this.isLoading = true
      this.setting.payroll_setting = this.checkedRows.map(x => x = x.payroll_setting_id)
      this.$http.put(`${this.$host}/api/payroll/setting/mass/edit/`,
        this.setting,
        this.config,
      ).then((response) => {
        let data = response.data
        this.$toast.open({
          message: 'Mass payroll setting updated.',
          type: 'is-success',
          position: 'is-bottom-right',
          duration: 4000
        })
        //console.log(data)
        this.isLoading = false
      }).catch((error) => {
        this.$toast.open({
          message: 'Error saving data.',
          type: 'is-danger',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.isLoading = false
      });
    },
  }
}
</script>

<style>

</style>
