<template>
  <div v-if="dataFound == true">
    <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
    <form @submit.prevent="save()" name="employee">
    <b-tabs type="is-toggle" size="is-small" v-if="show">
      <b-tab-item label="Personal Information">
        <section class="columns is-fullheight">
          <div class="column is-auto box">
            <PersonalInformation v-bind:isDisabled="isDisabled" v-bind:data="personal" ref="savePersonal" @personal="getNewData"/>
          </div>
        </section>
      </b-tab-item>
      <b-tab-item label="Family Background">
        <section class="columns is-fullheight">
          <div class="column is-auto">
            <FamilyBackground v-bind:isDisabled="isDisabled" v-bind:data="family" ref="saveFamily" @family="getNewData"/>
          </div>
        </section>
      </b-tab-item>
      <b-tab-item label="Educational Background">
        <section class="columns is-fullheight">
          <div class="column is-auto box">
            <EducationalBackground v-bind:isDisabled="isDisabled" v-bind:data="education" ref="saveEducation" @education="getNewData"/>
          </div>
        </section>
      </b-tab-item>
      <b-tab-item label="Civil Service Eligibility / Licenses">
        <section class="columns is-fullheight">
          <div class="column is-auto box">
            <CivilService v-bind:isDisabled="isDisabled" v-bind:data="civilservice" ref="saveCivil" @civil="getNewData"/>
          </div>
        </section>
      </b-tab-item>
      <b-tab-item label="Work Experience">
        <section class="columns is-fullheight">
          <div class="column is-auto box">
            <WorkExperience v-bind:isDisabled="isDisabled" v-bind:data="workexperiences" ref="saveWork" @work="getNewData"/>
          </div>
        </section>
      </b-tab-item>
      <b-tab-item label="Voluntary Work">
        <section class="columns is-fullheight">
          <div class="column is-auto box">
            <VoluntaryWork v-bind:isDisabled="isDisabled" v-bind:data="voluntarywork" ref="saveVoluntary" @voluntary="getNewData"/>
          </div>
        </section>
      </b-tab-item>
      <b-tab-item label="Learning and Development">
        <section class="columns is-fullheight">
          <div class="column is-auto box">
            <Learning v-bind:isDisabled="isDisabled" v-bind:data="learning" ref="saveLearning" @learning="getNewData" :id="id"/>
          </div>
        </section>
      </b-tab-item>
      <b-tab-item label="Other">
        <section class="columns is-fullheight">
          <div class="column is-auto">
            <Other v-bind:isDisabled="isDisabled" v-bind:data="other" ref="saveOther" @other="getNewData"/>
          </div>
        </section>
      </b-tab-item>
      <b-tab-item label="User" v-if="id != 'self'">
        <section class="columns is-fullheight">
          <div class="column is-auto box">
            <User v-bind:isDisabled="isDisabled" v-bind:data="user" ref="saveUser" @user="getNewData" :isAdd="isAdd"/>
          </div>
        </section>
      </b-tab-item>
      <b-tab-item label="Approvers" v-if="id != 'self'">
        <section class="columns is-fullheight" v-if="!isAdd">
          <div class="column is-auto box">
            <Approvers :isDisabled="isDisabled" :id="user.id" />
          </div>
        </section>
      </b-tab-item>
      <!-- <b-tab-item label="Test"> // refactor to this later.
        <Test v-bind:data="">
          <template slot-scope="props">
            <b-table-column label="Organization">
              {{ props.row.organization }}
            </b-table-column>
            <b-table-column label="Date">
              {{ props.row.date_from }} - {{ props.row.date_to }}
            </b-table-column>
            <b-table-column label="Number of Hours">
              {{ props.row.number_of_hours }}
            </b-table-column>
            <b-table-column label="Position">
              {{ props.row.position }}
            </b-table-column>
            <b-table-column label="Nature of Work">
              {{ props.row.nature_of_work }}
            </b-table-column>
            <b-table-column field="honors" label="Actions" width="150">
              <button class="button btn-action is-info"><i class="fa fa-pen"></i></button>
              <button class="button btn-action is-danger"><i class="fa fa-trash"></i></button>
            </b-table-column>
          </template>
        </Test>
      </b-tab-item> -->
    </b-tabs>
    <button type="submit" class="button is-success" v-if="!isDisabled && $role.state.permissions['write employee']">
      Save
    </button>
    <button class="button is-success" @click.prevent="edit()" v-if="isDisabled && $role.state.permissions['write employee']">
      Edit
    </button>
    <button class="button is-danger" @click.prevent="cancelPrompt" v-if="isAdd == false && $role.state.permissions['write employee']" :disabled="cancelDisabled">
      Cancel
    </button>

    <button type="submit" class="button is-success" v-if="!isDisabled && !$role.state.permissions['write employee'] && id == 'self'">
      Save
    </button>
    <button class="button is-success" @click.prevent="edit()" v-if="isDisabled && !$role.state.permissions['write employee'] && id == 'self'">
      Edit
    </button>
    <button class="button is-danger" @click.prevent="cancelPrompt" v-if="isAdd == false && !$role.state.permissions['write employee'] && id == 'self'" :disabled="cancelDisabled">
      Cancel
    </button>
    </form>
  </div>
</template>

<script>
import Photo from './Photo.vue'
import PersonalInformation from './employee_tabs/PersonalInformation.vue'
import FamilyBackground from './employee_tabs/FamilyBackground.vue'
import EducationalBackground from './employee_tabs/EducationalBackground.vue'
import CivilService from './employee_tabs/CivilService.vue'
import WorkExperience from './employee_tabs/WorkExperience.vue'
import VoluntaryWork from './employee_tabs/VoluntaryWork.vue'
import Learning from './employee_tabs/Learning.vue'
import Other from './employee_tabs/Other.vue'
import Test from './employee_tabs/Test.vue'
import User from './employee_tabs/User.vue'
import Approvers from './employee_tabs/Approvers.vue'
import moment from 'moment'

export default {
  components: {
    Photo,
    PersonalInformation,
    FamilyBackground,
    EducationalBackground,
    CivilService,
    WorkExperience,
    VoluntaryWork,
    Learning,
    Other,
    Test,
    User,
    Approvers
  },
  props: {
    isAdd: {
      type: Boolean
    },
    id: {

    }
  },
  data() {
    return {
      dataFound: 'false',
      editSave: 'Edit',
      isDisabled: true,
      data: '',
      education: '',
      civilservice: '',
      workexperiences: '',
      voluntarywork: '',
      learning: '',
      personal: '',
      family: '',
      other: '',
      user: '',
      newEmployee: false,
      newData: {
        children: [],
        education: [],
        eligibilities: [],
        workexperiences: [],
        volunteerings: [],
        trainings: [],
        skills: [],
        recognitions: [],
        memberships: [],
        references: [],
        servicerecords: [],
        supervises: [],
      },
      show: true,
      cancelDisabled: true,
      isLoading: false,
    }
  },
  mounted() {
    this.$suggestions.commit('getParams')
    if (this.newEmployee == false) {
      if (this.id == 'self') {
        let config = {
          headers: {'Authorization': "Bearer " + this.$store.state.token}
        };

        this.$http.get(`${this.$host}/api/user`,
          config
        ).then((response) => {
          if (response.data.data.employee != null) {
            let data = response.data.data.employee
            this.$editmode.commit('setUpdate', response.data.data.is_update)
            this.data = data
            this.education = data.education
            this.civilservice = data.eligibilities
            this.workexperiences = data.workexperiences
            this.voluntarywork = data.volunteerings
            this.learning = data.trainings
            this.personal = data
            this.family = data
            this.other = data
            this.user = data
            this.isLoading = false

            this.formatDates()
            if (this.id) {
              this.dataFound = true
            }
          }
          else {
            this.isLoading = false
          }
        }).catch((error) => {
          this.isLoading = false
          //console.log(error)
        });
      }
      else {
        let config = {
          headers: {'Authorization': "Bearer " + this.$store.state.token}
        };
        this.$http.get(`${this.$host}/api/employee/search/${this.id}`,
          config
        ).then((response) => {
          let data = response.data.data
          this.data = data
          this.education = response.data.data.education
          this.civilservice = response.data.data.eligibilities
          this.workexperiences = response.data.data.workexperiences
          this.voluntarywork = response.data.data.volunteerings
          this.learning = response.data.data.trainings
          this.personal = response.data.data
          this.family = response.data.data
          this.other = response.data.data
          this.user = response.data.data

          this.formatDates()
          if (this.id) {
            this.dataFound = true
          }
          //console.log(data)
        }).catch((error) => {
          //console.log(error)
        });
      }
    }
    else if (this.newEmployee == true) {
      this.dataFound = true
      this.data = {}
      this.education = []
      this.civilservice = []
      this.workexperiences = []
      this.voluntarywork = []
      this.learning = []
      this.personal = {}
      this.family = {
        children: []
      }
      this.other = {
        skills: [],
        recognitions: [],
        memberships: [],
        references: []
      }
      this.user = {
        users: {
          username: '',
        },
        roles: [],
        permissions: []
      }
    }

  },
  watch: {
    isAdd: {
      immediate: true,
      handler (val, oldVal) {
        if (val) {
          this.isDisabled = false
          this.editSave = "Save"
          this.newEmployee = true
          // add new record logic here
        }
        else {
          this.newEmployee = false
        }
      }
    }
  },
  methods: {
    getNewData(data) {
      Object.assign(this.newData, data)
      // alert(this.newData.first_name)
    },
    edit() {
      this.editSave = "Save"
      this.isDisabled = false
      this.cancelDisabled = false
      this.$emit('isEdit', true)
      this.$editmode.commit('editing', true)
    },
    confirmSave() {
      this.$refs.savePersonal.returnData()
      this.$refs.saveFamily.returnData()
      this.$refs.saveOther.returnData()
      this.$refs.saveEducation.returnData()
      this.$refs.saveCivil.returnData()
      this.$refs.saveWork.returnData()
      this.$refs.saveVoluntary.returnData()
      this.$refs.saveLearning.returnData()
      if (this.id != 'self') {
        this.$refs.saveUser.returnData()
      }

      // delete Object.assign(this.newData, {['telephone']: this.newData['telephone_2'] })['telephone_2'];

      if (this.newData.children == undefined) {
        this.newData.children = []
      }
      else {
        let children = []
        for (let i = 0; i < this.newData.children.length; i++) {
          let child = {}
          child.id = this.newData.children[i].id
          child.employee_id = this.newData.children[i].employee_id
          child.children_name = this.newData.children[i].children_name
          child.birth_date = moment(this.newData.children[i].birth_date).format("YYYY-MM-DD")
          children.push(child)
          // alert(this.newData.children[i].birth_date.toISOString().split('T')[0])
        }
        delete this.newData.children
        this.newData.children = children
      }
      if (this.newData.education == "") {
        this.newData.education = []
      }
      if (this.newData.eligibilities == "") {
        this.newData.eligibilities = []
      }
      else {
        for (let i = 0; i < this.newData.eligibilities.length; i++) {
          let eligibility = {}
          eligibility.date_conferment = moment(this.newData.eligibilities[i].date_conferment).format("YYYY-MM-DD")
          this.newData.eligibilities[i].date_conferment = eligibility.date_conferment

          if (this.newData.eligibilities[i].license_validity) {
            eligibility.license_validity = moment(this.newData.eligibilities[i].license_validity).format("YYYY-MM-DD")
            this.newData.eligibilities[i].license_validity = eligibility.license_validity
          }
        }
      }
      if (this.newData.workexperiences == "") {
        this.newData.workexperiences = []
      }
      else {
        for (let i = 0; i < this.newData.workexperiences.length; i++) {
          let work = {}
          work.date_from = moment(this.newData.workexperiences[i].date_from).format("YYYY-MM-DD")
          this.newData.workexperiences[i].date_from = work.date_from
          if (this.newData.workexperiences[i].date_to) {
            work.date_to = moment(this.newData.workexperiences[i].date_to).format("YYYY-MM-DD")
            this.newData.workexperiences[i].date_to = work.date_to
          }
        }
      }
      if (this.newData.volunteerings == "") {
        this.newData.volunteerings = []
      }
      else {
        for (let i = 0; i < this.newData.volunteerings.length; i++) {
          let obj = {}
          obj.date_from = moment(this.newData.volunteerings[i].date_from).format("YYYY-MM-DD")
          this.newData.volunteerings[i].date_from = obj.date_from
          if (this.newData.volunteerings[i].date_to) {
            obj.date_to = moment(this.newData.volunteerings[i].date_to).format("YYYY-MM-DD")
            this.newData.volunteerings[i].date_to = obj.date_to
          }
        }
      }
      if (this.newData.trainings == "") {
        this.newData.trainings = []
      }
      else {
        for (let i = 0; i < this.newData.trainings.length; i++) {
          let obj = {}
          obj.date_from = moment(this.newData.trainings[i].date_from).format("YYYY-MM-DD")
          obj.date_to = moment(this.newData.trainings[i].date_to).format("YYYY-MM-DD")
          this.newData.trainings[i].date_from = obj.date_from
          this.newData.trainings[i].date_to = obj.date_to
        }
      }
      if (this.newData.birth_date == "Invalid date" || this.newData.birth_date==null) {
        this.newData.birth_date = null
      }
      else {
        this.newData.birth_date = moment(this.newData.birth_date).format("YYYY-MM-DD")
      }
      if (this.newData.is_criminally_charged == 0 || this.newData.is_criminally_charged==null){
        this.newData.criminally_charged_date = null
      }
      else {
        this.newData.criminally_charged_date = moment(this.newData.criminally_charged_date).format("YYYY-MM-DD")
      }
      if (this.newData.issued_date == "Invalid date" || this.newData.issued_date==null){
        this.newData.issued_date = null
      }
      else {
        this.newData.issued_date = moment(this.newData.issued_date).format("YYYY-MM-DD")
      }

      if (this.isAdd == true) {
        let skills = this.newData.skills
        let recognitions = this.newData.recognitions
        let memberships = this.newData.memberships
        let references = this.newData.references
        let servicerecords = this.newData.servicerecords
        let supervises = this.newData.supervises
        let education = this.newData.education
        let eligibilities = this.newData.eligibilities
        let workexperiences = this.newData.workexperiences
        let volunteerings = this.newData.volunteerings
        let trainings = this.newData.trainings
        let children = this.newData.children
        let users = this.newData.users
        let roles = this.newData.roles
        let permissions = this.newData.permissions

        delete this.newData.skills
        delete this.newData.recognitions
        delete this.newData.memberships
        delete this.newData.references
        delete this.newData.servicerecords
        delete this.newData.supervises
        delete this.newData.education
        delete this.newData.eligibilities
        delete this.newData.workexperiences
        delete this.newData.volunteerings
        delete this.newData.trainings
        delete this.newData.children
        delete this.newData.users
        delete this.newData.roles
        delete this.newData.permissions

        // let data = this.newData
        // delete this.newData

        // this.newData = data
        this.newData.skills = skills
        this.newData.recognitions = recognitions
        this.newData.memberships = memberships
        this.newData.references = references
        this.newData.servicerecords = servicerecords
        this.newData.supervises = supervises
        this.newData.education = education
        this.newData.eligibilities = eligibilities
        this.newData.workexperiences = workexperiences
        this.newData.volunteerings = volunteerings
        this.newData.trainings = trainings
        this.newData.children = children
        this.newData.users = users
        this.newData.roles = roles
        this.newData.permissions = permissions

      }


      // let arr = [skills, recognitions, memberships, references, servicerecords, supervises, education,
      // eligibilities, workexperiences, volunteerings, trainings]
      // let i = 0
      // while (i < arr.length) {
      //   delete this.newData[this[arr[i]]]
      //   this.newData[arr[i]] = arr[i]
      //   i++
      // }

      delete this.newData.group

      //console.log(JSON.stringify(this.newData) + 'asd')
      let config = {
        headers: {'Authorization': "Bearer " + this.$store.state.token}
      };
      this.isLoading = true

      let endpoint = null
      if (this.id == 'self') {
        endpoint = `${this.$host}/api/employee/update`
      }
      else {
        endpoint = `${this.$host}/api/employee/save/${this.id}`
      }

      this.$http.post(endpoint, this.newData, config)
      .then(response => {
        this.editSave = "Edit"
        this.isDisabled = true
        this.$toast.open({
          message: 'Employee successfully saved!',
          type: 'is-success',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.cancelDisabled = false
        this.$emit('isEdit', false)
        this.$editmode.commit('editing', false)
        this.show = false
        this.isLoading = true
        this.cancelDisabled = true
        this.$suggestions.commit('getParams')
        setTimeout(this.fShowLoad(), 1)
        if (this.isAdd) {
          this.$editmode.commit('setAdd', true,)
          this.$editmode.commit('setNewEmp',  response.data.data)
          this.$router.push('/employees')
        }
        if (this.id != 'self') {
          let config = {
            headers: {'Authorization': "Bearer " + this.$store.state.token}
          };
          this.$http.get(`${this.$host}/api/employee/search/${this.id}`,
            config
          ).then((response) => {
            let data = response.data.data
            this.data = data
            this.education = response.data.data.education
            this.civilservice = response.data.data.eligibilities
            this.workexperiences = response.data.data.workexperiences
            this.voluntarywork = response.data.data.volunteerings
            this.learning = response.data.data.trainings
            this.personal = response.data.data
            this.family = response.data.data
            this.other = response.data.data
            this.user = response.data.data
            this.isLoading = false
            this.show = true
            this.formatDates()
            this.dataFound = true

            if (data.name_extension == null) {
              this.$emit('refreshHeaderName', `${data.first_name} ${data.last_name}`)
            }
            else {
              this.$emit('refreshHeaderName', `${data.first_name} ${data.last_name} ${data.name_extension}`)
            }

            //console.log(data)
            //console.log(JSON.stringify(data))
          }).catch((error) => {
            //console.log(error)
          });
        }
        else {
          let config = {
            headers: {'Authorization': "Bearer " + this.$store.state.token}
          };
          this.$http.get(`${this.$host}/api/user`,
            config
          ).then((response) => {
            let data = response.data.data.employee
            this.data = data
            this.education = response.data.data.employee.education
            this.civilservice = response.data.data.employee.eligibilities
            this.workexperiences = response.data.data.employee.workexperiences
            this.voluntarywork = response.data.data.employee.volunteerings
            this.learning = response.data.data.employee.trainings
            this.personal = response.data.data.employee
            this.family = response.data.data.employee
            this.other = response.data.data.employee
            this.user = response.data.data.employee
            this.isLoading = false
            this.show = true
            this.formatDates()
            this.dataFound = true

            if (data.name_extension == null) {
              this.$emit('refreshHeaderName', `${data.first_name} ${data.last_name}`)
            }
            else {
              this.$emit('refreshHeaderName', `${data.first_name} ${data.last_name} ${data.name_extension}`)
            }

            //console.log(data)
            //console.log(JSON.stringify(data))
          }).catch((error) => {
            //console.log(error)
          });
        }
      })
      .catch(error => {
        this.formatDates()
        this.isLoading = false
        if (error.response.data.errors) {
          let msg = error.response.data.errors[Object.keys(error.response.data.errors)[0]][0]
          this.$toast.open({
            message: msg,
            type: 'is-danger',
            position: 'is-bottom-right',
            duration: 4000
          })
        }
        else {
          this.$toast.open({
            message: 'Error saving data. Please make sure to fill the required fields.',
            type: 'is-danger',
            position: 'is-bottom-right',
            duration: 4000
          })
        }
        //console.log(error);
      });
    },
    fShowLoad() {
      this.isLoading = false
      this.show = true
    },
    cancelPrompt() {
      this.$dialog.confirm({
        title: 'Discard Changes',
        message: 'Are you sure you want to <b>discard</b> your changes? This action cannot be undone.',
        confirmText: 'Discard',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.cancel()
      })
    },
    cancel() {
      // revert to original data logic
      this.$emit('isEdit', false)
      this.$editmode.commit('editing', false)
      this.show = false
      this.editSave = "Edit"
      this.isDisabled = true
      this.isAdd = false
      let config = {
        headers: {'Authorization': "Bearer " + this.$store.state.token}
      };
      this.$http.get(`${this.$host}/api/employee/search/${this.id}`,
        config
      ).then((response) => {
        let data = response.data.data
        this.data = data
        this.education = response.data.data.education
        this.civilservice = response.data.data.eligibilities
        this.workexperiences = response.data.data.workexperiences
        this.voluntarywork = response.data.data.volunteerings
        this.learning = response.data.data.trainings
        this.personal = response.data.data
        this.family = response.data.data
        this.other = response.data.data
        this.user = response.data.data

        this.formatDates()
        this.dataFound = true
        //console.log(data)
        setTimeout(() => this.show = true, 1)
        this.$toast.open({
          message: 'Changes discarded.',
          position: 'is-bottom-right',
          duration: 3000
        })
        this.cancelDisabled = true
      }).catch((error) => {
        //console.log(error)
      });

    },
    save() {
      this.$dialog.confirm({
        title: 'Save',
        message: '<b>Save</b> your changes?',
        confirmText: 'Save',
        type: 'is-success',
        hasIcon: true,
        onConfirm: () => this.confirmSave()
      })
    },
    formatDates() {
      if (this.data.children) {
        for (let i = 0; i < this.data.children.length; i++) {
          this.data.children[i].birth_date = new Date(this.data.children[i].birth_date)
        }
      }
      if (this.civilservice != undefined) {
        for (let i = 0; i < this.civilservice.length; i++) {
          this.civilservice[i].date_conferment = new Date(this.civilservice[i].date_conferment)
          if (this.civilservice[i].license_validity) {
            this.civilservice[i].license_validity = new Date(this.civilservice[i].license_validity)
          }
        }
      }
      if (this.workexperiences != undefined) {
        for (let i = 0; i < this.workexperiences.length; i++) {
          this.workexperiences[i].date_from = new Date(this.workexperiences[i].date_from)
          if (this.workexperiences[i].date_to) {
            this.workexperiences[i].date_to = new Date(this.workexperiences[i].date_to)
          }
        }
      }
      if (this.voluntarywork != undefined) {
        for (let i = 0; i < this.voluntarywork.length; i++) {
          this.voluntarywork[i].date_from = new Date(this.voluntarywork[i].date_from)
          if (this.voluntarywork[i].date_to) {
            this.voluntarywork[i].date_to = new Date(this.voluntarywork[i].date_to)
          }
        }
      }
      if (this.learning != undefined) {
        for (let i = 0; i < this.learning.length; i++) {
          this.learning[i].date_from = new Date(this.learning[i].date_from)
          this.learning[i].date_to = new Date(this.learning[i].date_to)
        }
      }

    }
  }
}
</script>

<style scoped>
.box {
  margin-top: 10px;
}
</style>

