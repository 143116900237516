<template>
  <div class="box">
    <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
    <div>
      <button class="button is-success" @click.prevent="addEarning()" v-if="$role.state.permissions['write payroll'] || $role.state.permissions['write payroll funding source']">
        <span><i class="fa fa-plus"></i> Add Earning</span>
      </button>
      <button class="button is-success" @click.prevent="isType = true" v-if="$role.state.permissions['write payroll'] || $role.state.permissions['write payroll funding source']">
        <span><i class="fa fa-money-check"></i> Earning Types</span>
      </button>
      <b-input v-model="search_query" placeholder="Search" class="is-pulled-right"/>
    </div>
    <hr>

    <b-table
      :data="filter"
      :striped=true
      :bordered=true
      :hoverable=true
      :paginated="true"
      :per-page="5"
      detailed
      :has-detailed-visible="(row) => row.id != '1'"
      detail-key="id">
      <template slot-scope="props">
        <b-table-column label="Code" width="100">
          {{ props.row.code }}
        </b-table-column>
        <b-table-column label="Description">
          {{ props.row.description }}
        </b-table-column>
        <b-table-column label="Earning Type">
          {{ props.row.earning_type }}
        </b-table-column>
        <b-table-column label="Earning Frequency">
          {{ props.row.earning_frequency }}
        </b-table-column>
        <b-table-column label="Earning Classification">
          {{ props.row.earning_classification }}
        </b-table-column>
        <b-table-column label="Amount Type">
          {{ props.row.amount_type }}
        </b-table-column>
        <b-table-column label="Amount">
          {{ toFormula(props.row.amount, props.row.amount_type) }}
        </b-table-column>
        <b-table-column label="Amount Taxable">
          {{ toFormula(props.row.amount_taxable, props.row.amount_type) }}
        </b-table-column>
        <b-table-column label="Actions" width="150" v-if="$role.state.permissions['write payroll'] || $role.state.permissions['write payroll funding source']">
          <button class="button btn-action is-success is-small" @click.prevent="addAssignEarning(props.row)"><i class="fa fa-plus"></i></button>
          <button class="button btn-action is-primary is-small" @click.prevent="addEarning(props.row)"><i class="fa fa-pen"></i></button>
          <!-- <button class="button btn-action is-danger is-small" @click.prevent="deleteEarning(props.row)" :disabled="props.row.id <= 23"><i class="fa fa-trash"></i></button> -->
        </b-table-column>
      </template>
      <template slot="empty">
        <b-table-column label="Code">
        </b-table-column>
        <b-table-column label="Description">
        </b-table-column>
        <b-table-column label="Earning Type">
        </b-table-column>
        <b-table-column label="Earning Frequency">
        </b-table-column>
        <b-table-column label="Earning Classification">
        </b-table-column>
        <b-table-column label="Amount Type">
        </b-table-column>
        <b-table-column label="Amount">
        </b-table-column>
        <b-table-column label="Amount Taxable">
        </b-table-column>
        <b-table-column label="Actions" v-if="$role.state.permissions['write payroll'] || $role.state.permissions['write payroll funding source']">
        </b-table-column>
      </template>
      <template slot="detail" slot-scope="props">
          <article class="media">
            <div class="media-content">
              <div class="content">
                <b-table
                  :data="props.row.employee_earnings"
                  :bordered=true
                  :hoverable=true
                  :paginated=true
                  :per-page=5
                  :pagination-simple=true>
                  <template slot-scope="props">
                    <b-table-column label="Reference Date">
                      {{ props.row.ref_date }}
                    </b-table-column>
                     <b-table-column label="Payroll Type" expanded>
                      {{ props.row.payroll_type }}
                    </b-table-column>
                     <b-table-column label="Amount">
                      {{ toFormula(props.row.amount, props.row.amount_type) }}
                    </b-table-column>
                     <b-table-column label="Amount Taxable" v-if="props.row.earning_classification=='Taxable'">
                      {{ toFormula(props.row.amount_taxable, props.row.amount_type) }}
                    </b-table-column>
                    <b-table-column label="Actions" v-if="$role.state.permissions['write payroll'] || $role.state.permissions['write payroll funding source']">
                      <button class="button btn-action is-primary is-small" @click.prevent="assignEarning(props.row)"><i class="fa fa-user-plus"></i></button>
                      <button class="button btn-action is-primary is-small" @click.prevent="editEarning(props.row)"><i class="fa fa-pen"></i></button>
                    </b-table-column>
                  </template>
                  <template slot="empty">
                    <b-table-column label="Reference Date">
                    </b-table-column>
                     <b-table-column label="Payroll Type">
                    </b-table-column>
                     <b-table-column label="Amount">
                    </b-table-column>
                     <b-table-column label="Amount Taxable">
                    </b-table-column>
                    <b-table-column label="Actions" v-if="$role.state.permissions['write payroll'] || $role.state.permissions['write payroll funding source']">
                    </b-table-column>
                  </template>
                </b-table>
              </div>
            </div>
          </article>
        </template>
    </b-table>

    <b-modal :active.sync="isSave" has-modal-card :canCancel=false>
      <transition name="fade">
        <form @submit.prevent="save()">
          <div class="modal-card" style="height: 35.625rem; margin-top: 4rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">{{ state }} Earning</p>
            </header>
            <section class="modal-card-body">
              <b-field label="Code">
                <b-input
                  v-model="earning.code"
                  expanded required>
                </b-input>
              </b-field>
              <b-checkbox v-model="earning.use_code" true-value="1" false-value="0">
                Use Code For Display
              </b-checkbox>
              <b-field label="Description">
                <b-input
                  v-model="earning.description"
                  expanded required>
                </b-input>
              </b-field>
              <b-field label="Journal Entry">
                <b-input
                  v-model="earning.journal_entry"
                  @input="setSpace('earning')"
                  expanded>
                </b-input>
              </b-field>
              <b-field label="Type">
                <b-select placeholder="Earning Type" v-model="earning.earning_type_id" expanded required>
                  <option
                    v-for="option in types"
                    :value="option.id"
                    :key="option.id">
                    {{ option.code }}
                  </option>
                </b-select>
              </b-field>
               <b-field label="Payroll Type">
                <b-select v-model="earning.payroll_type" required expanded>
                  <option value="Regular">REGULAR</option>
                  <option value="Special">OTHER BENEFITS</option>
                </b-select>
              </b-field>
              <b-field label="Frequency">
                <b-select placeholder="Earning Frequency" v-model="earning.earning_frequency" expanded required>
                  <option value="One Time">One Time</option>
                  <option value="Perpetual">Perpetual</option>
                </b-select>
              </b-field>
              <b-field label="Classification">
                <b-select placeholder="Earning Classification" @input="clearClass()" v-model="earning.earning_classification" expanded required>
                  <option value="Taxable">Taxable</option>
                  <option value="Non-Taxable">Non-Taxable</option>
                </b-select>
              </b-field>
              <b-field v-if="earning.earning_classification == 'Taxable'">
                <b-checkbox v-model="earning.has_special_tax">
                  Use special tax table
                </b-checkbox>
              </b-field>
              <b-field label="Amount Type">
                <b-select placeholder="Amount Type" @input="clearType()" v-model="earning.amount_type" expanded required>
                  <option value="Fixed">Fixed</option>
                  <option value="Formula">Formula Based</option>
                </b-select>
              </b-field>
              <b-field label="Fixed Amount" v-if="earning.amount_type == 'Fixed'">
                <b-input
                  v-model="earning.fixed_amount"
                  step="0.01"
                  type="number"
                  expanded required>
                </b-input>
              </b-field>
              <b-field label="Formula Amount" v-if="earning.amount_type == 'Formula'">
                <b-field>
                  <b-input
                    v-model="formulaDisplay"
                    disabled expanded required>
                  </b-input>
                  <p class="control">
                    <button class="button is-primary" @click.prevent="openFormula()">
                      <span><i class="fa fa-calculator"></i></span>
                    </button>
                  </p>
                </b-field>
              </b-field>
              <b-field label="Fixed Amount Taxable" v-if="earning.amount_type == 'Fixed' && earning.earning_classification == 'Taxable'">
                <b-input
                  v-model="earning.fixed_amount_taxable"
                  step="0.01"
                  type="number"
                  expanded required>
                </b-input>
              </b-field>
              <b-field label="Formula Amount Taxable" v-if="earning.amount_type == 'Formula' && earning.earning_classification == 'Taxable'">
                <b-field>
                  <b-input
                    v-model="formulaTaxDisplay"
                    disabled expanded required>
                  </b-input>
                  <p class="control">
                    <button class="button is-primary" @click.prevent="openFormulaTax()">
                      <span><i class="fa fa-calculator"></i></span>
                    </button>
                  </p>
                </b-field>
              </b-field>
              <!-- <b-field label="Special Tax Table" v-if="earning.has_special_tax">
                <button class="button is-primary is-small" @click.prevent="addSpecialTax()">
                  <span><i class="fa fa-plus"></i> Add Special Tax</span>
                </button>
              </b-field> -->
              <b-table
                :data="taxes"
                :striped=true
                :bordered=true
                :hoverable=true
                :paginated="true"
                :per-page="5"
                v-if="earning.has_special_tax">
                <template slot-scope="props">
                  <b-table-column label="Min. Amount">
                    {{ props.row.minimum_amount }}
                  </b-table-column>
                  <b-table-column label="Max. Amount">
                    {{ props.row.maximum_amount }}
                  </b-table-column>
                  <b-table-column label="Percentage Rate">
                    {{ props.row.percentage_rate }}
                  </b-table-column>
                </template>
                <template slot="empty">
                  <b-table-column label="Min. Amount">
                  </b-table-column>
                  <b-table-column label="Max. Amount">
                  </b-table-column>
                  <b-table-column label="Percentage Rate">
                  </b-table-column>
                  <b-table-column label="Actions">
                  </b-table-column>
                </template>
              </b-table>
              <br>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" type="submit">Save</button>
              <button class="button is-danger" type="button" @click.prevent="close()">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>

    <b-modal :active.sync="isSpecialTax" has-modal-card :canCancel=false>
      <transition name="fade">
        <form @submit.prevent="saveSpecialTax()">
          <div class="modal-card" style="max-height: 35.625rem; margin-top: 4rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">{{ state_2 }} Special Tax</p>
            </header>
            <section class="modal-card-body">
              <b-field label="Min. Amount">
                <b-input
                  v-model="specialTax.minimum_amount"
                  type="number"
                  step="0.01"
                  min="0.01"
                  expanded>
                </b-input>
              </b-field>
              <b-field label="Max. Amount">
                <b-input
                  v-model="specialTax.maximum_amount"
                  type="number"
                  step="0.01"
                  min="0.01"
                  expanded>
                </b-input>
              </b-field>
              <b-field label="Percentage Rate">
                <b-input
                  v-model="specialTax.percentage_rate"
                  type="number"
                  step="0.01"
                  min="0.00"
                  expanded required>
                </b-input>
              </b-field>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" type="submit">Add</button>
              <button class="button is-danger" type="button" @click.prevent="closeSpecialTax()">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>

    <b-modal :active.sync="isAssign" has-modal-card :canCancel=false>
      <transition name="fade">
        <form @submit.prevent="assign()">
          <div class="modal-card pos" style="width: 60rem; height: 35.625rem; margin-left: 100px;">
            <header class="modal-card-head">
              <p class="modal-card-title">Assign Earning</p>
            </header>
            <section class="modal-card-body">
              <b-field grouped group-multiline>
                <b-select v-model="perPageEarnings">
                  <option value="10">10 per page</option>
                  <option value="50">50 per page</option>
                  <option value="100">100 per page</option>
                  <option value="1000">1000 per page</option>
                </b-select>
                <b-select v-model="employment_status_id" @input="getEmployees">
                  <option value="">All</option>
                  <option v-for="status in employment_statuses" v-bind:key="status.id" :value="status.id"> {{ status.description }} </option>\
                </b-select>
                <b-input v-model="search_list" placeholder="Search Employee" expanded/>
              </b-field>
              <br>
              <b-table
                :data="filterList"
                :striped=true
                :bordered=true
                :hoverable=true
                :paginated="true"
                :per-page="perPageEarnings"
                :checked-rows.sync="checkedRows"
                checkable>
                <template slot-scope="props">
                  <b-table-column label="Emp No." width="100">
                    {{ props.row.employee_number }}
                  </b-table-column>
                  <b-table-column label="Full Name">
                    {{ props.row.fullname }}
                  </b-table-column>
                  <b-table-column label="Position">
                    {{ props.row.position }}
                  </b-table-column>
                  <b-table-column label="Division">
                    {{ props.row.division }}
                  </b-table-column>
                </template>
                <template slot="empty">

                </template>
              </b-table>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" type="submit">Assign</button>
              <button class="button is-danger" type="button" @click.prevent="closeAssign()">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>

    <b-modal :active.sync="isAdd" has-modal-card :canCancel=false>
      <transition name="fade">
        <form @submit.prevent="saveAssignEarning()">
          <div class="modal-card" style="height: 35.625rem; margin-top: 4rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">{{ state }} Earning</p>
            </header>
            <section class="modal-card-body">
              <b-field label="Reference Date" v-if="earning.earning_frequency != 'Perpetual'">
                <b-datepicker
                  placeholder="reference date"
                  icon="calendar-today"
                  v-model="newEarning.ref_date"
                  :date-formatter="d => d.toLocaleDateString('en-GB')"
                  :date-parser="d => parseDate(d)"
                  required
                  editable>
                </b-datepicker>
              </b-field>
              <b-field label="Payroll Type">
                <b-select v-model="newEarning.payroll_type" required expanded>
                  <option value="Regular">REGULAR</option>
                  <option value="Special">OTHER BENEFITS</option>
                </b-select>
              </b-field>
              <b-field label="Fixed Amount" v-if="earning.amount_type == 'Fixed'" >
                <b-input
                  v-model="newEarning.fixed_amount"
                  type="number" step="0.01"
                  expanded required>
                </b-input>
              </b-field>
              <b-field label="Fixed Amount Taxable" v-if="earning.earning_classification == 'Taxable' && earning.amount_type == 'Fixed'">
                <b-input
                  v-model="newEarning.fixed_amount_taxable"
                  type="number" step="0.01"
                  expanded required>
                </b-input>
              </b-field>
              <b-field label="Formula Amount" v-if="earning.amount_type == 'Formula'">
                <!-- <b-input
                  v-model="newEarning.formula_amount"
                  expanded required>
                </b-input> -->
                <b-field>
                  <b-input
                    v-model="formulaDisplay2"
                    disabled expanded required>
                  </b-input>
                  <p class="control">
                    <button class="button is-primary" @click.prevent="openFormula2()">
                      <span><i class="fa fa-calculator"></i></span>
                    </button>
                  </p>
                </b-field>
              </b-field>
              <b-field v-if="earning.earning_classification == 'Taxable' && earning.amount_type == 'Formula'">
                <b-field label="Formula Amount Taxable" v-if="earning.formula_amount_taxable !== null && earning.formula_amount_taxable.length > 0">
                  <!-- <b-input
                    v-model="newEarning.formula_amount_taxable"
                    expanded required>
                  </b-input> -->
                  <b-field>
                    <b-input
                      v-model="formulaDisplayTax2"
                      disabled expanded required>
                    </b-input>
                    <p class="control">
                      <button class="button is-primary" @click.prevent="openFormulaTax2()">
                        <span><i class="fa fa-calculator"></i></span>
                      </button>
                    </p>
                  </b-field>
                </b-field>
              </b-field>
              <br>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" type="submit">Save</button>
              <button class="button is-danger" type="button" @click.prevent="closeAdd()">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>

    <b-modal :active.sync="isType" has-modal-card>
      <Type title="Earning" :types="types" :isSave="isTypeSave" @getTypes="getEarningTypes" @save="saveEarningTypes" @delete="deleteEarningTypes" />
    </b-modal>

    <b-modal :active.sync="isFormula" has-modal-card>
      <Formula title="Earning" :variables="earningsParams" :isOperator="isOperator" @input="setFormula" :formula="earning.formula_amount" />
    </b-modal>

    <b-modal :active.sync="isFormulaTax" has-modal-card>
      <FormulaTax title="Earning" :variables="earningsParams" :isOperator="isOperatorTax" @input="setFormulaTax" :formula="earning.formula_amount_taxable" />
    </b-modal>

    <b-modal :active.sync="isFormula2" has-modal-card>
      <Formula2 title="Earning" :variables="earningsParams" :isOperator="isOperator2" @input="setFormula2" :formula="newEarning.formula_amount" />
    </b-modal>

    <b-modal :active.sync="isFormulaTax2" has-modal-card>
      <FormulaTax2 title="Earning" :variables="earningsParams" :isOperator="isOperatorTax2" @input="setFormulaTax2" :formula="newEarning.formula_amount_taxable" />
    </b-modal>
  </div>
</template>

<script>
import Type from './Type.vue'
import Formula from '../Formula.vue'
import FormulaTax from '../Formula.vue'
import Formula2 from '../Formula.vue'
import FormulaTax2 from '../Formula.vue'
import moment from 'moment'

export default {
  components: {
    Type,
    Formula,
    FormulaTax,
    Formula2,
    FormulaTax2
  },
  data() {
    return {
      config: '',
      search_query: '',
      search_list: '',
      isLoading: false,
      isAdd: false,
      isSave: false,
      state: '',
      earnings: [],
      earning: {
        use_code: 0,
        amount_type: 'Fixed',
        formula_amount: [],
        formula_amount_taxable: []
      },
      isType: false,
      isTypeSave: false,
      isAssign: false,
      list: [],
      types: [],
      taxes: [],
      perPageEarnings: 10,
      checkedRows: [],
      earningAttach: {},
      newEarning: {
        ref_date: null,
        formula_amount: [],
        formula_amount_taxable: []
      },
      prevEarning: {},
      perDate: null,
      isFormula: false,
      isFormulaTax: false,
      earningsParams: [],
      isOperator: false,
      isOperatorTax: false,
      isFormula2: false,
      isFormulaTax2: false,
      isOperator2: false,
      isOperatorTax2: false,
      isSpecialTax: false,
      specialTax: {
        id: null
      },
      prevSpecialTax: {},
      state_2: '',
      employment_statuses: [],
      employment_status_id: "",
      ref_date: null
    }
  },
  mounted() {
    this.config = {
      headers: {'Authorization': "Bearer " + this.$store.state.token}
    };
    this.getEmploymentStatuses();
    this.getEarnings()
    this.getEarningTypes()
    this.getEarningsParams()
    this.getSpecialTaxDate()
  },
  computed: {
    formulaDisplay() {
      if (this.earning.formula_amount) {
        let formula
        let formulaArr
        formulaArr = this.earning.formula_amount.map(x => x = x.code)
        formula = formulaArr.join(' ')
        return formula
      }
    },
    formulaTaxDisplay() {
      if (this.earning.formula_amount_taxable) {
        let formula
        let formulaArr
        formulaArr = this.earning.formula_amount_taxable.map(x => x = x.code)
        formula = formulaArr.join(' ')
        return formula
      }
    },
    formulaDisplay2() {
      if (this.newEarning.formula_amount) {
        let formula
        let formulaArr
        formulaArr = this.newEarning.formula_amount.map(x => x = x.code)
        formula = formulaArr.join(' ')
        return formula
      }
    },
    formulaDisplayTax2() {
      if (this.newEarning.formula_amount_taxable) {
        let formula
        let formulaArr
        formulaArr = this.newEarning.formula_amount_taxable.map(x => x = x.code)
        formula = formulaArr.join(' ')
        return formula
      }
    },
    filter() {
      var i;
      var name_re = new RegExp(this.search_query, 'i')
      var data = []
      for (i in this.earnings) {
        if ([this.earnings[i].code, this.earnings[i].description, this.earnings[i].earning_type, this.earnings[i].earning_frequency, this.earnings[i].earning_classification, this.earnings[i].amount_type].join(' ').match(name_re)) {
          data.push(this.earnings[i])
        }
      }
      return data
    },
    filterList() {
      var i;
      var name_re = new RegExp(this.search_list, 'i')
      var data = []
      for (i in this.list) {
        if ([this.list[i].employee_number, this.list[i].fullname, this.list[i].position, this.list[i].division].join(' ').match(name_re)) {
          data.push(this.list[i])
        }
      }
      return data
    },
  },
  methods: {
    getSpecialTaxThruDate(){
      this.isLoading = true

      this.$http.get(`${this.$host}/api/payroll/special-tax/search/${this.perDate}`,
        this.config
      ).then((response) => {
        //console.log(response.data)
        let data = response.data
        this.allTaxes = data
        this.taxes = this.allTaxes.array
        this.isLoading = false
        //console.log(this.taxes)
      }).catch((error) => {
        this.taxes = []
        this.isLoading = false
        //console.log(error)
      });
    },
    getSpecialTaxDate() {
      this.isLoading = true

      this.$http.get(`${this.$host}/api/parameter/payroll`,
        this.config
      ).then((response) => {
        //console.log(response.data)
        let data = response.data
        this.dates = data.special_tax
        this.perDate = this.dates[this.dates.length - 1]

        this.$http.get(`${this.$host}/api/payroll/special-tax/search/${this.perDate}`,
          this.config
        ).then((response) => {
          //console.log(response.data)
          let data = response.data
          this.allTaxes = data
          this.taxes = this.allTaxes.array
          this.isLoading = false
          //console.log(this.taxes)
        }).catch((error) => {
          this.isLoading = false
          //console.log(error)
        });
        this.isLoading = false
      }).catch((error) => {
        this.isLoading = false
        //console.log(error)
      });
    },
    getEmploymentStatuses() {
      this.$http.get(`${this.$host}/api/parameter/service_record`,
        this.config
      ).then((response) => {
        this.employment_statuses = response.data.data.employment_statuses_id
      }).catch((error) => {
        //console.log(error)
      });

    },
    setSpace(type) {
      let arrJe = this[type].journal_entry.split('')
      if (arrJe.length > 8) {
        if (arrJe[8] != ' ') {
          if (arrJe[9] == ' ') {
            arrJe.splice(9, 1)
          }
          arrJe.splice(8, 0, ' ')
        }
        if (arrJe.length > 11) {
          if (arrJe[11] != ' ') {
            if (arrJe[12] == ' ') {
              arrJe.splice(12, 1)
            }
            arrJe.splice(11, 0, ' ')
          }
          this[type].journal_entry = arrJe.join('')
        }
        else {
          this[type].journal_entry = arrJe.join('')
        }
      }
    },
    toFormula(formula, type) {
      if (type == 'Formula' && formula) {
        return formula.map(x => x = x.code).join(' ')
      }
      else {
        return formula
      }
    },
    popFormulaTax() {
      if (this.earning.formula_amount_taxable.length > 0) {
        this.isOperatorTax = !this.isOperatorTax
        this.earning.formula_amount_taxable.pop()
      }
    },
    setFormulaTax(obj, bool) {
      this.earning.formula_amount_taxable = obj
      this.isOperatorTax = bool
    },
    openFormulaTax() {
      this.isFormulaTax = true
    },
    popFormula() {
      if (this.earning.formula_amount.length > 0) {
        this.isOperator = !this.isOperator
        this.earning.formula_amount.pop()
      }
    },
    setFormula(obj, bool) {
      this.earning.formula_amount = obj
      this.isOperator = bool
    },
    openFormula() {
      this.isFormula = true
    },
    popFormulaTax2() {
      if (this.newEarning.formula_amount_taxable.length > 0) {
        this.isOperatorTax2 = !this.isOperatorTax2
        this.newEarning.formula_amount_taxable.pop()
      }
    },
    setFormulaTax2(obj, bool) {
      this.newEarning.formula_amount_taxable = obj
      this.isOperatorTax2 = bool
    },
    openFormulaTax2() {
      this.isFormulaTax2 = true
    },
    popFormula2() {
      if (this.newEarning.formula_amount.length > 0) {
        this.isOperator2 = !this.isOperator2
        this.newEarning.formula_amount.pop()
      }
    },
    setFormula2(obj, bool) {
      this.newEarning.formula_amount = obj
      this.isOperator2 = bool
    },
    openFormula2() {
      this.isFormula2 = true
    },
    getEarningsParams() {
      this.isLoading = true

      this.$http.get(`${this.$host}/api/parameter/payroll/earnings`,
        this.config
      ).then((response) => {
        let data = response.data
        this.earningsParams = data
        this.isLoading = false
      }).catch((error) => {
        this.isLoading = false
        //console.log(error)
      });
    },
    parseDate(d) {
      let parts = d.split("/")
      let dt = new Date(parseInt(parts[2], 10),
        parseInt(parts[1], 10) - 1,
        parseInt(parts[0], 10))
      return dt
    },
    assign() {
      this.earningAttach.employee_id = this.checkedRows.map(x => x = x.id)
      let earningAttach = JSON.parse(JSON.stringify(this.earningAttach))
      if (earningAttach.formula_amount) {
        earningAttach.formula_amount = JSON.stringify(earningAttach.formula_amount.map(x => x = x.id))
      }
      if (earningAttach.formula_amount_taxable) {
        earningAttach.formula_amount_taxable = JSON.stringify(earningAttach.formula_amount_taxable.map(x => x = x.id))
      }
      this.isLoading = true
      this.$http.post(`${this.$host}/api/payroll/earning/attach/`,
        earningAttach,
        this.config,
      ).then((response) => {
        this.$toast.open({
          message: 'Earning succesfully attached.',
          type: 'is-success',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.checkedRows = []
        this.isLoading = false
        this.closeAssign()
      }).catch((error) => {
        this.isLoading = false
        this.$toast.open({
          message: 'Error attaching earning.',
          type: 'is-danger',
          position: 'is-bottom-right',
          duration: 4000
        })
      });
    },
    closeAdd() {
      this.getEarnings()
      this.getEarningsParams()
      this.isAdd = false
      this.newEarning = {
        ref_date: null,
        formula_amount: [],
        formula_amount_taxable: []
      }
      this.earning = {
        use_code: 0,
        formula_amount: [],
        formula_amount_taxable: []
      }
    },
    addAssignEarning(obj) {
      if (obj) {
        this.newEarning.earning_id = obj.id
        this.earning = JSON.parse(JSON.stringify(obj))
        if (obj.ref_date) {
          this.newEarning.ref_date = new Date(obj.ref_date)
        }
      }
      if (!this.newEarning.fixed_amount) {
        this.newEarning.fixed_amount = null
      }
      if (!this.newEarning.fixed_amount_taxable) {
        this.newEarning.fixed_amount_taxable = null
      }
      if (!this.newEarning.formula_amount || this.newEarning.formula_amount.length < 1) {
        this.newEarning.formula_amount = null
      }
      if (!this.newEarning.formula_amount_taxable || this.newEarning.formula_amount_taxable.length < 1) {
        this.newEarning.formula_amount_taxable = null
      }
      if (obj.formula_amount) {
        let lastVar = obj.formula_amount[obj.formula_amount.length - 1].code
        if (obj.formula_amount[obj.formula_amount.length - 1].code != undefined) {
          if (lastVar == '+' || lastVar == '-' || lastVar == '*' || lastVar == '/') {
            this.isOperator2 = false
          }
          else {
            this.isOperator2 = true
          }
        }
      }

      if (obj.formula_amount_taxable) {
        let lastVar = obj.formula_amount_taxable[obj.formula_amount_taxable.length - 1].code
        if (obj.formula_amount_taxable.length > 0) {
          if (lastVar = obj.formula_amount_taxable[obj.formula_amount_taxable.length - 1].code != undefined) {
            if (lastVar == '+' || lastVar == '-' || lastVar == '*' || lastVar == '/') {
              this.isOperatorTax2 = false
            }
            else {
              this.isOperatorTax2 = true
            }
          }
        }
      }

      this.newEarning.fixed_amount = obj.fixed_amount
      this.newEarning.fixed_amount_taxable = obj.fixed_amount_taxable
      this.newEarning.formula_amount = obj.formula_amount
      this.newEarning.formula_amount_taxable = obj.formula_amount_taxable

      this.state = 'Add'
      this.isAdd = true
    },
    saveSpecialTax() {
      if (this.state_2 == 'Add') {
        this.earning.special_tax.push(this.specialTax)
        this.closeSpecialTax()
      }
      else if (this.state_2 == 'Edit') {
        //console.log(this.prevSpecialTax)
        for (let i = 0; i < this.earning.special_tax.length; i++) {
          if (JSON.stringify(this.earning.special_tax[i]) == JSON.stringify(this.prevSpecialTax)) {
            this.earning.special_tax[i] = this.specialTax
            i = this.earning.special_tax.length
            this.closeSpecialTax()
          }
        }
      }
    },
    addSpecialTax() {
      this.state_2 = 'Add'
      if (!this.earning.special_tax) {
        this.earning.special_tax = []
      }
      this.isSpecialTax = true
    },
    editSpecialTax(obj) {
      this.state_2 = 'Edit'
      this.prevSpecialTax = JSON.parse(JSON.stringify(obj))
      this.specialTax = JSON.parse(JSON.stringify(obj))
      this.isSpecialTax = true
    },
    deleteSpecialTax(obj) {
      this.$dialog.confirm({
        title: 'Delete Special Tax',
        message: "Are you sure you want to delete this row?",
        confirmText: 'Delete',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          // this.earning.special_tax = this.earning.special_tax.filter(x => {
          //   return x != obj;
          // })
          let index = this.earning.special_tax.indexOf(obj);
          if (index !== -1) this.earning.special_tax.splice(index, 1);
        }
      })
    },
    closeSpecialTax() {
      this.specialTax = {
        id: null
      }
      this.isSpecialTax = false
    },
    getSpecialTax(id, callback) {
      this.$http.get(`${this.$host}/api/payroll/special-tax/search/${id}`,
        this.config
      ).then((response) => {
        let data = response.data
        this.earning.special_tax = data
        this.isLoading = false
        if (callback) {
          callback()
        }
      }).catch((error) => {
        this.isLoading = false
        //console.log(error)
      });
    },
    editEarning(obj) {
      if (obj) {
        this.newEarning.earning_id = obj.id
        this.earning = JSON.parse(JSON.stringify(obj))
        this.newEarning = JSON.parse(JSON.stringify(obj))
        if (obj.ref_date) {
          this.newEarning.ref_date = new Date(obj.ref_date)
        }
        this.prevEarning = JSON.parse(JSON.stringify(this.newEarning))
      }
      if (!this.newEarning.fixed_amount) {
        this.newEarning.fixed_amount = null
      }
      if (!this.newEarning.fixed_amount_taxable) {
        this.newEarning.fixed_amount_taxable = null
      }
      if (!this.newEarning.formula_amount) {
        this.newEarning.formula_amount = null
      }
      if (!this.newEarning.formula_amount_taxable) {
        this.newEarning.formula_amount_taxable = null
      }
      if (obj.formula_amount) {
        let lastVar = obj.formula_amount[obj.formula_amount.length - 1].code
        if (lastVar == '+' || lastVar == '-' || lastVar == '*' || lastVar == '/') {
          this.isOperator2 = false
        }
        else {
          this.isOperator2 = true
        }
      }
      if (obj.formula_amount_taxable) {
        let lastVar = obj.formula_amount_taxable[obj.formula_amount_taxable.length - 1].code
        if (lastVar == '+' || lastVar == '-' || lastVar == '*' || lastVar == '/') {
          this.isOperatorTax2 = false
        }
        else {
          this.isOperatorTax2 = true
        }
      }
      this.state = 'Edit'
      this.isAdd = true
    },
    saveAssignEarning() {
      let earning = JSON.parse(JSON.stringify(this.newEarning))
      if (earning.formula_amount) {
        earning.formula_amount = JSON.stringify(earning.formula_amount.map(x => x = x.id))
      }
      if (earning.formula_amount_taxable) {
        earning.formula_amount_taxable = JSON.stringify(earning.formula_amount_taxable.map(x => x = x.id))
      }
      if (earning.ref_date && earning.ref_date != '') {
        earning.ref_date = moment(this.earning.ref_date).format("YYYY-MM-DD")
      }
      this.isLoading = true
      if (this.state == 'Add') {
        this.$http.post(`${this.$host}/api/payroll/earning/add/`,
          earning,
          this.config,
        ).then((response) => {
          let data = response.data
          this.$toast.open({
            message: 'Data successfully saved.',
            type: 'is-success',
            position: 'is-bottom-right',
            duration: 4000
          })
          //console.log(data)
          this.isLoading = false
          this.isAdd = false
          this.newEarning = {
            ref_date: new Date(),
            formula_amount: [],
            formula_amount_taxable: []
          }
          this.getEarnings()
        }).catch((error) => {
          this.$toast.open({
            message: 'Error saving data.',
            type: 'is-danger',
            position: 'is-bottom-right',
            duration: 4000
          })
          this.isLoading = false
        });
      }
      else if (this.state == 'Edit') {
        this.$http.put(`${this.$host}/api/payroll/earning/edit/${this.newEarning.earning_id}`,
          {
            ref_date: (this.prevEarning.ref_date && this.prevEarning.ref_date != '') ? moment(this.prevEarning.ref_date).format("YYYY-MM-DD") : null,
            payroll_type: this.prevEarning.payroll_type,
            fixed_amount: this.prevEarning.fixed_amount,
            fixed_amount_taxable: this.prevEarning.fixed_amount_taxable,
            formula_amount: (this.prevEarning.formula_amount) ? JSON.stringify(this.prevEarning.formula_amount.map(x => x = x.id)) : null,
            formula_amount_taxable: (this.prevEarning.formula_amount_taxable) ? JSON.stringify(this.prevEarning.formula_amount_taxable.map(x => x = x.id)) : null,
            new_ref_date: (this.newEarning.ref_date && this.newEarning.ref_date != '') ? moment(this.newEarning.ref_date).format("YYYY-MM-DD") : null,
            new_payroll_type: this.newEarning.payroll_type,
            new_fixed_amount: this.newEarning.fixed_amount,
            new_fixed_amount_taxable: this.newEarning.fixed_amount_taxable,
            new_formula_amount: (this.newEarning.formula_amount) ? JSON.stringify(this.newEarning.formula_amount.map(x => x = x.id)) : null,
            new_formula_amount_taxable: (this.newEarning.formula_amount_taxable) ? JSON.stringify(this.newEarning.formula_amount_taxable.map(x => x = x.id)) : null,
          },
          this.config,
        ).then((response) => {
          let data = response.data
          this.$toast.open({
            message: 'Data successfully saved.',
            type: 'is-success',
            position: 'is-bottom-right',
            duration: 4000
          })
          //console.log(data)
          this.isLoading = false
          this.isAdd = false
          this.newEarning = {
            ref_date: new Date(),
            formula_amount: [],
            formula_amount_taxable: []
          }
          this.getEarnings()
        }).catch((error) => {
          this.$toast.open({
            message: 'Error saving data.',
            type: 'is-danger',
            position: 'is-bottom-right',
            duration: 4000
          })
          this.isLoading = false
        });
      }
      else {
        this.isLoading = false
        this.$toast.open({
          message: 'Error connecting to server.',
          type: 'is-danger',
          position: 'is-bottom-right',
          duration: 4000
        })
      }
    },
    assignEarning(obj) {
      // //console.log(obj);
      this.checkedRows = []
      this.earningAttach = obj
      this.ref_date = obj.ref_date
      this.isLoading = true

      this.$http.get(`${this.$host}/api/payroll/setting/list/?ref_date=${obj.ref_date}`,
        this.config
      ).then((response) => {
        let data = response.data
        this.list = data
        this.isLoading = false
        for (let i = 0; i < this.list.length; i++) {
          for (let j = 0; j < obj.employee_id.length; j++) {
            if (obj.employee_id[j] == this.list[i].id) {
              this.checkedRows.push(this.list[i])
              let employee = this.list.splice(i, 1)
              //console.log(employee)
              this.list.unshift(employee[0])
            }
          }
        }
        this.isAssign = true
      }).catch((error) => {
        //console.log(error)
        this.isLoading = false
      });
    },
    getEmployees() {
      this.isLoading = true

      this.$http.get(`${this.$host}/api/payroll/setting/list/?ref_date=${this.ref_date}&employment_status_id=${this.employment_status_id}`,
        this.config
      ).then((response) => {
        let data = response.data
        this.list = data
        this.isLoading = false
        for (let i = 0; i < this.list.length; i++) {
          for (let j = 0; j < obj.employee_id.length; j++) {
            if (obj.employee_id[j] == this.list[i].id) {
              this.checkedRows.push(this.list[i])
              let employee = this.list.splice(i, 1)
              //console.log(employee)
              this.list.unshift(employee[0])
            }
          }
        }
      }).catch((error) => {
        //console.log(error)
        this.isLoading = false
      });
    },
    closeAssign() {
      this.getEarnings()
      this.isAssign = false
    },
    getEarningTypes() {
      this.isLoading = true

      this.$http.get(`${this.$host}/api/payroll/earning/type/show/`,
        this.config
      ).then((response) => {
        let data = response.data
        this.types = data
        this.isLoading = false
      }).catch((error) => {
        this.isLoading = false
        //console.log(error)
      });
    },
    saveEarningTypes(obj) {
      this.isLoading = true

      let id = ''
      if (obj.id) {
        id = obj.id
      }

      this.$http.post(`${this.$host}/api/payroll/earning/type/save/${id}`,
        obj,
        this.config,
      ).then((response) => {
        let data = response.data
        this.$toast.open({
          message: 'Data successfully saved.',
          type: 'is-success',
          position: 'is-bottom-right',
          duration: 4000
        })
        //console.log(data)
        this.isLoading = false
        this.isTypeSave = false
        this.getEarningTypes()
      }).catch((error) => {
        this.$toast.open({
          message: 'Error saving data.',
          type: 'is-danger',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.isLoading = false
      });
    },
    deleteEarningTypes(id) {
      this.isLoading = true

      this.$http.delete(`${this.$host}/api/payroll/earning/type/delete/${id}`,
        this.config
      ).then((response) => {
        this.$toast.open({
          message: 'Row deleted.',
          type: 'is-success',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.getEarningTypes()
      }).catch((error) => {
        this.$toast.open({
          message: 'Error deleting row.',
          type: 'is-danger',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.isLoading = false
      });
    },
    getEarnings() {
      this.isLoading = true

      this.$http.get(`${this.$host}/api/payroll/earning/setting/show/`,
        this.config
      ).then((response) => {
        let data = response.data
        this.earnings = data
        this.isLoading = false
      }).catch((error) => {
        this.isLoading = false
        //console.log(error)
      });
    },
    addEarning(obj) {
      this.isLoading = true

      this.$http.get(`${this.$host}/api/parameter/payroll/earnings`,
        this.config
      ).then((response) => {
        let data = response.data
        this.earningsParams = data
        if (obj) {
          this.earning = obj
          this.state = 'Edit'
          if (this.earning.formula_amount) {
            if (this.earning.formula_amount.length > 0) {
              let lastVar = this.earning.formula_amount[this.earning.formula_amount.length - 1].code
              if (lastVar == '+' || lastVar == '-' || lastVar == '*' || lastVar == '/') {
                this.isOperator = false
              }
              else {
                this.isOperator = true
              }
            }
          }
          // if (this.earning.formula_amount_taxable) {
          //   let lastVarTax = this.earning.formula_amount_taxable[this.earning.formula_amount_taxable.length - 1].code
          //   if (lastVarTax == '+' || lastVarTax == '-' || lastVarTax == '*' || lastVarTax == '/') {
          //     this.isOperatorTax = false
          //   }
          //   else {
          //     this.isOperatorTax = true
          //   }
          // }
        }
        else {
          this.state = 'Add'
        }
        if (this.state == 'Add') {
          this.isLoading = false
          this.isSave = true
        }
        else if (obj.has_special_tax) {
          this.earning.fixed_amount_taxable = null
          this.earning.formula_amount_taxable = null
          this.getSpecialTax(obj.id, () => this.isSave = true)
        }
        else {
          this.isLoading = false
          this.isSave = true
        }
      }).catch((error) => {
        this.isLoading = false
        //console.log(error)
      });
    },
    save() {
      this.isLoading = true

      let id = ''
      if (this.earning.id) {
        id = this.earning.id
      }
      let earning
      earning = JSON.parse(JSON.stringify(this.earning))
      if (earning.formula_amount) {
        earning.formula_amount = JSON.stringify(earning.formula_amount.map(x => x = x.id))
      }
      if (earning.formula_amount_taxable) {
        earning.formula_amount_taxable = JSON.stringify(earning.formula_amount_taxable.map(x => x = x.id))
      }
      if (earning.has_special_tax == null) {
        this.earning.has_special_tax = false
      }
      if (earning.has_special_tax) {
        this.earning.fixed_amount_taxable = null
        this.earning.formula_amount_taxable = []
      }
      else {
        this.earning.has_special_tax = null
      }
      this.$http.post(`${this.$host}/api/payroll/earning/setting/save/${id}`,
        earning,
        this.config,
      ).then((response) => {
        let data = response.data
        this.$toast.open({
          message: 'Data successfully saved.',
          type: 'is-success',
          position: 'is-bottom-right',
          duration: 4000
        })
        //console.log(data)
        this.isLoading = false
        this.close()
      }).catch((error) => {
        this.$toast.open({
          message: 'Error saving data.',
          type: 'is-danger',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.isLoading = false
      });
    },
    deleteEarning(obj) {
      this.$dialog.confirm({
        title: 'Delete Earning',
        message: "Are you sure you want to delete this row? This action cannot be undone.",
        confirmText: 'Delete',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isLoading = true

          this.$http.delete(`${this.$host}/api/payroll/earning/setting/delete/${obj.id}`,
            this.config
          ).then((response) => {
            this.$toast.open({
              message: 'Row deleted.',
              type: 'is-success',
              position: 'is-bottom-right',
              duration: 4000
            })
            this.isLoading = false
            this.getEarnings()
          }).catch((error) => {
            this.$toast.open({
              message: 'Error deleting row.',
              type: 'is-danger',
              position: 'is-bottom-right',
              duration: 4000
            })
            this.isLoading = false
          });
        }
      })
    },
    close() {
      this.getEarnings()
      this.getEarningsParams()
      this.isSave = false
      this.earning = {
        use_code: 0,
        amount_type: 'Fixed',
        formula_amount: [],
        formula_amount_taxable: []
      }
      this.isOperator = false
      this.isOperatorTax = false
    },
    clearType() {
      if (this.earning.amount_type == 'Fixed') {
        this.earning.formula_amount = null
        this.earning.formula_amount_taxable = null
      }
      else if (this.earning.amount_type == 'Formula') {
        this.earning.fixed_amount = null
        this.earning.fixed_amount_taxable = null
        this.earning.formula_amount_taxable = []
        this.earning.formula_amount = []
        this.isOperator = false
        this.isOperatorTax = false
      }
    },
    clearClass() {
      this.earning.fixed_amount_taxable = null
      if (this.earning.amount_type == 'Formula') {
        this.earning.formula_amount_taxable = []
        this.earning.formula_amount = []
        this.isOperator = false
        this.isOperatorTax = false
      }
      else {
        this.earning.formula_amount_taxable = null
        this.earning.formula_amount = null
      }
    }
  }
}
</script>

<style>

</style>
