<template>
  <div style="overflow: visible !important;">
    <button class="button is-primary" @click.prevent="addCivil()" :disabled="isDisabled">
      Add Civil Service Eligibility
    </button>
    <b-table
      :data="data"
      focusable>  
      <template slot-scope="props">
        <b-table-column label="Type">
          {{ props.row.type }}
        </b-table-column>
        <b-table-column label="Rating">
          {{ props.row.rating }}
        </b-table-column>
        <b-table-column label="Date Conferment / Issued">
          {{ dateFormat(props.row.date_conferment) }}
        </b-table-column>
        <b-table-column label="Place Conferment">
          {{ props.row.place_conferment }}
        </b-table-column>
        <b-table-column label="License Number">
          {{ props.row.license_number }}
        </b-table-column>
        <b-table-column label="License Validity">
          {{ dateFormat(props.row.license_validity) }}
        </b-table-column>
        <b-table-column label="Actions">
          <button class="button is-small btn-action is-primary" @click.prevent="editRow(props.row)" :disabled="isDisabled"><i class="fa fa-pen"></i></button>
          <button class="button is-small btn-action is-danger" @click.prevent="deleteRow(props.row)" :disabled="isDisabled"><i class="fa fa-trash"></i></button>
        </b-table-column>
      </template>
      <template slot="empty">
        <b-table-column label="Type">
        </b-table-column>
        <b-table-column label="Rating">
        </b-table-column>
        <b-table-column label="Date Conferment / Issued">
        </b-table-column>
        <b-table-column label="Place Conferment">
        </b-table-column>
        <b-table-column label="License Number">
        </b-table-column>
        <b-table-column label="License Validity">
        </b-table-column>
        <b-table-column label="Actions">
        </b-table-column>
      </template>
    </b-table>
    <b-modal :active.sync="isComponentModalActive" has-modal-card :canCancel=false>
      <transition name="fade">
        <addedit-form :obj="civilservice" :state="state" @save="saveCivilService" @cancel="cancel"></addedit-form>
      </transition>
    </b-modal>
  </div>
</template>

<script>
const addeditForm = {
  props: {
    obj: Object,
    state: String
  },
  template: `
  <form @submit.prevent="$emit('save', obj)">
    <div class="modal-card" style="height: 35.625rem; margin-top: 4rem;">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ state }}</p>
      </header>
      <section class="modal-card-body">
        <b-field label="Type">
          <b-autocomplete
            v-model="obj.type"
            :data="filteredType"
            placeholder="Type"
            required>
          </b-autocomplete>
        </b-field>
        <b-field label="Rating">
          <b-input v-model="obj.rating" type="number" min="0" max="100" step="0.01" required></b-input>
        </b-field>
        <b-field label="Date Conferment">
          <b-datepicker
            placeholder="Click to select..."
            icon="calendar-today"
            v-model="obj.date_conferment"
            :date-formatter="d => d.toLocaleDateString('en-GB')"
            :date-parser="d => parseDate(d)"
            editable
            required>
          </b-datepicker>
        </b-field>
        <b-field label="Place Conferment">
          <b-input v-model="obj.place_conferment" required></b-input>
        </b-field>
        <b-field label="License Number">
          <b-input v-model="obj.license_number"></b-input>
        </b-field>
        <b-field label="License Validity">
          <b-datepicker
            placeholder="Click to select..."
            icon="calendar-today"
            v-model="obj.license_validity"
            position="is-top-right"
            :date-formatter="d => d.toLocaleDateString('en-GB')"
            :date-parser="d => parseDate(d)"
            editable>
          </b-datepicker>
        </b-field>
        <br>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-success" type="submit">{{ state }}</button>
        <button class="button is-danger" @click.prevent="$emit('cancel')">Cancel</button>
      </footer>
    </div>
  </form>`,
  computed: {
    filteredType() {
      return this.$suggestions.state.civil_type.filter((option) => {
        return option
          .toString()
          .toLowerCase()
          .indexOf(this.obj.type.toLowerCase()) >= 0
      })
    },
  },
  methods: {
    parseDate(d) {
      let parts = d.split("/")
      let dt = new Date(parseInt(parts[2], 10),
        parseInt(parts[1], 10) - 1,
        parseInt(parts[0], 10))
      return dt
    },
  }
}

import VueSimpleSuggest from 'vue-simple-suggest'
import '../../../css/styles.css'

export default {
  props: ["isDisabled", "data"],
  data() {
    return {
      isComponentModalActive: false,
      civilservice: {},
      state: '',
      previousData: {}
    }
  },
  components: {
    addeditForm,
    VueSimpleSuggest
  },
  methods: {
    dateFormat(d) {
      if (!d) {
        return d
      }
      else {
        d = new Date(d)
        return d.toLocaleDateString('en-GB')
      }
    },
    returnData() {
      let civil = {
        eligibilities: this.data
      }
      this.$emit('civil', civil)
    },
    addCivil() {
      this.civilservice = {
        id: null,
        type: '',
        rating: '',
        date_conferment: new Date(),
        place_conferment: '',
        license_number: '',
        license_validity: new Date(),
      }
      this.state = 'Add'
      this.isComponentModalActive = true
    },
    saveCivilService(obj) {
      let licenseValidity
      if (!obj.license_validity) {
        licenseValidity = null
      }
      else {
        licenseValidity = new Date(obj.license_validity)
      }

      if (this.state == 'Add') {
        this.data.push({
          id: obj.id,
          type: obj.type,
          rating: obj.rating,
          date_conferment: obj.date_conferment,
          place_conferment: obj.place_conferment,
          license_number: obj.license_number,
          license_validity: licenseValidity,
        });
      }
      this.close()
    },
    close() {
      this.isComponentModalActive = false
    },
    cancel() {
      this.isComponentModalActive = false
      if (this.state == 'Edit') {
        let index = this.data.findIndex(x => x.id === this.previousData.id)
        this.data[index] = this.previousData
      }
    },
    editRow(obj) {
      this.civilservice = obj
      this.civilservice.date_conferment = new Date(obj.date_conferment)
      if (obj.license_validity) {
        this.civilservice.license_validity = new Date(obj.license_validity)
      }
      else {
        this.civilservice.license_validity = null
      }
      this.state = 'Edit'
      this.previousData = JSON.parse(JSON.stringify(obj))
      this.isComponentModalActive = true
    },
    deleteRow(obj) {
      this.data = this.data.filter(x => {
        return x != obj;
      })
    },
  }
}
</script>
