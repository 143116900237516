<template>
  <div>
    <div class="columns header" style="margin-bottom: 0 !important;">
      <div class="column is-5" style="margin-left: 5px; font-size: 20px; text-transform: uppercase;">
        <b>APPRAISALS</b>
      </div>
      <div class="column is-auto">
        <button class="button is-primary is-hidden-tablet" style="margin-left: 10px;" @click.prevent="$router.push('/appraisals/employee')">
          <i class="fa fa-user"></i> &nbsp;Manage Employee Appraisals
        </button>
        <button class="button is-primary is-pulled-right is-hidden-mobile" style="margin-left: 10px;" @click.prevent="$router.push('/appraisals/employee')">
          <i class="fa fa-user"></i> &nbsp;Manage Employee Appraisals
        </button>
      </div>
    </div>
    <hr style="margin-top: 0 !important; margin-bottom: 0 !important;" class="has-background-grey-lighter is-hidden-mobile">
    <br>
    <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
    
    <div class="box">
      <div>
        <b-field label="My Appraisals"></b-field>
      </div>
      <div>
        <button class="button is-success control" @click.prevent="addAppraisal()">
          <i class="fa fa-plus"></i> &nbsp;Appraisal
        </button>
        <b-input v-model="search_query" placeholder="Search" class="is-pulled-right"/>
      </div>
      <br>
      <hr style="margin-top: 0; margin-bottom: 10px;">      
      <b-table
        :data="appraisals"
        :bordered=false
        :hoverable=true
        :paginated="true"
        :per-page="perPage">  
        <template slot-scope="props">
          <b-table-column label="Date">
            {{ props.row.date }}
          </b-table-column>
          <b-table-column label="Semester">
            {{ props.row.semester }}
          </b-table-column>
          <!-- <b-table-column label="Quality Rating Average">
            {{ props.row.numerical_rating }}
          </b-table-column>
          <b-table-column label="Efficiency Rating Average">
            {{ props.row.numerical_rating }}
          </b-table-column>
          <b-table-column label="Timeless Rating Average">
            {{ props.row.numerical_rating }}
          </b-table-column> -->
          <b-table-column label="Numerical Rating">
            {{ props.row.numerical_rating }}
          </b-table-column>
          <b-table-column label="Adjectival Rating">
            {{ adjectivalRate(props.row.numerical_rating) }}
          </b-table-column>
          <b-table-column label="View / Edit">
            <button class="button btn-action is-primary is-small" @click.prevent="editAppraisal(props.row)"><i class="fa fa-pen"></i></button>
          </b-table-column>
          <b-table-column label="MFO">
            <button class="button btn-action is-primary is-small" @click.prevent="viewMFO()"><i class="fa fa-eye"></i></button>
          </b-table-column>
        </template>
        <template slot="empty">
          <b-table-column label="Date">
          </b-table-column>
          <b-table-column label="Semester">
          </b-table-column>
          <b-table-column label="Numerical Rating">
          </b-table-column>
          <b-table-column label="Adjectival Rating">
          </b-table-column>
          <b-table-column label="View / Edit">
          </b-table-column>
          <b-table-column label="MFO">
          </b-table-column>
        </template>
      </b-table>
    </div>

    <b-modal :active.sync="isAddAppraisal" has-modal-card :canCancel=false>
      <transition name="fade">
        <form @submit.prevent="saveAppraisal()">
          <div class="modal-card" style="height: 35.625rem; margin-top: 4rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">Appraisal</p>
            </header>
            <section class="modal-card-body">
              <b-field label="Date">
                <b-datepicker
                  placeholder="Click to select..."
                  icon="calendar-today"
                  v-model="appraisal.date"
                  :date-formatter="d => d.toLocaleDateString('en-GB')"
                  :date-parser="d => parseDate(d)"
                  editable
                  required>
                </b-datepicker>
              </b-field>
              <b-field label="Semester">
                <b-select placeholder="Select a semester" v-model="appraisal.semester" expanded required>
                  <option value="1st">1st</option>
                  <option value="2nd">2nd</option>
                  <option value="other">Other</option>
                </b-select>
              </b-field>
              <b-datepicker v-if="appraisal.semester == 'other'"
                placeholder="Click to select..."
                icon="calendar-today"
                v-model="appraisal.month_from"
                :date-formatter="d => d.toLocaleDateString('en-GB')"
                :date-parser="d => parseDate(d)"
                editable
                required>
              </b-datepicker>
              <b-datepicker v-if="appraisal.semester == 'other'"
                placeholder="Click to select..."
                icon="calendar-today"
                v-model="appraisal.month_to"
                :date-formatter="d => d.toLocaleDateString('en-GB')"
                :date-parser="d => parseDate(d)"
                editable
                required>
              </b-datepicker>
              <!-- <b-field label="Numerical Rating">
                <b-input v-model="appraisal.numerical_rating" type="number" step=".1" min="1" max="5" required></b-input>
              </b-field> -->
              <br>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" type="submit">Save</button>
              <button class="button is-danger" @click.prevent="cancel()">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>

    <b-modal :active.sync="isMFO" has-modal-card :canCancel=false>
      <transition name="fade">
        <form action="">
          <div class="modal-card" style="width: 60rem; height: 35.625rem; margin-top: 4rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">MFO</p>
            </header>
            <section class="modal-card-body">
              <!-- <b-input v-model="search_query" placeholder="Search" /><br> -->
              <button class="button is-primary control" @click.prevent="addMFO()">
                <i class="fa fa-plus"></i> &nbsp;MFO
              </button><br><br>
              <b-table
                :data="mfo"
                :bordered=true
                :hoverable=true
                :paginated="true"
                :per-page="perPage">  
                <template slot-scope="props">
                  <b-table-column label="ID">
                    {{ props.row.number }}
                  </b-table-column>
                  <b-table-column label="Title">
                    {{ props.row.title }}
                  </b-table-column>
                  <b-table-column label="Quality Rating">
                    {{ props.row.quality }}
                  </b-table-column>
                  <b-table-column label="Efficiency Rating">
                    {{ props.row.efficiency }}
                  </b-table-column>
                  <b-table-column label="Timeless Rating">
                    {{ props.row.timeless }}
                  </b-table-column>
                  <b-table-column label="Average Rating">
                    {{ avg(props.row.quality, props.row.efficiency, props.row.timeless) }}
                  </b-table-column>
                  <b-table-column label="Adjectival Rating">
                    {{ adjectivalRate(avg(props.row.quality, props.row.efficiency, props.row.timeless)) }}
                  </b-table-column>
                  <b-table-column label="Edit">
                    <button class="button is-primary is-small" @click.prevent="addMFO(props.row)">
                      <i class="fa fa-pen"></i>
                    </button>
                  </b-table-column>
                </template>
                <template slot="empty">
                  <b-table-column label="Number">
                  </b-table-column>
                  <b-table-column label="Title">
                  </b-table-column>
                  <b-table-column label="Quality Rating">
                  </b-table-column>
                  <b-table-column label="Efficiency Rating">
                  </b-table-column>
                  <b-table-column label="Timeless Rating">
                  </b-table-column>
                  <b-table-column label="Edit">
                  </b-table-column>
                </template>
              </b-table>  

              <b-modal :active.sync="isAddMFO" has-modal-card :canCancel=false>
                <transition name="fade">
                  <form @submit.prevent="saveMFO()">
                    <div class="modal-card" style="height: 35.625rem; margin-top: 4rem;">
                      <header class="modal-card-head">
                        <p class="modal-card-title">Add MFO</p>
                      </header>
                      <section class="modal-card-body">
                        <b-field label="Number">
                          <b-input v-model="selectedMFO.number" required></b-input>
                        </b-field>
                        <b-field label="Title">
                          <b-input v-model="selectedMFO.title" required></b-input>
                        </b-field>
                        <b-field label="Quality Rating">
                          <b-input v-model="selectedMFO.quality" type="number" min="1" max="5" required></b-input>
                        </b-field>
                        <b-field label="Efficiency Rating">
                          <b-input v-model="selectedMFO.efficiency" type="number" min="1" max="5" required></b-input>
                        </b-field>
                        <b-field label="Timeless Rating">
                          <b-input v-model="selectedMFO.timeless" type="number" min="1" max="5" required></b-input>
                        </b-field>
                      </section>
                      <footer class="modal-card-foot">
                        <button class="button is-success" type="submit">Save</button>
                        <button class="button is-danger" @click.prevent="isAddMFO = false">Cancel</button>
                      </footer>
                    </div>
                  </form>
                </transition>
              </b-modal>

            </section>
            <footer class="modal-card-foot">
              <button class="button is-danger" @click.prevent="closeMFO()">Close</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      appraisals: [
        {
          id: 1,
          date: '2019-01-01',
          semester: '1st',
          numerical_rating: 1.1,
          mfo: []
        }
      ],
      appraisal: {
        id: null,
        date: new Date(),
        semester: null,
        numerical_rating: null
      },
      mfo: [
        {
          number: 'MFO1',
          title: 'Planning, Development & Implementation of A.S. Projects',
          quality: 5,
          efficiency: 4,
          timeless: 2,
        }
      ],
      selectedMFO: {
        number: null,
        title: null,
        quality: null,
        efficiency: null,
        timeless: null
      },
      perPage: 5,
      isMFO: false,
      isAddAppraisal: false,
      isAddMFO: false,
      search_query: null
    }
  },
  methods: {
    init() {

    },
    avg(q, e, t) {
      return ((parseInt(q) + parseInt(e) + parseInt(t)) / 3).toFixed(3)
    },
    parseDate(d) {
      let parts = d.split("/")
      let dt = new Date(parseInt(parts[2], 10),
        parseInt(parts[1], 10) - 1,
        parseInt(parts[0], 10))
      return dt
    },
    adjectivalRate(val) {
      if (val === 5) {
        return 'Outstanding'
      }
      else if (val >= 4) {
        return 'Very Satisfactory'
      }
      else if (val >= 3) {
        return 'Satisfactory'
      }
      else if (val >= 2) {
        return 'Unsatisfactory'
      }
      else if (val >= 1) {
        return 'Poor'
      }
      else {
        return 'Not Applicable'
      }
    },
    saveAppraisal() {
      this.isAddAppraisal = false

      // temporary code while there's no backend yet
      this.isLoading = true
      setTimeout(() => this.isLoading = false, 500)
      if (!this.appraisal.id) {
        setTimeout(() => this.isMFO = true, 500)
      }
    },
    addAppraisal() {
      this.appraisal = {
        id: null,
        date: new Date(),
        semester: null,
        numerical_rating: null
      },
      this.isAddAppraisal = true
    },
    editAppraisal(obj) {
      this.appraisal = JSON.parse(JSON.stringify(obj))
      this.appraisal.date = new Date(this.appraisal.date)
      this.isAddAppraisal = true
    },
    cancel() {
      this.isAddAppraisal = false
      this.appraisal = {
        id: null,
        date: new Date(),
        semester: null,
        numerical_rating: null
      },

      this.init()
    },
    saveMFO() {
      this.mfo.push(this.selectedMFO)
      this.isAddMFO = false
    },
    addMFO(obj) {
      if (obj) {
        this.selectedMFO = obj
      }
      else {
        this.selectedMFO = {
          number: null,
          title: null,
          quality: null,
          efficiency: null,
          timeless: null
        }
      }
      this.isAddMFO = true
    },
    viewMFO() {
      this.isMFO = true
    },
    closeMFO() {
      this.isMFO = false
    }
  }
}
</script>

<style>

</style>