<template>
  <div>
    <div class="columns header" style="margin-bottom: 0 !important;">
      <div class="column is-5" style="margin-left: 5px; font-size: 20px; text-transform: uppercase;">
        <b>APPRAISALS</b> {{ fullName }}
      </div>
      <div class="column is-auto">
        <button class="button is-primary is-hidden-tablet" style="margin-left: 10px;" @click.prevent="isPrint = true"
        v-if="$role.state.permissions['print appraisal']">
          <i class="fa fa-print"></i> &nbsp;Print Appraisals
        </button>
        <button class="button is-primary is-hidden-tablet" style="margin-left: 10px;" @click.prevent="isSearch = true"
        v-if="$role.state.permissions['search appraisal']">
          <i class="fa fa-user"></i> &nbsp;Search Employee
        </button>
        <button class="button is-primary is-pulled-right is-hidden-mobile" style="margin-left: 10px;" @click.prevent="isSearch = true"
        v-if="$role.state.permissions['search appraisal']">
          <i class="fa fa-user"></i> &nbsp;Search Employee
        </button>
        <button class="button is-primary is-pulled-right is-hidden-mobile" style="margin-left: 10px;" @click.prevent="isPrint = true"
        v-if="$role.state.permissions['print appraisal']">
          <i class="fa fa-print"></i> &nbsp;Print Appraisals
        </button>
      </div>
    </div>
    <hr style="margin-top: 0 !important; margin-bottom: 0 !important;" class="has-background-grey-lighter is-hidden-mobile">
    <br>
    <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>

    <div class="box">
      <div>
        <b-field>
          <button class="button is-success control" @click.prevent="addAppraisal()"
          v-if="$role.state.permissions['write appraisal']">
            <i class="fa fa-plus"></i> &nbsp;Appraisal
          </button>
        </b-field>
        <b-field grouped>
          <b-input v-model="year_query" @input="yearFilter()" placeholder="Year" type="number"/>
          <!-- <b-input v-model="search_qusemester already savedery" placeholder="Search"/> -->
        </b-field>
      </div>
      <br>
      <hr style="margin-top: 0; margin-bottom: 10px;">
      <b-table
        :data="appraisals"
        :bordered=false
        :hoverable=true
        :paginated="true"
        :per-page="perPage">
        <template slot-scope="props">
          <b-table-column label="Date">
            {{ props.row.date }}
          </b-table-column>
          <b-table-column label="Semester">
            {{ isOther(props.row) }}
          </b-table-column>
          <!-- <b-table-column label="Quality Rating Average">
            {{ props.row.numerical_rating }}
          </b-table-column>
          <b-table-column label="Efficiency Rating Average">
            {{ props.row.numerical_rating }}
          </b-table-column>
          <b-table-column label="Timeliness Rating Average">
            {{ props.row.numerical_rating }}
          </b-table-column> -->
          <b-table-column label="Numerical Rating">
            {{ props.row.average }}
          </b-table-column>
          <b-table-column label="Adjectival Rating">
            {{ adjectivalRate(props.row.average) }}
          </b-table-column>
          <b-table-column label="MFO">
            <button class="button btn-action is-primary is-small" @click.prevent="viewMFO(props.row)"><i class="fa fa-eye"></i></button>
          </b-table-column>
          <b-table-column label="Actions" v-if="$role.state.permissions['write appraisal']">
            <button class="button btn-action is-primary is-small" @click.prevent="editAppraisal(props.row)"><i class="fa fa-pen"></i></button>
            <button class="button btn-action is-danger is-small" @click.prevent="deleteAppraisal(props.row)"><i class="fa fa-trash"></i></button>
          </b-table-column>
        </template>
        <template slot="empty">
          <b-table-column label="Date">
            NO APPRAISALS FOUND
          </b-table-column>
          <b-table-column label="Semester">
          </b-table-column>
          <b-table-column label="Numerical Rating">
          </b-table-column>
          <b-table-column label="Adjectival Rating">
          </b-table-column>
          <b-table-column label="MFO">
          </b-table-column>
          <b-table-column label="Actions" v-if="$role.state.permissions['write appraisal']">
          </b-table-column>
        </template>
      </b-table>
      <span><b>5</b> - Outstanding, <b>4</b> - Very Satisfactory, <b>3</b> - Satisfactory, <b>2</b> - Unsatisfactory, <b>1</b> - Poor</span><br>
      <!-- <br>
      <b>Numerical Rating Average</b>: {{ avg2(filter) }} <br>
      <b>Adjectival Rating Average</b>: {{ adjectivalRate(avg2(filter)) }} -->
    </div>

    <b-modal :active.sync="isAddAppraisal" has-modal-card :canCancel=false>
      <transition name="fade">
        <form @submit.prevent="saveAppraisal()">
          <div class="modal-card" style="height: 35.625rem; margin-top: 4rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">Appraisal</p>
            </header>
            <section class="modal-card-body">
              <b-field label="Date">
                <b-datepicker
                  placeholder="Click to select..."
                  icon="calendar-today"
                  v-model="appraisal.date"
                  :date-formatter="d => d.toLocaleDateString('en-GB')"
                  :date-parser="d => parseDate(d)"
                  editable
                  required>
                </b-datepicker>
              </b-field>
              <b-field label="Semester">
                <!-- <b-select placeholder="Select a semester" v-model="appraisal.semester" expanded required>
                  <option value="1st">1st</option>
                  <option value="2nd">2nd</option>
                  <option value="other">Other</option>
                </b-select> -->
              </b-field>
                <b-radio v-model="appraisal.semester" native-value="1st">1st</b-radio>
                <b-radio v-model="appraisal.semester" native-value="2nd">2nd</b-radio>
                <b-radio v-model="appraisal.semester" native-value="others">Other</b-radio>
              <b-field label="Month From" v-if="appraisal.semester == 'others'">
                <b-select v-model="appraisal.month_from" placeholder="Month">
                  <option value="January">January</option>
                  <option value="February">February</option>
                  <option value="April">April</option>
                  <option value="March">March</option>
                  <option value="May">May</option>
                  <option value="June">June</option>
                  <option value="July">July</option>
                  <option value="August">August</option>
                  <option value="September">September</option>
                  <option value="October">October</option>
                  <option value="November">November</option>
                  <option value="December">December</option>
                </b-select>
              </b-field>
              <b-field label="Month To" v-if="appraisal.semester == 'others'">
                <b-select v-model="appraisal.month_to" placeholder="Month">
                  <option value="January">January</option>
                  <option value="February">February</option>
                  <option value="April">April</option>
                  <option value="March">March</option>
                  <option value="May">May</option>
                  <option value="June">June</option>
                  <option value="July">July</option>
                  <option value="August">August</option>
                  <option value="September">September</option>
                  <option value="October">October</option>
                  <option value="November">November</option>
                  <option value="December">December</option>
                </b-select>
              </b-field>
              <b-field label="Remarks" v-if="appraisal.semester == 'others'">
                <b-input v-model="appraisal.remarks"></b-input>
              </b-field>
              <br>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" type="submit">Save</button>
              <button class="button is-danger" @click.prevent="cancel()">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>

    <b-modal :active.sync="isMFO" has-modal-card :canCancel=false>
      <transition name="fade">
        <form action="">
          <div class="modal-card pos" style="width: 60rem; height: 35.625rem; margin-left: 100px; margin-top: 4px;">
            <header class="modal-card-head">
              <p class="modal-card-title">MFO</p>
            </header>
            <section class="modal-card-body">
              <!-- <b-input v-model="search_query" placeholder="Search" /><br> -->
              <button class="button is-primary control" @click.prevent="addMFO()">
                <i class="fa fa-plus"></i> &nbsp;MFO
              </button>
              <br>
              <b>Overall Average Rating</b>: {{ avgMFO(mfo, 'average') }} &nbsp;&nbsp;
              <b>Overall Adjectival Rating</b>: {{ adjectivalRate(avgMFO(mfo, 'average')) }}
              <br>
              <b-table
                :data="mfo"
                :bordered=true
                :hoverable=true
                :paginated="true"
                :per-page="perPage">
                <template slot-scope="props">
                  <b-table-column label="ID">
                    {{ props.row.number }}
                  </b-table-column>
                  <b-table-column label="Title">
                    {{ props.row.title }}
                  </b-table-column>
                  <b-table-column label="Description">
                    {{ props.row.description }}
                  </b-table-column>
                  <b-table-column label="Quality Rating">
                    {{ props.row['Q'] }}
                  </b-table-column>
                  <b-table-column label="Efficiency Rating">
                    {{ props.row['E'] }}
                  </b-table-column>
                  <b-table-column label="Timeliness Rating">
                    {{ props.row['T'] }}
                  </b-table-column>
                  <b-table-column label="Average Rating">
                    <span v-if="props.row.average ">{{ props.row.average }}</span>
                    <span v-else>{{ avg(props.row['Q'], props.row['E'], props.row['T']) }}</span>
                  </b-table-column>
                  <b-table-column label="Adjectival Rating">
                    {{ adjectivalRate(props.row.average) }}
                  </b-table-column>
                  <b-table-column label="Actions">
                    <button class="button is-primary is-small" @click.prevent="addMFO(props.row)">
                      <i class="fa fa-pen"></i>
                    </button>
                    <button class="button is-danger is-small" @click.prevent="deleteMFO(props.row.id)">
                      <i class="fa fa-trash"></i>
                    </button>
                  </b-table-column>
                </template>
                <template slot="empty">
                  <b-table-column label="ID">
                  </b-table-column>
                  <b-table-column label="Title">
                  </b-table-column>
                  <b-table-column label="Description">
                  </b-table-column>
                  <b-table-column label="Quality Rating">
                  </b-table-column>
                  <b-table-column label="Efficiency Rating">
                  </b-table-column>
                  <b-table-column label="Timeliness Rating">
                  </b-table-column>
                  <b-table-column label="Average Rating">
                  </b-table-column>
                  <b-table-column label="Adjectival Rating">
                  </b-table-column>
                  <b-table-column label="Actions">
                  </b-table-column>
                </template>
              </b-table>
              <!-- <b>SUMMARY</b> <br> -->
              <!-- <b>Quality Rating</b>: {{ avgMFO(mfo, 'Q') }} &nbsp;&nbsp;
              <b>Efficiency Rating</b>: {{ avgMFO(mfo, 'E') }} &nbsp;&nbsp;
              <b>Timeliness Rating</b>: {{ avgMFO(mfo, 'T') }} <br> -->

              <b-modal :active.sync="isAddMFO" has-modal-card :canCancel=false>
                <transition name="fade">
                  <form @submit.prevent="saveMFO()">
                    <div class="modal-card" style="height: 35.625rem; margin-top: 4rem;">
                      <header class="modal-card-head">
                        <p class="modal-card-title">Add MFO</p>
                      </header>
                      <section class="modal-card-body">
                        <b-field label="ID">
                          <b-input v-model="selectedMFO.number" required></b-input>
                        </b-field>
                        <b-field label="Title">
                          <b-autocomplete
                            v-model="selectedMFO.title"
                            :data="filteredMFO"
                            field="title"
                            @select="option => {selectedMFO.description = option.description}"
                            :open-on-focus=false required expanded>
                          </b-autocomplete>
                        </b-field>
                        <b-field label="Description">
                          <b-input v-model="selectedMFO.description" type="textarea"></b-input>
                        </b-field>
                        <b-field label="Quality Rating">
                          <b-field>
                            <b-radio v-model="selectedMFO['Q']" native-value="">N/A</b-radio>
                            <b-radio v-model="selectedMFO['Q']" native-value="1">1</b-radio>
                            <b-radio v-model="selectedMFO['Q']" native-value="2">2</b-radio>
                            <b-radio v-model="selectedMFO['Q']" native-value="3">3</b-radio>
                            <b-radio v-model="selectedMFO['Q']" native-value="4">4</b-radio>
                            <b-radio v-model="selectedMFO['Q']" native-value="5">5</b-radio>
                          </b-field>
                        </b-field>
                        <b-field label="Efficiency Rating">
                          <b-field>
                            <b-radio v-model="selectedMFO['E']" native-value="">N/A</b-radio>
                            <b-radio v-model="selectedMFO['E']" native-value="1">1</b-radio>
                            <b-radio v-model="selectedMFO['E']" native-value="2">2</b-radio>
                            <b-radio v-model="selectedMFO['E']" native-value="3">3</b-radio>
                            <b-radio v-model="selectedMFO['E']" native-value="4">4</b-radio>
                            <b-radio v-model="selectedMFO['E']" native-value="5">5</b-radio>
                          </b-field>
                        </b-field>
                        <b-field label="Timeliness Rating">
                          <b-field>
                            <b-radio v-model="selectedMFO['T']" native-value="">N/A</b-radio>
                            <b-radio v-model="selectedMFO['T']" native-value="1">1</b-radio>
                            <b-radio v-model="selectedMFO['T']" native-value="2">2</b-radio>
                            <b-radio v-model="selectedMFO['T']" native-value="3">3</b-radio>
                            <b-radio v-model="selectedMFO['T']" native-value="4">4</b-radio>
                            <b-radio v-model="selectedMFO['T']" native-value="5">5</b-radio>
                          </b-field>
                        </b-field>
                      </section>
                      <footer class="modal-card-foot">
                        <button class="button is-success" type="submit">Save</button>
                        <button class="button is-danger" @click.prevent="isAddMFO = false">Cancel</button>
                      </footer>
                    </div>
                  </form>
                </transition>
              </b-modal>

            </section>
            <footer class="modal-card-foot">
              <button class="button is-danger" @click.prevent="closeMFO()">Close</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>

    <b-modal :active.sync="isPrint" has-modal-card :canCancel=false>
      <transition name="fade">
        <form action="">
          <div class="modal-card" style="height: 35.625rem; margin-top: 70px !important;">
            <header class="modal-card-head">
              <p class="modal-card-title">Signature Field</p>
            </header>
            <section class="modal-card-body">
              <b-field label="Year">
                <b-input v-model="year" placeholder="Year" type="number"/>
              </b-field>
              <b-field label="Prepared By">
                <b-autocomplete
                  v-model="name_1"
                  :data="filteredName1"
                  field="full_name"
                  @select="option => {position_1 = option.position}"
                  :open-on-focus=false expanded>
                </b-autocomplete>
              </b-field>
              <b-field label="Position">
                <b-input
                  v-model="position_1"
                  expanded>
                </b-input>
              </b-field>
              <b-field label="Noted By">
                <b-autocomplete
                  v-model="name_2"
                  :data="filteredName2"
                  field="full_name"
                  @select="option => {position_2 = option.position}"
                  :open-on-focus=false expanded>
                </b-autocomplete>
              </b-field>
              <b-field label="Position">
                <b-input
                  v-model="position_2"
                  expanded>
                </b-input>
              </b-field>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" @click.prevent="print()">Print</button>
              <button class="button is-danger" type="button" @click.prevent="isPrint=false">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>

    <transition name="fade">
      <EmployeeSearch search-title="Employee" @close="close" @view="view_employee" v-if="isSearch" />
    </transition>
  </div>
</template>

<script>
import EmployeeSearch from '../components/SearchModal.vue'

import moment from 'moment'

export default {
  components: {
    EmployeeSearch
  },
  data() {
    return {
      config: '',
      isLoading: false,
      employee_id: null,
      isSearch: false,
      fullName: null,
      appraisals: [],
      appraisal: {
        id: null,
        date: new Date(),
        semester: null
      },
      mfo: [],
      selectedMFO: {
        id: null,
        number: null,
        title: '',
        description: null,
        Q: null,
        E: null,
        T: null
      },
      perPage: 5,
      isMFO: false,
      isAddAppraisal: false,
      isAddMFO: false,
      search_query: '',
      appraisal_id: null,
      year_query: '',
      mfoSuggest: [],
      isPrint: false,
      year: '',
      name_1: '',
      position_1: '',
      name_2: '',
      position_2: '',
      suggest: []
    }
  },
  mounted() {
    this.isSearch = true

    this.config = {
      headers: {'Authorization': "Bearer " + this.$store.state.token}
    };
  },
  computed: {
    filter() {
      var i;
      var name_re = new RegExp(this.search_query, 'i')
      var data = []
      for (i in this.appraisals) {
        if ([this.appraisals[i].date, this.appraisals[i].semester].join(' ').match(name_re)) {
          data.push(this.appraisals[i])
        }
      }
      return data
    },
    filteredMFO() {
      return this.mfoSuggest.filter((option) => {
        return option.title
          .toString()
          .toLowerCase()
          .indexOf(this.selectedMFO.title.toLowerCase()) >= 0
      })
    },
    filteredName1() {
      return this.suggest.filter((option) => {
        return option.full_name
          .toString()
          .toLowerCase()
          .indexOf(this.name_1.toLowerCase()) >= 0
      })
    },
    filteredName2() {
      return this.suggest.filter((option) => {
        return option.full_name
          .toString()
          .toLowerCase()
          .indexOf(this.name_2.toLowerCase()) >= 0
      })
    }
  },
  methods: {
    init() {
      this.isLoading = true

      this.getMFOSuggest()
      this.getAppraisals()
      this.getSuggest()
    },
    getSuggest() {
        this.$http.get(`${this.$host}/api/parameter/service_record/employee`,
        this.config
      ).then((response) => {
        let data = response.data
        this.suggest = data

        let i = 0
        for (i in this.suggest) {
          this.suggest[i].full_name = this.suggest[i].full_name.toUpperCase()
        }
      }).catch((error) => {
        //console.log(error)
      });
    },
    getAppraisals() {
      this.$http.get(`${this.$host}/api/appraisal/search/${this.employee_id}`,
        this.config
      ).then((response) => {
        let data = response.data
        this.appraisals = data
        this.isLoading = false
      }).catch((error) => {
        //console.log(error)
        this.isLoading = false
      });
    },
    getMFOSuggest() {
      this.$http.get(`${this.$host}/api/parameter/mfo`,
        this.config
      ).then((response) => {
        let data = response.data
        let i = 0
        for (i in data) {
          data[i].title = data[i].title.toUpperCase()
        }
        this.mfoSuggest = data
        this.isLoading = false
        // //console.log(this.mfoSuggest)
      }).catch((error) => {
        //console.log(error)
        this.isLoading = false
      });
    },
    isOther(obj) {
      if (obj.semester == 'others') {
        return `${obj.month_from} - ${obj.month_to}`
      }
      else {
        return obj.semester
      }
    },
    avg(q, e, t) {
      return ((parseInt(q) + parseInt(e) + parseInt(t)) / 3).toFixed(3)
    },
    avg2(arr) {
      if (arr.length > 0) {
        let sum = arr.reduce(function(count, b) { return count + b.average; }, 0)
        let avg = sum / arr.length
        return avg.toFixed(3)
      }
      else {
        return 0
      }
    },
    avgMFO(arr, key) {
      if (arr.length > 0) {
        let divisor = 0
        for (let i = 0; i < arr.length; i++) {
          if (arr[i][key]) {
            divisor = divisor + 1
          }
        }

        let sum = arr.reduce(function(count, b) { return count + b[key]; }, 0)
        let avg = sum / divisor
        return avg.toFixed(3)
      }
      else {
        return 0
      }
    },
    parseDate(d) {
      let parts = d.split("/")
      let dt = new Date(parseInt(parts[2], 10),
        parseInt(parts[1], 10) - 1,
        parseInt(parts[0], 10))
      return dt
    },
    adjectivalRate(val) {
      if (val === 5) {
        return 'Outstanding'
      }
      else if (val >= 4) {
        return 'Very Satisfactory'
      }
      else if (val >= 3) {
        return 'Satisfactory'
      }
      else if (val >= 2) {
        return 'Unsatisfactory'
      }
      else if (val >= 1) {
        return 'Poor'
      }
      else {
        return 'Not Applicable'
      }
    },
    saveAppraisal() {
      this.isLoading = true

      let appraisal = JSON.parse(JSON.stringify(this.appraisal))
      appraisal.date = moment(appraisal.date).format("YYYY-MM-DD")
      appraisal.employee_id = this.employee_id

      let id = appraisal.id
      if (!id) {
        id = ''
      }

      this.$http.post(`${this.$host}/api/appraisal/save/${id}`,
        appraisal,
        this.config,
      ).then((response) => {
        this.$toast.open({
          message: 'Data successfully saved.',
          type: 'is-success',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.init()
        this.isLoading = false
        this.isAddAppraisal = false
        if (!this.appraisal.id) {
          this.appraisal_id = response.data.id
          setTimeout(() => this.isMFO = true, 500)
        }
      }).catch((error) => {
        this.isLoading = false
        if (error.response.data == 'semester already saved') {
          this.$toast.open({
            message: 'Semester already exists.',
            type: 'is-danger',
            position: 'is-bottom-right',
            duration: 4000
          })
        }
        else {
          this.$toast.open({
            message: 'Error saving data.',
            type: 'is-danger',
            position: 'is-bottom-right',
            duration: 4000
          })
        }
      });
    },
    addAppraisal() {
      this.appraisal = {
        id: null,
        date: new Date(),
        semester: null
      },
      this.isAddAppraisal = true
    },
    editAppraisal(obj) {
      this.appraisal = JSON.parse(JSON.stringify(obj))
      this.appraisal.date = new Date(this.appraisal.date)
      this.isAddAppraisal = true
    },
    deleteAppraisal(obj) {
      this.$dialog.confirm({
        title: 'Delete Appraisal',
        message: 'Are you sure you want to <b>delete</b> this appraisal? This action cannot be undone.',
        confirmText: 'Delete',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isLoading = true

          this.$http.delete(`${this.$host}/api/appraisal/delete/${obj.id}`,
            this.config
          ).then((response) => {
            this.$toast.open({
              message: 'Appraisal deleted.',
              type: 'is-success',
              position: 'is-bottom-right',
              duration: 4000
            })
            this.init()
            this.isLoading = false
          }).catch((error) => {
            this.$toast.open({
              message: 'Error.',
              type: 'is-danger',
              position: 'is-bottom-right',
              duration: 4000
            })
            this.isLoading = false
          });
        }
      })
    },
    cancel() {
      this.isAddAppraisal = false
      this.appraisal = {
        id: null,
        date: new Date(),
        semester: null,
        numerical_rating: null
      },

      this.init()
    },
    saveMFO(id) {
      this.isLoading = true

      let mfo = this.selectedMFO

      if (mfo.id) {
        this.$http.put(`${this.$host}/api/mfo/edit/${mfo.id}`,
          mfo,
          this.config,
        ).then((response) => {
          this.$toast.open({
            message: 'Data successfully saved.',
            type: 'is-success',
            position: 'is-bottom-right',
            duration: 4000
          })
          this.getMFO()
          this.isAddMFO = false
        }).catch((error) => {
          this.isLoading = false
          this.$toast.open({
            message: 'Error saving data.',
            type: 'is-danger',
            position: 'is-bottom-right',
            duration: 4000
          })
        });
      }
      else {
        this.$http.post(`${this.$host}/api/mfo/add/${this.appraisal_id}`,
          mfo,
          this.config,
        ).then((response) => {
          this.$toast.open({
            message: 'Data successfully saved.',
            type: 'is-success',
            position: 'is-bottom-right',
            duration: 4000
          })
          this.isLoading = false
          this.getMFO()
          this.isAddMFO = false
        }).catch((error) => {
          this.isLoading = false
          this.$toast.open({
            message: 'Error saving data.',
            type: 'is-danger',
            position: 'is-bottom-right',
            duration: 4000
          })
        });
      }
    },
    getMFO() {
      this.$http.get(`${this.$host}/api/mfo/search/${this.appraisal_id}`,
        this.config
      ).then((response) => {
        let data = response.data
        this.mfo = data
        this.isLoading = false
      }).catch((error) => {
        //console.log(error)
        this.isLoading = false
      });
    },
    deleteMFO(id) {
      this.$dialog.confirm({
        title: 'Delete MFO',
        message: 'Are you sure you want to <b>delete</b> this MFO? This action cannot be undone.',
        confirmText: 'Delete',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isLoading = true

          this.$http.delete(`${this.$host}/api/mfo/delete/${id}`,
            this.config
          ).then((response) => {
            this.$toast.open({
              message: 'MFO deleted.',
              type: 'is-success',
              position: 'is-bottom-right',
              duration: 4000
            })
            this.getMFO()
            this.isLoading = false
          }).catch((error) => {
            this.$toast.open({
              message: 'Error.',
              type: 'is-danger',
              position: 'is-bottom-right',
              duration: 4000
            })
            this.isLoading = false
          });
        }
      })
    },
    addMFO(obj) {
      if (obj) {
        this.selectedMFO = obj
      }
      else {
        this.selectedMFO = {
          number: null,
          title: '',
          description: null,
          Q: null,
          E: null,
          T: null
        }
      }
      this.isAddMFO = true
    },
    viewMFO(obj) {
      this.appraisal_id = obj.id
      this.mfo = obj.mfo
      this.isMFO = true
    },
    closeMFO() {
      this.isMFO = false
      this.init()
    },
    view_employee(selected) {
      this.employee_id = selected.id
      if (selected.name_extension == null) {
        this.fullName = `${selected.first_name} ${selected.last_name}`
      }
      else {
        this.fullName = `${selected.first_name} ${selected.last_name} ${selected.name_extension}`
      }
      if (this.employee_id != undefined) {
        this.isSearch = false
        this.init()
      }
    },
    close() {
      if (this.employee_id) {
        this.isSearch = false
      }
    },
    yearFilter() {
      if (this.year_query.length == 4) {
        this.isLoading = true
        this.$http.get(`${this.$host}/api/appraisal/search/${this.employee_id}/${this.year_query}`,
          this.config
        ).then((response) => {
          let data = response.data
          this.appraisals = data
          this.isLoading = false
        }).catch((error) => {
          //console.log(error)
          this.isLoading = false
        });
      }
      else if (this.year_query.length == 0) {
        this.isLoading = true
        this.getAppraisals()
      }
    },
    print() {
      this.isLoading = true

      this.$http.get(`${this.$host}/api/report/generate/${this.$role.state.id}`,
        this.config
      ).then((response) => {
        this.isLoading = false
        let token = response.data

        let url = `${this.$host}/api/report/appraisal/${token}?year=${this.year}&name_1=${this.name_1}&position_1=${this.position_1}&name_2=${this.name_2}&position_2=${this.position_2}`
        let win = window.open(url, '_blank')
        win.focus()
        this.isPrint = false
      }).catch((error) => {
        //console.log(error)
        this.isLoading = false
      });
    },
  }
}
</script>

<style scoped>
@media screen and (min-width: 1px) and (max-width: 1215px)  {
  .modal-card {
    margin-left: 0px !important;
    width: 100% !important;
  }
  .pos {
    width: none;
  }
}
.pos {
  margin-top: 70px !important;
}
.modal-card-head {
  height: 50px;
}
</style>
