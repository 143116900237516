<template>
  <div>
    <div class="content columns header">
      <div class="column is-5" style="font-size: 22px;">
        ADD NEW EMPLOYEE
      </div>
      <div class="column is-auto">
        <router-link to="/employees" class="button is-primary is-pulled-right" style="margin-left: 10px;"
            @click.prevent="view_employee">
            <i class="fa fa-search"></i> &nbsp;Search
        </router-link>
      </div>
    </div>
    <EmployeeSearch search-title="Employee" @close="close()" @new="view_employee()" v-if="isSearch" />
    <EmployeeTabs :isAdd="isAdd" v-bind:id="id" />
  </div>
</template>

<script>
import EmployeeSearch from '../components/SearchModal.vue'
import EmployeeTabs from '../components/EmployeeTabs.vue'

export default {
  name: 'Employee',
  components: {
    EmployeeSearch,
    EmployeeTabs
  },
  data() {
    return {
      isSearch: false,
      isAdd: true,
      id: ''
    }
  },
  mounted() {
    this.$editmode.commit('editing', true)
    this.$editmode.commit('setUsername', '')
    this.$editmode.commit('setPassword', '')
  },
  methods: {
    close() {
      this.isSearch = false
    },
    add() {
      
    },
    view_employee() {
      this.$dialog.confirm({
        title: 'Discard Changes',
        message: 'You are currently adding a new data. Are you sure you want to <b>discard</b> your changes? This action cannot be undone.',
        confirmText: 'Discard',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.$router.push('/employees')
      })
    }
  }
}
</script>
  
<style scoped>
</style>
