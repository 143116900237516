<template>
  <div>
    <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
    <div class="box">
      <b-table
        :data="MORSData"
        mobile-cards
      >
        <template slot-scope="props">
          <b-table-column field="payroll_group" label="PAYROLL GROUP">
            {{props.row.payroll_group}}
          </b-table-column>
          <b-table-column field="particulars" label="PARTICULARS">
            <p v-html="props.row.particulars">
              {{props.row.particulars}}
            </p>
          </b-table-column>
          <b-table-column field="actions" label="Actions">
            <button class="button btn-action is-success is-small" @click.prevent="editMORSData(props.row)"><i class="fa fa-pen"></i></button>
          </b-table-column>
        </template>
      </b-table>
    </div>

    <b-modal :active.sync="isMorsSetting" has-modal-card :canCancel=false>
      <transition name="fade">
        <form @submit.prevent="save()">
          <div class="modal-card" style="height: 35.625rem; margin-top: 4rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">MORS Particulars</p>
            </header>
            <section class="modal-card-body">
              <b-input v-model="MORSRow.id" type="hidden"></b-input>
              <b-field label="Parameters">
                <p>
                  <button class="button is-primary is-small" style="margin-right: 5px; margin-bottom: 5px;" v-for="parameter in parameters" :key="parameter.id" @click.prevent="applyParameter(parameter.value)">{{parameter.label}}</button>
                </p>
              </b-field>
              <b-field label="Particulars">
                  <b-input type="textarea"
                      id="ta"
                      v-model="MORSRow.hash_value"
                      placeholder="Modified Obligation Request and Status">
                  </b-input>
              </b-field>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" @click.prevent="saveMors(MORSRow.id)">Save</button>
              <button class="button is-danger" @click.prevent="cancelMors">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: true,
      config: {},
      MORSData: [],
      isMorsSetting: false,
      MORSRow: {},
      parameters: [
        {id:1,label:"AMOUNT",value:"AMOUNT"},
        {id:2,label:"PERIOD",value:"PERIOD"},
        {id:3,label:"PAYROLL GROUP",value:"PAYROLL_GROUP"},
        {id:4,label:"FUNDING SOURCE",value:"FUNDING_SOURCE"},
        {id:5,label:"YEAR",value:"YEAR"},
        {id:6,label:"EARNINGS",value:"EARNINGS"}
      ],
    }
  },
  mounted() {
    this.config = {
      headers: {'Authorization': "Bearer " + this.$store.state.token}
    };
    this.getSettings()
  },
  methods: {
    setParticulars(str) {
      let particulars = str
      particulars = `&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${particulars}`
      particulars = particulars.replace('{AMOUNT}', '<span class="custom-span-particulars">Amount</span>')
      particulars = particulars.replace('{PAYROLL_GROUP}', '<span class="custom-span-particulars">Payroll Group</span>')
      particulars = particulars.replace('{FUNDING_SOURCE}', '<span class="custom-span-particulars">Funding Source</span>')
      particulars = particulars.replace('{PERIOD}', '<span class="custom-span-particulars">Period</span>')
      particulars = particulars.replace('{YEAR}', '<span class="custom-span-particulars">Year</span>')
      particulars = particulars.replace('{EARNINGS}', '<span class="custom-span-particulars">EARNING</span>')
      return particulars;
    },
    getSettings() {
      this.MORSData = []
      this.$http.get(`${this.$host}/api/setting/show`,
        this.config
      ).then((response) => {
        let data = response.data
        let payroll_groups = [
          "Permanent/PA/Temporary/Co-Terminus","Casuals/Contractuals","Contact of Services","Job Order"
        ]
        for (var i=14;i<=17;i++) {
          let payroll_group = payroll_groups[i-14]
          data[i].particulars = this.setParticulars(data[i].hash_value);
          data[i].payroll_group = payroll_group
          this.MORSData.push(data[i]);
        }
        data[22].particulars = this.setParticulars(data[22].hash_value);
        data[22].payroll_group = "For Other Benefits";
        this.MORSData.push(data[22]);
        this.isLoading = false
      }).catch((error) => {
        this.isLoading = false
        //console.log(error)
      });
    },
    editMORSData(row) {
      this.MORSRow = row;
      this.isMorsSetting = true;
    },
    saveMors(id) {
      this.isLoading = true
      this.$http.put(`${this.$host}/api/setting/update/${id}`,
      { hash_value: this.MORSRow.hash_value },
      this.config
      ).then((response) => {
          this.isMorsSetting = false;
          this.getSettings()
      }).catch((error) => {
          this.isLoading = false
      });
    },
    applyParameter(parameter) {
      var textArea = document.getElementById('ta')
      var startPos = textArea.selectionStart,
        endPos = textArea.selectionEnd,
        cursorPos = startPos,
        tmpStr = textArea.value;
      if (parameter === null) {
        return;
      }
      this.MORSRow.hash_value = tmpStr.substring(0,startPos) + "{" + parameter + "}" + tmpStr.substring(endPos,tmpStr.length);

      setTimeout(() => {
        cursorPos += parameter.length;
        textArea.selectionStart = textArea.selectionEnd = cursorPos;
      }, 10);
    },
    cancelMors() {
      this.isLoading = true
      this.getSettings()
      this.isMorsSetting = false
    }
  }
}
</script>

<style>

</style>
