<template>
  <div>
    <div class="columns header" style="margin-bottom: 0 !important;">
      <div class="column is-5" style="margin-left: 5px; font-size: 20px; text-transform: uppercase;">
        <b>MANAGE TRAININGS</b>
      </div>
      <div class="column is-auto">
        <button class="button is-primary is-hidden-tablet" style="margin-left: 10px;" @click.prevent="$router.push('/trainings')">
          <i class="fa fa-book"></i> &nbsp;My Trainings
        </button>
        <button class="button is-primary is-hidden-tablet" style="margin-left: 10px;" @click.prevent="$router.push('/trainings/employee')"
        v-if="$role.state.permissions['search training']">
          <i class="fa fa-search"></i> &nbsp;Search Employee
        </button>

        <button class="button is-primary is-pulled-right is-hidden-mobile" style="margin-left: 10px;" @click.prevent="$router.push('/trainings/employee')"
        v-if="$role.state.permissions['search training']">
          <i class="fa fa-search"></i> &nbsp;Search Employee
        </button>
        <button class="button is-primary is-pulled-right is-hidden-mobile" style="margin-left: 10px;" @click.prevent="$router.push('/trainings')">
          <i class="fa fa-book"></i> &nbsp;My Trainings
        </button>
      </div>
    </div>
    <hr style="margin-top: 0 !important; margin-bottom: 0 !important;" class="has-background-grey-lighter is-hidden-mobile">
    <br>
    <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
    <div class="box">
      <div>
        <!-- <span style="font-weight: 500;">Trainings</span> -->
        <button class="button is-success control" @click.prevent="addTraining()" v-if="$role.state.permissions['write training']">
          <i class="fa fa-plus"></i> &nbsp;Training
        </button>
        <button class="button is-primary control" @click.prevent="printTraining()" v-if="$role.state.permissions['print training']">
          <i class="fa fa-print"></i> &nbsp;Print Summary
        </button>
        <b-input v-model="search_training" placeholder="Search" @input="getTrainings" class="is-pulled-right"/>
      </div>
      <br>
      <hr style="margin-top: 0; margin-bottom: 10px;">
      <b-table
        :data="filter"
        :bordered=true
        :hoverable=true
        :paginated="true"
        :per-page="perPage"
        :current-page.sync="currentPage"
        detailed
        detail-key="id">
        <template slot-scope="props">
          <b-table-column label="Program">
            {{ props.row.program }}
          </b-table-column>
          <!-- <b-table-column label="Date From">
            {{ props.row.date_from }}
          </b-table-column>
          <b-table-column label="Date To">
            {{ props.row.date_to }}
          </b-table-column>
          <b-table-column label="Number of Hours">
            {{ props.row.number_of_hours }}
          </b-table-column> -->
          <b-table-column label="Type of LD">
            {{ props.row.type_of_ld }}
          </b-table-column>
          <b-table-column label="Sponsored By">
            {{ props.row.sponsored_by }}
          </b-table-column>
          <b-table-column label="Conducted By">
            {{ props.row.conducted_by }}
          </b-table-column>
          <b-table-column label="Location">
            {{ props.row.is_foreign }}
          </b-table-column>
          <b-table-column label="Actions" width="150">
            <button class="button btn-action is-success is-small" @click.prevent="addTrainingDate(props.row.id)"><i class="fa fa-plus"></i></button>
            <button class="button btn-action is-primary is-small" @click.prevent="editTraining(props.row)"><i class="fa fa-pen"></i></button>
            <button class="button btn-action is-danger is-small" @click.prevent="deleteTraining(props.row.id)"><i class="fa fa-trash"></i></button>
          </b-table-column>
        </template>
        <template slot="empty">
          <b-table-column label="Program">
          </b-table-column>
          <!-- <b-table-column label="Date From">
          </b-table-column>
          <b-table-column label="Date To">
          </b-table-column> -->
          <b-table-column label="Type of LD">
          </b-table-column>
          <b-table-column label="Sponsored By">
          </b-table-column>
          <b-table-column label="Conducted By">
          </b-table-column>
          <b-table-column label="Location">
          </b-table-column>
          <b-table-column label="Actions">
          </b-table-column>
        </template>
        <template slot="detail" slot-scope="props">
          <article class="media">
            <div class="media-content">
              <div class="content">
                <b>Description:</b><br>
                <span v-if="props.row.description && props.row.description != ''" v-html="props.row.description"></span>
                <span v-else>None</span>
                <br><br>

                <b-table
                  :data="props.row.trainings"
                  :bordered=true
                  :hoverable=true
                  :paginated=true
                  :per-page=5
                  :pagination-simple=true>
                  <template slot-scope="props">
                    <b-table-column label="Date From">
                      {{ props.row.date_from }}
                    </b-table-column>
                    <b-table-column label="Date To">
                      {{ props.row.date_to }}
                    </b-table-column>
                    <b-table-column label="Number of Hours">
                      {{ props.row.number_of_hours }}
                    </b-table-column>
                    <b-table-column label="Actions" v-if="$role.state.permissions['write training']">
                      <button class="button btn-action is-primary is-small" @click.prevent="editTrainingDate(props.row)"><i class="fa fa-pen"></i></button>
                      <button class="button btn-action is-primary is-small" @click.prevent="showEmployees(props.row)"><i class="fa fa-user-plus"></i></button>
                      <button class="button btn-action is-danger is-small" @click.prevent="deleteDate(props.row.id)" v-if="!props.row.employee"><i class="fa fa-trash"></i></button>
                    </b-table-column>
                  </template>
                  <template slot="empty">
                  </template>
                </b-table>
              </div>
            </div>
          </article>
        </template>
      </b-table>
    </div>
    <b-modal :active.sync="isViewEmployees" has-modal-card :canCancel=false>
      <transition name="fade">
        <form action="">
          <div class="modal-card" style="height: 35.625rem; margin-top: 4rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">Training</p>
            </header>
            <section class="modal-card-body">
              <b-input v-model="search_query" placeholder="Search" /><br>
              <b-table
                :data="trainingEmployees"
                :bordered=true
                :hoverable=true
                :paginated="true"
                per-page="5">
                <template slot-scope="props">
                  <b-table-column label="Employee Number">
                    {{ props.row.employee_number }}
                  </b-table-column>
                  <b-table-column label="Name">
                    {{ props.row.full_name }}
                  </b-table-column>
                  <b-table-column label="View">
                    <button class="button btn-action is-primary is-small" @click.prevent="viewTrainings(props.row)"><i class="fa fa-book"></i></button>
                  </b-table-column>
                </template>
                <template slot="empty">
                  <b-table-column label="Employee Number">
                  </b-table-column>
                  <b-table-column label="Name">
                  </b-table-column>
                  <b-table-column label="View">
                  </b-table-column>
                </template>
              </b-table>
            </section>
            <footer class="modal-card-foot">
              <!-- <button class="button is-success" @click.prevent="viewTrainings(props.row.id)">View</button> -->
              <button class="button is-danger" @click.prevent="isViewEmployees = false">Close</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>
    <b-modal :active.sync="isAddTraining" has-modal-card :canCancel=false>
      <transition name="fade">
        <form @submit.prevent="saveTraining()">
          <div class="modal-card" style="height: 35.625rem; margin-top: 4rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">{{ state }} Training</p>
            </header>
            <section class="modal-card-body">
              <b-field label="Program" v-if="state != 'Add Date for' && state != 'Edit Date for'">
                <b-autocomplete
                  v-model="training.program"
                  :data="filteredPrograms"
                  @select="option => {
                    selected = option,
                    training.type_of_ld = option.type_of_ld,
                    training.sponsored_by = option.sponsored_by,
                    training.location = option.is_foreign,
                    training.conducted_by = option.conducted_by,
                    training.description = option.description
                    }"
                  field="program"
                  :open-on-focus=false expanded required>
                </b-autocomplete>
              </b-field>
              <b-field label="Date From" v-if="state != 'Edit'">
                <b-datepicker
                  icon="calendar-today"
                  v-model="training.date_from"
                  :date-formatter="d => d.toLocaleDateString('en-GB')"
                  expanded required>
                </b-datepicker>
              </b-field>
              <b-field label="Date To" v-if="state != 'Edit'">
                <b-datepicker
                  icon="calendar-today"
                  v-model="training.date_to"
                  :date-formatter="d => d.toLocaleDateString('en-GB')"
                  expanded required>
                </b-datepicker>
              </b-field>
              <b-field label="Number of Hours" v-if="state != 'Edit'">
                <b-input v-model="training.number_of_hours" type="number" expanded required>
                </b-input>
              </b-field>
              <b-field label="Type of LD" v-if="state != 'Add Date for'  && state != 'Edit Date for'">
                <b-autocomplete
                  v-model="training.type_of_ld"
                  :data="filteredLd"
                  :open-on-focus=false expanded required>
                </b-autocomplete>
              </b-field>
              <b-field label="Sponsored by" v-if="state != 'Add Date for' && state != 'Edit Date for'">
                <b-autocomplete
                  v-model="training.sponsored_by"
                  :data="filteredSponsors"
                  :open-on-focus=false expanded required>
                </b-autocomplete>
              </b-field>
              <b-field label="Conducted by" v-if="state != 'Add Date for' && state != 'Edit Date for'">
                <b-autocomplete
                  v-model="training.conducted_by"
                  :data="filteredConductors"
                  :open-on-focus=false expanded required>
                </b-autocomplete>
              </b-field>
              <b-field label="Location" v-if="state != 'Add Date for' && state != 'Edit Date for'">
                <b-select placeholder="Select location" v-model="training.location" expanded required>
                  <option value="LOCAL">LOCAL</option>
                  <option value="FOREIGN">FOREIGN</option>
                </b-select>
              </b-field>
              <b-field label="Description and Requirements" v-if="state != 'Add Date for' && state != 'Edit Date for'">
                <quill-editor v-model="training.description"
                  ref="myQuillEditor"
                  :options="editorOption"
                  required>
                </quill-editor>
              </b-field>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" type="submit">Save</button>
              <button class="button is-danger" @click.prevent="close()">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>
    <b-modal :active.sync="isAssign" has-modal-card>
      <EmployeeSelect @assigned="assign" :training_employees="training_employees" />
    </b-modal>
  </div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import moment from 'moment'

import EmployeeSelect from '../components/EmployeeSelect.vue'

export default {
  components: {
    quillEditor,
    EmployeeSelect
  },
  data() {
    return {
      perPage: 10,
      currentPage: 0,
      search_training: '',
      isLoading: false,
      config: '',
      state: null,
      search_query: '',
      search_employee: '',
      isAddTraining: false,
      isViewEmployees: false,
      trainings: [
      ],
      training: {
        id: null,
        program: '',
        number_of_hours: null,
        type_of_ld: '',
        sponsored_by: '',
        conducted_by: '',
        location: null,
        is_foreign: null,
        description: null
      },
      previousTraining: {},
      employees: [],
      trainingEmployees: [],
      editorOption: {
        "modules": {
          // "toolbar": false
          toolbar: [
            ['bold', 'italic', 'underline'],
            [{'list': 'ordered'}, {'list': 'bullet'}]
          ]
        }
      },
      isAssign: false,
      fullName: null,
      traning_id: null,
      trainingData: {
        employee_id: [],
        date_from: null,
        date_to: null,
        number_of_hours: null
      },
      programs: [],
      ld_types: [],
      sponsors: [],
      conductors: [],
      selected: null,
      training_employees: []
    }
  },
  mounted() {
    this.config = {
      headers: {'Authorization': "Bearer " + this.$store.state.token}
    };

    this.getEmployees()
  },
  computed: {
    filter() {
      var i;
      var name_re = new RegExp(this.search_query, 'i')
      var data = []
      for (i in this.trainings) {
        if ([this.trainings[i].program, this.trainings[i].type_of_ld, this.trainings[i].sponsored_by, this.trainings[i].conducted_by].join(' ').match(name_re)) {
          data.push(this.trainings[i])
        }
      }
      return data
    },
    filteredPrograms() {
      return this.programs.filter((option) => {
        return option.program
          .toString()
          .toLowerCase()
          .indexOf(this.training.program.toLowerCase()) >= 0
      })
    },
    filteredLd() {
      return this.ld_types.filter((option) => {
        return option
          .toString()
          .toLowerCase()
          .indexOf(this.training.type_of_ld.toLowerCase()) >= 0
      })
    },
    filteredSponsors() {
      return this.sponsors.filter((option) => {
        return option
          .toString()
          .toLowerCase()
          .indexOf(this.training.sponsored_by.toLowerCase()) >= 0
      })
    },
    filteredConductors() {
      return this.conductors.filter((option) => {
        return option
          .toString()
          .toLowerCase()
          .indexOf(this.training.conducted_by.toLowerCase()) >= 0
      })
    }
  },
  methods: {
    init() {
      this.getPrograms()
      this.getSuggest()
      this.getTrainings()
    },
    getSuggest() {
      this.$http.get(`${this.$host}/api/parameter/training/suggest`,
        this.config
      ).then((response) => {
        let data = response.data
        this.ld_types = data.type_of_ld
        this.sponsors = data.sponsored_by
        this.conductors = data.conducted_by
      }).catch((error) => {
        //console.log(error)
      });
    },
    getPrograms() {
      this.$http.get(`${this.$host}/api/parameter/training/fill`,
        this.config
      ).then((response) => {
        let data = response.data
        this.programs = data
      }).catch((error) => {
        //console.log(error)
      });
    },
    getTrainings() {
      this.isLoading = true
      this.$http.get(`${this.$host}/api/training/show/?perPage=${this.perPage}&currentPage=${(this.currentPage)}&search=${this.search_training}`,
        this.config
      ).then((response) => {
        let data = response.data
        this.trainings = data
        this.isLoading = false
      }).catch((error) => {
        this.isLoading = false
        //console.log(error)
      });
    },
    close() {
      this.isAddTraining = false
      this.init()
      this.training = {
        id: null,
        program: '',
        number_of_hours: null,
        type_of_ld: '',
        sponsored_by: '',
        conducted_by: '',
        location: null,
        is_foreign: null,
        description: null
      }
    },
    addTraining() {
      this.state = 'Add'
      this.isAddTraining = true
    },
    addTrainingDate(id) {
      this.state = 'Add Date for'
      this.training.id = id
      this.isAddTraining = true
    },
    editTrainingDate(obj) {
      this.state = 'Edit Date for'
      this.training = JSON.parse(JSON.stringify(obj))

      if (!this.training.date_from) {
        this.training.date_from = new Date()
      }
      if (!this.training.date_to) {
        this.training.date_to = new Date()
      }

      this.training.date_from = new Date(this.training.date_from)
      this.training.date_to = new Date(this.training.date_to)
      this.previousTraining = JSON.parse(JSON.stringify(this.training))
      this.isAddTraining = true
    },
    editTraining(obj) {
      //console.log(obj)
      this.state = 'Edit'
      this.training = obj
      this.isAddTraining = true
    },
    edit(id) {
      this.$http.put(`${this.$host}/api/training/edit/${id}`,
        this.training,
        this.config,
      ).then((response) => {
        this.$toast.open({
          message: 'Data successfully saved.',
          type: 'is-success',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.isLoading = false
        this.close()
        this.isAddTraining = false
      }).catch((error) => {
        this.isLoading = false
        this.$toast.open({
          message: 'Error saving data.',
          type: 'is-danger',
          position: 'is-bottom-right',
          duration: 4000
        })
      });
    },
    saveTraining() {
      this.isLoading = true

      if (this.state == 'Add') {
        if (this.selected == undefined) {
          this.training.id = ''
          // alert(this.training.id)
        }
        else {
          this.training.id = this.selected.id
          // alert(this.selected.id)
        }
      }

      if (this.state == 'Edit') {
        this.edit(this.training.id)
      }
      else if (this.state == 'Add Date for') {
        this.saveDate(this.training.id)
      }
      else if (this.state == 'Edit Date for') {
        this.editDate(this.training)
      }
      else if (this.state == 'Add') {
        let training = this.training
        training.date_from = moment(this.training.date_from).format("YYYY-MM-DD")
        training.date_to = moment(this.training.date_to).format("YYYY-MM-DD")
        this.$http.post(`${this.$host}/api/training/admin/save/${this.training.id}`,
          this.training,
          this.config,
        ).then((response) => {
          this.$toast.open({
            message: 'Data successfully saved.',
            type: 'is-success',
            position: 'is-bottom-right',
            duration: 4000
          })
          this.isLoading = false
          this.close()
          this.isAddTraining = false
        }).catch((error) => {
          this.isLoading = false
          this.$toast.open({
            message: 'Error saving data.',
            type: 'is-danger',
            position: 'is-bottom-right',
            duration: 4000
          })
        });
      }
      else {
        this.isLoading = false
        this.$toast.open({
          message: 'Error saving data.',
          type: 'is-danger',
          position: 'is-bottom-right',
          duration: 4000
        })
      }
    },
    saveDate(id) {
      //console.log(id)
      let training = {
        date_from: moment(this.training.date_from).format("YYYY-MM-DD"),
        date_to: moment(this.training.date_to).format("YYYY-MM-DD"),
        number_of_hours: this.training.number_of_hours
      }
      this.$http.post(`${this.$host}/api/training/add/${id}`,
        training,
        this.config,
      ).then((response) => {
        this.$toast.open({
          message: 'Data successfully saved.',
          type: 'is-success',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.isLoading = false
        this.close()
        this.isAddTraining = false
      }).catch((error) => {
        this.isLoading = false
        this.$toast.open({
          message: 'Error saving data.',
          type: 'is-danger',
          position: 'is-bottom-right',
          duration: 4000
        })
      });
    },
    editDate(obj) {
      //console.log(obj)
      this.$http.put(`${this.$host}/api/training/employee/edit/${obj.training_id}`,
        {
          date_from: moment(this.previousTraining.date_from).format("YYYY-MM-DD"),
          date_to: moment(this.previousTraining.date_to).format("YYYY-MM-DD"),
          number_of_hours: this.previousTraining.number_of_hours,
          new_number_of_hours: this.training.number_of_hours,
          new_date_from: moment(this.training.date_from).format("YYYY-MM-DD"),
          new_date_to: moment(this.training.date_to).format("YYYY-MM-DD")
        },
        this.config,
      ).then((response) => {
        this.$toast.open({
          message: 'Data successfully saved.',
          type: 'is-success',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.isLoading = false
        this.close()
        this.isAddTraining = false
      }).catch((error) => {
        this.isLoading = false
        this.$toast.open({
          message: 'Error saving data.',
          type: 'is-danger',
          position: 'is-bottom-right',
          duration: 4000
        })
      });
    },
    deleteDate(id) {
      this.$dialog.confirm({
        title: 'Delete Training Date',
        message: 'Are you sure you want to <b>delete</b> this training date? This action cannot be undone.',
        confirmText: 'Delete',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isLoading = true

          this.$http.delete(`${this.$host}/api/training/employee/delete/${id}`,
            this.config
          ).then((response) => {
            this.$toast.open({
              message: 'Training deleted.',
              type: 'is-success',
              position: 'is-bottom-right',
              duration: 4000
            })
            this.init()
            this.isLoading = false
          }).catch((error) => {
            this.$toast.open({
              message: 'Error. Training is already attached to an employee.',
              type: 'is-danger',
              position: 'is-bottom-right',
              duration: 4000
            })
            this.isLoading = false
          });
        }
      })
    },
    deleteTraining(id) {
      this.$dialog.confirm({
        title: 'Delete Appraisal',
        message: 'Are you sure you want to <b>delete</b> this training? This action cannot be undone.',
        confirmText: 'Delete',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isLoading = true

          this.$http.delete(`${this.$host}/api/training/delete/${id}`,
            this.config
          ).then((response) => {
            this.$toast.open({
              message: 'Training deleted.',
              type: 'is-success',
              position: 'is-bottom-right',
              duration: 4000
            })
            this.init()
            this.isLoading = false
          }).catch((error) => {
            this.$toast.open({
              message: 'Error. Training is already attached to an employee.',
              type: 'is-danger',
              position: 'is-bottom-right',
              duration: 4000
            })
            this.isLoading = false
          });
        }
      })
    },
    viewTrainings(data) {
      this.$editmode.commit('setEmpData', data)
      this.$router.push('/trainings/employee')
    },
    printTraining() {
      this.isLoading = true

      let config = {
        headers: {'Authorization': "Bearer " + this.$store.state.token}
      };

      this.$http.get(`${this.$host}/api/report/generate/${this.$role.state.id}`,
        config
      ).then((response) => {
        this.isLoading = false
        let token = response.data

        let url = `${this.$host}/api/report/training/${token}`
        let win = window.open(url, '_blank')
        win.focus()
      }).catch((error) => {
        //console.log(error)
        this.isLoading = false
      });
    },
    viewEmployees() {
      this.trainingEmployees = this.employees // to do: set employees for this training
      this.isViewEmployees = true
    },
    getEmployees() {
      this.isLoading = true
      this.$http.get(`${this.$host}/api/parameter/service_record/employee`,
        this.config
      ).then((response) => {
        let data = response.data
        this.employees = data
        this.init()
      }).catch((error) => {
        //console.log(error)
        this.isLoading = false
      });
    },
    assign(obj) {
      let ids = []
      obj.forEach((emp, index) => {
        ids.push(emp.id)
      })

      this.trainingData.employee_id = ids

      this.isLoading = true
      this.$http.post(`${this.$host}/api/training/add/${this.training_id}`,
        this.trainingData,
        this.config,
      ).then((response) => {
        this.$toast.open({
          message: 'Data successfully saved.',
          type: 'is-success',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.training_id = null
        this.trainingData = {
          employee_id: [],
          date_from: null,
          date_to: null,
          number_of_hours: null
        }
        this.isAssign = false
        this.isLoading = false
        this.close()
        this.isAddTraining = false
      }).catch((error) => {
        this.training_id = null
        this.trainingData = {
          employee_id: [],
          date_from: null,
          date_to: null,
          number_of_hours: null
        }
        this.isAssign = false
        this.isLoading = false
        this.$toast.open({
          message: 'Error saving data.',
          type: 'is-danger',
          position: 'is-bottom-right',
          duration: 4000
        })
      });
    },
    showEmployees(obj) {
      this.training_employees = obj.employee
      this.training_id = obj.training_id
      this.trainingData.date_from = obj.date_from
      this.trainingData.date_to = obj.date_to
      this.trainingData.number_of_hours = obj.number_of_hours
      this.search_query = ''
      this.isAssign = true
    },
    onEditorBlur(quill) {
      //console.log('editor blur!', quill)
    },
    onEditorFocus(quill) {
      //console.log('editor focus!', quill)
    },
    onEditorReady(quill) {
      //console.log('editor ready!', quill)
    },
    onEditorChange({ quill, html, text }) {
      //console.log('editor change!', quill, html, text)
      this.content = html
    }
  }
}
</script>

<style scoped>

</style>
