<template>
  <div>
    <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
    <div class="box">
      <div>
        <b-field>
          <b>Request Types</b>
        </b-field>
      </div>
      <hr>
      <b-table
        :data="types"
        :striped=true
        :bordered=true
        :hoverable=true
        :paginated="true"
        :per-page="5">
        <template slot-scope="props">
          <b-table-column label="Code" width="150">
            {{ props.row.code }}
          </b-table-column>
          <b-table-column label="Description" width="150">
            {{ props.row.description }}
          </b-table-column>
          <b-table-column label="Filing Period Type" width="150">
            {{ props.row.filing_period_type }}
          </b-table-column>
          <b-table-column label="Filing Period Days" width="150">
            {{ props.row.filing_period_days }}
          </b-table-column>
          <b-table-column label="Request Limit Minimum" width="150">
            {{ props.row.request_limit_min }}
          </b-table-column>
          <b-table-column label="Request Limit Maximum" width="150" v-if="props.row.code == 'MOL'">
            {{ props.row.request_limit_max }}%
          </b-table-column>
          <b-table-column label="Request Limit Maximum" width="150" v-else>
            {{ props.row.request_limit_max }}
          </b-table-column>
          <b-table-column label="Category" width="150">
            {{ props.row.category }}
          </b-table-column>
          <b-table-column label="Based On" width="150">
            {{ props.row.based_on }}
          </b-table-column>
          <b-table-column label="Actions" width="150">
            <button class="button btn-action is-primary is-small" @click.prevent="editRow(props.row)"><i class="fa fa-pen"></i></button>
          </b-table-column>
        </template>
        <template slot="empty">
          <b-table-column label="Code">
          </b-table-column>
          <b-table-column label="Description">
          </b-table-column>
          <b-table-column label="Filing Period Type" width="150">
          </b-table-column>
          <b-table-column label="Filing Period Days" width="150">
          </b-table-column>
          <b-table-column label="Request Limit Minimum" width="150">
          </b-table-column>
          <b-table-column label="Request Limit Maximum" width="150">
          </b-table-column>
          <b-table-column label="Category" width="150">
          </b-table-column>
          <b-table-column label="Based On" width="150">
          </b-table-column>
          <b-table-column label="Actions">
          </b-table-column>
        </template>
      </b-table>
    </div>
    <b-modal :active.sync="isComponentModalActive" has-modal-card :canCancel=false>
      <transition name="fade">
        <form action="">
          <div class="modal-card" style="height: 35.625rem; margin-top: 4rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">Request Type</p>
            </header>
            <section class="modal-card-body">
              <b-field label="Code">
                <b-input v-model="type.code" expanded disabled></b-input>
              </b-field>
              <b-field label="Description">
                <b-input v-model="type.description" expanded></b-input>
              </b-field>
              <b-field label="Filing Period Type" v-if="type.code == 'MOL'">
                <b-field>
                  <b-select placeholder="Select Category" v-model="filing_month_1" expanded>
                    <option
                      v-for="option in months"
                      :value="option"
                      :key="option">
                      {{ option }}
                    </option>
                  </b-select>
                  <b-select placeholder="Select Category" v-model="filing_month_2" expanded>
                    <option
                      v-for="option in months"
                      :value="option"
                      :key="option">
                      {{ option }}
                    </option>
                  </b-select>
                </b-field>
              </b-field>
              <b-field label="Filing Period Type" v-else>
                <b-select placeholder="Select Category" v-model="type.filing_period_type" expanded>
                  <option value="BEFORE">BEFORE</option>
                  <option value="EITHER">EITHER</option>
                  <option value="AFTER">AFTER</option>
                </b-select>
              </b-field>
              <b-field label="Filing Period Days">
                <b-input v-model="type.filing_period_days" type="number" expanded></b-input>
              </b-field>
              <b-field label="Request Limit Minimum">
                <b-input v-model="type.request_limit_min" :max="type.request_limit_max" type="number" expanded></b-input>
              </b-field>
              <b-field label="Request Limit Maximum">
                <b-input v-model="type.request_limit_max" type="number" expanded></b-input>
              </b-field>
              <b-field label="Category">
                <b-select placeholder="Select Category" v-model="type.category" expanded>
                  <option value="LEAVES">LEAVES</option>
                  <option value="OB">OB</option>
                  <option value="OVERTIME">OVERTIME</option>
                </b-select>
              </b-field>
              <b-field label="Based On">
                <b-select placeholder="Based On" v-model="type.based_on" expanded>
                  <option value="SCHEDULE">SCHEDULE</option>
                  <option value="CALENDAR">CALENDAR</option>
                </b-select>
              </b-field>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" @click.prevent="save">Save</button>
              <button class="button is-danger" @click.prevent="close">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      config: '',
      isLoading: false,
      types: [],
      isComponentModalActive: false,
      type: {
        code: '',
        description: ''
      },
      months: ['JANUARY', 'FEBRUARY', 'MARCH', 'APRIL', 'MAY', 'JUNE', 'JULY', 'AUGUST', 'SEPTEMBER', 'OCTOBER', 'NOVEMBER', 'DECEMBER'],
      filing_month_1: '',
      filing_month_2: ''
    }
  },
  mounted() {
    this.config = {
      headers: {'Authorization': "Bearer " + this.$store.state.token}
    }
    this.getRequestTypes()
  },
  methods: {
    getRequestTypes() {
      this.isLoading = true
      this.$http.get(`${this.$host}/api/request-type/all/`,
        this.config
      ).then((response) => {
        let data = response.data
        this.types = data
        this.isLoading = false
      }).catch((error) => {
        this.isLoading = false
      });
    },
    editRow(obj) {
      //console.log(obj)
      if (obj.code == 'MOL') {
        let type = obj.filing_period_type.split(',');
        //console.log(type)
        this.filing_month_1 = type[0]
        this.filing_month_2 = type[1]
      }
      this.isComponentModalActive = true
      this.type = obj
    },
    close() {
      this.isComponentModalActive = false
      this.type = {
        id: null,
        code: '',
        description: ''
      }
      this.getRequestTypes()
    },
    save() {
      if (this.type.code == 'MOL') {
        this.type.filing_period_type = `${this.filing_month_1},${this.filing_month_2}`
      }
      this.isLoading = true
      this.$http.put(`${this.$host}/api/request-type/update/${this.type.id}`,
        this.type,
        this.config
      ).then((response) => {
        let data = response.data
        this.isLoading = false
        this.$toast.open({
          message: 'Type successfully saved.',
          type: 'is-success',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.close()
      }).catch((error) => {
        this.isLoading = false
        this.$toast.open({
          message: 'Error saving request type.',
          type: 'is-danger',
          position: 'is-bottom-right',
          duration: 4000
        })
      });
    }
  }
}
</script>

<style>

</style>
