<template>
  <div>
    <div class="columns header" style="margin-bottom: 0 !important;">
      <div class="column is-7" style="margin-left: 5px; font-size: 20px; text-transform: uppercase;">
        Name: <b>{{ name }}</b> <span style="margin-left: 10px;">Department: <b>{{ department }}</b></span>
        <b-tag type="is-success is-small" v-if="ot">OT Allowed: {{ ot }} hours</b-tag>
      </div>
      <div class="column is-auto">
        <button class="button is-primary is-pulled-right" style="margin-left: 10px;" @click.prevent="listUsers()" v-if="$role.state.permissions['search employee']">
          <i class="fa fa-search"></i> &nbsp;Search
        </button>
        <button class="button is-success is-pulled-right" style="margin-left: 10px;" @click.prevent="newEmployee()" v-if="$role.state.permissions['write employee']">
          <i class="fa fa-plus"></i> &nbsp;New Employee
        </button>
        <button class="button is-primary is-pulled-right" style="margin-left: 10px;" @click.prevent="printPDS()" v-if="$role.state.permissions['print employee']">
          <span><i class="fa fa-print"></i> Print</span>
        </button>
        <!-- <button class="button is-primary is-pulled-right"  @click.prevent="setOT()" v-if="$role.state.permissions['write employee']">
          <span><i class="fa fa-cog"></i> Settings</span>
        </button> -->
      </div>
    </div>
    <transition name="fade">
      <EmployeeSearch search-title="Employee" @close="close" @view="view_employee" v-if="isSearch" />
    </transition>
    <transition name="fade">
      <div class="scroll-print" v-if="isScrolled">
        <button v-if="$role.state.permissions['print employee']" title="Print" class="button is-medium is-primary" @click.prevent="printPDS()">
          <span><i class="fa fa-print"></i></span>
        </button>
        <button v-if="$role.state.permissions['write employee']" title="Add Employee" class="button is-medium is-success" @click.prevent="newEmployee">
          <span><i class="fa fa-plus"></i></span>
        </button>
        <button v-if="$role.state.permissions['search employee']" title="Search Employee" class="button is-medium is-primary" @click.prevent="listUsers">
          <span><i class="fa fa-search"></i></span>
        </button>
      </div>
    </transition>
    <EmployeeTabs v-bind:id="id" @isEdit="isEdit" :isAdd="isAdd" v-if="!isSearch" @refreshHeaderName="refreshName"/>
    <b-modal :active.sync="isSetOT" has-modal-card :canCancel=false>
      <transition name="fade">
        <form @submit.prevent="saveOT()">
          <div class="modal-card" style="max-height: 35.625rem; margin-top: 4rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">Settings</p>
            </header>
            <section class="modal-card-body">
              <b-field label="OT Allowed Hours">
                <b-input
                  v-model="ot_change"
                  type="number"
                  min="0"
                  expanded required>
                </b-input>
              </b-field>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" type="submit">Save</button>
              <button class="button is-danger" type="button" @click.prevent="closeOT()">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>
    <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
  </div>
</template>

<script>
import EmployeeSearch from '../components/SearchModal.vue'
import EmployeeTabs from '../components/EmployeeTabs.vue'
import EmployeeAdd from './EmployeeAdd.vue'

export default {
  name: 'Employee',
  components: {
    EmployeeSearch,
    EmployeeTabs
  },
  data() {
    return {
      isSearch: true,
      id: '',
      name: '',
      edit: false,
      isAdd: false,
      department: '',
      isScrolled: false,
      isSetOT: false,
      ot: null,
      ot_change: null,
      payroll_setting_id: null,
      isLoading: false,
    }
  },
  beforeMount() {
    if (this.$editmode.state.fromAdd == true) {
      this.isSearch = false
      this.id = this.$editmode.state.newEmpId
      this.$editmode.commit('setAdd', false)

      let config = {
        headers: {'Authorization': "Bearer " + this.$store.state.token}
      };
      this.$http.get(`${this.$host}/api/employee/search/${this.id}`,
        config
      ).then((response) => {
        let data = response.data.data
        if (data.name_extension == null) {
          this.name = `${data.first_name} ${data.last_name}`
        }
        else {
          this.name = `${data.first_name} ${data.last_name} ${data.name_extension}`
        }
        //console.log(data)
      }).catch((error) => {
        //console.log(error)
      });
    }

    window.addEventListener('scroll', this.scrollPrint);
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.scrollPrint);
  },
  methods: {
    getOT() {
      let config = {
          headers: {'Authorization': "Bearer " + this.$store.state.token}
        };
        this.$http.get(`${this.$host}/api/employee/search/${this.id}`,
          config
        ).then((response) => {
          let data = response.data.data
          if (data.name_extension == null) {
            this.name = `${data.first_name} ${data.last_name}`
          }
          else {
            this.name = `${data.first_name} ${data.last_name} ${data.name_extension}`
          }
          this.ot = data.ot_allowed
          this.ot_change = JSON.parse(JSON.stringify(data.ot_allowed))
          //console.log(data)
        }).catch((error) => {
          //console.log(error)
        });
    },
    getEmployee() {
      this.isLoading = true

      let config = {
        headers: {'Authorization': "Bearer " + this.$store.state.token}
      };
      this.$http.get(`${this.$host}/api/employee/search/${this.id}`,
        config
      ).then((response) => {
        let data = response.data.data
        if (data.name_extension == null) {
          this.name = `${data.first_name} ${data.last_name}`
        }
        else {
          this.name = `${data.first_name} ${data.last_name} ${data.name_extension}`
        }
        if (data.payroll_setting_id) {
          this.payroll_setting_id = data.payroll_setting_id
        }
        this.ot = data.ot_allowed
        this.ot_change = JSON.parse(JSON.stringify(data.ot_allowed))
        this.isSetOT = true
        this.isLoading = false
      }).catch((error) => {
        //console.log(error)
        this.isLoading = false
        this.isSetOT = false
      });
    },
    saveOT() {
      this.isLoading = true

      let config = {
        headers: {'Authorization': "Bearer " + this.$store.state.token}
      };
      this.$http.post(`${this.$host}/api/payroll/setting/assign/ot-allowed/`,
        {
          payroll_id: [this.payroll_setting_id],
          ot_allowed: this.ot_change
        },
        config
      ).then((response) => {
        let data = response.data
        this.$toast.open({
          message: 'OT Allowed saved.',
          type: 'is-success',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.isLoading = false
        this.closeOT()
      }).catch((error) => {
        this.$toast.open({
          message: 'Error.',
          type: 'is-danger',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.isLoading = false
        //console.log(error)
      });
    },
    closeOT() {
      this.getOT()
      this.isSetOT = false
    },
    setOT() {
      this.getEmployee()
    },
    scrollPrint() {
      if (window.scrollY != 0) {
        this.isScrolled = true
      }
      else {
        this.isScrolled = false
      }
    },
    isEdit(bool) {
      this.edit = bool
    },
    listUsers() {
      if (this.edit == true) {
        this.$dialog.confirm({
          title: 'Discard Changes',
          message: 'You are currently editing data. Are you sure you want to <b>discard</b> your changes? This action cannot be undone.',
          confirmText: 'Discard',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: () => {this.isSearch = true, this.edit = false}
        })
      }
      else {
        this.isSearch = true
      }
    },
    newEmployee() {
      if (this.edit == true) {
        this.$dialog.confirm({
          title: 'Discard Changes',
          message: 'You are currently editing data. Are you sure you want to <b>discard</b> your changes? This action cannot be undone.',
          confirmText: 'Discard',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: () => {this.$router.push({path: '/employees/add'}), this.edit = false}
        })
      }
      else {
        this.$router.push({path: '/employees/add'})
      }
    },
    refreshName(obj) {
      this.name = obj
    },
    close() {
      if (this.id) {
        this.isSearch = false
      }
    },
    view_employee(selected) {
      if (selected.id == undefined) {

      }
      else {
        if (selected.name_extension == null) {
          this.name = `${selected.first_name} ${selected.last_name}`
        }
        else {
          this.name = `${selected.first_name} ${selected.last_name} ${selected.name_extension}`
        }
        this.department = selected.division
        this.id = selected.id
        this.isSearch = false

        this.getOT()
      }
    },
    printPDS() {
      this.isLoading = true

      let config = {
        headers: {'Authorization': "Bearer " + this.$store.state.token}
      };

      this.$http.get(`${this.$host}/api/report/generate/${this.id}`,
        config
      ).then((response) => {
        this.isLoading = false
        let token = response.data

        let url = `${this.$host}/api/report/pds/${token}`
        let win = window.open(url, '_blank')
        win.focus()
        }).catch((error) => {
        //console.log(error)
        this.isLoading = false
      });
    }
  }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.scroll-print {
  position: fixed;
  z-index: 3;
  top: 90%;
  left: 87%;
}
@media screen and (min-width: 1px) and (max-width: 768px)  {
  .scroll-print {
    left: 55%
  }
}
</style>
