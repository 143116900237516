<template>
  <div>
    <button class="button is-primary" @click.prevent="addLearning()" :disabled="isDisabled">
      Add Learning / Development
    </button>
    <b-table
      :data="data"
      focusable>
      <template slot-scope="props">
        <b-table-column label="Program">
          {{ props.row.program }}
        </b-table-column>
        <b-table-column label="Date From">
          {{ dateFormat(props.row.date_from) }}
        </b-table-column>
        <b-table-column label="Date To">
          {{ dateFormat(props.row.date_to) }}
        </b-table-column>
        <b-table-column label="Number of Hours">
          {{ props.row.number_of_hours }}
        </b-table-column>
        <b-table-column label="Type of LD">
          {{ props.row.type_of_ld }}
        </b-table-column>
        <b-table-column label="Sponsored by">
          {{ props.row.sponsored_by }}
        </b-table-column>
        <b-table-column label="Conducted by">
          {{ props.row.conducted_by }}
        </b-table-column>
        <b-table-column label="Location">
          {{ props.row.location }}
        </b-table-column>
        <b-table-column label="Actions">
          <button class="button is-small btn-action is-primary" @click.prevent="editRow(props.row)" :disabled="isDisabled"><i class="fa fa-pen"></i></button>
          <button class="button is-small btn-action is-danger" @click.prevent="deleteRow(props.row)" :disabled="isDisabled"><i class="fa fa-trash"></i></button>
        </b-table-column>
      </template>
      <template slot="empty">
        <b-table-column label="Program">
        </b-table-column>
        <b-table-column label="Date From">
        </b-table-column>
        <b-table-column label="Date To">
        </b-table-column>
        <b-table-column label="Number of Hours">
        </b-table-column>
        <b-table-column label="Type of LD">
        </b-table-column>
        <b-table-column label="Sponsored by">
        </b-table-column>
        <b-table-column label="Conducted by">
        </b-table-column>
        <b-table-column label="Location">
        </b-table-column>
        <b-table-column label="Actions">
        </b-table-column>
      </template>
    </b-table>
    <b-modal :active.sync="isComponentModalActive" has-modal-card :canCancel=false>
      <transition name="fade">
        <addedit-form :obj="learning" :state="state" @save="saveLearning" @cancel="cancel"></addedit-form>
      </transition>
    </b-modal>
  </div>
</template>

<script>
const addeditForm = {
  props: {
    obj: Object,
    state: String
  },
  template: `
  <form @submit.prevent="save()">
    <div class="modal-card" style="height: 35.625rem; margin-top: 4rem;">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ state }}</p>
      </header>
      <section class="modal-card-body">
        <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
        <b-field label="Program">
          <b-autocomplete
            v-model="obj.program"
            :data="filteredPrograms"
            @select="option => {
              selected = option,
              obj.id = option.id,
              obj.type_of_ld = option.type_of_ld,
              obj.sponsored_by = option.sponsored_by,
              obj.location = option.is_foreign,
              obj.conducted_by = option.conducted_by,
              obj.description = option.description,
              existingTraining = JSON.parse(JSON.stringify(option))
              }"
            field="program"
            :open-on-focus=false expanded required>
          </b-autocomplete>
        </b-field>
        <b-field label="Date From">
          <b-datepicker
            placeholder="Click to select..."
            icon="calendar-today"
            v-model="obj.date_from"
            :date-formatter="d => d.toLocaleDateString('en-GB')"
            :date-parser="d => parseDate(d)"
            editable
            required>
          </b-datepicker>
        </b-field>
        <b-field label="Date To">
          <b-datepicker
            placeholder="Click to select..."
            icon="calendar-today"
            v-model="obj.date_to"
            :date-formatter="d => d.toLocaleDateString('en-GB')"
            :date-parser="d => parseDate(d)"
            editable
            required>
          </b-datepicker>
        </b-field>
        <b-field label="Number of Hours">
          <b-input v-model="obj.number_of_hours" type="number" required></b-input>
        </b-field>
        <b-field label="Type of LD">
          <b-autocomplete
            v-model="obj.type_of_ld"
            :data="filteredLd"
            :open-on-focus=false expanded required>
          </b-autocomplete>
        </b-field>
        <b-field label="Sponsored By">
          <b-autocomplete
            v-model="obj.sponsored_by"
            :data="filteredSponsors"
            :open-on-focus=false expanded required>
          </b-autocomplete>
        </b-field>
        <b-field label="Conducted By">
          <b-autocomplete
            v-model="obj.conducted_by"
            :data="filteredConductors"
            :open-on-focus=false expanded>
          </b-autocomplete>
        </b-field>
        <b-field label="Location">
          <b-select placeholder="Select location" v-model="obj.location" expanded>
            <option value="LOCAL">LOCAL</option>
            <option value="FOREIGN">FOREIGN</option>
          </b-select>
        </b-field>
        <br>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-success" type="submit">{{ state }}</button>
        <button class="button is-danger" @click.prevent="$emit('cancel')">Cancel</button>
      </footer>
    </div>
  </form>`,
  data() {
    return {
      config: '',
      isLoading: false,
      programs: [],
      ld_types: [],
      sponsors: [],
      conductors: [],
      selected: null,
      existingTraining: {}
    }
  },
  mounted() {
    this.config = {
      headers: {'Authorization': "Bearer " + this.$store.state.token}
    };

    this.getSuggest()
    this.getPrograms()
  },
  computed: {
    filteredPrograms() {
      return this.programs.filter((option) => {
        return option.program
          .toString()
          .toLowerCase()
          .indexOf(this.obj.program.toLowerCase()) >= 0
      })
    },
    filteredLd() {
      return this.ld_types.filter((option) => {
        return option
          .toString()
          .toLowerCase()
          .indexOf(this.obj.type_of_ld.toLowerCase()) >= 0
      })
    },
    filteredSponsors() {
      return this.sponsors.filter((option) => {
        return option
          .toString()
          .toLowerCase()
          .indexOf(this.obj.sponsored_by.toLowerCase()) >= 0
      })
    },
    filteredConductors() {
      return this.conductors.filter((option) => {
        return option
          .toString()
          .toLowerCase()
          .indexOf(this.obj.conducted_by.toLowerCase()) >= 0
      })
    }
  },
  methods: {
    save() {
      if (this.obj.program == this.existingTraining.program && this.obj.type_of_ld == this.existingTraining.type_of_ld && this.obj.sponsored_by == this.existingTraining.sponsored_by &&
      this.obj.location == this.existingTraining.is_foreign && this.obj.conducted_by == this.existingTraining.conducted_by && this.obj.description == this.existingTraining.description) {
        this.$emit('save', this.obj)
      }
      else {
        this.obj.id = null
        this.$emit('save', this.obj)
      }
      // //console.log(this.obj)
    },
    getSuggest() {
      this.$http.get(`${this.$host}/api/parameter/training/suggest`,
        this.config
      ).then((response) => {
        let data = response.data
        this.ld_types = data.type_of_ld
        this.sponsors = data.sponsored_by
        this.conductors = data.conducted_by
      }).catch((error) => {
        //console.log(error)
      });
    },
    getPrograms() {
      setTimeout(() => this.isLoading = true, 250)
      this.$http.get(`${this.$host}/api/parameter/training/fill`,
        this.config
      ).then((response) => {
        let data = response.data
        this.programs = data
        setTimeout(() => this.isLoading = false, 250)
      }).catch((error) => {
        this.isLoading = false
        setTimeout(() => this.isLoading = false, 250)
      });
    },
    parseDate(d) {
      let parts = d.split("/")
      let dt = new Date(parseInt(parts[2], 10),
        parseInt(parts[1], 10) - 1,
        parseInt(parts[0], 10))
      return dt
    },
  }
}

export default {
  props: ["isDisabled", "data", "id"],
  data() {
    return {
      isComponentModalActive: false,
      learning: {},
      state: '',
      previousData: {}
    }
  },
  components: {
    addeditForm
  },
  methods: {
    dateFormat(d) {
      d = new Date(d)
      return d.toLocaleDateString('en-GB')
    },
    returnData() {
      let learning = {
        trainings: this.data
      }
      this.$emit('learning', learning)
    },
    addLearning() {
      this.learning = {
        id: null,
        program: '',
        date_from: new Date(),
        date_to: new Date(),
        number_of_hours: '',
        type_of_ld: '',
        sponsored_by: '',
        conducted_by: '',
        location: null
      }
      this.state = 'Add'
      this.isComponentModalActive = true
    },
    saveLearning(obj) {
      let isShowPds
      if (this.id == 'self') {
        isShowPds = 0
      }
      else {
        isShowPds = 1
      }

      if (this.state == 'Add') {
        if (obj.id == '') {
          obj.id = null
        }

        this.data.push({
          id: obj.id,
          program: obj.program,
          date_from: obj.date_from,
          date_to: obj.date_to,
          number_of_hours: obj.number_of_hours,
          type_of_ld: obj.type_of_ld,
          sponsored_by: obj.sponsored_by,
          conducted_by: obj.conducted_by,
          location: obj.location,
          certificate_of_appearance: null,
          post_training_report: null,
          show_pds: isShowPds
        });
      }
      this.close()
    },
    close() {
      this.isComponentModalActive = false
    },
    cancel() {
      this.isComponentModalActive = false
      if (this.state == 'Edit') {
        let index = this.data.findIndex(x => x.id === this.previousData.id)
        this.data[index] = this.previousData
      }
    },
    editRow(obj) {
      this.learning = obj
      this.learning.date_from = new Date(obj.date_from)
      this.learning.date_to = new Date(obj.date_to)
      this.state = 'Edit'
      this.previousData = JSON.parse(JSON.stringify(obj))
      this.isComponentModalActive = true
    },
    deleteRow(obj) {
      this.data = this.data.filter(x => {
        return x != obj;
      })
    },
  }
}
</script>
