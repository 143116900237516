<template>
  <div>
    <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
    <div class="columns header" style="margin-bottom: 0 !important;">
      <div class="column is-4" style="margin-left: 5px; font-size: 20px; text-transform: uppercase;">
        <b>Service Record </b> {{ fullName }}
        <b-tag type="is-success is-small" v-if="ot">OT Allowed: {{ ot }} hours</b-tag>
      </div>
      <div class="column is-auto">
        <button v-if="$role.state.permissions['search service record']" class="button is-primary is-pulled-right is-hidden-mobile" style="margin-left: 10px;" @click.prevent="isSearch = true">
          <i class="fa fa-user-tie"></i> &nbsp;Employees
        </button>
        <button v-if="$role.state.permissions['print service record']" class="button is-primary is-hidden-tablet" @click.prevent="isPrint = true">
          <span><i class="fa fa-print"></i> Print Service Record</span>
        </button>
        <button v-if="$role.state.permissions['print service record']" class="button is-primary is-hidden-tablet" @click.prevent="isPrintCOE = true">
          <span><i class="fa fa-print"></i> &nbsp;Print Certificate of Employment</span>
        </button>

        <button v-if="$role.state.permissions['search service record']" class="button is-primary is-hidden-tablet" @click.prevent="isSearch = true">
          <i class="fa fa-user-tie"></i> &nbsp;Employees
        </button>
        <button v-if="$role.state.permissions['print service record']" style="margin-left: 10px;" class="button is-primary is-pulled-right is-hidden-mobile" @click.prevent="isPrintCOE = true">
          <span><i class="fa fa-print"></i> Print Certificate of Employment</span>
        </button>
        <button v-if="$role.state.permissions['print service record']" class="button is-primary is-pulled-right is-hidden-mobile" @click.prevent="isPrint = true">
          <span><i class="fa fa-print"></i> Print Service Record</span>
        </button>
        <button class="button is-primary is-pulled-right" style="margin-right: 10px;" @click.prevent="setOT()" v-if="$role.state.permissions['write service record']">
          <span><i class="fa fa-cog"></i> Settings</span>
        </button>
        <transition name="fade">
          <div class="scroll-print" v-if="isScrolled">
            <button v-if="$role.state.permissions['print service record']" title="Print" class="button is-medium is-primary" @click.prevent="isPrint = true">
              <span><i class="fa fa-print"></i></span>
            </button>
            <button v-if="$role.state.permissions['search service record']" title="Search Employee" class="button is-medium is-primary" @click.prevent="isSearch = true">
              <span><i class="fa fa-search"></i></span>
            </button>
          </div>
        </transition>
      </div>
    </div>
    <hr style="margin-top: 0 !important; margin-bottom: 0 !important;" class="has-background-grey-lighter is-hidden-mobile">
    <br>
    <transition name="fade">
      <Service :record="record" :employee="selected" :employee_id="id" :date_hired="dateHired" v-if="!isSearch" @reset="setService"/>
      <Search :data="data" @view="setEmployee" v-if="isSearch" @close="close"/>
    </transition>
    <b-modal :active.sync="isPrint" has-modal-card :canCancel=false>
      <transition name="fade">
        <form action="">
          <div class="modal-card">
            <header class="modal-card-head">
              <p class="modal-card-title">Signature Field</p>
            </header>
            <section class="modal-card-body">
              <b-field label="Supervisor">
                <b-autocomplete
                  v-model="supervisor"
                  :data="filteredSupervisor"
                  field="full_name"
                  @select="option => {position = option.position, division = option.division}"
                  :open-on-focus=false expanded>
                </b-autocomplete>
              </b-field>
              <b-field label="Position">
                <b-input
                  v-model="position"
                  expanded>
                </b-input>
              </b-field>
              <b-field label="Division">
                <b-input
                  v-model="division"
                  expanded>
                </b-input>
              </b-field><br><br>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" @click.prevent="printSR()">Print</button>
              <button class="button is-danger" type="button" @click.prevent="isPrint=false">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>

    <b-modal :active.sync="isPrintCOE" has-modal-card :canCancel=false>
      <transition name="fade">
        <form action="">
          <div class="modal-card" style="height: 35.625rem; margin-top: 70px !important;">
            <header class="modal-card-head">
              <p class="modal-card-title">Signature Field</p>
            </header>
            <section class="modal-card-body">
              <b-field label="SIGNATURE 1"></b-field>
              <b-field label="Name">
                <b-autocomplete
                  v-model="coeField.name_1"
                  :data="filteredName1"
                  field="full_name"
                  @select="option => {coeField.position_1 = option.position, coeField.division_1 = option.division}"
                  :open-on-focus=false expanded>
                </b-autocomplete>
              </b-field>
              <b-field label="Position">
                <b-input
                  v-model="coeField.position_1"
                  expanded>
                </b-input>
              </b-field>
              <b-field label="Division">
                <b-input
                  v-model="coeField.division_1"
                  expanded>
                </b-input>
              </b-field>
              <b-field label="SIGNATURE 2"></b-field>
              <b-field label="Name">
                <b-autocomplete
                  v-model="coeField.name_2"
                  :data="filteredName2"
                  field="full_name"
                  @select="option => {coeField.position_2 = option.position, coeField.division_2 = option.division}"
                  :open-on-focus=false expanded>
                </b-autocomplete>
              </b-field>
              <b-field label="Position">
                <b-input
                  v-model="coeField.position_2"
                  expanded>
                </b-input>
              </b-field>
              <b-field label="Division">
                <b-input
                  v-model="coeField.division_2"
                  expanded>
                </b-input>
              </b-field>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" @click.prevent="printCE()">Print</button>
              <button class="button is-danger" type="button" @click.prevent="isPrintCOE=false">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>
    <b-modal :active.sync="isSetOT" has-modal-card :canCancel=false>
      <transition name="fade">
        <form @submit.prevent="saveOT()">
          <div class="modal-card" style="max-height: 35.625rem; margin-top: 4rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">Settings</p>
            </header>
            <section class="modal-card-body">
              <b-field label="OT Allowed Hours">
                <b-input
                  v-model="ot_change"
                  type="number"
                  min="0"
                  expanded required>
                </b-input>
              </b-field>
              <b-field label="Hours Per Month" v-if="$editmode.state.isJo == true">
                <b-input
                  v-model="hours_per_month_change"
                  type="number"
                  min="0"
                  expanded required>
                </b-input>
              </b-field>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" type="submit">Save</button>
              <button class="button is-danger" type="button" @click.prevent="closeOT()">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>
  </div>
</template>

<script>
import Search from '../components/service/Search.vue'
import Service from '../components/service/Service.vue'

export default {
  components: {
    Search,
    Service
  },
  data() {
    return {
      isSearch: true,
      data: {},
      id: '',
      selected: {},
      fullName: '',
      record: '',
      isLoading: false,
      isScrolled: false,
      dateHired: '',
      isPrint: false,
      supervisor: '',
      position: '',
      division: '',
      suggest: [],
      employees: [],
      isPrintCOE: false,
      coeField: {
        name_1: '',
        position_1: '',
        division_1: '',
        name_2: '',
        position_2: '',
        division_2: ''
      },
      isSetOT: false,
      ot: null,
      ot_change: null,
      payroll_setting_id: null,
      hours_per_month: null,
      hours_per_month_change: null,
    }
  },
  mounted() {
    let config = {
      headers: {'Authorization': "Bearer " + this.$store.state.token}
    };

    this.$http.get(`${this.$host}/api/employee/search`,
      config
    ).then((response) => {
      let data = response.data.data
      this.data = data
      this.employees = data
      // let i = 0
      // for (i in this.employees) {
      //   if (!this.employees[i].name_extension && !this.employees[i].middle_name) {
      //     this.suggest.push([this.employees[i].first_name.toUpperCase(),
      //     this.employees[i].last_name.toUpperCase()].join(' '))
      //   }
      //   else if (!this.employees[i].name_extension) {
      //     this.suggest.push([this.employees[i].first_name.toUpperCase(), this.employees[i].middle_name.toUpperCase(),
      //     this.employees[i].last_name.toUpperCase()].join(' '))
      //   }
      //   else if (!this.employees[i].middle_name) {
      //     this.suggest.push([this.employees[i].first_name.toUpperCase(),
      //     this.employees[i].last_name.toUpperCase(), this.employees[i].name_extension.toUpperCase()].join(' '))
      //   }
      //   else {
      //     this.suggest.push([this.employees[i].first_name.toUpperCase(), this.employees[i].middle_name.toUpperCase(),
      //     this.employees[i].last_name.toUpperCase(), this.employees[i].name_extension.toUpperCase()].join(' '))
      //   }
      // }
      // this.searchLoad = false
    }).catch((error) => {
      //console.log(error)
    });

    this.$http.get(`${this.$host}/api/parameter/service_record/employee`,
      config
    ).then((response) => {
      let data = response.data
      this.suggest = data

      let i = 0
      for (i in this.suggest) {
        this.suggest[i].full_name = this.suggest[i].full_name.toUpperCase()
      }
    }).catch((error) => {
      //console.log(error)
    });

    this.getPrintParams()

    window.addEventListener('scroll', this.scrollPrint);
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollPrint);
  },
  computed: {
    filteredSupervisor() {
      return this.suggest.filter((option) => {
        return option.full_name
          .toString()
          .toLowerCase()
          .indexOf(this.supervisor.toLowerCase()) >= 0
      })
    },
    filteredName1() {
      return this.suggest.filter((option) => {
        return option.full_name
          .toString()
          .toLowerCase()
          .indexOf(this.coeField.name_1.toLowerCase()) >= 0
      })
    },
    filteredName2() {
      return this.suggest.filter((option) => {
        return option.full_name
          .toString()
          .toLowerCase()
          .indexOf(this.coeField.name_2.toLowerCase()) >= 0
      })
    }
  },
  methods: {
    saveOT() {
      this.isLoading = true

      let config = {
        headers: {'Authorization': "Bearer " + this.$store.state.token}
      };
      let body = {
        payroll_id: [this.payroll_setting_id],
        ot_allowed: this.ot_change,
      }
      if (this.$editmode.state.isJo == true) {
        body.hours_per_month = this.hours_per_month_change
      }
      this.$http.post(`${this.$host}/api/payroll/setting/assign/`,
        body,
        config
      ).then((response) => {
        let data = response.data
        this.$toast.open({
          message: 'OT Allowed saved.',
          type: 'is-success',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.isLoading = false
        this.closeOT()
      }).catch((error) => {
        this.$toast.open({
          message: 'Error.',
          type: 'is-danger',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.isLoading = false
        //console.log(error)
      });
    },
    getOT() {
      let config = {
        headers: {'Authorization': "Bearer " + this.$store.state.token}
      };
      this.$http.get(`${this.$host}/api/payroll/setting/employee/${this.id}`,
        config
      ).then((response) => {
        let data = response.data
        this.ot = data.ot_allowed
        this.ot_change = JSON.parse(JSON.stringify(data.ot_allowed))
        this.hours_per_month = data.hours_per_month
        this.hours_per_month_change = JSON.parse(JSON.stringify(data.hours_per_month))
        //console.log(data)
      }).catch((error) => {
        //console.log(error)
      });
    },
    closeOT() {
      this.getOT()
      this.isSetOT = false
    },
    setOT() {
      this.isLoading = true

      let config = {
        headers: {'Authorization': "Bearer " + this.$store.state.token}
      };
      this.$http.get(`${this.$host}/api/payroll/setting/employee/${this.id}`,
        config
      ).then((response) => {
        let data = response.data
        if (data.payroll_setting_id) {
          this.payroll_setting_id = data.payroll_setting_id
        }

        this.ot = data.ot_allowed
        this.ot_change = JSON.parse(JSON.stringify(data.ot_allowed))
        this.hours_per_month = data.hours_per_month
        this.hours_per_month_change = JSON.parse(JSON.stringify(data.hours_per_month))
        this.isSetOT = true
        this.isLoading = false
      }).catch((error) => {
        //console.log(error)
        this.isLoading = false
        this.isSetOT = false
      });
    },
    getPrintParams() {
      let config = {
        headers: {'Authorization': "Bearer " + this.$store.state.token}
      };

      this.$http.get(`${this.$host}/api/parameter/service-record/position/`,
        config
      ).then((response) => {
        let data = response.data
        this.position = data[0]
        //console.log(this.printParams)
        // this.searchLoad = false
      }).catch((error) => {
        //console.log(error)
      });
    },
    scrollPrint() {
      if (window.scrollY != 0) {
        this.isScrolled = true
      }
      else {
        this.isScrolled = false
      }
    },
    setEmployee(obj) {
      this.id = obj.id
      this.getOT()
      this.dateHired = obj.date_hired
      let config = {
        headers: {'Authorization': "Bearer " + this.$store.state.token}
      };

      this.$http.get(`${this.$host}/api/employee/search/${this.id}`,
        config
      ).then((response) => {
        let data = response.data.data
        this.selected = data
        if (this.selected.name_extension == null || this.selected.name_extension == 'null') {
          this.fullName = `${this.selected.first_name} ${this.selected.last_name}`
        }
        else {
          this.fullName = `${this.selected.first_name} ${this.selected.last_name} ${this.selected.name_extension}`
        }
        this.isSearch = false
        // this.searchLoad = false
      }).catch((error) => {
        //console.log(error)
        this.isSearch = false
      });
    },
    setService() {
      let config = {
        headers: {'Authorization': "Bearer " + this.$store.state.token}
      };

      this.$http.get(`${this.$host}/api/service-records/search/${this.id}`,
        config
      ).then((response) => {
        let data = response.data.data.servicerecords
        this.record = data

      }).catch((error) => {
        //console.log(error)
      });
    },
    close() {
      if (this.id) {
        this.isSearch = false
      }
    },
    printSR() {
      this.isLoading = true

      let config = {
        headers: {'Authorization': "Bearer " + this.$store.state.token}
      };

      this.$http.get(`${this.$host}/api/report/generate/${this.id}`,
        config
      ).then((response) => {
        this.isLoading = false
        let token = response.data

        let url = `${this.$host}/api/report/sr/${token}?supervisor=${this.supervisor}&position=${this.position}&division=${this.division}`
        let win = window.open(url, '_blank')
        win.focus()
        this.isPrint = false
        }).catch((error) => {
        //console.log(error)
        this.isLoading = false
      });
    },
    printCE() {
      this.isLoading = true

      let config = {
        headers: {'Authorization': "Bearer " + this.$store.state.token}
      };

      this.$http.get(`${this.$host}/api/report/generate/${this.id}`,
        config
      ).then((response) => {
        this.isLoading = false
        let token = response.data

        let url = `${this.$host}/api/report/certification-of-employment/${token}?name_1=${this.coeField.name_1}&position_1=${this.coeField.position_1}&division_1=${this.coeField.division_1}&name_2=${this.coeField.name_2}&position_2=${this.coeField.position_2}&division_2=${this.coeField.division_2}`
        let win = window.open(url, '_blank')
        win.focus()
        this.isPrintCOE = false
      }).catch((error) => {
        //console.log(error)
        this.isLoading = false
      });


    }
  }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.scroll-print {
  position: fixed;
  z-index: 3;
  top: 90%;
  left: 87%;
}
@media screen and (min-width: 1px) and (max-width: 768px)  {
  .scroll-print {
    left: 75%
  }
}
</style>
