<template>
  <form @submit.prevent="runPayroll()">
    <div class="modal-card pos" style="width: 60rem; height: 35.625rem; margin-left: 100px;">
      <header class="modal-card-head">
        <p class="modal-card-title">Run Payroll</p>
      </header>
      <section class="modal-card-body">
        <b>Date Today: </b> {{ dateToday }}
        <hr style="margin-top: 10px; margin-bottom: 10px;">
        <b-field grouped group-multiline style="margin-bottom: 10px;">
          <b-field label="Payroll Group" expanded>
            <b-select v-model="settings.payroll_group" expanded required @input="changeRefDateDays()">
              <option value="1">PERMANENT/PA/TEMPORARY/CO-TERMINUS EMPLOYEES</option>
              <option value="2">CASUALS/CONTRACTUAL</option>
              <option value="3">CONTRACT OF SERVICE</option>
              <option value="4">JOB ORDER</option>
            </b-select>
          </b-field>
          <b-field label="Project" expanded required v-if="$role.state.permissions['run payroll']">
            <b-select v-model="settings.funding_source" expanded required @input="isGetEmployees()">
              <option
                v-for="source in sources"
                :value="source.id"
                :key="source.id">
                {{ source.code }} - {{ source.description }}
              </option>
            </b-select>
          </b-field>
        </b-field>

        <b-field grouped group-multiline>
          <b-field label="Payroll Type" expanded>
            <b-select v-model="settings.payroll_type" @input="changeRefDateDays()" expanded required>
              <option value="salary">SALARY</option>
              <option value="special">OTHER BENEFITS</option>
              <option value="voucher">VOUCHER</option>
            </b-select>
          </b-field>
          <b-field label="Earning" expanded required v-if="settings.payroll_type == 'special'">
            <b-select v-model="settings.earning" expanded required @input="isGetEmployees()">
              <option
                v-for="earning in earnings"
                :value="earning.id"
                :key="earning.id">
                {{ earning.code }}
              </option>
            </b-select>
          </b-field>
          <b-field label="Voucher" expanded required v-if="settings.payroll_type == 'voucher'">
            <b-select v-model="settings.voucher" expanded required @input="isGetEmployees()">
              <option
                v-for="voucher in vouchers"
                :value="voucher.id"
                :key="voucher.id">
                {{ voucher.code }}
              </option>
            </b-select>
          </b-field>
          <b-field label="Salary" expanded required v-if="settings.payroll_type == 'voucher' && settings.voucher == 1">
            <b-select v-model="settings.voucher_salary" expanded required @input="isGetEmployees()">
              <option value="1">1st Salary</option>
              <option value="2">2nd Salary</option>
              <option value="3">3rd Salary</option>
              <option value="4">4th Salary</option>
              <option value="5">5th Salary</option>
            </b-select>
          </b-field>
          <b-field label="Year" v-if="settings.payroll_type != 'voucher'">
            <b-input v-model="dateSelected.year" type="number" min="1800" max="2100" @input="changeRefDateDays()" style="width: 100px;" required>
            </b-input>
          </b-field>
          <b-field label="Month" v-if="settings.payroll_type != 'voucher'">
            <b-select v-model="dateSelected.month" @input="changeRefDateDays()" :disabled="checkYear()" expanded required>
              <option v-for="month in dateConfig.monthsFixed" :key="month" :value="month">
                {{ month }}
              </option>
            </b-select>
          </b-field>
          <b-field label="Day" v-if="settings.payroll_group == '4' && settings.payroll_type=='salary'">
            <b-select v-model="dateSelected.day" expanded required @input="isGetEmployees()">
              <option v-for="day in dateConfig.days" :key="day" :value="day">
                {{ day }}
              </option>
            </b-select>
          </b-field>
        </b-field>

        <b-field grouped group-multiline v-if="((settings.payroll_type == 'special') && settings.earning != 3 && settings.earning != 4)">
          <b-field label="Start Date" expanded>
            <b-field expanded>
              <b-field expanded>
                <b-input v-model="start_date.year" type="number" min="1800" max="2100" placeholder="YEAR" expanded required @input="isGetEmployees()">
                </b-input>
              </b-field>
              <b-field expanded>
                <b-select v-model="start_date.month" placeholder="MONTH" expanded required @input="isGetEmployees()">
                  <option v-for="month in dateConfig.monthsFixed" :key="month" :value="month">
                    {{ month }}
                  </option>
                </b-select>
              </b-field>
            </b-field>
          </b-field>

          <b-field label="End Date" expanded>
            <b-field expanded>
              <b-field expanded>
                <b-input v-model="end_date.year" type="number" min="1800" max="2100" placeholder="YEAR" expanded required @input="isGetEmployees()">
                </b-input>
              </b-field>
              <b-field expanded>
                <b-select v-model="end_date.month" placeholder="MONTH" expanded required @input="isGetEmployees()">
                  <option v-for="month in dateConfig.monthsFixed" :key="month" :value="month">
                    {{ month }}
                  </option>
                </b-select>
              </b-field>
            </b-field>
          </b-field>
        </b-field>

        <b-field grouped group-multiline v-if="settings.payroll_type == 'voucher'">
          <b-field label="Start Date" expanded>
            <b-field expanded>
              <b-field expanded>
                <b-input v-model="start_date.year" type="number" min="1800" max="2100" placeholder="YEAR" expanded required @blur="isGetEmployees()">
                </b-input>
              </b-field>
              <b-field expanded>
                <b-select v-model="start_date.month" placeholder="MONTH" expanded required @blur="isGetEmployees()">
                  <option v-for="month in dateConfig.monthsFixed" :key="month" :value="month">
                    {{ month }}
                  </option>
                </b-select>
              </b-field>
              <b-field expanded>
                <b-input v-model="start_date.day" type="number" min="1" max="32" placeholder="DAY" expanded required @blur="isGetEmployees()">
                </b-input>
              </b-field>
            </b-field>
          </b-field>

          <b-field label="End Date" expanded>
            <b-field expanded>
              <b-field expanded>
                <b-input v-model="end_date.year" type="number" min="1800" max="2100" placeholder="YEAR" expanded required @blur="isGetEmployees()">
                </b-input>
              </b-field>
              <b-field expanded>
                <b-select v-model="end_date.month" placeholder="MONTH" expanded required @blur="isGetEmployees()">
                  <option v-for="month in dateConfig.monthsFixed" :key="month" :value="month">
                    {{ month }}
                  </option>
                </b-select>
              </b-field>
              <b-field expanded>
                <b-input v-model="end_date.day" type="number" min="1" max="32" placeholder="DAY" expanded required @blur="isGetEmployees()">
                </b-input>
              </b-field>
            </b-field>
          </b-field>
        </b-field>

        <b-field label="Amount" v-if="settings.payroll_type == 'voucher' && settings.voucher >= 7">
          <b-input v-model="settings.voucher_amount" type="number" placeholder="AMOUNT" expanded required>
          </b-input>
        </b-field>

        <b-field label="Selected">
          <button class="button is-primary is-small" @click.prevent="selectEmployee()">
            <span><i class="fa fa-user-plus"></i> Select Employee</span>
          </button>
        </b-field>

        <div style="overflow: auto; max-height: 10rem;">
          <b-tag v-for="emp in checkedRows" :key="emp.id" type="is-primary" closable @close="removeSelected(emp)"
          style="margin-right: 5px; margin-top:4px;">
            {{ emp.fullname }}
          </b-tag>
        </div>

      </section>
      <footer class="modal-card-foot">
        <button class="button is-success" type="submit" :disabled="percent != 0">Run Payroll</button>
        <button class="button is-danger" type="button" @click.prevent="close()" :disabled="percent != 0">Close</button>
        <progress class="progress is-primary" :value="this.percent" max="100" v-if="percent != 0" style="max-width: 350px;"></progress>
      </footer>
    </div>

    <b-modal :active.sync="isSelect" has-modal-card :canCancel=false>
      <transition name="fade">
        <form @submit.prevent="select()">
          <div class="modal-card pos" style="width: 60rem; height: 35.625rem; margin-left: 100px;">
            <header class="modal-card-head">
              <p class="modal-card-title">Select Employees</p>
            </header>
            <section class="modal-card-body">
              <b-field label="Filters"></b-field>
              <b-field grouped group-multiline>
                <!-- <b-select v-model="perPage">
                  <option value="10">10 per page</option>
                  <option value="50">50 per page</option>
                  <option value="100">100 per page</option>
                  <option value="1000">1000 per page</option>
                </b-select> -->
                <b-select v-model="selectedStatus" @input="changeStatus()" v-if="settings.payroll_group == 1">
                  <option :value="''">All</option>
                  <option v-for="s in status" :key="s.key" :value="s.key">{{ s.value }}</option>
                </b-select>
                <b-input v-model="search_list" placeholder="Search Employee" expanded/>
              </b-field>
              <div v-if="settings.earning == 4">
                <button class="button is-primary is-small" type="button" @click.prevent="selectAll('checkbox_0')">Select All (14th Pay)</button>
                <button class="button is-primary is-small" type="button" @click.prevent="selectAll('checkbox_1')">Select All (Cash Bonus)</button>
                <button class="button is-danger is-small" type="button" @click.prevent="unselectAll('checkbox_0')">Unselect All (14th Pay)</button>
                <button class="button is-danger is-small" type="button" @click.prevent="unselectAll('checkbox_1')">Unselect All (Cash Bonus)</button>
                <br><br>
              </div>
              <div v-if="settings.earning == 16">
                <button class="button is-primary is-small" type="button" @click.prevent="selectAll('checkbox_0')">Select All (Perpetual)</button>
                <button class="button is-primary is-small" type="button" @click.prevent="selectAll('checkbox_1')">Select All (One-Time)</button>
                <button class="button is-danger is-small" type="button" @click.prevent="unselectAll('checkbox_0')">Unselect All (Perpetual)</button>
                <button class="button is-danger is-small" type="button" @click.prevent="unselectAll('checkbox_1')">Unselect All (One-Time)</button>
                <br><br>
              </div>
              <div v-if="settings.earning == 12">
                <button class="button is-primary is-small" type="button" @click.prevent="selectAll('checkbox_0')">Select All (Subsistence)</button>
                <button class="button is-primary is-small" type="button" @click.prevent="selectAll('checkbox_1')">Select All (Laundry)</button>
                <button class="button is-danger is-small" type="button" @click.prevent="unselectAll('checkbox_0')">Unselect All (Subsistence)</button>
                <button class="button is-danger is-small" type="button" @click.prevent="unselectAll('checkbox_1')">Unselect All (Laundry)</button>
                <br><br>
              </div>
              <div v-if="settings.earning == 33">
                <button class="button is-primary is-small" type="button" @click.prevent="selectAll('checkbox_0')">Select All (Reem Ra)</button>
                <button class="button is-primary is-small" type="button" @click.prevent="selectAll('checkbox_1')">Select All (Reem Ta)</button>
                <button class="button is-danger is-small" type="button" @click.prevent="unselectAll('checkbox_0')">Unselect All (Reem Ra)</button>
                <button class="button is-danger is-small" type="button" @click.prevent="unselectAll('checkbox_1')">Unselect All (Reem Ta)</button>
                <br><br>
              </div>
              <b-table
                :data="filterEmployees"
                :striped=true
                :bordered=true
                :hoverable=true
                :paginated="false"
                :per-page="perPage"
                :is-row-checkable="(row) => !row.for_cancellation && !row.is_suspended"
                :checked-rows.sync="checkedRows"
                :checkable="settings.earning != 4 && settings.earning != 16 && settings.earning != 12 && settings.earning != 33 && settings.payroll_type != 'voucher'"
                class="table-font">
                <template slot-scope="props">
                  <b-table-column label="Voucher" v-if="settings.payroll_type == 'voucher'">
                    <b-checkbox v-model="props.row.checkbox_0" v-if="props.row.is_0 == true" @input="isTrue(props.row)" :disabled="voucherDisabled(props.row)">
                    </b-checkbox>
                  </b-table-column>
                  <b-table-column label="Emp. No." width="100">
                    {{ props.row.employee_number }}
                  </b-table-column>
                  <b-table-column label="Full Name">
                    {{ props.row.fullname }}
                  </b-table-column>
                  <b-table-column label="Position">
                    {{ props.row.position }}
                  </b-table-column>
                  <b-table-column label="Division">
                    {{ props.row.division }}
                  </b-table-column>
                  <b-table-column label="Cancellation" v-if="settings.payroll_type=='salary'">
                    <span v-html="isCancellation(props.row.for_cancellation)"></span>
                    <button class="button btn-action is-primary is-small" @click.prevent="showReason(props.row.for_cancellation)" v-if="props.row.for_cancellation"><i class="fa fa-eye"></i></button>
                  </b-table-column>
                  <b-table-column label="Suspended" v-if="settings.payroll_type=='salary'">
                    <span v-html="isSuspended(props.row.is_suspended)"></span>
                  </b-table-column>
                  <b-table-column label="Salary Deduction" v-if="settings.payroll_type=='salary'">
                    <span v-html="hasSalaryDeduction(props.row.hasSalaryDeduction)"></span>
                  </b-table-column>
                  <b-table-column label="Perpetual" v-if="settings.earning == 16">
                    <b-checkbox v-model="props.row.checkbox_0" v-if="props.row.is_0 == true" @input="isTrue(props.row)">
                    </b-checkbox>
                  </b-table-column>
                  <b-table-column label="One-Time" v-if="settings.earning == 16">
                    <b-checkbox v-model="props.row.checkbox_1" v-if="props.row.is_1 == true" @input="isTrue(props.row)">
                    </b-checkbox>
                  </b-table-column>
                  <b-table-column label="14th Pay" v-if="settings.earning == 4">
                    <b-checkbox v-model="props.row.checkbox_0" v-if="props.row.is_0 == true" @input="isTrue(props.row)">
                    </b-checkbox>
                  </b-table-column>
                  <b-table-column label="Cash Bonus" v-if="settings.earning == 4">
                    <b-checkbox v-model="props.row.checkbox_1" @input="isTrue(props.row)" v-if="props.row.is_1 == true">
                    </b-checkbox>
                  </b-table-column>
                  <b-table-column label="Subsistence" v-if="settings.earning == 12">
                    <b-checkbox v-model="props.row.checkbox_0" v-if="props.row.is_0 == true" @input="isTrue(props.row)">
                    </b-checkbox>
                  </b-table-column>
                  <b-table-column label="Laundry" v-if="settings.earning == 12">
                    <b-checkbox v-model="props.row.checkbox_1" v-if="props.row.is_1 == true" @input="isTrue(props.row)">
                    </b-checkbox>
                  </b-table-column>
                  <b-table-column label="Reem Ra" v-if="settings.earning == 33">
                    <b-checkbox v-model="props.row.checkbox_0" v-if="props.row.is_0 == true" @input="isTrue(props.row)">
                    </b-checkbox>
                  </b-table-column>
                  <b-table-column label="Reem Ta" v-if="settings.earning == 33">
                    <b-checkbox v-model="props.row.checkbox_1" v-if="props.row.is_1 == true" @input="isTrue(props.row)">
                    </b-checkbox>
                  </b-table-column>
                </template>
                <template slot="empty">

                </template>
              </b-table>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" type="submit">Select</button>
              <!-- <button class="button is-danger" type="button" @click.prevent="closeSelect()">Cancel</button> -->
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>
    <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
  </form>
</template>

<script>
import moment from 'moment'

export default {
  data() {
    return {
      config: '',
      isLoading: false,
      settings: {
        payroll_group: null,
        funding_source: this.$role.state.funding_source_id,
        payroll_type: null,
        earning: null,
        voucher: null,
        voucher_salary: null,
        voucher_amount: null,
      },
      dateToday: null,
      earnings: [],
      vouchers: [],
      unselectable: [],
      dateSelected: {
        month: null,
        day: null,
        year: null
      },
      dateConfig: {
        months: [],
        days: [],
        monthsFixed: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
      },
      sources: [],
      isSelect: false,
      checkedRows: [],
      employees: [],
      perPage: 10,
      search_list: '',
      percent: 0,
      status: [],
      selectedStatus: '',
      prevRows: [],
      prevStatus: null,
      start_date: {
        month: null,
        year: new Date().getFullYear(),
      },
      end_date: {
        month: null,
        year: new Date().getFullYear(),
      }
    }
  },
  mounted() {
    this.config = {
      headers: {'Authorization': "Bearer " + this.$store.state.token}
    };

    setTimeout(() => this.getEarnings(), 100)
  },
  computed: {
    filterEmployees() {
      var i;
      var name_re = new RegExp(this.search_list, 'i')
      var data = []
      for (i in this.employees) {
        if ([this.employees[i].employee_number, this.employees[i].fullname, this.employees[i].position, this.employees[i].division].join(' ').match(name_re)) {
          data.push(this.employees[i])
        }
      }
      return data
    },
    excludedEmployees() {
      return this.employees.filter(x=> !this.checkedRows.includes(x))
    }
  },
  methods: {
    selectAll(key) {
      this.employees.forEach((item, index) => {
        if(key == 'checkbox_0'){
          if(item.is_0 == true){
            if(this.settings.earning == 4){
              item['is_1'] = true
            }
            item[key] = true
            if(this.checkedRows.includes(this.employees[index])){
              item[key] = true
            }else{
              item[key] = true
              this.checkedRows.push(this.employees[index])
            }
          }
        }
        if(key == 'checkbox_1'){
          if(this.settings.earning == 4){
            if(item.is_1 == true){
                item.is_1 = false
                item.is_1 = true
                item.key = true
              if(this.checkedRows.includes(this.employees[index])){
                item[key] = true
              }else{
                item[key] = true
                this.checkedRows.push(this.employees[index])
              }
            }
          }else{
            if(item.is_1 == true){
              item.is_1 = false
              item.is_1 = true
              item.key = true
              item[key] = true
              if(this.checkedRows.includes(this.employees[index])){
                item.key = true
                item[key] = true
              }else{
                item[key] = true
                item.key = true
                this.checkedRows.push(this.employees[index])
              }
            }
          }
        }
      })
    },

    unselectAll(key) {
      let key_2
      if (key == 'checkbox_1') {
        key_2 = 'checkbox_0'
      }
      else {
        key_2 = 'checkbox_1'
      }
      this.employees.forEach((item, index) => {
        if(this.settings.earning == 4){
          if(key == 'checkbox_0'){
            item['checkbox_0'] = false
            item['checkbox_1'] = false
            this.checkedRows = []
            item['is_1'] = false
          }else{
            item['is_1'] = false
            item['is_1'] = true
            item['checkbox_1'] = false
          }
        }else{
          item[key] = false
          if (item[key_2] == false || item[key_2] == undefined) {
            let name = item['fullname']
            this.checkedRows.forEach((val, key) => {
              if(val.fullname == name){
                this.checkedRows.splice(key, 1)
              }
            })
            item[key_2] = false
          }else{
            let name = item['fullname']
            this.checkedRows.forEach((val, key) => {
              if(val.fullname == name){
                if(this.checkedRows.includes(this.employees[key])){
                }else{
                  this.checkedRows.push(this.employees[key])
                }
              }
            })
          }
        }
      })
    },
    isTrue(obj) {
      // //console.log(obj)
      if(this.settings.earning == 3 || this.settings.earning == 4){
        if(obj.checkbox_0 == false){
          if(obj.checkbox_1 == true){
            obj.checkbox_1 = false
            obj.is_1 = false
          }
        }else{
          obj.is_1 = true
          //console.log(obj)
        }
      }
      if (obj.checkbox_0 == true || obj.checkbox_1 == true) {
        if(this.checkedRows.includes(obj)){

        }else{
          this.checkedRows.push(obj)
          if(this.settings.payroll_type == 'voucher'){
            this.voucherDisabled(obj)
          }
        }
      }
      else if (obj.checkbox_0 == false || obj.checkbox_1 == false) {
        // let index = this.checkedRows.indexOf(obj)
        // if (index > -1) {
        //   this.checkedRows.splice(index, 1)
        // }
        if(this.settings.payroll_type == 'voucher'){
          this.checkedRows = this.checkedRows.filter(item => item !== obj)
          this.voucherDisabled(obj)
        }else{
          this.checkedRows = this.checkedRows.filter(item => item !== obj)
        }
      }
    },
    isDisabled(key) {
      if(key.checkbox_0 == true){
        return false
      }else{
        return true
      }
    },
    voucherDisabled(row){
      // //console.log(this.checkedRows.length)
      if(this.checkedRows.length > 0){
        // //console.log('checked')
        // return true
        // //console.log(this.employees)
        this.employees.forEach((item, index) => {
          if (typeof item['checkbox_0'] !== 'undefined') {
            if(item['checkbox_0'] == true){
              item['is_0'] = true
            }else{
              item['is_0'] = false
            }
          }else{
            item['is_0'] = false
          }
          // if(item['checkbox_0'] == true){
          //   return true
          // }else{
          //   if(item['checkbox_0'] == false){
          //     item['is_0'] = true
          //     return false
          //   }else{
          //     item['is_0'] = false
          //     item['checkbox_0'] == false
          //     return true
          //   }
          // }
        })
      }else{
        this.employees.forEach((item, index) => {
          item['is_0'] = true
        })
        return false
      }
    },
    showReason(reason) {
      this.$dialog.alert({
        title: 'Reason',
        message: reason,
        type: 'is-primary',
        hasIcon: false,
        iconPack: 'fa'
      })
    },
    hasSalaryDeduction(status) {
      if (status == true) {
        return "<span style='color: green'>YES</span>"
      }
      else {
        return "<span>NO</span>"
      }
    },
    isSuspended(status) {
      if (status == true) {
        return "<span style='color: green'>YES</span>"
      }
      else {
        return "<span>NO</span>"
      }
    },
    isCancellation(status) {
      if (!status) {
        return "<span>NO</span>"
      }
      else {
        return "<span style='color: green'>YES</span>"
      }
    },
    changeStatus() {
      if (this.settings.payroll_group != 1) {
        this.selectedStatus = ''
      }
      this.getEmployees()
    },
    runPayroll() {
      this.$dialog.confirm({
        title: 'Run Payroll',
        message: "Are you sure you want to <b>run</b> this payroll?",
        confirmText: 'Run',
        type: 'is-success',
        hasIcon: false,
        onConfirm: () => {
          this.isLoading = true
          this.percent = 1

          // if (this.settings.earning == 4 || this.settings.earning == 16) {
          //   this.checkedRows = []
          //   this.checkedRows = this.employees.filter(x => x.is_0 === true || x.is_1 === true)
          // }

          let lastDay = moment(new Date(this.end_date.year, parseInt(this.end_date.month), 0)).format("DD")

          let config = {
            headers: {'Authorization': "Bearer " + this.$store.state.token},
            onUploadProgress: progressEvent => {
              let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
              this.percent = percentCompleted
            }
          }
          //console.log(this.settings);
          // var data = this.employees.filter(employee => !this.checkedRows.includes(employee));
          // //console.log(data);
          if(this.settings.payroll_type == 'voucher'){
            this.$http.post(`${this.$host}/api/payroll/run_voucher/`,
              {
                employee_id: this.checkedRows.map(x => x = {
                  id: x.id,
                  is_0: x.checkbox_0
                }),
                payroll_type: this.settings.payroll_type,
                ref_date: `${this.dateSelected.year}-${this.dateSelected.month}-${this.dateSelected.day}`,
                payroll_group: this.settings.payroll_group,
                vouchers: this.settings.voucher,
                voucher_salary: this.settings.voucher_salary,
                start_date: `${this.start_date.year}-${this.start_date.month}-${this.start_date.day}`,
                end_date: `${this.end_date.year}-${this.end_date.month}-${this.end_date.day}`,
                funding_source: this.settings.funding_source,
                voucher_amount: this.settings.voucher_amount
              },
              config,
            ).then((response) => {
              this.$dialog.alert({
                title: 'Success',
                message: "Payroll run complete",
                type: 'is-success',
                hasIcon: true,
                icon: 'check',
                iconPack: 'fa'
              })
              this.isLoading = false
              this.percent = 0
            }).catch((error) => {
              this.isLoading = false
              this.percent = 0
              this.$toast.open({
                message: 'Error running payroll',
                type: 'is-danger',
                position: 'is-bottom-right',
                duration: 4000
              })
            });
          }else{
            this.$http.post(`${this.$host}/api/payroll/run/`,
              {
                employee_id: this.checkedRows.map(x => x = {
                  id: x.id,
                  is_0: x.checkbox_0,
                  is_1: x.checkbox_1
                }),
                excluded_employee_id: this.excludedEmployees.map(x => x = {
                  id: x.id
                }),
                payroll_type: this.settings.payroll_type,
                ref_date: `${this.dateSelected.year}-${this.dateSelected.month}-${this.dateSelected.day}`,
                payroll_group: this.settings.payroll_group,
                earnings: this.settings.earning,
                start_date: (this.settings.payroll_type == 'special' && this.settings.earning != 3 && this.settings.earning != 4) ? `${this.start_date.year}-${this.start_date.month}-01` : null,
                end_date: (this.settings.payroll_type == 'special' && this.settings.earning != 3 && this.settings.earning != 4) ? `${this.end_date.year}-${this.end_date.month}-${lastDay}` : null,
                funding_source: this.settings.funding_source
              },
              config,
            ).then((response) => {
              this.$dialog.alert({
                title: 'Success',
                message: "Payroll run complete",
                type: 'is-success',
                hasIcon: true,
                icon: 'check',
                iconPack: 'fa'
              })
              this.isLoading = false
              this.percent = 0
            }).catch((error) => {
              this.isLoading = false
              this.percent = 0
              this.$toast.open({
                message: 'Error running payroll',
                type: 'is-danger',
                position: 'is-bottom-right',
                duration: 4000
              })
            });
          }
        }
      })
    },
    changeRefDateDays() {
      this.employees = []
      if(this.settings.payroll_type == 'special'){
        let all = {
          id: 0,
          code: "All",
          description: "All"
        };
        var result;
        for( let i = 0, len = this.sources.length; i < len; i++ ) {
            if( this.sources[i]['id'] === 0 ) {
                result = true;
                break;
            }
        }
        if(result == true){
        }else{
          this.sources.splice(0,0,all)
        }
      }
      if (this.settings.payroll_group == '1' || this.settings.payroll_group == '2' || this.settings.payroll_group == '3' || this.settings.payroll_type=='special') {
        if (this.settings.payroll_type == 'salary') {
          if (this.settings.payroll_group != '3' && this.dateSelected.year) {
            this.monthAdvance()
          }
          else if (this.settings.payroll_group == '3' && this.dateSelected.year) {
            this.monthBefore()
          }
        }
        this.dateConfig.days = ['01']
        this.dateSelected.day = '01'
      }
      else if (this.dateSelected.year) {
        if (this.dateSelected.year.length == '4') {
          let lastDay = moment(new Date(this.dateSelected.year, parseInt(this.dateSelected.month), 0)).format("DD")
          if (this.settings.payroll_group == '4' && this.dateSelected.year) {
            let currentDay = this.dateToday.substr(8, 2)
            let currentMonth = this.dateToday.substr(5, 2)
            let currentYear = this.dateToday.substr(0, 4)
            this.monthBefore()
            if (currentMonth == 12) {
              if (this.dateSelected.year == parseInt(currentYear) + 1) {
                this.dateConfig.months = [1]
              }
            }
            if (this.dateSelected.year == currentYear && currentDay > 15 && currentDay <= lastDay && currentMonth != 12) {
              if (parseInt(currentMonth) < 10) {
                this.dateConfig.months.push('0' + parseInt(currentMonth))
              }
              else {
                this.dateConfig.months.push(parseInt(currentMonth))
              }
            }
            if (currentDay > 15 && currentDay <= lastDay && this.dateSelected.month == currentMonth) {
              this.dateConfig.days = [15]
            }
            else if (this.dateSelected.month) {
              this.dateConfig.days = [15, lastDay]
            }
            else {
              this.dateConfig.days = []
            }
          }
          else if (this.dateSelected.year && this.dateSelected.month) {
            let i = 1
            while (i <= lastDay) {
              this.dateConfig.days.push(i)
              i++
            }
          }
        }
      }
      else {
        this.dateConfig.days = []
        this.dateSelected.day = null
      }
      this.clearSource()
    },
    monthBefore() {
      this.dateConfig.months = []
      let currentYear = this.dateToday.substr(0, 4)
      let currentMonth = this.dateToday.substr(5, 2)
      if (this.dateSelected.year == currentYear) {
        for (let i = 1; i < parseInt(currentMonth); i++) {
          if (i < 10) {
            this.dateConfig.months.push('0' + i)
          }
          else {
            this.dateConfig.months.push(i)
          }
        }
      }
      else if (this.dateSelected.year < currentYear) {
        this.dateConfig.months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
      }
      else {
        this.dateConfig.months = []
      }
    },
    monthAdvance() {
      this.dateConfig.months = []
      let currentYear = this.dateToday.substr(0, 4)
      let currentMonth = this.dateToday.substr(5, 2)
      if (this.dateSelected.year == currentYear && parseInt(currentMonth)) {
        for (let i = 1; i < parseInt(currentMonth) + 2; i++) {
          if (i < 13) {
            if (i < 10) {
              this.dateConfig.months.push('0' + i)
            }
            else {
              this.dateConfig.months.push(i)
            }
          }
        }
      }
      else if (this.dateSelected.year < currentYear) {
        this.dateConfig.months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
      }
      else if (currentMonth == '12') {
        if (this.dateSelected.year == parseInt(currentYear) + 1) {
          this.dateConfig.months = ['01']
        }
      }
      else {
        this.dateConfig.months = []
      }
    },
    parseDate(d) {
      let parts = d.split("/")
      let dt = new Date(parseInt(parts[2], 10),
        parseInt(parts[1], 10) - 1,
        parseInt(parts[0], 10))
      return dt
    },
    checkYear() {
      if (this.dateSelected.year) {
        if (this.dateSelected.year.length != '4') {
          this.dateSelected.month = null
          return true
        }
        else if (this.dateSelected.year.length == '4' ){
          return false
        }
        else {
          return true
        }
      }
      else {
        return true
      }
    },
    clearSource() {
      // if (this.settings.payroll_group == '1') {
      //   this.settings.funding_source = null
      // }
      this.clearEarning()
      // this.isGetEmployees()
    },
    clearEarning() {
      if (this.settings.payroll_type != 'special') {
        this.settings.earning = null
      }
      if (this.settings.payroll_type != 'voucher') {
        this.settings.voucher = null
      }
      this.isGetEmployees()
    },
    getEarnings() {
      this.isLoading = true

      this.$http.get(`${this.$host}/api/parameter/payroll/run`,
        this.config
      ).then((response) => {
        let data = response.data
        this.earnings = data.earnings
        this.vouchers = data.vouchers
        this.status = data.status
        this.dateToday = data.date
        this.dateSelected.year = this.dateToday.substr(0, 4)
        this.getFundingSources()
        this.isSave = true
      }).catch((error) => {
        this.isLoading = false
        //console.log(error)
      });
    },
    getFundingSources() {
      this.isLoading = true

      this.$http.get(`${this.$host}/api/payroll/funding-source/show/`,
        this.config
      ).then((response) => {
        let data = response.data
        this.sources = data
        this.isLoading = false
      }).catch((error) => {
        this.isLoading = false
        //console.log(error)
      });
    },
    removeSelected(emp) {
      this.checkedRows = this.checkedRows.filter(x => x !== emp)
      emp.checkbox_0 = false
      emp.checkbox_1 = false
    },
    selectEmployee() {
      // this.prevRows = JSON.parse(JSON.stringify(this.checkedRows))
      // this.prevStatus = JSON.parse(JSON.stringify(this.selectedStatus))
      this.isSelect = true
    },
    select() {
      // //console.log(this.employees);
      // if (this.settings.earning == 4 || this.settings.earning == 16) {
      //   this.checkedRows = this.employees.filter(x => x.is_0 === true || x.is_1 === true)
      // }
      // //console.log(this.checkedRows)
      this.isSelect = false
    },
    closeSelect() {
      // this.selectedStatus = JSON.parse(JSON.stringify(this.prevStatus))
      // this.checkedRows = this.prevRows
      this.isSelect = false
    },
    isGetEmployees() {
      if (this.settings.payroll_group == 4 && this.dateSelected.day == 1 && this.settings.payroll_type=='salary') {
        this.dateSelected.day = null
      }
      if(this.settings.payroll_type == 'voucher'){
        this.dateSelected.year = this.dateToday.substr(0, 4)
        this.dateSelected.month = this.dateToday.substr(5, 2)
        this.dateSelected.day = this.dateToday.substr(8, 2)
      }
      if (this.dateSelected.year && this.dateSelected.month && this.dateSelected.day && this.settings.payroll_type && this.settings.payroll_group) {
        // alert('1')
        if (this.dateSelected.year.length == 4) {
          // alert('2')
          if ((this.settings.payroll_type == 'special' && this.settings.earning) || this.settings.payroll_type == 'salary' || (this.settings.payroll_type == 'voucher' && this.settings.voucher)) {
            // alert('3')
            if (((this.settings.payroll_group == '3' || this.settings.payroll_group == '4') && this.settings.funding_source !== null) || (this.settings.payroll_group == '1' || this.settings.payroll_group == '2')) {
              if (this.settings.earning == '2') {
                if (this.start_date.year && this.start_date.month && this.end_date.year && this.end_date.month) {
                  this.getEmployees()
                }
              }
              else {
                if(this.settings.payroll_type == 'voucher'){
                  if (this.start_date.year && this.start_date.month && this.start_date.day && this.end_date.year && this.end_date.month && this.end_date.day) {
                    if(this.start_date.day.length == 1){
                      this.start_date.day = '0' + this.start_date.day
                    }else if(this.start_date.day.length == 3){
                      this.start_date.day.replace('0','')
                    }
                    if(this.end_date.day.length == 1){
                      this.end_date.day = '0' + this.end_date.day
                    }else if(this.end_date.day.length == 3){
                      this.end_date.day.replace('0','')
                    }
                    //console.log(this.start_date.day)
                    this.getEmployees()
                  }
                }else{
                  this.getEmployees()
                }
              }
            }
          }
        }
      }
    },
    getEmployees() {
      this.isLoading = true

      let ref_date = `${this.dateSelected.year}-${this.dateSelected.month}-${this.dateSelected.day}`
      //console.log(this.dateSelected);
      let lastDay = moment(new Date(this.end_date.year, parseInt(this.end_date.month), 0)).format("DD")
      let start_date =  `${this.start_date.year}-${this.start_date.month}-01`
      let end_date = `${this.end_date.year}-${this.end_date.month}-${lastDay}`
      let url
      //if (this.settings.earning == 2) {
      if(this.settings.payroll_type == 'special'){
        if(this.settings.earning == 3 || this.settings.earning == 4){
          url = `${this.$host}/api/payroll/setting/list/?payroll_type=${this.settings.payroll_type}&ref_date=${ref_date}&earnings=${this.settings.earning}&payroll_group=${this.settings.payroll_group}&funding_source=${this.settings.funding_source}&status=${this.selectedStatus}`
        }else{
          url = `${this.$host}/api/payroll/setting/list/?payroll_type=${this.settings.payroll_type}&ref_date=${ref_date}&earnings=${this.settings.earning}&payroll_group=${this.settings.payroll_group}&funding_source=${this.settings.funding_source}&status=${this.selectedStatus}&start_date=${start_date}&end_date=${end_date}`
        }
      }
      else if(this.settings.payroll_type == 'voucher'){
        //console.log(1);
        // url = `${this.$host}/api/payroll/setting/list/?payroll_type=${this.settings.payroll_type}&ref_date=${ref_date}&vouchers=${this.settings.voucher}$voucher_salary=${this.settings.voucher_salary}&payroll_group=${this.settings.payroll_group}&status=${this.selectedStatus}&start_date=${start_date}&end_date=${end_date}`
        url = `${this.$host}/api/payroll/setting/list/?payroll_type=${this.settings.payroll_type}&ref_date=${ref_date}&vouchers=${this.settings.voucher}&payroll_group=${this.settings.payroll_group}&funding_source=${this.settings.funding_source}`
      }
      else {
        //console.log(0);
        url = `${this.$host}/api/payroll/setting/list/?payroll_type=${this.settings.payroll_type}&ref_date=${ref_date}&earnings=${this.settings.earning}&payroll_group=${this.settings.payroll_group}&funding_source=${this.settings.funding_source}&status=${this.selectedStatus}`
        // url = `${this.$host}/api/payroll/setting/list/?payroll_type=${this.settings.payroll_type}&ref_date=${ref_date}&earnings=${this.settings.earning}&payroll_group=${this.settings.payroll_group}&funding_source=${this.settings.funding_source}&status=${this.selectedStatus}`

      }

      this.$http.get(url,
        this.config
      ).then((response) => {
        let data = response.data
        this.checkedRows = []
        this.employees = data
        // if (this.settings.earning == 4 || this.settings.earning == 16) {
        //   this.employees = this.employees.map(x => ({...x, checkbox_0: false, checkbox_1: false}))
        // }
        this.isLoading = false
      }).catch((error) => {
        this.isLoading = false
        //console.log(error)
      });
    },
    close() {
      this.$parent.close()
      this.$emit('refresh')
    }
  }
}
</script>

<style scoped>
.progress {
  height: 8px !important;
}

.progress::-webkit-progress-value {
  transition: width 0.5s ease;
}

@media screen and (min-width: 769px) {
  .table-font {
    font-size: 14px !important;
  }
}
</style>
