<template>
  <div>
    <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
    <div class="box">
      <div>
        <b-field>
          <!-- <b-field label="Flexi">
          </b-field> -->
          <button class="button is-primary" @click.prevent="isEmployerSetting = true">
            <i class="fa fa-pen"></i> &nbsp;Edit Employer Setting
          </button>
        </b-field>
      </div>
      <hr>
      <b>PHILHEALTH Number: </b> {{ employerSettingValue.philhealth_number }} <br />
      <b>MID Number:</b> {{ employerSettingValue.mid_number }}<br>
      <b>Employer TIN:</b> {{ employerSettingValue.employer_tin }}<br>
      <b>GSIS Office Code:</b> {{ employerSettingValue.employer_gsis }}<br>
      <b>Employer/Business Name:</b> {{ employerSettingValue.employer_name }}<br>
      <b>Employer Telephone Number:</b> {{ employerSettingValue.telephone_number }}<br>
      <b>Employer Email Address:</b> {{ employerSettingValue.email_address }}<br><br>
      <b>MORS and DV Settings</b><br>
      <b>Employer Payee/Claimant:</b> {{ employerSettingValue.mors_dv_name }}<br>
      <b>Employer Office:</b> {{ employerSettingValue.mors_office }}<br><br>
      <b>Employer/Business Address</b><br>
      <b>Unit: </b> {{ employerSettingValue.unit }}<br>
      <b>Building Name: </b> {{ employerSettingValue.building_name }}<br>
      <b>Lot No., Block No., Phase No. House No: </b> {{ employerSettingValue.number }}<br>
      <b>Street Name: </b> {{ employerSettingValue.street_name }}<br>
      <b>Subdivision: </b> {{ employerSettingValue.subdivision }}<br>
      <b>Barangay: </b> {{ employerSettingValue.barangay }}<br>
      <b>Municipality/City: </b> {{ employerSettingValue.city }}<br>
      <b>Province/State/Country (if abroad): </b> {{ employerSettingValue.province }}<br>
      <b>Zip Code: </b> {{ employerSettingValue.zip_code }}<br>
    </div>

    <b-modal :active.sync="isEmployerSetting" has-modal-card :canCancel=false>
      <transition name="fade">
        <form @submit.prevent="save()">
          <div class="modal-card" style="height: 35.625rem; margin-top: 4rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">Employer Setting</p>
            </header>
            <section class="modal-card-body">
              <b-field label="PHILHEALTH Number">
                <b-input v-model="employerSettingValue.philhealth_number" expanded></b-input>
              </b-field>
              <b-field label="MID Number">
                <b-input v-model="employerSettingValue.mid_number" expanded></b-input>
              </b-field>
              <b-field label="Employer TIN">
                <b-input v-model="employerSettingValue.employer_tin" expanded></b-input>
              </b-field>
              <b-field label="Employer/Business Name">
                <b-input v-model="employerSettingValue.employer_name" expanded></b-input>
              </b-field>
              <b-field label="Employer Telephone Number">
                <b-input v-model="employerSettingValue.telephone_number" expanded></b-input>
              </b-field>
              <b-field label="Employer Telephone Number">
                <b-input v-model="employerSettingValue.email_address" expanded></b-input>
              </b-field><br>
              <b>MORS and DV Settings</b>
              <b-field label="Employer Payee/Claimant">
                <b-input v-model="employerSettingValue.mors_dv_name" expanded></b-input>
              </b-field>
              <b-field label="Employer Office">
                <b-input v-model="employerSettingValue.mors_office" expanded></b-input>
              </b-field><br>
              <b>Employer/Business Address</b>
              <b-field label="Unit">
                <b-input v-model="employerSettingValue.unit" expanded></b-input>
              </b-field>
              <b-field label="Building Name">
                <b-input v-model="employerSettingValue.building_name" expanded></b-input>
              </b-field>
              <b-field label="Lot No., Block No., Phase No. House No">
                <b-input v-model="employerSettingValue.number" expanded></b-input>
              </b-field>
              <b-field label="Street Name">
                <b-input v-model="employerSettingValue.street_name" expanded></b-input>
              </b-field>
              <b-field label="Subdivision">
                <b-input v-model="employerSettingValue.subdivision" expanded></b-input>
              </b-field>
              <b-field label="Barangay">
                <b-input v-model="employerSettingValue.barangay" expanded></b-input>
              </b-field>
              <b-field label="Municipality/City">
                <b-input v-model="employerSettingValue.city" expanded></b-input>
              </b-field>
              <b-field label="Province/State/Country (if abroad)">
                <b-input v-model="employerSettingValue.province" expanded></b-input>
              </b-field>
              <b-field label="Zip Code">
                <b-input v-model="employerSettingValue.zip_code" expanded></b-input>
              </b-field>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" @click.prevent="saveEmployerSetting">Save</button>
              <button class="button is-danger" @click.prevent="cancelEmployerSetting">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: true,
      config: {},
      employerSettingValue: {},
      isEmployerSetting: false
    }
  },
  mounted() {
    this.config = {
      headers: {'Authorization': "Bearer " + this.$store.state.token}
    };
    this.getSettings()
  },
  methods: {
    getSettings() {
      this.$http.get(`${this.$host}/api/setting/show`,
        this.config
      ).then((response) => {
        let data = response.data
        this.employerSettingValue = JSON.parse(data[13].hash_value)
        this.isLoading = false
      }).catch((error) => {
        this.isLoading = false
        //console.log(error)
      });
    },
    saveEmployerSetting() {
        this.isLoading = true
        this.$http.put(`${this.$host}/api/setting/update/14`,
        { hash_value: JSON.stringify(this.employerSettingValue) },
        this.config
        ).then((response) => {
            this.isEmployerSetting = false
            this.getSettings()
        }).catch((error) => {
            this.isLoading = false
        });
    },

    cancelEmployerSetting() {
      this.isLoading = true
      this.getSettings()
      this.isEmployerSetting = false
    }
  }
}
</script>

<style>

</style>
