<template>
  <div>
    <div class="columns header" style="margin-bottom: 0 !important;">
      <div class="column is-5" style="margin-left: 5px; font-size: 20px; text-transform: uppercase;">
        <b>TRAININGS</b> {{ fullName }}
      </div>
      <div class="column is-auto">
        <button class="button is-primary is-hidden-tablet" style="margin-left: 10px;" @click.prevent="$router.push('/trainings')">
          <i class="fa fa-book"></i> &nbsp;My Trainings
        </button>
        <button class="button is-primary is-hidden-tablet" style="margin-left: 10px;" @click.prevent="$router.push('/trainings/manage')"
        v-if="$role.state.permissions['search training']">
          <i class="fa fa-bookmark"></i> &nbsp;Manage Trainings
        </button>
        <button class="button is-primary is-hidden-tablet" style="margin-left: 10px;" @click.prevent="isSearch = true"
        v-if="$role.state.permissions['search training']">
          <i class="fa fa-search"></i> &nbsp;Search Employee
        </button>

        <button class="button is-primary is-pulled-right is-hidden-mobile" style="margin-left: 10px;" @click.prevent="isSearch = true"
        v-if="$role.state.permissions['search training']">
          <i class="fa fa-search"></i> &nbsp;Search Employee
        </button>
        <button class="button is-primary is-pulled-right is-hidden-mobile" style="margin-left: 10px;" @click.prevent="$router.push('/trainings/manage')"
        v-if="$role.state.permissions['search training']">
          <i class="fa fa-bookmark"></i> &nbsp;Manage Trainings
        </button>
        <button class="button is-primary is-pulled-right is-hidden-mobile" style="margin-left: 10px;" @click.prevent="$router.push('/trainings')">
          <i class="fa fa-book"></i> &nbsp;My Trainings
        </button>

      </div>
    </div>
    <hr style="margin-top: 0 !important; margin-bottom: 0 !important;" class="has-background-grey-lighter is-hidden-mobile">
    <br>
    <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
    <div class="box">
      <b-field label="Trainings"></b-field>
      <b-field grouped group-multiline>
        <!-- <button class="button is-primary control" @click.prevent="addTraining()">
          <i class="fa fa-plus"></i> &nbsp;Training
        </button> -->
        <b-select placeholder="Per Page" v-model="perPage">
          <option value="5">5 per page</option>
          <option value="10">10 per page</option>
          <option value="20">20 per page</option>
          <option value="31">31 per page</option>
        </b-select>
        <b-input v-model="search_query" placeholder="Search" />
      </b-field>
      <hr style="margin-top: 0; margin-bottom: 10px;">
      <b-table
        :data="filter"
        :bordered=false
        :hoverable=true
        :paginated="true"
        :per-page="perPage"
        detailed
        detail-key="id">
        <template slot-scope="props">
          <b-table-column label="Program">
            {{ props.row.program }}
          </b-table-column>
          <b-table-column label="Date From" field="date_from" width="120" sortable>
            {{ props.row.date_from }}
          </b-table-column>
          <b-table-column label="Date To" field="date_to" width="120" sortable>
            {{ props.row.date_to }}
          </b-table-column>
          <b-table-column label="Number of Hours">
            {{ props.row.number_of_hours }}
          </b-table-column>
          <b-table-column label="Type of LD">
            {{ props.row.type_of_ld }}
          </b-table-column>
          <b-table-column label="Sponsored By">
            {{ props.row.sponsored_by }}
          </b-table-column>
          <b-table-column label="Conducted By">
            {{ props.row.conducted_by }}
          </b-table-column>
          <b-table-column label="Location">
            {{ props.row.location }}
          </b-table-column>
        </template>
        <template slot="detail" slot-scope="props">
          <article class="media">
            <div class="media-content">
              <div class="content">
                <p>
                  <span v-if="!props.row.certificate_of_appearance && !props.row.post_training_report && props.row.need_proof == 1">NO PROOFS PROVIDED BY THE EMPLOYEE</span>
                  <b></b> <button v-if="props.row.certificate_of_appearance" class="button is-primary is-small" @click.prevent="openProofCOA(props.row.certificate_of_appearance)"><span>CERTIFICATE OF APPEARANCE</span></button>
                  <b></b> <button v-if="props.row.post_training_report" class="button is-primary is-small" @click.prevent="openProofPTR(props.row.post_training_report)"><span>POST TRAINING REPORT</span></button>
                </p>
              </div>
            </div>
          </article>
        </template>
        <template slot="empty">
          <b-table-column label="Program">
          </b-table-column>
          <b-table-column label="Date From">
          </b-table-column>
          <b-table-column label="Date To">
          </b-table-column>
          <b-table-column label="Number of Hours">
          </b-table-column>
          <b-table-column label="Type of LD">
          </b-table-column>
          <b-table-column label="Sponsored By">
          </b-table-column>
          <b-table-column label="Conducted By">
          </b-table-column>
          <b-table-column label="Location">
          </b-table-column>
        </template>
      </b-table>
    </div>
    <!-- <div class="box">
      <b-field label="Appraisals"></b-field>
      <b-field grouped group-multiline>
        <button class="button is-primary control" @click.prevent="addTraining()">
          <i class="fa fa-plus"></i> &nbsp;Appraisals
        </button>
        <b-select placeholder="Per Page" v-model="perPage">
          <option value="5">5 per page</option>
          <option value="10">10 per page</option>
          <option value="20">20 per page</option>
          <option value="31">31 per page</option>
        </b-select>
      </b-field>
      <hr style="margin-top: 0; margin-bottom: 10px;">
      <b-table
        :data="trainings"
        :bordered=false
        :hoverable=true
        :paginated="true"
        :per-page="perPage">
        <template slot-scope="props">
          <b-table-column label="Program">
            {{ props.row.program }}
          </b-table-column>
          <b-table-column label="Rating">
            {{ props.row.rating }}
          </b-table-column>
          <b-table-column label="Date From">
            {{ props.row.date_from }}
          </b-table-column>
          <b-table-column label="Date To">
            {{ props.row.date_to }}
          </b-table-column>
          <b-table-column label="Number of Hours">
            {{ props.row.number_of_hours }}
          </b-table-column>
          <b-table-column label="Type of LD">
            {{ props.row.type_of_ld }}
          </b-table-column>
          <b-table-column label="Conducted By">
            {{ props.row.conducted_by }}
          </b-table-column>
        </template>
        <template slot="detail" slot-scope="props">
          <article class="media">
            <div class="media-content">
              <div class="content">
                <p>
                  <b>Remarks:</b> {{ props.row.raw.remarks }}
                  <b>Approver 1:</b> <span style="color: green" title="approved" aria-label="approved">APPROVED <i class="fas fa-check"></i></span>
                  <b>Approver 2:</b> <span style="color: red;" title="disapproved" aria-label="disapproved">DISAPPROVED (No lorem ipsum.) <i class="fas fa-times"></i></span>
                  <b>Approver 3:</b> <span style="color: orange;" title="request" aria-label="request">REQUEST <i class="far fa-clock"></i></span>
                  <b>Approver 4:</b> <span style="color: orange;" title="request" aria-label="request">REQUEST <i class="far fa-clock"></i></span>
                </p>
              </div>
            </div>
          </article>
        </template>
        <template slot="empty">
          <b-table-column label="Program">
          </b-table-column>
          <b-table-column label="Rating">
          </b-table-column>
          <b-table-column label="Date From">
          </b-table-column>
          <b-table-column label="Date To">
          </b-table-column>
          <b-table-column label="Number of Hours">
          </b-table-column>
          <b-table-column label="Type of LD">
          </b-table-column>
          <b-table-column label="Conducted By">
          </b-table-column>
        </template>
      </b-table>
    </div> -->
    <transition name="fade">
      <EmployeeSearch search-title="Employee" @close="close" @view="view_employee" v-if="isSearch" />
    </transition>
    <b-modal :active.sync="isAddTraining" has-modal-card :canCancel=false>
      <transition name="fade">
        <form action="">
          <div class="modal-card" style="height: 35.625rem; margin-top: 4rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">Training</p>
            </header>
            <section class="modal-card-body">
              <b-field label="Program">
                <b-input v-model="training.program.name"></b-input>
                <!-- <b-field>
                  <b-input v-model="training.program.name" disabled expanded required>
                  </b-input>
                  <p class="control">
                    <button class="button is-primary" @click.prevent="isAddProgram = true"><i class="fa fa-bars"></i></button>
                  </p>
                </b-field> -->
              </b-field>
              <b-field label="Date From">
                <b-datepicker
                  placeholder="Click to select..."
                  icon="calendar-today"
                  v-model="training.date_from"
                  :date-formatter="d => d.toLocaleDateString('en-GB')"
                  :date-parser="d => parseDate(d)"
                  editable>
                </b-datepicker>
              </b-field>
              <b-field label="Date To">
                <b-datepicker
                  placeholder="Click to select..."
                  icon="calendar-today"
                  v-model="training.date_to"
                  :date-formatter="d => d.toLocaleDateString('en-GB')"
                  :date-parser="d => parseDate(d)"
                  editable>
                </b-datepicker>
              </b-field>
              <b-field label="Number of Hours">
                <b-input v-model="training.number_of_hours"></b-input>
              </b-field>
              <b-field label="Type of LD">
                <b-input v-model="training.type_of_ld"></b-input>
              </b-field>
              <b-field label="Conducted By / Sponsored By">
                <b-input v-model="training.sponsored_by"></b-input>
              </b-field>
              <br>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" @click.prevent="saveTraining()">Save</button>
              <button class="button is-danger" @click.prevent="isAddTraining = false">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>
    <!-- <b-modal :active.sync="isAddProgram" has-modal-card :canCancel=false>
      <transition name="fade">
        <form action="">
          <div class="modal-card" style="height: 35.625rem; margin-top: 4rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">Training</p>
            </header>
            <section class="modal-card-body">
              <b-input v-model="search_query" placeholder="Search" /><br>
              <b-table
                :data="programs"
                :bordered=true
                :hoverable=true
                :paginated="true"
                :selected.sync="programSelected"
                :per-page="perPage">
                <template slot-scope="props">
                  <b-table-column label="Programs">
                    {{ props.row.name }}
                  </b-table-column>
                </template>
                <template slot="empty">
                  <b-table-column label="Programs">
                  </b-table-column>
                </template>
              </b-table>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" @click.prevent="selectProgram()">Select</button>
              <button class="button is-danger" @click.prevent="isAddProgram = false">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal> -->
  </div>
</template>

<script>
import EmployeeSearch from '../components/SearchModal.vue'

export default {
  components: {
    EmployeeSearch
  },
  data() {
    return {
      config: '',
      employee_id: null,
      isLoading: false,
      isSearch: true,
      isAddTraining: false,
      isAddProgram: false,
      trainings: [],
      programs: [
        {
          id: 1,
          name: 'Lorem Ipsum'
        },
        {
          id: 2,
          name: 'Sed ut perspiciatis unde'
        },
        {
          id: 3,
          name: 'Duis aute irure'
        },
        {
          id: 4,
          name: 'Excepteur'
        },
        {
          id: 5,
          name: 'Quis autem vel eum'
        }
      ],
      training: {
        program: {
          id: null,
          name: null
        },
        date_from: new Date(),
        date_to: new Date(),
        number_of_hours: null,
        type_of_ld: null,
        conducted_by: null,
        sponsored_by: null,
        location: null
      },
      perPage: 10,
      fullName: '',
      programSelected: null,
      search_query: ''
    }
  },
  beforeMount() {
    if (this.$editmode.state.empData) {
      this.isSearch = false
      this.employee_id = this.$editmode.state.empData.id
      this.fullName = this.$editmode.state.empData.full_name
      this.$editmode.commit('setEmpData', null)
    }
  },
  mounted() {
    this.config = {
      headers: {'Authorization': "Bearer " + this.$store.state.token}
    };
  },
  computed: {
    filter() {
      var i;
      var name_re = new RegExp(this.search_query, 'i')
      var data = []
      for (i in this.trainings) {
        if ([this.trainings[i].program, this.trainings[i].type_of_ld, this.trainings[i].sponsored_by, this.trainings[i].conducted_by].join(' ').match(name_re)) {
          data.push(this.trainings[i])
        }
      }
      return data
    },
  },
  methods: {
    getTrainings() {
      this.isLoading = true
      this.$http.get(`${this.$host}/api/training/employee/show/${this.employee_id}`,
        this.config
      ).then((response) => {
        let data = response.data
        this.trainings = data.training
        this.isLoading = false
      }).catch((error) => {
        //console.log(error)
        this.isLoading = false
      });
    },
    parseDate(d) {
      let parts = d.split("/")
      let dt = new Date(parseInt(parts[2], 10),
        parseInt(parts[1], 10) - 1,
        parseInt(parts[0], 10))
      return dt
    },
    selectProgram() {
      this.isAddProgram = false
      this.training.program = this.programSelected
    },
    saveTraining() {
      this.trainings.push(this.training)
      this.isAddTraining = false
    },
    addTraining() {
      this.isAddTraining = true
    },
    view_employee(selected) {
      this.employee_id = selected.id
      if (selected.name_extension == null) {
        this.fullName = `${selected.first_name} ${selected.last_name}`
      }
      else {
        this.fullName = `${selected.first_name} ${selected.last_name} ${selected.name_extension}`
      }
      if (this.employee_id != undefined) {
        this.isSearch = false
        this.getTrainings()
      }
    },
    close() {
      if (!this.employee_id) {
        this.$router.push({ path: '/trainings/manage' })
        this.isSearch = false
      }
      else {
        this.isSearch = false
      }
    },
    openProofCOA(proof) {
      let url = this.$host + '/storage/training/certificate/' + proof
      let win = window.open(url, '_blank')
      win.focus()
    },
    openProofPTR(proof) {
      let url = this.$host + '/storage/training/report/' + proof
      let win = window.open(url, '_blank')
      win.focus()
    },
  }
}
</script>

<style>

</style>
