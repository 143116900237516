<template>
  <div class="box">
    <div>
      <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
      <button class="button is-primary" @click.prevent="isPrint = true">
        <span><i class="fa fa-print"></i> Print Selected</span>
      </button>
      <button class="button is-danger" @click.prevent="clearSelected()">
        <span><i class="fa fa-times"></i> Clear Selected</span>
      </button>
      <hr>
      <b-field label="">
        <b-input v-model="search_query" placeholder="Search Employee" />
      </b-field>
      <b-table
        :data="filter"
        focusable
        :paginated="true"
        :per-page="10"
        :checked-rows.sync="checkedRows"
        checkable>
        <template slot-scope="props">
          <b-table-column label="Employee No.">
            {{ props.row.employee_number }}
          </b-table-column>
          <b-table-column label="First Name">
            {{ props.row.first_name }}
          </b-table-column>
          <b-table-column label="Middle Name">
            {{ props.row.middle_name }}
          </b-table-column>
          <b-table-column label="Last Name">
            {{ props.row.last_name }}
          </b-table-column>
          <b-table-column label="Name Extension">
            {{ isNull(props.row.name_extension) }}
          </b-table-column>
          <b-table-column label="Date of Birth">
            {{ props.row.birth_date }}
          </b-table-column>
          <b-table-column label="Division">
            {{ props.row.division }}
          </b-table-column>
          <!-- <b-table-column field="updated_at" label="Updated" sortable centered>
            {{ yesNo(props.row.updated_at) }}
          </b-table-column> -->
        </template>
      </b-table>
    </div>
    <b-modal :active.sync="isPrint" has-modal-card :canCancel=false>
      <transition name="fade">
        <form action="">
          <div class="modal-card" style="height: 35.625rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">Signature Field</p>
            </header>
            <section class="modal-card-body">
              <b-field label="SIGNATURE 1"></b-field>
              <b-field label="Name">
                <b-autocomplete
                  v-model="coeField.name_1"
                  :data="filteredName1"
                  field="full_name"
                  @select="option => {coeField.position_1 = option.position, coeField.division_1 = option.division}"
                  :open-on-focus=false expanded>
                </b-autocomplete>
              </b-field>
              <b-field label="Position">
                <b-input
                  v-model="coeField.position_1"
                  expanded>
                </b-input>
              </b-field>
              <b-field label="Division">
                <b-input
                  v-model="coeField.division_1"
                  expanded>
                </b-input>
              </b-field>
              <b-field label="SIGNATURE 2"></b-field>
              <b-field label="Name">
                <b-autocomplete
                  v-model="coeField.name_2"
                  :data="filteredName2"
                  field="full_name"
                  @select="option => {coeField.position_2 = option.position, coeField.division_2 = option.division}"
                  :open-on-focus=false expanded>
                </b-autocomplete>
              </b-field>
              <b-field label="Position">
                <b-input
                  v-model="coeField.position_2"
                  expanded>
                </b-input>
              </b-field>
              <b-field label="Division">
                <b-input
                  v-model="coeField.division_2"
                  expanded>
                </b-input>
              </b-field>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" @click.prevent="printSR()">Print</button>
              <button class="button is-danger" type="button" @click.prevent="isPrint=false">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: true,
      search_query: '',
      data: [],
      checkedRows: [],
      supervisor: '',
      position: '',
      division: '',
      isPrint: false,
      suggest: [],
      employees: [],
      coeField: {
        name_1: '',
        position_1: '',
        division_1: '',
        name_2: '',
        position_2: '',
        division_2: ''
      }
    }
  },
  mounted() {
    let config = {
      headers: {'Authorization': "Bearer " + this.$store.state.token}
    };

    this.$http.get(`${this.$host}/api/employee/search`,
      config
    ).then((response) => {
      let data = response.data.data
      this.data = data
      this.employees = data
      // let i = 0
      // for (i in this.employees) {
      //   if (!this.employees[i].name_extension && !this.employees[i].middle_name) {
      //     this.suggest.push([this.employees[i].first_name.toUpperCase(),
      //     this.employees[i].last_name.toUpperCase()].join(' '))
      //   }
      //   else if (!this.employees[i].name_extension) {
      //     this.suggest.push([this.employees[i].first_name.toUpperCase(), this.employees[i].middle_name.toUpperCase(),
      //     this.employees[i].last_name.toUpperCase()].join(' '))
      //   }
      //   else if (!this.employees[i].middle_name) {
      //     this.suggest.push([this.employees[i].first_name.toUpperCase(),
      //     this.employees[i].last_name.toUpperCase(), this.employees[i].name_extension.toUpperCase()].join(' '))
      //   }
      //   else {
      //     this.suggest.push([this.employees[i].first_name.toUpperCase(), this.employees[i].middle_name.toUpperCase(),
      //     this.employees[i].last_name.toUpperCase(), this.employees[i].name_extension.toUpperCase()].join(' '))
      //   }
      // }
      this.isLoading = false
    }).catch((error) => {
      //console.log(error)
    });

    this.$http.get(`${this.$host}/api/parameter/service_record/employee`,
      config
    ).then((response) => {
      let data = response.data
      this.suggest = data

      let i = 0
      for (i in this.suggest) {
        this.suggest[i].full_name = this.suggest[i].full_name.toUpperCase()
      }
    }).catch((error) => {
      //console.log(error)
    });
  },
  computed: {
    filter() {
      var i;
      var name_re = new RegExp(this.search_query, 'i')
      var data = []
      for (i in this.data) {
        if ([this.data[i].employee_number, this.data[i].first_name, this.data[i].middle_name, this.data[i].last_name, this.data[i].division].join(' ').match(name_re)) {
          data.push(this.data[i])
        }
      }
      return data
    },
    filteredSupervisor() {
      return this.suggest.filter((option) => {
        return option
          .toString()
          .toLowerCase()
          .indexOf(this.supervisor.toLowerCase()) >= 0
      })
    },
    filteredName1() {
      return this.suggest.filter((option) => {
        return option.full_name
          .toString()
          .toLowerCase()
          .indexOf(this.coeField.name_1.toLowerCase()) >= 0
      })
    },
    filteredName2() {
      return this.suggest.filter((option) => {
        return option.full_name
          .toString()
          .toLowerCase()
          .indexOf(this.coeField.name_2.toLowerCase()) >= 0
      })
    }
  },
  methods: {
    clearSelected() {
      if (this.checkedRows.length > 0) {
        this.$dialog.confirm ({
          title: 'Clear Selected Rows',
          message: 'Are you sure you want to <b>clear</b> the selected rows? This action cannot be undone.',
          confirmText: 'Confirm',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: () => this.checkedRows = []
        })
      }
    },
    printSR() {
      if (this.checkedRows.length < 1) {
        this.$toast.open({
          message: 'No data selected.',
          type: 'is-danger',
          position: 'is-bottom-right',
          duration: 4000
        })
      }
      else {
        this.isLoading = true

        let config = {
          headers: {'Authorization': "Bearer " + this.$store.state.token}
        };

        this.$http.get(`${this.$host}/api/report/generate/${this.checkedRows[0].id}`,
          config
        ).then((response) => {
          this.isLoading = false
          let token = response.data

          let url = `${this.$host}/api/report/certification-of-employment/${token}?name_1=${this.coeField.name_1}&position_1=${this.coeField.position_1}&division_1=${this.coeField.division_1}&name_2=${this.coeField.name_2}&position_2=${this.coeField.position_2}&division_2=${this.coeField.division_2}`
          let win = window.open(url, '_blank')
          win.focus()
          this.isPrint = false
        }).catch((error) => {
          //console.log(error)
          this.isLoading = false
        });
      }
    },
    isNull(val) {
      if (val == 'null') {
        return ''
      }
      else {
        return val
      }
    }
  }
}
</script>
