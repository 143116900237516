import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { store, suggestions, role, editmode, time, preload } from './store.js'
import Axios from 'axios'
import { host } from './host.js'
import vSelect from 'vue-select'
import IdleVue from 'idle-vue'

Vue.component('v-select', vSelect)

Vue.prototype.$http = Axios;
Vue.prototype.$store = store;
Vue.prototype.$suggestions = suggestions;
Vue.prototype.$role = role;
Vue.prototype.$host = host;
Vue.prototype.$editmode = editmode;
Vue.prototype.$time = time;
Vue.prototype.$preload = preload;

Vue.config.productionTip = false

const eventsHub = new Vue()

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  idleTime: 60 * 60000
})

// Vue.directive('uppercase', {
//   bind(el) {
//     el.addEventListener('keyup', (e) => {
//         e.target.value = e.target.value.toUpperCase()
//         vnode.componentInstance.$emit('input', e.target.value.toUpperCase())
//     })
//   }
// })

new Vue({
  router,
  render: h => h(App),
  data() {
    return {
      snackbar: ''
    }
  },
  onIdle() {
    // if (store.state.isLoggedIn) {
    //   this.snackbar = this.$snackbar.open({
    //     message: 'Logged out due to inactivity.',
    //     indefinite: true,
    //     position: 'is-bottom',
    //     actionText: null
    //   })
    // }
    store.commit('clearToken')
    window.location.reload()
  },
  onActive() {
    // if (this.snackbar != '') {
    //   setTimeout(() => this.snackbar.close(), 4000)
    // }
  }
}).$mount('#app')
