<template>
  <div>
    <b-collapse class="card">
      <div
        slot="trigger"
        class="card-header"
        role="button"
        aria-controls="contentIdForA11y3">
        <p class="card-header-title has-background-success has-text-white">
          Announcements
        </p>
        <a class="card-header-icon has-background-success has-text-white">
          
        </a>
      </div>
      <div class="card-content">
        <div class="content">
          <b>{{ currentTitle }}</b><br>
          <span v-html="currentAnnouncementShort"></span><a @click="showAnnouncement()"> ..read more</a><br>
        </div>
      </div>
      <footer class="card-footer">
        <a class="card-footer-item" @click="switchAnnouncement('back')"><i class="fas fa-angle-double-left"></i>&nbsp;Back</a>
        <a class="card-footer-item" @click="switchAnnouncement('next')">Next&nbsp;<i class="fas fa-angle-double-right"></i></a>
      </footer>
    </b-collapse>
    
    <br>

    <b-collapse class="card">
      <div
        slot="trigger"
        class="card-header"
        role="button"
        aria-controls="contentIdForA11y3">
        <p class="card-header-title has-background-success has-text-white">
          Quick Links
        </p>
        <a class="card-header-icon has-background-success has-text-white">
          
        </a>
      </div>
      <div class="card-content">
        <div class="content">
          <router-link to="/schedule">
            Schedule
          </router-link>
          <br>
          <router-link to="/requests">
            Requests
          </router-link>
        </div>
      </div>
    </b-collapse>

    <b-modal :active.sync="isFullAnnouncement" has-modal-card>
      <form action="">
        <div class="modal-card pos" style="width: 50rem;">
          <section class="modal-card-body">
            <b>{{ currentTitle }}</b>
            <a class="has-text-grey-lighter a-actions" disabled><i class="fas fa-bullhorn"></i></a>
            <a class="has-text-grey-lighter a-actions" disabled><i class="fas fa-pen"></i></a>
            <a class="has-text-grey-lighter a-actions" disabled><i class="fas fa-trash"></i></a>
            <a class="has-text-danger is-pulled-right" @click="isFullAnnouncement = false"><i class="fas fa-times"></i></a>
            <br><br>
            <span v-html="currentAnnouncement"></span><br>
          </section>
        </div>
      </form>
    </b-modal>
  </div>
  <!-- <aside class="menu has-background-white box section">
    <p class="menu-label">
      General
    </p>
    <ul class="menu-list">
      <li><a>Dashboard</a></li>
      <li><a>Customers</a></li>
    </ul>
    <p class="menu-label">
      Administration
    </p>
    <ul class="menu-list">
      <li><a>Team Settings</a></li>
      <li>
        <a class="is-active">Manage Your Team</a>
        <ul>
          <li><a>Members</a></li>
          <li><a>Plugins</a></li>
          <li><a>Add a member</a></li>
        </ul>
      </li>
      <li><a>Invitations</a></li>
      <li><a>Cloud Storage Environment Settings</a></li>
      <li><a>Authentication</a></li>
    </ul>
    <p class="menu-label">
      Transactions
    </p>
    <ul class="menu-list">
      <li><a>Payments</a></li>
      <li><a>Transfers</a></li>
      <li><a>Balance</a></li>
    </ul>
  </aside> -->
</template>

<script>
export default {
  data() {
    return {
      currentAnnouncement: '',
      currentTitle: '',
      isFullAnnouncement: false,
      announcements: []
    }
  },
  computed: {
    currentAnnouncementShort() {
      return this.currentAnnouncement.substr(0, 150)
    }
  },
  mounted() {
    let announcement = this.announcements[0]
    this.currentAnnouncement = announcement.announcement
    this.currentTitle = announcement.title
  },
  methods: {
    switchAnnouncement(direction) {
      let index = this.announcements.findIndex( x => x.announcement == this.currentAnnouncement )
      if (direction == 'back') {
        if (index == 0) {
          let announcement = this.announcements[this.announcements.length - 1]
          this.currentAnnouncement = announcement.announcement
          this.currentTitle = announcement.title
        }
        else {
          let announcement = this.announcements[index - 1]
          this.currentAnnouncement = announcement.announcement
          this.currentTitle = announcement.title
        }
      }
      else if (direction == 'next') {
        if (index == this.announcements.length - 1) {
          let announcement = this.announcements[0]
          this.currentAnnouncement = announcement.announcement
          this.currentTitle = announcement.title
        }
        else {
          let announcement = this.announcements[index + 1]
          this.currentAnnouncement = announcement.announcement
          this.currentTitle = announcement.title
        }
      }
    },
    showAnnouncement() {
      this.isFullAnnouncement = true
      
    }
  }
}
</script>

<style scoped>
@media screen and (min-width: 1px) and (max-width: 1215px)  {
  .modal-card {
    margin-left: 0px !important;
    width: 100% !important;
  }
  .pos {
    width: none;
  }
}
.pos {
  margin-top: 70px !important;
}
.modal-card-head {
  height: 50px;
}
.a-actions {
  margin-right: 5px;
}
</style>

