<template>
  <div>
    <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
    <button class="button is-primary" @click.prevent="importFile()" v-if="$role.state.permissions['write payroll'] || $role.state.permissions['write payroll funding source']">
      <span><i class="fa fa-upload"></i> Import</span>
    </button>

    <b-modal :active.sync="isAttach" has-modal-card :canCancel=false>
      <transition name="fade">
        <form @submit.prevent="attach()" id="requestForm">
          <div class="modal-card" style="margin-top: 4rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">Import File</p>
            </header>
            <section class="modal-card-body">
              <b-field>
                <input type="file" @input="onAttach" id="proofCOA" required>
              </b-field>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" type="submit">Import</button>
              <button class="button is-danger" @click.prevent="close()">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      isAttach: false,
      attachForm: new FormData()
    }
  },
  methods: {
    onAttach(e) {
      let file = e.target.files[0]
      this.attachForm.append('csv_file', file)
    },
    importFile() {
      this.isAttach = true
    },
    close() {
      this.attachForm = new FormData()
      this.isAttach = false
    },
    attach() {
      this.$dialog.confirm({
        title: 'Import File',
        message: "Are you sure you want to <b>import</b> this file?",
        confirmText: 'Import',
        type: 'is-success',
        hasIcon: false,
        onConfirm: () => {
          this.isLoading = true

          let config = {
            headers: {'Authorization': "Bearer " + this.$store.state.token},
          }

          this.$http.post(`${this.$host}/api/payroll/earning/import/`,
            this.attachForm,
            config,
          ).then((response) => {
            this.$dialog.alert({
              title: 'Success',
              message: "File Imported.",
              type: 'is-success',
              hasIcon: true,
              icon: 'check',
              iconPack: 'fa'
            })
            this.isAttach = false
            this.isLoading = false
          }).catch((error) => {
            if (error.response.data) {
              this.$dialog.alert({
                title: 'Error',
                message: "<b>message:</b> <br>" + error.response.data,
                type: 'is-danger',
                hasIcon: true,
                icon: 'exclamation-circle',
                iconPack: 'fa'
              })
            }
            this.isLoading = false
          });
        }
      })
    }
  }
}
</script>

<style>

</style>
