<template>
  <div>
    <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
    <div class="box">
      <div>
        <b-field>
          <!-- <b-field label="Flexi">
          </b-field> -->
          <button class="button is-primary" @click.prevent="isEdit = true">
            <i class="fa fa-pen"></i> &nbsp;Edit Payroll Minimum Amount
          </button>
        </b-field>
      </div>
      <hr>
      <b>Payroll Minimum Amount:</b> {{ minimum_payroll_amount }}
    </div>
    <b-modal :active.sync="isEdit" has-modal-card :canCancel=false>
      <transition name="fade">
        <form @submit.prevent="save()">
          <div class="modal-card" style="margin-top: 4rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">Minimum Payroll Amount</p>
            </header>
            <section class="modal-card-body">
              <b-field label="Minimum Payroll Amount">
                <b-input type="number" step="any" v-model="minimum_payroll_amount" expanded required></b-input>
              </b-field>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" type="submit">Save</button>
              <button class="button is-danger" @click.prevent="cancel">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: true,
      config: {},
      minimum_payroll_amount: '',
      flexi_to: '',
      lunch_hour: '',
      isEdit: false
    }
  },
  mounted() {
    this.config = {
      headers: {'Authorization': "Bearer " + this.$store.state.token}
    };
    this.getSettings()
  },
  methods: {
    getSettings() {
      this.$http.get(`${this.$host}/api/setting/show`,
        this.config
      ).then((response) => {
        let data = response.data
        this.minimum_payroll_amount = data[11].hash_value
        this.isLoading = false
      }).catch((error) => {
        this.isLoading = false
        //console.log(error)
      });
    },
    save() {
      this.isLoading = true
      const dec = this.minimum_payroll_amount.split('.')[1]
      const len = dec && dec.length > 2 ? dec.length : 2
      this.minimum_payroll_amount = Number(this.minimum_payroll_amount).toFixed(len)

      this.$http.put(`${this.$host}/api/setting/update/12`,
        { hash_value: this.minimum_payroll_amount },
        this.config
      ).then((response) => {
        this.$toast.open({
          message: 'Data successfully saved.',
          type: 'is-success',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.cancel()
        // //console.log(data)
      }).catch((error) => {
        //console.log(error)
        this.isLoading = false
      });
    },
    cancel() {
      this.isLoading = true
      this.getSettings()
      this.isEdit = false
    }
  }
}
</script>

<style>

</style>
