<template>
  <div>
    <b-field label="Offenses"></b-field>
    <b-field grouped group-multiline>
      <button class="button is-success control" @click.prevent="openOffense()"
      v-if="$role.state.permissions['write offense']">
        <i class="fa fa-plus"></i> &nbsp;Offense
      </button>
      <b-select placeholder="Per Page" v-model="perPage">
        <option value="5">5 per page</option>
        <option value="10">10 per page</option>
        <option value="20">20 per page</option>
        <option value="31">31 per page</option>
      </b-select>
      <b-input v-model="search_query" placeholder="Search" />
    </b-field>
    <hr style="margin-top: 0; margin-bottom: 10px;">
    <b-table
      :data="filter"
      :bordered=false
      :hoverable=true
      :paginated="true"
      :per-page="perPage">
      <template slot-scope="props">
        <b-table-column label="Recieved Date" field="recieved_date" sortable>
          {{ props.row.recieved_date }}
        </b-table-column>
        <b-table-column label="Type" field="type" sortable>
          {{ props.row.type }}
        </b-table-column>
        <b-table-column label="Offense" field="offense" sortable>
          {{ props.row.offense }}
        </b-table-column>
        <b-table-column label="Corrective Action Taken" field="corrective_action_taken" sortable>
          {{ props.row.corrective_action_taken }}
        </b-table-column>
        <b-table-column label="Status" field="status" sortable>
          {{ props.row.status }}
        </b-table-column>
        <b-table-column label="Remarks" field="remarks" sortable>
          {{ props.row.remarks }}
        </b-table-column>
        <b-table-column label="Memo Date" field="memo_date" sortable>
          {{ props.row.memo_date }}
        </b-table-column>
        <b-table-column label="Actions">
          <button class="button btn-action is-primary is-small" @click.prevent="openOffense(props.row)"><i class="fa fa-pen"></i></button>
          <button class="button btn-action is-danger is-small" @click.prevent="deleteOffense(props.row.id)"><i class="fa fa-trash"></i></button>
        </b-table-column>
      </template>
      <template slot="empty">
        <b-table-column label="Recieved Date">
        </b-table-column>
        <b-table-column label="Type">
        </b-table-column>
        <b-table-column label="Offense">
        </b-table-column>
        <b-table-column label="Corrective Action Taken">
        </b-table-column>
        <b-table-column label="Status">
        </b-table-column>
        <b-table-column label="Remarks">
        </b-table-column>
        <b-table-column label="Memo Date">
        </b-table-column>
        <b-table-column label="Actions">
        </b-table-column>
      </template>
    </b-table>

    <b-modal :active.sync="isoffense" has-modal-card :canCancel=false>
      <transition name="fade">
        <form @submit.prevent="saveoffenses()">
          <div class="modal-card" style="height: 35.625rem; margin-top: 4rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">Offense</p>
            </header>
            <section class="modal-card-body">
              <b-field label="Recieved Date">
                <b-datepicker
                  placeholder="Click to select..."
                  icon="calendar-today"
                  v-model="offense.recieved_date"
                  :date-formatter="d => d.toLocaleDateString('en-GB')"
                  :date-parser="d => parseDate(d)"
                  editable
                  required>
                </b-datepicker>
              </b-field>
              <b-field label="Type">
                <b-autocomplete
                  v-model="offense.type"
                  :data="filteredType"
                  :open-on-focus=false expanded required>
                </b-autocomplete>
              </b-field>
              <b-field label="Offense">
                <b-autocomplete
                  v-model="offense.offense"
                  :data="filteredOffense"
                  :open-on-focus=false expanded required>
                </b-autocomplete>
              </b-field>
              <b-field label="Corrective Action Taken">
                <b-autocomplete
                  v-model="offense.corrective_action_taken"
                  :data="filteredCorrective"
                  :open-on-focus=false expanded required>
                </b-autocomplete>
              </b-field>
              <b-field label="Status">
                <b-autocomplete
                  v-model="offense.status"
                  :data="filteredStatus"
                  :open-on-focus=false expanded required>
                </b-autocomplete>
              </b-field>
              <b-field label="Remarks">
                <b-input v-model="offense.remarks" expanded/>
              </b-field>
              <b-field label="Memo Date">
                <b-datepicker
                  placeholder="Click to select..."
                  icon="calendar-today"
                  v-model="offense.memo_date"
                  :date-formatter="d => d.toLocaleDateString('en-GB')"
                  :date-parser="d => parseDate(d)"
                  position="is-top-right"
                  editable
                  required>
                </b-datepicker>
              </b-field>
              <br>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" type="submit">Save</button>
              <button class="button is-danger" @click.prevent="close()">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  props: ['employee_id', 'offenses'],
  data() {
    return {
      config: '',
      isLoading: false,
      perPage: 5,
      search_query: '',
      isoffense: false,
      offense: {
        employee_id: null,
        recieved_date: new Date(),
        type: '',
        offense: '',
        corrective_action_taken: '',
        status: '',
        remarks: null,
        memo_date: new Date()
      },
      offense_id: null,
      offenseParams: {
        type: [],
        activity: []
      }
    }
  },
  mounted() {
    this.config = {
      headers: {'Authorization': "Bearer " + this.$store.state.token}
    }

    this.init()
    this.getParams()
  },
  computed: {
    filter() {
      var i;
      var name_re = new RegExp(this.search_query, 'i')
      var data = []
      for (i in this.offenses) {
        if ([this.offenses[i].recieved_date, this.offenses[i].type, this.offenses[i].activity, this.offenses[i].remarks].join(' ').match(name_re)) {
          data.push(this.offenses[i])
        }
      }
      return data
    },
    filteredType() {
      return this.offenseParams.type.filter((option) => {
        return option
          .toString()
          .toLowerCase()
          .indexOf(this.offense.type.toLowerCase()) >= 0
      })
    },
    filteredOffense() {
      return this.offenseParams.offense.filter((option) => {
        return option
          .toString()
          .toLowerCase()
          .indexOf(this.offense.offense.toLowerCase()) >= 0
      })
    },
    filteredCorrective() {
      return this.offenseParams.corrective_action_taken.filter((option) => {
        return option
          .toString()
          .toLowerCase()
          .indexOf(this.offense.corrective_action_taken.toLowerCase()) >= 0
      })
    },
    filteredStatus() {
      return this.offenseParams.status.filter((option) => {
        return option
          .toString()
          .toLowerCase()
          .indexOf(this.offense.status.toLowerCase()) >= 0
      })
    },
  },
  methods: {
    parseDate(d) {
      let parts = d.split("/")
      let dt = new Date(parseInt(parts[2], 10),
        parseInt(parts[1], 10) - 1,
        parseInt(parts[0], 10))
      return dt
    },
    init() {
      this.$emit('update')
    },
    close() {
      this.offense_id = null
      this.offense = {
        employee_id: null,
        recieved_date: new Date(),
        type: '',
        offense: '',
        corrective_action_taken: '',
        status: '',
        remarks: null,
        memo_date: new Date()
      }
      this.isoffense = false
      this.init()
    },
    openOffense(obj) {
      this.isoffense = true
      if (obj) {
        this.offense_id = obj.id
        this.offense = obj
        this.offense.recieved_date = new Date(obj.recieved_date)
        this.offense.memo_date = new Date(obj.memo_date)
      }
      this.getParams()
    },
    getParams() {
      this.isLoading = true

      this.$http.get(`${this.$host}/api/parameter/offense/`,
        this.config
      ).then((response) => {
        let data = response.data
        this.offenseParams.type = data.type.map(a => a.toUpperCase())
        this.offenseParams.offense = data.offense.map(a => a.toUpperCase())
        this.offenseParams.corrective_action_taken = data.corrective_action_taken.map(a => a.toUpperCase())
        this.offenseParams.status = data.status.map(a => a.toUpperCase())
        this.isLoading = false
      }).catch((error) => {
        //console.log(error)
        this.isLoading = false
      });
    },
    saveoffenses() {
      this.isLoading = true

      let id = ''
      let offense = this.offense
      offense.recieved_date = moment(offense.recieved_date).format("YYYY-MM-DD")
      offense.memo_date = moment(offense.memo_date).format("YYYY-MM-DD")
      offense.employee_id = this.employee_id

      if (this.offense_id) {
        id = this.offense_id
      }

      this.$http.post(`${this.$host}/api/offense/save/${id}`,
        offense,
        this.config,
      ).then((response) => {
        this.$toast.open({
          message: 'Data successfully saved.',
          type: 'is-success',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.isLoading = false
        this.close()
      }).catch((error) => {
        this.isLoading = false
        this.$toast.open({
          message: 'Error saving data.',
          type: 'is-danger',
          position: 'is-bottom-right',
          duration: 4000
        })
      });
    },
    deleteOffense(id) {
      this.$dialog.confirm({
        title: 'Delete Offense',
        message: 'Are you sure you want to <b>delete</b> this offense? This action cannot be undone.',
        confirmText: 'Delete',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$http.delete(`${this.$host}/api/offense/delete/${id}`,
            this.config
          ).then((response) => {
            this.$toast.open({
              message: 'Offense deleted.',
              type: 'is-success',
              position: 'is-bottom-right',
              duration: 4000
            })
            this.init()
            this.isLoading = false
          }).catch((error) => {
            this.$toast.open({
              message: 'Error.',
              type: 'is-danger',
              position: 'is-bottom-right',
              duration: 4000
            })
            this.isLoading = false
          });
        }
      })
    },
  }
}
</script>

<style>

</style>
