<template>
  <div>
    <div class="columns header" style="margin-bottom: 0 !important;">
      <div class="column is-7" style="margin-left: 5px; font-size: 20px; text-transform: uppercase;">
        Position: <b>{{ selected.title }}</b>
      </div>
      <div class="column is-auto">
        <button v-if="$role.state.permissions['search position']" class="button is-primary is-pulled-right" style="margin-left: 10px;" @click.prevent="searchPosition">
          <i class="fa fa-search"></i> &nbsp;Positions
        </button>
        <button v-if="$role.state.permissions['write position']" class="button is-success is-pulled-right" style="margin-left: 10px;" @click.prevent="newPosition">
          <i class="fa fa-plus"></i> &nbsp;Add Position
        </button>
        <button v-if="$role.state.permissions['print position']" class="button is-primary is-pulled-right" @click.prevent="isPrint = true">
          <span><i class="fa fa-print"></i> Print</span>
        </button>
        <transition name="fade">
          <div class="scroll-print" v-if="isScrolled">
            <button v-if="$role.state.permissions['print position']" title="Print" class="button is-medium is-primary" @click.prevent="isPrint = true">
              <span><i class="fa fa-print"></i></span>
            </button>
            <button v-if="$role.state.permissions['write position']" title="Add Position" class="button is-medium is-success" @click.prevent="newPosition">
              <span><i class="fa fa-plus"></i></span>
            </button>
            <button v-if="$role.state.permissions['search position']" title="Search Position" class="button is-medium is-primary" @click.prevent="searchPosition">
              <span><i class="fa fa-search"></i></span>
            </button>
          </div>
        </transition>
        <!-- <router-link to="/users/add" class="button is-success is-pulled-right" @click="add()">
          <i class="fa fa-plus"></i> &nbsp;New User
        </router-link> -->
      </div>
    </div>
    <hr style="margin-top: 0 !important; margin-bottom: 0 !important;" class="has-background-grey-lighter">
    <br>
    <!-- <hr style="margin-top: 0 !important; margin-bottom: 0 !important;" class="has-background-grey-lighter"> -->
    <transition name="fade">
      <Search :isSupervisor="false" :data="data" :searchLoad="searchLoad" @view="getPositions" v-if="isSearch" @close="close"/>
      <PositionForm :data="selected" v-if="!isSearch && isLoaded" :isAdd="false"/>
    </transition>
    <b-modal :active.sync="isPrint" has-modal-card :canCancel=false>
      <transition name="fade">
        <SignatureField @print="printPDF" @close="isPrint = false" />
      </transition>
    </b-modal>
  </div>
</template>

<script>
const SignatureField = {
  data() {
    return {
      obj: {
        employee: '',
        supervisor: ''
      },
      employees: [],
      filteredEmployees: []
    }
  },
  mounted() {
    let config = {
      headers: {'Authorization': "Bearer " + this.$store.state.token}
    };

    this.$http.get(`${this.$host}/api/employee/search`,
      config
    ).then((response) => {
      let data = response.data.data
      this.employees = data
      let i = 0
      for (i in this.employees) {
        if (this.employees[i].name_extension == 'null') {
          this.filteredEmployees.push([this.employees[i].first_name, this.employees[i].middle_name,
          this.employees[i].last_name].join(' '))
        }
        else {
          this.filteredEmployees.push([this.employees[i].first_name, this.employees[i].middle_name,
          this.employees[i].last_name, this.employees[i].name_extension].join(' '))
        }
      }
    }).catch((error) => {
      //console.log(error)
    });
  },
  computed: {
    filteredEmployee() {
      return this.filteredEmployees.filter((option) => {
        return option
          .toString()
          .toLowerCase()
          .indexOf(this.obj.employee.toLowerCase()) >= 0
      })
    },
    filteredSupervisor() {
      return this.filteredEmployees.filter((option) => {
        return option
          .toString()
          .toLowerCase()
          .indexOf(this.obj.supervisor.toLowerCase()) >= 0
      })
    }
  },
  template: `
  <form action="">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Signature Field</p>
      </header>
      <section class="modal-card-body">
        <b-field label="Employee Name">
          <b-autocomplete
            v-model="obj.employee"
            :data="filteredEmployee"
            placeholder="Employee">
          </b-autocomplete>
        </b-field>
        <b-field label="Supervisor Name">
          <b-autocomplete
            v-model="obj.supervisor"
            :data="filteredSupervisor"
            placeholder="Supervisor">
          </b-autocomplete>
        </b-field>
        <br><br>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-success" @click.prevent="$emit('print', obj)">Print</button>
        <button class="button is-danger" type="button" @click="$emit('close')">Cancel</button>
      </footer>
    </div>
  </form>`
}

import Search from '../components/position/Search.vue'
import PositionForm from '../components/position/PositionForm.vue'

export default {
  components: {
    Search,
    PositionForm,
    SignatureField
  },
  data() {
    return {
      isSearch: true,
      data: {},
      searchLoad: true,
      selected: {},
      name: '',
      id: '',
      isLoaded: false,
      isPrint: false,
      isScrolled: false
    }
  },
  beforeMount() {
    if (this.$editmode.state.fromPositionAdd) {
      this.isSearch = false
      this.id = this.$editmode.state.newPositionId
      this.$editmode.commit('setPositionAdd', false)
      this.setPosition()
    }
    let config = {
      headers: {'Authorization': "Bearer " + this.$store.state.token}
    };

    this.$http.get(`${this.$host}/api/position/search`,
      config
    ).then((response) => {
      let data = response.data.data
      this.data = data
      this.searchLoad = false
    }).catch((error) => {
      //console.log(error)
    });

    window.addEventListener('scroll', this.scrollPrint);
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.scrollPrint);
  },
  methods: {
    scrollPrint() {
      if (window.scrollY != 0) {
        this.isScrolled = true
      }
      else {
        this.isScrolled = false
      }
    },
    setPosition() {
      let config = {
        headers: {'Authorization': "Bearer " + this.$store.state.token}
      };

      this.$http.get(`${this.$host}/api/position/search/${this.id}`,
        config
      ).then((response) => {
        let data = response.data.data
        this.selected = data
        this.searchLoad = false
        this.isSearch = false
        this.isLoaded = true
      }).catch((error) => {
        //console.log(error)
      });
    },
    close() {
      this.setPosition()
    },
    getPositions(obj) {
      this.id = obj.id
      this.setPosition()
    },
    newPosition() {
      this.$router.push({path: '/position/add'})
    },
    printPDF(obj) {
      this.isLoading = true

      let config = {
        headers: {'Authorization': "Bearer " + this.$store.state.token}
      };

      this.$http.get(`${this.$host}/api/report/generate/${this.id}`,
        config
      ).then((response) => {
        this.isLoading = false
        let token = response.data

        let url = `${this.$host}/api/report/pdf/${token}?employee=${obj.employee}&supervisor=${obj.supervisor}`
        let win = window.open(url, '_blank')
        win.focus()
        this.isPrint = false
      }).catch((error) => {
        //console.log(error)
        this.isLoading = false
      });
    },
    searchPosition() {
      if (this.$editmode.state.data) {
        this.$dialog.confirm({
          title: 'Discard Changes',
          message: 'Are you sure you want to <b>discard</b> your changes? This action cannot be undone.',
          confirmText: 'Discard',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: () => { this.isSearch = true }
        })
      }
      else {
        this.isSearch = true
      }
    }
  }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.scroll-print {
  position: fixed;
  z-index: 3;
  top: 90%;
  left: 87%;
}
@media screen and (min-width: 1px) and (max-width: 768px)  {
  .scroll-print {
    left: 55%
  }
}
</style>
