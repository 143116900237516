<template>
  <div>
    <div class="content columns">
      <div class="column is-6">
        <b-field label="USER INFORMATION">
        </b-field>
        <b-field label="Username" v-if="!isAdd">
          {{ this.$editmode.state.username }}
        </b-field>
        <b-field label="Email" v-if="!isAdd">
          <b-input v-model="data.users.email" :disabled="!isAdd"></b-input>
        </b-field>
        <b-radio v-model="data.users.active" v-if="!isAdd"
          native-value="1" :disabled="!isAdd">
          Active
        </b-radio>
        <b-radio v-model="data.users.active" v-if="!isAdd"
          native-value="0" :disabled="!isAdd">
          Inactive
        </b-radio><br v-if="!isAdd"><br v-if="!isAdd">
        <button class="button is-primary" @click.prevent="confirmPassDefault" :disabled="isDisabled" v-if="!isAdd">
          Set Password to Default
        </button><br v-if="!isAdd">
        <span style="font-size: 12px;" v-if="!isAdd">
          Setting the password to default will have the following convention: <b>firstnameEmployeenumber</b> (all lower case)
        </span>
        <div v-if="isAdd">
          <b>Username:</b> {{ $editmode.state.username }} <br>
          <b>Email:</b> {{ $editmode.state.email }} <br />
          <b>Password:</b> {{ $editmode.state.password }} <br>
          <b>Active: </b> Yes <br>
          <b>Role: </b> Employee
        </div>
      </div>
      <div class="column is-auto" v-if="!isAdd">
        <b-field label="ROLES">
        </b-field>
        <b-radio v-model="data.users.roles[0]"
          native-value="Administrator" :disabled="!isAdd">
          Admin
        </b-radio><br>
        <b-radio v-model="data.users.roles[0]"
          native-value="CSD" :disabled="!isAdd">
          CSD
        </b-radio><br>
        <b-radio v-model="data.users.roles[0]"
          native-value="Employee" :disabled="!isAdd">
          Employee
        </b-radio><br>
        <b-radio v-model="data.users.roles[0]"
          native-value="Human Resource" :disabled="!isAdd">
          HR
        </b-radio><br>
        <b-radio v-model="data.users.roles[0]"
          native-value="Payroll" :disabled="!isAdd">
          Payroll
        </b-radio><br>
        <br>
        <!-- <b-field label="PERMISSIONS">
        </b-field>
        <b-checkbox v-model="data.users.permissions"
          native-value="read users" :disabled="!isAdd">
          Read User
        </b-checkbox><br>
        <b-checkbox v-model="data.users.permissions"
          native-value="write users" :disabled="!isAdd">
          Write User
        </b-checkbox><br>
        <b-checkbox v-model="data.users.permissions"
          native-value="print users" :disabled="!isAdd">
          Print User
        </b-checkbox><br> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["isDisabled", "data", "isAdd"],
  data() {
    return {
      username: '',
      password: this.data.employee_number,
      emp: '',
      active: '',
      pass_default: false,
      username_input: this.isDisabled,
      currentUsername: ''
    }
  },
  mounted() {
    this.getData()
    if (this.data.users.username == null || this.data.users.username == '') {
      this.username_input = false
    }
    this.currentUsername = this.data.users.username
  },
  methods: {
    confirmPassDefault() {
      this.$dialog.confirm({
        title: 'Set Password to Default',
        message: 'Are you sure you want to change your password to default? This action cannot be undone.',
        confirmText: 'Yes',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.passDefault()
      })
    },
    passDefault() {
      this.data.users.password = this.$editmode.state.password
      // alert(this.$editmode.state.password)
    },
    returnData() {
      // if (this.pass_default) {
      //   let personal = {
      //     users: {
      //       username: this.data.username,
      //       password: this.data.emp,
      //       active: this.data.active
      //     }
      //   }
      // }
      if (this.isAdd) {
        this.data.users = {
          email: this.$editmode.state.email,
          username: this.$editmode.state.username,
          password: this.$editmode.state.password,
          active: 1,
          roles: [ 'Employee' ],
          permissions: ['read employee', 'read daily time record', 'read schedule', 'apply request']
        }
        // alert(this.data.users.permissions)
        this.$emit('user', this.data)
      }
      else {
        if (this.currentUsername != this.$editmode.state.username) {
          this.data.users.username = this.$editmode.state.username
        }
        this.$emit('user', this.data)
      }
    },
    getData() {
      this.username = this.data.users.username
      this.password = this.data.users.password
      this.active = this.data.users.active
      this.emp = this.data.employee_number
      this.email = this.data.users.email
    },
  }
}
</script>

<style scoped>
  .input-spacing {
    opacity: 0;
  }
  input::placeholder {
    color: black !important;
  }
  .header {
    border-bottom: 2px solid #0F3C8B;
  }
</style>
