<template>
  <form>
    <div class="modal-card" style="width: 40rem; max-height: 35.625rem;">
      <header class="modal-card-head">
        <p class="modal-card-title">Formula Calculator</p>
      </header>
      <section class="modal-card-body">
        <b-field style="margin-bottom: 0px;">
          <b-input
            v-model="formulaDisplay"
            disabled expanded required>
          </b-input>
          <p class="control">
            <button type="button" class="button is-danger" @click.prevent="popFormula()">
              <span><i class="fa fa-minus"></i></span>
            </button>
          </p>
        </b-field>
        <span style="color: red; font-size: 12px;">{{ errorMsg }}</span>
        <b-field style="margin-top: 10px;">
          <button v-for="operator in operators" :key="operator.id"
          type="button" class="button is-primary is-small" style="margin-right: 5px; margin-bottom: 5px;"
          @click.prevent="applyOperator(operator)">
            {{ operator.code }}
          </button>
          <b-input
            @input="formulaValidate()"
            v-model="value"
            step="0.01"
            type="number"
            size="is-small">
          </b-input>
          <p class="control">
            <button type="button" class="button is-primary is-small" @click.prevent="mutateValue(value)">
              <span><i class="fa fa-plus"></i></span>
            </button>
          </p>
        </b-field>
        <button v-for="variable in variables" :key="variable.id"
        type="button" class="button is-primary is-small" style="margin-right: 5px; margin-bottom: 5px;"
        @click.prevent="applyVariable(variable)">
          {{ variable.code }}
        </button>
        <br>
        <button 
        type="button" class="button is-danger is-small"
        @click.prevent="clearFormula()">
          CLEAR
        </button>
        <br>
        <span style="font-size: 13px;"><b>NOTE:</b> If payroll group is casual, MONTHLY RATE formula is: <i>DAILY RATE * 22 DAYS</i></span>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-success" type="button" @click.prevent="confirm()">Confirm</button>
        <button class="button is-danger" type="button" @click.prevent="close()">Close</button>
      </footer>
    </div>
  </form>
</template>

<script>
export default {
  props: ['variables', 'isOperator', 'formula'],
  data() {
    return {
      config: '',
      operators: [
        {
          id: '+',
          code: '+'
        },
        {
          id: '-',
          code: '-'
        },
        {
          id: '*',
          code: '*'
        },
        {
          id: '/',
          code: '/'
        },
        {
          id: '(',
          code: '('
        },
        {
          id: ')',
          code: ')'
        },
      ],
      val: {},
      value: null,
      prevFormula: [],
      isVariable: true,
      errorMsg: ''
    }
  },
  computed: {
    formulaDisplay() {
      if (this.formula) {
        let formula
        let formulaArr
        formulaArr = this.formula.map(x => x = x.code)
        formula = formulaArr.join(' ')
        return formula
      }
    },
  },
  mounted() {
    this.config = {
      headers: {'Authorization': "Bearer " + this.$store.state.token}
    };

    this.prevFormula = JSON.parse(JSON.stringify(this.formula))
    this.formulaValidate()
  },
  methods: {
    formulaValidate() {
      if (this.formula.length > 0) {
        let lastVal = this.formula[this.formula.length - 1]
        if (this.operators.includes(lastVal) || lastVal == '(') {
          this.isVariable = true
        }
        else {
          this.isVariable = false
        }
        this.errorMsg = ''
      }
    },
    mutateValue(val) {
      if (!(val <= 0)) {
        this.val = {
          id: val.toString(),
          code: val.toString()
        }
        this.formula.push(this.val)
      }
      // if (!(val <= 0)) {
      //   if (val && val != '' && this.isVariable) {
      //     this.val = {
      //       id: val.toString(),
      //       code: val.toString()
      //     }
      //     this.formula.push(this.val)
      //     this.formulaValidate()
      //   }
      // }
      else if (val <= 0 && this.isVariable) {
        this.errorMsg = 'Value must be greater than 0.'
      }
      else {
        this.errorMsg = ''
      }
    },
    applyVariable(obj) {
      // if (this.isVariable) {
      //   this.formula.push(obj)
      //   this.formulaValidate()
      // }
      this.formula.push(obj)
    },
    applyOperator(obj) {
      // let lastVal = this.formula[this.formula.length - 1]
      // if (!this.isVariable || obj.code == '(' || obj.code == ')') {
      //   this.formula.push(obj)
      //   this.formulaValidate()
      // }
      this.formula.push(obj)
    },
    clearFormula() {
      this.formula.splice(0, this.formula.length)
      this.isVariable = true
    },
    popFormula() {
      this.formula.pop()
      // this.formulaValidate()
    },
    confirm() {
      this.$emit('input', this.formula)
      this.$parent.close()
    },
    close() {
      this.$emit('input', this.prevFormula)
      this.$parent.close()
    }
  }
}
</script>

<style>

</style>