<template>
  <div>
    <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
    <div class="columns header" style="margin-bottom: 0 !important;">
      <div class="column is-12" style="margin-left: 5px; font-size: 20px;">
        <b>Reports </b>- Print or view reports
      </div>
    </div>
    <hr style="margin-top: 0 !important; margin-bottom: 0 !important;" class="has-background-grey-lighter">
    <br>
    <div class="columns header" style="margin-bottom: 0 !important;">
      <div class="column is-5">
        <b-field>
          <div class="select is-fullwidth">
            <select placeholder="Select Citizenship" @change="table">
              <option value="select"></option>
              <optgroup label="Employee" v-if="$role.state.permissions['print employee']">
                <option value="Pds">Personal Datasheet</option>
                <option value="Coe">Certification of Employment</option>
                <!-- <option value="Ueo">Update Employees Organization</option>
                <option value="Io">Import Organization</option> -->
              </optgroup>
              <optgroup label="Position" v-if="$role.state.permissions['print position']">
                <option value="Pdf">Position Description Form</option>
              </optgroup>
              <optgroup label="Service Record" v-if="$role.state.permissions['print service record']">
                <option value="Sr">Service Record</option>
                <option value="Mr">Management Report</option>
                <option value="Pmr">Quarterly Report on Personnel Movement</option>
                <option value="Pc">Personnel Complement</option>
                <option value="Dpc">Detailed Personnel Complement</option>
                <option value="Pipd">Personnel Inventory (Per Division)</option>
                <option value="Jopi">Job Order Personnel Inventory</option>
                <!-- <option value="">Personnel Complement // duplicate</option> -->
                <option value="Cspi">Contract of Service Personnel Inventory</option>
              </optgroup>
              <optgroup label="Request" v-if="$role.state.permissions['print leave ledger']">
                <option value="Salary">Report of Salary Deduction/Cancellation from/Restoration to Payroll</option>
                <option value="Balance">Leave Balance Report</option>
                <option value="Cwp">Certification Without Pay</option>
                <option value="Clc">Certification Leave Credits</option>
                <!-- <option value="">Report of Salary Deduction/Cancellation from/Restoration to Payroll</option> -->
                <option value="Monetization">Monetization</option>
                <option value="Slr">Summary of approved/disapproved/cancelled leave and CTO requests</option>
                <!-- <option value="Approved">Summary of approved leave & CTO requests</option> -->
                <option value="YearVlSl">Year-end list of employees unused VL and SL commutation</option>
                <option value="Negative">Monthly summary of employees with negative VL and SL credits</option>
                <option value="Overtime">Summary of employees with approved request to render overtime</option>
                <option value="Night">Summary of employees with approved request for night differential pay</option>
              </optgroup>
              <optgroup label="Appraisal" v-if="$role.state.permissions['print appraisal']">
                <option value="Appraisal">Appraisal Summary Report</option>
              </optgroup>
              <optgroup label="Relations" v-if="$role.state.permissions['print award'] || $role.state.permissions['print offense']">
                <option value="Relations">Summary Report</option>
              </optgroup>
              <optgroup label="Payroll" v-if="($role.state.permissions['print payroll'] || $role.state.permissions['print payroll funding source'])">
                <option value="Payroll">Payroll</option>
              </optgroup>
            </select>
          </div>
        </b-field>
      </div>
    </div>
    <Pds v-if="this.location == 'Pds'" />
    <Pdf v-if="this.location == 'Pdf'" />
    <Sr v-if="this.location == 'Sr'" />
    <Mr v-if="this.location == 'Mr'" />
    <Pmr v-if="this.location == 'Pmr'" />
    <Pipd v-if="this.location == 'Pipd'" />
    <Cspi v-if="this.location == 'Cspi'" />
    <Jopi v-if="this.location == 'Jopi'" />
    <Pc v-if="this.location == 'Pc'" />
    <Dpc v-if="this.location == 'Dpc'" />
    <Salary v-if="this.location == 'Salary'" />
    <Balance v-if="this.location == 'Balance'" />
    <Cwp v-if="this.location == 'Cwp'" />
    <Clc v-if="this.location == 'Clc'" />
    <Coe v-if="this.location == 'Coe'" />
    <Ueo v-if="this.location == 'Ueo'" />
    <Io v-if="this.location == 'Io'" />
    <Approved v-if="this.location == 'Approved'" />
    <Slr v-if="this.location == 'Slr'" />
    <Unused v-if="this.location == 'Unused'" />
    <Negative v-if="this.location == 'Negative'" />
    <Overtime v-if="this.location == 'Overtime'" />
    <Night v-if="this.location == 'Night'" />
    <Appraisal v-if="this.location == 'Appraisal'" />
    <Monetization v-if="this.location == 'Monetization'" />
    <YearVlSl v-if="this.location == 'YearVlSl'" />
    <Payroll v-if="this.location == 'Payroll'" />
    <Relations v-if="this.location == 'Relations'" />

    <!-- <SalaryGrades v-if="this.location == 'Salary Grades'" />
    <Division v-if="this.location == 'Division'" :divisions="divisions" @reset="setDivision" :isPos="false"/>
    <Equipment v-if="this.location == 'Equipment'" :equipments="equipments" @reset="setEquipment" /> -->
  </div>
</template>

<script>
import Pds from '../components/reports/Pds.vue'
import Pdf from '../components/reports/Pdf.vue'
import Sr from '../components/reports/Sr.vue'
import Mr from '../components/reports/Mr.vue'
import Pmr from '../components/reports/Pmr.vue'
import Pipd from '../components/reports/Pipd.vue'
import Cspi from '../components/reports/Cspi.vue'
import Jopi from '../components/reports/Jopi.vue'
import Pc from '../components/reports/Pc.vue'
import Dpc from '../components/reports/Dpc.vue'
import Salary from '../components/reports/Salary.vue'
import Balance from '../components/reports/Balance.vue'
import Cwp from '../components/reports/Cwp.vue'
import Clc from '../components/reports/Clc.vue'
import Coe from '../components/reports/Coe.vue'
import Ueo from '../components/reports/Ueo.vue'
import Io from '../components/reports/Io.vue'
import Approved from '../components/reports/Approved.vue'
import Slr from '../components/reports/Slr.vue'
import Unused from '../components/reports/Unused.vue'
import Negative from '../components/reports/Negative.vue'
import Overtime from '../components/reports/Overtime.vue'
import Night from '../components/reports/Night.vue'
import Appraisal from '../components/reports/Appraisal.vue'
import Monetization from '../components/reports/Monetization.vue'
import YearVlSl from '../components/reports/YearVlSl.vue'
import Payroll from '../components/reports/Payroll.vue'
import Relations from '../components/reports/Relations.vue'

export default {
  data() {
    return {
      isLoading: false,
      location: null,
    }
  },
  components: {
    Pds,
    Pdf,
    Sr,
    Mr,
    Pmr,
    Pipd,
    Cspi,
    Jopi,
    Pc,
    Dpc,
    Salary,
    Balance,
    Cwp,
    Clc,
    Coe,
    Ueo,
    Io,
    Approved,
    Slr,
    Unused,
    Negative,
    Overtime,
    Night,
    Appraisal,
    Monetization,
    YearVlSl,
    Payroll,
    Relations
  },
  mounted() {
    this.location = 'select'
  },
  methods: {
    // printMR() {
    //   let url = `${this.$host}/api/report/mr/`
    //   let win = window.open(url, '_blank')
    //   win.focus()
    // },
    // printPMR() {
    //   let url = `${this.$host}/api/report/pmr/`
    //   let win = window.open(url, '_blank')
    //   win.focus()
    // },
    // printPID() {
    //   let url = `${this.$host}/api/report/pid/`
    //   let win = window.open(url, '_blank')
    //   win.focus()
    // },
    // printCSPI() {
    //   let url = `${this.$host}/api/report/cspi/`
    //   let win = window.open(url, '_blank')
    //   win.focus()
    // },
    table(e) {
      //console.log(e.target.value);
      if (e.target.value == 'Pds') {
        this.location = e.target.value
      }
      else if (e.target.value == 'Pdf') {
        this.location = e.target.value
      }
      else if (e.target.value == 'Sr') {
        this.location = e.target.value
      }
      else if (e.target.value == "Mr") {
        this.location = e.target.value
      }
      else if (e.target.value == "Pmr") {
        this.location = e.target.value
      }
      else if (e.target.value == "Pipd") {
        this.location = e.target.value
      }
      else if (e.target.value == "Cspi") {
        this.location = e.target.value
      }
      else if (e.target.value == 'Jopi') {
        this.location = e.target.value
      }
      else if (e.target.value == 'Pc') {
        this.location = e.target.value
      }
      else if (e.target.value == 'Dpc') {
        this.location = e.target.value
      }
      else if (e.target.value == 'Salary') {
        this.location = e.target.value
      }
      else if (e.target.value == 'Balance') {
        this.location = e.target.value
      }
      else if (e.target.value == 'Cwp') {
        this.location = e.target.value
      }
      else if (e.target.value == 'Clc') {
        this.location = e.target.value
      }
      else if (e.target.value == 'Coe') {
        this.location = e.target.value
      }
      else if (e.target.value == 'Ueo') {
        this.location = e.target.value
      }
      else if (e.target.value == 'Appraisal') {
        this.location = e.target.value
      }
      else if (e.target.value == 'Monetization') {
        this.location = e.target.value
      }
      else if (e.target.value == 'YearVlSl') {
        this.location = e.target.value
      }
      else if (e.target.value == 'Payroll') {
        this.location = e.target.value
      }
      else if (e.target.value == 'Slr') {
        this.location = e.target.value
      }
      else if (e.target.value == 'Negative') {
        this.location = e.target.value
      }
      else if (e.target.value == 'Overtime') {
        this.location = e.target.value
      }
      else if (e.target.value == 'Night') {
        this.location = e.target.value
      }
      else if (e.target.value == 'Relations') {
        this.location = e.target.value
      }
      else if (e.target.value == '') {
        this.location = e.target.value
      }
      else {
        this.location = 'select'
      }
    },
  }
}
</script>
