<template>
  <div>
    <button class="button is-primary" @click.prevent="addWork()" :disabled="isDisabled">
      Add Voluntary Work
    </button>
    <b-table
      :data="data"
      focusable>  
      <template slot-scope="props">
        <b-table-column label="Organization">
          {{ props.row.organization }}
        </b-table-column>
        <b-table-column label="Date From">
          {{ dateFormat(props.row.date_from) }}
        </b-table-column>
        <b-table-column label="Date To">
          {{ dateFormatNull(props.row.date_to) }}
        </b-table-column>
        <b-table-column label="Number of Hours">
          {{ props.row.number_of_hours }}
        </b-table-column>
        <b-table-column label="Position">
          {{ props.row.position }}
        </b-table-column>
        <b-table-column label="Nature of Work">
          {{ props.row.nature_of_work }}
        </b-table-column>
        <b-table-column label="Actions">
          <button class="button is-small btn-action is-primary" @click.prevent="editRow(props.row)" :disabled="isDisabled"><i class="fa fa-pen"></i></button>
          <button class="button is-small btn-action is-danger" @click.prevent="deleteRow(props.row)" :disabled="isDisabled"><i class="fa fa-trash"></i></button>
        </b-table-column>
      </template>
      <template slot="empty">
        <b-table-column label="Organization">
        </b-table-column>
        <b-table-column label="Date From">
        </b-table-column>
        <b-table-column label="Date To">
        </b-table-column>
        <b-table-column label="Number of Hours">
        </b-table-column>
        <b-table-column label="Position">
        </b-table-column>
        <b-table-column label="Nature of Work">
        </b-table-column>
        <b-table-column label="Actions">
        </b-table-column>
      </template>
    </b-table>
    <b-modal :active.sync="isComponentModalActive" has-modal-card :canCancel=false>
      <transition name="fade">
        <addedit-form :obj="work" :state="state" @save="saveWork" @cancel="cancel"></addedit-form>
      </transition>
    </b-modal>
  </div>
</template>

<script>
const addeditForm = {
  props: {
    obj: Object,
    state: String
  },
  template: `
  <form @submit.prevent="$emit('save', obj)">
    <div class="modal-card" style="height: 35.625rem; margin-top: 4rem;">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ state }}</p>
      </header>
      <section class="modal-card-body">
        <b-field label="Organization">
          <b-autocomplete
            v-model="obj.organization"
            :data="filteredOrganization"
            placeholder="Organization"
            required>
          </b-autocomplete>
        </b-field>
        <b-field label="Date From">
          <b-datepicker
            placeholder="Click to select..."
            icon="calendar-today"
            v-model="obj.date_from"
            :date-formatter="d => d.toLocaleDateString('en-GB')"
            :date-parser="d => parseDate(d)"
            editable
            required>
          </b-datepicker>
        </b-field>
        <b-field label="Date To">
          <b-datepicker
            placeholder="Click to select..."
            icon="calendar-today"
            v-model="obj.date_to"
            :date-formatter="d => d.toLocaleDateString('en-GB')"
            :date-parser="d => parseDate(d)"
            editable>
          </b-datepicker>
        </b-field>
        <b-field label="Number of Hours">
          <b-input v-model="obj.number_of_hours" required></b-input>
        </b-field>
        <b-field label="Position">
          <b-autocomplete
            v-model="obj.position"
            :data="filteredPosition"
            placeholder="Position"
            required>
          </b-autocomplete>
        </b-field>
        <b-field label="Nature of Work">
          <b-autocomplete
            v-model="obj.nature_of_work"
            :data="filteredNature"
            placeholder="Nature of Work"
            required>
          </b-autocomplete>
        </b-field>
        <br>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-success" type="submit">{{ state }}</button>
        <button class="button is-danger" @click.prevent="$emit('cancel')">Cancel</button>
      </footer>
    </div>
  </form>`,
  computed: {
    filteredOrganization() {
      return this.$suggestions.state.volunteerings.organization.filter((option) => {
        return option
          .toString()
          .toLowerCase()
          .indexOf(this.obj.organization.toLowerCase()) >= 0
      })
    },
    filteredPosition() {
      return this.$suggestions.state.volunteerings.position.filter((option) => {
        return option
          .toString()
          .toLowerCase()
          .indexOf(this.obj.position.toLowerCase()) >= 0
      })
    },
    filteredNature() {
      return this.$suggestions.state.volunteerings.nature_of_work.filter((option) => {
        return option
          .toString()
          .toLowerCase()
          .indexOf(this.obj.nature_of_work.toLowerCase()) >= 0
      })
    }
  },
  methods: {
    parseDate(d) {
      let parts = d.split("/")
      let dt = new Date(parseInt(parts[2], 10),
        parseInt(parts[1], 10) - 1,
        parseInt(parts[0], 10))
      return dt
    },
  }
}

import VueSimpleSuggest from 'vue-simple-suggest'
import '../../../css/styles.css'

export default {
  props: ["isDisabled", "data"],
  data() {
    return {
      isComponentModalActive: false,
      work: {},
      state: '',
      previousData: {}
    }
  },
  components: {
    addeditForm,
    VueSimpleSuggest
  },
  methods: {
    dateFormat(d) {
      d = new Date(d)
      return d.toLocaleDateString('en-GB')
    },
    dateFormatNull(d) {
      if (!d) {
        return 'Present'
      }
      else {
        d = new Date(d)
        return d.toLocaleDateString('en-GB')
      }
    },
    returnData() {
      let voluntary = {
        volunteerings: this.data
      }
      this.$emit('voluntary', voluntary)
    },
    addWork() {
      this.work = {
        id: null,
        organization: '',
        date_from: new Date(),
        date_to: new Date(),
        number_of_hours: '',
        position: '',
        nature_of_work: ''
      }
      this.state = 'Add'
      this.isComponentModalActive = true
    },
    saveWork(obj) {
      if (this.state == 'Add') {
        this.data.push({
          id: obj.id,
          organization: obj.organization,
          date_from: obj.date_from,
          date_to: obj.date_to,
          number_of_hours: obj.number_of_hours,
          position: obj.position,
          nature_of_work: obj.nature_of_work
        });
      }
      this.close()
    },
    close() {
      this.isComponentModalActive = false
    },
    cancel() {
      this.isComponentModalActive = false
      if (this.state == 'Edit') {
        let index = this.data.findIndex(x => x.id === this.previousData.id)
        this.data[index] = this.previousData
      }
    },
    editRow(obj) {
      this.work = obj
      this.work.date_from = new Date(obj.date_from)
      if (obj.date_to) {
        this.work.date_to = new Date(obj.date_to)
      }
      this.state = 'Edit'
      this.previousData = JSON.parse(JSON.stringify(obj))
      this.isComponentModalActive = true
    },
    deleteRow(obj) {
      this.data = this.data.filter(x => {
        return x != obj;
      })
    },
  }
}
</script>
