<template>
  <div class="box">
    <div>
      <b-input v-model="search_query" placeholder="Search Division" class="is-pulled-right"/>
    </div>
    <b-table
      :data="filter"
      :striped=true
      :bordered=true
      :hoverable=true
      :selected.sync="selected"
      :paginated="true"
      :per-page="5">  
      <template slot-scope="props">
        <b-table-column label="Code" width="150">
          {{ props.row.code }}
        </b-table-column>
        <b-table-column label="Name" width="150">
          {{ props.row.name }}
        </b-table-column>
        <b-table-column label="Description" width="150">
          {{ props.row.description }}
        </b-table-column>
        <b-table-column label="Office Group" width="150">
          <span v-if="props.row.offices">{{ props.row.offices.name }} ({{ props.row.offices.code }})</span>
        </b-table-column>
      </template>
      <template slot="empty">
        <b-table-column label="Code">
        </b-table-column>
        <b-table-column label="Name">
        </b-table-column>
        <b-table-column label="Description">
        </b-table-column>
        <b-table-column label="Office Group">
        </b-table-column>
      </template>
    </b-table>
    <button class="button is-primary" @click.prevent="$emit('confirm', selected)" v-if="isPos">
      Confirm
    </button>
    <button class="button is-danger" @click.prevent="$emit('close')" v-if="isPos">
      Cancel
    </button>
  </div>
</template>

<script>
export default {
  props: ['divisions', 'isPos'],
  data() {
    return {
      isComponentModalActive: false,
      data: {},
      state: '',
      selected: {},
      search_query: ''
    }
  },
  computed: {
    filter() {
      var i;
      var name_re = new RegExp(this.search_query, 'i')
      var data = []
      for (i in this.divisions) {
        if ([this.divisions[i].name, this.divisions[i].code].join(' ').match(name_re)) {
          data.push(this.divisions[i])
        }
      }
      return data
    }
  },
  methods: {
    close() {
      this.isComponentModalActive = false
      this.$emit('reset')
    }
  }
}
</script>