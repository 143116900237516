<template>
  <div v-if="$role.state.permissions['write payroll'] || $role.state.permissions['write payroll funding source']">
    <div class="columns header" style="margin-bottom: 0 !important;">
      <div class="column is-5" style="margin-left: 5px; font-size: 20px;">
        <b>PAYROLL FINALIZE</b>
      </div>
      <div class="column is-auto">
        <button class="button is-primary is-hidden-tablet" style="margin-left: 10px;" @click.prevent="$router.push('/payroll')"
        v-if="$role.state.permissions['write payroll'] || $role.state.permissions['write payroll funding source']">
          <i class="fa fa-file-invoice-dollar"></i> &nbsp;Payroll Home
        </button>

        <button class="button is-primary is-pulled-right is-hidden-mobile" style="margin-left: 10px;" @click.prevent="$router.push('/payroll')"
        v-if="$role.state.permissions['write payroll'] || $role.state.permissions['write payroll funding source']">
          <i class="fa fa-file-invoice-dollar"></i> &nbsp;Payroll Home
        </button>
      </div>
    </div>
    <hr style="margin-top: 0 !important; margin-bottom: 0 !important;" class="has-background-grey-lighter is-hidden-mobile">
    <br>
    <div class="box">
      <!-- <b>Payroll Records</b>
      <hr> -->
      <b-field grouped group-multiline>
        <b-field label="Pagination">
          <b-select placeholder="Per Page" v-model="perPage">
            <option value="5">5 per page</option>
            <option value="10">10 per page</option>
            <option value="20">20 per page</option>
            <option value="50">50 per page</option>
          </b-select>
        </b-field>
        <b-field label="Payroll Type">
          <b-select v-model="payroll_type" @input="getPayroll">
            <option value="">ALL</option>
            <option value="salary">SALARY</option>
            <option value="special">OTHER BENEFITS</option>
            <option value="voucher">VOUCHER</option>
          </b-select>
        </b-field>
        <b-field label="Earning" expanded required v-if="payroll_type == 'special'">
          <b-select v-model="earning" @input="getPayroll">
            <option
              v-for="earning in earnings"
              :value="earning.id"
              :key="earning.id">
              {{ earning.code }}
            </option>
          </b-select>
        </b-field>
        <b-field label="Voucher" v-if="payroll_type == 'voucher'">
          <b-select v-model="voucher" expanded required @input="getPayroll">
            <option
              v-for="voucher in vouchers"
              :value="voucher.id"
              :key="voucher.id">
              {{ voucher.code }}
            </option>
          </b-select>
        </b-field>
        <b-field label="Payroll Group">
          <b-select v-model="payroll_group" @input="getPayroll">
            <option value="">ALL</option>
            <option value="1">PERM/PA/TEMP/CO-TERM</option>
            <option value="2">CASUALS/CONTRACTUAL</option>
            <option value="3">CONTRACT OF SERVICE</option>
            <option value="4">JOB ORDER</option>
          </b-select>
        </b-field>
        <b-field label="Payroll Subgroup" v-if="payroll_group == '1'">
          <b-select placeholder="Payroll Group" v-model="payroll_subgroup"  @input="getPayroll">
            <option value="">All</option>
            <option value="1">Presidential Appointee</option>
            <option value="2">Permanent</option>
            <option value="3">Co-Terminus </option>
            <option value="4">Temporary </option>
          </b-select>
        </b-field>
        <b-field label="Payroll Subgroup" v-if="payroll_group == '2'">
          <b-select placeholder="Payroll Group" v-model="payroll_subgroup"  @input="getPayroll">
            <option value="">All</option>
            <option value="9">Casuals</option>
            <option value="6">Contractual</option>
          </b-select>
        </b-field>
        <b-field label="Funding Source" v-if="((payroll_group == '3' || payroll_group == '4') && $role.state.permissions['write payroll'])">
          <b-select v-model="payroll_subgroup" @input="getPayroll">
            <option value="">All</option>
            <option v-for="source in sources" :key="source.id" :value="source.id">{{ source.code }}</option>
          </b-select>
        </b-field>
        <!-- <b-select placeholder="PERIOD">
          <option value=""></option>
        </b-select> -->
        <b-field label="Month">
          <b-select v-model="month" @input="getPayroll">
            <option value="1">JANUARY</option>
            <option value="2">FEBRUARY</option>
            <option value="3">MARCH</option>
            <option value="4">APRIL</option>
            <option value="5">MAY</option>
            <option value="6">JUNE</option>
            <option value="7">JULY</option>
            <option value="8">AUGUST</option>
            <option value="9">SEPTEMBER</option>
            <option value="10">OCTOBER</option>
            <option value="11">NOVEMBER</option>
            <option value="12">DECEMBER</option>
          </b-select>
        </b-field>
        <b-field label="Year">
          <b-input placeholder="Year" type="number" @input="getPayroll" v-model="year"></b-input>
        </b-field>
      </b-field>
      <b-field label="Status">
        <b-select v-model="finalize_status" @input="getPayroll">
          <option value="0">Unfinalized</option>
          <option value="1">Finalized</option>
        </b-select>
      </b-field>

      <b-table
        :data="payroll"
        :striped=true
        :bordered=true
        :hoverable=true
        :paginated="true"
        :per-page="perPage">
        <template slot-scope="props">
          <b-table-column label="Reference Date">
            {{ props.row.ref_date }}
          </b-table-column>
          <b-table-column label="Payroll Group">
            {{ setGroup(props.row.payroll_group) }}
          </b-table-column>
          <b-table-column label="Payroll Type">
            {{ props.row.payroll_type }}
          </b-table-column>
          <b-table-column label="Amount">
            {{ props.row.amount }}
          </b-table-column>
          <b-table-column label="View">
            <button class="button btn-action is-primary is-small" @click.prevent="viewLedger(props.row)"><i class="fa fa-search"></i></button>
          </b-table-column>
        </template>
        <template slot="empty">
          <b-table-column label="Reference Date">
          </b-table-column>
          <b-table-column label="Payroll Group">
          </b-table-column>
          <b-table-column label="Payroll Type">
          </b-table-column>
          <b-table-column label="Amount">
          </b-table-column>
          <b-table-column label="View">
          </b-table-column>
        </template>
      </b-table>
    </div>

    <b-modal :active.sync="isView" has-modal-card :canCancel=false>
      <transition name="fade">
        <form>
          <div class="modal-card pos" style="width: 60rem; height: 35.625rem; margin-left: 100px;">
            <header class="modal-card-head">
              <p class="modal-card-title">{{ ledgerPeriod }} {{ ledgerType }}</p>
            </header>
            <section class="modal-card-body">
              <b-field grouped group-multiline>
                <b-input v-model="search" placeholder="Search" size="is-small" />
                <b-select placeholder="Per Page" v-model="perPageLedger" size="is-small">
                  <option value="50">50 per page</option>
                  <option value="100">100 per page</option>
                  <option value="150">150 per page</option>
                  <option value="200">200 per page</option>
                </b-select>
                <b-select placeholder="finalize" v-model="is_finalize" size="is-small" @input="getLedger()">
                  <option value="">ALL</option>
                  <option value="0">NOT FINALIZED</option>
                  <option value="1">FINALIZED</option>
                </b-select>
                <b-select placeholder="status" v-model="status" size="is-small" @input="getLedger()" v-if="ledgerGroup == 1">
                  <option value="">ALL</option>
                  <option value="1">PERMANENT</option>
                  <option value="2">PA</option>
                  <option value="3">TEMPORARY</option>
                  <option value="4">COTERMINUS</option>
                </b-select>
                <b-field>
                  <button class="button btn-action is-primary is-small" v-if="finalize_status == 1" @click.prevent="selectAllFinalized()">Select All</button>
                </b-field>
                <b-field>
                  <button class="button btn-action is-primary is-small" v-if="finalize_status == 0" @click.prevent="selectAllUnfinalized()">Select All</button>
                </b-field>
                <b-field>
                  <button class="button btn-action is-primary is-small" @click.prevent="unselectAll()">Unselect All</button>
                </b-field>
              </b-field>
              <b-table
                :data="filter"
                :striped=true
                :bordered=true
                :hoverable=true
                :paginated="true"
                :per-page="perPageLedger"
                :checked-rows.sync="checkedRows"
                :checkable=true>
                <template slot-scope="props">
                  <b-table-column label="Full Name">
                    {{ props.row.payslip.name }}
                  </b-table-column>
                  <b-table-column label="Division">
                    {{ props.row.payslip.division }}
                  </b-table-column>
                  <b-table-column label="Gross Pay">
                    {{ props.row.gross_pay }}
                  </b-table-column>
                  <b-table-column label="Total Deductions">
                    {{ props.row.total_deductions }}
                  </b-table-column>
                  <b-table-column label="Net Pay">
                    {{ props.row.net_pay }}
                  </b-table-column>
                  <b-table-column label="Actions">
                    <button class="button btn-action is-primary is-small" @click.prevent="viewPayslip(props.row)"><i class="fa fa-receipt"></i></button>
                  </b-table-column>
                </template>
                <template slot="empty">
                  <b-table-column label="Full Name">
                  </b-table-column>
                  <b-table-column label="Division">
                  </b-table-column>
                  <b-table-column label="Gross Pay">
                  </b-table-column>
                  <b-table-column label="Total Deductions">
                  </b-table-column>
                  <b-table-column label="Net Pay">
                  </b-table-column>
                  <b-table-column label="Actions">
                  </b-table-column>
                </template>
              </b-table>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" type="button" v-if="finalize_status == 0" @click.prevent="finalizePrompt()">FINALIZE SELECTED</button>
              <button class="button is-success" type="button" v-if="finalize_status == 1" @click.prevent="unfinalizePrompt()">UNFINALIZE SELECTED</button>
              <button class="button is-danger" type="button" @click.prevent="isView = false">Close</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>

    <b-modal :active.sync="isPayslip" has-modal-card :canCancel=false>
        <transition name="fade">
          <form>
            <div class="modal-card pos" style="width: 60rem; height: 35.625rem; margin-left: 100px;">
              <header class="modal-card-head">
                <p class="modal-card-title">View Payslip</p>
              </header>
              <section class="modal-card-body">
                <div class="columns is-vcentered is-centered" style="font-size: 13px;">
                  <div class="column is-narrow is-hidden-touch" style="padding: 0px !important;">
                    <img src="/img/dotr-logo.png" width="40"/>
                  </div>
                  <div class="column is-narrow">
                    <div>
                      <span style="text-align: center;"><b>Department of Transporation<br></b></span>
                      <span style="text-align: center;"><b>Employee Pay Slip</b></span>
                    </div>
                  </div>
                </div>
                <hr style="margin-top: 0px; margin-bottom: 10px; background-color: grey">
                <div class="columns" style="font-size: 13px;">
                  <div class="column is-6">
                    <b>Employee Name:</b> {{ payslip.name }} <br>
                    <b>Organization Unit:</b> {{ payslip.division }}
                  </div>
                  <div class="column is-6">
                    <b>Employee Number:</b> {{ payslip.employee_number }} <br>
                    <b>Pay Period:</b> {{ payslip.payroll_period }}
                  </div>
                </div>
                <div class="columns" style="font-size: 13px; min-height: 14.5rem;">
                  <div class="column is-3">
                    <b>Basic Pay</b>
                    <hr style="margin-top: 10px; margin-bottom: 10px; background-color: grey">
                    <table style="border: 0px; width: 100%;">
                      <tr v-for="option in payslip.basic_pay" :key="option.key">
                        <td>{{ option.key }} <a @click.prevent="showDTR(option.dtr)" v-if="option.dtr"><i class="far fa-calendar"></i></a></td>
                        <td style="text-align: right;">{{ option.value }}</td>
                      </tr>
                    </table>
                  </div>
                  <div class="column is-3">
                    <b>Other Earnings</b>
                    <hr style="margin-top: 10px; margin-bottom: 10px; background-color: grey">
                    <table style="border: 0px; width: 100%;">
                      <tr v-for="option in payslip.other_earnings" :key="option.key">
                        <td>{{ option.key }} <a @click.prevent="showDTR(option.dtr)" v-if="option.dtr"><i class="far fa-calendar"></i></a></td>
                        <td style="text-align: right;">{{ option.value }}</td>
                      </tr>
                    </table>
                  </div>
                  <div class="column is-3">
                    <b>Regular Deductions</b>
                    <hr style="margin-top: 10px; margin-bottom: 10px; background-color: grey">
                    <table style="border: 0px; width: 100%;">
                      <tr v-for="option in payslip.regular_deductions" :key="option.key">
                        <td>{{ option.key }}</td>
                        <td style="text-align: right;">{{ option.value }}</td>
                      </tr>
                    </table>
                  </div>
                  <div class="column is-3">
                    <b>Loan and Other Deductions</b>
                    <hr style="margin-top: 10px; margin-bottom: 10px; background-color: grey">
                    <table style="border: 0px; width: 100%;">
                      <tr v-for="option in payslip.other_deductions" :key="option.key">
                        <td>{{ option.key }} <a @click.prevent="showDTR(option.dtr)" v-if="option.dtr"><i class="far fa-calendar"></i></a></td>
                        <td style="text-align: right;">{{ option.value }}</td>
                      </tr>
                    </table>
                  </div>
                </div>
                <hr style="margin-top: 0px; margin-bottom: 10px; background-color: grey">
                <div class="columns" style="font-size: 13px;">
                  <div class="column is-3" style="padding-bottom: 0rem;">
                    <b>Gross Pay:</b> {{ payslip.gross_pay }}
                  </div>
                  <div class="column is-3" style="padding-bottom: 0rem;">
                    <b>Total Deduction:</b> {{ payslip.total_deductions }}
                  </div>
                  <div class="column is-3" style="padding-bottom: 0rem;">
                  </div>
                  <div class="column is-3" style="padding-bottom: 0rem;">
                    <b>Net Pay:</b> {{ payslip.net_pay }}
                  </div>
                </div>
                <div class="columns" style="font-size: 13px;" v-if="employment_status<10 || employment_status>11">
                  <div class="column is-3" style="padding-top: 0rem;">
                  </div>
                  <div class="column is-3" style="padding-top: 0rem;">
                    <b>1 - 15th:</b> {{ payslip['1st_half'] }}
                  </div>
                  <div class="column is-3" style="padding-top: 0rem;">
                  </div>
                  <div class="column is-3" style="padding-top: 0rem;">
                    <b>16 - 31st:</b> {{ payslip['2nd_half'] }}
                  </div>
                </div>
                <hr style="margin-top: 0px; margin-bottom: 10px; background-color: grey">
                <div style="font-size: 12px;">
                  <i>*** This is a system-generated report. If issued without alteration, this does not require a signature. <br>
                  Any data subject for correction/adjustment, please notify Payroll-Treasury Division immediately. ***</i>
                </div>

              </section>
              <footer class="modal-card-foot">
                <button class="button is-danger" @click.prevent="isPayslip = false">Close</button>
              </footer>
            </div>
          </form>
        </transition>
      </b-modal>

      <b-modal :active.sync="isFinalize" has-modal-card :canCancel=false>
      <transition name="fade">
        <form @submit.prevent="finalize()">
          <div class="modal-card" style="max-height: 35.625rem; margin-top: 4rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">Password</p>
            </header>
            <section class="modal-card-body">
              <b-field label="Password">
                <b-input
                  type="password"
                  v-model="password"
                  expanded required>
                </b-input>
              </b-field>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" type="submit">FINALIZE</button>
              <button class="button is-danger" type="button" @click.prevent="closeFinalize()">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>

    <b-modal :active.sync="isUnfinalize" has-modal-card :canCancel=false>
      <transition name="fade">
        <form @submit.prevent="unfinalize()">
          <div class="modal-card" style="max-height: 35.625rem; margin-top: 4rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">Password</p>
            </header>
            <section class="modal-card-body">
              <b-field label="Password">
                <b-input
                  type="password"
                  v-model="password"
                  expanded required>
                </b-input>
              </b-field>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" type="submit">UNFINALIZE</button>
              <button class="button is-danger" type="button" @click.prevent="closeUnfinalize()">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>

    <b-modal :active.sync="isShowDTR" has-modal-card :canCancel=false>
      <transition name="fade">
        <form>
          <div class="modal-card pos" style="width: 60rem; height: 35.625rem; margin-left: 100px;">
            <header class="modal-card-head">
              <p class="modal-card-title">DTR</p>
            </header>
            <section class="modal-card-body">
              <b-select v-model="perPageDTR">
                <option value="5">5 per page</option>
                <option value="10">10 per page</option>
                <option value="15">15 per page</option>
                <option value="20">20 per page</option>
                <option value="31">31 per page</option>
              </b-select>
              <b-table
                :data="dtr.record"
                :striped=true
                :bordered=true
                :hoverable=true
                :paginated="true"
                :per-page="perPageDTR">
                <template slot-scope="props">
                  <b-table-column label="Reference Date">
                    {{ props.row.ref_date }}
                  </b-table-column>
                  <b-table-column label="AM IN">
                    {{ props.row.am.in }}
                  </b-table-column>
                  <b-table-column label="AM OUT">
                    {{ props.row.am.out }}
                  </b-table-column>
                  <b-table-column label="PM IN">
                    {{ props.row.pm.in }}
                  </b-table-column>
                  <b-table-column label="PM OUT">
                    {{ props.row.pm.out }}
                  </b-table-column>
                  <b-table-column label="LATE/UT/HD">
                    {{ props.row.difference }}
                  </b-table-column>
                  <b-table-column label="OT">
                    {{ props.row.ot }}
                  </b-table-column>
                  <b-table-column label="Remarks">
                    {{ props.row.remarks }}
                  </b-table-column>
                </template>
                <template slot="empty">
                  <b-table-column label="Period">
                  </b-table-column>
                </template>
              </b-table>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-danger" @click.prevent="closeDTR()">Close</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>

    <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      config: '',
      payroll: [],
      ledgers: [],
      year: '',
      perPage: 5,
      checkedRows: [],
      isView: false,
      perPageLedger: 50,
      isPayslip: false,
      payslip: {},
      payroll_id: '',
      ledgerPeriod: '',
      ledgerType: '',
      ledgerGroup: '',
      payroll_type: '',
      payroll_group: '',
      password: '',
      isFinalize: false,
      isUnfinalize: false,
      is_finalize: '',
      finalize_status: 0,
      status: '',
      ids: [],
      search: '',
      isShowDTR: false,
      dtr: {},
      earnings: [],
      sources: [],
      perPageDTR: 5,
      voucher: '',
      earning: '',
      month: 1,
      payroll_subgroup: this.$role.state.permissions['finalize payroll']?'':this.$role.state.funding_source_id,
      employment_status: ''
    }
  },
  mounted() {
    this.config = {
      headers: {'Authorization': "Bearer " + this.$store.state.token}
    };
    this.year = new Date().getFullYear()
    this.month = new Date().getMonth()+1
    setTimeout(() => this.getEarnings(), 100)
    this.getPayroll()
  },
  computed: {
    filter() {
      var i;
      var name_re = new RegExp(this.search, 'i')
      var data = []
      for (i in this.ledgers) {
        if ([this.ledgers[i].code, this.ledgers[i].payslip.name, this.ledgers[i].payslip.division, this.ledgers[i].gross_pay, this.ledgers[i].total_deductions, this.ledgers[i].net_pay].join(' ').match(name_re)) {
          data.push(this.ledgers[i])
        }
      }
      return data
    },
  },
  methods: {
    finalizePrompt() {
      this.$dialog.confirm({
        title: 'Run Payroll',
        message: "Are you sure you want to <b>finalize</b> this payroll?",
        confirmText: 'Yes',
        type: 'is-success',
        hasIcon: false,
        onConfirm: () => {
          this.$dialog.confirm({
            title: 'Run Payroll',
            message: "Are you sure <b>really</b> you want to <b>finalize</b> this payroll?",
            confirmText: 'Yes',
            type: 'is-success',
            hasIcon: false,
            onConfirm: () => {
              this.isFinalize = true
            }
          })
        }
      })
    },
    unfinalizePrompt() {
      this.$dialog.confirm({
        title: 'Run Payroll',
        message: "Are you sure you want to <b>unfinalize</b> this payroll?",
        confirmText: 'Yes',
        type: 'is-success',
        hasIcon: false,
        onConfirm: () => {
          this.$dialog.confirm({
            title: 'Run Payroll',
            message: "Are you sure <b>really</b> you want to <b>unfinalize</b> this payroll?",
            confirmText: 'Yes',
            type: 'is-success',
            hasIcon: false,
            onConfirm: () => {
              this.isUnfinalize = true
            }
          })
        }
      })
    },
    finalize() {
      this.isLoading = true
      //console.log(this.checkedRows);
      this.$http.post(`${this.$host}/api/payroll/post/`,
        {
          ids: this.checkedRows.map(x => x = x.id),
          password: this.password,
          finalize_status: this.finalize_status,
        },
        this.config
      ).then((response) => {
        let data = response.data
        this.closeFinalize()
        this.isLoading = false
        this.isView = false
        this.$dialog.alert({
          title: 'Success',
          message: "Selected payroll finalized.",
          type: 'is-success',
          hasIcon: true,
          icon: 'check',
          iconPack: 'fa'
        })
        this.getPayroll()
      }).catch((error) => {
        if (error.response.data == 'wrong password') {
          this.$toast.open({
            message: 'Wrong password.',
            type: 'is-danger',
            position: 'is-bottom-right',
            duration: 4000
          })
        }
        else {
          this.$toast.open({
            message: 'Error finalizing.',
            type: 'is-danger',
            position: 'is-bottom-right',
            duration: 4000
          })
        }
        this.isLoading = false
        //console.log(error)
      });
    },
    unfinalize() {
      this.isLoading = true
      //console.log(this.checkedRows);
      this.$http.post(`${this.$host}/api/payroll/post/`,
        {
          ids: this.checkedRows.map(x => x = x.id),
          password: this.password,
          finalize_status: this.finalize_status,
        },
        this.config
      ).then((response) => {
        let data = response.data
        this.closeUnfinalize()
        this.isLoading = false
        this.isView = false
        this.$dialog.alert({
          title: 'Success',
          message: "Selected payroll unfinalized.",
          type: 'is-success',
          hasIcon: true,
          icon: 'check',
          iconPack: 'fa'
        })
        this.getPayroll()
      }).catch((error) => {
        if (error.response.data == 'wrong password') {
          this.$toast.open({
            message: 'Wrong password.',
            type: 'is-danger',
            position: 'is-bottom-right',
            duration: 4000
          })
        }
        else {
          this.$toast.open({
            message: 'Error unfinalizing.',
            type: 'is-danger',
            position: 'is-bottom-right',
            duration: 4000
          })
        }
        this.isLoading = false
        //console.log(error)
      });
    },
    closeFinalize() {
      this.isFinalize = false
      this.password = ''
    },
    closeUnfinalize() {
      this.isUnfinalize = false
      this.password = ''
    },
    showDTR(dtr) {
      this.dtr = dtr
      this.isShowDTR = true
    },
    closeDTR() {
      this.dtr = {}
      this.isShowDTR = false
    },
    viewPayslip(payroll) {
      // //console.log('payroll_group');
      this.payslip = payroll.payslip
      this.employment_status = payroll.employment_status
      //console.log(this.employment_status);
      this.payroll_id = payroll.id
      this.isPayslip = true
    },
    viewLedger(obj) {
      this.isLoading = true

      this.ids = obj.ids
      this.ledgerPeriod = obj.ref_date
      this.ledgerType = obj.payroll_type
      this.ledgerGroup = obj.payroll_group
      this.$http.post(`${this.$host}/api/payroll/post/find/`,
        {
          ids: this.ids,
          is_finalize: (this.is_finalize != '') ? parseInt(this.is_finalize) : this.is_finalize,
          status: (this.status != '') ? parseInt(this.status) : this.status,
        },
        this.config
      ).then((response) => {
        let data = response.data
        this.ledgers = data
        this.isView = true
        this.isLoading = false
      }).catch((error) => {
        this.isLoading = false
        //console.log(error)
      });
    },
    getLedger() {
      this.isLoading = true

      this.$http.post(`${this.$host}/api/payroll/post/find/`,
        {
          year: this.year,
          month: this.month,
          ids: this.ids,
          is_finalize: (this.is_finalize != '') ? parseInt(this.is_finalize) : this.is_finalize,
          status: (this.status != '') ? parseInt(this.status) : this.status,
        },
        this.config
      ).then((response) => {
        let data = response.data
        this.ledgers = data
        this.checkedRows = []
        this.isLoading = false
      }).catch((error) => {
        this.isLoading = false
        //console.log(error)
      });
    },
    setGroup(group) {
      if (group == 1) {
        return 'PERMANENT/PA/TEMPORARY/CO-TERMINUS EMPLOYEES'
      }
      else if (group == 2) {
        return 'CASUALS/CONTRACTUAL'
      }
      else if (group == 3) {
        return 'CONTRACT OF SERVICE'
      }
      else if (group == 4) {
        return 'JOB ORDER'
      }
    },
    changeYear() {
      this.search_query = ''
      if (this.year.length == 4) {
        this.isLoading = true
        this.$http.get(`${this.$host}/api/payroll/post/list/${this.year}/?payroll_type=${this.payroll_type}&payroll_group=${this.payroll_group}`,
          this.config
        ).then((response) => {
          let data = response.data
          this.payroll = data
          this.isLoading = false
        }).catch((error) => {
          this.isLoading = false
          //console.log(error)
        });
      }
      else if (this.year.length == 0) {
        this.getPayroll()
      }
    },
    selectAllFinalized() {
      this.checkedRows = this.ledgers.filter((x) => x.is_finalize != 0)
    },
    selectAllUnfinalized() {
      this.checkedRows = this.ledgers.filter((x) => x.is_finalize != 1)
    },
    unselectAll() {
      this.checkedRows = []
    },
    getPayroll() {
      this.isLoading = true

      this.$http.get(`${this.$host}/api/payroll/post/list/${this.year}/?month=${this.month}&payroll_type=${this.payroll_type}&payroll_group=${this.payroll_group}&earning=${this.earning}&voucher=${this.voucher}&payroll_subgroup=${this.payroll_subgroup}&finalize_status=${this.finalize_status}`,
        this.config
      ).then((response) => {
        let data = response.data
        this.payroll = data
        this.isLoading = false
      }).catch((error) => {
        this.isLoading = false
      });
    },
    getEarnings() {
      this.isLoading = true
      this.$http.get(`${this.$host}/api/parameter/payroll/run`,
        this.config
      ).then((response) => {
        let data = response.data
        this.earnings = data.earnings
        this.vouchers = data.vouchers
        this.getFundingSources()
      }).catch((error) => {
        this.isLoading = false
        //console.log(error)
      });
    },
    getFundingSources() {
      this.isLoading = true
      this.$http.get(`${this.$host}/api/payroll/funding-source/show/`,
        this.config
      ).then((response) => {
        let data = response.data
        this.sources = data
        this.isLoading = false
      }).catch((error) => {
        this.isLoading = false
        //console.log(error)
      });
    }
  },
}
</script>

<style>

</style>
