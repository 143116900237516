<template>
  <div>
    <div class="content columns box" style="margin-top: -2px; margin-bottom: 1.5rem; padding: 0;">
      <div class="column is-6">
        <b-field label="SPOUSE"></b-field>
        <b-field label="Surname">
          <b-input v-model="data.spouse_last_name" :disabled="isDisabled"></b-input>
        </b-field>
        <div class="columns">
          <div class="column is-8">
            <b-field label="First Name">
              <b-input v-model="data.spouse_first_name" :disabled="isDisabled"></b-input>
            </b-field>
          </div>  
          <div class="column is-4">
            <b-field label="Name Extension">
              <b-input v-model="data.spouse_name_extension" :disabled="isDisabled"></b-input>
            </b-field>  
          </div>
        </div>
        <b-field label="Middle Name">
          <b-input v-model="data.spouse_middle_name" :disabled="isDisabled"></b-input>
        </b-field>
        <b-field label="Occupation">
          <b-input v-model="data.spouse_occupation" :disabled="isDisabled"></b-input>
        </b-field>
        <b-field label="Employer/Business Name">
          <b-input v-model="data.spouse_employer_business" :disabled="isDisabled"></b-input>
        </b-field>
        <b-field label="Business Address">
          <b-input v-model="data.spouse_business_address" :disabled="isDisabled"></b-input>
        </b-field>
        <b-field label="Telephone Number">
          <b-input v-model="data.spouse_telephone" :disabled="isDisabled"></b-input>
        </b-field>
      </div>
      <div class="column is-6">
        <b-field label="FATHER"></b-field>
        <b-field label="Surname">
          <b-input v-model="data.father_last_name" :disabled="isDisabled"></b-input>
        </b-field>
        <div class="columns">
          <div class="column is-8">
            <b-field label="First Name">
              <b-input v-model="data.father_first_name" :disabled="isDisabled"></b-input>
            </b-field>
          </div>  
          <div class="column is-4">
            <b-field label="Name Extension">
              <b-input v-model="data.father_name_extension" :disabled="isDisabled"></b-input>
            </b-field>  
          </div>
        </div>
        <b-field label="Middle Name">
          <b-input v-model="data.father_middle_name" :disabled="isDisabled"></b-input>
        </b-field>
        <b-field label="MOTHER'S MAIDEN NAME"></b-field>
        <!-- <b-field label="Maiden Name">
          <b-input v-model="data.mother_maiden_name" :disabled="isDisabled"></b-input>
        </b-field> -->
        <b-field label="Surname">
          <b-input v-model="data.mother_last_name" :disabled="isDisabled"></b-input>
        </b-field>
        <div class="columns">
          <div class="column is-12">
            <b-field label="First Name">
              <b-input v-model="data.mother_first_name" :disabled="isDisabled"></b-input>
            </b-field>
          </div>  
          <!-- <div class="column is-4">
            <b-field label="Name Extension">
              <b-input v-model="data.mother_name_extension" :disabled="isDisabled"></b-input>
            </b-field>  
          </div> -->
        </div>
        <b-field label="Middle Name">
          <b-input v-model="data.mother_middle_name" :disabled="isDisabled"></b-input>
        </b-field>
      </div>
    </div>
    <div class="content columns box" style="margin-bottom: 1.5rem; padding: 0;">
      <div class="column is-auto">
        <b-field label="CHILDREN"></b-field>
        <b-table
          :data="data.children"
          focusable>  
          <template slot-scope="props">
            <b-table-column label="Name">
              {{ props.row.children_name }}
            </b-table-column>
            <b-table-column label="Birth Date">
              {{ (props.row.birth_date).toLocaleDateString('en-GB') }}
            </b-table-column>
            <b-table-column label="Actions">
              <!-- <button class="button btn-action is-info" @click="editChild(props.row)" :disabled="isDisabled"><i class="fa fa-pen"></i></button> -->
              <button class="button btn-action is-primary" @click.prevent="editRow(props.row)" :disabled="isDisabled"><i class="fa fa-pen"></i></button>
              <button class="button btn-action is-danger" @click.prevent="deleteRow(props.row)" :disabled="isDisabled"><i class="fa fa-trash"></i></button>
            </b-table-column>
          </template>
          <template slot="empty">
            <b-table-column label="Children">
            </b-table-column>
            <b-table-column>
            </b-table-column>
          </template>
        </b-table>
        <button class="button is-primary" @click.prevent="addChild()" :disabled="isDisabled">
          Add Child
        </button>
        <!-- <b-modal :active.sync="childrenModal" has-modal-card>
          <children v-bind="childrenProps" @edit="editedChild"></children>
        </b-modal> -->
      </div>
    </div>
    <b-modal :active.sync="isComponentModalActive" has-modal-card :canCancel=false>
      <transition name="fade">
        <addedit-form :obj="child" :state="state" @save="saveChild" @close="closeChild"></addedit-form>
      </transition>
    </b-modal>
  </div>
</template>

<script>
const addeditForm = {
  props: {
    obj: Object,
    state: String
  },
  template: `
  <form @submit.prevent="$emit('save', obj)">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ state }}</p>
      </header>
      <section class="modal-card-body">
        <b-field label="Name">
          <b-input v-model="obj.children_name" required></b-input>
        </b-field>
        <b-field label="Birth Date">
          <b-datepicker
            placeholder="Click to select..."
            icon="calendar-today"
            v-model="obj.birth_date"
            :date-formatter="d => d.toLocaleDateString('en-GB')"
            :date-parser="d => parseDate(d)"
            :focused-date="new Date()"
            editable
            required>
          </b-datepicker>
        </b-field>
        <br><br><br><br><br>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-success" type="submit">{{ state }}</button>
        <button class="button is-danger" @click.prevent="$emit('close')" v-if="state == 'Add'">Cancel</button>
      </footer>
    </div>
  </form>`,
  methods: {
    parseDate(d) {
      let parts = d.split("/")
      let dt = new Date(parseInt(parts[2], 10),
        parseInt(parts[1], 10) - 1,
        parseInt(parts[0], 10))
      return dt
    },
  }
}

import Children from './add_edit/Children.vue'
import moment from 'moment'

export default {
  components: {
    Children,
    addeditForm
  },
  props: ["isDisabled", "data"],
  data () {
    return {
      spouse_last_name: '',
      spouse_first_name: '',
      spouse_name_extension: '',
      spouse_middle_name: '',
      spouse_occupation: '',
      spouse_telephone: '',
      father_last_name: '',
      father_first_name: '',
      father_middle_name: '',
      father_name_extension: '',
      spouse_employer_business: '',
      spouse_business_address: '',
      mother_maiden_name: '',
      mother_last_name: '',
      mother_first_name: '',
      mother_middle_name: '',
      state: '',
      isComponentModalActive: false,
      child: {
        id: null,
        children_name: '',
        birth_date: ''
      },
      // childrenModal: false,
      // childrenProps: {},
    }
  },
  mounted() {
    this.getData()
    // for (let i = 0; i < this.data.children.length; i++) {
    //   this.data.children[i].birth_date = new Date(this.data.children[i].birth_date)
    // }
  },
  methods: {
    returnData() {
      delete this.data.education
      delete this.data.eligibilities
      delete this.data.workexperiences
      delete this.data.volunteerings
      delete this.data.trainings
      this.$emit('family', this.data)
    },
    addChild() {
      this.child = {id: null, children_name: '', birth_date: new Date()}
      this.state = 'Add'
      this.isComponentModalActive = true
    },
    saveChild(obj) {
      if (this.state == 'Add') {
        this.data.children.push({id: obj.id, children_name: obj.children_name, birth_date: obj.birth_date});
      }
      this.closeChild()
    },
    closeChild() {
      this.isComponentModalActive = false
    },
    getData() {
    },
    editRow(obj) {
      this.child = obj
      this.state = 'Edit'
      this.isComponentModalActive = true
    },
    deleteRow(obj) {
      this.data.children = this.data.children.filter(x => {
        return x != obj;
      })
    }
    // addChild() {
    //   this.childrenProps = {
    //     action: 'Add',
    //     name: '',
    //     birth_date: ''
    //   }
    //   this.childrenModal = true
    // },
    // editChild(child) {
    //   this.childrenProps = {
    //     action: 'Edit',
    //     name: child.children_name,
    //     birth_date: child.birth_date,
    //     id: child.id
    //   }
    //   this.childrenModal = true
    // },
  }
}
</script>
