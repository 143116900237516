<template>
  <div>
    <button class="button is-primary" @click.prevent="addEducation()" :disabled="isDisabled">
      Add Education
    </button>
    <b-table
      :data="data"
      focusable>  
      <template slot-scope="props">
        <b-table-column label="Level">
          {{ props.row.level }}
        </b-table-column>
        <b-table-column label="School Name">
          {{ props.row.school_name }}
        </b-table-column>
        <b-table-column label="Course">
          {{ props.row.course }}
        </b-table-column>
        <b-table-column label="Attended From">
          {{ props.row.attended_from }}
        </b-table-column>
        <b-table-column label="Attended To">
          {{ props.row.attended_to }}
        </b-table-column>
        <b-table-column label="Graduated">
          {{ graduated(props.row.graduated) }}
        </b-table-column>
        <b-table-column label="Highest Level">
          {{ props.row.highest_level }}
        </b-table-column>
        <b-table-column label="Year Graduated">
          {{ props.row.year_graduated }}
        </b-table-column>
        <b-table-column label="Honors">
          {{ props.row.honors }}
        </b-table-column>
        <b-table-column label="Actions" width="100">
          <button class="button is-small btn-action is-primary" @click.prevent="editRow(props.row)" :disabled="isDisabled"><i class="fa fa-pen"></i></button>
          <button class="button is-small btn-action is-danger" @click.prevent="deleteRow(props.row)" :disabled="isDisabled"><i class="fa fa-trash"></i></button>
        </b-table-column>
      </template>
      <template slot="empty">
        <b-table-column label="Level">
        </b-table-column>
        <b-table-column label="School Name">
        </b-table-column>
        <b-table-column label="Course">
        </b-table-column>
        <b-table-column label="Attended From">
        </b-table-column>
        <b-table-column label="Attended To">
        </b-table-column>
        <b-table-column label="Graduated">
        </b-table-column>
        <b-table-column label="Highest Level">
        </b-table-column>
        <b-table-column label="Year Graduated">
        </b-table-column>
        <b-table-column label="Honors">
        </b-table-column>
        <b-table-column label="Actions">
        </b-table-column>
      </template>
    </b-table>
    <b-modal :active.sync="isComponentModalActive" has-modal-card :canCancel=false>
      <transition name="fade">
        <addedit-form :obj="education" :state="state" @save="saveEducation" @cancel="cancel"></addedit-form>
      </transition>
    </b-modal>
  </div>
</template>

<script>
const addeditForm = {
  props: {
    obj: Object,
    state: String
  },
  template: `
  <form @submit.prevent="$emit('save', obj)">
    <div class="modal-card" style="height: 35.625rem; margin-top: 4rem;">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ state }}</p>
      </header>
      <section class="modal-card-body">
        <b-field label="Level">
          <b-select v-model="obj.level" expanded>
            <option
              v-for="(option, index) in $suggestions.state.data.education.level"
              :value="option"
              :key="index">
              {{ option }}
            </option>
          </b-select>
        </b-field>
        <b-field label="School Name">
          <b-autocomplete
            v-model="obj.school_name"
            :data="filteredSchool"
            placeholder="School Name"
            required>
          </b-autocomplete>
        </b-field>
        <b-field label="Course">
          <b-autocomplete
            v-model="obj.course"
            :data="filteredCourse"
            placeholder="Course">
          </b-autocomplete>
        </b-field>
        <b-field label="Attended From">
          <b-input v-model="obj.attended_from" type="number" min="1800" max="2100" required></b-input>
        </b-field>
        <b-field label="Attended To">
          <b-input v-model="obj.attended_to" type="number" min="1800" max="2100"></b-input>
        </b-field>
        <b-field label="Graduated">
          <b-select placeholder="Select Gender" @input="clearGraduate()" v-model="obj.graduated" expanded required>
            <option value="1">Yes</option>
            <option value="0">No</option>
          </b-select>
        </b-field>
        <b-field label="Highest Level" v-if="obj.graduated == 0">
          <b-input v-model="obj.highest_level"></b-input>
        </b-field>
        <b-field label="Year Graduated" v-if="obj.graduated == 1">
          <b-input v-model="obj.year_graduated"></b-input>
        </b-field>
        <b-field label="Honors">
          <b-input v-model="obj.honors"></b-input>
        </b-field>
        <br>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-success" type="submit">{{ state }}</button>
        <button class="button is-danger" @click.prevent="$emit('cancel')">Cancel</button>
      </footer>
    </div>
  </form>`,
  computed: {
    filteredSchool() {
      return this.$suggestions.state.school_name.filter((option) => {
        return option
          .toString()
          .toLowerCase()
          .indexOf(this.obj.school_name.toLowerCase()) >= 0
      })
    },
    filteredCourse() {
      return this.$suggestions.state.course.filter((option) => {
        return option
          .toString()
          .toLowerCase()
          .indexOf(this.obj.course.toLowerCase()) >= 0
      })
    }
  },
  methods: {
    clearGraduate() {
      this.obj.highest_level = ''
      this.obj.year_graduated = ''
    }
  }
}

import VueSimpleSuggest from 'vue-simple-suggest'
import '../../../css/styles.css'

export default {
  props: ["isDisabled", "data"],
  data() {
    return {
      isComponentModalActive: false,
      education: {},
      state: '',
      previousData: {}
    }
  },
  components: {
    addeditForm,
    VueSimpleSuggest
  },
  methods: {
    returnData() {
      let education = {
        education: this.data
      }
      this.$emit('education', education)
    },
    graduated(bool) {
      if (bool == 1) {
        this.isGraduate = true
        return 'Yes'
      }
      else {
        return 'No'
      }
    },
    addEducation() {
      this.education = {
        id: null,
        level: '',
        school_name: '',
        course: '',
        attended_from: '',
        attended_to: '',
        graduated: '',
        highest_level: '',
        year_graduated: '',
        honors: '',
      }
      this.state = 'Add'
      this.isComponentModalActive = true
    },
    saveEducation(obj) {
      if (this.state == 'Add') {
        this.data.push({
          id: obj.id, 
          level: obj.level,
          school_name: obj.school_name, 
          course: obj.course,
          attended_from: obj.attended_from,
          attended_to: obj.attended_to,
          graduated: obj.graduated,
          highest_level: obj.highest_level,
          year_graduated: obj.year_graduated,
          honors: obj.honors
        });
      }
      this.close()
    },
    close() {
      this.isComponentModalActive = false
    },
    cancel() {
      this.isComponentModalActive = false
      if (this.state == 'Edit') {
        let index = this.data.findIndex(x => x.id === this.previousData.id)
        this.data[index] = this.previousData
      }
    },
    editRow(obj) {
      this.education = obj
      this.state = 'Edit'
      this.previousData = JSON.parse(JSON.stringify(obj))
      this.isComponentModalActive = true
    },
    deleteRow(obj) {
      this.data = this.data.filter(x => {
        return x != obj;
      })
    },
  }
}
</script>

<style scoped>
  .btn-action {
    width: 35px;
  }
</style>
