<template>
  <div class="box">
    <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
    <div>
      <button class="button is-success" @click.prevent="addSource()" v-if="$role.state.permissions['write payroll'] || $role.state.permissions['write payroll funding source']">
        <span><i class="fa fa-plus"></i> Add Project</span>
      </button>
      <b-input v-model="search_query" placeholder="Search" class="is-pulled-right"/>
    </div>
    <hr>

    <b-table
      :data="filter"
      :striped=true
      :bordered=true
      :hoverable=true
      :paginated="true"
      :per-page="5">
      <template slot-scope="props">
        <b-table-column label="Code" width="100">
          {{ props.row.code }}
        </b-table-column>
        <b-table-column label="Description">
          {{ props.row.description }}
        </b-table-column>
        <b-table-column label="Actions" width="150">
          <button class="button btn-action is-primary is-small" @click.prevent="addSource(props.row)"><i class="fa fa-pen"></i></button>
          <button class="button btn-action is-danger is-small" @click.prevent="deleteSource(props.row)"><i class="fa fa-trash"></i></button>
        </b-table-column>
      </template>
      <template slot="empty">
        <b-table-column label="Code">
        </b-table-column>
        <b-table-column label="Description">
        </b-table-column>
        <b-table-column label="Actions">
        </b-table-column>
      </template>
    </b-table>

    <b-modal :active.sync="isSave" has-modal-card :canCancel=false>
      <transition name="fade">
        <form @submit.prevent="save()">
          <div class="modal-card" style="height: 35.625rem; margin-top: 4rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">{{ state }} Project</p>
            </header>
            <section class="modal-card-body">
              <b-field label="Code">
                <b-input
                  v-model="source.code"
                  expanded required>
                </b-input>
              </b-field>
              <b-field label="Description">
                <b-input
                  v-model="source.description"
                  expanded required>
                </b-input>
              </b-field>
              <br>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" type="submit">Save</button>
              <button class="button is-danger" type="button" @click.prevent="close()">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      config: '',
      isLoading: '',
      state: '',
      search_query: '',
      isSave: false,
      sources: [],
      source: {},
    }
  },
  computed: {
    filter() {
      var i;
      var name_re = new RegExp(this.search_query, 'i')
      var data = []
      this.sources.forEach(function(item,index){
        var searchString = [item.code, item.description].join(' ')
        if (searchString.match(name_re)) {
          data.push(item)
        }
      });
      return data
    }
  },
  mounted() {
    this.config = {
      headers: {'Authorization': "Bearer " + this.$store.state.token}
    };

    this.getSources()
  },
  methods: {
    getSources() {
      this.isLoading = true

      this.$http.get(`${this.$host}/api/payroll/funding-source/show/`,
        this.config
      ).then((response) => {
        let data = response.data
        this.sources = data
        this.isLoading = false
      }).catch((error) => {
        this.isLoading = false
        //console.log(error)
      });
    },
    save() {
      this.isLoading = true

      let id = ''
      if (this.source.id) {
        id = this.source.id
      }

      this.$http.post(`${this.$host}/api/payroll/funding-source/save/${id}`,
        this.source,
        this.config,
      ).then((response) => {
        let data = response.data
        this.$toast.open({
          message: 'Data successfully saved.',
          type: 'is-success',
          position: 'is-bottom-right',
          duration: 4000
        })
        //console.log(data)
        this.isLoading = false
        this.close()
      }).catch((error) => {
        this.$toast.open({
          message: 'Error saving data.',
          type: 'is-danger',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.isLoading = false
      });
    },
    close() {
      this.getSources()
      this.isSave = false
      this.source = {}
    },
    addSource(obj) {
      if (obj) {
        this.source = obj
        this.state = 'Edit'
      }
      else {
        this.state = 'Add'
      }
      this.isSave = true
    },
    deleteSource(obj) {
      this.$dialog.confirm({
        title: 'Delete Funding Source',
        message: "Are you sure you want to delete this row? This action cannot be undone.",
        confirmText: 'Delete',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isLoading = true

          this.$http.delete(`${this.$host}/api/payroll/funding-source/delete/${obj.id}`,
            this.config
          ).then((response) => {
            this.$toast.open({
              message: 'Row deleted.',
              type: 'is-success',
              position: 'is-bottom-right',
              duration: 4000
            })
            this.isLoading = false
            this.getSources()
          }).catch((error) => {
            this.$toast.open({
              message: 'Error deleting row.',
              type: 'is-danger',
              position: 'is-bottom-right',
              duration: 4000
            })
            this.isLoading = false
          });
        }
      })
    },
  }
}
</script>

<style>

</style>
