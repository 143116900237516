<template>
  <div>
    <div>
      <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
      <div>
        <b>Select Approvers</b><br>
        <hr style="margin-top: 10px;">
        <div class="columns">
          <div class="column is-6">
            <!-- Approver 1: {{ approvers.first.full_name }}
            <b-autocomplete
              v-model="approver1"
              :open-on-focus=true
              :data="filteredApprover1"
              field="full_name"
              @select="option => approvers.first = option">
            </b-autocomplete> -->
            <span style='font-weight: 500;'>1ST APPROVER</span>
            <!-- <a class="has-background-white has-text-primary" @click.prevent="assign('approver', 'first', 'approver1')">
              <span><i class="fa fa-user-plus"></i></span>
            </a> -->
            <a v-if="!isDisabled" class="has-background-white has-text-danger" @click.prevent="() =>{ return approver1 = '', approvers.first = null }">
              <span><i class="fa fa-trash"></i></span>
            </a>
            <b-field>
              <b-input v-model="approver1" disabled expanded></b-input>
              <button class="button is-primary" @click.prevent="assign('approver', 'first', 'approver1')" :disabled="isDisabled">
                <span><i class="fa fa-user"></i></span>
              </button>
            </b-field>
            <span style='font-weight: 500;'>2ND APPROVER</span>
            <a v-if="!isDisabled" class="has-background-white has-text-danger" @click.prevent="() =>{ return approver2 = '', approvers.second = null }">
              <span><i class="fa fa-trash"></i></span>
            </a>
            <b-field>
              <b-input v-model="approver2" disabled expanded></b-input>
              <button class="button is-primary" @click.prevent="assign('approver', 'second', 'approver2')" :disabled="isDisabled">
                <span><i class="fa fa-user"></i></span>
              </button>
            </b-field>
          </div>
          <div class="column is-6">
            <span style='font-weight: 500;'>FINAL APPROVER</span>
            <a v-if="!isDisabled" class="has-background-white has-text-danger" @click.prevent="() =>{ return approver3 = '', approvers.third = null }">
              <span><i class="fa fa-trash"></i></span>
            </a>
            <b-field>
              <b-input v-model="approver3" disabled expanded></b-input>
              <button class="button is-primary" @click.prevent="assign('approver', 'third', 'approver3')" :disabled="isDisabled">
                <span><i class="fa fa-user"></i></span>
              </button>
            </b-field>
            <!-- <span style='font-weight: 500;'>APPROVER 4</span>
            <a v-if="!isDisabled" class="has-background-white has-text-danger" @click.prevent="() =>{ return approver4 = '', approvers.fourth = null }">
              <span><i class="fa fa-trash"></i></span>
            </a>
            <b-field>
              <b-input v-model="approver4" disabled expanded></b-input>
              <button class="button is-primary" @click.prevent="assign('approver', 'fourth', 'approver4')" :disabled="isDisabled">
                <span><i class="fa fa-user"></i></span>
              </button>
            </b-field> -->
          </div>
        </div><br>

        <b>OB Approvers</b><br>
        <hr style="margin-top: 10px;">
        <div class="columns">
          <div class="column is-6">
            <!-- Approver 1: {{ approvers.first.full_name }}
            <b-autocomplete
              v-model="approver1"
              :open-on-focus=true
              :data="filteredApprover1"
              field="full_name"
              @select="option => approvers.first = option">
            </b-autocomplete> -->
            <span style='font-weight: 500;'>1ST OB APPROVER</span>
            <!-- <a class="has-background-white has-text-primary" @click.prevent="assign('approver', 'first', 'approver1')">
              <span><i class="fa fa-user-plus"></i></span>
            </a> -->
            <a v-if="!isDisabled" class="has-background-white has-text-danger" @click.prevent="() =>{ return ob_approvers.first.id = null, ob_approvers.first.name = null }">
              <span><i class="fa fa-trash"></i></span>
            </a>
            <b-field>
              <b-input v-model="ob_approvers.first.name" disabled expanded></b-input>
              <button class="button is-primary" @click.prevent="assign('ob_approver', 'first', 'approver1')" :disabled="isDisabled">
                <span><i class="fa fa-user"></i></span>
              </button>
            </b-field>
            <span style='font-weight: 500;'>2ND OB APPROVER</span>
            <a v-if="!isDisabled" class="has-background-white has-text-danger" @click.prevent="() =>{ return ob_approvers.second.id = null, ob_approvers.second.name = null }">
              <span><i class="fa fa-trash"></i></span>
            </a>
            <b-field>
              <b-input v-model="ob_approvers.second.name" disabled expanded></b-input>
              <button class="button is-primary" @click.prevent="assign('ob_approver', 'second', 'approver2')" :disabled="isDisabled">
                <span><i class="fa fa-user"></i></span>
              </button>
            </b-field>
          </div>
          <div class="column is-6">
            <span style='font-weight: 500;'>3RD OB APPROVER</span>
            <a v-if="!isDisabled" class="has-background-white has-text-danger" @click.prevent="() =>{ return ob_approvers.third.id = null, ob_approvers.third.name = null }">
              <span><i class="fa fa-trash"></i></span>
            </a>
            <b-field>
              <b-input v-model="ob_approvers.third.name" disabled expanded></b-input>
              <button class="button is-primary" @click.prevent="assign('ob_approver', 'third', 'approver3')" :disabled="isDisabled">
                <span><i class="fa fa-user"></i></span>
              </button>
            </b-field>
            <!-- <span style='font-weight: 500;'>APPROVER 4</span>
            <a class="has-background-white has-text-danger" @click.prevent="() =>{ return approver4 = '', approvers.fourth = null }">
              <span><i class="fa fa-trash"></i></span>
            </a>
            <b-field>
              <b-input v-model="approver4" disabled expanded></b-input>
              <button class="button is-primary" @click.prevent="assign('approver', 'fourth', 'approver4')">
                <span><i class="fa fa-user"></i></span>
              </button>
            </b-field> -->
          </div>
        </div><br>

        <b>OT Approvers</b><br>
        <hr style="margin-top: 10px;">
        <div class="columns">
          <div class="column is-6">
            <!-- Approver 1: {{ approvers.first.full_name }}
            <b-autocomplete
              v-model="approver1"
              :open-on-focus=true
              :data="filteredApprover1"
              field="full_name"
              @select="option => approvers.first = option">
            </b-autocomplete> -->
            <span style='font-weight: 500;'>1ST OT APPROVER</span>
            <!-- <a class="has-background-white has-text-primary" @click.prevent="assign('approver', 'first', 'approver1')">
              <span><i class="fa fa-user-plus"></i></span>
            </a> -->
            <a v-if="!isDisabled" class="has-background-white has-text-danger" @click.prevent="() =>{ return overtime_approvers.first.id = null, overtime_approvers.first.name = null }">
              <span><i class="fa fa-trash"></i></span>
            </a>
            <b-field>
              <b-input v-model="overtime_approvers.first.name" disabled expanded></b-input>
              <button class="button is-primary" @click.prevent="assign('overtime_approver', 'first', 'approver1')" :disabled="isDisabled">
                <span><i class="fa fa-user"></i></span>
              </button>
            </b-field>
            <span style='font-weight: 500;'>2ND OT APPROVER</span>
            <a v-if="!isDisabled" class="has-background-white has-text-danger" @click.prevent="() =>{ return overtime_approvers.second.id = null, overtime_approvers.second.name = null }">
              <span><i class="fa fa-trash"></i></span>
            </a>
            <b-field>
              <b-input v-model="overtime_approvers.second.name" disabled expanded></b-input>
              <button class="button is-primary" @click.prevent="assign('overtime_approver', 'second', 'approver2')" :disabled="isDisabled">
                <span><i class="fa fa-user"></i></span>
              </button>
            </b-field>
          </div>
          <div class="column is-6">
            <span style='font-weight: 500;'>3RD OT APPROVER</span>
            <a v-if="!isDisabled" class="has-background-white has-text-danger" @click.prevent="() =>{ return overtime_approvers.third.id = null, overtime_approvers.third.name = null }">
              <span><i class="fa fa-trash"></i></span>
            </a>
            <b-field>
              <b-input v-model="overtime_approvers.third.name" disabled expanded></b-input>
              <button class="button is-primary" @click.prevent="assign('overtime_approver', 'third', 'approver3')" :disabled="isDisabled">
                <span><i class="fa fa-user"></i></span>
              </button>
            </b-field>
            <!-- <span style='font-weight: 500;'>APPROVER 4</span>
            <a class="has-background-white has-text-danger" @click.prevent="() =>{ return approver4 = '', approvers.fourth = null }">
              <span><i class="fa fa-trash"></i></span>
            </a>
            <b-field>
              <b-input v-model="approver4" disabled expanded></b-input>
              <button class="button is-primary" @click.prevent="assign('approver', 'fourth', 'approver4')">
                <span><i class="fa fa-user"></i></span>
              </button>
            </b-field> -->
          </div>
        </div><br>

      </div>
      <br>
      <button class="button is-success" @click.prevent="save()" :disabled="isDisabled">Save</button>
      <button class="button is-danger" @click.prevent="reset()" :disabled="isDisabled">Reset</button>
      <span v-if="warning != ''" style="color: red; font-size: 16px;"><br>{{ warning }}</span>
      <!-- <b-table
        :data="approvers"
        :striped=true
        :bordered=true
        :hoverable=true
        :selected.sync="selected"
        :paginated="true"
        :per-page="5">
        <template slot-scope="props">
          <b-table-column label="Code" width="150">
            {{ props.row.code }}
          </b-table-column>
          <b-table-column label="Description" width="150">
            {{ props.row.description }}
          </b-table-column>
          <b-table-column label="Actions" width="150">
            <button class="button btn-action is-primary is-small" @click.prevent="editRow(props.row)"><i class="fa fa-pen"></i></button>
          </b-table-column>
        </template>
        <template slot="empty">
          <b-table-column label="Code">
          </b-table-column>
          <b-table-column label="Description">
          </b-table-column>
          <b-table-column label="Actions">
          </b-table-column>
        </template>
      </b-table> -->
    </div>
    <b-modal :active.sync="isAssign" has-modal-card :canCancel=false>
      <transition name="fade">
        <form action="">
          <div class="modal-card" style="width: 60rem; height: 35.625rem; margin-left: 100px; margin-top: 4rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">Select Employee</p>
            </header>
            <section class="modal-card-body">
              <b-field label="">
                <b-input v-model="search_query" placeholder="Search" />
              </b-field>
              <b-table
                :data="filter"
                :striped=true
                :bordered=true
                :hoverable=true
                :selected.sync="selected">
                <template slot-scope="props">
                  <b-table-column label="Employee Number">
                    {{ props.row.employee_number }}
                  </b-table-column>
                  <b-table-column label="Name">
                    {{ props.row.first_name }} {{ props.row.middle_name }} {{ props.row.last_name }} {{ props.row.name_extension }}
                  </b-table-column>
                  <b-table-column label="Date Hired">
                    {{ props.row.date_hired }}
                  </b-table-column>
                  <b-table-column label="Date of Birth">
                    {{ props.row.birth_date }}
                  </b-table-column>
                  <b-table-column label="Division / Office">
                    {{ props.row.division }}
                  </b-table-column>
                </template>
                <template slot="empty">
                  <b-table-column label="Employee Number">
                  </b-table-column>
                  <b-table-column label="Name">
                  </b-table-column>
                  <b-table-column label="Date Hired">
                  </b-table-column>
                  <b-table-column label="Date of Birth">
                  </b-table-column>
                  <b-table-column label="Division / Office">
                  </b-table-column>
                </template>
              </b-table>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" @click.prevent="select">Select</button>
              <button class="button is-danger" @click.prevent="close">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>
    <b-modal :active.sync="isMassAssign" has-modal-card :canCancel=false>
      <transition name="fade">
        <form action="">
          <div class="modal-card" style="width: 60rem; height: 35.625rem; margin-left: 100px; margin-top: 4rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">Select Employees</p>
            </header>
            <section class="modal-card-body">
              <b-field label="">
                <b-input v-model="search_query" placeholder="Search" />
              </b-field>
              <b-table
                :data="filter"
                :striped=true
                :bordered=true
                :hoverable=true
                :checked-rows.sync="checkedRows"
                checkable>
                <template slot-scope="props">
                  <b-table-column label="Employee Number">
                    {{ props.row.employee_number }}
                  </b-table-column>
                  <b-table-column label="Name">
                    {{ props.row.first_name }} {{ props.row.middle_name }} {{ props.row.last_name }} {{ props.row.name_extension }}
                  </b-table-column>
                  <b-table-column label="Date Hired">
                    {{ props.row.date_hired }}
                  </b-table-column>
                  <b-table-column label="Date of Birth">
                    {{ props.row.birth_date }}
                  </b-table-column>
                  <b-table-column label="Division / Office">
                    {{ props.row.division }}
                  </b-table-column>
                </template>
                <template slot="empty">
                  <b-table-column label="Employee Number">
                  </b-table-column>
                  <b-table-column label="Name">
                  </b-table-column>
                  <b-table-column label="Date Hired">
                  </b-table-column>
                  <b-table-column label="Date of Birth">
                  </b-table-column>
                  <b-table-column label="Division / Office">
                  </b-table-column>
                </template>
              </b-table>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" @click.prevent="massSelect">Select</button>
              <button class="button is-danger" @click.prevent="massClose">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: ['id', 'isDisabled'],
  data() {
    return {
      isLoading: true,
      isAssign: false,
      isMassAssign: false,
      config: '',
      employees: [],
      employeeNames: [],
      selected: {},
      confirmSelected: {},
      search_query: '',
      checkedRows: [],
      confirmMassSelected: [],
      filteredEmployees: [],
      approvers: {
        first: null,
        second: null,
        third: null,
        fourth: null
      },
      ob_approvers: {
        first: {
          id: null,
          name: null
        },
        second: {
          id: null,
          name: null
        },
        third: {
          id: null,
          name: null
        }
      },
      overtime_approvers: {
        first: {
          id: null,
          name: null
        },
        second: {
          id: null,
          name: null
        },
        third: {
          id: null,
          name: null
        }
      },
      approver1: '',
      approver2: '',
      approver3: '',
      approver4: '',
      type: '',
      approver: '',
      ordinal: '',
      warning: ''
    }
  },
  mounted() {
    this.config = {
      headers: {'Authorization': "Bearer " + this.$store.state.token}
    }
    this.isLoading = false
    this.getApprovers()
    // this.getEmployees()
    // this.getEmployeeNames()
  },
  computed: {
    filter() {
      let i;
      let name_re = new RegExp(this.search_query, 'i')
      let data = []
      for (i in this.employees) {
        if ([this.employees[i].employee_number, this.employees[i].first_name, this.employees[i].middle_name, this.employees[i].last_name, this.employees[i].division].join(' ').match(name_re)) {
          data.push(this.employees[i])
        }
      }
      return data
    },
    // filteredApprover1() {
    //   return this.employeeNames.filter((option) => {
    //     return option.full_name
    //       .toString()
    //       .toLowerCase()
    //       .indexOf(this.approver1.toLowerCase()) >= 0
    //   })
    // }
  },
  methods: {
    reset() {
      this.approvers = {
        first: null,
        second: null,
        third: null,
        fourth: null
      }
      this.approver1 = ''
      this.approver2 = ''
      this.approver3 = ''
      this.approver4 = ''
      this.type = ''
      this.approver = ''
      this.ordinal = ''
      this.search_query = ''
      this.checkedRows = []
      this.confirmMassSelected = []
      this.selected = {}
      this.confirmSelected = {}
      this.getApprovers()
    },
    clearEmployees() {
      this.search_query = ''
      this.checkedRows = []
      this.confirmMassSelected = []
      this.selected = {}
      this.confirmSelected = {}
    },
    assign(type, approver, ordinal) {
      this.getEmployees(() => {
        this.isAssign = true
        this.type = type
        this.approver = approver
        this.ordinal = ordinal
      })
    },
    close() {
      this.search_query = ''
      this.isAssign = false
    },
    select() {
      if (this.type == 'approver') {
        this.approvers[this.approver] = this.selected
        if (this.selected.middle_name == null || this.selected.name_extension == null) {
          if (this.selected.middle_name == null && this.selected.name_extension == null) {
            this[this.ordinal] = `${this.selected.first_name} ${this.selected.last_name}`
          }
          else if (this.selected.middle_name == null) {
            this[this.ordinal] = `${this.selected.first_name} ${this.selected.last_name} ${this.selected.name_extension}`
          }
          else if (this.selected.name_extension == null) {
            this[this.ordinal] = `${this.selected.first_name} ${this.selected.middle_name} ${this.selected.last_name}`
          }
          else {
            this[this.ordinal] = `${this.selected.first_name} ${this.selected.middle_name} ${this.selected.last_name} ${this.selected.name_extension}`
          }
        }
        else {
          this[this.ordinal] = `${this.selected.first_name} ${this.selected.middle_name} ${this.selected.last_name} ${this.selected.name_extension}`
        }
      }
      else if (this.type == 'ob_approver') {
        this.ob_approvers[this.approver].id = this.selected.id
        if (this.selected.middle_name == null || this.selected.name_extension == null) {
          if (this.selected.middle_name == null && this.selected.name_extension == null) {
           this.ob_approvers[this.approver].name = `${this.selected.first_name} ${this.selected.last_name}`
          }
          else if (this.selected.middle_name == null) {
           this.ob_approvers[this.approver].name = `${this.selected.first_name} ${this.selected.last_name} ${this.selected.name_extension}`
          }
          else if (this.selected.name_extension == null) {
           this.ob_approvers[this.approver].name = `${this.selected.first_name} ${this.selected.middle_name} ${this.selected.last_name}`
          }
          else {
           this.ob_approvers[this.approver].name = `${this.selected.first_name} ${this.selected.middle_name} ${this.selected.last_name} ${this.selected.name_extension}`
          }
        }
        else {
         this.ob_approvers[this.approver].name = `${this.selected.first_name} ${this.selected.middle_name} ${this.selected.last_name} ${this.selected.name_extension}`
        }
      }
      else if (this.type == 'overtime_approver') {
        this.overtime_approvers[this.approver].id = this.selected.id
        if (this.selected.middle_name == null || this.selected.name_extension == null) {
          if (this.selected.middle_name == null && this.selected.name_extension == null) {
            this.overtime_approvers[this.approver].name = `${this.selected.first_name} ${this.selected.last_name}`
          }
          else if (this.selected.middle_name == null) {
            this.overtime_approvers[this.approver].name = `${this.selected.first_name} ${this.selected.last_name} ${this.selected.name_extension}`
          }
          else if (this.selected.name_extension == null) {
            this.overtime_approvers[this.approver].name = `${this.selected.first_name} ${this.selected.middle_name} ${this.selected.last_name}`
          }
          else {
            this.overtime_approvers[this.approver].name = `${this.selected.first_name} ${this.selected.middle_name} ${this.selected.last_name} ${this.selected.name_extension}`
          }
        }
        else {
          this.overtime_approvers[this.approver].name = `${this.selected.first_name} ${this.selected.middle_name} ${this.selected.last_name} ${this.selected.name_extension}`
        }
      }
      else {
        this.checkedRows = []
        this.confirmMassSelected = []
        this.confirmSelected = this.selected
      }
      this.isAssign = false
    },
    massAssign() {
      this.getEmployees(() => {
        this.isMassAssign = true
      })
    },
    massClose() {
      this.search_query = ''
      this.checkedRows = []
      this.isMassAssign = false
    },
    massSelect() {
      this.selected = {}
      this.confirmSelected = {}
      this.confirmMassSelected = this.checkedRows
      this.isMassAssign = false
      this.$dialog.alert({
        title: 'Notice',
        message: 'Selecting multiple employees will <b>overwrite</b> their previous approvers upon saving.',
        type: 'is-primary',
        hasIcon: true,
        icon: 'exclamation-circle',
        iconPack: 'fa'
      })
    },
    getEmployees(callback) {
      this.isLoading = true
      this.$http.get(`${this.$host}/api/employee/search`,
        this.config
      ).then((response) => {
        let data = response.data.data
        this.employees = data
        this.isLoading = false
        callback()
      }).catch((error) => {
        //console.log(error)
        this.isLoading = false
      });
    },
    getApprovers() {
      this.isLoading = true
      this.$http.get(`${this.$host}/api/approver/search/${this.id}`,
        this.config
      ).then((response) => {
        let data = response.data.approvers
        this.approvers.first = data.leaves.approver1
        this.approvers.second = data.leaves.approver2
        this.approvers.third = data.leaves.approver3
        // this.approvers.fourth = data.leaves.approver4

        this.approver1 = data.leaves.approver1.name
        this.approver2 = data.leaves.approver2.name
        this.approver3 = data.leaves.approver3.name
        // this.approver4 = data.leaves.approver4.name

        this.ob_approvers.first = data.ob.approver1
        this.ob_approvers.second = data.ob.approver2
        this.ob_approvers.third = data.ob.approver3

        this.overtime_approvers.first = data.overtime.approver1
        this.overtime_approvers.second = data.overtime.approver2
        this.overtime_approvers.third = data.overtime.approver3

        this.isLoading = false
      }).catch((error) => {
        //console.log(error)
        this.isLoading = false
      });
    },
    save() {
      this.isLoading = true
      this.$http.post(`${this.$host}/api/approver/assign`,
        {
          employee_id: [this.id],
          leaves_approver_1: (this.approvers.first) ? this.approvers.first.id : null,
          leaves_approver_2: (this.approvers.second) ? this.approvers.second.id : null,
          leaves_approver_3: (this.approvers.third) ? this.approvers.third.id : null,
          leaves_approver_4: null,
          ob_approver_1: (this.ob_approvers.first.id) ? this.ob_approvers.first.id : null,
          ob_approver_2: (this.ob_approvers.second.id) ? this.ob_approvers.second.id : null,
          ob_approver_3: (this.ob_approvers.third.id) ? this.ob_approvers.third.id : null,
          overtime_approver_1: (this.overtime_approvers.first.id) ? this.overtime_approvers.first.id : null,
          overtime_approver_2: (this.overtime_approvers.second.id) ? this.overtime_approvers.second.id : null,
          overtime_approver_3: (this.overtime_approvers.third.id) ? this.overtime_approvers.third.id : null
        },
        this.config,
      ).then((response) => {
        let data = response.data.data
        this.$toast.open({
          message: 'Data successfully saved.',
          type: 'is-success',
          position: 'is-bottom-right',
          duration: 4000
        })
        //console.log(data)
        this.isLoading = false
        this.getEmployees()
        this.getApprovers()
      }).catch((error) => {
        this.$toast.open({
          message: 'Error saving data.',
          type: 'is-danger',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.isLoading = false
        this.getEmployees()
      });
    }
    // getEmployeeNames() {
    //   this.$http.get(`${this.$host}/api/parameter/service_record/employee`,
    //     this.config
    //   ).then((response) => {
    //     let data = response.data
    //     this.employeeNames = data
    //   }).catch((error) => {
    //     //console.log(error)
    //   });
    // }
  }
}
</script>

<style>

</style>
