<template>
  <div class="box">
    <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="true"></b-loading>
    <div v-if="$role.state.permissions['write service record']">
      <button class="button is-success" @click.prevent="addRecord()">
        <span><i class="fa fa-plus"></i> Add Service Record</span>
      </button>
    </div>
    <hr>
    <b>Date Hired:</b> {{ date_hired }}
    <b-table
      style="overflow-x: scroll;"
      :data="records"
      :striped=true
      :bordered=true
      :hoverable=true
      :paginated="true"
      :per-page="5"
      v-if="!isComponentModalActive"
      class="table-font">
      <template slot-scope="props">
        <b-table-column label="From">
          {{ props.row.from }}
        </b-table-column>
        <b-table-column label="To">
          {{ props.row.to }}
        </b-table-column>
        <b-table-column label="Designation">
          {{ props.row.position }}
        </b-table-column>
        <b-table-column label="Status">
          {{ props.row.status.code }}
        </b-table-column>
        <b-table-column label="Salary">
          {{ props.row.salary }}
        </b-table-column>
        <!-- <b-table-column label="Station / Place of Assignment">
          {{ props.row.division }}
        </b-table-column> -->
        <b-table-column label="Office / Project">
          {{ props.row.place_of_work }}
        </b-table-column>
        <b-table-column label="Supervisor">
          {{ props.row.supervisor }}
        </b-table-column>
        <b-table-column label="Date of Separation">
          {{ props.row.date_seperation }}
        </b-table-column>
        <b-table-column label="Cause of Separation">
          <span v-if="props.row.remarks.id==17 || props.row.remarks.id==18 || props.row.remarks.id==19 || props.row.remarks.id==20 || props.row.remarks.id==23 || props.row.remarks.id==25 || props.row.remarks.id==22">{{props.row.remarks.code}}</span>
          <span v-else>{{ props.row.cause }}</span>
        </b-table-column>
        <b-table-column label="Leave Without Pay">
          {{ props.row.awol_at }}
        </b-table-column>
        <b-table-column label="Remarks">
          <span v-if="props.row.remarks.id==17 || props.row.remarks.id==18 || props.row.remarks.id==19 || props.row.remarks.id==20 || props.row.remarks.id==23 || props.row.remarks.id==25 || props.row.remarks.id==22">{{props.row.cause}}</span>
          <span v-else>
            <span v-if="props.row.remarks">{{ props.row.remarks.code }} <button class="button btn-action is-primary is-small" @click.prevent="showReason(props.row.cancellation_reason)" v-if="props.row.cancellation_reason"><i class="fa fa-eye"></i></button></span>
          </span>
        </b-table-column>
        <b-table-column label="Show in Reports">
          {{ yesNo(props.row.show_in_reports) }}
        </b-table-column>
        <b-table-column label="" v-if="$role.state.permissions['write service record']">
          <button class="button btn-action is-primary is-small" @click.prevent="editRow(props.row)"><i class="fa fa-pen"></i></button>
          <button class="button btn-action is-danger is-small" @click.prevent="deleteRow(props.row)"><i class="fa fa-trash"></i></button>
        </b-table-column>
      </template>
      <template slot="empty">
        <b-table-column label="From">
        </b-table-column>
        <b-table-column label="To">
        </b-table-column>
        <b-table-column label="Designation">
        </b-table-column>
        <b-table-column label="Status">
        </b-table-column>
        <b-table-column label="Salary">
        </b-table-column>
        <b-table-column label="Station / Place of Assignment">
        </b-table-column>
        <b-table-column label="Office / Project">
        </b-table-column>
        <b-table-column label="Supervisor">
        </b-table-column>
        <b-table-column label="Date of Separation">
        </b-table-column>
        <b-table-column label="Cause of Separation">
        </b-table-column>
        <b-table-column label="Leave Without Pay">
        </b-table-column>
        <b-table-column label="Remarks">
        </b-table-column>
        <b-table-column label="Show in Reports">
        </b-table-column>
        <b-table-column label="" v-if="$role.state.permissions['write service record']">
        </b-table-column>
      </template>
    </b-table>
    <b-modal :active.sync="isComponentModalActive" has-modal-card :canCancel=false>
      <transition name="fade">
        <addedit-form :obj="data" :state="state" @close="close" @save="saveData"></addedit-form>
      </transition>
    </b-modal>
  </div>
</template>

<script>
import addeditForm from './addeditForm.vue'
import moment from 'moment'

export default {
  props: ['employee', 'employee_id', 'date_hired'],
  components: {
    addeditForm
  },
  data() {
    return {
      isComponentModalActive: false,
      data: {},
      state: '',
      selected: {},
      records: [],
      isLoading: false
    }
  },
  mounted() {
    let config = {
      headers: {'Authorization': "Bearer " + this.$store.state.token}
    };

    this.$http.get(`${this.$host}/api/service-records/search/${this.employee_id}`,
      config
    ).then((response) => {
      let records = response.data.data.servicerecords
      this.records = records
      this.isJo(this.records[0].status.id)
    }).catch((error) => {});
  },
  methods: {
    isJo(status_id) {
      if (status_id != undefined || status_id) {
        if (status_id == 10) {
        this.$editmode.commit('setIsJo', true)
        }
        else {
          this.$editmode.commit('setIsJo', false)
        }
      }
      else {
        this.$editmode.commit('setIsJo', false)
      }
    },
    showReason(reason) {
      this.$dialog.alert({
        title: 'Reason',
        message: reason,
        type: 'is-primary',
        hasIcon: false,
        iconPack: 'fa'
      })
    },
    yesNo(bool) {
      if (bool == 1) {
        return 'Yes'
      }
      else if (bool == 0) {
        return 'No'
      }
    },
    addRecord() {
      this.isComponentModalActive = true
      this.data.employee_id = this.employee_id
      this.data.date_seperation = null
      if (this.records.length > 0) {
        this.data = this.records[0]
        this.data.id = ''
        this.data.remarks_id = this.data.remarks.id
        this.data.employment_statuses_id = this.data.status.id
        this.data.employee_id = this.employee_id
        this.data.date_seperation = null
        this.data.date_from = null
        this.data.date_to = null
        this.data.awol_at = null
        this.data.date_hired = 0
      }
      else {
        this.data = {
          employee_id: this.employee_id,
          id: '',
          date_from: null,
          date_to: null,
          position_id: '',
          employment_statuses_id: '',
          salary: '',
          assigned_to: '',
          place_of_work: '',
          assigned_supervisor: '',
          date_seperation: null,
          cause: '',
          awol_at: null,
          remarks_id: '',
          show_in_reports: '',
          newData: {}
        }
      }
      this.state = 'ADD'
    },
    close() {
      let config = {
        headers: {'Authorization': "Bearer " + this.$store.state.token}
      };

      this.$http.get(`${this.$host}/api/service-records/search/${this.employee_id}`,
        config
      ).then((response) => {
        let records = response.data.data.servicerecords
        if(this.records.length > 0) {
          this.records = records
          this.isJo(this.records[0].status.id)
        }
        else {
          this.records = records
          this.isJo(this.records[0].status.id)
        }
        this.isComponentModalActive = false
        // this.searchLoad = false
      }).catch((error) => {
        this.isComponentModalActive = false
      });
    },
    editRow(obj) {
      obj.employee_id = this.employee_id
      if (obj.designation) {
        obj.position_id = obj.designation.id
      }
      obj.employment_statuses_id = obj.status.id
      obj.assigned_to = obj.station.id
      if (obj.remarks) {
        obj.remarks_id = obj.remarks.id
      }
      obj.date_from = new Date(obj.from)

      if (obj.date_seperation == '') {
        obj.date_seperation = null
      }

      if (obj.to == 'Present') {
        obj.date_to = null
      }
      else {
        obj.date_to = new Date(obj.to)
      }
      if (obj.date_seperation != null) {
        obj.date_seperation = new Date(obj.date_seperation)
      }

      this.data = obj
      this.state = 'EDIT'
      this.isComponentModalActive = true
    },
    saveData(data) {
      this.newData = JSON.parse(JSON.stringify(data))
      this.newData.date_from = moment(this.newData.date_from).format("YYYY-MM-DD")
      if (this.newData.date_to != '' && this.newData.date_to) {
        this.newData.date_to = moment(this.newData.date_to).format("YYYY-MM-DD")
      }
      if (this.newData.date_to == 'Invalid date') {
        delete this.newData.date_to
      }
      if (this.newData.date_seperation != '') {
        this.newData.date_seperation = moment(this.newData.date_seperation).format("YYYY-MM-DD")
      }
      if (this.newData.date_seperation == 'Invalid date') {
        this.newData.date_seperation = null
      }
      if (this.newData.awol_at == 'Invalid date') {
        delete this.newData.awol_at
      }
      else if (this.newData.awol_at == null) {

      }
      else if (this.newData.awol_at.includes('Invalid date')) {
        delete this.newData.awol_at
      }
      let id = this.newData.id
      if (id == undefined) {
        id = ''
      }
      delete this.newData.id
      if (!this.newData.position_id && this.newData.designation) {
        this.newData.position_id = this.newData.designation.id
        if (this.newData.designation.supervisor) {
          this.newData.assigned_supervisor = this.newData.designation.supervisor.id
        }
        this.newData.assigned_to = this.newData.station.id
      }
      else if (this.newData.position_id === 'none') {
        this.newData.position_id = null
        if (this.newData.designation.supervisor) {
          this.newData.assigned_supervisor = this.newData.designation.supervisor.id
        }
        this.newData.assigned_to = this.newData.station.id
      }
      this.isLoading = true
      let config = {
        headers: {'Authorization': "Bearer " + this.$store.state.token}
      };
      this.$http.post(`${this.$host}/api/service-records/save/${id}`,
        this.newData,
        config,
      ).then((response) => {
        let data = response.data.data
        this.$http.get(`${this.$host}/api/service-records/search/${this.employee_id}`,
          config
        ).then((response) => {
          let records = response.data.data.servicerecords
          this.close()
          this.isLoading = false
          // this.searchLoad = false
        }).catch((error) => {
          this.isLoading = false
        });
        this.$toast.open({
          message: 'Data successfully saved!',
          type: 'is-success',
          position: 'is-bottom-right',
          duration: 4000
        })
      }).catch((error) => {
        this.isLoading = false
        this.$toast.open({
          message: 'Error saving data.',
          type: 'is-danger',
          position: 'is-bottom-right',
          duration: 4000
        })
      });
    },
    deleteRow(obj) {
      let config = {
        headers: {'Authorization': "Bearer " + this.$store.state.token}
      };
      this.$dialog.confirm({
        title: `Discard Service Record?`,
        message: "Are you sure you want to delete this record? This action cannot be undone.",
        confirmText: 'Discard',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$dialog.confirm({
            title: `Confirm?`,
            message: "Are you really sure? This action cannot be undone.",
            confirmText: 'Yes',
            cancelText: 'No',
            type: 'is-danger',
            hasIcon: true,
            onConfirm: () => {
              this.isLoading = true
              this.$http.delete(`${this.$host}/api/service-records/delete/${obj.id}`,
                config
              ).then((response) => {
                this.$http.get(`${this.$host}/api/service-records/search/${this.employee_id}`,
                  config
                ).then((response) => {
                  let records = response.data.data.servicerecords
                  this.records = records
                  this.isJo(this.records[0].status.id)
                  this.isLoading = false
                  this.$toast.open({
                    message: 'Service Record Deleted.',
                    type: 'is-danger',
                    position: 'is-bottom-right',
                    duration: 4000
                  })
                }).catch((error) => {});
              }).catch((error) => {
                this.isLoading = false
                this.$toast.open({
                  message: 'Error saving data.',
                  type: 'is-danger',
                  position: 'is-bottom-right',
                  duration: 4000
                })
              });
            }
          })
        }
      })

    },
  }
}
</script>

<style scoped>
@media screen and (min-width: 1024px) and (max-width: 1407px)  {
  .table-font {
    font-size: 12px !important;
  }
}
</style>
