<template>
  <div>
    <b-loading :is-full-page="true" :active.sync="isLoading"></b-loading>
    <Selection @search="openSearch()" @self="getEmployee" v-if="isSelection" />
    <EmployeeSchedule :employee="employee" v-if="!isSelection" @back="backToSelection" />
    <transition name="fade">
      <SearchModal search-title="Employee" @close="closeSearch()" @view="getEmployee" v-if="isSearch" />
    </transition>
  </div>
</template>

<script>
import SearchModal from '../components/SearchModal.vue'
import EmployeeSchedule from '../components/schedule/EmployeeSchedule.vue'
import Selection from '../components/schedule/Selection.vue'

export default {
  component: {
    SearchModal,
    EmployeeSchedule,
    Selection
  },
  data() {
    return {
      isLoading: true,
      isSearch: false,
      isSelection: true,
      employee: {}
    }
  },
  mounted() {
    if (this.$editmode.state.isSearch) {
      this.isSearch = true
      this.$editmode.commit('setSearch', false)
    }
    //console.log(this.$route.params)
    this.isLoading = false
  },
  methods: {
    closeSearch() {
      this.isSearch = false
    },
    openSearch() {
      this.isSearch = true
    },
    getEmployee(obj) {
      // router.push({ path: '/schedule', params: obj.employee_number  })
      this.closeSearch()
      this.employee = obj
      this.isSelection = false
      // this.isSchedule = true
    },
    backToSelection() {
      this.isSelection = true
      this.employee = {}
    }
  }
}
</script>


<style scoped>
</style>
