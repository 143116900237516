<template>
  <div class="custom-container">
    <div class="login-page">
      <div class="header"><i class="fas fa-cloud"></i> HRIS <i class="fas fa-wrench"></i></div>
      <div class="form">
        <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
        <img src="/img/dotr-logo.png">
        <form class="login-form">
          <input class="login-input" type="text" placeholder="username" v-model="username"/>
          <input class="login-input" type="password" placeholder="password" v-model="password" />
          <button @click.prevent="login()" id="loginBtn">login</button>
          <p class="status">{{ status }}</p>
          <!-- <p class="message">Not registered? <a href="#">Create an account</a></p> -->
        </form>
        <a><span id="needHelp" class="need-help" @click.prevent="showHelp">Need help?</span></a>
      </div>
    </div>
    <div id="leftCorner" class="left-corner"></div>
    <div id="rightCorner" class="right-corner"></div>
    <footer style="text-align: center; position: fixed; bottom: 2%; width: 100%;">
      <a href="http://dotr.gov.ph/19-about-dotc/1009-dotr-data-privacy-notice.html" target="_blank">PRIVACY POLICY</a>
    </footer>
  </div>
</template>

<script>

export default {
  data() {
    return {
      username: '',
      password: '',
      status: '',
      isLoading: false,
      isEmptyLogin: true,
    }
  },
  mounted() {
    setTimeout(() => {
      document.getElementById("leftCorner").style['border-width'] = "20vw 0 0 40vw"
      document.getElementById("rightCorner").style['border-width'] = "0 40vw 20vw 0"
    }, 1000)
  },
  methods: {
    changeBG() {
      document.getElementById("rightCorner").style['border-width'] = "0 204vw 102vw 0"
      document.getElementById("loginBtn").style.background = "#1565C0"
      document.getElementById("needHelp").style.color = "white"
    },
    showHelp() {
      this.$dialog.alert({
          title: 'Help',
          message: "If you're having trouble logging in, please send an email to <a href='mailto:as.support@dotr.gov.ph'>as.support@dotr.gov.ph</a> for assistance.",
          // message: 'If you are having trouble logging in, contact or go to HR department for assistance. <b>(<a>example@example.com</a> / 1234567890)</b>',
          type: 'is-primary',
          hasIcon: true,
          icon: 'info-circle',
          iconPack: 'fa'
      })
    },
    login() {
      this.isLoading = true
      let config = {
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json"
        }
      }
      this.$http.post(`${this.$host}/api/login`, {
        username: this.username,
        password: this.password
      }, config)
      .then(response => {
        if (response.data.message == 'kiosk login is not allowed') {
            this.isLoading = false
            this.$toast.open({
                duration: 4000,
                message: `Kiosk Accounts are not allowed to login.`,
                position: 'is-bottom',
                type: 'is-danger'
            })
        } else if (response.data.message == 'user not found') {
          this.isLoading = false
          this.$toast.open({
            duration: 4000,
            message: `Wrong username or password`,
            position: 'is-bottom',
            type: 'is-danger'
          })
        }
        else if (response.data.message == 'user is not active') {
          this.isLoading = false
          this.$toast.open({
            duration: 4000,
            message: `User / Employee is not active.`,
            position: 'is-bottom',
            type: 'is-danger'
          })
        }
        else if (response.data.message == 'password not match') {
          this.isLoading = false
          this.$toast.open({
            duration: 4000,
            message: `Wrong username or password.`,
            position: 'is-bottom',
            type: 'is-danger'
          })
        }
        else if (response.data.data.authentication.access_token) {
          let token = response.data.data.authentication.access_token
          if (token) {
            this.$store.commit('setToken', token)
            this.$emit('authenticate')
            this.$emit('timeout')
            this.$role.commit('getRole')
            // //console.log(this.$store.state.token)
            // this.$toast.open({
            //   message: 'Successfully logged in.',
            //   type: 'is-success',
            //   position: 'is-bottom-right',
            //   duration: 3000
            // })
            this.$router.push('home')
            if (response.data.data.should_change == 1) {
              this.$emit('changepass', true)
            }
            // let config = {
            //   headers: {'Authorization': "Bearer " + this.$store.state.token}
            // };
            // this.$http.get(`${this.$host}/api/user`,
            //   config
            // ).then((response) => {
            //   if (response.data.data.is_verify == 1) {
            //     this.$snackbar.open({
            //       duration: 10000,
            //       message: 'NOTICE: Please verify your DTR.',
            //       type: 'is-success',
            //       position: 'is-bottom-right',
            //       actionText: 'Go',
            //       queue: false,
            //       onAction: () => { this.$router.push({ path: 'schedule' }) }
            //     })
            //     this.$editmode.commit('setVerify', true)
            //   }
            //   else {
            //     this.$editmode.commit('setVerify', false)
            //   }
            // }).catch((error) => {
            //   //console.log(error)
            // });
          }
        }
        else {
          this.isLoading = false
          this.$toast.open({
            duration: 4000,
            message: `Error connecting to server.`,
            position: 'is-bottom',
            type: 'is-danger'
          })
        }
      })
      .catch(error => {
        //console.log(error)
        this.isLoading = false
        if (!error.response) {
          this.$toast.open({
            duration: 4000,
            message: `Connection to server failed.`,
            position: 'is-bottom',
            type: 'is-danger'
          })
        }
        // else if (error.response.data.message == "Account has resigned" || error.response.data.message == 'Account has retired' || "You have ended your contract with DOTr.") {
        //   this.$toast.open({
        //     duration: 4000,
        //     message: `User/Employee is either inactive, retired, resigned or has ended it's contract.`,
        //     position: 'is-bottom',
        //     type: 'is-danger'
        //   })
        // }
        // else if (error.response.data.message == "kiosk login is not allowed") {
        //   this.$toast.open({
        //     duration: 4000,
        //     message: error.response.data.message,
        //     position: 'is-bottom',
        //     type: 'is-danger'
        //   })
        // }
        // else if (error.response.data.errors.password && error.response.data.errors.password[0] == 'The password field is required.' && error.response.data.errors.username === undefined) {
        //   this.$toast.open({
        //     duration: 4000,
        //     message: `Password field required.`,
        //     position: 'is-bottom',
        //     type: 'is-danger'
        //   })
        // }
        // else if (error.response.data.errors.username && error.response.data.errors.username[0] == 'The username field is required.' && error.response.data.errors.password === undefined) {
        //   this.$toast.open({
        //     duration: 4000,
        //     message: `Username field required.`,
        //     position: 'is-bottom',
        //     type: 'is-danger'
        //   })
        // }
        // else if (error.response.data.errors.password[0] && error.response.data.errors.password[0] == 'The password field is required.' && error.response.data.errors.username[0] == 'The username field is required.') {
        //   this.$toast.open({
        //     duration: 4000,
        //     message: `Please input your username and password.`,
        //     position: 'is-bottom',
        //     type: 'is-danger'
        //   })
        // }
        else {
          this.$toast.open({
            duration: 4000,
            message: error.response.data.message,
            position: 'is-bottom',
            type: 'is-danger'
          })
        }
      });

      // this.$emit('authenticate')
    },
  }
}
</script>


<style scoped>
  @import url(https://fonts.googleapis.com/css?family=Roboto:300);

  .login-input {
    text-transform: none;
  }
  .status {
    color: red;
  }
  .login-page {
    width: 350px;
    margin: auto;
    /* box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24); */
  }
  .form {
    position: relative;
    z-index: 1;
    background: transparent;
    max-width: 360px;
    margin: 0 auto 100px;
    padding: 30px;
    padding-top: 25px;
    /* text-align: center; */
  }
  .form input {
    /* font-family: "Roboto", sans-serif; */
    border-radius: 5px;
    outline: 0;
    background: #f0f0f0;
    width: 100%;
    border: 0;
    margin: 0 0 15px;
    padding: 15px;
    box-sizing: border-box;
    font-size: 16px;
  }
  .form button {
    /* font-family: "Roboto", sans-serif; */
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12) !important;
    border-radius: 5px;
    text-transform: uppercase;
    outline: 0;
    background: #0D47A1;
    width: 100%;
    border: 0;
    padding: 10px;
    color: #FFFFFF;
    font-size: 20px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    cursor: pointer;
  }
  .form button:hover,.form button:active,.form button:focus {
    background: #1565C0;
  }
  .form .message {
    margin: 15px 0 0;
    color: #b3b3b3;
    font-size: 12px;
  }
  .form .message a {
    color: #4CAF50;
    text-decoration: none;
  }
  .form .register-form {
    display: none;
  }
  .container {
    position: relative;
    z-index: 1;
    max-width: 300px;
    margin: 0 auto;
  }
  .container:before, .container:after {
    content: "";
    display: block;
    clear: both;
  }
  .container .info {
    margin: 50px auto;
    text-align: center;
  }
  .container .info h1 {
    margin: 0 0 15px;
    padding: 0;
    font-size: 36px;
    font-weight: 300;
    color: #1a1a1a;
  }
  .container .info span {
    color: #4d4d4d;
    font-size: 12px;
  }
  .container .info span a {
    color: #000000;
    text-decoration: none;
  }
  .container .info span .fa {
    color: #EF3B3A;
  }
  body {
    background: #B0BEC5; /* fallback for old browsers */
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .header {
    /* background-color: #0F3C8B; */
    /* height: 60px; */
    text-align: center;
    /* line-height: 60px; */
    /* color: white; */
    color: #515151;
  }
  .login-page img {
    margin: auto;
    display: block;
    height: 200px;
    padding-bottom: 25px;
  }
  .custom-container {
    padding-top: 6%;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
  }
  .need-help {
    text-align: left !important;
    font-size: 15px;
  }
  .need-help:hover {
    text-decoration: underline;
  }
  .left-corner {
    position: absolute;
    bottom: 0%;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    /* border-width: 20vw 0 0 40vw; */
    border-width: 0;
    border-color: transparent transparent transparent #d32f2f;
    transition: border-width 0.8s;
  }
  .right-corner {
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    /* border-width: 0 40vw 20vw 0; */
    border-width: 0;
    border-color: transparent #0D47A1 transparent transparent;
    transition: border-width 0.8s;
  }
  @media screen and (min-width: 1px) and (max-width: 1215px)  {
    .left-corner {
      border-width: 0 0 0 0 !important;
    }
    .right-corner {
      border-width: 0 0 0 0 !important;
    }
    .custom-container {
      padding-top: 70px;
    }
    .need-help {
      color: #515151 !important;
    }
  }
  .footer {
    background-color: transparent !important;
  }
</style>

