import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import Cookies from 'js-cookie';
import Axios from 'axios'
import { host } from './host.js'

export const store = new Vuex.Store({
  state: {
    token: '',
    isLoggedIn: false,
  },
  plugins: [createPersistedState({
    storage: {
      getItem: key => Cookies.get(key),
      setItem: (key, value) => Cookies.set(key, value, { expires: 0.5, secure: false }),
      removeItem: key => Cookies.remove(key)
    }
  })],
  mutations: {
    setToken: function(state, value) {
      state.token = value
      state.isLoggedIn = true
    },
    clearToken: function(state) {
      state.token = '';
      state.isLoggedIn = false
      let newRole = {
        employee_id: '',
        last_name: '',
        first_name: '',
        middle_name: '',
        name_extension: '',
        permissions: {}
      }
      role.replaceState(newRole)
      preload.commit('reset')
    }
  }
});

export const suggestions = new Vuex.Store({
  state: {
    data: '',
    school_name: '',
    civil_type: '',
    workexperiences: '',
    volunteerings: '',
    course: ''
  },
  mutations: {
    getParams(state) {
      let config = {
        headers: {'Authorization': "Bearer " + store.state.token}
      };
      Axios.get(`${host}/api/parameter`,
        config
      ).then((response) => {
        state.data = response.data.data
        state.school_name = response.data.data.education.school_name
        state.civil_type = response.data.data.eligibilities.type
        state.workexperiences = response.data.data.workexperiences
        state.volunteerings = response.data.data.volunteerings
        state.course = response.data.data.education.course
      }).catch((error) => {
        //console.log(error)
      });
    }
  }
});

export const role = new Vuex.Store({
  state: {
    id: '',
    employee_id: '',
    last_name: '',
    first_name: '',
    middle_name: '',
    name_extension: '',
    role: '',
    permissions: {},
    username: '',
    funding_source_id: 84
  },
  mutations: {
    getRole(state) {
      setTimeout(() => preload.commit('increase', 10), 200)
      let config = {
        headers: {'Authorization': "Bearer " + store.state.token}
      };
      Axios.get(`${host}/api/user`,
        config
      ).then((response) => {
        state.id = response.data.data.user.id
        state.username =response.data.data.user.username
        state.role = response.data.data.user.roles.join(' ')
        state.permissions = response.data.data.user.permissions
        let data = response.data.data
        if (data.employee) {
          state.employee_id = data.employee.id
          state.last_name = data.employee.last_name
          state.first_name = data.employee.first_name
          state.middle_name = data.employee.middle_name
          state.name_extension = data.employee.name_extension
          state.funding_source_id = data.employee.funding_source_id
        }
        // if (role.state.permissions.find(x => x === 'read user')) {
        //   state.readUser = true
        // }
        // else {
        //   state.readUser = false
        // }
        preload.commit('increase', 20)
        setTimeout(() => preload.commit('increase', 69.999), 1000)
        setTimeout(() => preload.commit('increase', 0.001), 1500)
      }).catch((error) => {
        if (error.response.data.message == 'Unauthenticated.') {
          setTimeout(() => {
            role.commit('clearRole')
            store.commit('clearToken')
            window.location.reload()
            this.$emit('timeout')
          }, 1 * 1000)
        }
        //console.log(error.response)
      });
    },
    clearRole(state) {
      state.role = '',
      state.permissions = ''
    }
  }
});

export const editmode = new Vuex.Store({
  state: {
    data: false,
    username: '',
    password: '',
    email: '',
    fromAdd: false,
    newEmpId: '',
    newUserId: '',
    newPositionId: '',
    fromPositionAdd: false,
    fromUserAdd: false,
    shouldChange: false,
    isSearch: false,
    isUpdate: false,
    templates: [{
      code: 'SS',
      monday: '07:00:00 - 10:00:00 - 11:00:00 - 17:00:00',
      tuesday: '07:00:00 - 10:00:00 - 11:00:00 - 17:00:00',
      wednesday:  '07:00:00 - 10:00:00 - 11:00:00 - 17:00:00',
      thursday: '07:00:00 - 10:00:00 - 11:00:00 - 17:00:00',
      friday: '- - - ',
      saturday: '- - - ',
      sunday: '- - - '
    }],
    verify: false,
    empData: null, // for training
    isJo: false,
  },
  mutations: {
    editing(state, bool) {
      state.data = bool
    },
    setSearch(state, bool) {
      state.isSearch = bool
    },
    setUsername(state, username) {
      state.username = username
    },
    setPassword(state, password) {
      state.password = password
    },
    setEmail(state, email) {
      state.email = email
    },
    setAdd(state, bool) {
      state.fromAdd = bool
    },
    setNewEmp(state, id) {
      state.newEmpId = id
    },
    setNewUserId(state, id) {
      state.newUserId = id
    },
    setNewPositionId(state, id) {
      state.newPositionId = id
    },
    setUserAdd(state, bool) {
      state.fromUserAdd = bool
    },
    setPositionAdd(state, bool) {
      state.fromPositionAdd = bool
    },
    setShouldChange(state, bool) {
      state.shouldChange = bool
    },
    setTemplate(state, array) {
      state.templates = array
    },
    setUpdate(state, bool) {
      state.isUpdate = bool
    },
    setVerify(state, bool) {
      state.verify = bool
    },
    setEmpData(state, data) {
      state.empData = data
    },
    setIsJo(state, bool) {
      state.isJo = bool
    }
  }
});

export const time = new Vuex.Store({
  state: {
    time: [
      '00:00:00', '00:30:00', '01:00:00', '01:30:00', '02:00:00', '02:30:00', '03:00:00', '03:30:00', '04:00:00', '04:30:00',
      '05:00:00', '05:30:00', '06:00:00', '06:30:00', '07:00:00', '07:30:00', '08:00:00', '08:30:00', '09:00:00', '09:30:00',
      '10:00:00', '10:30:00', '11:00:00', '11:30:00', '12:00:00', '12:30:00', '13:00:00', '13:30:00', '14:00:00', '14:30:00',
      '15:00:00', '15:30:00', '16:00:00', '16:30:00', '17:00:00', '17:30:00', '18:00:00', '18:30:00', '19:00:00', '19:30:00',
      '20:00:00', '20:30:00',' 21:00:00', '21:30:00', '22:00:00', '22:30:00', '23:00:00', '23:30:00'
    ],
  },
});

export const preload = new Vuex.Store({
  state: {
    progress: 0
  },
  mutations: {
    increase(state, val) {
      state.progress = state.progress + val
    },
    reset(state) {
      state.progress = 0
    }
  }
});
