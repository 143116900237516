<template>
  <div>
    <div class="columns header" style="margin-bottom: 0 !important;">
      <div class="column is-5" style="margin-left: 5px; font-size: 20px; text-transform: uppercase;">
        <b>Templates</b> 
      </div>
      <div class="column is-auto">
        <button v-if="$role.state.permissions['read special dates']" class="button is-primary is-pulled-right is-hidden-mobile" style="margin-left: 10px;" 
        @click.prevent="$router.push('/schedule/holidays')">
          <i class="fa fa-calendar-day"></i> &nbsp;Special Dates
        </button>
        <button v-if="$role.state.permissions['read daily time record']" class="button is-primary is-pulled-right is-hidden-mobile" style="margin-left: 10px;" 
        @click.prevent="toSchedule()">
          <i class="fa fa-user-tie"></i> &nbsp;Schedules
        </button>
        <button v-if="$role.state.permissions['read daily time record']" class="button is-primary is-hidden-tablet" @click.prevent="toSchedule()">
          <i class="fa fa-user-tie"></i> &nbsp;Schedules
        </button>
        <button v-if="$role.state.permissions['read special dates']" class="button is-primary is-hidden-tablet" @click.prevent="$router.push('/schedule/holidays')">
          <i class="fa fa-calendar-day"></i> &nbsp;Special Dates
        </button>
      </div>
    </div>
    <hr style="margin-top: 0 !important; margin-bottom: 0 !important;" class="has-background-grey-lighter is-hidden-mobile">
    <br>
    <Template />
  </div>
</template>

<script>
import Template from '../components/templates/Template.vue'

export default {
  data() {
    return {
    }
  },
  components: {
    Template
  },
  mounted() {
    
  },
  methods: {
    toSchedule() {
      // this.$editmode.commit('setSearch', true)
      this.$router.push({ path: '/schedule' })
    }
  }
}
</script>

<style>

</style>
