<template>
  <div>
    <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
    <div class="columns header" style="margin-bottom: 0 !important;">
      <div class="column is-12" style="margin-left: 5px; font-size: 20px;">
        <b>Settings</b>
      </div>
    </div>
    <hr style="margin-top: 0 !important; margin-bottom: 0 !important;" class="has-background-grey-lighter">
    <br>
    <div class="columns header" style="margin-bottom: 0 !important;">
      <div class="column is-5">
        <b-field>
          <div class="select is-fullwidth">
            <select placeholder="Select Setting" @change="table">
              <optgroup label="Service Record and Position" v-if="$role.state.permissions['write service record'] && $role.state.permissions['write position']">
                <option value="Salary Grades">Salary Grades</option>
                <option value="Division">Division</option>
                <option value="Equipment">Equipment</option>
                <option value="Employment Status">Employment Status</option>
              </optgroup>

              <optgroup label="Schedule" v-if="$role.state.permissions['write daily time record']">
                <option value="Schedule">Flexi</option>
                <option value="Groups">Groups</option>
              </optgroup>

              <optgroup label="Leave Management" v-if="$role.state.permissions['write leave ledger']">
                <option value="Request Types">Request Types</option>
                <option value="Approvers">Approvers</option>
                <!-- <option value="Verify Range">Verify Range</option> -->
              </optgroup>

              <optgroup label="Employee" v-if="$role.state.permissions['write employee']">
                <option value="Employee Update">Employee Update</option>
                <option value="OT Allowed">Employee Settings</option>
              </optgroup>

              <optgroup label="Payroll" v-if="($role.state.role === 'Administrator' || $role.state.role === 'Payroll' || $role.state.permissions['write payroll funding source'])">
                <option value="Earnings">Earnings</option>
                <option value="Deductions">Deductions</option>
                <option value="Tax" v-if="$role.state.permissions['search tax']">Tax</option>
                <option value="Special Tax" v-if="($role.state.permissions['search tax'] || $role.state.permissions['payroll setting funding source'])">Special Tax</option>
                <option value="Gsis" v-if="$role.state.permissions['search gsis']">GSIS</option>
                <!-- <option value="Sss">SSS</option> -->
                <option value="Philhealth" v-if="$role.state.permissions['search philhealth']">PHILHEALTH</option>
                <option value="Pagibig" v-if="$role.state.permissions['search pagibig']">PAG-IBIG</option>
                <option value="Funding Source" v-if="$role.state.permissions['write payroll']">Projects</option>
                <option value="Payroll Setting"  v-if="($role.state.permissions['write payroll'] || $role.state.permissions['write payroll funding source'])">Payroll Setting</option>
                <option value="Minimum Amount" v-if="$role.state.permissions['write payroll']">Payroll Minimum Amount</option>
              </optgroup>

              <optgroup label="Employee Based Earnings" v-if="$role.state.permissions['import template'] || $role.state.permissions['export template']">
                <!-- <option value="Import">Import</option> -->
                <option value="Download Template">Earning Template</option>
              </optgroup>
              <optgroup label="Admin" v-if="$role.state.role === 'Administrator' || $role.state.role === 'Payroll'">
                <option value="EmployerInfo">Employer Info</option>
                <option value="MORS">Modified Obligation Request and Status (Particulars)</option>
                <option value="DV">Disbursement Voucher (Particulars)</option>
              </optgroup>
            </select>
          </div>
        </b-field>
      </div>
    </div>
    <SalaryGrades v-if="this.location == 'Salary Grades'" />
    <Schedule v-if="this.location == 'Schedule'" />
    <Division v-if="this.location == 'Division'" :divisions="divisions" @reset="setDivision" :isPos="false"/>
    <Equipment v-if="this.location == 'Equipment'" :equipments="equipments" @reset="setEquipment" />
    <Groups v-if="this.location == 'Groups'" />
    <RequestTypes v-if="this.location == 'Request Types'" />
    <Approvers v-if="this.location == 'Approvers'" />
    <EmployeeUpdate v-if="this.location == 'Employee Update'" />
    <VerifyRange v-if="this.location == 'Verify Range'" />
    <Earnings v-if="this.location == 'Earnings'" />
    <EmployerInfo v-if="this.location == 'EmployerInfo'" />
    <MORS v-if="this.location == 'MORS'" />
    <DV v-if="this.location == 'DV'" />
    <Deductions v-if="this.location == 'Deductions'" />
    <Tax v-if="this.location == 'Tax'" />
    <SpecialTax v-if="this.location == 'Special Tax'" />
    <Gsis v-if="this.location == 'Gsis'" />
    <!-- <Sss v-if="this.location == 'Sss'" /> -->
    <Philhealth v-if="this.location == 'Philhealth'" />
    <Pagibig v-if="this.location == 'Pagibig'" />
    <FundingSource v-if="this.location == 'Funding Source'" />
    <PayrollSetting v-if="this.location == 'Payroll Setting'" />
    <OtAllowed v-if="this.location == 'OT Allowed'" />
    <DownloadTemplate v-if="this.location == 'Download Template'" />
    <Import v-if="this.location == 'Import'" />
    <MinAmount v-if="this.location == 'Minimum Amount'" />
    <EmploymentStatuses v-if="this.location == 'Employment Status'" :employmentStatuses="employmentStatuses" @reset="setEmploymentStatus" />
  </div>
</template>

<script>
import SalaryGrade from '../components/settings/SalaryGrades.vue'
import Division from '../components/settings/Division.vue'
import Equipment from '../components/settings/Equipment.vue'
import Schedule from '../components/settings/Schedule.vue'
import Groups from '../components/settings/Groups.vue'
import RequestTypes from '../components/settings/RequestTypes.vue'
import Approvers from '../components/settings/Approvers.vue'
import EmployeeUpdate from '../components/settings/EmployeeUpdate.vue'
import VerifyRange from '../components/settings/VerifyRange.vue'
import Earnings from '../components/settings/Earnings.vue'
import EmployerInfo from '../components/settings/EmployerInfo.vue'
import MORS from '../components/settings/MORS.vue'
import DV from '../components/settings/DV.vue'
import Deductions from '../components/settings/Deductions.vue'
import Tax from '../components/settings/Tax.vue'
import SpecialTax from '../components/settings/SpecialTax.vue'
import Gsis from '../components/settings/Gsis.vue'
import Sss from '../components/settings/Sss.vue'
import Philhealth from '../components/settings/Philhealth.vue'
import Pagibig from '../components/settings/Pagibig.vue'
import FundingSource from '../components/settings/FundingSource.vue'
import PayrollSetting from '../components/settings/PayrollSetting.vue'
import OtAllowed from '../components/settings/OtAllowed.vue'
import DownloadTemplate from '../components/settings/DownloadTemplate.vue'
import Import from '../components/settings/Import.vue'
import MinAmount from '../components/settings/MinAmount.vue'
import EmploymentStatuses from '../components/settings/EmploymentStatuses.vue'

export default {
  data() {
    return {
      isLoading: false,
      location: '',
      divisions: [],
      equipments: [],
      employmentStatuses: []
    }
  },
  components: {
    SalaryGrade,
    Division,
    Schedule,
    Groups,
    RequestTypes,
    Approvers,
    EmployeeUpdate,
    VerifyRange,
    Earnings,
    EmployerInfo,
    Deductions,
    Tax,
    SpecialTax,
    Gsis,
    Sss,
    Philhealth,
    Pagibig,
    FundingSource,
    PayrollSetting,
    OtAllowed,
    DownloadTemplate,
    Import,
    MinAmount,
  },
  mounted() {
    this.location = ''
  },
  methods: {
    setDivision() {
      this.isLoading = true
      let config = {
        headers: {'Authorization': "Bearer " + this.$store.state.token}
      };

      this.$http.get(`${this.$host}/api/division/show`,
        config
      ).then((response) => {
        let data = response.data.data
        this.divisions = data
        this.isLoading = false
      }).catch((error) => {
        this.isLoading = false
        //console.log(error)
      });
    },
    setEquipment() {
      this.isLoading = true
      let config = {
        headers: {'Authorization': "Bearer " + this.$store.state.token}
      };

      this.$http.get(`${this.$host}/api/equipment/search`,
        config
      ).then((response) => {
        let data = response.data.data
        this.equipments = data
        this.isLoading = false
      }).catch((error) => {
        this.isLoading = false
        //console.log(error)
      });
    },
    setEmploymentStatus() {
      this.isLoading = true
      let config = {
        headers: {'Authorization': "Bearer " + this.$store.state.token}
      };

      this.$http.get(`${this.$host}/api/employment-status/show/`,
        config
      ).then((response) => {
        let data = response.data.data
        this.employmentStatuses = data
        this.isLoading = false
      }).catch((error) => {
        this.isLoading = false
        //console.log(error)
      });
    },
    table(e) {
      if (e.target.value == 'Salary Grades') {
        this.location = e.target.value
      }
      else if (e.target.value == 'Division') {
        this.setDivision()
        this.location = e.target.value
      }
      else if (e.target.value == 'Equipment') {
        this.setEquipment()
        this.location = e.target.value
      }
      else if (e.target.value == 'Schedule') {
        this.location = e.target.value
      }
      else if (e.target.value == 'Groups') {
        this.location = e.target.value
      }
      else if (e.target.value == 'Request Types') {
        this.location = e.target.value
      }
      else if (e.target.value == 'Approvers') {
        this.location = e.target.value
      }
      else if (e.target.value == 'Employee Update') {
        this.location = e.target.value
      }
      else if (e.target.value == 'Verify Range') {
        this.location = e.target.value
      }
      else if (e.target.value == 'Earnings') {
        this.location = e.target.value
      }
      else if (e.target.value == 'EmployerInfo') {
        this.location = e.target.value
      }
      else if (e.target.value == 'MORS') {
        this.location = e.target.value
      }
      else if (e.target.value == 'DV') {
        this.location = e.target.value
      }
      else if (e.target.value == 'Deductions') {
        this.location = e.target.value
      }
      else if (e.target.value == 'Tax') {
        this.location = e.target.value
      }
      else if (e.target.value == 'Special Tax') {
        this.location = e.target.value
      }
      else if (e.target.value == 'Gsis') {
        this.location = e.target.value
      }
      else if (e.target.value == 'Sss') {
        this.location = e.target.value
      }
      else if (e.target.value == 'Philhealth') {
        this.location = e.target.value
      }
      else if (e.target.value == 'Pagibig') {
        this.location = e.target.value
      }
      else if (e.target.value == 'Funding Source') {
        this.location = e.target.value
      }
      else if (e.target.value == 'Payroll Setting') {
        this.location = e.target.value
      }
      else if (e.target.value == 'OT Allowed') {
        this.location = e.target.value
      }
      else if (e.target.value == 'Download Template') {
        this.location = e.target.value
      }
      else if (e.target.value == 'Import') {
        this.location = e.target.value
      }
      else if (e.target.value == 'Minimum Amount') {
        this.location = e.target.value
      }else if (e.target.value == 'Employment Status') {
        this.setEmploymentStatus()
        this.location = e.target.value
      }
    },
  }
}
</script>

<style scoped>

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
