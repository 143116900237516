<template>
  <div>
    <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
    <div class="columns header" style="margin-bottom: 0 !important;">
      <div class="column is-7" style="margin-left: 5px; font-size: 20px; text-transform: uppercase;">
        <b>Activity Log</b>
      </div>
      <div class="column is-auto">
        <button class="button is-primary is-pulled-right" style="margin-left: 10px;" @click.prevent="isPrint = true" v-if="$role.state.permissions['read activity log'] || $role.state.permissions['print activity log']">
          <span><i class="fa fa-print"></i> Print Logs</span>
        </button>
      </div>
      <transition name="fade">
        <div class="scroll-print" v-if="isScrolled">
          <button v-if="$role.state.permissions['read activity log'] || $role.state.permissions['print activity log']" title="Print" class="button is-medium is-primary" @click.prevent="printActivityLogs()">
            <span><i class="fa fa-print"></i></span>
          </button>
        </div>
      </transition>
    </div>
    <hr style="margin-top: 0 !important; margin-bottom: 0 !important;" class="has-background-grey-lighter is-hidden-mobile">
    <div class="box" v-if="$role.state.permissions['read activity log']">
      <b-field grouped group-multiline>
        <b-select placeholder="Per Page" v-model="perPage" @input="getLogs">
          <option value="5">5 per page</option>
          <option value="10">10 per page</option>
          <option value="20">20 per page</option>
          <option value="50">50 per page</option>
          <option value="100">50 per page</option>
        </b-select>
        <b-datepicker
          placeholder="date from"
          icon="calendar-today"
          v-model="filterFrom"
          @input="getLogs"
          :date-formatter="d => d.toLocaleDateString('en-GB')">
        </b-datepicker>
        <b-datepicker
          placeholder="date to"
          icon="calendar-today"
          v-model="filterTo"
          @input="getLogs"
          :date-formatter="d => d.toLocaleDateString('en-GB')">
        </b-datepicker>
        <b-input v-model="search_query" placeholder="Search" @input="getLogs" />
      </b-field>
      <hr style="margin-top: 0; margin-bottom: 10px;">
      <b-table
        :data="logData"
        :bordered=false
        :hoverable=true
        :paginated="true"
        :per-page="perPage">
        <template slot-scope="props">
          <b-table-column label="Username" field="username" sortable>
            {{ props.row.username }}
          </b-table-column>
          <b-table-column label="Log Name" field="log_name" sortable>
            {{ props.row.log_name }}
          </b-table-column>
          <b-table-column label="Description" field="description" sortable>
            {{ props.row.description }}
          </b-table-column>
          <b-table-column label="Reference Date" field="created_at" sortable>
            {{ props.row.created_at }}
          </b-table-column>
          <b-table-column label="Actions">
            <button class="button btn-action is-primary is-small" @click.prevent="openDetails(props.row.id)"><i class="fa fa-eye"></i></button>
          </b-table-column>
        </template>
        <template slot="empty">
          <b-table-column label="Username">
          </b-table-column>
          <b-table-column label="Log Name">
          </b-table-column>
          <b-table-column label="Description">
          </b-table-column>
          <b-table-column label="Reference Date">
          </b-table-column>
          <b-table-column label="Actions">
          </b-table-column>
        </template>
      </b-table>
      <b-modal :active.sync="isLog" has-modal-card :canCancel=false>
        <transition name="fade">
          <div class="modal-card" style="height: 35.625rem; margin-top: 4rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">Activity Log</p>
            </header>
            <section class="modal-card-body">
              <b-field label="Username">
                {{log.username}}
              </b-field>
              <b-field label="Employee Nmae">
                {{log.employee_name}}
              </b-field>
              <b-field label="Log Name">
                {{log.log_name}}
              </b-field>
              <b-field label="Description">
                {{log.description}}
              </b-field>
              <b-field label="Reference Date">
                {{log.created_at}}
              </b-field>
              <b-field label="Changes">
                {{log.changes}}
              </b-field>
              <br>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" @click.prevent="print(log.id)">Print</button>
              <button class="button is-danger" @click.prevent="close()">Cancel</button>
            </footer>
          </div>
        </transition>
      </b-modal>
      <b-modal :active.sync="isPrint" has-modal-card :canCancel=false>
      <transition name="fade">
        <form action="">
          <div class="modal-card" style="height: 35.625rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">Print Logs</p>
            </header>
            <section class="modal-card-body">
              <b-field label="Filters"></b-field>
              <b-field label="Date From">
                <b-datepicker
                  placeholder="Filter From"
                  icon="calendar-today"
                  v-model="filterDateFrom"
                  format="yyyy-MM-dd">
                </b-datepicker>
              </b-field>
              <b-field label="Date To">
                <b-datepicker
                  placeholder="Filter To"
                  icon="calendar-today"
                  v-model="filterDateTo"
                  format="yyyy-MM-dd">
                </b-datepicker>
              </b-field>
              <b-field label="Log Name">
                <b-autocomplete
                  @input="showSuggestLog"
                  v-model="filterLogName"
                  :data="filterLogSuggest"
                  field="Log Name"
                  :open-on-focus=false expanded>
                </b-autocomplete>
              </b-field>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" @click.prevent="printActivityLogs()">Print</button>
              <button class="button is-danger" type="button" @click.prevent="isPrint=false">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>
    </div>
    <br>
  </div>
</template>
<script>
export default {
  data() {
    return {
      config: null,
      isLoading: false,
      isPrint: false,
      isScrolled: false,
      perPage: 5,
      search_query: null,
      filterFrom: null,
      filterTo: null,
      filterDateFrom: null,
      filterDateTo: null,
      filterLogName: '',
      filterLogSuggest: [],
      logData: [],
      suggest: [],
      log: {},
      isLog: false
    }
  },
  beforeMount() {
    window.addEventListener('scroll', this.scrollPrint);
  },
  mounted() {
    this.config = {
      headers: {'Authorization': "Bearer " + this.$store.state.token},
    }

    this.$http.get(`${this.$host}/api/parameter/logs`,
      this.config
    ).then((response) => {
      let data = response.data
      this.suggest = data.data
    }).catch((error) => {
      //console.log(error)
    });

    this.getLogs()
  },
  methods: {
    showSuggestLog() {
      this.filterLogSuggest = this.suggest.log.filter((option) => {
                return option && option
                    .toString()
                    .toLowerCase()
                    .indexOf(this.filterLogName.toLowerCase()) >= 0;
            })
    },
    scrollPrint() {
      if (window.scrollY != 0) {
        this.isScrolled = true
      }
      else {
        this.isScrolled = false
      }
    },
    close() {
      this.log = {}
      this.isLog = false
    },
    async loadData() {
      try {
        const response = await this.$http.get(`${this.$host}/api/parameter/logs`, this.config);
        let data = response.data;
        this.suggest = data.data;

        // Now that the data is loaded, you can call the method to filter the logs
        this.getLogs();
      } catch (error) {
        console.error(error);
      }
    },
    getLogs() {
      this.isLoading = true
      this.config.params = {
        search_query: this.search_query,
        filterFrom: this.filterFrom,
        filterTo: this.filterTo,
        perPage: this.perPage
      }
      this.$http.get(`${this.$host}/api/logs/`,
        this.config
      ).then((response) => {
        let data = response.data.data
        this.logData = data
        this.isLoading = false
      }).catch((error) => {
        this.isLoading = false
      });
    },
    openDetails(id) {
      this.isLog = true;
      this.isLoading = true;
      this.$http.get(`${this.$host}/api/logs/search/` + id,
        this.config
      ).then((response) => {
        let data = response.data.data
        this.log = data
        this.isLoading = false;
      }).catch((error) => {
        this.isLoading = false
      })
    },
    activityRangeFilter() {

    },
    print(id) {
      this.isLoading = true
      let config = {
        headers: {'Authorization': "Bearer " + this.$store.state.token}
      };

      this.$http.get(`${this.$host}/api/report/generate/${this.$role.state.id}`,
        config
      ).then((response) => {
        this.isLoading = false
        let token = response.data
        let url = `${this.$host}/api/report/logs/${token}?id=${id}`
        let win = window.open(url, '_blank')
        win.focus()
        this.isPrint = false
      }).catch((error) => {
        this.isLoading = false
      });
    },
    printActivityLogs() {
      this.isLoading = true

      let config = {
        headers: {'Authorization': "Bearer " + this.$store.state.token}
      };

      this.$http.get(`${this.$host}/api/report/generate/${this.$role.state.id}`,
        config
      ).then((response) => {
        this.isLoading = false
        let token = response.data
        let url = `${this.$host}/api/report/logs/${token}?date_from=${this.convertDateString(this.filterDateFrom)}&date_to=${this.convertDateString(this.filterDateTo)}&log_name=${this.filterLogName}`
        let win = window.open(url, '_blank')
        win.focus()
        this.isPrint = false
      }).catch((error) => {
        this.isLoading = false
      });
    },
    convertDateString(dateString) {
      var dateObject = new Date(dateString);
      return dateObject.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      });
    }
  }
}
</script>
<style scoped>
  .fade-enter-active, .fade-leave-active {
    transition: opacity .3s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
  .scroll-print {
    position: fixed;
    z-index: 3;
    top: 90%;
    left: 89%;
  }
  @media screen and (min-width: 1px) and (max-width: 768px)  {
    .scroll-print {
      left: 55%
    }
  }
</style>
