<template>
  <div>
    <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
    <div class="content columns box" style="margin-top: -2px; margin-bottom: 1.5rem; padding: 0;">
      <div class="column is-6">
        <b-field label="Are you related by consanguinity or affinity to the appointing or recommending authority, or to the
        chief of bureau or office or to the person who has immediate supervision over you in the Office, Bureau or Department
        where you will be appointed, within third degree?">
          <b-field>
            <b-select placeholder="Yes/No" v-model="data.is_affiliated_third" :disabled="isDisabled">
              <option value="1">Yes</option>
              <option value="0">No</option>
            </b-select>
            <b-input v-model="data.affiliated_third" :disabled="isDisabled" expanded placeholder="If yes, give details"></b-input>
          </b-field>
        </b-field>
        <b-field>

        </b-field>
        <b-field label="Are you related by consanguinity or affinity to the appointing or recommending authority, or to the
        chief of bureau or office or to the person who has immediate supervision over you in the Office, Bureau or Department
        where you will be appointed, within fourth degree? (for Local Government Unit - Career Employees)">
          <b-field>
            <b-select placeholder="Yes/No" v-model="data.is_affiliated_fourth" :disabled="isDisabled">
              <option value="1">Yes</option>
              <option value="0">No</option>
            </b-select>
            <b-input v-model="data.affiliated_fourth" :disabled="isDisabled" expanded placeholder="If yes, give details"></b-input>
          </b-field>
        </b-field>

        <b-field label="Have you ever been found guilty of any administrative offense?">
          <b-field>
            <b-select placeholder="Yes/No" v-model="data.is_found_guilty" :disabled="isDisabled">
              <option value="1">Yes</option>
              <option value="0">No</option>
            </b-select>
            <b-input v-model="data.found_guilty" :disabled="isDisabled" expanded placeholder="If yes, give details"></b-input>
          </b-field>
        </b-field>

        <b-field label="Have you been criminally charged before any court?">
          <b-field>
            <b-select placeholder="Yes/No" v-model="data.is_criminally_charged" :disabled="isDisabled">
              <option value="1">Yes</option>
              <option value="0">No</option>
            </b-select>
            <b-datepicker
              placeholder="Click to select..."
              icon="calendar-today"
              v-model="data.criminally_charged_date"
              :date-formatter="d => d.toLocaleDateString('en-GB')"
              :disabled="isDisabled"
              editable>
            </b-datepicker>
          </b-field>
        </b-field>

        <b-field label="Criminally Charged Status">
          <b-input v-model="data.criminally_charged_status" :disabled="isDisabled"></b-input>
        </b-field>

        <b-field label="Have you ever been convicted of any crime or violation of any law, decree, ordinance or regulation by
        any court or tribunal?">
          <b-field>
            <b-select placeholder="Yes/No" v-model="data.is_convicted" :disabled="isDisabled">
              <option value="1">Yes</option>
              <option value="0">No</option>
            </b-select>
            <b-input v-model="data.convicted" :disabled="isDisabled" expanded placeholder="If yes, give details"></b-input>
          </b-field>
        </b-field>

        <b-field label="Have you ever been separated from the service in any of the following modes: resignation, retirement,
        dropped from the rolls, dismissal, termination, end of term, finished contract or phased out (abolition) in the public
        or private sector?">
          <b-field>
            <b-select placeholder="Yes/No" v-model="data.is_separated_service" :disabled="isDisabled">
              <option value="1">Yes</option>
              <option value="0">No</option>
            </b-select>
            <b-input v-model="data.separated_service" :disabled="isDisabled" expanded placeholder="If yes, give details"></b-input>
          </b-field>
        </b-field>

        <b-field label="Have you ever been a candidate in a national or local election held within the last year (except
        Barangay election)?">
          <b-field>
            <b-select placeholder="Yes/No" v-model="data.is_candidate" :disabled="isDisabled">
              <option value="1">Yes</option>
              <option value="0">No</option>
            </b-select>
            <b-input v-model="data.candidate" :disabled="isDisabled" expanded placeholder="If yes, give details"></b-input>
          </b-field>
        </b-field>
      </div>
      <div class="column is-6">
        <b-field label="Have you resigned from the government service during the three (3)-month period before the last
        election to promote/actively campaign for a national or local candidate?">
          <b-field>
            <b-select placeholder="Yes/No" v-model="data.is_resigned" :disabled="isDisabled">
              <option value="1">Yes</option>
              <option value="0">No</option>
            </b-select>
            <b-input v-model="data.resigned" :disabled="isDisabled" expanded placeholder="If yes, give details"></b-input>
          </b-field>
        </b-field>

        <b-field label="Have you acquired the status of an immigrant or permanent resident of another country?">
          <b-field>
            <b-select placeholder="Yes/No" v-model="data.is_immigrant" :disabled="isDisabled">
              <option value="1">Yes</option>
              <option value="0">No</option>
            </b-select>
            <b-input v-model="data.immigrant" :disabled="isDisabled" expanded placeholder="If yes, give details (country)"></b-input>
          </b-field>
        </b-field>

        <b-field label="Pursuant to: (a) Indigenous People's Act (RA 8371); (b) Magna Carta for Disabled Persons (RA 7277);
        and (c) Solo Parents Welfare Act of 2000 (RA 8972), please answer the following items:">
        </b-field>

        <b-field label="Are you a member of any indigenous group?">
          <b-field>
            <b-select placeholder="Yes/No" v-model="data.is_indigenous" :disabled="isDisabled">
              <option value="1">Yes</option>
              <option value="0">No</option>
            </b-select>
            <b-input v-model="data.indigenous" :disabled="isDisabled" expanded placeholder="If yes, please specify"></b-input>
          </b-field>
        </b-field>

        <b-field label="Are you a person with disability?">
          <b-field>
            <b-select placeholder="Yes/No" v-model="data.is_disabled" :disabled="isDisabled">
              <option value="1">Yes</option>
              <option value="0">No</option>
            </b-select>
            <b-input v-model="data.disabled" :disabled="isDisabled" expanded placeholder="If yes, please specify ID No."></b-input>
          </b-field>
        </b-field>

        <b-field label="Are you a solo parent?">
          <b-field>
            <b-select placeholder="Yes/No" v-model="data.is_solo" :disabled="isDisabled">
              <option value="1">Yes</option>
              <option value="0">No</option>
            </b-select>
            <b-input v-model="data.solo" :disabled="isDisabled" expanded placeholder="If yes, please specify ID No."></b-input>
          </b-field>
        </b-field>

        <b-field label="Government ID Type">
          <b-input v-model="data.government_id_type" :disabled="isDisabled"></b-input>
        </b-field>
        <b-field label="Government ID Number">
          <b-input v-model="data.government_id_number" :disabled="isDisabled"></b-input>
        </b-field>
        <b-field label="Issued Date">
          <b-datepicker
            placeholder="Click to select..."
            icon="calendar-today"
            v-model="data.issued_date"
            :date-formatter="d => d.toLocaleDateString('en-GB')"
            position="is-top-right"
            :disabled="isDisabled"
            editable>
          </b-datepicker>
        </b-field>
        <b-field label="Issued Place">
          <b-input v-model="data.issued_place" :disabled="isDisabled"></b-input>
        </b-field>
      </div>
    </div>

    <div class="content columns box" style="margin-bottom: 1.5rem; padding: 0;">
      <div class="column is-12">
        <b-field label="REFERENCES"></b-field>
        <button class="button is-primary" @click.prevent="addReference()" :disabled="isDisabled">
          Add Reference
        </button>
        <b-table
          :data="data.references"
          focusable>
          <template slot-scope="props">
            <b-table-column label="Name">
              <b-input v-model="props.row.name" :disabled="isDisabled" required></b-input>
            </b-table-column>
            <b-table-column label="Address">
              <b-input v-model="props.row.address" :disabled="isDisabled" required></b-input>
            </b-table-column>
            <b-table-column label="Telephone">
              <b-input v-model="props.row.telephone" :disabled="isDisabled" required></b-input>
            </b-table-column>
            <b-table-column field="honors">
              <button class="button btn-action is-danger" @click.prevent="deleteRow(props.row.id, 'references')" :disabled="isDisabled"><i class="fa fa-trash"></i></button>
            </b-table-column>
          </template>
        </b-table>
      </div>
    </div>

    <div class="content columns box" style="margin-bottom: 1.5rem; padding: 0;">
      <div class="column is-4">
        <b-table
          :data="data.skills"
          focusable>
          <template slot-scope="props">
            <b-table-column label="Skills">
              {{ props.row.skill }}
            </b-table-column>
            <b-table-column>
              <button class="button btn-action is-danger" @click.prevent="deleteRow(props.row.id, 'skills')" :disabled="isDisabled"><i class="fa fa-trash"></i></button>
            </b-table-column>
          </template>
          <template slot="empty">
            <b-table-column label="Skills">
            </b-table-column>
            <b-table-column>
            </b-table-column>
          </template>
        </b-table>
        <b-field>
          <b-autocomplete
            v-model="m_skill"
            :open-on-focus="true"
            :data="f_skill"
            field="skill"
            @select="option => selected = option"
            :disabled="isDisabled"
            expanded>
          </b-autocomplete>
          <p class="control">
            <button class="button btn-action is-success" @click.prevent="addMany('skill')" :disabled="isDisabled">Add</button>
          </p>
        </b-field>
        </div>
      <div class="column is-4">
        <b-table
          :data="data.recognitions"
          focusable>
          <template slot-scope="props">
            <b-table-column label="Recognitions">
              {{ props.row.recognition }}
            </b-table-column>
            <b-table-column>
              <button class="button btn-action is-danger" @click.prevent="deleteRow(props.row.id, 'recognitions')" :disabled="isDisabled"><i class="fa fa-trash"></i></button>
            </b-table-column>
          </template>
          <template slot="empty">
            <b-table-column label="Recognitions">
            </b-table-column>
            <b-table-column>
            </b-table-column>
          </template>
        </b-table>
        <b-field>
          <b-autocomplete
            v-model="m_recognition"
            :open-on-focus="true"
            :data="f_recognition"
            field="recognition"
            @select="option => selected = option"
            :disabled="isDisabled"
            expanded>
          </b-autocomplete>
          <button class="button btn-action is-success" @click.prevent="addMany('recognition')" :disabled="isDisabled">Add</button>
        </b-field>
      </div>
      <div class="column is-4">
        <b-table
          :data="data.memberships"
          focusable>
          <template slot-scope="props">
            <b-table-column label="Memberships">
              {{ props.row.membership }}
            </b-table-column>
            <b-table-column>
              <button class="button btn-action is-danger" @click.prevent="deleteRow(props.row.id, 'memberships')" :disabled="isDisabled"><i class="fa fa-trash"></i></button>
            </b-table-column>
          </template>
          <template slot="empty">
            <b-table-column label="Memberships">
            </b-table-column>
            <b-table-column>
            </b-table-column>
          </template>
        </b-table>
        <b-field>
          <b-autocomplete
            v-model="m_membership"
            :open-on-focus="true"
            :data="f_membership"
            field="membership"
            @select="option => selected = option"
            :disabled="isDisabled"
            expanded>
          </b-autocomplete>
          <button class="is-pulled-right button btn-action is-success" @click.prevent="addMany('membership')" :disabled="isDisabled">Add</button>
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  props: ["isDisabled", "data"],
  data() {
    return {
      affiliated_third: '',
      skill: '',
      options: {},
      s_skill: [],
      s_recognition: [],
      s_membership: [],
      m_skill: '',
      m_recognition: '',
      m_membership: '',
      skills: [],
      recognitions: [],
      memberships: [],
      isLoading: false
    }
  },
  beforeMount() {
    if (this.data.criminally_charged_date) {
      this.data.criminally_charged_date = new Date(this.data.criminally_charged_date)
    }
    else {
      this.data.criminally_charged_date = null
    }
    if (this.data.issued_date) {
      this.data.issued_date = new Date(this.data.issued_date)
    }
    else {
      this.data.issued_date = null
    }
  },
  mounted() {
    this.getData()
    this.getArray('skill')
    this.getArray('recognition')
    this.getArray('membership')
  },
  computed: {
    f_membership() {
      return this.s_membership.filter((option) => {
        return option.membership
          .toString()
          .toLowerCase()
          .indexOf(this.m_membership.toLowerCase()) >= 0
      })
    },
    f_skill() {
      return this.s_skill.filter((option) => {
        return option.skill
          .toString()
          .toLowerCase()
          .indexOf(this.m_skill.toLowerCase()) >= 0
      })
    },
    f_recognition() {
      return this.s_recognition.filter((option) => {
        return option.recognition
          .toString()
          .toLowerCase()
          .indexOf(this.m_recognition.toLowerCase()) >= 0
      })
    },
  },
  methods: {
    getArray(val) {
      let config = {
        headers: {'Authorization': "Bearer " + this.$store.state.token}
      };
      this.$http.get(`${this.$host}/api/${val}/show`,
        config
      ).then((response) => {
        this['s_'+val] = response.data.data

        //console.log(this[val])
      }).catch((error) => {
        //console.log(error)
      });
    },
    addMany(val, check) {
      this.getArray(val)
      let id;
      let s = "s_" + val
      let m = "m_" + val
      let table = val + "s"
      let obj = this[s].find(x => x[val] === this[m])

      if (obj != undefined) {
        id = obj.id

        this.data[table].push({
          id: id,
          [val]: this[m]
        })
        this[m] = ''
      }
      else if (obj == undefined) {
        this.isLoading = true

        let config = {
          headers: {'Authorization': "Bearer " + this.$store.state.token}
        };
        this.$http.post(`${this.$host}/api/${val}/save/`, {
          [val]: this[m]
          },
          config,
        ).then((response) => {
          this.isLoading = false
          this[m] = ''
          let data = response.data.data
          this.data[table].push({
            id: data.id,
            [val]: data[val]
          })
          //console.log(data)
        }).then(() => {

          let config = {
            headers: {'Authorization': "Bearer " + this.$store.state.token}
          };
          this.$http.get(`${this.$host}/api/${val}/show`,
            config
          ).then((response) => {
            this['s_'+val] = response.data.data

            // let id;
            // let obj = this[s].find(x => x[val] === this[m])
            // id = obj.id

            // this.data[table].push({
            //   id: id,
            //   [val]: this[m]
            // })
            // this[m] = ''
            // //console.log(this[val])
          }).catch((error) => {
            //console.log(error)
          });

        }).catch((error) => {
          this.isLoading = false
          //console.log(error)
        });
      }
      // this.data.skills.push({
      //   skill: this.skill,
      // });
      // this.skill = ''
    },
    pluck(array, key) {
      return array.map(function(item) { return item[key]; });
    },
    returnData() {
      delete this.data.education
      delete this.data.eligibilities
      delete this.data.workexperiences
      delete this.data.volunteerings
      delete this.data.trainings
      let skills = []
      let recognitions = []
      let memberships = []
      if (this.data.skills){
        for (let i = 0; i < this.data.skills.length; i++) {
            skills.push(this.data.skills[i].id)
        }
      }
      if (this.data.recognitions){
        for (let i = 0; i < this.data.recognitions.length; i++) {
            recognitions.push(this.data.recognitions[i].id)
        }
      }
      if (this.data.memberships){
        for (let i = 0; i < this.data.memberships.length; i++) {
            memberships.push(this.data.memberships[i].id)
        }
      }
      delete this.data.skills
      delete this.data.recognitions
      delete this.data.memberships
      let other = {
        is_affiliated_third: this.data.is_affiliated_third,
        affiliated_third: this.data.affiliated_third,
        is_affiliated_fourth: this.data.is_affiliated_fourth,
        affiliated_fourth: this.data.affiliated_fourth,
        is_found_guilty: this.data.is_found_guilty,
        found_guilty: this.data.found_guilty,
        is_criminally_charged: this.data.is_criminally_charged,
        criminally_charged_date: this.criminally_charged_date?(moment(this.criminally_charged_date).format("YYYY-MM-DD")):null,
        criminally_charged_status: this.data.criminally_charged_status,
        is_convicted: this.data.is_convicted,
        convicted: this.data.convicted,
        is_separated_service: this.data.is_separated_service,
        separated_service: this.data.separated_service,
        is_candidate: this.data.is_candidate,
        candidate: this.data.candidate,
        is_resigned: this.data.is_resigned,
        resigned: this.data.resigned,
        is_immigrant: this.data.is_immigrant,
        immigrant: this.data.immigrant,
        is_indigenous: this.data.is_indigenous,
        indigenous: this.data.indigenous,
        is_disabled: this.data.is_disabled,
        disabled: this.data.disabled,
        is_solo: this.data.is_solo,
        solo: this.data.solo,
        government_id_type: this.data.government_id_type,
        government_id_number: this.data.government_id_number,
        issued_date: this.issued_date?(moment(this.data.issued_date).format("YYYY-MM-DD")):null,
        issued_place: this.data.issued_place,
        references: this.data.references,
        skills: skills,
        recognitions: recognitions,
        memberships: memberships,
      }
      this.$emit('other', other)
    },
    addReference() {
      this.data.references.push({
        id: null,
        name: '',
        address: '',
        telephone: '',
      });
    },
    getData() {
      this.affiliated_third = this.data.affiliated_third
      this.is_affiliated_third = this.data.is_affiliated_third
      this.affiliated_fourth = this.data.affiliated_fourth
      this.is_affiliated_fourth = this.data.is_affiliated_fourth
      this.found_guilty = this.data.found_guilty
      this.is_found_guilty = this.data.is_found_guilty
      this.criminally_charged_date = this.data.criminally_charged_date==null?null:new Date(this.data.criminally_charged_date)
      this.criminally_charged_status = this.data.criminally_charged_status
      this.is_criminally_charged = this.data.is_criminally_charged
      this.convicted = this.data.convicted
      this.is_convicted = this.data.is_convicted
      this.separated_service = this.data.separated_service
      this.is_separated_service = this.data.is_separated_service
      this.candidate = this.data.candidate
      this.is_candidate = this.data.is_candidate
      this.resigned = this.data.resigned
      this.is_resigned = this.data.is_resigned
      this.immigrant = this.data.immigrant
      this.is_immigrant = this.data.is_immigrant
      this.indigenous = this.data.indigenous
      this.is_indigenous = this.data.is_indigenous
      this.disabled = this.data.disabled
      this.is_disabled = this.data.is_disabled
      this.solo = this.data.solo
      this.is_solo = this.data.is_solo
      this.government_id_type = this.data.government_id_type
      this.government_id_number = this.data.government_id_number
      this.issued_date = this.data.issued_date==null?null:new Date(this.data.issued_date)
      this.issued_place = this.data.issued_place
    },
    deleteRow(id, arr) {
      this.data[arr] = this.data[arr].filter(x => {
        return x.id != id;
      })
    },
  }
}
</script>
