<template>
  <div class="box">
    <div>
      <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
      <button class="button is-primary" @click.prevent="isPrint = true">
        <span><i class="fa fa-print"></i> Print Selected</span>
      </button>
      <button class="button is-danger" @click.prevent="clearSelected()">
        <span><i class="fa fa-times"></i> Clear Selected</span>
      </button>
      <hr>
      <b-field label="">
        <b-input v-model="search_query" placeholder="Search Employee" />
      </b-field>
      <b-table
        :data="filter"
        focusable
        :paginated="true"
        :per-page="10"
        :checked-rows.sync="checkedRows"
        checkable>
        <template slot-scope="props">
          <b-table-column label="Employee No.">
            {{ props.row.employee_number }}
          </b-table-column>
          <b-table-column label="First Name">
            {{ props.row.first_name }}
          </b-table-column>
          <b-table-column label="Middle Name">
            {{ props.row.middle_name }}
          </b-table-column>
          <b-table-column label="Last Name">
            {{ props.row.last_name }}
          </b-table-column>
          <b-table-column label="Name Extension">
            {{ isNull(props.row.name_extension) }}
          </b-table-column>
          <b-table-column label="Date of Birth">
            {{ props.row.birth_date }}
          </b-table-column>
          <b-table-column label="Division">
            {{ props.row.division }}
          </b-table-column>
          <!-- <b-table-column field="updated_at" label="Updated" sortable centered>
            {{ yesNo(props.row.updated_at) }}
          </b-table-column> -->
        </template>
      </b-table>
    </div>
    <b-modal :active.sync="isPrint" has-modal-card :canCancel=false>
      <transition name="fade">
        <form action="">
          <div class="modal-card" style="height: 35.625rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">Signature Field</p>
            </header>
            <section class="modal-card-body">
              <b-field label="Reference Date">
                <b-input v-model="date_as_of" type="date" expanded />
              </b-field>
              <!-- <b-field label="Employee">
                <b-autocomplete
                  v-model="employee"
                  :data="filteredEmployee"
                  field="full_name"
                  @select="option => { employee_position = option.position, employee_division = option.division }"
                  :open-on-focus=true expanded>
                </b-autocomplete>
              </b-field>
              <b-field label="Employee Position">
                <b-input
                  v-model="employee_position"
                  expanded>
                </b-input>
              </b-field> -->
              <b-field label="Supervisor">
                <b-autocomplete
                  v-model="supervisor"
                  :data="filteredSupervisor"
                  field="full_name"
                  @select="option => { supervisor_division = option.division, supervisor_position = option.position }"
                  :open-on-focus=true expanded>
                </b-autocomplete>
              </b-field>
              <b-field label="Supervisor Position">
                <b-input
                  v-model="supervisor_position"
                  expanded>
                </b-input>
              </b-field>
              <b-field label="Supervisor Division">
                <b-input
                  v-model="supervisor_division"
                  expanded>
                </b-input>
              </b-field><br><br>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" @click.prevent="printSR()">Print</button>
              <button class="button is-danger" type="button" @click.prevent="isPrint=false">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: true,
      search_query: '',
      data: [],
      checkedRows: [],
      employee: '',
      employee_position: '',
      employee_division: '',
      supervisor: '',
      supervisor_position: '',
      supervisor_division: '',
      date_as_of: null,
      isPrint: false,
      suggest: [],
      employees: []
    }
  },
  mounted() {
    let config = {
      headers: {'Authorization': "Bearer " + this.$store.state.token}
    };

    this.$http.get(`${this.$host}/api/employee/search`,
      config
    ).then((response) => {
      let data = response.data.data
      this.data = data
      this.employees = data

      this.isLoading = false
    }).catch((error) => {
      //console.log(error)
    });

    this.$http.get(`${this.$host}/api/parameter/service_record/employee`,
      config
    ).then((response) => {
      let data = response.data
      this.suggest = data

      let i = 0
      for (i in this.suggest) {
        this.suggest[i].full_name = this.suggest[i].full_name.toUpperCase()
      }
    }).catch((error) => {
      //console.log(error)
    });
  },
  computed: {
    filter() {
      var i;
      var name_re = new RegExp(this.search_query, 'i')
      var data = []
      for (i in this.data) {
        if ([this.data[i].employee_number, this.data[i].first_name, this.data[i].middle_name, this.data[i].last_name, this.data[i].division].join(' ').match(name_re)) {
          data.push(this.data[i])
        }
      }
      return data
    },
    filteredSupervisor() {
      return this.suggest.filter((option) => {
        return option.full_name
          .toString()
          .toLowerCase()
          .indexOf(this.supervisor.toLowerCase()) >= 0
      })
    },
    filteredEmployee() {
      return this.suggest.filter((option) => {
        return option.full_name
          .toString()
          .toLowerCase()
          .indexOf(this.employee.toLowerCase()) >= 0
      })
    }
  },
  methods: {
    clearSelected() {
      if (this.checkedRows.length > 0) {
        this.$dialog.confirm ({
          title: 'Clear Selected Rows',
          message: 'Are you sure you want to <b>clear</b> the selected rows? This action cannot be undone.',
          confirmText: 'Confirm',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: () => this.checkedRows = []
        })
      }
    },
    printSR() {
      if (this.checkedRows.length < 1) {
        this.$toast.open({
          message: 'No data selected.',
          type: 'is-danger',
          position: 'is-bottom-right',
          duration: 4000
        })
      }
      else {
        if (!this.checkedRows[0].middle_name) {
          this.checkedRows[0].middle_name = ''
        }
        if (!this.checkedRows[0].name_extension) {
          this.checkedRows[0].name_extension = ''
        }

        this.isLoading = true
        var ids = [];
        this.checkedRows.forEach(employee => {
          ids.push(employee.id)
        });

        let config = {
          headers: {'Authorization': "Bearer " + this.$store.state.token}
        };

        // this.$http.post(`${this.$host}/api/report/generate/${this.$role.state.id}`,
        //   {
        //     general_payroll: {
        //       payroll_group: this.printParams.payroll_group,
        //       month: this.printParams.month,
        //       day: this.printParams.day,
        //       year: this.printParams.year,
        //       payroll_type: this.printParams.payroll_type,
        //       payroll_earning: this.printParams.payroll_earning,
        //       payroll_subgroup: this.printParams.payroll_subgroup,
        //       funding_source: this.printParams.funding_source,
        //       certified_by_name: this.printParams.certified_by_name,
        //       certified_by_position: this.printParams.certified_by_position,
        //       is_finalize: 0,
        //       username: this.$role.state.username,
        //       mors_signatory: this.printParams.mors_signatory,
        //       dv_signatory: this.printParams.dv_signatory,
        //       attachment: this.printParams.attachment
        //     }
        //   },
        //   config
        // ).then((response) => {
        //   this.isLoading = false
        //   let token = response.data

        //   let url = `${this.$host}/api/report/${this.url}/${token}`
        //   let win = window.open(url, '_blank')
        //   win.focus()
        // }).catch((error) => {
        //   //console.log(error)
        //   this.isLoading = false
        // });

        this.$http.post(`${this.$host}/api/report/generate/${this.$role.state.id}`,
          {
            params: {
              employees_list : ids,
              certified_by_name: this.supervisor,
              certified_by_position: this.supervisor_position,
              certified_by_division: this.supervisor_division,
              date_as_of: this.date_as_of
            }
          },
          config
        ).then((response) => {
          this.isLoading = false
          let token = response.data
          // let fullName = `${this.checkedRows[0].first_name} ${this.checkedRows[0].middle_name} ${this.checkedRows[0].last_name} ${this.checkedRows[0].name_extension}`
          let url = `${this.$host}/api/report/certification-leave-credits/${token}`
          let win = window.open(url, '_blank')
          win.focus()
          this.isPrint = false
        }).catch((error) => {
          //console.log(error)
          this.isLoading = false
        });
      }
    },
    isNull(val) {
      if (val == 'null') {
        return ''
      }
      else {
        return val
      }
    }
  }
}
</script>
