<template>
  <div class="box">
    <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
    <b-field label="For Approval"></b-field>
    <b-field grouped group-multiline>
      <button class="button is-primary control" @click.prevent="init()">
        <i class="fa fa-redo-alt"></i> &nbsp;Reset Filters
      </button>
      <b-select placeholder="Per Page" v-model="perPage" style="width: 70px;">
        <option value="5">5 per page</option>
        <option value="10">10 per page</option>
        <option value="20">20 per page</option>
        <option value="31">31 per page</option>
      </b-select>
      <b-select placeholder="Request Type" v-model="requestType" @input="getList">
        <option value='all'>All</option>
        <option
          v-for="option in requestTypes"
          :value="option.id"
          :key="option.id">
          {{ option.name }}
        </option>
      </b-select>
      <b-select placeholder="Status" v-model="request_status" @input="getList">
        <option value="all">All</option>
        <option value="request">Request</option>
        <option value="approved">Approved</option>
        <option value="disapproved">Disapproved</option>
        <option value="cancelled">Cancelled</option>
      </b-select>
      <b-select v-model="perMonth" @input="setMonthFilter" placeholder="Month">
        <option value="1">January</option>
        <option value="2">February</option>
        <option value="3">April</option>
        <option value="4">March</option>
        <option value="5">May</option>
        <option value="6">June</option>
        <option value="7">July</option>
        <option value="8">August</option>
        <option value="9">September</option>
        <option value="10">October</option>
        <option value="11">November</option>
        <option value="12">December</option>
      </b-select>
      <b-datepicker
        style="width: 150px;"
        placeholder="date from"
        icon="calendar-today"
        v-model="filterFrom"
        :date-formatter="d => d.toLocaleDateString('en-GB')"
        @input="setFilterDates"
      >
      </b-datepicker>
      <b-datepicker
        style="width: 150px;"
        placeholder="date to"
        icon="calendar-today"
        v-model="filterTo"
        :date-formatter="d => d.toLocaleDateString('en-GB')"
        @input="setFilterDates"
      >
      </b-datepicker>
      <!-- <b-select placeholder="Status" @input="statusFilter(byStatus)" v-model="byStatus">
        <option value="request">Request</option>
        <option value="approved">Approved</option>
        <option value="disapproved">Disapproved</option>
        <option value="cancelled">Cancelled</option>
        <option value="automatic">Automatic</option>
        <option value="manual">Manual</option>
      </b-select> -->
      <b-input v-model="searchName" placeholder="Enter employee name to search" expanded></b-input>
    </b-field>
    <hr style="margin-top: 0; margin-bottom: 10px;">
    <b-table
    :data="approvalListFilter"
    :bordered=false
    :hoverable=true
    :paginated="true"
    :per-page="perPage"
    detailed
    detail-key="id">
      <template slot-scope="props">
        <b-table-column label="Date Filed" :class="{greyed: props.row.is_counted == 0}" width="200">
          <b-tag type="is-success is-small" v-if="props.row.is_new">New</b-tag> {{ props.row.date_filed }}
        </b-table-column>
        <b-table-column label="Name" :class="{greyed: props.row.is_counted == 0}">
          {{ props.row.name }}
        </b-table-column>
        <b-table-column label="Request Type" :class="{greyed: props.row.is_counted == 0}">
          {{ props.row['request-type'] }}
        </b-table-column>
        <b-table-column label="From" :class="{greyed: props.row.is_counted == 0}">
          {{ props.row.from }}
        </b-table-column>
        <b-table-column label="To" :class="{greyed: props.row.is_counted == 0}">
          {{ props.row.to }}
        </b-table-column>
        <!-- <b-table-column label="Credit" :class="{green: props.row.credit > 0, red: props.row.credit < 0, greyed: props.row.is_counted == 0}">
          {{ props.row.credit }}
        </b-table-column> -->
        <b-table-column label="Status">
          <span style="margin-left: 18px;" v-html="setStatus(props.row.status)"></span>
        </b-table-column>
      </template>
      <template slot="detail" slot-scope="props">
        <article class="media">
          <div class="media-content">
            <div class="content">
              <p>
                <b>Status:</b><br />
                <ul style="margin-top: 0px;">
                  <li v-if="props.row.status_approver_1!=null"><b>Approver 1:</b> <span v-html="setStatus(props.row.status_approver_1)"></span></li>
                  <li v-if="props.row.status_approver_2!=null"><b>Approver 2:</b> <span v-html="setStatus(props.row.status_approver_2)"></span></li>
                  <li v-if="props.row.status_approver_3!=null"><b>Approver 3:</b> <span v-html="setStatus(props.row.status_approver_3)"></span></li>
                  <li v-if="props.row.status_approver_4!=null"><b>Approver 4:</b> <span v-html="setStatus(props.row.status_approver_4)"></span></li>
                </ul>
                <b>Reason:</b><br> {{ props.row.reason }}<br><br>
                <b>Remarks:</b><br> {{ props.row.remarks }}<br><br>
                <b>Proof:</b> <button v-if="props.row.proof" class="button is-primary is-small" @click.prevent="openProof(props.row.proof)"><span>ATTACHMENT LINK</span></button><br/>
                <b>Actions:</b><br>
                <button class="button is-success" @click.prevent="approve(props.row.id)" v-if="props.row.status != 'cancelled'">
                  <i class="fa fa-check"></i> &nbsp;APPROVE
                </button>
                <button class="button is-danger" @click.prevent="disapproveRemarks(props.row.id)" v-if="props.row.status != 'cancelled'">
                  <i class="fa fa-times"></i> &nbsp;DISAPPROVE
                </button>
                <!-- <button class="button is-danger">
                  <i class="fa fa-ban"></i> &nbsp;CANCEL
                </button> -->
              </p>
            </div>
          </div>
        </article>
      </template>
      <template slot="empty">
        <b-table-column label="Date Filed">
        </b-table-column>
        <b-table-column label="Name">
        </b-table-column>
        <b-table-column label="Request Type">
        </b-table-column>
        <b-table-column label="From">
        </b-table-column>
        <b-table-column label="To">
        </b-table-column>
        <b-table-column label="Status">
        </b-table-column>
      </template>
    </b-table>
    <b-modal :active.sync="isApprove" has-modal-card :canCancel=false>
      <transition name="fade">
        <form action="">
          <div class="modal-card" style="margin-top: 4rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">Approve Request</p>
            </header>
            <section class="modal-card-body">
              <b-field label="Remarks">
                <b-input v-model="remarks" expanded></b-input>
              </b-field>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" @click.prevent="approve()">Approve</button>
              <button class="button is-danger" @click.prevent="isApprove = false">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>
    <b-modal :active.sync="isDisapprove" has-modal-card :canCancel=false>
      <transition name="fade">
        <form action="">
          <div class="modal-card" style="margin-top: 4rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">Disapprove Request</p>
            </header>
            <section class="modal-card-body">
              <b-field label="Remarks">
                <b-input v-model="remarks" expanded></b-input>
              </b-field>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" @click.prevent="disapprove()">Save</button>
              <button class="button is-danger" @click.prevent="isDisapprove = false">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>
  </div>
</template>

<script>
  export default {
    computed: {
      approvalListFilter: function() {
        if (this.searchName!='')
          return this.approvalList.filter(function(approval) {
            return approval.name.toUpperCase().includes(this.toUpperCase())
          },this.searchName)
        return this.approvalList;
      }
    },
    props: {
      forApproval: Array,
      is_approver: Boolean,
      url: String,
      requestTypes: Array,
      status: String
    },
    data() {
      return {
        searchName: '',
        attachment_url: this.url,
        isLoading: false,
        config: {
          headers: {
            'Authorization': "Bearer " + this.$store.state.token,
            'Content-Type': 'application/json'
          }
        },
        perPage: 10,
        requestType: 'all',
        perMonth: null,
        month: '',
        filterFrom: null,
        filterTo: null,
        byStatus: null,
        approvalList: this.forApproval,
        dummyTable: [
          {
            id: 1,
            name: 'Test This III',
            from: '2019-10-16',
            to: '2019-10-17',
            credit: '1.25',
            reason: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
            status: 'request'
          },
          {
            id: 2,
            name: 'Abc Defg Hijk',
            from: '2019-10-16',
            to: '2019-10-17',
            credit: '1.25',
            reason: 'lorem ipsum leaving.',
            status: 'request'
          },
          {
            id: 3,
            name: 'John Doe',
            from: '2019-10-16',
            to: '2019-10-17',
            credit: '1.25',
            reason: 'lorem ipsum leaving.',
            status: 'request'
          }
        ],
        remarks: '',
        isApprove: false,
        isDisapprove: false,
        ledger_id: null,
        employee_id: null,
        request_status: this.status,
      }
    },
    methods: {
      setMonthFilter() {
        this.filterFrom=null
        this.filterTo=null
        this.getList()
      },
      setFilterDates() {
        this.perMonth=null
        if (this.filterFrom!=null && this.filterTo!=null)
          this.getList()
      },
      init() {
        this.perPage = 10
        this.requestType = null
        this.perMonth = null
        this.filterFrom = null
        this.filterTo = null
      },
      openProof(proof) {
        let attachment_url = this.$host + '/storage/proofs/' + proof
        let win = window.open(attachment_url, '_blank')
        win.focus()
      },
      approveRemarks(id) {
        this.remarks = ''
        this.ledger_id = id
        this.isApprove = true
      },
      disapproveRemarks(id) {
        this.employee_id = id
        this.remarks = ''
        this.ledger_id = id
        this.isDisapprove = true
      },
      approve(id) {
        this.$dialog.confirm({
          title: 'Approve Request',
          message: 'Are you sure you want to <b>approve</b> this request?',
          confirmText: 'Approve',
          type: 'is-success',
          hasIcon: true,
          onConfirm: () => {
            this.isLoading = true;
            this.$http.put(`${this.$host}/api/approver/approved/${id}`,
              {},
              this.config
            ).then((response) => {
              this.isLoading = false;
              this.isApprove = false
              // this.ledger_id = null
              this.$toast.open({
                message: 'Request approved.',
                type: 'is-success',
                position: 'is-bottom-right',
                duration: 4000
              })
              this.getList();
              // this.$emit('refresh')
            }).catch((error) => {
              this.$toast.open({
                message: 'Error.',
                type: 'is-danger',
                position: 'is-bottom-right',
                duration: 4000
              })
              // this.isApprove = false
              // this.ledger_id = null
              this.isLoading = false
            });
          }
        })

      },
      disapprove() {
        this.$dialog.confirm({
          title: 'Disapprove Request',
          message: 'Are you sure you want to <b>disapprove</b> this request?',
          confirmText: 'Disapprove',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: () => {
            this.isLoading = true
            this.isDisapprove = true
            this.$http.put(`${this.$host}/api/approver/disapproved/${this.employee_id}`,
              {remarks: this.remarks},
              this.config
            ).then((response) => {
              this.employee_id = null
              this.isDisapprove = false;
              this.isLoading = false;
              // this.$emit('refresh')
              this.$toast.open({
                message: 'Request disapproved.',
                type: 'is-success',
                position: 'is-bottom-right',
                duration: 4000
              })
              this.getList()
            }).catch((error) => {
              this.$toast.open({
                message: error,
                type: 'is-danger',
                position: 'is-bottom-right',
                duration: 4000
              })
              this.employee_id = null
              this.isLoading = false
            });
          }
        })
      },
      getList() {
        this.isLoading = true
        let config = {
          headers: {
            'Authorization': "Bearer " + this.$store.state.token,
            'Content-Type': 'application/json'
          },
          params: {
            requestType: this.requestType,
            perMonth: this.perMonth,
            filterFrom: this.filterFrom,
            filterTo: this.filterTo,
            status: this.request_status,
            searchName: this.searchName
          }
        }
        this.$http.get(`${this.$host}/api/approver/ledger/`,
          config
        ).then((response) => {
          let data = response.data.data
          this.approvalList = data
          this.attachment_url = response.data.url
          this.isLoading = false
        }).catch((error) => {
          //console.log(error)
          this.isLoading = false
        });
      },
      setStatus(status) {
        if (status == 'request') {
          return '<span style="color: orange;" title="request" aria-label="request">REQUEST<i class="far fa-clock"></i></span>'
        }
        else if (status == 'approved') {
          return '<span style="color: green" title="approved" aria-label="approved">APPROVED<i class="fas fa-check"></i></span>'
        }
        else if (status == 'disapproved') {
          return '<span style="color: red" title="disapproved" aria-label="disapproved">DISAPPROVED<i class="fas fa-times"></i></span>'
        }
        else if (status == 'cancelled') {
          return '<span style="color: red" title="cancelled" aria-label="cancelled">CANCELLED<i class="fas fa-ban"></i></span>'
        }
        else if (status == 'automatic') {
          return '<span style="color: #0D47A1" title="automatic" aria-label="automatic">AUTOMATIC<i class="fas fa-bullseye"></i></span>'
        }
        else if (status == 'manual') {
          return '<span style="color: #0D47A1" title="manual" aria-label="manual">MANUAL<i class="fas fa-wrench"></i></span>'
        }
        else {
          return status
        }
      },
    }
  }
</script>

<style>
.greyed {
  color: grey !important;
}
.red {
  color: red;
}
.green {
  color: green;
}
</style>
