<template>
  <div class="box">
    <div class="columns">
      <div class="column is-6">
        <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
        <b-field label="Print Type">
          <b-select
            v-model="printType"
            placeholder="Select Action"
            expanded
          >
            <option value="approval">For Approval</option>
            <option value="action">For Appropriate Action</option>
          </b-select>
        </b-field>
        <b-field label="Year">
          <b-select
            v-model="year"
            placeholder="Select Year"
            expanded
          >
            <option v-for="year in years" :key="year" :value="year">{{year}}</option>
          </b-select>
        </b-field>
         <b-field label="Month">
          <b-select v-model="month" placeholder="Month" expanded>
            <option value="05">May</option>
            <option value="11">November</option>
          </b-select>
        </b-field>
        <b-field label="For">
          <b-autocomplete
            v-model="for_employee"
            :data="filteredEmployee(for_employee)"
            field="full_name"
            @select="option => { for_position = option.position, for_division = option.division }"
            :open-on-focus=true expanded>
          </b-autocomplete>
        </b-field>
        <b-field label="For Position">
          <b-input
            v-model="for_position"
            expanded>
          </b-input>
        </b-field>
        <b-field label="For Division">
          <b-input
            v-model="for_division"
            expanded>
          </b-input>
        </b-field>
        <b-field label="Through" v-if="printType=='action'">
          <b-autocomplete
            v-model="through_employee"
            :data="filteredEmployee(through_employee)"
            field="full_name"
            @select="option => { through_position = option.position, through_division = option.division }"
            :open-on-focus=true expanded>
          </b-autocomplete>
        </b-field>
        <b-field label="Through Position" v-if="printType=='action'">
          <b-input
            v-model="through_position"
            expanded>
          </b-input>
        </b-field>
        <b-field label="Through Division" v-if="printType=='action'">
          <b-input
            v-model="through_division"
            expanded>
          </b-input>
        </b-field>
        <b-field label="Signed">
          <b-autocomplete
            v-model="signed_employee"
            :data="filteredEmployee(signed_employee)"
            field="full_name"
            @select="option => { signed_position = option.position, signed_division = option.division }"
            :open-on-focus=true expanded>
          </b-autocomplete>
        </b-field>
        <b-field label="Signed Position">
          <b-input
            v-model="signed_position"
            expanded>
          </b-input>
        </b-field>
        <b-field label="Signed Division">
          <b-input
            v-model="signed_division"
            expanded>
          </b-input>
        </b-field>
        <button class="button is-primary" @click.prevent="print()">
          <span><i class="fa fa-print"></i> Print</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      printType: 'approval',
      year: new Date().getFullYear(),
      month: (new Date().getMonth()+1>5?11:5),
      for_employee: '',
      for_position: '',
      for_division: '',
      through_employee: '',
      through_position: '',
      through_division: '',
      signed_employee: '',
      signed_position: '',
      signed_division: '',
      suggest: [],
      isLoading: false
    }
  },
  mounted() {
    this.isLoading = true
    let config = {
      headers: {'Authorization': "Bearer " + this.$store.state.token}
    };

    this.$http.get(`${this.$host}/api/parameter/service_record/employee`,
      config
    ).then((response) => {
      let data = response.data
      this.suggest = data
      for (let i = 0; i < this.suggest.length; i++) {
        this.suggest[i].full_name = this.suggest[i].full_name.toUpperCase();
      }
      this.isLoading = false
    }).catch((error) => {
      //console.log(error)
      this.isLoading = false
    });
  },
  computed: {
    years() {
      const year = new Date().getFullYear()
      return Array.from({length: (year-1970) + 1},(value,index)=>year-index)
    }
  },
  methods: {
    filteredEmployee(employee) {
      return this.suggest.filter((option) => {
        return option.full_name
          .toString()
          .toLowerCase()
          .indexOf(employee.toLowerCase()) >= 0
      })
    },
    print() {
      this.isLoading = true

      let config = {
        headers: {'Authorization': "Bearer " + this.$store.state.token}
      };

      this.$http.get(`${this.$host}/api/report/generate/${this.$role.state.id}`,
        config
      ).then((response) => {
        this.isLoading = false
        let token = response.data

        let url = `${this.$host}/api/report/monetization/${token}?printType=${this.printType}&year=${this.year}&month=${this.month}&for_employee=${this.for_employee}&for_position=${this.for_position}&through_employee=${this.through_employee}&through_position=${this.through_position}&signed_employee=${this.signed_employee}&signed_position=${this.signed_position}&for_division=${this.for_division}&through_division=${this.through_division}&signed_division=${this.signed_division}`

        let win = window.open(url, '_blank')
        win.focus()
      }).catch((error) => {
        //console.log(error)
        this.isLoading = false
      });
    }
  }
}
</script>

<style>

</style>
