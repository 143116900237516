<template>
  <div>
    <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
    <div class="box">
      <div>
        <b-field>
          <button class="button is-success" @click.prevent="isComponentModalActive = true">
            <i class="fa fa-plus"></i> &nbsp;Group
          </button>&nbsp;
          <button class="button is-primary" @click.prevent="assignGroup">
            <i class="fa fa-users"></i> &nbsp;Assign Group
          </button>
        </b-field>
      </div>
      <hr>
      <b-table
        :data="groups"
        :striped=true
        :bordered=true
        :hoverable=true
        :selected.sync="selected"
        :paginated="true"
        :per-page="5">
        <template slot-scope="props">
          <b-table-column label="Code" width="150">
            {{ props.row.code }}
          </b-table-column>
          <b-table-column label="Description" width="150">
            {{ props.row.description }}
          </b-table-column>
          <b-table-column label="Actions" width="150">
            <button class="button btn-action is-primary is-small" @click.prevent="editRow(props.row)"><i class="fa fa-pen"></i></button>
          </b-table-column>
        </template>
        <template slot="empty">
          <b-table-column label="Code">
          </b-table-column>
          <b-table-column label="Description">
          </b-table-column>
          <b-table-column label="Actions">
          </b-table-column>
        </template>
      </b-table>
    </div>
    <b-modal :active.sync="isComponentModalActive" has-modal-card :canCancel=false>
      <transition name="fade">
        <form action="">
          <div class="modal-card" style="margin-top: 4rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">Group</p>
            </header>
            <section class="modal-card-body">
              <b-field label="Code">
                <b-input v-model="group.code" expanded></b-input>
              </b-field>
              <b-field label="Description">
                <b-input v-model="group.description" expanded></b-input>
              </b-field>
              <br><br><br><br><br><br><br><br>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" @click.prevent="save">Save</button>
              <button class="button is-danger" @click.prevent="close">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>
    <b-modal :active.sync="isAssign" has-modal-card :canCancel=false>
      <transition name="fade">
        <form action="">
          <div class="modal-card pos" style="width: 60rem; height: 35.625rem; margin-left: 100px;">
            <header class="modal-card-head">
              <p class="modal-card-title">Assign Group</p>
            </header>
            <section class="modal-card-body">
              <b-field label="Group">
                <b-select placeholder="Select group" v-model="selectedGroup">
                  <option value=null>ALL</option>
                  <option
                    v-for="option in groups"
                    :value="option.id"
                    :key="option.id">
                    {{ option.code }}
                  </option>
                </b-select>
              </b-field>
              <b-field label="">
                <b-input v-model="search_query" placeholder="Search" />
              </b-field>
              <b-table
                :data="filter"
                focusable
                :striped=true
                :bordered=true
                :checked-rows.sync="checkedRows"
                checkable>
                <template slot-scope="props">
                  <b-table-column label="Employee Number">
                    {{ props.row.employee_number }}
                  </b-table-column>
                  <b-table-column label="First Name">
                    {{ props.row.first_name }}
                  </b-table-column>
                  <b-table-column label="Middle Name">
                    {{ props.row.middle_name }}
                  </b-table-column>
                  <b-table-column label="Last Name">
                    {{ props.row.last_name }}
                  </b-table-column>
                  <b-table-column label="Name Extension">
                    {{ isNull(props.row.name_extension) }}
                  </b-table-column>
                  <b-table-column label="Division">
                    {{ props.row.division }}
                  </b-table-column>
                  <b-table-column label="Group">
                    {{ props.row.group }}
                  </b-table-column>
                  <!-- <b-table-column field="updated_at" label="Updated" sortable centered>
                    {{ yesNo(props.row.updated_at) }}
                  </b-table-column> -->
                </template>
              </b-table>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" @click.prevent="saveAssign">Save</button>
              <button class="button is-danger" @click.prevent="closeAssign">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isComponentModalActive: false,
      state: '',
      selected: {},
      groups: [],
      config: '',
      isLoading: false,
      id: '',
      group: {
        code: '',
        description: ''
      },
      isAssign: false,
      search_query: '',
      checkedRows: [],
      employees: [],
      selectedGroup: ''
    }
  },
  mounted() {
    this.config = {
      headers: {'Authorization': "Bearer " + this.$store.state.token}
    };
    this.getGroups()
  },
  computed: {
    filter() {
      let i;
      let name_re = new RegExp(this.search_query, 'i')
      let data = []
      for (i in this.employees) {
        if ([this.employees[i].employee_number, this.employees[i].first_name, this.employees[i].middle_name, this.employees[i].last_name, this.employees[i].division].join(' ').match(name_re)) {
          data.push(this.employees[i])
        }
      }
      return data
    }
  },
  methods: {
    getGroups() {
      this.isLoading = true
      this.$http.get(`${this.$host}/api/group/all`,
        this.config
      ).then((response) => {
        let data = response.data
        this.groups = data
        this.isLoading = false
      }).catch((error) => {
        this.isLoading = false
      });
    },
    editRow(obj) {
      this.isComponentModalActive = true
      this.id = obj.id
      this.group.code = obj.code
      this.group.description = obj.description
    },
    save() {
      this.isLoading = true
      this.$http.post(`${this.$host}/api/group/save/${this.id}`,
        this.group,
        this.config
      ).then((response) => {
        let data = response.data
        this.isLoading = false
        this.$toast.open({
          message: 'Group successfully saved.',
          type: 'is-success',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.close()
      }).catch((error) => {
        this.isLoading = false
        this.$toast.open({
          message: 'Error saving group.',
          type: 'is-danger',
          position: 'is-bottom-right',
          duration: 4000
        })
      });
    },
    close() {
      this.getGroups()
      this.isComponentModalActive = false
      this.group = {
        code: '',
        description: ''
      }
    },
    saveAssign() {
      this.isLoading = true

      let employee_ids = []
      for (let i = 0; i < this.checkedRows.length; i++) {
        employee_ids.push(this.checkedRows[i].id)
      }

      this.$http.put(`${this.$host}/api/group/assign/`,
        {
          'employee_id': employee_ids,
          'employee_groups_id': this.selectedGroup
        },
        this.config
      ).then((response) => {
        this.isLoading = false
        this.$toast.open({
          message: 'Group assigned successfully.',
          type: 'is-success',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.closeAssign()
      }).catch((error) => {
        this.isLoading = false
        this.$toast.open({
          message: 'Error assigning group.',
          type: 'is-danger',
          position: 'is-bottom-right',
          duration: 4000
        })
      });
    },
    getEmployees() {
      this.$http.get(`${this.$host}/api/employee/search`,
        this.config
      ).then((response) => {
        let data = response.data.data
        this.employees = data
      }).catch((error) => {
        //console.log(error)
      });
    },
    assignGroup() {
      this.getEmployees()
      this.isAssign = true
    },
    closeAssign() {
      this.isAssign = false
      this.checkedRows = []
      this.search_query = ''
    },
    isNull(val) {
      if (val == 'null') {
        return ''
      }
      else {
        return val
      }
    }
  }
}
</script>

<style scoped>
@media screen and (min-width: 1px) and (max-width: 1215px)  {
  .modal-card {
    margin-left: 0px !important;
    width: 100% !important;
  }
  .pos {
    width: none;
  }
}
.pos {
  margin-top: 70px !important;
}
.modal-card-head {
  height: 50px;
}
</style>
