<template>
  <form action="">
    <div class="modal-card pos" style="width: 60rem; height: 35.625rem; margin-left: 100px;">
      <header class="modal-card-head">
        <p class="modal-card-title">Employees</p>
      </header>
      <section class="modal-card-body">
        <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false"></b-loading>
        <b-field label="">
          <b-input v-model="search_query" placeholder="Search Employee" />
        </b-field>
        <b-table
          :data="filter"
          focusable
          :paginated="false"
          :per-page="5"
          :checked-rows.sync="checkedRows"
          checkable>
          <template slot-scope="props">
            <b-table-column label="Emp. #">
              {{ props.row.employee_number }}
            </b-table-column>
            <b-table-column label="Full Name">
              {{ props.row.last_name }}, {{ props.row.first_name }} {{ props.row.middle_name }} {{ isNull(props.row.name_extension) }}
            </b-table-column>
            <b-table-column label="Date of Birth">
              {{ props.row.birth_date }}
            </b-table-column>
            <b-table-column label="Division">
              {{ props.row.division }}
            </b-table-column>
            <!-- <b-table-column field="updated_at" label="Updated" sortable centered>
              {{ yesNo(props.row.updated_at) }}
            </b-table-column> -->
          </template>
        </b-table>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-primary" @click.prevent="$emit('assigned', checkedRows)">Assign</button>
        <button class="button is-danger" type="button" @click.prevent="$parent.close()">Close</button>
      </footer>
    </div>
  </form>
</template>

<script>
export default {
  props: ['training_employees'],
  data() {
    return {
      isLoading: true,
      config: null,
      search_query: '',
      employees: [],
      checkedRows: [],
      training_employees: []
    }
  },
  mounted() {
    this.config = {
      headers: {'Authorization': "Bearer " + this.$store.state.token}
    };

    this.getEmployees()
  },
  computed: {
    filter() {
      var i;
      var name_re = new RegExp(this.search_query, 'i')
      var data = []
      for (i in this.employees) {
        if ([this.employees[i].employee_number, this.employees[i].first_name, this.employees[i].middle_name, this.employees[i].last_name, this.employees[i].division].join(' ').match(name_re)) {
          data.push(this.employees[i])
        }
      }
      return data
    }
  },
  methods: {
    getEmployees() {
      this.isLoading = true
      this.$http.get(`${this.$host}/api/employee/search`,
        this.config
      ).then((response) => {
        let data = response.data.data
        this.employees = data
        this.isLoading = false
        for (let i = 0; i < this.employees.length; i++) {
          for (let j = 0; j < this.training_employees.length; j++) {
            if (this.training_employees[j] == this.employees[i].id) {
              this.checkedRows.push(this.employees[i])
              let employee = this.employees.splice(i, 1)
              //console.log(employee)
              this.employees.unshift(employee[0])
            }
          }
        }
      }).catch((error) => {
        //console.log(error)
        this.isLoading = false
      });
    },
    isNull(val) {
      if (val == 'null') {
        return ''
      }
      else {
        return val
      }
    }
  }
}
</script>

<style scoped>
@media screen and (min-width: 1px) and (max-width: 768px)  {
  .modal-card {
    margin-left: 0px !important;
    width: 100% !important;
  }
  .pos {
    width: none;
  }
}
.pos {
  margin-top: 70px !important;
}
.modal-card-head {
  height: 50px;
}
</style>
