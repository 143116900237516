<template>
  <div class="box">
    <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
    <div>
      <button class="button is-success" @click.prevent="addDeduction()" v-if="$role.state.permissions['write payroll'] || $role.state.permissions['write payroll funding source']">
        <span><i class="fa fa-plus"></i> Add Deduction</span>
      </button>
      <button class="button is-success" @click.prevent="isType = true" v-if="$role.state.permissions['write payroll'] || $role.state.permissions['write payroll funding source']">
        <span><i class="fa fa-money-check"></i> Deduction Types</span>
      </button>
      <b-input v-model="search_query" placeholder="Search" class="is-pulled-right"/>
    </div>
    <hr>

    <b-table
      :data="filter"
      :striped=true
      :bordered=true
      :hoverable=true
      :paginated="true"
      :per-page="5"
      detailed
      :has-detailed-visible="(row) => row.amount_type != 'Amortized' && row.id != 1 && row.id != 2 && row.id != 3"
      detail-key="id">
      <template slot-scope="props">
        <b-table-column label="Code">
          {{ props.row.code }}
        </b-table-column>
        <b-table-column label="Description">
          {{ props.row.description }}
        </b-table-column>
        <b-table-column label="Deduction Type">
          {{ props.row.deduction_type }}
        </b-table-column>
        <b-table-column label="Deduction Frequency">
          {{ props.row.deduction_frequency }}
        </b-table-column>
        <b-table-column label="Priority Level">
          {{ props.row.priority_level }}
        </b-table-column>
        <b-table-column label="Amount Type">
          {{ props.row.amount_type }}
        </b-table-column>
        <b-table-column label="Amount">
          {{ toFormula(props.row.amount, props.row.amount_type) }}
        </b-table-column>
        <!-- <b-table-column label="Amount Taxable">
          {{ props.row.amount_taxable }}
        </b-table-column> -->
        <b-table-column label="Allow Slashes">
          {{ yesNo(props.row.is_slashes) }}
        </b-table-column>
        <b-table-column label="Actions" width="150" v-if="$role.state.permissions['write payroll'] || $role.state.permissions['write payroll funding source']">
          <button class="button btn-action is-success is-small" @click.prevent="addAssignDeduction(props.row)" :disabled="props.row.id == 1 || props.row.id == 2 || props.row.id == 3" v-if="props.row.deduction_frequency != 'Diminishing'"><i class="fa fa-plus"></i></button>
          <button class="button btn-action is-primary is-small" @click.prevent="addDeduction(props.row)"><i class="fa fa-pen"></i></button>
          <button class="button btn-action is-danger is-small" @click.prevent="deleteDeduction(props.row)" :disabled="props.row.id == 1 || props.row.id == 2 || props.row.id == 3"><i class="fa fa-trash"></i></button>
        </b-table-column>
      </template>
      <template slot="empty">
        <b-table-column label="Code">
        </b-table-column>
        <b-table-column label="Description">
        </b-table-column>
        <b-table-column label="Deduction Type">
        </b-table-column>
        <b-table-column label="Deduction Frequency">
        </b-table-column>
        <b-table-column label="Priority Level">
        </b-table-column>
        <b-table-column label="Amount Type">
        </b-table-column>
        <b-table-column label="Amount">
        </b-table-column>
        <!-- <b-table-column label="Amount Taxable">
        </b-table-column> -->
        <b-table-column label="Allow Slashes">
        </b-table-column>
        <b-table-column label="Actions" v-if="$role.state.permissions['write payroll'] || $role.state.permissions['write payroll funding source']">
        </b-table-column>
      </template>
      <template slot="detail" slot-scope="props" v-if="props.row.deduction_frequency != 'Diminishing'">
          <article class="media">
            <div class="media-content">
              <div class="content">
                <b-table
                  :data="props.row.employee_deductions"
                  :bordered=true
                  :hoverable=true
                  :paginated=true
                  :per-page=5
                  :pagination-simple=true>
                  <template slot-scope="props">
                    <b-table-column label="Reference Date">
                      {{ props.row.ref_date }}
                    </b-table-column>
                     <b-table-column label="Payroll Type" expanded>
                      {{ props.row.payroll_type }}
                    </b-table-column>
                     <b-table-column label="Amount">
                      {{ toFormula(props.row.amount, props.row.amount_type) }}
                    </b-table-column>
                     <!-- <b-table-column label="Amount Taxable">
                      {{ props.row.amount_taxable }}
                    </b-table-column> -->
                    <b-table-column label="Actions" v-if="$role.state.permissions['write payroll'] || $role.state.permissions['write payroll funding source']">
                      <button class="button btn-action is-primary is-small" @click.prevent="assignDeduction(props.row)"><i class="fa fa-user-plus"></i></button>
                      <button class="button btn-action is-primary is-small" @click.prevent="editDeduction(props.row)"><i class="fa fa-pen"></i></button>
                    </b-table-column>
                  </template>
                  <template slot="empty">
                    <b-table-column label="Reference Date">
                    </b-table-column>
                     <b-table-column label="Payroll Type">
                    </b-table-column>
                     <b-table-column label="Amount">
                    </b-table-column>
                     <!-- <b-table-column label="Amount Taxable">
                    </b-table-column> -->
                    <b-table-column label="Actions" v-if="$role.state.permissions['write payroll'] || $role.state.permissions['write payroll funding source']">
                    </b-table-column>
                  </template>
                </b-table>
              </div>
            </div>
          </article>
        </template>
    </b-table>

    <b-modal :active.sync="isSave" has-modal-card :canCancel=false>
      <transition name="fade">
        <form @submit.prevent="save()">
          <div class="modal-card" style="height: 35.625rem; margin-top: 4rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">{{ state }} Deduction</p>
            </header>
            <section class="modal-card-body" v-if="deduction.id == 1 || deduction.id == 2 || deduction.id == 3">
              <b-field label="Description">
                <b-input
                  v-model="deduction.description"
                  expanded required>
                </b-input>
              </b-field>
            </section>
            <section class="modal-card-body" v-if="deduction.id != 1 && deduction.id != 2 && deduction.id != 3">
              <b-field label="Code">
                <b-input
                  v-model="deduction.code"
                  expanded required>
                </b-input>
              </b-field>
              <b-checkbox v-model="deduction.use_code" true-value="1" false-value="0">
                Use Code For Display
              </b-checkbox>
              <b-field label="Description">
                <b-input
                  v-model="deduction.description"
                  expanded required>
                </b-input>
              </b-field>
              <b-field label="Journal Entry">
                <b-input
                  v-model="deduction.journal_entry"
                  @input="setSpace('deduction')"
                  expanded>
                </b-input>
              </b-field>
              <b-field label="Type">
                <b-select placeholder="Deduction Type" v-model="deduction.deduction_type_id" expanded required>
                  <option
                    v-for="option in types"
                    :value="option.id"
                    :key="option.id">
                    {{ option.code }}
                  </option>
                </b-select>
              </b-field>
              <b-field label="Frequency">
                <b-select placeholder="Deduction Frequency" v-model="deduction.deduction_frequency"
                @input="isDiminishing()" :disabled="state == 'Edit'" expanded required>
                  <option value="One Time">One Time</option>
                  <option value="Perpetual">Perpetual</option>
                  <option value="Diminishing">Diminishing</option>
                  <option value="Exempt">Exempt</option>
                </b-select>
              </b-field>
              <b-field label="Priority Level (1 being the highest priority)">
                <b-input
                  v-model="deduction.priority_level"
                  min="1"
                  type="number"
                  expanded required>
                </b-input>
              </b-field>
              <b-field label="Amount Type">
                <b-select placeholder="Amount Type" @input="clearType()" v-model="deduction.amount_type"
                :disabled="deduction.deduction_frequency == 'Diminishing' || state == 'Edit'" expanded required>
                  <option value="Fixed">Fixed</option>
                  <option value="Formula">Formula Based</option>
                  <option value="Amortized">Amortized</option>
                </b-select>
              </b-field>
              <b-field label="Fixed Amount" v-if="deduction.amount_type == 'Fixed'">
                <b-input
                  v-model="deduction.fixed_amount"
                  step="0.01"
                  type="number"
                  expanded required>
                </b-input>
              </b-field>
              <!-- <b-field label="Fixed Amount Taxable" v-if="deduction.amount_type == 'Fixed'">
                <b-input
                  v-model="deduction.fixed_amount_taxable"
                  step="0.01"
                  type="number"
                  expanded required>
                </b-input>
              </b-field> -->
              <b-field label="Formula Amount" v-if="deduction.amount_type == 'Formula'">
                <!-- <b-input
                  v-model="deduction.formula_amount"
                  expanded required>
                </b-input> -->
                <b-field>
                  <b-input
                    v-model="formulaDisplay"
                    disabled expanded required>
                  </b-input>
                  <p class="control">
                    <button class="button is-primary" @click.prevent="openFormula()">
                      <span><i class="fa fa-calculator"></i></span>
                    </button>
                  </p>
                </b-field>
              </b-field>
              <!-- <b-field label="Formula Amount Taxable" v-if="deduction.amount_type == 'Formula'">
                <b-input
                  v-model="deduction.formula_amount_taxable"
                  expanded required>
                </b-input>
              </b-field> -->
              <b-field label="Allow Slashes" v-if="deduction.amount_type == 'Amortized'">
                <b-checkbox true-value="1" false-value="0" v-model="deduction.is_slashes">
              </b-checkbox>
              </b-field>
              <br>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" type="submit">Save</button>
              <button class="button is-danger" type="button" @click.prevent="close()">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>

    <b-modal :active.sync="isAssign" has-modal-card :canCancel=false>
      <transition name="fade">
        <form @submit.prevent="assign()">
          <div class="modal-card pos" style="width: 60rem; height: 35.625rem; margin-left: 100px;">
            <header class="modal-card-head">
              <p class="modal-card-title">Assign Deduction</p>
            </header>
            <section class="modal-card-body">
              <b-field grouped group-multiline>
                <b-select v-model="perPageDeductions">
                  <option value="10">10 per page</option>
                  <option value="50">50 per page</option>
                  <option value="100">100 per page</option>
                  <option value="1000">1000 per page</option>
                </b-select>
                <b-input v-model="search_list" placeholder="Search Employee" expanded/>
              </b-field>
              <br>
              <b-table
                :data="filterList"
                :striped=true
                :bordered=true
                :hoverable=true
                :paginated="true"
                :per-page="perPageDeductions"
                :checked-rows.sync="checkedRows"
                checkable>
                <template slot-scope="props">
                  <b-table-column label="Emp No." width="100">
                    {{ props.row.employee_number }}
                  </b-table-column>
                  <b-table-column label="Full Name">
                    {{ props.row.fullname }}
                  </b-table-column>
                  <b-table-column label="Position">
                    {{ props.row.position }}
                  </b-table-column>
                  <b-table-column label="Division">
                    {{ props.row.division }}
                  </b-table-column>
                </template>
                <template slot="empty">

                </template>
              </b-table>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" type="submit">Assign</button>
              <button class="button is-danger" type="button" @click.prevent="closeAssign()">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>

    <b-modal :active.sync="isAdd" has-modal-card :canCancel=false>
      <transition name="fade">
        <form @submit.prevent="saveAssignDeduction()">
          <div class="modal-card" style="height: 35.625rem; margin-top: 4rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">{{ state }} Deduction</p>
            </header>
            <section class="modal-card-body">
              <b-field label="Reference Date" v-if="deduction.deduction_frequency != 'Perpetual'">
                <b-datepicker
                  placeholder="reference date"
                  icon="calendar-today"
                  v-model="newDeduction.ref_date"
                  :date-formatter="d => d.toLocaleDateString('en-GB')"
                  :date-parser="d => parseDate(d)"
                  required
                  editable>
                </b-datepicker>
              </b-field>
              <b-field label="Payroll Type">
                <b-select v-model="newDeduction.payroll_type" required expanded>
                  <option value="Regular">REGULAR</option>
                  <option value="Special" v-if="deduction.deduction_frequency != 'Perpetual'">OTHER BENEFITS</option>
                </b-select>
              </b-field>
              <b-field label="Fixed Amount" v-if="deduction.fixed_amount">
                <b-input
                  v-model="newDeduction.fixed_amount"
                  type="number" step="0.01"
                  expanded required>
                </b-input>
              </b-field>
              <!-- <b-field label="Fixed Amount Taxable" v-if="deduction.fixed_amount_taxable">
                <b-input
                  v-model="newDeduction.fixed_amount_taxable"
                  type="number" step="0.01"
                  expanded required>
                </b-input>
              </b-field> -->
              <b-field label="Formula Amount" v-if="deduction.formula_amount">
                <!-- <b-input
                  v-model="newDeduction.formula_amount"
                  expanded required>
                </b-input> -->
                <b-field>
                  <b-input
                    v-model="formulaDisplay2"
                    disabled expanded required>
                  </b-input>
                  <p class="control">
                    <button class="button is-primary" @click.prevent="openFormula2()">
                      <span><i class="fa fa-calculator"></i></span>
                    </button>
                  </p>
                </b-field>
              </b-field>
              <!-- <b-field label="Formula Amount Taxable" v-if="deduction.formula_amount_taxable">
                <b-input
                  v-model="newDeduction.formula_amount_taxable"
                  expanded required>
                </b-input>
              </b-field> -->
              <br>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" type="submit">Save</button>
              <button class="button is-danger" type="button" @click.prevent="closeAdd()">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>

    <b-modal :active.sync="isType" has-modal-card>
      <Type title="Deduction" :types="types" :isSave="isTypeSave" @getTypes="getDeductionTypes" @save="saveDeductionTypes" @delete="deleteDeductionTypes" />
    </b-modal>

    <b-modal :active.sync="isFormula" has-modal-card :canCancel=false>
      <Formula title="Deduction" :variables="deductionsParams" :isOperator="isOperator" @input="setFormula" :formula="deduction.formula_amount" />
    </b-modal>
    <b-modal :active.sync="isFormula2" has-modal-card :canCancel=false>
      <Formula2 title="Deduction" :variables="deductionsParams" :isOperator="isOperator2" @input="setFormula2" :formula="newDeduction.formula_amount" />
    </b-modal>
  </div>
</template>

<script>
import Type from './Type.vue'
import Formula from '../Formula.vue'
import Formula2 from '../Formula.vue'
import moment from 'moment'

export default {
  components: {
    Type,
    Formula,
    Formula2
  },
  data() {
    return {
      config: '',
      search_query: '',
      search_list: '',
      isLoading: false,
      isAdd: false,
      isSave: false,
      state: '',
      deductions: [],
      deduction: {
        use_code: 0,
        amount_type: 'fixed',
        is_slashes: '0',
        formula_amount: []
      },
      isType: false,
      isTypeSave: false,
      isAssign: false,
      list: [],
      types: [],
      perPageDeductions: 10,
      checkedRows: [],
      deductionAttach: {},
      newDeduction: {
        ref_date: null,
        formula_amount: []
      },
      prevDeduction: {},
      isFormula: false,
      deductionsParams: [],
      isOperator: false,
      isFormula2: false,
      isOperator2: false,
    }
  },
  mounted() {
    this.config = {
      headers: {'Authorization': "Bearer " + this.$store.state.token}
    };

    this.getDeductions()
    this.getDeductionTypes()
    this.getDeductionsParams()
  },
  computed: {
    formulaDisplay() {
      if (this.deduction.formula_amount) {
        let formula
        let formulaArr
        formulaArr = this.deduction.formula_amount.map(x => x = x.code)
        formula = formulaArr.join(' ')
        return formula
      }
    },
    formulaDisplay2() {
      if (this.newDeduction.formula_amount) {
        let formula
        let formulaArr
        formulaArr = this.newDeduction.formula_amount.map(x => x = x.code)
        formula = formulaArr.join(' ')
        return formula
      }
    },
    filter() {
      var i;
      var name_re = new RegExp(this.search_query, 'i')
      var data = []
      for (i in this.deductions) {
        if ([this.deductions[i].code, this.deductions[i].description, this.deductions[i].deduction_type, this.deductions[i].deduction_frequency, this.deductions[i].amount_type, this.deductions[i].default_value].join(' ').match(name_re)) {
          data.push(this.deductions[i])
        }
      }
      return data
    },
    filterList() {
      var i;
      var name_re = new RegExp(this.search_list, 'i')
      var data = []
      for (i in this.list) {
        if ([this.list[i].employee_number, this.list[i].fullname, this.list[i].position, this.list[i].division].join(' ').match(name_re)) {
          data.push(this.list[i])
        }
      }
      return data
    },
  },
  methods: {
    setSpace(type) {
      let arrJe = this[type].journal_entry.split('')
      if (arrJe.length > 8) {
        if (arrJe[8] != ' ') {
          if (arrJe[9] == ' ') {
            arrJe.splice(9, 1)
          }
          arrJe.splice(8, 0, ' ')
        }
        if (arrJe.length > 11) {
          if (arrJe[11] != ' ') {
            if (arrJe[12] == ' ') {
              arrJe.splice(12, 1)
            }
            arrJe.splice(11, 0, ' ')
          }
          this[type].journal_entry = arrJe.join('')
        }
        else {
          this[type].journal_entry = arrJe.join('')
        }
      }
    },
    toFormula(formula, type) {
      if (type == 'Formula' && formula) {
        return formula.map(x => x = x.code).join(' ')
      }
      else {
        return formula
      }
    },
    getDeductionsParams() {
      this.isLoading = true

      this.$http.get(`${this.$host}/api/parameter/payroll/deductions`,
        this.config
      ).then((response) => {
        let data = response.data
        this.deductionsParams = data
        this.isLoading = false
      }).catch((error) => {
        this.isLoading = false
        //console.log(error)
      });
    },
    openFormula() {
      this.isFormula = true
    },
    popFormula() {
      if (this.deduction.formula_amount.length > 0) {
        this.isOperator = !this.isOperator
        this.deduction.formula_amount.pop()
      }
    },
    setFormula(obj, bool) {
      this.deduction.formula_amount = obj
      this.isOperator = bool
    },
    openFormula2() {
      this.isFormula2 = true
    },
    popFormula2() {
      if (this.newDeduction.formula_amount.length > 0) {
        this.isOperator2 = !this.isOperator2
        this.newDeduction.formula_amount.pop()
      }
    },
    setFormula2(obj, bool) {
      this.newDeduction.formula_amount = obj
      this.isOperator2 = bool
    },
    yesNo(bool) {
      if (bool == 1) {
        return 'YES'
      }
      else if (bool == 0) {
        return 'NO'
      }
      else {
        return 'INVALID'
      }
    },
    parseDate(d) {
      let parts = d.split("/")
      let dt = new Date(parseInt(parts[2], 10),
        parseInt(parts[1], 10) - 1,
        parseInt(parts[0], 10))
      return dt
    },
    assign() {
      this.deductionAttach.employee_id = this.checkedRows.map(x => x = x.id)
      let deductionAttach = JSON.parse(JSON.stringify(this.deductionAttach))
      if (deductionAttach.formula_amount) {
        deductionAttach.formula_amount = JSON.stringify(deductionAttach.formula_amount.map(x => x = x.id))
      }
      this.isLoading = true
      this.$http.post(`${this.$host}/api/payroll/deduction/attach/`,
        deductionAttach,
        this.config,
      ).then((response) => {
        this.$toast.open({
          message: 'Deduction succesfully attached.',
          type: 'is-success',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.checkedRows = []
        this.isLoading = false
        this.closeAssign()
      }).catch((error) => {
        this.isLoading = false
        this.$toast.open({
          message: 'Error attaching deduction.',
          type: 'is-danger',
          position: 'is-bottom-right',
          duration: 4000
        })
      });
    },
    closeAdd() {
      this.getDeductions()
      this.getDeductionsParams()
      this.isAdd = false
      this.newDeduction = {
        ref_date: null,
        formula_amount: []
      }
      this.deduction = {
        use_code: 0,
        is_slashes: '0',
        formula_amount: []
      }
    },
    addAssignDeduction(obj) {
      if (obj.deduction_frequency == 'Perpetual') {
        this.newDeduction.payroll_type = 'Regular'
      }
      if (obj) {
        this.newDeduction.deduction_id = obj.id
        this.deduction = JSON.parse(JSON.stringify(obj))
        if (obj.ref_date)
          this.newDeduction.ref_date = new Date(obj.ref_date)
      }
      if (!this.newDeduction.fixed_amount) {
        this.newDeduction.fixed_amount = null
      }
      // if (!this.newDeduction.fixed_amount_taxable) {
      //   this.newDeduction.fixed_amount_taxable = null
      // }
      if (!this.newDeduction.formula_amount || this.newDeduction.formula_amount.length < 1) {
        this.newDeduction.formula_amount = null
      }
      // if (!this.newDeduction.formula_amount_taxable) {
      //   this.newDeduction.formula_amount_taxable = null
      // }
      if (obj.formula_amount) {
        if (lastVar = obj.formula_amount[obj.formula_amount.length - 1].code != undefined) {
          let lastVar = obj.formula_amount[obj.formula_amount.length - 1].code
          if (lastVar == '+' || lastVar == '-' || lastVar == '*' || lastVar == '/') {
            this.isOperator2 = false
          }
          else {
            this.isOperator2 = true
          }
        }
      }

      this.newDeduction.fixed_amount = obj.fixed_amount
      this.newDeduction.formula_amount = obj.formula_amount

      this.state = 'Add'
      this.isAdd = true
    },
    editDeduction(obj) {
      if (obj) {
        this.newDeduction.deduction_id = obj.id
        this.deduction = JSON.parse(JSON.stringify(obj))
        this.newDeduction = JSON.parse(JSON.stringify(obj))
        if (obj.ref_date) {
          this.newDeduction.ref_date = new Date(obj.ref_date)
        }
        this.prevDeduction = JSON.parse(JSON.stringify(this.newDeduction))
      }
      if (!this.newDeduction.fixed_amount) {
        this.newDeduction.fixed_amount = null
      }
      // if (!this.newDeduction.fixed_amount_taxable) {
      //   this.newDeduction.fixed_amount_taxable = null
      // }
      if (!this.newDeduction.formula_amount) {
        this.newDeduction.formula_amount = null
      }
      // if (!this.newDeduction.formula_amount_taxable) {
      //   this.newDeduction.formula_amount_taxable = null
      // }
      if (obj.formula_amount) {
        let lastVar = obj.formula_amount[obj.formula_amount.length - 1].code
        if (lastVar == '+' || lastVar == '-' || lastVar == '*' || lastVar == '/') {
          this.isOperator2 = false
        }
        else {
          this.isOperator2 = true
        }
      }

      this.state = 'Edit'
      this.isAdd = true
    },
    saveAssignDeduction() {
      let deduction = JSON.parse(JSON.stringify(this.newDeduction))
      if (deduction.formula_amount) {
        deduction.formula_amount = JSON.stringify(deduction.formula_amount.map(x => x = x.id))
      }
      if (deduction.ref_date && deduction.ref_date != '') {
        deduction.ref_date = moment(this.deduction.ref_date).format("YYYY-MM-DD")
      }
      this.isLoading = true
      if (this.state == 'Add') {
        this.$http.post(`${this.$host}/api/payroll/deduction/add/`,
          deduction,
          this.config,
        ).then((response) => {
          let data = response.data
          this.$toast.open({
            message: 'Data successfully saved.',
            type: 'is-success',
            position: 'is-bottom-right',
            duration: 4000
          })
          //console.log(data)
          this.isLoading = false
          this.isAdd = false
          this.newDeduction = {
            ref_date: null,
            formula_amount: []
          }
          this.getDeductions()
        }).catch((error) => {
          this.$toast.open({
            message: 'Error saving data.',
            type: 'is-danger',
            position: 'is-bottom-right',
            duration: 4000
          })
          this.isLoading = false
        });
      }
      else if (this.state == 'Edit') {
        this.$http.put(`${this.$host}/api/payroll/deduction/edit/${this.newDeduction.deduction_id}`,
          {
            ref_date: (this.prevDeduction.ref_date && this.prevDeduction.ref_date != '') ? moment(this.prevDeduction.ref_date).format("YYYY-MM-DD") : null,
            payroll_type: this.prevDeduction.payroll_type,
            fixed_amount: this.prevDeduction.fixed_amount,
            // fixed_amount_taxable: this.prevDeduction.fixed_amount_taxable,
            formula_amount: (this.prevDeduction.formula_amount) ? JSON.stringify(this.prevDeduction.formula_amount.map(x => x = x.id)) : null,
            // formula_amount_taxable: this.prevDeduction.formula_amount_taxable,
            new_ref_date: (this.newDeduction.ref_date && this.newDeduction.ref_date != '') ? moment(this.newDeduction.ref_date).format("YYYY-MM-DD") : null,
            new_payroll_type: this.newDeduction.payroll_type,
            new_fixed_amount: this.newDeduction.fixed_amount,
            // new_fixed_amount_taxable: this.newDeduction.fixed_amount_taxable,
            new_formula_amount: (this.newDeduction.formula_amount) ? JSON.stringify(this.newDeduction.formula_amount.map(x => x = x.id)) : null,
            // new_formula_amount_taxable: this.newDeduction.formula_amount_taxable,
          },
          this.config,
        ).then((response) => {
          let data = response.data
          this.$toast.open({
            message: 'Data successfully saved.',
            type: 'is-success',
            position: 'is-bottom-right',
            duration: 4000
          })
          //console.log(data)
          this.isLoading = false
          this.isAdd = false
          this.newDeduction = {
            ref_date: null,
            formula_amount: []
          }
          this.getDeductions()
        }).catch((error) => {
          this.$toast.open({
            message: 'Error saving data.',
            type: 'is-danger',
            position: 'is-bottom-right',
            duration: 4000
          })
          this.isLoading = false
        });
      }
      else {
        this.isLoading = false
        this.$toast.open({
          message: 'Error connecting to server.',
          type: 'is-danger',
          position: 'is-bottom-right',
          duration: 4000
        })
      }
    },
    assignDeduction(obj) {
      this.checkedRows = []
      this.deductionAttach = obj

      this.isLoading = true
      this.$http.get(`${this.$host}/api/payroll/setting/list/?sort=`,
        this.config
      ).then((response) => {
        let data = response.data
        this.list = data
        this.isLoading = false
        for (let i = 0; i < this.list.length; i++) {
          for (let j = 0; j < obj.employee_id.length; j++) {
            if (obj.employee_id[j] == this.list[i].id) {
              this.checkedRows.push(this.list[i])
              let employee = this.list.splice(i, 1)
              //console.log(employee)
              this.list.unshift(employee[0])
            }
          }
        }
        this.isAssign = true
      }).catch((error) => {
        //console.log(error)
        this.isLoading = false
      });
    },
    getEmployees() {
      this.isLoading = true

      this.$http.get(`${this.$host}/api/payroll/setting/list/?sort=`,
        this.config
      ).then((response) => {
        let data = response.data
        this.list = data
        this.isLoading = false
      }).catch((error) => {
        this.isLoading = false
        //console.log(error)
      });
    },
    closeAssign() {
      this.getDeductions()
      this.isAssign = false
    },
    getDeductionTypes() {
      this.isLoading = true

      this.$http.get(`${this.$host}/api/payroll/deduction/type/show/`,
        this.config
      ).then((response) => {
        let data = response.data
        this.types = data
        this.isLoading = false
      }).catch((error) => {
        this.isLoading = false
        //console.log(error)
      });
    },
    saveDeductionTypes(obj) {
      this.isLoading = true

      let id = ''
      if (obj.id) {
        id = obj.id
      }

      this.$http.post(`${this.$host}/api/payroll/deduction/type/save/${id}`,
        obj,
        this.config,
      ).then((response) => {
        let data = response.data
        this.$toast.open({
          message: 'Data successfully saved.',
          type: 'is-success',
          position: 'is-bottom-right',
          duration: 4000
        })
        //console.log(data)
        this.isLoading = false
        this.isTypeSave = false
        this.getDeductionTypes()
      }).catch((error) => {
        this.$toast.open({
          message: 'Error saving data.',
          type: 'is-danger',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.isLoading = false
      });
    },
    deleteDeductionTypes(id) {
      this.isLoading = true

      this.$http.delete(`${this.$host}/api/payroll/deduction/type/delete/${id}`,
        this.config
      ).then((response) => {
        this.$toast.open({
          message: 'Row deleted.',
          type: 'is-success',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.getDeductionTypes()
      }).catch((error) => {
        this.$toast.open({
          message: 'Error deleting row.',
          type: 'is-danger',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.isLoading = false
      });
    },
    getDeductions() {
      this.isLoading = true

      this.$http.get(`${this.$host}/api/payroll/deduction/setting/show/`,
        this.config
      ).then((response) => {
        let data = response.data
        this.deductions = data
        this.isLoading = false
      }).catch((error) => {
        this.isLoading = false
        //console.log(error)
      });
    },
    addDeduction(obj) {
      this.isLoading = true

      this.$http.get(`${this.$host}/api/parameter/payroll/deductions`,
        this.config
      ).then((response) => {
        let data = response.data
        this.deductionsParams = data
        this.isLoading = false
          if (obj) {
          this.deduction = obj
          this.state = 'Edit'
          if (this.deduction.formula_amount) {
            if (this.deduction.formula_amount.length > 0) {
              let lastVar = this.deduction.formula_amount[this.deduction.formula_amount.length - 1].code
              if (lastVar == '+' || lastVar == '-' || lastVar == '*' || lastVar == '/') {
                this.isOperator = false
              }
              else {
                this.isOperator = true
              }
            }
          }
        }
        else {
          this.state = 'Add'
        }
        this.isSave = true
      }).catch((error) => {
        this.isLoading = false
        //console.log(error)
      });
    },
    save() {
      this.isLoading = true

      let id = ''
      if (this.deduction.id) {
        id = this.deduction.id
      }
      let deduction
      deduction = JSON.parse(JSON.stringify(this.deduction))
      if (deduction.amount_type == 'Amortized') {
        deduction.formula_amount = null
      }
      if (deduction.formula_amount) {
        deduction.formula_amount = JSON.stringify(deduction.formula_amount.map(x => x = x.id))
      }

      this.$http.post(`${this.$host}/api/payroll/deduction/setting/save/${id}`,
        deduction,
        this.config,
      ).then((response) => {
        let data = response.data
        this.$toast.open({
          message: 'Data successfully saved.',
          type: 'is-success',
          position: 'is-bottom-right',
          duration: 4000
        })
        //console.log(data)
        this.isLoading = false
        this.close()
      }).catch((error) => {
        this.$toast.open({
          message: 'Error saving data.',
          type: 'is-danger',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.isLoading = false
      });
    },
    deleteDeduction(obj) {
      this.$dialog.confirm({
        title: 'Delete Deduction',
        message: "Are you sure you want to delete this row? This action cannot be undone.",
        confirmText: 'Delete',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isLoading = true

          this.$http.delete(`${this.$host}/api/payroll/deduction/setting/delete/${obj.id}`,
            this.config
          ).then((response) => {
            this.$toast.open({
              message: 'Row deleted.',
              type: 'is-success',
              position: 'is-bottom-right',
              duration: 4000
            })
            this.isLoading = false
            this.getDeductions()
          }).catch((error) => {
            this.$toast.open({
              message: 'Error deleting row.',
              type: 'is-danger',
              position: 'is-bottom-right',
              duration: 4000
            })
            this.isLoading = false
          });
        }
      })
    },
    close() {
      this.getDeductions()
      this.getDeductionsParams()
      this.isSave = false
      this.deduction = {
        use_code: 0,
        amount_type: 'Fixed',
        is_slashes: '0',
        formula_amount: []
      }
      this.isOperator = false
    },
    clearType() {
      if (this.deduction.amount_type == 'Fixed') {
        this.deduction.formula_amount = null
      }
      else if (this.deduction.amount_type == 'Formula') {
        this.deduction.fixed_amount = null
        this.deduction.formula_amount = []
        this.isOperator = false
      }
      else if (this.deduction.amount_type == 'Amortized') {
        this.deduction.deduction_frequency = 'Diminishing'
        this.deduction.formula_amount = null
      }
    },
    isDiminishing() {
      this.deduction.is_slashes = 0
      if (this.deduction.deduction_frequency == 'Diminishing') {
        this.deduction.amount_type = 'Amortized'
      }
      else if (this.deduction.deduction_frequency != 'Diminishing' && this.deduction.amount_type == 'Amortized') {
        this.deduction.amount_type = null
      }
    }
  }
}
</script>

<style>

</style>
