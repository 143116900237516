<template>
  <div class="box">
    <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
    <div>
      <b-field>
      <b-select placeholder="Tranche" v-model="perTranche">
        <option
          v-for="tranche in tranches"
          :value="tranche"
          :key="tranche">
          Tranche {{ tranche }}
        </option>
      </b-select>&nbsp;
      <button class="button is-success" @click.prevent="addTranche()">
        <span><i class="fa fa-plus"></i> Tranche</span>
      </button>
      </b-field>
    </div>
    <div v-if="perTranche != null && refreshTable">
      <button class="button is-primary is-pulled-right" @click.prevent="addStep()" :disabled="isDisabled">
        <span><i class="fa fa-plus"></i> Step</span>
      </button>
      <b-table :data="trancheData" :striped=true :bordered=true :hoverable=true v-if="refreshTable">
        <template slot-scope="props">
          <b-table-column label="Salary Grade">
            <b-input v-model="props.row.salary_grade" :disabled="isDisabled"></b-input>
          </b-table-column>
          <template v-for="(step, key) in props.row">
            <b-table-column :label="'Step ' + key" :key="step.id" v-if="key != 'salary_grade'">
              <b-input v-model="step.salary" :disabled="isDisabled"></b-input>
            </b-table-column>
          </template>
        </template>
      </b-table>
      <button class="button is-primary" @click.prevent="addSalaryGrade" :disabled="isDisabled">
        <span><i class="fa fa-plus"></i> Salary Grade</span>
      </button>
      <br><br>
      <button class="button is-success" @click.prevent="isDisabled ? edit() : save()">
        {{ editSave }}
      </button>
      <button class="button is-danger" @click.prevent="cancelPrompt" :disabled="cancelDisabled">
        Cancel
      </button>
    </div>
    <!-- <b-modal :active.sync="isComponentModalActive" has-modal-card :canCancel=false>
      <transition name="fade">
        <addedit-form :obj="data" :state="state" @close="close" @save="save"></addedit-form>
      </transition>
    </b-modal> -->
  </div>
</template>

<script>

export default {
  // props: ['salarygrades'],
  data() {
    return {
      isLoading: true,
      isComponentModalActive: false,
      data: {},
      state: '',
      salarygrades: '',
      tranches: '',
      currentTranche: '',
      trancheData: [],
      perTranche: null,
      isDisabled: true,
      cancelDisabled: true,
      editSave: 'Edit',
      previousData: '',
      refreshTable: true,
    }
  },
  mounted() {
    this.setSalaryGrade()
  },
  watch: {
    'perTranche'() {
      this.isLoading = true
      this.refreshTable = false
      this.trancheData = this.salarygrades[this.perTranche]
      //console.log(JSON.stringify(this.trancheData))
      let config = {
        headers: {'Authorization': "Bearer " + this.$store.state.token}
      };

      this.$http.get(`${this.$host}/api/salary-grade/search/${this.perTranche}`,
        config
      ).then((response) => {
        let data = response.data.data
        this.trancheData = response.data.data[this.perTranche]
        this.refreshTable = true
        this.isLoading = false
      }).catch((error) => {
        this.refreshTable = true
        this.isLoading = false
        //console.log(error)
        // alert(error.errors)
      });
    }
  },
  methods: {
    addTranche() {
      let newTranche = Number(this.tranches[this.tranches.length - 1]) + 1
      this.$dialog.confirm({
        message: `Are you sure you want to create a new tranche? (Tranche
        ${newTranche})`,
        confirmText: 'Confirm',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          let config = {
            headers: {'Authorization': "Bearer " + this.$store.state.token}
          };
          this.$http.post(`${this.$host}/api/salary-grade/save/${newTranche}`,
            [
              {
                "1": {
                  "id": null,
                  "salary": 0.00
                },
                salary_grade: 1
              }
            ],
            config,
          ).then((response) => {
            let data = response.data.data
            this.$toast.open({
              message: 'Tranche successfully created!',
              type: 'is-success',
              position: 'is-bottom-right',
              duration: 4000
            })
            this.setSalaryGrade()
            this.perTranche = newTranche
            //console.log(data)
          }).catch((error) => {
            this.$toast.open({
              message: 'Error creating tranche.',
              type: 'is-danger',
              position: 'is-bottom-right',
              duration: 4000
            })
          });
        }
      })
    },
    addSalaryGrade() {
      this.trancheData.push({
        salary_grade: this.trancheData[this.trancheData.length-1].salary_grade + 1,
      });
      let max_step = Object.keys(this.trancheData[0]).length
      let i = 1;
      while (i < max_step) {
        this.trancheData.slice(-1)[0]['' + i] = {
          id: null,
          salary: '0.00'
        }
        i++
      }
      //console.log(JSON.stringify(this.trancheData))
    },
    addStep() {
      let i = 0;
      let max_step = Object.keys(this.trancheData[0]).length
      while (i < this.trancheData.length) {
        this.trancheData[i][max_step.toString()] = {
          id: null,
          salary: '0.00'
        }
        i++
      }
      this.refreshTable = false
      setTimeout(() => this.refreshTable = true, 1)
    },
    save() {
      this.$dialog.confirm({
        title: 'Save',
        message: '<b>Save</b> your changes?',
        confirmText: 'Save',
        type: 'is-success',
        hasIcon: true,
        onConfirm: () => this.confirmSave()
      })
    },
    confirmSave() {
      for (let i = 0; i < this.trancheData.length; i++) {
        if (this.trancheData[i].salary_grade == "") {
          delete this.trancheData[i]
        }
      }
      for (let i = 0; i < this.trancheData.length; i++) {
        this.trancheData[i].salary_grade = Number(this.trancheData[i].salary_grade)
      }
      //console.log(JSON.stringify(this.trancheData))
      this.trancheData = this.trancheData.filter(function (x) { return x != null })
      this.isLoading = true
      let config = {
        headers: {'Authorization': "Bearer " + this.$store.state.token}
      };
      this.$http.post(`${this.$host}/api/salary-grade/save/${this.perTranche}`,
        this.trancheData,
        config,
      ).then((response) => {
        let data = response.data.data
        this.$toast.open({
          message: 'Data successfully saved!',
          type: 'is-success',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.editSave = "Edit"
        this.isDisabled = true
        this.cancelDisabled= true
        //console.log(data)
        this.isLoading = false
      }).catch((error) => {
        this.$toast.open({
          message: 'Error saving data.',
          type: 'is-danger',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.isLoading = false
      });
      // //console.log(JSON.stringify(this.trancheData))
    },
    cancelPrompt() {
      this.$dialog.confirm({
        title: 'Discard Changes',
        message: 'Are you sure you want to <b>discard</b> your changes? This action cannot be undone.',
        confirmText: 'Discard',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.cancel()
      })
    },
    cancel() {
      this.editSave = "Edit"
      this.isDisabled = true
      this.cancelDisabled= true
      let config = {
        headers: {'Authorization': "Bearer " + this.$store.state.token}
      };

      this.$http.get(`${this.$host}/api/salary-grade/search`,
        config
      ).then((response) => {
        let data = response.data.data
        this.perTranche = 1
        this.trancheData = response.data.data[this.perTranche]
      }).catch((error) => {
        //console.log(error)
        // alert(error.errors)
      });
    },
    edit() {
      this.editSave = "Save"
      this.isDisabled = false
      this.cancelDisabled = false
    },
    close() {
      this.isComponentModalActive = false
    },
    setSalaryGrade() {
      let config = {
        headers: {'Authorization': "Bearer " + this.$store.state.token}
      };

      this.$http.get(`${this.$host}/api/salary-grade/search`,
        config
      ).then((response) => {
        let data = response.data.data
        this.salarygrades = data
        this.tranches = Object.keys(data)
        //console.log(Object.keys(data))
        this.isLoading = false
      }).catch((error) => {
        this.isLoading = false
        //console.log(error)
        // alert(error.errors)
      });
    },
    setTranche() {
      //console.log('asd')
      // this.tranche = this.salarygrade[tranche]
    },
    // save(data) {
    //   //console.log(data)
    //   this.$dialog.confirm({
    //     message: '<b>Save</b> your changes?',
    //     confirmText: 'Save',
    //     type: 'is-success',
    //     hasIcon: false,
    //     onConfirm: () => this.saveData(data)
    //   })
    // },
    // saveData(data) {
    //   let config = {
    //     headers: {'Authorization': "Bearer " + this.$store.state.token}
    //   };
    //   this.$http.post(`${this.$host}/api/salary-grade/save/${data.id}`,
    //     data,
    //     config,
    //   ).then((response) => {
    //     let data = response.data.data
    //     this.close()
    //     this.$toast.open({
    //       message: 'Data successfully saved!',
    //       type: 'is-success',
    //       position: 'is-bottom-right',
    //       duration: 4000
    //     })
    //     //console.log(data)
    //   }).catch((error) => {
    //     this.$toast.open({
    //       message: 'Error saving data.',
    //       type: 'is-danger',
    //       position: 'is-bottom-right',
    //       duration: 4000
    //     })
    //     //console.log(error)
    //   });
    // },
    deleteRow(obj) {
      // this.$dialog.confirm({
      //   title: `Discard ${obj.salary_grade}?`,
      //   message: "Are you sure you want to delete this row? This action cannot be undone.",
      //   confirmText: 'Discard',
      //   type: 'is-danger',
      //   hasIcon: true,
      //   onConfirm: () =>
      // })

    },
  }
}
</script>
