<template>
  <div class="box">
    <div class="columns">
      <div class="column is-6">
        <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
        <b-field label="Prepared By">
          <b-autocomplete
            v-model="employee"
            :data="filteredEmployee"
            field="full_name"
            @select="option => employee_position = option.position"
            :open-on-focus=true expanded>
          </b-autocomplete>
        </b-field>
        <b-field label="Employee Position">
          <b-input
            v-model="employee_position"
            expanded>
          </b-input>
        </b-field>
        <b-field label="Supervisor">
          <b-autocomplete
            v-model="supervisor"
            :data="filteredSupervisor"
            field="full_name"
            @select="option => supervisor_position = option.position"
            :open-on-focus=true expanded>
          </b-autocomplete>
        </b-field>
        <b-field label="Supervisor Position">
          <b-input
            v-model="supervisor_position"
            expanded>
          </b-input>
        </b-field>
        <b-field label="Technical">
          <b-input
            type="number"
            v-model="technical"
            expanded>
          </b-input>
        </b-field>
        <b-field label="Outsourced Personnel ">
          <b-input
            type="number"
            v-model="guard"
            expanded>
          </b-input>
        </b-field>
        <button class="button is-primary" @click.prevent="print()">
          <span><i class="fa fa-print"></i> Print</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      employee: '',
      supervisor: '',
      employee_position: '',
      supervisor_position: '',
      technical: '',
      guard: '',
      suggest: [],
      isLoading: false
    }
  },
  computed: {
    filteredEmployee() {
      return this.suggest.filter((option) => {
        return option.full_name
          .toString()
          .toLowerCase()
          .indexOf(this.employee.toLowerCase()) >= 0
      })
    },
    filteredSupervisor() {
      return this.suggest.filter((option) => {
        return option.full_name
          .toString()
          .toLowerCase()
          .indexOf(this.supervisor.toLowerCase()) >= 0
      })
    }
  },
  mounted() {
    this.isLoading = true
    let config = {
      headers: {'Authorization': "Bearer " + this.$store.state.token}
    };

    this.$http.get(`${this.$host}/api/parameter/service_record/employee`,
      config
    ).then((response) => {
      let data = response.data
      this.suggest = data
      for (let i = 0; i < this.suggest.length; i++) {
        this.suggest[i].full_name = this.suggest[i].full_name.toUpperCase();
      }
      this.isLoading = false
    }).catch((error) => {
      //console.log(error)
      this.isLoading = false
    });
  },
  methods: {
    print() {
      this.isLoading = true

      let config = {
        headers: {'Authorization': "Bearer " + this.$store.state.token}
      };

      this.$http.get(`${this.$host}/api/report/generate/${this.$role.state.id}`,
        config
      ).then((response) => {
        this.isLoading = false
        let token = response.data

        let url = `${this.$host}/api/report/pc/${token}?employee=${this.employee}&supervisor=${this.supervisor}
        &employee_position=${this.employee_position}&supervisor_position=${this.supervisor_position}&technical=${this.technical}
        &guard=${this.guard}`
        let win = window.open(url, '_blank')
        win.focus()
      }).catch((error) => {
        //console.log(error)
        this.isLoading = false
      });
    }
  }
}
</script>

<style>

</style>
