<template>
  <div>
    <div class="content columns">
      <div class="column is-6">
        <b-field label="Surname">
          <b-input id="last_name" v-model="data.last_name" :disabled="isDisabled" required></b-input>
        </b-field>
        <b-field label="First Name">
          <b-input id="first_name" v-model="data.first_name" :disabled="isDisabled" required></b-input>
        </b-field>
        <b-field label="Middle Name">
          <b-input v-model="data.middle_name" :disabled="isDisabled"></b-input>
        </b-field>
      </div>
      <div class="column is-3">
        <b-field label="Agency Employee ID">
          <b-input id="empid" v-model="data.employee_number" :disabled="isDisabled" required></b-input>
        </b-field>
        <b-field label="Name Extension (JR, SR)">
          <b-select placeholder="Name Extension" v-model="data.name_extension" :disabled="isDisabled" expanded>
            <option value=""></option>
            <option value="JR">JR</option>
            <option value="SR">SR</option>
            <option value="I">I</option>
            <option value="II">II</option>
            <option value="III">III</option>
          </b-select>
        </b-field>
        <b-field label="Group" v-if="data.employee_groups_id != undefined || isEdit == false">
          <b-select placeholder="Select group" v-model="data.employee_groups_id" :disabled="isDisabled" required expanded>
            <!-- <option value=null>ALL</option> -->
            <option
              v-for="option in groups"
              :value="option.id"
              :key="option.id">
              {{ option.code }}
            </option>
          </b-select>
        </b-field>
      </div>
      <div class="column is-3">
        <div id="preview" v-if="isEdit">
          <img v-if="url" :src="url">
          <input type="file" @change="onImgChange" />
        </div>
      </div>
    </div>
    <div class="content columns">
      <div class="column is-4">
        <b-field label="Date of Birth">
          <b-datepicker
            placeholder="Click to select..."
            icon="calendar-today"
            v-model="data.birth_date"
            :date-formatter="d => d.toLocaleDateString('en-GB')"
            :date-parser="d => parseDate(d)"
            :disabled="isDisabled"
            editable>
          </b-datepicker>
        </b-field>
        <b-field label="Place of Birth">
          <b-input v-model="data.birth_place" :disabled="isDisabled"></b-input>
        </b-field>
        <b-field label="Gender">
          <b-select placeholder="Select Gender" v-model="data.gender" :disabled="isDisabled" expanded required>
            <option value="Male">MALE</option>
            <option value="Female">FEMALE</option>
          </b-select>
        </b-field>
        <b-field label="Civil Status">
          <b-select v-if="data.id"
          placeholder="Select Civil Status" v-model="data.civil_status" :disabled="isDisabled" expanded>
            <option value="Single">SINGLE</option>
            <option value="Married">MARRIED</option>
            <option value="Widowed">WIDOWED</option>
            <option value="Separated">SEPARATED</option>
            <option value="Others">OTHERS</option>
          </b-select>
          <b-select v-if="!data.id"
          placeholder="Select Civil Status" v-model="data.civil_status" :disabled="isDisabled" expanded required>
            <option value="Single">SINGLE</option>
            <option value="Married">MARRIED</option>
            <option value="Widowed">WIDOWED</option>
            <option value="Separated">SEPARATED</option>
            <option value="Others">OTHERS</option>
          </b-select>
        </b-field>
        <!-- <b-field>
          <b-input v-model="civil_status_other" v-if="othersVisible" :disabled="isDisabled"></b-input>
        </b-field> -->
        <b-field label="Height">
            <b-field>
                <b-input id="meter" v-model="data.height" :disabled="isDisabled" expanded></b-input>
                <p class="control">
                  <span class="button is-static no-shadow">meters (m)</span>
                </p>
            </b-field>
        </b-field>
        <b-field>
            <b-input id="feet" v-model="feet" :disabled="isDisabled" expanded></b-input>
            <p class="control">
              <span class="button is-static no-shadow">feet (ft)</span>
            </p>
            <b-input id="inches" v-model="inches" :disabled="isDisabled" expanded></b-input>
            <p class="control">
              <span class="button is-static no-shadow">inches (in)</span>
            </p>
        </b-field>
        <b-field>

        </b-field>
        <b-field label="Weight">
          <b-field>
            <b-input id="kilograms" v-model="data.weight" :disabled="isDisabled" expanded></b-input>
            <p class="control">
              <span class="button is-static no-shadow">kilograms (kg)</span>
            </p>
          </b-field>
        </b-field>
        <b-field>
          <b-input id="pounds" v-model="pounds" :disabled="isDisabled" expanded></b-input>
          <p class="control">
            <span class="button is-static no-shadow">pounds (lbs)</span>
          </p>
        </b-field>
        <b-field label="Blood Type">
          <b-select placeholder="Select Blood Type" v-model="data.blood_type" :disabled="isDisabled" expanded>
            <option value="">N/A</option>
            <option value="A+">A+</option>
            <option value="A-">A-</option>
            <option value="B+">B+</option>
            <option value="B-">B-</option>
            <option value="AB+">AB+</option>
            <option value="AB-">AB-</option>
            <option value="O+">O+</option>
            <option value="O-">O-</option>
          </b-select>
        </b-field>
        <b-field label="GSIS ID Number">
          <b-input v-model="data.gsis" :disabled="isDisabled"></b-input>
        </b-field>
        <b-field label="PAG-IBIG ID Number">
          <b-input v-model="data.pagibig" :disabled="isDisabled"></b-input>
        </b-field>
        <b-field label="PHILHEALTH Number">
          <b-input v-model="data.philhealth" :disabled="isDisabled"></b-input>
        </b-field>
        <b-field label="SSS Number">
          <b-input v-model="data.sss" :disabled="isDisabled"></b-input>
        </b-field>
        <b-field label="TIN Number">
          <b-input v-model="data.tin" :disabled="isDisabled"></b-input>
        </b-field>
      </div>
      <div class="column is-8">
        <div class="columns">
          <div class="column is-4">
            <b-field label="Citizenship">
              <!-- <select class="select" placeholder="Select Citizenship" v-model="citizenship" :disabled="isDisabled"
              v-on:change="citizenStatus" expanded>
                <option value="Filipino">Filipino</option>
                <option value="Dual Citizen">Dual Citizenship</option>
              </select> -->
              <div class="select">
                <select placeholder="Select Citizenship" v-model="data.citizenship" :disabled="isDisabled"
                v-on:change="citizenStatus" expanded>
                  <option value="Filipino">FILIPINO</option>
                  <option value="Dual Citizen">DUAL CITIZENSHIP</option>
                </select>
              </div>
            </b-field>
            <b-field label="Dual Citizen Country" v-if="dualcitizenVisible">
              <vue-simple-suggest
                v-model="data.citizenship_by_country"
                :list="countries"
                :filter-by-query="true"
                :prevent-submit="false"
                :min-length="1"
                display-attribute="name"
                value-attribute="name"
                :disabled="isDisabled">
              </vue-simple-suggest>
            </b-field>
          </div>
          <div class="column is-8">
            <b-field label="Citizenship By">
              <b-select placeholder="Citizenship by" v-model="data.citizenship_by" :disabled="isDisabled">
                <option value="Birth">BIRTH</option>
                <option value="Naturalization">NATURALIZATION</option>
              </b-select>
            </b-field>
          </div>
        </div>
        <b-field label="RESIDENTIAL ADDRESS">
        </b-field>
        <div class="columns">
          <div class="column is-6">
            <b-field label="House/Block/Lot Number">
              <b-input v-model="data.residential_housenum" :disabled="isDisabled"></b-input>
            </b-field>
            <b-field label="Subdivision/Village">
              <b-input v-model="data.residential_subdivision" :disabled="isDisabled"></b-input>
            </b-field>
            <b-field label="City/Municipality">
              <b-input v-model="data.residential_city" :disabled="isDisabled"></b-input>
            </b-field>
            <b-field label="Zip Code">
              <b-input v-model="data.residential_zipcode" :disabled="isDisabled"></b-input>
            </b-field>

          </div>
          <div class="column is-6">
            <b-field label="Street">
              <b-input v-model="data.residential_street" :disabled="isDisabled"></b-input>
            </b-field>
            <b-field label="Barangay">
              <b-input v-model="data.residential_barangay" :disabled="isDisabled"></b-input>
            </b-field>
            <b-field label="Province">
              <b-input v-model="data.residential_province" :disabled="isDisabled"></b-input>
            </b-field>
          </div>
        </div>
        <b-field>
          <b-field label="PERMANENT ADDRESS">
          </b-field>&nbsp;
          <p class="control">
            <button class="button is-primary is-small" @click.prevent="sameResidential()" v-if="!isDisabled">
              SAME AS RESIDENTIAL
            </button>
          </p>
        </b-field>
        <div class="columns">
          <div class="column is-6">
            <b-field label="House/Block/Lot Number">
              <b-input id="p_housenum" v-model="data.permanent_housenum" :disabled="isDisabled"></b-input>
            </b-field>
            <b-field label="Subdivision/Village">
              <b-input id="p_subdivision" v-model="data.permanent_subdivision" :disabled="isDisabled"></b-input>
            </b-field>
            <b-field label="City/Municipality">
              <b-input id="p_city" v-model="data.permanent_city" :disabled="isDisabled"></b-input>
            </b-field>
            <b-field label="Zip Code">
              <b-input id="p_zipcode" v-model="data.permanent_zipcode" :disabled="isDisabled"></b-input>
            </b-field>

          </div>
          <div class="column is-6">
            <b-field label="Street">
              <b-input id="p_street" v-model="data.permanent_street" :disabled="isDisabled"></b-input>
            </b-field>
            <b-field label="Barangay">
              <b-input id="p_barangay" v-model="data.permanent_barangay" :disabled="isDisabled"></b-input>
            </b-field>
            <b-field label="Province">
              <b-input id="p_province" v-model="data.permanent_province" :disabled="isDisabled"></b-input>
            </b-field>
          </div>
        </div>
        <b-field label="Telephone Number">
          <b-input v-model="data.telephone" :disabled="isDisabled"></b-input>
        </b-field>
        <b-field label="Mobile Number">
          <b-input v-model="data.mobile" :disabled="isDisabled"></b-input>
        </b-field>
        <b-field label="E-mail Address">
          <b-input id="email" v-model="data.email" :disabled="isDisabled" style="text-transform: none;"></b-input>
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
import Photo from '../Photo.vue'
import moment from 'moment'
import VueSimpleSuggest from 'vue-simple-suggest'
import '../../../css/styles.css'

export default {
  props: ["isDisabled", "data"],
  data() {
    return {
      isEdit: false,
      last_name: '',
      first_name: '',
      middle_name: '',
      employee_number: '',
      name_extension: '',
      birth_place: '',
      gender: '',
      height: '',
      weight: '',
      blood_type: '',
      gsis: '',
      pagibig: '',
      philhealth: '',
      sss: '',
      tin: '',
      agency_employee_number: '',
      citizenship: '',
      citizenship_by: '',
      residential_housenum: '',
      residential_subdivision: '',
      residential_city: '',
      residential_zipcode: '',
      residential_street: '',
      residential_barangay: '',
      residential_province: '',
      permanent_housenum: '',
      permanent_subdivision: '',
      permanent_city: '',
      permanent_zipcode: '',
      permanent_street: '',
      permanent_barangay: '',
      permanent_province: '',
      mobile: '',
      birth_date: '',
      dualcitizenVisible: false,
      othersVisible: false,
      civil_status: '',
      email: '',
      telephone2: this.data.telephone,
      url: '',
      inches: '',
      feet: '',
      delay: '',
      pounds: '',
      countries: [
        {name: 'Afghanistan', code: 'AF'},
        {name: 'Åland Islands', code: 'AX'},
        {name: 'Albania', code: 'AL'},
        {name: 'Algeria', code: 'DZ'},
        {name: 'American Samoa', code: 'AS'},
        {name: 'AndorrA', code: 'AD'},
        {name: 'Angola', code: 'AO'},
        {name: 'Anguilla', code: 'AI'},
        {name: 'Antarctica', code: 'AQ'},
        {name: 'Antigua and Barbuda', code: 'AG'},
        {name: 'Argentina', code: 'AR'},
        {name: 'Armenia', code: 'AM'},
        {name: 'Aruba', code: 'AW'},
        {name: 'Australia', code: 'AU'},
        {name: 'Austria', code: 'AT'},
        {name: 'Azerbaijan', code: 'AZ'},
        {name: 'Bahamas', code: 'BS'},
        {name: 'Bahrain', code: 'BH'},
        {name: 'Bangladesh', code: 'BD'},
        {name: 'Barbados', code: 'BB'},
        {name: 'Belarus', code: 'BY'},
        {name: 'Belgium', code: 'BE'},
        {name: 'Belize', code: 'BZ'},
        {name: 'Benin', code: 'BJ'},
        {name: 'Bermuda', code: 'BM'},
        {name: 'Bhutan', code: 'BT'},
        {name: 'Bolivia', code: 'BO'},
        {name: 'Bosnia and Herzegovina', code: 'BA'},
        {name: 'Botswana', code: 'BW'},
        {name: 'Bouvet Island', code: 'BV'},
        {name: 'Brazil', code: 'BR'},
        {name: 'British Indian Ocean Territory', code: 'IO'},
        {name: 'Brunei Darussalam', code: 'BN'},
        {name: 'Bulgaria', code: 'BG'},
        {name: 'Burkina Faso', code: 'BF'},
        {name: 'Burundi', code: 'BI'},
        {name: 'Cambodia', code: 'KH'},
        {name: 'Cameroon', code: 'CM'},
        {name: 'Canada', code: 'CA'},
        {name: 'Cape Verde', code: 'CV'},
        {name: 'Cayman Islands', code: 'KY'},
        {name: 'Central African Republic', code: 'CF'},
        {name: 'Chad', code: 'TD'},
        {name: 'Chile', code: 'CL'},
        {name: 'China', code: 'CN'},
        {name: 'Christmas Island', code: 'CX'},
        {name: 'Cocos (Keeling) Islands', code: 'CC'},
        {name: 'Colombia', code: 'CO'},
        {name: 'Comoros', code: 'KM'},
        {name: 'Congo', code: 'CG'},
        {name: 'Congo, The Democratic Republic of the', code: 'CD'},
        {name: 'Cook Islands', code: 'CK'},
        {name: 'Costa Rica', code: 'CR'},
        {name: 'Cote D\'Ivoire', code: 'CI'},
        {name: 'Croatia', code: 'HR'},
        {name: 'Cuba', code: 'CU'},
        {name: 'Cyprus', code: 'CY'},
        {name: 'Czech Republic', code: 'CZ'},
        {name: 'Denmark', code: 'DK'},
        {name: 'Djibouti', code: 'DJ'},
        {name: 'Dominica', code: 'DM'},
        {name: 'Dominican Republic', code: 'DO'},
        {name: 'Ecuador', code: 'EC'},
        {name: 'Egypt', code: 'EG'},
        {name: 'El Salvador', code: 'SV'},
        {name: 'Equatorial Guinea', code: 'GQ'},
        {name: 'Eritrea', code: 'ER'},
        {name: 'Estonia', code: 'EE'},
        {name: 'Ethiopia', code: 'ET'},
        {name: 'Falkland Islands (Malvinas)', code: 'FK'},
        {name: 'Faroe Islands', code: 'FO'},
        {name: 'Fiji', code: 'FJ'},
        {name: 'Finland', code: 'FI'},
        {name: 'France', code: 'FR'},
        {name: 'French Guiana', code: 'GF'},
        {name: 'French Polynesia', code: 'PF'},
        {name: 'French Southern Territories', code: 'TF'},
        {name: 'Gabon', code: 'GA'},
        {name: 'Gambia', code: 'GM'},
        {name: 'Georgia', code: 'GE'},
        {name: 'Germany', code: 'DE'},
        {name: 'Ghana', code: 'GH'},
        {name: 'Gibraltar', code: 'GI'},
        {name: 'Greece', code: 'GR'},
        {name: 'Greenland', code: 'GL'},
        {name: 'Grenada', code: 'GD'},
        {name: 'Guadeloupe', code: 'GP'},
        {name: 'Guam', code: 'GU'},
        {name: 'Guatemala', code: 'GT'},
        {name: 'Guernsey', code: 'GG'},
        {name: 'Guinea', code: 'GN'},
        {name: 'Guinea-Bissau', code: 'GW'},
        {name: 'Guyana', code: 'GY'},
        {name: 'Haiti', code: 'HT'},
        {name: 'Heard Island and Mcdonald Islands', code: 'HM'},
        {name: 'Holy See (Vatican City State)', code: 'VA'},
        {name: 'Honduras', code: 'HN'},
        {name: 'Hong Kong', code: 'HK'},
        {name: 'Hungary', code: 'HU'},
        {name: 'Iceland', code: 'IS'},
        {name: 'India', code: 'IN'},
        {name: 'Indonesia', code: 'ID'},
        {name: 'Iran, Islamic Republic Of', code: 'IR'},
        {name: 'Iraq', code: 'IQ'},
        {name: 'Ireland', code: 'IE'},
        {name: 'Isle of Man', code: 'IM'},
        {name: 'Israel', code: 'IL'},
        {name: 'Italy', code: 'IT'},
        {name: 'Jamaica', code: 'JM'},
        {name: 'Japan', code: 'JP'},
        {name: 'Jersey', code: 'JE'},
        {name: 'Jordan', code: 'JO'},
        {name: 'Kazakhstan', code: 'KZ'},
        {name: 'Kenya', code: 'KE'},
        {name: 'Kiribati', code: 'KI'},
        {name: 'Korea, Democratic People\'S Republic of', code: 'KP'},
        {name: 'Korea, Republic of', code: 'KR'},
        {name: 'Kuwait', code: 'KW'},
        {name: 'Kyrgyzstan', code: 'KG'},
        {name: 'Lao People\'S Democratic Republic', code: 'LA'},
        {name: 'Latvia', code: 'LV'},
        {name: 'Lebanon', code: 'LB'},
        {name: 'Lesotho', code: 'LS'},
        {name: 'Liberia', code: 'LR'},
        {name: 'Libyan Arab Jamahiriya', code: 'LY'},
        {name: 'Liechtenstein', code: 'LI'},
        {name: 'Lithuania', code: 'LT'},
        {name: 'Luxembourg', code: 'LU'},
        {name: 'Macao', code: 'MO'},
        {name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK'},
        {name: 'Madagascar', code: 'MG'},
        {name: 'Malawi', code: 'MW'},
        {name: 'Malaysia', code: 'MY'},
        {name: 'Maldives', code: 'MV'},
        {name: 'Mali', code: 'ML'},
        {name: 'Malta', code: 'MT'},
        {name: 'Marshall Islands', code: 'MH'},
        {name: 'Martinique', code: 'MQ'},
        {name: 'Mauritania', code: 'MR'},
        {name: 'Mauritius', code: 'MU'},
        {name: 'Mayotte', code: 'YT'},
        {name: 'Mexico', code: 'MX'},
        {name: 'Micronesia, Federated States of', code: 'FM'},
        {name: 'Moldova, Republic of', code: 'MD'},
        {name: 'Monaco', code: 'MC'},
        {name: 'Mongolia', code: 'MN'},
        {name: 'Montserrat', code: 'MS'},
        {name: 'Morocco', code: 'MA'},
        {name: 'Mozambique', code: 'MZ'},
        {name: 'Myanmar', code: 'MM'},
        {name: 'Namibia', code: 'NA'},
        {name: 'Nauru', code: 'NR'},
        {name: 'Nepal', code: 'NP'},
        {name: 'Netherlands', code: 'NL'},
        {name: 'Netherlands Antilles', code: 'AN'},
        {name: 'New Caledonia', code: 'NC'},
        {name: 'New Zealand', code: 'NZ'},
        {name: 'Nicaragua', code: 'NI'},
        {name: 'Niger', code: 'NE'},
        {name: 'Nigeria', code: 'NG'},
        {name: 'Niue', code: 'NU'},
        {name: 'Norfolk Island', code: 'NF'},
        {name: 'Northern Mariana Islands', code: 'MP'},
        {name: 'Norway', code: 'NO'},
        {name: 'Oman', code: 'OM'},
        {name: 'Pakistan', code: 'PK'},
        {name: 'Palau', code: 'PW'},
        {name: 'Palestinian Territory, Occupied', code: 'PS'},
        {name: 'Panama', code: 'PA'},
        {name: 'Papua New Guinea', code: 'PG'},
        {name: 'Paraguay', code: 'PY'},
        {name: 'Peru', code: 'PE'},
        {name: 'Philippines', code: 'PH'},
        {name: 'Pitcairn', code: 'PN'},
        {name: 'Poland', code: 'PL'},
        {name: 'Portugal', code: 'PT'},
        {name: 'Puerto Rico', code: 'PR'},
        {name: 'Qatar', code: 'QA'},
        {name: 'Reunion', code: 'RE'},
        {name: 'Romania', code: 'RO'},
        {name: 'Russian Federation', code: 'RU'},
        {name: 'RWANDA', code: 'RW'},
        {name: 'Saint Helena', code: 'SH'},
        {name: 'Saint Kitts and Nevis', code: 'KN'},
        {name: 'Saint Lucia', code: 'LC'},
        {name: 'Saint Pierre and Miquelon', code: 'PM'},
        {name: 'Saint Vincent and the Grenadines', code: 'VC'},
        {name: 'Samoa', code: 'WS'},
        {name: 'San Marino', code: 'SM'},
        {name: 'Sao Tome and Principe', code: 'ST'},
        {name: 'Saudi Arabia', code: 'SA'},
        {name: 'Senegal', code: 'SN'},
        {name: 'Serbia and Montenegro', code: 'CS'},
        {name: 'Seychelles', code: 'SC'},
        {name: 'Sierra Leone', code: 'SL'},
        {name: 'Singapore', code: 'SG'},
        {name: 'Slovakia', code: 'SK'},
        {name: 'Slovenia', code: 'SI'},
        {name: 'Solomon Islands', code: 'SB'},
        {name: 'Somalia', code: 'SO'},
        {name: 'South Africa', code: 'ZA'},
        {name: 'South Georgia and the South Sandwich Islands', code: 'GS'},
        {name: 'Spain', code: 'ES'},
        {name: 'Sri Lanka', code: 'LK'},
        {name: 'Sudan', code: 'SD'},
        {name: 'Suriname', code: 'SR'},
        {name: 'Svalbard and Jan Mayen', code: 'SJ'},
        {name: 'Swaziland', code: 'SZ'},
        {name: 'Sweden', code: 'SE'},
        {name: 'Switzerland', code: 'CH'},
        {name: 'Syrian Arab Republic', code: 'SY'},
        {name: 'Taiwan, Province of China', code: 'TW'},
        {name: 'Tajikistan', code: 'TJ'},
        {name: 'Tanzania, United Republic of', code: 'TZ'},
        {name: 'Thailand', code: 'TH'},
        {name: 'Timor-Leste', code: 'TL'},
        {name: 'Togo', code: 'TG'},
        {name: 'Tokelau', code: 'TK'},
        {name: 'Tonga', code: 'TO'},
        {name: 'Trinidad and Tobago', code: 'TT'},
        {name: 'Tunisia', code: 'TN'},
        {name: 'Turkey', code: 'TR'},
        {name: 'Turkmenistan', code: 'TM'},
        {name: 'Turks and Caicos Islands', code: 'TC'},
        {name: 'Tuvalu', code: 'TV'},
        {name: 'Uganda', code: 'UG'},
        {name: 'Ukraine', code: 'UA'},
        {name: 'United Arab Emirates', code: 'AE'},
        {name: 'United Kingdom', code: 'GB'},
        {name: 'United States', code: 'US'},
        {name: 'United States Minor Outlying Islands', code: 'UM'},
        {name: 'Uruguay', code: 'UY'},
        {name: 'Uzbekistan', code: 'UZ'},
        {name: 'Vanuatu', code: 'VU'},
        {name: 'Venezuela', code: 'VE'},
        {name: 'Viet Nam', code: 'VN'},
        {name: 'Virgin Islands, British', code: 'VG'},
        {name: 'Virgin Islands, U.S.', code: 'VI'},
        {name: 'Wallis and Futuna', code: 'WF'},
        {name: 'Western Sahara', code: 'EH'},
        {name: 'Yemen', code: 'YE'},
        {name: 'Zambia', code: 'ZM'},
        {name: 'Zimbabwe', code: 'ZW'}
      ],
      groups: []
    }
  },
  components: {
    Photo,
    VueSimpleSuggest
  },
  beforeMount() {
    if (this.data.birth_date) {
      this.data.birth_date = new Date(this.data.birth_date)
    }
    else {
      this.data.birth_date = null
    }
  },
  mounted() {
    if (this.data.employee_number) {
      this.isEdit = true
      this.getImg()
    }
    this.getGroups()
    if (this.data.citizenship == 'Dual Citizen') {
      this.dualcitizenVisible = true
    }
    if (this.data.height == null && this.data.weight == null) {
      this.feet = 0
      this.inches = 0
      this.pounds = 0
      this.data.height = 0
      this.data.weight = 0
    }
    else {
      this.feet = Math.floor(this.data.height * 3.281)
      this.inches = Math.round((this.data.height - (this.feet / 3.281)) * 39.37)
      this.pounds = parseFloat((this.data.weight * 2.205).toFixed(2))
    }
    if (this.data.last_name != null && this.data.first_name != null && this.data.employee_number != null) {
      this.$editmode.commit('setUsername', (this.data.last_name + this.data.employee_number).toLowerCase().replace(/[ñÑ]/g,"n").replace(/[^0-9a-z]/gi,''))
      this.$editmode.commit('setPassword', (this.data.first_name + this.data.employee_number).toLowerCase().replace(/[ñÑ]/g,"n").replace(/[^0-9a-z]/gi,''))
    }
  },
  watch: {
    inches() {
        let inch = document.getElementById('inches')
        if (inch === document.activeElement) {
            var feet = Number(this.feet)
            var inches = Number(this.inches)/12
            ////console.log(feet + '-' + inches);
            this.data.height = parseFloat(((feet + inches) / 3.281).toFixed(4))
        }
    },
    feet() {
        let ft = document.getElementById('feet')
        if (ft === document.activeElement) {
            var feet = Number(this.feet)
            var inches = Number(this.inches)/12
            ////console.log(feet + '-' + inches);
            this.data.height = parseFloat(((feet + inches) / 3.281).toFixed(4))
        }
    },
    'data.height'() {
      let m = document.getElementById('meter')
      if (m === document.activeElement) {
        this.feet = Math.floor(this.data.height * 3.281)
        this.inches = Math.round((this.data.height - (this.feet / 3.281)) * 39.37)
      }
    },
    'data.weight'() {
      let kg = document.getElementById('kilograms')
      if (kg === document.activeElement) {
        this.pounds = parseFloat((this.data.weight * 2.205).toFixed(2))
      }
    },
    pounds() {
      let lbs = document.getElementById('pounds')
      if (lbs === document.activeElement) {
        this.data.weight = parseFloat((this.pounds / 2.205).toFixed(2))
      }
    },
    'data.employee_number'() {
      let empid = document.getElementById('empid')
      if (empid === document.activeElement) {
        if (this.data.employee_number){
            this.$editmode.commit('setUsername', (this.data.last_name + this.data.employee_number).toLowerCase().replace(/[ñÑ]/g,"n").replace(/[^0-9a-z]/gi,''))
            this.$editmode.commit('setPassword', (this.data.first_name + this.data.employee_number).toLowerCase().replace(/[ñÑ]/g,"n").replace(/[^0-9a-z]/gi,''))
        } else {
            this.$editmode.commit('setUsername', (this.data.last_name + this.data.first_name).toLowerCase().replace(/[ñÑ]/g,"n").replace(/[^0-9a-z]/gi,''))
            this.$editmode.commit('setPassword', (this.data.first_name + this.data.last_name).toLowerCase().replace(/[ñÑ]/g,"n").replace(/[^0-9a-z]/gi,''))
        }
      }
    },
    'data.last_name'() {
      let last_name = document.getElementById('last_name')
      if (last_name === document.activeElement) {
          if (this.data.employee_number){
            this.$editmode.commit('setUsername', (this.data.last_name + this.data.employee_number).toLowerCase().replace(/[ñÑ]/g,"n").replace(/[^0-9a-z]/gi,''))
          } else {
            this.$editmode.commit('setUsername', (this.data.last_name + this.data.first_name).toLowerCase().replace(/[ñÑ]/g,"n").replace(/[^0-9a-z]/gi,''))
            this.$editmode.commit('setPassword', (this.data.first_name + this.data.last_name).toLowerCase().replace(/[ñÑ]/g,"n").replace(/[^0-9a-z]/gi,''))
          }
      }
    },
    'data.first_name'() {
      let first_name = document.getElementById('first_name')
      if (first_name === document.activeElement) {
          if (this.data.employee_number){
            this.$editmode.commit('setPassword', (this.data.first_name + this.data.employee_number).toLowerCase().replace(/[ñÑ]/g,"n").replace(/[^0-9a-z]/gi,''))
          } else {
            this.$editmode.commit('setUsername', (this.data.last_name + this.data.first_name).toLowerCase().replace(/[ñÑ]/g,"n").replace(/[^0-9a-z]/gi,''))
            this.$editmode.commit('setPassword', (this.data.first_name + this.data.last_name).toLowerCase().replace(/[ñÑ]/g,"n").replace(/[^0-9a-z]/gi,''))
          }
      }
    },
    'data.email'(){
        let email = document.getElementById('email')
        if (email === document.activeElement) {
            this.$editmode.commit('setEmail', this.data.email);
        }
    }
  },
  methods: {
    getGroups() {
      let config = {
        headers: {
          'Authorization': "Bearer " + this.$store.state.token
        }
      }
      this.$http.get(`${this.$host}/api/group/all`,
        config
      ).then((response) => {
        let data = response.data
        this.groups = data
        this.isLoading = false
      }).catch((error) => {
        this.isLoading = false
      });
    },
    parseDate(d) {
      let parts = d.split("/")
      let dt = new Date(parseInt(parts[2], 10),
        parseInt(parts[1], 10) - 1,
        parseInt(parts[0], 10))
      return dt
    },
    twoDecimals(num) {
      return Math.floor(num);
    },
    sameResidential() {
      if (!this.data.residential_housenum) {
        this.data.residential_housenum = ''
      }
      if (!this.data.residential_subdivision) {
        this.data.residential_subdivision = ''
      }
      if (!this.data.residential_city) {
        this.data.residential_city = ''
      }
      if (!this.data.residential_zipcode) {
        this.data.residential_zipcode = ''
      }
      if (!this.data.residential_street) {
        this.data.residential_street = ''
      }
      if (!this.data.residential_barangay) {
        this.data.residential_barangay = ''
      }
      if (!this.data.residential_province) {
        this.data.residential_province = ''
      }

      document.getElementById('p_housenum').value = this.data.residential_housenum
      document.getElementById('p_subdivision').value = this.data.residential_subdivision
      document.getElementById('p_city').value = this.data.residential_city
      document.getElementById('p_zipcode').value = this.data.residential_zipcode
      document.getElementById('p_street').value = this.data.residential_street
      document.getElementById('p_barangay').value = this.data.residential_barangay
      document.getElementById('p_province').value = this.data.residential_province

      this.data.permanent_housenum = this.data.residential_housenum
      this.data.permanent_subdivision = this.data.residential_subdivision
      this.data.permanent_city = this.data.residential_city
      this.data.permanent_zipcode = this.data.residential_zipcode
      this.data.permanent_street = this.data.residential_street
      this.data.permanent_barangay = this.data.residential_barangay
      this.data.permanent_province = this.data.residential_province
    },
    getImg() {
      let config = {
        headers: {
          'Authorization': "Bearer " + this.$store.state.token
        }
      }
      this.$http.get(`${this.$host}/api/employee/image/${this.data.id}`,
        config
      ).then((response) => {
        if (response.data) {
          let data = response.data
          this.url = data
        }
        else {
          this.url = '/img/photo.png'
        }
      }).catch((error) => {
        this.url = '/img/photo.png'
        // this.$toast.open({
        //   message: 'Error retrieving image.',
        //   type: 'is-danger',
        //   position: 'is-bottom-right',
        //   duration: 4000
        // })
      });
    },
    onImgChange(e) {
      // //console.log(e.target.files)
      let file = e.target.files[0];
      let formData = new FormData()
      formData.append('image', file)

      let config = {
        headers: {
          'Authorization': "Bearer " + this.$store.state.token,
          'Content-Type': 'multipart/form-data'
        }
      };
      this.$http.post(`${this.$host}/api/employee/upload/${this.data.id}`,
        formData,
        config,
      ).then((response) => {
        this.$toast.open({
          message: 'Image saved!',
          type: 'is-success',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.getImg()
      }).catch((error) => {
        this.$toast.open({
          message: 'Failed to save image.',
          type: 'is-danger',
          position: 'is-bottom-right',
          duration: 4000
        })
      });
    },
    returnData() {
      delete this.data.education
      delete this.data.eligibilities
      delete this.data.workexperiences
      delete this.data.volunteerings
      delete this.data.trainings
      this.$emit('personal', this.data)
    },
    civilStatus() {
      // if (this.civil_status == "Other") {
      //   return this.othersVisible = true
      // }
      // else {
      //   return this.othersVisible = false
      // }
    },
    citizenStatus() {
      if (this.data.citizenship == 'Dual Citizen') {
        this.dualcitizenVisible = true
      }
      else {
        this.dualcitizenVisible = false
      }
    },
    getData() {
      this.employee_number = this.data.employee_number
      this.last_name = this.data.last_name
      this.first_name = this.data.first_name
      this.middle_name = this.data.middle_name
      this.name_extension = this.data.name_extension
      this.birth_date = new Date(this.data.birth_date)
      this.birth_place = this.data.birth_place
      this.gender = this.data.gender
      this.civil_status = this.data.civil_status
      this.height = this.data.height
      this.weight = this.data.weight
      this.blood_type = this.data.blood_type
      this.gsis = this.data.gsis
      this.pagibig = this.data.pagibig
      this.philhealth = this.data.philhealth
      this.sss = this.data.sss
      this.tin = this.data.tin
      this.agency_employee_number = this.data.agency_employee_number
      this.citizenship = this.data.citizenship
      this.citizenship_by_country = this.data.citizenship_by_country
      this.citizenship_by = this.data.citizenship_by
      this.residential_housenum = this.data.residential_housenum
      this.residential_subdivision = this.data.residential_subdivision
      this.residential_city = this.data.residential_city
      this.residential_zipcode = this.data.residential_zipcode
      this.residential_street = this.data.residential_street
      this.residential_barangay = this.data.residential_barangay

      this.permanent_housenum = this.data.permanent_housenum
      this.permanent_subdivision = this.data.permanent_subdivision
      this.permanent_city = this.data.permanent_city
      this.permanent_zipcode = this.data.permanent_zipcode
      this.permanent_street = this.data.permanent_street
      this.permanent_barangay = this.data.permanent_barangay

      this.telephone = this.data.telephone
      this.mobile = this.data.mobile
      this.email = this.data.email
      this.residential_province = this.data.residential_province
      this.permanent_province = this.data.permanent_province
    },
  }
}
</script>

<style scoped>
  .input-spacing {
    opacity: 0;
  }
  input::placeholder {
    color: black !important;
  }
  .header {
    border-bottom: 2px solid #0F3C8B;
  }
  #preview {
    height: 100%;
    width: 100%;
  }
  #preview img {
    max-height: 200px;
    max-width: 200px;
    min-height: 200px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  #preview input {
    width: 200px;
    min-width: 200px;
  }
  .no-shadow {
    box-shadow: none !important;
  }
</style>
