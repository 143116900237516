<template>
  <div id="mySidenav" class="sidenav has-background-white is-hidden-touch">
    <a class="closebtn" id="closebtn" v-on:click="closeNav()"><i class="fas" :class="navClosed ? 'fa-bars' : 'fa-angle-left'"></i></a>
    <span id="role" class="has-text-white-ter is-hidden-touch">{{ this.$role.state.role }}</span>
    <!-- <router-link to="/home" @click.native="scrollToTop">
      <img src="/img/dotr-logo.png" width="150" style="margin: 0 auto; display: block; cursor: pointer;">
    </router-link> -->
    <router-link to="/home" tag="img" src="/img/dotr-logo.png" width="150" style="margin: 0 auto; display: block; cursor: pointer;"></router-link>
    <hr style="margin-top: 0; width: 70%; margin: 0 auto; display: block;" class="has-background-grey-lighter">
    <router-link to="/home" @click.native="scrollToTop" active-class="has-background-primary active-white" class="is-active navbar-item has-text-grey-darker">
      <span class="icon"><i class="fa fa-home"></i></span> &nbsp;&nbsp;&nbsp;HOME
    </router-link>
    <router-link to="/employees" @click.native="scrollToTop" active-class="has-background-primary active-white" class="is-active navbar-item has-text-grey-darker"
    v-if="$role.state.permissions['search employee']">
      <span class="icon"><i class="fa fa-user-tie"></i></span> &nbsp;&nbsp;&nbsp;EMPLOYEES
    </router-link>
    <router-link to="/service-record" @click.native="scrollToTop" active-class="has-background-primary active-white" class="is-active navbar-item has-text-grey-darker"
    v-if="$role.state.permissions['search service record']">
      <span class="icon"><i class="fa fa-folder-open"></i></span> &nbsp;&nbsp;&nbsp;HISTORY
    </router-link>
    <router-link to="/schedule" @click.native="scrollToTop" active-class="has-background-primary active-white" class="is-active navbar-item has-text-grey-darker"
    v-if="$role.state.permissions['read daily time record'] || $role.state.permissions['search daily time record'] || $role.state.permissions['write daily time record']">
      <span class="icon"><i class="fa fa-calendar-alt"></i></span> &nbsp;&nbsp;&nbsp;SCHEDULE
    </router-link>
    <router-link to="/requests" @click.native="scrollToTop" active-class="has-background-primary active-white" class="is-active navbar-item has-text-grey-darker"
    v-if="$role.state.permissions['apply request']">
      <span class="icon"><i class="fa fa-exchange-alt"></i></span> &nbsp;&nbsp;&nbsp;REQUESTS
    </router-link>
    <router-link to="/position" @click.native="scrollToTop" active-class="has-background-primary active-white" class="is-active navbar-item has-text-grey-darker"
    v-if="$role.state.permissions['search position']">
      <span class="icon"><i class="fa fa-sign"></i></span> &nbsp;&nbsp;&nbsp;POSITIONS
    </router-link>
    <router-link to="/trainings" @click.native="scrollToTop" active-class="has-background-primary active-white" class="is-active navbar-item has-text-grey-darker"
    v-if="$role.state.permissions['read training']">
      <span class="icon"><i class="fa fa-book"></i></span> &nbsp;&nbsp;&nbsp;TRAININGS
    </router-link>
    <a href="https://sites.google.com/dotr.gov.ph/dotr-lds/" target="_blank" active-class="has-background-primary active-white" class="is-active navbar-item has-text-grey-darker">
      <span class="icon"><i class="fa fa-graduation-cap"></i></span> &nbsp;&nbsp;&nbsp;LEARNING & DEVELOPMENT
    </a>
    <router-link to="/appraisals" @click.native="scrollToTop" active-class="has-background-primary active-white" class="is-active navbar-item has-text-grey-darker"
    v-if="$role.state.permissions['search appraisal']">
      <span class="icon"><i class="fa fa-medal"></i></span> &nbsp;&nbsp;&nbsp;APPRAISALS
    </router-link>
    <router-link to="/relations" @click.native="scrollToTop" active-class="has-background-primary active-white" class="is-active navbar-item has-text-grey-darker"
    v-if="$role.state.permissions['search award'] || $role.state.permissions['search offense']">
      <span class="icon"><i class="fa fa-sliders-h"></i></span> &nbsp;&nbsp;&nbsp;RELATIONS
    </router-link>
    <router-link to="/payroll" @click.native="scrollToTop" active-class="has-background-primary active-white" class="is-active navbar-item has-text-grey-darker"
    v-if="$role.state.permissions['read payroll'] || $role.state.permissions['read payroll funding source']">
      <span class="icon"><i class="fa fa-file-invoice-dollar"></i></span> &nbsp;&nbsp;&nbsp;PAYROLL
    </router-link>
    <router-link to="/users" @click.native="scrollToTop" active-class="has-background-primary active-white" class="is-active navbar-item has-text-grey-darker"
    v-if="$role.state.permissions['search user']">
      <span class="icon"><i class="fa fa-user"></i></span> &nbsp;&nbsp;&nbsp;USERS
    </router-link>
    <router-link to="/reports" @click.native="scrollToTop" active-class="has-background-primary active-white" class="is-active navbar-item has-text-grey-darker"
    v-if="($role.state.permissions['print employee'] || $role.state.permissions['print position'] || $role.state.permissions['print salary grade'] ||
        $role.state.permissions['print security'] || $role.state.permissions['print service record'] || $role.state.permissions['print special dates'] ||
        $role.state.permissions['print activity log'] || $role.state.permissions['print time log'] || $role.state.permissions['print daily time record'] ||
        $role.state.permissions['print user'] || $role.state.permissions['print work schedule template'] || $role.state.permissions['print payroll'] || $role.state.permissions['print payroll funding source'])">
      <span class="icon"><i class="fa fa-clipboard"></i></span> &nbsp;&nbsp;&nbsp;REPORTS
    </router-link>
    <router-link to="/settings" @click.native="scrollToTop" active-class="has-background-primary active-white" class="is-active navbar-item has-text-grey-darker"
    v-if="($role.state.permissions['write service record'] || $role.state.permissions['write daily time record'] || $role.state.permissions['write employee'] || $role.state.permissions['write payroll'] || $role.state.permissions['write payroll funding source'])">
      <span class="icon"><i class="fa fa-cog"></i></span> &nbsp;&nbsp;&nbsp;SETTINGS
    </router-link>
    <router-link to="/logs" @click.native="scrollToTop" active-class="has-background-primary active-white" class="is-active navbar-item has-text-grey-darker"
    v-if="$role.state.permissions['read activity log'] || $role.state.permissions['print activity log']">
      <span class="icon"><i class="fa fa-history"></i></span> &nbsp;&nbsp;&nbsp;LOGS
    </router-link>
    <!-- <router-link to="/logs" @click.native="scrollToTop" active-class="has-background-primary active-white" class="is-active navbar-item has-text-grey-darker">
      <span class="icon"><i class="fa fa-scroll"></i></span> &nbsp;&nbsp;&nbsp;Logs
    </router-link> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      userPermission: false,
      navClosed: false,
    }
  },
  name: 'Menu',
  mounted() {
    var checkState = document.getElementById("mySidenav").display
    if (checkState == "none") {
      checkState = document.getElementById("main").style.marginLeft = "0px";
    }
    if(this.$role.state.readUser) {
      this.userPermission = true
    }
    window.addEventListener("resize", this.dynamicMenu);
  },
  destroyed() {
    window.addEventListener("resize", this.dynamicMenu);
  },
  methods: {
    dynamicMenu(e) {
      let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
      if (width < 1216 || this.navClosed == true) {
        document.getElementById("mySidenav").style.width = "0";
        document.getElementById("main").style.marginLeft = "0";
        document.getElementById("closebtn").style.left = "0";
        document.getElementById("closebtn").style.color = 'white';
        document.getElementById("role").style.left = "70px";
      }
      else if (!this.$store.state.isLoggedIn) {

      }
      else {
        document.getElementById("mySidenav").style.width = "250px";
        document.getElementById("main").style.marginLeft = "250px";
        document.getElementById("closebtn").style.left = "180px";
        document.getElementById("closebtn").style.color = '#d32f2f';
        document.getElementById("role").style.left = "270px";
      }
    },
    scrollToTop() {
      window.scrollTo(0,0);
    },
    closeNav() {
      if (this.navClosed == false) {
        document.getElementById("mySidenav").style.width = "0";
        document.getElementById("main").style.marginLeft = "0";
        document.getElementById("closebtn").style.left = "0";
        document.getElementById("closebtn").style.color = 'white';
        document.getElementById("role").style.left = "70px";
        this.navClosed = true
      }
      else if (this.navClosed == true) {
        document.getElementById("mySidenav").style.width = "250px";
        document.getElementById("main").style.marginLeft = "250px";
        document.getElementById("closebtn").style.left = "180px";
        document.getElementById("closebtn").style.color = '#d32f2f';
        document.getElementById("role").style.left = "270px";
        this.navClosed = false
      }
    },
  }
}
</script>

<style>
#mySidenav {
  width: 250px;
  z-index: 100000;
  /* border-top: 65px solid #d32f2f; */
  overflow: hidden;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* IE 10+ */
  padding-top: 30px;
}
#mySidenav::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
}

#main {
  margin-left: 250px;
}

.sidenav img {
  position: relative;
  bottom: 15px;
  /* padding-bottom: 20px; */
}

@media screen and (min-width: 1px) and (max-width: 1215px)  {
  #mySidenav {
    width: 0;
  }
  #main {
    margin-left: 0px;
  }
  #closebtn {
    visibility: hidden;
  }
}

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
  box-shadow: 0px 0px 3px #313131;
  /* border-right: 5px solid black; */
}

.sidenav a {
  padding: 6px 6px 6px 32px;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidenav .closebtn {
  position: fixed;
  top: 0;
  left: 180px;
  font-size: 30px;
  color: #d32f2f;
  /* margin-left: 50px; */
}

.sidenav .closebtn:hover {
  opacity: 0.8;
  /* margin-left: 50px; */
}

#main {
  transition: margin-left .5s;
  padding: 16px;
}

@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
}
</style>

<style scoped>
.is-active:hover {
  background-color: #EEEEEE !important;
}

.active-white {
  color: white !important;
  border-left: 7px solid #d32f2f;
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12) !important;
}

.active-white:hover {
  background-color: #0F3C8B !important;
}

.navbar-item {
  margin: 7px 15px 7px 15px;
}

#role {
  position: fixed;
  left: 270px;
  top: 0;
  margin-top: 0.70rem;
  font-size: 25px;
  transition: left .5s;
}
</style>
