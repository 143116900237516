<template>
  <div>
    <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
    <div class="box">
      <div>
        <b-field>
          <!-- <b-field label="Employee Update">
          </b-field> -->
          <button class="button is-primary" @click.prevent="editRange">
            <i class="fa fa-pen"></i> &nbsp;Edit Update Range
          </button>
        </b-field>
      </div>
      <hr>
      <b-field label="ALLOW EDITING OF EMPLOYEE INFORMATION:"></b-field>
      <b>FROM:</b> {{ update_from }}<br>
      <b>UNTIL:</b> {{ update_to }}
    </div>
    <b-modal :active.sync="isUpdate" has-modal-card :canCancel=false>
      <transition name="fade">
        <form @submit.prevent="saveUpdateFrom()">
          <div class="modal-card" style="margin-top: 4rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">Employee Update</p>
            </header>
            <section class="modal-card-body">
              <b-field label="Allow Update From">
                <b-datepicker
                  placeholder="date from"
                  icon="calendar-today"
                  v-model="update_from"
                  :date-formatter="d => d.toLocaleDateString('en-GB')"
                  :date-parser="d => parseDate(d)"
                  editable>
                </b-datepicker>
              </b-field>
              <b-field label="Allow Update Until">
                <b-datepicker
                  placeholder="date to"
                  icon="calendar-today"
                  v-model="update_to"
                  :date-formatter="d => d.toLocaleDateString('en-GB')"
                  :date-parser="d => parseDate(d)"
                  editable>
                </b-datepicker>
              </b-field>
              <br><br><br><br><br><br><br><br>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" type="submit">Save</button>
              <button class="button is-danger" @click.prevent="cancelUpdate">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  data() {
    return {
      isLoading: true,
      config: {},
      update_from: '',
      update_to: '',
      lunch_hour: '',
      isUpdate: false
    }
  },
  mounted() {
    this.config = {
      headers: {'Authorization': "Bearer " + this.$store.state.token}
    };
    this.getSettings()
  },
  methods: {
    parseDate(d) {
      let parts = d.split("/")
      let dt = new Date(parseInt(parts[2], 10),
        parseInt(parts[1], 10) - 1,
        parseInt(parts[0], 10))
      return dt
    },
    editRange() {
      this.update_from = new Date(this.update_from)
      this.update_to = new Date(this.update_to)
      this.isUpdate = true
    },
    getSettings() {
      this.$http.get(`${this.$host}/api/setting/show`,
        this.config
      ).then((response) => {
        let data = response.data
        this.update_from = data[6].hash_value
        this.update_to = data[7].hash_value
        this.isLoading = false
      }).catch((error) => {
        this.isLoading = false
        //console.log(error)
      });
    },
    saveUpdateFrom() {
      this.isLoading = true
      if (this.update_from == null || this.update_to == null) {
        this.$toast.open({
          message: 'Invalid Date.',
          type: 'is-danger',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.isLoading = false
      }
      else {
        this.$http.put(`${this.$host}/api/setting/update/7`,
          { hash_value: moment(this.update_from).format("YYYY-MM-DD") },
          this.config
        ).then((response) => {
          this.saveUpdateTo()
          // //console.log(data)
        }).catch((error) => {
          //console.log(error)
          this.isLoading = false
        });
      }
    },
    saveUpdateTo() {
      this.$http.put(`${this.$host}/api/setting/update/8`,
        { hash_value: moment(this.update_to).format("YYYY-MM-DD") },
        this.config
      ).then((response) => {
        // //console.log(data)
        this.isUpdate = false
        this.getSettings()
      }).catch((error) => {
        //console.log(error)
        this.isLoading = false
      });
    },
    cancelUpdate() {
      this.isLoading = true
      this.getSettings()
      this.isUpdate = false
    }
  }
}
</script>

<style>

</style>
