<template>
  <div v-if="hasData">
    <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="true"></b-loading>
    <form @submit.prevent="save()">
    <div class="box">
      <div class="content columns">
        <div class="column is-6">
          <b-field label="Position Title">
            <b-input v-model="data.title" :disabled="isDisabled" required></b-input>
          </b-field>
          <b-field label="Item Number">
            <b-input v-model="data.item_number" :disabled="isDisabled" required></b-input>
          </b-field>
          <b-field label="Salary Grade">
            <b-field>
              <b-select placeholder="Select Salary Grade" v-model="salary_grade" :disabled="isDisabled" expanded required>
                <option
                  @click.prevent="removeStep = true"
                  v-for="step in steps"
                  :value="step['1']"
                  :key="step.id">
                  SG-{{ step['salary_grade'] }}
                </option>
                <option :value="this.salary_grade" v-if="!removeStep && !isAdd">
                  SG-{{ this.salary_grade.salary_grade }}
                </option>
              </b-select>
            </b-field>
          </b-field>
          <b-field label="Department / Division">
            <b-input v-model="data.department" disabled required></b-input>
          </b-field>
          <b-field label="Division">
            <b-field>
              <b-input v-model="selectedDivision" disabled expanded required>
              </b-input>
              <p class="control">
                <button class="button is-primary" :disabled="isDisabled" @click.prevent="showDivision"><i class="fa fa-bars"></i></button>
              </p>
            </b-field>
          </b-field>
          <b-field label="Level">
            <b-select placeholder="Select level" v-model="data.level" expanded :disabled="isDisabled" required>
              <option value="1st">1st</option>
              <option value="2nd">2nd</option>
              <option value="3rd">3rd</option>
            </b-select>
          </b-field>
        </div>
        <div class="column is-6">
          <b-field label="Former Position">
            <b-input v-model="data.former_position" :disabled="isDisabled"></b-input>
          </b-field>
          <b-field label="Place of Work">
            <b-input v-model="data.place_of_work" :disabled="isDisabled"></b-input>
          </b-field>
          <b-field label="Salary Authorized">
            <b-input v-model="stepOneLatest" disabled></b-input>
          </b-field>
          <b-field label="Position Title of Immediate Supervisor">
            <b-field>
              <b-input v-model="supervisor" disabled expanded>
              </b-input>
              <p class="control">
                <button class="button is-primary" :disabled="isDisabled" @click.prevent="searchSupervisor"><i class="fa fa-bars"></i></button>
              </p>
            </b-field>
          </b-field>
          <b-field label="Position Title of Next Higher Supervisor">
            <b-field>
              <b-input v-model="higherSupervisor" disabled expanded>
              </b-input>
            </b-field>
          </b-field>
          <b-field label="Classification">
            <b-select placeholder="Select level" v-model="data.classification" expanded :disabled="isDisabled" required>
              <option value="Key">Key</option>
              <option value="Technical">Technical</option>
              <option value="Support to the Technical">Support to the Technical</option>
              <option value="Administrative">Administrative</option>
            </b-select>
          </b-field>
        </div>
      </div>
    </div>
    <div class="box">
      <b-field label="POSITION TITLE, AND ITEM OF THOSE DIRECTLY SUPERVISED">
      </b-field>
      <div class="content columns">
        <div class="column is-6">
          <b-field label="Position Title">
            <b-input type="textarea" v-model="supervised_position_title" :disabled="isDisabled"></b-input>
          </b-field>
        </div>
        <div class="column is-6">
          <b-field label="Item Number">
            <b-input type="textarea" v-model="supervised_item_number" :disabled="isDisabled"></b-input>
          </b-field>
        </div>
      </div>
    </div>
    <div class="box">
      <b-field label="CONTACTS / CLIENTS / STAKEHOLDERS">
      </b-field>
      <div class="content columns">
        <div class="column is-auto">
          <b-field label="INTERNAL">
          </b-field>
          <b-field label="Executive / Managerial">
            <div class="block">
              <b-radio v-model="data.contact_internal_executive"
                native-value="Occasional" :disabled="isDisabled">
                Occasional
              </b-radio>
              <b-radio v-model="data.contact_internal_executive"
                native-value="Frequent" :disabled="isDisabled">
                Frequent
              </b-radio>
            </div>
          </b-field>
          <b-field label="Non-Supervisors">
            <div class="block">
              <b-radio v-model="data.contact_internal_non_supervisor"
                native-value="Occasional" :disabled="isDisabled">
                Occasional
              </b-radio>
              <b-radio v-model="data.contact_internal_non_supervisor"
                native-value="Frequent" :disabled="isDisabled">
                Frequent
              </b-radio>
            </div>
          </b-field>
        </div>
        <div class="column is-auto">
          <b-field label="_" class="is-hidden-mobile">
          </b-field>
          <b-field label="Supervisors">
            <div class="block">
              <b-radio v-model="data.contact_internal_supervisor"
                native-value="Occasional" :disabled="isDisabled">
                Occasional
              </b-radio>
              <b-radio v-model="data.contact_internal_supervisor"
                native-value="Frequent" :disabled="isDisabled">
                Frequent
              </b-radio>
            </div>
          </b-field>
          <b-field label="Staff">
            <div class="block">
              <b-radio v-model="data.contact_internal_staff"
                native-value="Occasional" :disabled="isDisabled">
                Occasional
              </b-radio>
              <b-radio v-model="data.contact_internal_staff"
                native-value="Frequent" :disabled="isDisabled">
                Frequent
              </b-radio>
            </div>
          </b-field>
        </div>
        <div class="column is-auto">
          <b-field label="EXTERNAL">
          </b-field>
          <b-field label="General Public">
            <div class="block">
              <b-radio v-model="data.contact_external_public"
                native-value="Occasional" :disabled="isDisabled">
                Occasional
              </b-radio>
              <b-radio v-model="data.contact_external_public"
                native-value="Frequent" :disabled="isDisabled">
                Frequent
              </b-radio>
            </div>
          </b-field>
          <b-field label="Other Agencies">
            <div class="block">
              <b-radio v-model="data.contact_external_agencies"
                native-value="Occasional" :disabled="isDisabled">
                Occasional
              </b-radio>
              <b-radio v-model="data.contact_external_agencies"
                native-value="Frequent" :disabled="isDisabled">
                Frequent
              </b-radio>
            </div>
          </b-field>
        </div>
        <div class="column is-auto">
          <b-field label="_" class="is-hidden-mobile">
          </b-field>
          <b-field label="Others">
            <b-input v-model="data.contact_external_others" :disabled="isDisabled"></b-input>
          </b-field>
        </div>
      </div>
    </div>
    <div class="box">
      <div class="content columns">
        <div class="column is-3">
          <b-field label="WORKING CONDITIONS">
          </b-field>
          <b-field label="Office Work">
            <div class="block">
              <b-radio v-model="data.office_work"
                native-value="Occasional" :disabled="isDisabled">
                Occasional
              </b-radio>
              <b-radio v-model="data.office_work"
                native-value="Frequent" :disabled="isDisabled">
                Frequent
              </b-radio>
            </div>
          </b-field>
          <b-field label="Field Work">
            <div class="block">
              <b-radio v-model="data.field_work"
                native-value="Occasional" :disabled="isDisabled">
                Occasional
              </b-radio>
              <b-radio v-model="data.field_work"
                native-value="Frequent" :disabled="isDisabled">
                Frequent
              </b-radio>
            </div>
          </b-field>
          <b-field label="Others">
            <b-input v-model="data.other_work" :disabled="isDisabled"></b-input>
          </b-field>
        </div>
        <div class="column is-auto">
          <b-field label="Brief Description of the General Function of the Unit or Section">
            <b-input rows="8" type="textarea" :disabled="isDisabled" v-model="data.general_function"></b-input>
          </b-field>
        </div>
      </div>
      <div class="content columns">
        <div class="column is-auto">
          <b-field label="Brief Description of the General Function of the Position">
            <b-input rows="6" type="textarea" :disabled="isDisabled" v-model="data.job_summary"></b-input>
          </b-field>
        </div>
      </div>
    </div>
    <div class="box">
      <b-field label="Qualification Standards">
      </b-field>
      <div class="content columns">
        <div class="column is-auto">
          <b-field label="Education">
            <b-input rows="4" type="textarea" :disabled="isDisabled" v-model="data.education"></b-input>
          </b-field>
        </div>
        <div class="column is-auto">
          <b-field label="Experience">
            <b-input rows="4" type="textarea" :disabled="isDisabled" v-model="data.experience"></b-input>
          </b-field>
        </div>
        <div class="column is-auto">
          <b-field label="Training">
            <b-input rows="4" type="textarea" :disabled="isDisabled" v-model="data.training"></b-input>
          </b-field>
        </div>
        <div class="column is-auto">
          <b-field label="Eligibility">
            <b-input rows="4" type="textarea" :disabled="isDisabled" v-model="data.eligibility"></b-input>
          </b-field>
        </div>
      </div>
      <div class="content columns">
        <div class="column is-9">
          <hr>
          <b-field label="Core Competencies">
            <b-input rows="4" type="textarea" :disabled="isDisabled" v-model="data.core_compentencies"></b-input>
          </b-field>
        </div>
        <div class="column is-3">
          <hr>
          <b-field label="Competency Level">
            <b-input rows="4" type="textarea" :disabled="isDisabled" v-model="data.core_competency_level"></b-input>
          </b-field>
        </div>
      </div>
      <div class="content columns">
        <div class="column is-9">
          <hr>
          <b-field label="Leadership Competencies">
            <b-input rows="4" type="textarea" :disabled="isDisabled" v-model="data.leadership_competencies"></b-input>
          </b-field>
        </div>
        <div class="column is-3">
          <hr>
          <b-field label="Competency Level">
            <b-input rows="4" type="textarea" :disabled="isDisabled" v-model="data.leadership_compentency_level"></b-input>
          </b-field>
        </div>
      </div>
    </div>
    <div class="box">
      <b-field label="Statement of Duties and Responsibilities (Technical Competencies)">
      </b-field>
      <div class="content columns">
        <div class="column is-3">
          <b-field label="Percentage of Working Time">
            <b-input rows="4" type="textarea" :disabled="isDisabled" v-model="data.percentage_working_time"></b-input>
          </b-field>
        </div>
        <div class="column is-auto">
          <b-field label="State the duties and responsibilities here">
            <b-input rows="4" type="textarea" :disabled="isDisabled" v-model="data.duties_responsibilities"></b-input>
          </b-field>
        </div>
        <div class="column is-3">
          <b-field label="Competency Level">
            <b-input rows="4" type="textarea" :disabled="isDisabled" v-model="data.duties_competency_level"></b-input>
          </b-field>
        </div>
      </div>
    </div>
    <div class="box">
      <b-field label="Equipments">
      </b-field>
      <div class="content columns">
        <div class="column is-auto">
          <b-field grouped group-multiline>
            <div class="control" v-for="equipment in selectedEquipments" :key="equipment.id" :value="equipment">
              <b-tag type="is-primary" closable @close="deleteEquipment(equipment)" :disabled="isDisabled">{{ equipment.name }}</b-tag>
            </div>
          </b-field>
          <b-field>
            <b-field>
              <b-autocomplete
                v-model="equipment"
                :data="filteredEquipments"
                placeholder="Add Equipments"
                field="name"
                :disabled="isDisabled"
                @select="option => selected = option">
              </b-autocomplete>
              <p class="control">
                <button class="button is-success" :disabled="isDisabled" @click.prevent="addEquipment">Add</button>
              </p>
            </b-field>
          </b-field>
        </div>
      </div>
    </div>
    <button class="button is-success" type="submit" v-if="isAdd && $role.state.permissions['write position']">
      Save
    </button>
    <!-- <button class="button is-success" @click.prevent="isDisabled ? edit() : save()" v-if="!isAdd && $role.state.permissions['write position']">
      {{ editSave }}
    </button> -->
    <button class="button is-success" type="submit" v-if="!isAdd && !isDisabled && $role.state.permissions['write position']">
      Save
    </button>
    <button class="button is-success" @click.prevent="edit()" v-if="!isAdd && isDisabled && $role.state.permissions['write position']">
      Edit
    </button>
    <button class="button is-danger" @click.prevent="cancelPrompt" :disabled="cancelDisabled" v-if="!isAdd && $role.state.permissions['write position']">
      Cancel
    </button>
    </form>
    <b-modal :active.sync="divisionActive" has-modal-card>
      <div class="modal-card pos" style="width: 60rem; height: 35.625rem; margin-left: 100px;">
        <Division style="overflow: auto;" :divisions="divisions" @reset="setDivision" @close="showDivision" @confirm="selDivision" :isPos="true"
        />
      </div>
    </b-modal>
    <transition name="fade">
      <Search :isSupervisor="true" :data="positions" @view="getSupervisor" v-if="isSearch" @close="close"/>
    </transition>
  </div>
</template>

<script>
import Division from '../../components/settings/Division.vue'
import Search from './Search.vue'

export default {
  props: ['data', 'isAdd'],
  components: {
    Division,
    Search
  },
  data() {
    return {
      radio: '',
      isDisabled: true,
      editSave: 'Edit',
      cancelDisabled: true,
      steps: '',
      latestTranche: '',
      editSave: 'Edit',
      salary_grade: '',
      divisionActive: false,
      divisions: '',
      selectedDivision: '',
      equipments: [],
      id: '',
      removeStep: false,
      isSearch: false,
      positions: [],
      supervisor: '',
      higherSupervisor: '',
      isLoading: false,
      equipment: '',
      selectedEquipments: [],
      hasData: false,
      previousData: {},
      supervised_position_title: '',
      supervised_item_number: ''
    }
  },
  mounted() {
    //console.log(this.data.id + 'fdf')
    if (this.isAdd) {
      this.hasData = true
      this.isDisabled = false
      this.$editmode.commit('editing', true)
      this.data.equipments = []
    }
    if (this.data.id) {
      this.hasData = true
      for (let i = 0; i < this.data.equipments.length; i++) {
        delete this.data.equipments[i].created_at
        delete this.data.equipments[i].updated_at
        delete this.data.equipments[i].pivot
      }
      this.selectedEquipments = this.data.equipments
      this.getImSupervisor()
      this.id = this.data.id
      this.salary_grade = this.data.salarygrade
      this.selectedDivision = this.data.division.name
    }
    else {
      this.id = ''
    }
    this.data.department = "Department of Transportation"
    this.setSalaryGrade()
    this.setDivision()
    this.setEquipment()
    this.supervised_position_title = JSON.parse(this.data.supervised_position_title)
    this.supervised_item_number = JSON.parse(this.data.supervised_item_number)
  },
  computed: {
    stepOneLatest() {
      return this.salary_grade.salary
    },
    filteredEquipments() {
      return this.equipments.filter((option) => {
        return option.name
          .toString()
          .toLowerCase()
          .indexOf(this.equipment.toLowerCase()) >= 0
      })
    }
  },
  methods: {
    setPosition() {
      let config = {
        headers: {'Authorization': "Bearer " + this.$store.state.token}
      };

      this.$http.get(`${this.$host}/api/position/search/${this.data.id}`,
        config
      ).then((response) => {
        this.data = response.data.data
      }).catch((error) => {
        //console.log(error)
      });
    },
    deleteEquipment(name) {
      this.selectedEquipments = this.selectedEquipments.filter(x => x !== name)
    },
    addEquipment() {
      let equipment = this.equipments.find(x => x.name === this.equipment)
      if (equipment == undefined) {
        let obj = {
          id: null,
          name: this.equipment
        }
        this.selectedEquipments.push(obj)
        this.equipment = ''
      }
      else if (equipment) {
        delete equipment.created_at
        delete equipment.updated_at
        delete equipment.pivot
        this.selectedEquipments.push(equipment)
        this.equipment = ''
      }
    },
    close() {
      this.isSearch = false
    },
    searchSupervisor() {
      this.isSearch = !this.isSearch
      this.isLoading = true
      let config = {
        headers: {'Authorization': "Bearer " + this.$store.state.token}
      };

      this.$http.get(`${this.$host}/api/position/search`,
        config
      ).then((response) => {
        let data = response.data.data
        this.positions = data
        this.isLoading = false
      }).catch((error) => {
        //console.log(error)
        this.isLoading = false
      });
    },
    getSupervisor(obj) {
      this.data.supervisor_id = obj.id
      this.supervisor = obj.title
      let searchHigh
      let config = {
        headers: {'Authorization': "Bearer " + this.$store.state.token}
      };
      this.$http.get(`${this.$host}/api/position/search/${this.data.supervisor_id}`,
        config
      ).then((response) => {
        let data = response.data.data
        searchHigh = data.supervisor_id
        this.$http.get(`${this.$host}/api/position/search/${searchHigh}`,
          config
        ).then((response) => {
          let data = response.data.data
          this.higherSupervisor = data.title
        }).catch((error) => {
          //console.log(error)
          this.higherSupervisor = ''
        });
      }).catch((error) => {
        //console.log(error)
      });
      this.close()
    },
    getImSupervisor() {
      this.isLoading = true
      let searchHigh
      let config = {
        headers: {'Authorization': "Bearer " + this.$store.state.token}
      };

      this.$http.get(`${this.$host}/api/position/search/${this.data.supervisor_id}`,
        config
      ).then((response) => {
        let data = response.data.data
        this.supervisor = data.title
        searchHigh = data.supervisor_id
        this.$http.get(`${this.$host}/api/position/search/${searchHigh}`,
          config
        ).then((response) => {
          let data = response.data.data
          this.higherSupervisor = data.title
          this.isLoading = false
        }).catch((error) => {
          //console.log(error)
          this.higherSupervisor = ''
          this.isLoading = false
        });
      }).catch((error) => {
        //console.log(error)
        this.isLoading = false
      });
    },
    setDivision() {
      this.isLoading = true
      let config = {
        headers: {'Authorization': "Bearer " + this.$store.state.token}
      };

      this.$http.get(`${this.$host}/api/division/show`,
        config
      ).then((response) => {
        let data = response.data.data
        this.divisions = data
        this.isLoading = false
      }).catch((error) => {
        this.isLoading = false
        //console.log(error)
      });
    },
    setEquipment() {
      this.isLoading = true
      let config = {
        headers: {'Authorization': "Bearer " + this.$store.state.token}
      };

      this.$http.get(`${this.$host}/api/equipment/search`,
        config
      ).then((response) => {
        let data = response.data.data
        this.equipments = data
        this.isLoading = false
      }).catch((error) => {
        this.isLoading = false
        //console.log(error)
      });
    },
    setSalaryGrade() {
      let config = {
        headers: {'Authorization': "Bearer " + this.$store.state.token}
      };

      this.$http.get(`${this.$host}/api/salary-grade/search`,
        config
      ).then((response) => {
        let data = response.data.data
        this.latestTranche = Object.keys(data)[Object.keys(data).length - 1]
        this.setLatestTranche()
      }).catch((error) => {
        //console.log(error)
        // alert(error.errors)
      });
    },
    setLatestTranche() {
      let config = {
        headers: {'Authorization': "Bearer " + this.$store.state.token}
      };

      this.$http.get(`${this.$host}/api/salary-grade/search/${this.latestTranche}`,
        config
      ).then((response) => {
        let data = response.data.data
        this.steps = data[this.latestTranche]
        // //console.log(JSON.stringify(data))
        //console.log(JSON.stringify(this.steps))
      }).catch((error) => {
        //console.log(error)
        // alert(error.errors)
      });
    },
    cancel() {
      this.setPosition()
      this.editSave = "Edit"
      this.isDisabled = true
      this.cancelDisabled= true
      this.$editmode.commit('editing', false)
    },
    cancelPrompt() {
      this.$dialog.confirm({
        title: 'Discard Changes',
        message: 'Are you sure you want to <b>discard</b> your changes? This action cannot be undone.',
        confirmText: 'Discard',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.cancel()
      })
    },
    edit() {
      this.editSave = "Save"
      this.isDisabled = false
      this.cancelDisabled = false
      this.$editmode.commit('editing', true)
    },
    save() {
      this.$dialog.confirm({
        title: 'Save',
        message: '<b>Save</b> your changes?',
        confirmText: 'Save',
        type: 'is-success',
        hasIcon: true,
        onConfirm: () => this.confirmSave()
      })
    },
    confirmSave() {
      this.data.supervised_position_title = JSON.stringify(this.supervised_position_title).replace(/\"/g,"")
      this.data.supervised_item_number = JSON.stringify(this.supervised_item_number).replace(/\"/g,"")
      this.data.salary_grade_id = this.salary_grade.id
      this.data.equipments = this.selectedEquipments
      //console.log(JSON.stringify(this.data) + 'asd')
      this.deleteNonParams()
      let config = {
        headers: {'Authorization': "Bearer " + this.$store.state.token}
      };
      this.$http.post(`${this.$host}/api/position/save/${this.id}`,
        this.data,
        config,
      ).then((response) => {
        let data = response.data.data
        this.$editmode.commit('editing', false)
        if (this.isAdd) {
          this.$editmode.commit('setNewPositionId', response.data.data.id)
          this.$editmode.commit('setPositionAdd', true)
          this.$router.push('/position')
        }
        this.successSave()

        //console.log(data)
      }).catch((error) => {
        if (error.errors.item_number) {
          this.$toast.open({
            message: 'Item number already taken.',
            type: 'is-danger',
            position: 'is-bottom-right',
            duration: 5000
          })
        }
        else {
          this.errorSave()
        }

        //console.log(error)
      });
    },
    deleteNonParams() {
      for (let i = 0; i < this.data.equipments.length; i++) {
        delete this.data.equipments[i].created_at
        delete this.data.equipments[i].updated_at
      }
      delete this.data.updated_at
      delete this.data.salarygrade
      delete this.data.division
      delete this.data.supervisor
    },
    errorSave() {
      this.editSave = "Save"
      this.isDisabled = false
      this.cancelDisabled = false
      this.$toast.open({
        message: 'Error saving data. Please make sure to fill the required fields.',
        type: 'is-danger',
        position: 'is-bottom-right',
        duration: 4000
      })
    },
    successSave() {
      window.scrollTo(0,0)
      this.$editmode.commit('editing', false)
      this.editSave = "Edit"
      this.isDisabled = true
      this.cancelDisabled = true
      this.$toast.open({
        message: 'Position successfully saved.',
        type: 'is-success',
        position: 'is-bottom-right',
        duration: 4000
      })
    },
    showDivision() {
      this.divisionActive = !this.divisionActive
    },
    selDivision(obj) {
      // alert(obj.id)
      this.selectedDivision = obj.name
      this.data.division_id = obj.id
      this.showDivision()
    },
  }
}
</script>

<style scoped>
hr {
  margin: 0 !important;
  margin-bottom: 20px !important;
}
@media screen and (min-width: 1px) and (max-width: 768px)  {
  .modal-card {
    margin-left: 0px !important;
    width: 100% !important;
    margin-top: 70px !important;
  }
}
.modal-card-head {
  height: 50px;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
@media screen and (min-width: 1px) and (max-width: 1215px)  {
  .modal-card {
    margin-left: 0px !important;
    width: 100% !important;
  }
  .pos {
    width: none;
  }
}
.pos {
  margin-top: 70px !important;
}
.modal-card-head {
  height: 50px;
}
</style>
