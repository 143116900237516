<template>
  <div class="container column is-9">
    <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
    <button class="button is-success" style="margin-bottom: 10px;" @click.prevent="edit()" v-if="this.$editmode.state.isUpdate">
      Edit Information
    </button>
    <!-- Personal Information -->
    <b-collapse class="card" :open.sync="isOpen1">
      <div slot="trigger" slot-scope="props" class="card-header" role="button" @click.prevent="activeTab(1)">
        <p class="card-header-title has-background-primary has-text-white">
          Personal Information
        </p>
        <a class="card-header-icon has-background-primary has-text-white">
          <b-icon
              :icon="props.open ? 'menu-up' : 'menu-down'">
          </b-icon>
        </a>
      </div>
      <div class="card-content">
        <div class="content columns" :style="{ 'text-transform': capitalize }">
          <div class="column is-12">
            <div class="columns">
              <div class="column is-12">
                <b>Agency Employee ID:</b> {{ d.employee_number }} <br>
                <b>Last Name:</b> {{ d.last_name }} <br>
                <b>First Name:</b> {{ d.first_name }} <b>Name Extension:</b> {{ d.name_extension }} <br>
                <b>Middle Name:</b> {{ d.middle_name }}
              </div>
            </div>
            <div class="columns">
              <div class="column is-5">
                <b>Date of Birth:</b> {{ d.birth_date }}<br>
                <b>Place of Birth:</b> {{ d.birth_place }}<br>
                <b>Sex:</b> {{ d.gender }} <br>
                <b>Civil Status:</b> {{ d.civil_status }}<br>
                <b>Height:</b> {{ d.height }} (m)<br>
                <b>Weight:</b> {{ d.weight }} (kg)<br>
                <b>Blood Type:</b> {{ d.blood_type }}<br>
                <b>GSIS ID Number:</b> {{ d.gsis }}<br>
                <b>PAG-IBIG ID Number:</b> {{ d.pagibig }}<br>
                <b>PHILHEALTH Number:</b> {{ d.philhealth }}<br>
                <b>SSS Number:</b> {{ d.sss }}<br>
                <b>TIN Number:</b> {{ d.tin }}<br>
              </div>
              <div class="column is-7">
                <b>Citizenship:</b> {{ d.citizenship }} by {{ d.citizenship_by }}<br>
                <b>Dual Citizenship:</b>  {{ d.citizenship_by_country }}<br>
                <b>Residential Address:</b> {{ d.residential_housenum }} {{ d.residential_street }}
                {{ d.residential_subdivision }} {{ d.residential_barangay }} {{ d.residential_city }} {{ d.residential_zipcode }}<br>
                <b>Permanent Address:</b> {{ d.permanent_housenum }} {{ d.permanent_street }}
                {{ d.permanent_subdivision }} {{ d.permanent_barangay }} {{ d.permanent_city }} {{ d.permanent_zipcode }} <br>
                <b>Telephone Number:</b> {{ d.telephone }}<br>
                <b>Mobile Number:</b> {{ d.mobile }}<br>
                <b>E-mail Address:</b> {{ d.email }}<br>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-collapse>
    <br>
    <!-- Family Background -->
    <b-collapse class="card" :open.sync="isOpen2">
      <div slot="trigger" slot-scope="props" class="card-header" role="button" @click.prevent="activeTab(2)">
        <p class="card-header-title has-background-primary has-text-white">
          Family Background
        </p>
        <a class="card-header-icon has-background-primary has-text-white">
          <b-icon
              :icon="props.open ? 'menu-up' : 'menu-down'">
          </b-icon>
        </a>
      </div>
      <div class="card-content">
        <div class="content columns" :style="{ 'text-transform': capitalize }">
          <div class="column is-12">
            <div class="columns">
              <div class="column is-6">
                <b>Spouse</b> <br>
                <b>Last Name:</b> {{ d.spouse_last_name }}<br>
                <b>First Name:</b> {{ d.spouse_first_name }} <b>Name Extension:</b> {{ d.spouse_name_extension }} <br>
                <b>Middle Name:</b> {{ d.spouse_middle_name }} <br><br>
                <b>Occupation:</b> {{ d.spouse_occupation }}<br>
                <b>Employer/Business Name:</b> {{ d.spouse_employer_business }} <br>
                <b>Business Address:</b> {{ d.spouse_business_address }} <br>
                <b>Telephone Number:</b> {{  }} <br>
                <br>
                <b>Children:</b> <br>
                {{  }}
              </div>
              <div class="column is-6">
                <b>Father</b> <br>
                <b>Last Name:</b> {{ d.father_last_name }}<br>
                <b>First Name:</b> {{ d.father_first_name }} <b>Name Extension:</b> {{ d.father_name_extension }} <br>
                <b>Middle Name:</b> {{ d.father_middle_name }} <br>
                <br>
                <b>Mother</b> <br>
                <b>Maiden Name:</b> {{  }}<br>
                <b>Last Name:</b> {{ d.mother_last_name }}<br>
                <b>First Name:</b> {{ d.mother_first_name }} <b>Name Extension:</b> {{ d.mother_name_extension }} <br>
                <b>Middle Name:</b> {{ d.mother_middle_name }} <br>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-collapse>
    <br>
    <!-- Educational Background -->
    <b-collapse class="card" :open.sync="isOpen3">
      <div slot="trigger" slot-scope="props" class="card-header" role="button" @click.prevent="activeTab(3)">
        <p class="card-header-title has-background-primary has-text-white">
          Educational Background
        </p>
        <a class="card-header-icon has-background-primary has-text-white">
          <b-icon
              :icon="props.open ? 'menu-up' : 'menu-down'">
          </b-icon>
        </a>
      </div>
      <div class="card-content">
        <div class="content columns" style="overflow-x: scroll;" :style="{ 'text-transform': capitalize }">
          <div class="column is-12">
            <b-table :data="d.education" :narrowed="false">
              <template slot-scope="props">
                <b-table-column field="level" label="Level">
                  {{ props.row.level }}
                </b-table-column>
                <b-table-column field="school_name" label="School Name">
                  {{ props.row.school_name }}
                </b-table-column>
                <b-table-column field="course" label="Course">
                  {{ props.row.course }}
                </b-table-column>
                <b-table-column field="attended_from" label="Attended">
                  {{ props.row.attended_from }} - {{ props.row.attended_to }}
                </b-table-column>
                <b-table-column field="graduated" label="Graduated">
                  {{ graduated(props.row.graduated) }}
                </b-table-column>
                <b-table-column field="highest_level" label="Highest Level">
                  {{ props.row.highest_level }}
                </b-table-column>
                <b-table-column field="year_graduated" label="Year Graduated">
                  {{ props.row.year_graduated }}
                </b-table-column>
                <b-table-column field="honors" label="Honors">
                  {{ props.row.honors }}
                </b-table-column>
              </template>
              <template slot="empty">
                <b-table-column field="level" label="Level">
                </b-table-column>
                <b-table-column field="school_name" label="School Name">
                </b-table-column>
                <b-table-column field="course" label="Course">
                </b-table-column>
                <b-table-column field="attended_from" label="Attended">
                </b-table-column>
                <b-table-column field="graduated" label="Graduated">
                </b-table-column>
                <b-table-column field="highest_level" label="Highest Level">
                </b-table-column>
                <b-table-column field="year_graduated" label="Year Graduated">
                </b-table-column>
                <b-table-column field="honors" label="Honors">
                </b-table-column>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </b-collapse>
    <br>
    <!-- Civil Service Eligibility -->
    <b-collapse class="card" :open.sync="isOpen4">
      <div slot="trigger" slot-scope="props" class="card-header" role="button" @click.prevent="activeTab(4)">
        <p class="card-header-title has-background-primary has-text-white">
          Civil Service Eligibility
        </p>
        <a class="card-header-icon has-background-primary has-text-white">
          <b-icon
              :icon="props.open ? 'menu-up' : 'menu-down'">
          </b-icon>
        </a>
      </div>
      <div class="card-content">
        <div class="content columns" style="overflow-x: scroll;" :style="{ 'text-transform': capitalize }">
          <div class="column is-12">
            <b-table :data="d.eligibilities" :narrowed="true">
              <template slot-scope="props">
                <b-table-column label="Type">
                  {{ props.row.type }}
                </b-table-column>
                <b-table-column label="Rating">
                  {{ props.row.rating }}
                </b-table-column>
                <b-table-column label="Date Conferment">
                  {{ props.row.date_conferment }}
                </b-table-column>
                <b-table-column label="Place Conferment">
                  {{ props.row.place_conferment }}
                </b-table-column>
              </template>
              <template slot="empty">
                <b-table-column label="Type">
                </b-table-column>
                <b-table-column label="Rating">
                </b-table-column>
                <b-table-column label="Date Conferment">
                </b-table-column>
                <b-table-column label="Place Conferment">
                </b-table-column>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </b-collapse>
    <br>
    <!-- Work Experience -->
    <b-collapse class="card" :open.sync="isOpen5">
      <div slot="trigger" slot-scope="props" class="card-header" role="button" @click.prevent="activeTab(5)">
        <p class="card-header-title has-background-primary has-text-white">
          Work Experience
        </p>
        <a class="card-header-icon has-background-primary has-text-white">
          <b-icon
              :icon="props.open ? 'menu-up' : 'menu-down'">
          </b-icon>
        </a>
      </div>
      <div class="card-content">
        <div class="content columns" style="overflow-x: scroll;" :style="{ 'text-transform': capitalize }">
          <div class="column is-12">
            <b-table :data="d.workexperiences" :narrowed="true">
              <template slot-scope="props">
                <b-table-column label="Position">
                  {{ props.row.position }}
                </b-table-column>
                <b-table-column label="Date">
                  {{ props.row.date_from }} - {{ props.row.date_to }}
                </b-table-column>
                <b-table-column label="Company">
                  {{ props.row.company }}
                </b-table-column>
                <b-table-column label="Salary">
                  {{ props.row.salary }}
                </b-table-column>
                <b-table-column label="Pay Grade">
                  {{ props.row.pay_grade }}
                </b-table-column>
                <b-table-column label="Status of Appointnment">
                  {{ props.row.status_of_appointment }}
                </b-table-column>
              </template>
              <template slot="empty">
                <b-table-column label="Position">
                </b-table-column>
                <b-table-column label="Date">
                </b-table-column>
                <b-table-column label="Company">
                </b-table-column>
                <b-table-column label="Salary">
                </b-table-column>
                <b-table-column label="Pay Grade">
                </b-table-column>
                <b-table-column label="Status of Appointnment">
                </b-table-column>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </b-collapse>
    <br>
    <!-- Voluntary Work -->
    <b-collapse class="card" :open.sync="isOpen6">
      <div slot="trigger" slot-scope="props" class="card-header" role="button" @click.prevent="activeTab(6)">
        <p class="card-header-title has-background-primary has-text-white">
          Voluntary Work or Involvement in Civic/Non-Governemnt/People/Voluntary Organizations
        </p>
        <a class="card-header-icon has-background-primary has-text-white">
          <b-icon
              :icon="props.open ? 'menu-up' : 'menu-down'">
          </b-icon>
        </a>
      </div>
      <div class="card-content">
        <div class="content columns" style="overflow-x: scroll;" :style="{ 'text-transform': capitalize }">
          <div class="column is-12">
            <b-table :data="d.volunteerings" :narrowed="true">
              <template slot-scope="props">
                <b-table-column label="Organization">
                  {{ props.row.organization }}
                </b-table-column>
                <b-table-column label="Date">
                  {{ props.row.date_from }} - {{ props.row.date_to }}
                </b-table-column>
                <b-table-column label="Number of Hours">
                  {{ props.row.number_of_hours }}
                </b-table-column>
                <b-table-column label="Position">
                  {{ props.row.position }}
                </b-table-column>
                <b-table-column label="Nature of Work">
                  {{ props.row.nature_of_work }}
                </b-table-column>
              </template>
              <template slot="empty">
                <b-table-column label="Organization">
                </b-table-column>
                <b-table-column label="Date">
                </b-table-column>
                <b-table-column label="Number of Hours">
                </b-table-column>
                <b-table-column label="Position">
                </b-table-column>
                <b-table-column label="Nature of Work">
                </b-table-column>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </b-collapse>
    <br>
    <!-- Learning and Development Interventions/Training Programs Attended -->
    <b-collapse class="card" :open.sync="isOpen7">
      <div slot="trigger" slot-scope="props" class="card-header" role="button" @click.prevent="activeTab(7)">
        <p class="card-header-title has-background-primary has-text-white">
          Learning and Development Interventions/Training Programs Attended
        </p>
        <a class="card-header-icon has-background-primary has-text-white">
          <b-icon
              :icon="props.open ? 'menu-up' : 'menu-down'">
          </b-icon>
        </a>
      </div>
      <div class="card-content">
        <div class="content columns" style="overflow-x: scroll;" :style="{ 'text-transform': capitalize }">
          <div class="column is-12">
            <b-table :data="d.trainings" :narrowed="true">
              <template slot-scope="props">
                <b-table-column label="Program">
                  {{ props.row.program }}
                </b-table-column>
                <b-table-column label="Date">
                  {{ props.row.date_from }} - {{ props.row.date_to }}
                </b-table-column>
                <b-table-column label="Number of Hours">
                  {{ props.row.number_of_hours }}
                </b-table-column>
              </template>
              <template slot="empty">
                <b-table-column label="Program">
                </b-table-column>
                <b-table-column label="Date">
                </b-table-column>
                <b-table-column label="Number of Hours">
                </b-table-column>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </b-collapse>
    <br>
    <!-- Other Information -->
    <b-collapse class="card" :open.sync="isOpen8">
      <div slot="trigger" slot-scope="props" class="card-header" role="button" @click.prevent="activeTab(8)">
        <p class="card-header-title has-background-primary has-text-white">
          Other Information
        </p>
        <a class="card-header-icon has-background-primary has-text-white">
          <b-icon
              :icon="props.open ? 'menu-up' : 'menu-down'">
          </b-icon>
        </a>
      </div>
      <div class="card-content">
        <div class="content columns" :style="{ 'text-transform': capitalize }">
          <div class="column is-12">
            <div class="columns">
              <div class="column is-12">
                <b>Skills:</b><br>
                <span v-for="skill in d.skills" :key="skill.id">
                  {{ skill.skill }},
                </span>
              </div>
            </div>
            <div class="columns">
              <div class="column is-12">
                <b>Recognitions:</b><br>
                <span v-for="recog in d.recognitions" :key="recog.id">
                  {{ recog.recognition }},
                </span>
              </div>
            </div>
            <div class="columns">
              <div class="column is-12">
                <b>Memberships:</b><br>
                <span v-for="member in d.memberships" :key="member.id">
                  {{ member.membership }},
                </span>
              </div>
            </div>
            <div class="columns">
              <div class="columns" v-for="other in d.otherinfos" v-bind:key="other.id">
                <div class="column is-12">
                <!-- <div class="column is-12" v-for="" v-bind:key=""> -->
                  <b>Special Skills:</b> {{ other.special_skill }}<br>
                  <b>Hobbies:</b> {{ other.hobbies }}
                  <b>Non-Academic Distinctions/Recognition:</b> {{ other.other_distinctions }}<br>
                  <b>Membership in Association/Organization:</b> {{ other.organization_membership }}<br>
                </div>
              </div>
              <div class="column is-12">
              <!-- <div class="column is-12" v-for="" v-bind:key=""> -->
                <b>Affiliated Third:</b> {{ isTrue(d.is_affiliated_third) }} - {{ d.affiliated_third }}<br>
                <b>Affiliated Fourth:</b> {{ isTrue(d.is_affiliated_fourth) }} - {{ d.affiliated_fourth }}<br>
                <b>Found Guilty:</b> {{ isTrue(d.is_found_guilty) }} - {{ d.found_guilty }}<br>
                <b>Criminally Charged:</b> {{ isTrue(d.is_criminally_charged) }}<br>
                <b>Criminally Charged Date:</b> {{ d.criminally_charged_date }}<br>
                <b>Criminally Charged Status:</b> {{ d.criminally_charged_status }}<br>
                <b>Convicted:</b> {{ isTrue(d.is_convicted) }} - {{ d.convicted }}<br>
                <b>Separated Service:</b> {{ isTrue(d.is_separated_service) }} - {{ d.separated_service }}<br>
                <b>Candidate:</b> {{ isTrue(d.is_candidate) }} - {{ d.candidate }}<br>
                <b>Resigned:</b> {{ isTrue(d.is_resigned) }} - {{ d.resigned }}<br>
                <b>Immigrant:</b> {{ isTrue(d.is_immigrant) }} - {{ d.immigrant }}<br>
                <b>Indigenous:</b> {{ isTrue(d.is_indigenous) }}<br>
                <b>Disabled:</b> {{ isTrue(d.is_disabled) }}<br>
                <b>Solo:</b> {{ isTrue(d.is_solo) }}<br>
                <b>Government ID Type:</b> {{ d.government_id_type }}<br>
                <b>Government ID Number:</b> {{ d.government_id_number }}<br>
                <b>Issued Date:</b> {{ d.issued_date }}<br>
                <b>Issued Place:</b> {{ d.issued_place }}<br>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-collapse>
    <br>
    <!-- References -->
    <b-collapse class="card" :open.sync="isOpen9">
      <div slot="trigger" slot-scope="props" class="card-header" role="button" @click.prevent="activeTab(9)">
        <p class="card-header-title has-background-primary has-text-white">
          References
        </p>
        <a class="card-header-icon has-background-primary has-text-white">
          <b-icon
              :icon="props.open ? 'menu-up' : 'menu-down'">
          </b-icon>
        </a>
      </div>
      <div class="card-content">
        <div class="content columns" :style="{ 'text-transform': capitalize }">
          <div class="column is-12">
            <b-table :data="d.references" :narrowed="true">
              <template slot-scope="props">
                <b-table-column label="Name">
                  {{ props.row.name }}
                </b-table-column>
                <b-table-column label="Address">
                  {{ props.row.address }}
                </b-table-column>
                <b-table-column label="Telephone">
                  {{ props.row.telephone }}
                </b-table-column>
              </template>
              <template slot="empty">
                <b-table-column label="Name">
                </b-table-column>
                <b-table-column label="Address">
                </b-table-column>
                <b-table-column label="Telephone">
                </b-table-column>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>

export default {
  data() {
    return {
      isLoading: false,
      d: '',
      isGraduate: false,
      isOpen1: true,
      isOpen2: false,
      isOpen3: false,
      isOpen4: false,
      isOpen5: false,
      isOpen6: false,
      isOpen7: false,
      isOpen8: false,
      isOpen9: false,
      lower: {},
      capitalize: {}
    }
  },
  mounted() {
    this.isLoading = true
    let config = {
      headers: {'Authorization': "Bearer " + this.$store.state.token}
    };

    this.$http.get(`${this.$host}/api/user`,
      config
    ).then((response) => {
      if (response.data.data.employee != null) {
        let data = response.data.data.employee
        this.$editmode.commit('setUpdate', response.data.data.is_update)
        this.d = data
        this.capitalize = 'capitalize !important'
        this.isLoading = false
      }
      else {
        this.isLoading = false
      }
    }).catch((error) => {
      this.isLoading = false
      //console.log(error)
    });
  },
  methods: {
    edit() {
      this.$router.push('/employees/update')
    },
    getInformation() {

    },
    graduated(bool) {
      if (bool == 1) {
        this.isGraduate = true
        return 'Yes'
      }
      else {
        return 'No'
      }
    },
    isTrue(bool) {
      if (bool == 1) {
        return 'Yes'
      }
      else {
        return 'No'
      }
    },
    activeTab(tab) {
      let i = 1
      let active = "isOpen" + tab
      while (i < 10) {
        let value = "isOpen" + i
        if (this[value] != this[active]) {
          this[value] = false
        }
        else {

        }
        i++
      }

    }
  }
}
</script>
