<template>
  <div class="box">
    <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
    <div>
      <b-field>
        <b-select placeholder="Date" v-model="perDate" @input="getGSIS()">
          <option
            v-for="date in dates"
            :value="date"
            :key="date">
            {{ date }}
          </option>
        </b-select>&nbsp;
        <button class="button is-success" @click.prevent="isAddDate = true" v-if="$role.state.permissions['write gsis']">
          <span><i class="fa fa-plus"></i> Add Date</span>
        </button>
      </b-field>
    </div>
    <hr>
    <b-field>
      <!-- <button class="button is-success" @click.prevent="addGSIS()" v-if="$role.state.permissions['write gsis']" :disabled="!perDate">
        <span><i class="fa fa-plus"></i> Add GSIS</span>
      </button>&nbsp; -->
      <button class="button is-danger" @click.prevent="deleteTable()" v-if="$role.state.permissions['write gsis']" :disabled="!perDate">
        <span><i class="fa fa-trash"></i> Delete Table</span>
      </button>
    </b-field>
    <b-table
      :data="gsises"
      :striped=true
      :bordered=true
      :hoverable=true
      :paginated="true"
      :per-page="5">
      <template slot-scope="props">
        <b-table-column label="Employee Contribution">
          {{ props.row.employee_contribution }}%
        </b-table-column>
        <b-table-column label="Employer Contribution">
          {{ props.row.employer_contribution }}%
        </b-table-column>
        <b-table-column label="Employer Compensation Commission">
          {{ props.row.employer_compensation_commission }}%
        </b-table-column>
        <b-table-column label="Actions">
          <button class="button btn-action is-primary is-small" @click.prevent="addGSIS(props.row)"><i class="fa fa-pen"></i></button>
          <!-- <button class="button btn-action is-danger is-small" @click.prevent="deleteGSIS(props.row)"><i class="fa fa-trash"></i></button> -->
        </b-table-column>
      </template>
      <template slot="empty">
        <b-table-column label="Employee Contribution">
        </b-table-column>
        <b-table-column label="Employer Contribution">
        </b-table-column>
        <b-table-column label="Employer Compensation Commission">
        </b-table-column>
        <b-table-column label="Actions">
        </b-table-column>
      </template>
    </b-table>

    <b-modal :active.sync="isAddDate" has-modal-card :canCancel=false>
      <transition name="fade">
        <form action="">
          <div class="modal-card" style="height: 33rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">Add Date</p>
            </header>
            <section class="modal-card-body">
              <b-field label="Date">
                <b-datepicker
                  icon="calendar-today"
                  v-model="date"
                  :date-formatter="d => d.toLocaleDateString('en-GB')"
                  expanded required>
                </b-datepicker>
              </b-field><br><br>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" @click.prevent="saveDate()">Save</button>
              <button class="button is-danger" type="button" @click.prevent="closeDate()">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>

    <b-modal :active.sync="isSave" has-modal-card :canCancel=false>
      <transition name="fade">
        <form @submit.prevent="save()">
          <div class="modal-card" style="height: 33rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">{{ state }} GSIS</p>
            </header>
            <section class="modal-card-body">
              <b-field label="Employee Contribution">
                <b-input
                  v-model="gsis.employee_contribution"
                  type="number"
                  step="0.01"
                  min="0.00"
                  max="100.00"
                  expanded required>
                </b-input>
              </b-field>
              <b-field label="Employer Contribution">
                <b-input
                  v-model="gsis.employer_contribution"
                  type="number"
                  step="0.01"
                  min="0.00"
                  max="100.00"
                  expanded required>
                </b-input>
              </b-field>
              <b-field label="Employer Compensation Commission">
                <b-input
                  v-model="gsis.employer_compensation_commission"
                  type="number"
                  step="0.01"
                  min="0.00"
                  max="100.00"
                  expanded required>
                </b-input>
              </b-field>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" type="submit">Save</button>
              <button class="button is-danger" type="button" @click.prevent="close()">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  data() {
    return {
      config: '',
      isLoading: false,
      perDate: null,
      dates: [],
      state: '',
      isSave: false,
      isAddDate: false,
      date: new Date(),
      gsises: [],
      gsis: {}
    }
  },
  mounted() {
    this.config = {
      headers: {'Authorization': "Bearer " + this.$store.state.token}
    };

    this.getDate()
  },
  methods: {
    getDate() {
      this.isLoading = true

      this.$http.get(`${this.$host}/api/parameter/payroll`,
        this.config
      ).then((response) => {
        let data = response.data
        this.dates = data.gsis
        this.perDate = this.dates[this.dates.length - 1]

        this.$http.get(`${this.$host}/api/payroll/gsis/search/${this.perDate}`,
          this.config
        ).then((response) => {
          let data = response.data
          this.gsises = data.array
          this.isLoading = false
        }).catch((error) => {
          this.isLoading = false
          //console.log(error)
        });
      }).catch((error) => {
        this.isLoading = false
        //console.log(error)
      });
    },
    addDate() {

    },
    saveDate() {
      this.isLoading = true

      this.$http.post(`${this.$host}/api/payroll/gsis/save/`,
        {
          effective_date: moment(this.date).format("YYYY-MM-DD"),
          employee_contribution: 0.00,
          employer_contribution: 0.00,
        },
        this.config,
      ).then((response) => {
        let data = response.data.data
        this.$toast.open({
          message: 'Data successfully saved.',
          type: 'is-success',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.isLoading = false
        this.closeDate()
      }).catch((error) => {
        this.$toast.open({
          message: 'Error saving data.',
          type: 'is-danger',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.isLoading = false
      });
    },
    closeDate() {
      this.getDate()
      this.isAddDate = false
      this.date = new Date()
    },
    getGSIS() {
      this.isLoading = true

      this.$http.get(`${this.$host}/api/payroll/gsis/search/${this.perDate}`,
        this.config
      ).then((response) => {
        let data = response.data
        this.gsises = data.array
        this.isLoading = false
      }).catch((error) => {
        this.isLoading = false
        //console.log(error)
      });
    },
    addGSIS(obj) {
      if (obj) {
        this.gsis = obj
        this.state = 'Edit'
      }
      else {
        this.state = 'Add'
      }

      this.isSave = true
    },
    save() {
      this.isLoading = true

      let id = ''
      if (this.gsis.id) {
        id = this.gsis.id
      }
      this.$http.post(`${this.$host}/api/payroll/gsis/save/${id}`,
        {
          effective_date: this.perDate,
          employee_contribution: this.gsis.employee_contribution,
          employer_contribution: this.gsis.employer_contribution
        },
        this.config,
      ).then((response) => {
        let data = response.data.data
        this.$toast.open({
          message: 'Data successfully saved.',
          type: 'is-success',
          position: 'is-bottom-right',
          duration: 4000
        })
        //console.log(data)
        this.isLoading = false
        this.close()
      }).catch((error) => {
        this.$toast.open({
          message: 'Error saving data.',
          type: 'is-danger',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.isLoading = false
      });
    },
    deleteGSIS(obj) {
      this.$dialog.confirm({
        title: 'Delete Table',
        message: "Are you sure you want to delete this row? This action cannot be undone.",
        confirmText: 'Delete',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isLoading = true

          this.$http.delete(`${this.$host}/api/payroll/gsis/delete/${obj.id}`,
            this.config
          ).then((response) => {
            this.$toast.open({
              message: 'Row deleted.',
              type: 'is-success',
              position: 'is-bottom-right',
              duration: 4000
            })
            this.isLoading = false
            this.getDate()
          }).catch((error) => {
            this.$toast.open({
              message: 'Error deleting row.',
              type: 'is-danger',
              position: 'is-bottom-right',
              duration: 4000
            })
            this.isLoading = false
          });
        }
      })
    },
    deleteTable() {
      this.$dialog.confirm({
        title: 'Delete Table',
        message: "Are you sure you want to delete this table? This action cannot be undone.",
        confirmText: 'Delete',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isLoading = true

          this.$http.delete(`${this.$host}/api/payroll/gsis/remove/${this.perDate}`,
            this.config
          ).then((response) => {
            this.$toast.open({
              message: 'GSIS table deleted.',
              type: 'is-success',
              position: 'is-bottom-right',
              duration: 4000
            })
            this.isLoading = false
            this.getGSIS()
            this.getDate()
          }).catch((error) => {
            this.$toast.open({
              message: 'Error deleting table.',
              type: 'is-danger',
              position: 'is-bottom-right',
              duration: 4000
            })
            this.isLoading = false
          });
        }
      })
    },
    close() {
      this.getGSIS()
      this.isSave = false
      this.gsis = {

      }
    }
  }
}
</script>

<style>

</style>
