<template>
  <div>
    <div class="s">
      <section class="hero is-primary" @click.prevent="$emit('search')">
        <div class="hero-body">
          <div class="container">
            <h1 class="title">
              <i class="far fa-calendar-alt"></i> Employees
            </h1>
            <h2 class="subtitle">
              manage an employee's schedule
            </h2>
          </div>
        </div>
      </section>
      <section class="hero is-danger" @click.prevent="$router.push({ name: 'templates' })">
        <div class="hero-body">
          <div class="container">
            <h1 class="title">
              <i class="fa fa-clipboard-list"></i> Templates
            </h1>
            <h2 class="subtitle">
              manage schedule templates
            </h2>
          </div>
        </div>
      </section>
      <section class="hero is-success" @click.prevent="$router.push({ name: 'holidays' })">
        <div class="hero-body">
          <div class="container">
            <h1 class="title">
              <i class="fa fa-calendar-day"></i> Special Dates
            </h1>
            <h2 class="subtitle">
              manage holidays and special dates
            </h2>
          </div>
        </div>
      </section>
    </div>
    <br>
    <!-- <button class="button is-primary" @click.prevent="ownSchedule()">My Schedule</button> -->
  </div>
</template>

<script>
export default {
  mounted() {
    // if (!this.$role.state.permissions['search daily time record']) {
    //   this.setEmployee()
    // }
    this.setEmployee()
  },
  methods: {
    ownSchedule() {
      if (!isNaN(this.$role.state.employee_id) && this.$role.state.employee_id != '') {
        this.setEmployee()
      }
    },
    setEmployee() {
      this.$emit('self', { 
        id: this.$role.state.employee_id,
        last_name: this.$role.state.last_name,
        first_name: this.$role.state.first_name,
        middle_name: this.$role.state.middle_name,
        name_extension: this.$role.state.name_extension 
      })
    }
  }
}
</script>

<style scoped>
.s {
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
}
.hero {
  cursor: pointer;
  transition: opacity 0.5s;
}
.hero:hover {
  opacity: 0.9;
}
</style>
