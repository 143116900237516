<template>
  <div class="box">
    <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
    <div>
      <button class="button is-success" @click.prevent="add()">
        <span><i class="fa fa-plus"></i> Add Equipment</span>
      </button>&nbsp;
      <b-input v-model="search_query" placeholder="Search Equipment" class="is-pulled-right"/>
    </div>
    <hr>
    <b-table
      v-if="isPos"
      :data="filter"
      :striped=true
      :bordered=true
      :hoverable=true
      :checked-rows.sync="selected"
      checkable
      :paginated="true"
      :per-page="5">
      <template slot-scope="props">
        <b-table-column label="Name">
          {{ props.row.name }}
        </b-table-column>
      </template>
      <template slot="empty">
        <b-table-column label="Name">
        </b-table-column>
      </template>
    </b-table>
    <b-table
      v-if="!isPos"
      :data="filter"
      :striped=true
      :bordered=true
      :hoverable=true
      :selected.sync="selected"
      :paginated="true"
      :per-page="5">
      <template slot-scope="props">
        <b-table-column label="Name">
          {{ props.row.name }}
        </b-table-column>
        <b-table-column label="Actions">
          <button class="button btn-action is-primary is-small" @click.prevent="editRow(props.row)"><i class="fa fa-pen"></i></button>
          <button class="button btn-action is-danger is-small" @click.prevent="deleteRow(props.row)" disabled><i class="fa fa-trash"></i></button>
        </b-table-column>
      </template>
      <template slot="empty">
        <b-table-column label="Name">
        </b-table-column>
        <b-table-column label="Actions">
        </b-table-column>
      </template>
    </b-table>
    <button class="button is-primary" @click.prevent="$emit('confirm', selected)" v-if="isPos">
      Confirm
    </button>
    <button class="button is-danger" @click.prevent="$emit('close')" v-if="isPos">
      Cancel
    </button>
    <b-modal :active.sync="isComponentModalActive" has-modal-card :canCancel=false>
      <transition name="fade">
        <addedit-form :obj="data" :state="state" @close="close" @save="save"></addedit-form>
      </transition>
    </b-modal>
  </div>
</template>

<script>
const addeditForm = {
  props: {
    obj: Object,
    state: String
  },
  template: `
  <form action="">
    <div class="modal-card" style="width: 40rem;">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ state }}</p>
      </header>
      <section class="modal-card-body">
        <b-field label="Name">
          <b-input v-model="obj.name"></b-input>
        </b-field>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-success" @click.prevent="$emit('save', obj)">Save</button>
        <button class="button is-danger" type="button" @click.prevent="$emit('close')">Cancel</button>
      </footer>
    </div>
  </form>`
}

export default {
  components: {
    addeditForm
  },
  props: ['equipments', 'isPos', 'current'],
  data() {
    return {
      isComponentModalActive: false,
      data: {},
      state: '',
      selected: this.current,
      search_query: '',
      isLoading: false
    }
  },
  computed: {
    filter() {
      var i;
      var name_re = new RegExp(this.search_query, 'i')
      var data = []
      for (i in this.equipments) {
        if (this.equipments[i].name.match(name_re)) {
          data.push(this.equipments[i])
        }
      }
      return data
    }
  },
  methods: {
    add() {
      this.isComponentModalActive = true
      this.data = {
        id: '',
        name: '',
      }
      this.state = 'ADD'
    },
    close() {
      this.isComponentModalActive = false
      this.$emit('reset')
    },
    editRow(obj) {
      this.isComponentModalActive = true
      this.data = obj
      this.state = 'EDIT'
    },
    save(data) {
      //console.log(data)
      this.$dialog.confirm({
        title: 'Save',
        message: '<b>Save</b> your changes?',
        confirmText: 'Save',
        type: 'is-success',
        hasIcon: true,
        onConfirm: () => this.saveData(data)
      })
    },
    saveData(data) {
      this.isLoading = true
      let config = {
        headers: {'Authorization': "Bearer " + this.$store.state.token}
      };
      this.$http.post(`${this.$host}/api/equipment/save/${data.id}`,
        data,
        config,
      ).then((response) => {
        let data = response.data.data
        this.close()
        this.$toast.open({
          message: 'Data successfully saved!',
          type: 'is-success',
          position: 'is-bottom-right',
          duration: 4000
        })
        //console.log(data)
        this.isLoading = false
      }).catch((error) => {
        this.$toast.open({
          message: 'Error saving data.',
          type: 'is-danger',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.isLoading = false
      });
    },
    deleteRow(obj) {
      // this.$dialog.confirm({
      //   title: `Discard ${obj.code} - ${obj.name}?`,
      //   message: "Are you sure you want to delete this row? This action cannot be undone.",
      //   confirmText: 'Discard',
      //   type: 'is-danger',
      //   hasIcon: true,
      //   onConfirm: () =>
      // })

    },
  }
}
</script>
