<template>
  <div>
    <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
    <div class="box">
      <button v-if="$role.state.permissions['write work schedule template']" class="button is-success" @click.prevent="addTemplate">
        <i class="fa fa-plus"></i> &nbsp;Add Template
      </button>
      <b-input v-model="search_query" placeholder="Search Template" class="is-pulled-right"/>
      <hr>
      <b-table
      :data="filterTemplates"
      :bordered=true
      :hoverable=true
      :paginated="true"
      :per-page="5">
        <template slot-scope="props">
          <b-table-column label="Code">
            {{ props.row.code }}
          </b-table-column>
          <b-table-column label="Description">
            {{ props.row.description }}
          </b-table-column>
          <b-table-column label="Monday">
            {{ is_dayoff(props.row.schedule[0]) }} {{ props.row.schedule[0].time_in }} {{ props.row.schedule[0].lunch_out }} {{ props.row.schedule[0].lunch_in }} {{ props.row.schedule[0].time_out }}
          </b-table-column>
          <b-table-column label="Tuesday">
            {{ is_dayoff(props.row.schedule[1]) }} {{ props.row.schedule[1].time_in }} {{ props.row.schedule[1].lunch_out }} {{ props.row.schedule[1].lunch_in }} {{ props.row.schedule[1].time_out }}
          </b-table-column>
          <b-table-column label="Wednesday">
            {{ is_dayoff(props.row.schedule[2]) }} {{ props.row.schedule[2].time_in }} {{ props.row.schedule[2].lunch_out }} {{ props.row.schedule[2].lunch_in }} {{ props.row.schedule[2].time_out }}
          </b-table-column>
          <b-table-column label="Thursday">
            {{ is_dayoff(props.row.schedule[3]) }} {{ props.row.schedule[3].time_in }} {{ props.row.schedule[3].lunch_out }} {{ props.row.schedule[3].lunch_in }} {{ props.row.schedule[3].time_out }}
          </b-table-column>
          <b-table-column label="Friday">
            {{ is_dayoff(props.row.schedule[4]) }} {{ props.row.schedule[4].time_in }} {{ props.row.schedule[4].lunch_out }} {{ props.row.schedule[4].lunch_in }} {{ props.row.schedule[4].time_out }}
          </b-table-column>
          <b-table-column label="Saturday">
            {{ is_dayoff(props.row.schedule[5]) }} {{ props.row.schedule[5].time_in }} {{ props.row.schedule[5].lunch_out }} {{ props.row.schedule[5].lunch_in }} {{ props.row.schedule[5].time_out }}
          </b-table-column>
          <b-table-column label="Sunday">
            {{ is_dayoff(props.row.schedule[6]) }} {{ props.row.schedule[6].time_in }} {{ props.row.schedule[6].lunch_out }} {{ props.row.schedule[6].lunch_in }} {{ props.row.schedule[6].time_out }}
          </b-table-column>
          <b-table-column label="Edit" v-if="$role.state.permissions['write work schedule template']">
            <button class="button btn-action is-primary is-small" @click.prevent="editRow(props.row)"><i class="fa fa-pen"></i></button>
          </b-table-column>
        </template>
        <template slot="empty">
          <b-table-column label="Code">
          </b-table-column>
          <b-table-column label="Description">
          </b-table-column>
          <b-table-column label="Monday">
          </b-table-column>
          <b-table-column label="Tuesday">
          </b-table-column>
          <b-table-column label="Wednesday">
          </b-table-column>
          <b-table-column label="Thursday">
          </b-table-column>
          <b-table-column label="Friday">
          </b-table-column>
          <b-table-column label="Saturday">
          </b-table-column>
          <b-table-column label="Sunday">
          </b-table-column>
          <b-table-column label="Edit" v-if="$role.state.permissions['write work schedule template']">
          </b-table-column>
        </template>
      </b-table>
    </div>
    <b-modal :active.sync="isComponentModalActive" has-modal-card :canCancel=false>
      <transition name="fade">
        <form action="">
          <div class="modal-card" style="height: 35.625rem; margin-top: 4rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">{{ state }}</p>
            </header>
            <section class="modal-card-body">
              <i>Time Format: Military Time (ex. 08:00:00)</i>
              <b-field label="Code">
                <b-field>
                  <b-input v-model="schedule.code" expanded></b-input>
                </b-field>
              </b-field>
              <b-field label="Description">
                <b-field>
                  <b-input v-model="schedule.description" expanded></b-input>
                </b-field>
              </b-field>
              <b-field>
                <b-checkbox v-model="flexi"
                  @input="checkFlexi"
                  true-value=1
                  false-value=0>
                </b-checkbox>
                <b-field label="Flexi">
                </b-field>
              </b-field>
              <b-field label="Flexi From" v-if="flexi == 1">
                <b-autocomplete
                  v-model="schedule.flexi_from"
                  :data="filteredTime"
                  :open-on-focus=true
                  placeholder="Flexi From"
                  @focus="day = 'flexi_from'"
                  @select="option => selected = option" expanded>
                </b-autocomplete>
              </b-field>
              <b-field label="Flexi To" v-if="flexi == 1">
                <b-autocomplete
                  v-model="schedule.flexi_to"
                  :data="filteredTime"
                  :open-on-focus=true
                  placeholder="Flexi To"
                  @focus="day = 'flexi_to'"
                  @select="option => selected = option" expanded>
                </b-autocomplete>
              </b-field>
              <b-field label="Monday">
                <b-field>
                  <b-autocomplete
                    v-model="schedule.mon_time_in"
                    :data="filteredTime"
                    :open-on-focus=true
                    placeholder="Time In"
                    @focus="day = 'mon_time_in'"
                    @select="option => selected = option" expanded>
                  </b-autocomplete>
                  &nbsp;
                  <b-autocomplete
                    v-model="schedule.mon_lunch_out"
                    :data="filteredTime"
                    :open-on-focus=true
                    placeholder="Lunch Out"
                    @focus="day = 'mon_lunch_out'"
                    @select="option => selected = option" expanded>
                  </b-autocomplete>
                  &nbsp;
                  <b-autocomplete
                    v-model="schedule.mon_lunch_in"
                    :data="filteredTime"
                    :open-on-focus=true
                    placeholder="Lunch In"
                    @focus="day = 'mon_lunch_in'"
                    @select="option => selected = option" expanded>
                  </b-autocomplete>
                  &nbsp;
                  <b-autocomplete
                    v-model="schedule.mon_time_out"
                    :data="filteredTime"
                    :open-on-focus=true
                    placeholder="Time Out"
                    @focus="day = 'mon_time_out'"
                    @select="option => selected = option" expanded>
                  </b-autocomplete>
                </b-field>
              </b-field>
              <b-field label="Tuesday">
                <b-field>
                  <b-autocomplete
                    v-model="schedule.tue_time_in"
                    :data="filteredTime"
                    :open-on-focus=true
                    placeholder="Time In"
                    @focus="day = 'tue_time_in'"
                    @select="option => selected = option" expanded>
                  </b-autocomplete>
                  &nbsp;
                  <b-autocomplete
                    v-model="schedule.tue_lunch_out"
                    :data="filteredTime"
                    :open-on-focus=true
                    placeholder="Lunch Out"
                    @focus="day = 'tue_lunch_out'"
                    @select="option => selected = option" expanded>
                  </b-autocomplete>
                  &nbsp;
                  <b-autocomplete
                    v-model="schedule.tue_lunch_in"
                    :data="filteredTime"
                    :open-on-focus=true
                    placeholder="Lunch In"
                    @focus="day = 'tue_lunch_in'"
                    @select="option => selected = option" expanded>
                  </b-autocomplete>
                  &nbsp;
                  <b-autocomplete
                    v-model="schedule.tue_time_out"
                    :data="filteredTime"
                    :open-on-focus=true
                    placeholder="Time Out"
                    @focus="day = 'tue_time_out'"
                    @select="option => selected = option" expanded>
                  </b-autocomplete>
                </b-field>
              </b-field>
              <b-field label="Wednesday">
                <b-field>
                  <b-autocomplete
                    v-model="schedule.wed_time_in"
                    :data="filteredTime"
                    :open-on-focus=true
                    placeholder="Time In"
                    @focus="day = 'wed_time_in'"
                    @select="option => selected = option" expanded>
                  </b-autocomplete>
                  &nbsp;
                  <b-autocomplete
                    v-model="schedule.wed_lunch_out"
                    :data="filteredTime"
                    :open-on-focus=true
                    placeholder="Lunch Out"
                    @focus="day = 'wed_lunch_out'"
                    @select="option => selected = option" expanded>
                  </b-autocomplete>
                  &nbsp;
                  <b-autocomplete
                    v-model="schedule.wed_lunch_in"
                    :data="filteredTime"
                    :open-on-focus=true
                    placeholder="Lunch In"
                    @focus="day = 'wed_lunch_in'"
                    @select="option => selected = option" expanded>
                  </b-autocomplete>
                  &nbsp;
                  <b-autocomplete
                    v-model="schedule.wed_time_out"
                    :data="filteredTime"
                    :open-on-focus=true
                    placeholder="Time Out"
                    @focus="day = 'wed_time_out'"
                    @select="option => selected = option" expanded>
                  </b-autocomplete>
                </b-field>
              </b-field>
              <b-field label="Thursday">
                <b-field>
                  <b-autocomplete
                    v-model="schedule.thu_time_in"
                    :data="filteredTime"
                    :open-on-focus=true
                    placeholder="Time In"
                    @focus="day = 'thu_time_in'"
                    @select="option => selected = option" expanded>
                  </b-autocomplete>
                  &nbsp;
                  <b-autocomplete
                    v-model="schedule.thu_lunch_out"
                    :data="filteredTime"
                    :open-on-focus=true
                    placeholder="Lunch Out"
                    @focus="day = 'thu_lunch_out'"
                    @select="option => selected = option" expanded>
                  </b-autocomplete>
                  &nbsp;
                  <b-autocomplete
                    v-model="schedule.thu_lunch_in"
                    :data="filteredTime"
                    :open-on-focus=true
                    placeholder="Lunch In"
                    @focus="day = 'thu_lunch_in'"
                    @select="option => selected = option" expanded>
                  </b-autocomplete>
                  &nbsp;
                  <b-autocomplete
                    v-model="schedule.thu_time_out"
                    :data="filteredTime"
                    :open-on-focus=true
                    placeholder="Time Out"
                    @focus="day = 'thu_time_out'"
                    @select="option => selected = option" expanded>
                  </b-autocomplete>
                </b-field>
              </b-field>
              <b-field label="Friday">
                <b-field>
                  <b-autocomplete
                    v-model="schedule.fri_time_in"
                    :data="filteredTime"
                    :open-on-focus=true
                    placeholder="Time In"
                    @focus="day = 'fri_time_in'"
                    @select="option => selected = option" expanded>
                  </b-autocomplete>
                  &nbsp;
                  <b-autocomplete
                    v-model="schedule.fri_lunch_out"
                    :data="filteredTime"
                    :open-on-focus=true
                    placeholder="Lunch Out"
                    @focus="day = 'fri_lunch_out'"
                    @select="option => selected = option" expanded>
                  </b-autocomplete>
                  &nbsp;
                  <b-autocomplete
                    v-model="schedule.fri_lunch_in"
                    :data="filteredTime"
                    :open-on-focus=true
                    placeholder="Lunch In"
                    @focus="day = 'fri_lunch_in'"
                    @select="option => selected = option" expanded>
                  </b-autocomplete>
                  &nbsp;
                  <b-autocomplete
                    v-model="schedule.fri_time_out"
                    :data="filteredTime"
                    :open-on-focus=true
                    placeholder="Time Out"
                    @focus="day = 'fri_time_out'"
                    @select="option => selected = option" expanded>
                  </b-autocomplete>
                </b-field>
              </b-field>
              <b-field label="Saturday">
                <b-field>
                  <b-autocomplete
                    v-model="schedule.sat_time_in"
                    :data="filteredTime"
                    :open-on-focus=true
                    placeholder="Time In"
                    @focus="day = 'sat_time_in'"
                    @select="option => selected = option" expanded>
                  </b-autocomplete>
                  &nbsp;
                  <b-autocomplete
                    v-model="schedule.sat_lunch_out"
                    :data="filteredTime"
                    :open-on-focus=true
                    placeholder="Lunch Out"
                    @focus="day = 'sat_lunch_out'"
                    @select="option => selected = option" expanded>
                  </b-autocomplete>
                  &nbsp;
                  <b-autocomplete
                    v-model="schedule.sat_lunch_in"
                    :data="filteredTime"
                    :open-on-focus=true
                    placeholder="Lunch In"
                    @focus="day = 'sat_lunch_in'"
                    @select="option => selected = option" expanded>
                  </b-autocomplete>
                  &nbsp;
                  <b-autocomplete
                    v-model="schedule.sat_time_out"
                    :data="filteredTime"
                    :open-on-focus=true
                    placeholder="Time Out"
                    @focus="day = 'sat_time_out'"
                    @select="option => selected = option" expanded>
                  </b-autocomplete>
                </b-field>
              </b-field>
              <b-field label="Sunday">
                <b-field>
                  <b-autocomplete
                    v-model="schedule.sun_time_in"
                    :data="filteredTime"
                    :open-on-focus=true
                    placeholder="Time In"
                    @focus="day = 'sun_time_in'"
                    @select="option => selected = option" expanded>
                  </b-autocomplete>
                  &nbsp;
                  <b-autocomplete
                    v-model="schedule.sun_lunch_out"
                    :data="filteredTime"
                    :open-on-focus=true
                    placeholder="Lunch Out"
                    @focus="day = 'sun_lunch_out'"
                    @select="option => selected = option" expanded>
                  </b-autocomplete>
                  &nbsp;
                  <b-autocomplete
                    v-model="schedule.sun_lunch_in"
                    :data="filteredTime"
                    :open-on-focus=true
                    placeholder="Lunch In"
                    @focus="day = 'sun_lunch_in'"
                    @select="option => selected = option" expanded>
                  </b-autocomplete>
                  &nbsp;
                  <b-autocomplete
                    v-model="schedule.sun_time_out"
                    :data="filteredTime"
                    :open-on-focus=true
                    placeholder="Time Out"
                    @focus="day = 'sun_time_out'"
                    @select="option => selected = option" expanded>
                  </b-autocomplete>
                </b-field>
              </b-field>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" @click.prevent="save">{{ state }}</button>
              <button class="button is-danger" @click.prevent="cancel">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      config: {},
      isComponentModalActive: false,
      isLoading: true,
      state: '',
      templates: [],
      template: {
        code: '',
        monday: ''
      },
      time: [
        '00:00:00', '00:30:00', '01:00:00', '01:30:00', '02:00:00', '02:30:00', '03:00:00', '03:30:00', '04:00:00', '04:30:00',
        '05:00:00', '05:30:00', '06:00:00', '06:30:00', '07:00:00', '07:30:00', '08:00:00', '08:30:00', '09:00:00', '09:30:00',
        '10:00:00', '10:30:00', '11:00:00', '11:30:00', '12:00:00', '12:30:00', '13:00:00', '13:30:00', '14:00:00', '14:30:00',
        '15:00:00', '15:30:00', '16:00:00', '16:30:00', '17:00:00', '17:30:00', '18:00:00', '18:30:00', '19:00:00', '19:30:00',
        '20:00:00', '20:30:00',' 21:00:00', '21:30:00', '22:00:00', '22:30:00', '23:00:00', '23:30:00', '24:00:00'
      ],
      schedule: {
        code: '',
        description: '',
        flexi_from: null,
        flexi_to: null,
        mon_time_in: '',
        mon_lunch_out: '',
        mon_lunch_in: '',
        mon_time_out: '',
        tue_time_in: '',
        tue_lunch_out: '',
        tue_lunch_in: '',
        tue_time_out: '',
        wed_time_in: '',
        wed_lunch_out: '',
        wed_lunch_in: '',
        wed_time_out: '',
        thu_time_in: '',
        thu_lunch_out: '',
        thu_lunch_in: '',
        thu_time_out: '',
        fri_time_in: '',
        fri_lunch_out: '',
        fri_lunch_in: '',
        fri_time_out: '',
        sat_time_in: '',
        sat_lunch_out: '',
        sat_lunch_in: '',
        sat_time_out: '',
        sun_time_in: '',
        sun_lunch_out: '',
        sun_lunch_in: '',
        sun_time_out: '',
      },
      flexi: 0,
      day: 'mon_time_in',
      templateId: '',
      search_query: '',
      flexi_global_from: '',
      flexi_global_to: ''
    }
  },
  mounted() {
    this.config = {
      headers: {'Authorization': "Bearer " + this.$store.state.token}
    };
    this.getAllSchedule()
    this.getDefaultFlexi()
  },
  computed: {
    filteredTime() {
      return this.time.filter((option) => {
        let i;
        for (i in this.time) {
          if (this.time[i].toLowerCase().match(this.schedule[this.day])) {
            return option
          }
        }
      })
    },
    filterTemplates() {
      var i;
      var name_re = new RegExp(this.search_query, 'i')
      var data = []
      for (i in this.templates) {
        if (this.templates[i].code.match(name_re)) {
          data.push(this.templates[i])
        }
      }
      return data
    }
  },
  methods: {
    is_dayoff(schedule) {
      if (schedule.time_in == null && schedule.lunch_out == null && schedule.lunch_in == null && schedule.time_out == null) {
        return 'DAY OFF'
      }
    },
    checkFlexi() {
      if (this.flexi == 0) {
        this.schedule.flexi_from = null
        this.schedule.flexi_to = null
      }
      else if (this.flexi == 1 && this.state == 'Save') {
        this.schedule.flexi_from = JSON.parse(JSON.stringify(this.flexi_global_from))
        this.schedule.flexi_to = JSON.parse(JSON.stringify(this.flexi_global_to))
      }
    },
    getDefaultFlexi() {
      this.$http.get(`${this.$host}/api/setting/show`,
        this.config
      ).then((response) => {
        let data = response.data
        this.flexi_global_from = data[2].hash_value
        this.flexi_global_to = data[3].hash_value
        this.isLoading = false
      }).catch((error) => {
        this.isLoading = false
        //console.log(error)
      });
    },
    getAllSchedule() {
      this.isLoading = true
      this.$http.get(`${this.$host}/api/schedule/all/`,
        this.config
      ).then((response) => {
        let data = response.data
        this.templates = data
        //console.log(data)
        this.isLoading = false
      }).catch((error) => {
        //console.log(error)
        this.isLoading = false
      });
    },
    isDayOff(day) {
      if (day.length < 7) {
        return 'DAY OFF'
      }
      else {
        return day
      }
    },
    close() {
      Object.keys(this.schedule).forEach(x => this.schedule[x] = '')
      this.isComponentModalActive = false
      this.getAllSchedule()
    },
    cancel() {
      Object.keys(this.schedule).forEach(x => this.schedule[x] = '')
      this.isComponentModalActive = false
    },
    save() {
      if (this.state == 'Save') {
        // replace this to api post once api is available
        // this.templates.push({
        //   code: this.template.code,
        //   monday: `${this.mon_f} - ${this.mon_lo} - ${this.mon_li} - ${this.mon_t}`,
        //   tuesday: `${this.tue_f} - ${this.tue_lo} - ${this.tue_li} - ${this.tue_t}`,
        //   wednesday:  `${this.wed_f} - ${this.wed_lo} - ${this.wed_li} - ${this.wed_t}`,
        //   thursday: `${this.thu_f} - ${this.thu_lo} - ${this.thu_li} - ${this.thu_t}`,
        //   friday: `${this.fri_f} - ${this.fri_lo} - ${this.fri_li} - ${this.fri_t}`,
        //   saturday: `${this.sat_f} - ${this.sat_lo} - ${this.sat_li} - ${this.sat_t}`,
        //   sunday: `${this.sun_f} - ${this.sun_lo} - ${this.sun_li} - ${this.sun_t}`
        // })
        this.templateId = ''
      }
      this.$http.post(`${this.$host}/api/schedule/save/${this.templateId}`,
        this.schedule,
        this.config,
      ).then((response) => {
        let data = response.data.data
        this.$toast.open({
          message: 'Data successfully saved!',
          type: 'is-success',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.close()
        //console.log(data)
      }).catch((error) => {
        this.$toast.open({
          message: 'Error saving data.',
          type: 'is-danger',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.close()
      });
    },
    addTemplate() {
      this.isComponentModalActive = true
      this.state = 'Save'
      this.flexi = 0
    },
    editRow(obj) {
      if (obj.flexi_from != null && obj.flexi_to != null) {
        this.flexi = 1
      }
      else {
        this.flexi = 0
      }
      this.schedule = {
        code: obj.code,
        description: obj.description,
        flexi_from: obj.flexi_from,
        flexi_to: obj.flexi_to,
        mon_time_in: obj.schedule[0].time_in,
        mon_lunch_out: obj.schedule[0].lunch_out,
        mon_lunch_in: obj.schedule[0].lunch_in,
        mon_time_out: obj.schedule[0].time_out,
        tue_time_in: obj.schedule[1].time_in,
        tue_lunch_out: obj.schedule[1].lunch_out,
        tue_lunch_in: obj.schedule[1].lunch_in,
        tue_time_out: obj.schedule[1].time_out,
        wed_time_in: obj.schedule[2].time_in,
        wed_lunch_out: obj.schedule[2].lunch_out,
        wed_lunch_in: obj.schedule[2].lunch_in,
        wed_time_out: obj.schedule[2].time_out,
        thu_time_in: obj.schedule[3].time_in,
        thu_lunch_out: obj.schedule[3].lunch_out,
        thu_lunch_in: obj.schedule[3].lunch_in,
        thu_time_out: obj.schedule[3].time_out,
        fri_time_in: obj.schedule[4].time_in,
        fri_lunch_out: obj.schedule[4].lunch_out,
        fri_lunch_in: obj.schedule[4].lunch_in,
        fri_time_out: obj.schedule[4].time_out,
        sat_time_in: obj.schedule[5].time_in,
        sat_lunch_out: obj.schedule[5].lunch_out,
        sat_lunch_in: obj.schedule[5].lunch_in,
        sat_time_out: obj.schedule[5].time_out,
        sun_time_in: obj.schedule[6].time_in,
        sun_lunch_out: obj.schedule[6].lunch_out,
        sun_lunch_in: obj.schedule[6].lunch_in,
        sun_time_out: obj.schedule[6].time_out,
      }
      this.templateId = obj.id
      this.isComponentModalActive = true
      this.state = 'Edit'
      //console.log(obj)
      // let days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
      // for (let i = 0; i < days.length; i++) {
      //   this[days[i].substr(0, 3) + '_f'] = obj[days[i]].substr(0, 8)
      // }
      // for (let i = 0; i < days.length; i++) {
      //   this[days[i].substr(0, 3) + '_lo'] = obj[days[i]].substr(11, 8)
      // }
      // for (let i = 0; i < days.length; i++) {
      //   this[days[i].substr(0, 3) + '_li'] = obj[days[i]].substr(22, 8)
      // }
      // for (let i = 0; i < days.length; i++) {
      //   this[days[i].substr(0, 3) + '_t'] = obj[days[i]].substr(33, 8)
      // }
    }
  }
}
</script>

<style>

</style>
