<template>
  <div class="box">
    <div>
      <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
      <button class="button is-primary" @click.prevent="isPrint = true">
        <span><i class="fa fa-print"></i> Print</span>
      </button>
    </div>
    <b-modal :active.sync="isPrint" has-modal-card :canCancel=false>
      <transition name="fade">
        <form action="">
          <div class="modal-card" style="height: 35.625rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">Parameter Field List</p>
            </header>
            <section class="modal-card-body">
              <b-field label="Month">
                <b-select v-model="month" placeholder="Month" expanded required>
                  <option value="01">January</option>
                  <option value="02">February</option>
                  <option value="03">March</option>
                  <option value="04">April</option>
                  <option value="05">May</option>
                  <option value="06">June</option>
                  <option value="07">July</option>
                  <option value="08">August</option>
                  <option value="09">September</option>
                  <option value="10">October</option>
                  <option value="11">November</option>
                  <option value="12">December</option>
                </b-select>
              </b-field>
              <b-field label="Year">
                <b-input v-model="year" type="number" min="1800" max="2100" expanded required>
                </b-input>
              </b-field>
              <b-field label="For">
                <b-autocomplete
                  v-model="employee"
                  :data="filteredEmployee"
                  field="full_name"
                  @select="option => { employee_position = option.position, employee_division = option.division }"
                  :open-on-focus=true expanded>
                </b-autocomplete>
              </b-field>
              <b-field label="For Position">
                <b-input
                  v-model="employee_position"
                  expanded>
                </b-input>
              </b-field>
              <b-field label="For Division">
                <b-input
                  v-model="employee_division"
                  expanded>
                </b-input>
              </b-field>
              <b-field label="Signed">
                <b-autocomplete
                  v-model="supervisor"
                  :data="filteredSupervisor"
                  field="full_name"
                  @select="option => supervisor_division = option.division"
                  :open-on-focus=true expanded>
                </b-autocomplete>
              </b-field>
              <b-field label="Signed Division">
                <b-input
                  v-model="supervisor_division"
                  expanded>
                </b-input>
              </b-field><br><br>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" @click.prevent="printSR()">Print</button>
              <button class="button is-danger" type="button" @click.prevent="isPrint=false">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: true,
      search_query: '',
      data: [],
      checkedRows: [],
      employee: '',
      employee_position: '',
      employee_division: '',
      supervisor: '',
      supervisor_division: '',
      isPrint: false,
      suggest: [],
      employees: [],
      type: '',
      year: null,
      month: null
    }
  },
  mounted() {
    let config = {
      headers: {'Authorization': "Bearer " + this.$store.state.token}
    };

    this.$http.get(`${this.$host}/api/employee/search`,
      config
    ).then((response) => {
      let data = response.data.data
      this.data = data
      this.employees = data

      this.isLoading = false
    }).catch((error) => {
      //console.log(error)
    });

    this.$http.get(`${this.$host}/api/parameter/service_record/employee`,
      config
    ).then((response) => {
      let data = response.data
      this.suggest = data

      let i = 0
      for (i in this.suggest) {
        this.suggest[i].full_name = this.suggest[i].full_name.toUpperCase()
      }
    }).catch((error) => {
      //console.log(error)
    });
    this.year = new Date().getFullYear()
    this.month = (new Date().getMonth() + 1).toString().padStart(2,'0')
  },
  computed: {
    filter() {
      var i;
      var name_re = new RegExp(this.search_query, 'i')
      var data = []
      for (i in this.data) {
        if ([this.data[i].employee_number, this.data[i].first_name, this.data[i].middle_name, this.data[i].last_name, this.data[i].division].join(' ').match(name_re)) {
          data.push(this.data[i])
        }
      }
      return data
    },
    filteredSupervisor() {
      return this.suggest.filter((option) => {
        return option.full_name
          .toString()
          .toLowerCase()
          .indexOf(this.supervisor.toLowerCase()) >= 0
      })
    },
    filteredEmployee() {
      return this.suggest.filter((option) => {
        return option.full_name
          .toString()
          .toLowerCase()
          .indexOf(this.employee.toLowerCase()) >= 0
      })
    }
  },
  methods: {
    clearSelected() {
      if (this.checkedRows.length > 0) {
        this.$dialog.confirm ({
          title: 'Clear Selected Rows',
          message: 'Are you sure you want to <b>clear</b> the selected rows? This action cannot be undone.',
          confirmText: 'Confirm',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: () => this.checkedRows = []
        })
      }
    },
    printSR() {
      this.isLoading = true

      let config = {
        headers: {'Authorization': "Bearer " + this.$store.state.token}
      };

      this.$http.get(`${this.$host}/api/report/generate/${this.$role.state.id}`,
        config
      ).then((response) => {
        this.isLoading = false
        let token = response.data

        let url = `${this.$host}/api/report/salary-report/${token}?employee=${this.employee}&position_1=${this.employee_position}&division_1=${this.employee_division}&certified_by=${this.supervisor}&division_2=${this.supervisor_division}&year=${this.year}&month=${this.month}`
        let win = window.open(url, '_blank')
        win.focus()
        this.isPrint = false
      }).catch((error) => {
        //console.log(error)
        this.isLoading = false
      });
    },
    isNull(val) {
      if (val == 'null') {
        return ''
      }
      else {
        return val
      }
    }
  }
}
</script>
