<template>
  <div>
    <b-field label="Awards"></b-field>
    <b-field grouped group-multiline>
      <button class="button is-success control" @click.prevent="openAward()"
      v-if="$role.state.permissions['write award']">
        <i class="fa fa-plus"></i> &nbsp;Award
      </button>
      <b-select placeholder="Per Page" v-model="perPage">
        <option value="5">5 per page</option>
        <option value="10">10 per page</option>
        <option value="20">20 per page</option>
        <option value="31">31 per page</option>
      </b-select>
      <b-input v-model="search_query" placeholder="Search" />
    </b-field>
    <hr style="margin-top: 0; margin-bottom: 10px;">
    <b-table
      :data="filter"
      :bordered=false
      :hoverable=true
      :paginated="true"
      :per-page="perPage">
      <template slot-scope="props">
        <b-table-column label="Date Awarded" field="date_awarded" sortable>
          {{ props.row.date_awarded }}
        </b-table-column>
        <b-table-column label="Type" field="type" sortable>
          {{ props.row.type }}
        </b-table-column>
        <b-table-column label="Activity" field="activity" sortable>
          {{ props.row.activity }}
        </b-table-column>
        <b-table-column label="Remarks" field="remarks" sortable>
          {{ props.row.remarks }}
        </b-table-column>
        <b-table-column label="Actions">
          <button class="button btn-action is-primary is-small" @click.prevent="openAward(props.row)"><i class="fa fa-pen"></i></button>
          <button class="button btn-action is-danger is-small" @click.prevent="deleteAward(props.row.id)"><i class="fa fa-trash"></i></button>
        </b-table-column>
      </template>
      <template slot="empty">
        <b-table-column label="Date Awarded">
        </b-table-column>
        <b-table-column label="Type">
        </b-table-column>
        <b-table-column label="Activity">
        </b-table-column>
        <b-table-column label="Remarks">
        </b-table-column>
        <b-table-column label="Actions">
        </b-table-column>
      </template>
    </b-table>

    <b-modal :active.sync="isAward" has-modal-card :canCancel=false>
      <transition name="fade">
        <form @submit.prevent="saveAwards()">
          <div class="modal-card" style="height: 35.625rem; margin-top: 4rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">Award</p>
            </header>
            <section class="modal-card-body">
              <b-field label="Date Awarded">
                <b-datepicker
                  placeholder="Click to select..."
                  icon="calendar-today"
                  v-model="award.date_awarded"
                  :date-formatter="d => d.toLocaleDateString('en-GB')"
                  :date-parser="d => parseDate(d)"
                  editable
                  required>
                </b-datepicker>
              </b-field>
              <b-field label="Type">
                <b-autocomplete
                  v-model="award.type"
                  :data="filteredType"
                  :open-on-focus=false expanded required>
                </b-autocomplete>
              </b-field>
              <b-field label="Activity">
                <b-autocomplete
                  v-model="award.activity"
                  :data="filteredActivity"
                  :open-on-focus=false expanded required>
                </b-autocomplete>
              </b-field>
              <b-field label="Remarks">
                <b-input v-model="award.remarks" expanded/>
              </b-field>
              <br>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" type="submit">Save</button>
              <button class="button is-danger" @click.prevent="close()">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  props: ['employee_id', 'awards'],
  data() {
    return {
      config: '',
      isLoading: false,
      perPage: 5,
      search_query: '',
      isAward: false,
      award: {
        employee_id: null,
        date_awarded: new Date(),
        type: '',
        activity: '',
        remarks: null
      },
      award_id: null,
      awardParams: {
        type: [],
        activity: []
      }
    }
  },
  mounted() {
    this.config = {
      headers: {'Authorization': "Bearer " + this.$store.state.token}
    }

    this.getParams()
  },
  computed: {
    filter() {
      var i;
      var name_re = new RegExp(this.search_query, 'i')
      var data = []
      for (i in this.awards) {
        if ([this.awards[i].date_awarded, this.awards[i].type, this.awards[i].activity, this.awards[i].remarks].join(' ').match(name_re)) {
          data.push(this.awards[i])
        }
      }
      return data
    },
    filteredType() {
      return this.awardParams.type.filter((option) => {
        return option
          .toString()
          .toLowerCase()
          .indexOf(this.award.type.toLowerCase()) >= 0
      })
    },
    filteredActivity() {
      return this.awardParams.activity.filter((option) => {
        return option
          .toString()
          .toLowerCase()
          .indexOf(this.award.activity.toLowerCase()) >= 0
      })
    }
  },
  methods: {
    parseDate(d) {
      let parts = d.split("/")
      let dt = new Date(parseInt(parts[2], 10),
        parseInt(parts[1], 10) - 1,
        parseInt(parts[0], 10))
      return dt
    },
    init() {
      this.$emit('update')
    },
    close(init) {
      this.award_id = null
      this.award = {
        employee_id: null,
        date_awarded: new Date(),
        type: '',
        activity: '',
        remarks: null
      }
      this.isAward = false
      this.init()
    },
    openAward(obj) {
      this.isAward = true
      if (obj) {
        this.award_id = obj.id
        this.award = obj
        this.award.date_awarded = new Date(obj.date_awarded)
      }
      this.getParams()
    },
    getParams() {
      this.isLoading = true

      this.$http.get(`${this.$host}/api/parameter/award/`,
        this.config
      ).then((response) => {
        let data = response.data
        this.awardParams.type = data.type.map(a => a.toUpperCase())
        this.awardParams.activity = data.activity.map(a => a.toUpperCase())
        this.isLoading = false
      }).catch((error) => {
        //console.log(error)
        this.isLoading = false
      });
    },
    saveAwards() {
      this.isLoading = true

      let id = ''
      let award = this.award
      award.date_awarded = moment(award.date_awarded).format("YYYY-MM-DD")
      award.employee_id = this.employee_id

      if (this.award_id) {
        id = this.award_id
      }

      this.$http.post(`${this.$host}/api/award/save/${id}`,
        award,
        this.config,
      ).then((response) => {
        this.$toast.open({
          message: 'Data successfully saved.',
          type: 'is-success',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.isLoading = false
        this.close()
      }).catch((error) => {
        this.isLoading = false
        this.$toast.open({
          message: 'Error saving data.',
          type: 'is-danger',
          position: 'is-bottom-right',
          duration: 4000
        })
      });
    },
    deleteAward(id) {
      this.$dialog.confirm({
        title: 'Delete Award',
        message: 'Are you sure you want to <b>delete</b> this award? This action cannot be undone.',
        confirmText: 'Delete',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$http.delete(`${this.$host}/api/award/delete/${id}`,
            this.config
          ).then((response) => {
            this.$toast.open({
              message: 'Award deleted.',
              type: 'is-success',
              position: 'is-bottom-right',
              duration: 4000
            })
            this.init()
            this.isLoading = false
          }).catch((error) => {
            this.$toast.open({
              message: 'Error.',
              type: 'is-danger',
              position: 'is-bottom-right',
              duration: 4000
            })
            this.isLoading = false
          });
        }
      })
    },
  }
}
</script>

<style>

</style>
