<template>
  <div class="box">
    <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
    <div>
      <button class="button is-success" @click.prevent="addDivision()">
        <span><i class="fa fa-plus"></i> Add Division</span>
      </button>&nbsp;
      <b-input v-model="search_query" placeholder="Search Division" class="is-pulled-right"/>
    </div>
    <hr>
    <b-table
      :data="filter"
      :striped=true
      :bordered=true
      :hoverable=true
      :selected.sync="selected"
      :paginated="true"
      :per-page="5">
      <template slot-scope="props">
        <b-table-column label="Code" width="150">
          {{ props.row.code }}
        </b-table-column>
        <b-table-column label="Name" width="150">
          {{ props.row.name }}
        </b-table-column>
        <b-table-column label="Description" width="150">
          {{ props.row.description }}
        </b-table-column>
        <b-table-column label="Office Group" width="150">
          <span v-if="props.row.offices">{{ props.row.offices.name }} ({{ props.row.offices.code }})</span>
        </b-table-column>
        <b-table-column label="Actions" width="150" v-if="!isPos">
          <button class="button btn-action is-primary is-small" @click.prevent="editRow(props.row)"><i class="fa fa-pen"></i></button>
          <!-- <button class="button btn-action is-danger is-small" @click.prevent="deleteRow(props.row)" disabled><i class="fa fa-trash"></i></button> -->
        </b-table-column>
      </template>
      <template slot="empty">
        <b-table-column label="Code">
        </b-table-column>
        <b-table-column label="Name">
        </b-table-column>
        <b-table-column label="Description">
        </b-table-column>
        <b-table-column label="Office Group">
        </b-table-column>
        <b-table-column label="Actions" v-if="!isPos">
        </b-table-column>
      </template>
    </b-table>
    <button class="button is-primary" @click.prevent="$emit('confirm', selected)" v-if="isPos">
      Confirm
    </button>
    <button class="button is-danger" @click.prevent="$emit('close')" v-if="isPos">
      Cancel
    </button>
    <b-modal :active.sync="isComponentModalActive" has-modal-card :canCancel=false>
      <transition name="fade">
        <addedit-form :divisions="divisions" :obj="data" :state="state" @close="close" @save="save"></addedit-form>
      </transition>
    </b-modal>
  </div>
</template>

<script>
import Division2 from './Division2.vue'

const addeditForm = {
  props: {
    obj: Object,
    state: String,
    divisions: Array
  },
  components: {
    Division2
  },
  data() {
    return {
      selectedOffice: '',
      divisionActive: false,
    }
  },
  template: `
  <form action="">
    <div class="modal-card pos" style="width: 40rem; height: 33rem; margin-top: 70px;">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ state }}</p>
      </header>
      <section class="modal-card-body">
        <b-field label="Code">
          <b-input v-model="obj.code"></b-input>
        </b-field>
        <b-field label="Name">
          <b-input v-model="obj.name"></b-input>
        </b-field>
        <b-field label="Description">
          <b-input v-model="obj.description"></b-input>
        </b-field>
        <b-field label="Office Group">
          <b-field>
            <b-input v-model="selectedOffice" disabled expanded>
            </b-input>
            <p class="control">
              <button class="button is-primary" @click.prevent="showDivision"><i class="fa fa-bars"></i></button>
            </p>
          </b-field>
        </b-field>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-success" @click.prevent="$emit('save', obj)">Save</button>
        <button class="button is-danger" type="button" @click.prevent="$emit('close')">Cancel</button>
      </footer>
    </div>
    <b-modal :active.sync="divisionActive" has-modal-card>
      <div class="modal-card" style="width: 60rem; height: 35.625rem; margin-left: 100px;">
        <Division2 style="overflow: auto; max-height: 630px" :divisions="divisions" @reset="setDivision" @close="close" @confirm="selDivision" :isPos="true"/>
      </div>
    </b-modal>
  </form>`,
  methods: {
    setDivision() {
      this.isLoading = true
      let config = {
        headers: {'Authorization': "Bearer " + this.$store.state.token}
      };

      this.$http.get(`${this.$host}/api/division/show`,
        config
      ).then((response) => {
        let data = response.data.data
        this.offices = data
        this.isLoading = false
      }).catch((error) => {
        this.isLoading = false
        //console.log(error)
      });
    },
    showDivision() {
      this.divisionActive = true
    },
    selDivision(obj) {
      // alert(obj.id)
      this.selectedOffice = obj.name
      this.obj.office_group = obj.id
      this.divisionActive = false
    },
    close() {
      this.divisionActive = false
    }
  }
}

export default {
  components: {
    addeditForm
  },
  props: ['divisions', 'isPos'],
  data() {
    return {
      isComponentModalActive: false,
      data: {},
      state: '',
      selected: {},
      search_query: '',
      isLoading: false
    }
  },
  computed: {
    filter() {
      var i;
      var name_re = new RegExp(this.search_query, 'i')
      var data = []
      for (i in this.divisions) {
        if ([this.divisions[i].name, this.divisions[i].code].join(' ').match(name_re)) {
          data.push(this.divisions[i])
        }
      }
      return data
    }
  },
  methods: {
    addDivision() {
      this.isComponentModalActive = true
      this.data = {
        id: '',
        code: '',
        name: '',
        description: '',
        office_group: ''
      }
      this.state = 'ADD'
    },
    close() {
      this.isComponentModalActive = false
      this.$emit('reset')
    },
    editRow(obj) {
      this.isComponentModalActive = true
      this.data = obj
      this.state = 'EDIT'
    },
    save(data) {
      //console.log(data)
      this.$dialog.confirm({
        title: 'Save',
        message: '<b>Save</b> your changes?',
        confirmText: 'Save',
        type: 'is-success',
        hasIcon: true,
        onConfirm: () => this.saveData(data)
      })
    },
    saveData(data) {
      this.isLoading = true
      let config = {
        headers: {'Authorization': "Bearer " + this.$store.state.token}
      };
      this.$http.post(`${this.$host}/api/division/save/${data.id}`,
        data,
        config,
      ).then((response) => {
        let data = response.data.data
        this.close()
        this.$toast.open({
          message: 'Data successfully saved.',
          type: 'is-success',
          position: 'is-bottom-right',
          duration: 4000
        })
        //console.log(data)
        this.isLoading = false
      }).catch((error) => {
        this.$toast.open({
          message: 'Error saving data.',
          type: 'is-danger',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.isLoading = false
      });
    },
    deleteRow(obj) {
      // this.$dialog.confirm({
      //   title: `Discard ${obj.code} - ${obj.name}?`,
      //   message: "Are you sure you want to delete this row? This action cannot be undone.",
      //   confirmText: 'Discard',
      //   type: 'is-danger',
      //   hasIcon: true,
      //   onConfirm: () =>
      // })

    },
  }
}
</script>

<style scoped>
@media screen and (min-width: 1px) and (max-width: 1215px)  {
  .modal-card {
    margin-left: 0px !important;
    width: 100% !important;
  }
  .pos {
    width: none;
  }
}
.pos {
  margin-top: 70px !important;
}
.modal-card-head {
  height: 50px;
}

</style>
