<template>
  <div class="container column is-9"> 
    <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
    
    <button type="submit" class="button is-success" @click.prevent="save()" v-if="!isDisabled">
      Save
    </button>
    <button class="button is-success" @click.prevent="edit()" v-if="isDisabled">
      Edit Information
    </button>
    <button class="button is-danger" @click.prevent="cancelPrompt()" :disabled="isDisabled">
      Cancel
    </button>
    <br><br>
    <!-- Personal Information --> 
    <b-collapse class="card" :open.sync="isOpen1">
      <div slot="trigger" slot-scope="props" class="card-header" role="button" @click.prevent="activeTab(1)">
        <p class="card-header-title has-background-primary has-text-white">
          Personal Information
        </p>
        <a class="card-header-icon has-background-primary has-text-white">
          <b-icon
              :icon="props.open ? 'menu-down' : 'menu-up'">
          </b-icon>
        </a>
      </div>
      <div class="card-content">
        <div class="content columns" :style="{ 'text-transform': capitalize }">
          <div class="column is-12">
            <div class="columns">
              <div class="column is-12">
                <b>Agency Employee ID:</b> <input v-model="d.employee_number" :disabled="isDisabled"> <br>
                <b>Last Name:</b> <input v-model="d.last_name" :disabled="isDisabled"> <br>
                <b>First Name:</b> <input v-model="d.first_name" :disabled="isDisabled"> <b>Name Extension:</b> 
                <select v-model="d.name_extension" :disabled="isDisabled">
                  <option value=""></option>
                  <option value="JR">JR</option>
                  <option value="SR">SR</option>
                  <option value="I">I</option>
                  <option value="II">II</option>
                  <option value="III">III</option>
                </select>  
                <br>
                <b>Middle Name:</b> <input v-model="d.middle_name" :disabled="isDisabled">
              </div>
            </div>
            <div class="columns">
              <div class="column is-5">
                <b>Date of Birth:</b> <input v-model="d.birth_date" :disabled="isDisabled"><br>
                <b>Place of Birth:</b> <input v-model="d.birth_place" :disabled="isDisabled"><br>
                <b>Sex:</b> 
                <select v-model="d.gender" :disabled="isDisabled">
                  <option value="male">Male</option>
                  <option value="female">Female</option>  
                </select>
                <br>
                <b>Civil Status:</b> 
                <select v-model="d.civil_status" :disabled="isDisabled">
                  <option value="Single">SINGLE</option>
                  <option value="Married">MARRIED</option>
                  <option value="Widowed">WIDOWED</option>
                  <option value="Separated">SEPARATED</option>
                  <option value="Others">OTHERS</option>
                </select>
                <br>
                <b>Height:</b> (m)<input v-model="d.height" :disabled="isDisabled"><br>
                <b>Weight:</b> (kg)<input v-model="d.weight" :disabled="isDisabled"><br>
                <b>Blood Type:</b> 
                <select v-model="d.blood_type" :disabled="isDisabled">
                  <option value="">N/A</option>
                  <option value="A+">A+</option>
                  <option value="A-">A-</option>
                  <option value="B+">B+</option>
                  <option value="B-">B-</option>
                  <option value="AB+">AB+</option>
                  <option value="AB-">AB-</option>
                  <option value="O+">O+</option>
                  <option value="O-">O-</option>
                </select>
                <br>
                <b>GSIS ID Number:</b> <input v-model="d.gsis" :disabled="isDisabled"><br>
                <b>PAG-IBIG ID Number:</b> <input v-model="d.pagibig" :disabled="isDisabled"><br>
                <b>PHILHEALTH Number:</b> <input v-model="d.philhealth" :disabled="isDisabled"><br>
                <b>SSS Number:</b> <input v-model="d.sss" :disabled="isDisabled"><br>
                <b>TIN Number:</b> <input v-model="d.tin" :disabled="isDisabled"><br>
              </div>
              <div class="column is-7">
                <b>Citizenship:</b> 
                <select v-model="d.citizenship" :disabled="isDisabled">
                  <option value="Filipino">Filipino</option>
                  <option value="Dual Citizen">Dual Citizenship</option>
                </select>
                by 
                <select v-model="d.citizenship_by" :disabled="isDisabled">
                  <option value="Birth">Birth</option>
                  <option value="Naturalization">Naturalization</option>
                </select>
                <br>
                <b>Dual Citizenship:</b>  <input v-model="d.citizenship_by_country" :disabled="isDisabled"><br>
                <b>Residential Address:</b> <input v-model="d.residential_housenum" placeholder="House No." :disabled="isDisabled"> <input v-model="d.residential_street" placeholder="Street" :disabled="isDisabled"> 
                <input v-model="d.residential_subdivision" placeholder="Subdivision" :disabled="isDisabled"> <input v-model="d.residential_barangay" placeholder="Barangay" :disabled="isDisabled"> <input v-model="d.residential_city" placeholder="City" :disabled="isDisabled"> <input v-model="d.residential_zipcode" placeholder="Zip Code" :disabled="isDisabled"><br>
                <b>Permanent Address:</b> <input v-model="d.permanent_housenum" placeholder="House No." :disabled="isDisabled"> <input v-model="d.permanent_street" placeholder="Street" :disabled="isDisabled"> 
                <input v-model="d.permanent_subdivision" placeholder="Subdivision" :disabled="isDisabled"> <input v-model="d.permanent_barangay" placeholder="Barangay" :disabled="isDisabled"> <input v-model="d.permanent_city" placeholder="City" :disabled="isDisabled"> <input v-model="d.permanent_zipcode" placeholder="Zip Code" :disabled="isDisabled"><br>
                <b>Telephone Number:</b> <input v-model="d.telephone" :disabled="isDisabled"><br>
                <b>Mobile Number:</b> <input v-model="d.mobile" :disabled="isDisabled"><br>
                <b>E-mail Address:</b> <input v-model="d.email" :disabled="isDisabled"><br>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-collapse>
    <br>
    <!-- Family Background -->
    <b-collapse class="card" :open.sync="isOpen2">
      <div slot="trigger" slot-scope="props" class="card-header" role="button" @click.prevent="activeTab(2)">
        <p class="card-header-title has-background-primary has-text-white">
          Family Background
        </p>
        <a class="card-header-icon has-background-primary has-text-white">
          <b-icon
              :icon="props.open ? 'menu-down' : 'menu-up'">
          </b-icon>
        </a>
      </div>
      <div class="card-content">
        <div class="content columns" :style="{ 'text-transform': capitalize }">
          <div class="column is-12">
            <div class="columns">
              <div class="column is-6">
                <b>Spouse</b> <br><input v-model="d.birth_date" :disabled="isDisabled">
                <b>Last Name:</b> <input v-model="d.spouse_last_name" :disabled="isDisabled"><br>
                <b>First Name:</b> <input v-model="d.spouse_first_name" :disabled="isDisabled"> <b>Name Extension:</b> <input v-model="d.spouse_name_extension" :disabled="isDisabled"> <br>
                <b>Middle Name:</b> <input v-model="d.spouse_middle_name" :disabled="isDisabled"> <br><br>
                <b>Occupation:</b> <input v-model="d.spouse_occupation" :disabled="isDisabled"><br>
                <b>Employer/Business Name:</b> <input v-model="d.spouse_employer_business" :disabled="isDisabled"> <br>
                <b>Business Address:</b> <input v-model="d.spouse_business_address" :disabled="isDisabled"> <br>
                <b>Telephone Number:</b> <input v-model="d.telephone" :disabled="isDisabled"> <br>
                <br>
                <b>Children:</b> <br>
                <a class="has-text-success a-actions" v-if="!isDisabled" @click.prevent="d.children.push({ id: null, children_name: '', birth_date: ''})"><i class="fas fa-plus"></i> Add</a> <br>
                <span v-for="child in d.children" :value="child.children_name" :key="child.id"> <input v-model="child.children_name" :disabled="isDisabled" style="width: 40%;"> <input v-model="child.birth_date" :disabled="isDisabled" style="width: 40%;"> 
                <a class="has-text-danger a-actions" v-if="!isDisabled" @click.prevent="deleteChild(child)"><i class="fas fa-trash"></i></a><br> </span>
              </div>
              <div class="column is-6">
                <b>Father</b> <br>
                <b>Last Name:</b> <input v-model="d.father_last_name" :disabled="isDisabled"><br>
                <b>First Name:</b> <input v-model="d.father_first_name" :disabled="isDisabled"> <b>Name Extension:</b> <input v-model="d.father_name_extension" :disabled="isDisabled"> <br>
                <b>Middle Name:</b> <input v-model="d.father_middle_name" :disabled="isDisabled"> <br>
                <br>
                <b>Mother</b> <br>
                <b>Last Name:</b> <input v-model="d.mother_last_name" :disabled="isDisabled"><br>
                <b>First Name:</b> <input v-model="d.mother_first_name" :disabled="isDisabled"> <b>Name Extension:</b> <input v-model="d.mother_name_extension" :disabled="isDisabled"> <br>
                <b>Middle Name:</b> <input v-model="d.mother_middle_name" :disabled="isDisabled"> <br>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-collapse>
    <br>
    <!-- Educational Background -->
    <b-collapse class="card" :open.sync="isOpen3">
      <div slot="trigger" slot-scope="props" class="card-header" role="button" @click.prevent="activeTab(3)">
        <p class="card-header-title has-background-primary has-text-white">
          Educational Background
        </p>
        <a class="card-header-icon has-background-primary has-text-white">
          <b-icon
              :icon="props.open ? 'menu-down' : 'menu-up'">
          </b-icon>
        </a>
      </div>
      <div class="card-content">
        <div class="content columns" style="overflow-x: scroll" :style="{ 'text-transform': capitalize }">
          <div class="column is-12">
            <a class="has-text-success a-actions" v-if="!isDisabled" @click.prevent="d.education.push({ 
              id: null, 
              level: '', 
              school_name: '',
              course: '',
              attended_from: '',
              attended_to: '',
              graduated: '',
              highest_level: '',
              year_graduated: '',
              honors: ''
            })"><i class="fas fa-plus"></i> Add</a> <br>
            <b-table :data="d.education" :narrowed="true">
              <template slot-scope="props">
                <b-table-column field="level" label="Level">
                  <select v-model="props.row.level" :disabled="isDisabled">
                    <option value="elementary">
                      Elementary
                    </option>
                    <option value="secondary">
                      Secondary
                    </option>
                    <option value="vocational">
                      Vocational
                    </option>
                    <option value="college">
                      College
                    </option>
                    <option value="graduate studies">
                      Graduate Studies
                    </option>
                  </select>
                </b-table-column>
                <b-table-column field="school_name" label="School Name">
                  <input v-model="props.row.school_name" :disabled="isDisabled">
                </b-table-column>
                <b-table-column field="course" label="Course">
                  <input v-model="props.row.course" :disabled="isDisabled">
                </b-table-column>
                <b-table-column field="attended_from" label="Attended">
                  <input v-model="props.row.attended_from" :disabled="isDisabled" style="width: 40%;"> - <input v-model="props.row.attended_to" :disabled="isDisabled" style="width: 40%;">
                </b-table-column>
                <b-table-column field="graduated" label="Graduated">
                  <select v-model="props.row.graduated" :disabled="isDisabled">
                    <option value=1>Yes</option>
                    <option value=0>No</option>
                  </select>
                </b-table-column>
                <b-table-column field="highest_level" label="Highest Level">
                  <input v-model="props.row.highest_level" :disabled="isDisabled">
                </b-table-column>
                <b-table-column field="year_graduated" label="Year Graduated">
                  <input v-model="props.row.year_graduated" :disabled="isDisabled">
                </b-table-column>
                <b-table-column field="honors" label="Honors">
                  <input v-model="props.row.honors" :disabled="isDisabled">
                </b-table-column>
                <b-table-column label="Delete" v-if="!isDisabled">
                  <a class="has-text-danger a-actions" @click.prevent="deleteRow('education', props.row)"><i class="fas fa-trash"></i></a>
                </b-table-column>
              </template>
              <template slot="empty">
                <b-table-column field="level" label="Level">
                </b-table-column>
                <b-table-column field="school_name" label="School Name">
                </b-table-column>
                <b-table-column field="course" label="Course">
                </b-table-column>
                <b-table-column field="attended_from" label="Attended">
                </b-table-column>
                <b-table-column field="graduated" label="Graduated">
                </b-table-column>
                <b-table-column field="highest_level" label="Highest Level">
                </b-table-column>
                <b-table-column field="year_graduated" label="Year Graduated">
                </b-table-column>
                <b-table-column field="honors" label="Honors">
                </b-table-column>
                <b-table-column label="Delete" v-if="!isDisabled">
                </b-table-column>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </b-collapse>
    <br>
    <!-- Civil Service Eligibility -->
    <b-collapse class="card" :open.sync="isOpen4">
      <div slot="trigger" slot-scope="props" class="card-header" role="button" @click.prevent="activeTab(4)">
        <p class="card-header-title has-background-primary has-text-white">
          Civil Service Eligibility
        </p>
        <a class="card-header-icon has-background-primary has-text-white">
          <b-icon
              :icon="props.open ? 'menu-down' : 'menu-up'">
          </b-icon>
        </a>
      </div>
      <div class="card-content">
        <div class="content columns" style="overflow-x: scroll;" :style="{ 'text-transform': capitalize }">
          <div class="column is-12">
            <a class="has-text-success a-actions" v-if="!isDisabled" @click.prevent="d.eligibilities.push({ 
              id: null,
              type: '',
              rating: '',
              date_conferment: '',
              place_conferment: ''
            })"><i class="fas fa-plus"></i> Add</a> <br>
            <b-table :data="d.eligibilities" :narrowed="true">
              <template slot-scope="props">
                <b-table-column label="Type">
                  <input v-model="props.row.type" :disabled="isDisabled">
                </b-table-column>
                <b-table-column label="Rating">
                  <input v-model="props.row.rating" :disabled="isDisabled">
                </b-table-column>
                <b-table-column label="Date Conferment">
                  <input v-model="props.row.date_conferment" :disabled="isDisabled">
                </b-table-column>
                <b-table-column label="Place Conferment">
                  <input v-model="props.row.place_conferment" :disabled="isDisabled">
                </b-table-column>
                <b-table-column label="Delete" v-if="!isDisabled">
                  <a class="has-text-danger a-actions" @click.prevent="deleteRow('eligibilities', props.row)"><i class="fas fa-trash"></i></a>
                </b-table-column>
              </template>
              <template slot="empty">
                <b-table-column label="Type">
                </b-table-column>
                <b-table-column label="Rating">
                </b-table-column>
                <b-table-column label="Date Conferment">
                </b-table-column>
                <b-table-column label="Place Conferment">
                </b-table-column>
                <b-table-column label="Delete" v-if="!isDisabled">
                </b-table-column>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </b-collapse>
    <br>
    <!-- Work Experience -->
    <b-collapse class="card" :open.sync="isOpen5">
      <div slot="trigger" slot-scope="props" class="card-header" role="button" @click.prevent="activeTab(5)">
        <p class="card-header-title has-background-primary has-text-white">
          Work Experience
        </p>
        <a class="card-header-icon has-background-primary has-text-white">
          <b-icon
              :icon="props.open ? 'menu-down' : 'menu-up'">
          </b-icon>
        </a>
      </div>
      <div class="card-content">
        <div class="content columns" style="overflow-x: scroll;" :style="{ 'text-transform': capitalize }">
          <div class="column is-12">
            <a class="has-text-success a-actions" v-if="!isDisabled" @click.prevent="d.workexperiences.push({ 
              id: null,
              position: '',
              date_from: '',
              company: '',
              salary: '',
              pay_grade: '',
              status_of_appointment: ''
            })"><i class="fas fa-plus"></i> Add</a> <br>
            <b-table :data="d.workexperiences" :narrowed="true">
              <template slot-scope="props">
                <b-table-column label="Position">
                  <input v-model="props.row.position" :disabled="isDisabled">
                </b-table-column>
                <b-table-column label="Date">
                  <input v-model="props.row.date_from" :disabled="isDisabled"> - <input v-model="props.row.date_to" :disabled="isDisabled">
                </b-table-column>
                <b-table-column label="Company">
                  <input v-model="props.row.company" :disabled="isDisabled">
                </b-table-column>
                <b-table-column label="Government">
                  <select v-model="props.row.is_government_service" :disabled="isDisabled">
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                  </select>
                </b-table-column>
                <b-table-column label="Salary">
                  <input v-model="props.row.salary" :disabled="isDisabled">
                </b-table-column>
                <b-table-column label="Pay Grade">
                  <input v-model="props.row.pay_grade" :disabled="isDisabled">
                </b-table-column>
                <b-table-column label="Status of Appointnment">
                  <input v-model="props.row.status_of_appointment" :disabled="isDisabled">
                </b-table-column>
                <b-table-column label="Delete" v-if="!isDisabled">
                  <a class="has-text-danger a-actions" @click.prevent="deleteRow('workexperiences', props.row)"><i class="fas fa-trash"></i></a>
                </b-table-column>
              </template>
              <template slot="empty">
                <b-table-column label="Position">
                </b-table-column>
                <b-table-column label="Date">
                </b-table-column>
                <b-table-column label="Company">
                </b-table-column>
                <b-table-column label="Government">
                </b-table-column>
                <b-table-column label="Salary">
                </b-table-column>
                <b-table-column label="Pay Grade">
                </b-table-column>
                <b-table-column label="Status of Appointnment">
                </b-table-column>
                <b-table-column label="Delete" v-if="!isDisabled">
                </b-table-column>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </b-collapse>
    <br>
    <!-- Voluntary Work -->
    <b-collapse class="card" :open.sync="isOpen6">
      <div slot="trigger" slot-scope="props" class="card-header" role="button" @click.prevent="activeTab(6)">
        <p class="card-header-title has-background-primary has-text-white">
          Voluntary Work or Involvement in Civic/Non-Governemnt/People/Voluntary Organizations
        </p>
        <a class="card-header-icon has-background-primary has-text-white">
          <b-icon
              :icon="props.open ? 'menu-down' : 'menu-up'">
          </b-icon>
        </a>
      </div>
      <div class="card-content">
        <div class="content columns" style="overflow-x: scroll" :style="{ 'text-transform': capitalize }">
          <div class="column is-12">
            <a class="has-text-success a-actions" v-if="!isDisabled" @click.prevent="d.volunteerings.push({ 
              id: null,
              date_from: '',
              number_of_hours: '',
              position: '',
              nature_of_work: ''
            })"><i class="fas fa-plus"></i> Add</a> <br>
            <b-table :data="d.volunteerings" :narrowed="true">
              <template slot-scope="props">
                <b-table-column label="Organization">
                  <input v-model="props.row.organization" :disabled="isDisabled">
                </b-table-column>
                <b-table-column label="Date">
                  <input v-model="props.row.date_from" :disabled="isDisabled"> - <input v-model="props.row.date_to" :disabled="isDisabled">
                </b-table-column>
                <b-table-column label="Number of Hours">
                  <input v-model="props.row.number_of_hours" :disabled="isDisabled">
                </b-table-column>
                <b-table-column label="Position">
                  <input v-model="props.row.position" :disabled="isDisabled">
                </b-table-column>
                <b-table-column label="Nature of Work">
                  <input v-model="props.row.nature_of_work" :disabled="isDisabled">
                </b-table-column>
                <b-table-column label="Delete" v-if="!isDisabled">
                  <a class="has-text-danger a-actions" @click.prevent="deleteRow('volunteerings', props.row)"><i class="fas fa-trash"></i></a>
                </b-table-column>
              </template>
              <template slot="empty">
                <b-table-column label="Organization">
                </b-table-column>
                <b-table-column label="Date">
                </b-table-column>
                <b-table-column label="Number of Hours">
                </b-table-column>
                <b-table-column label="Position">
                </b-table-column>
                <b-table-column label="Nature of Work">
                </b-table-column>
                <b-table-column label="Delete" v-if="!isDisabled">
                </b-table-column>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </b-collapse>
    <br>
    <!-- Learning and Development Interventions/Training Programs Attended -->
    <b-collapse class="card" :open.sync="isOpen7">
      <div slot="trigger" slot-scope="props" class="card-header" role="button" @click.prevent="activeTab(7)">
        <p class="card-header-title has-background-primary has-text-white">
          Learning and Development Interventions/Training Programs Attended
        </p>
        <a class="card-header-icon has-background-primary has-text-white">
          <b-icon
              :icon="props.open ? 'menu-down' : 'menu-up'">
          </b-icon>
        </a>
      </div>
      <div class="card-content">
        <div class="content columns" style="overflow-x: scroll" :style="{ 'text-transform': capitalize }">
          <div class="column is-12">
            <a class="has-text-success a-actions" v-if="!isDisabled" @click.prevent="d.trainings.push({ 
              id: null,
              program: '',
              date_from: '',
              number_of_hours: ''
            })"><i class="fas fa-plus"></i> Add</a> <br>
            <b-table :data="d.trainings" :narrowed="true">
              <template slot-scope="props">
                <b-table-column label="Program">
                  <input v-model="props.row.program" :disabled="isDisabled">
                </b-table-column>
                <b-table-column label="Date">
                  <input v-model="props.row.date_from" :disabled="isDisabled"> - <input v-model="props.row.date_to" :disabled="isDisabled">
                </b-table-column>
                <b-table-column label="Number of Hours">
                  <input v-model="props.row.number_of_hours" :disabled="isDisabled">
                </b-table-column>
                <b-table-column label="Delete" v-if="!isDisabled">
                  <a class="has-text-danger a-actions" @click.prevent="deleteRow('trainings', props.row)"><i class="fas fa-trash"></i></a>
                </b-table-column>
              </template>
              <template slot="empty">
                <b-table-column label="Program">
                </b-table-column>
                <b-table-column label="Date">
                </b-table-column>
                <b-table-column label="Number of Hours">
                </b-table-column>
                <b-table-column label="Delete" v-if="!isDisabled">
                </b-table-column>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </b-collapse>
    <br>
    <!-- Other Information -->
    <b-collapse class="card" :open.sync="isOpen8">
      <div slot="trigger" slot-scope="props" class="card-header" role="button" @click.prevent="activeTab(8)">
        <p class="card-header-title has-background-primary has-text-white">
          Other Information
        </p>
        <a class="card-header-icon has-background-primary has-text-white">
          <b-icon
              :icon="props.open ? 'menu-down' : 'menu-up'">
          </b-icon>
        </a>
      </div>
      <div class="card-content">
        <div class="content columns" style="overflow-x: scroll" :style="{ 'text-transform': capitalize }">
          <div class="column is-12">
            <div class="columns">
              <div class="column is-4">
                <b-table
                  :data="d.skills"
                  focusable>
                  <template slot-scope="props">
                    <b-table-column label="Skills">
                      {{ props.row.skill }}
                    </b-table-column>
                    <b-table-column>
                      <button class="button btn-action is-danger is-small" @click.prevent="deleteOther(props.row.id, 'skills')" :disabled="isDisabled"><i class="fa fa-trash"></i></button>
                    </b-table-column>
                  </template>
                  <template slot="empty">
                    <b-table-column label="Skills">
                    </b-table-column>
                    <b-table-column>
                    </b-table-column>
                  </template>
                </b-table>
                <b-field>
                  <b-autocomplete
                    size="is-small"
                    v-model="m_skill"
                    :open-on-focus="true"
                    :data="f_skill"
                    field="skill"
                    @select="option => selected = option"
                    :disabled="isDisabled"
                    expanded>
                  </b-autocomplete>
                  <p class="control">
                    <button class="button btn-action is-success is-small" @click.prevent="addMany('skill')" :disabled="isDisabled">Add</button>
                  </p>
                </b-field>
              </div>
              <div class="column is-4">
                <b-table
                  :data="d.recognitions"
                  focusable>
                  <template slot-scope="props">
                    <b-table-column label="Recognitions">
                      {{ props.row.recognition }}
                    </b-table-column>
                    <b-table-column>
                      <button class="button btn-action is-danger is-small" @click.prevent="deleteOther(props.row.id, 'recognitions')" :disabled="isDisabled"><i class="fa fa-trash"></i></button>
                    </b-table-column>
                  </template>
                  <template slot="empty">
                    <b-table-column label="Recognitions">
                    </b-table-column>
                    <b-table-column>
                    </b-table-column>
                  </template>
                </b-table>
                <b-field>
                  <b-autocomplete
                    size="is-small"
                    v-model="m_recognition"
                    :open-on-focus="true"
                    :data="f_recognition"
                    field="recognition"
                    @select="option => selected = option"
                    :disabled="isDisabled"
                    expanded>
                  </b-autocomplete>
                  <button class="button btn-action is-success is-small" @click.prevent="addMany('recognition')" :disabled="isDisabled">Add</button>
                </b-field>
              </div>
              <div class="column is-4">
                <b-table
                  :data="d.memberships"
                  focusable>
                  <template slot-scope="props">
                    <b-table-column label="Memberships">
                      {{ props.row.membership }}
                    </b-table-column>
                    <b-table-column>
                      <button class="button btn-action is-danger is-small" @click.prevent="deleteOther(props.row.id, 'memberships')" :disabled="isDisabled"><i class="fa fa-trash"></i></button>
                    </b-table-column>
                  </template>
                  <template slot="empty">
                    <b-table-column label="Memberships">
                    </b-table-column>
                    <b-table-column>
                    </b-table-column>
                  </template>
                </b-table>
                <b-field>
                  <b-autocomplete
                    size="is-small"
                    v-model="m_membership"
                    :open-on-focus="true"
                    :data="f_membership"
                    field="membership"
                    @select="option => selected = option"
                    :disabled="isDisabled"
                    expanded>
                  </b-autocomplete>
                  <button class="is-pulled-right button btn-action is-success is-small" @click.prevent="addMany('membership')" :disabled="isDisabled">Add</button>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="columns" v-for="other in d.otherinfos" v-bind:key="other.id">
                <div class="column is-12">
                <!-- <div class="column is-12" v-for="" v-bind:key=""> -->
                  <b>Special Skills:</b> {{ other.special_skill }}<br>
                  <b>Hobbies:</b> {{ other.hobbies }}
                  <b>Non-Academic Distinctions/Recognition:</b> {{ other.other_distinctions }}<br>
                  <b>Membership in Association/Organization:</b> {{ other.organization_membership }}<br>
                </div>
              </div>
              <div class="column is-12">
              <!-- <div class="column is-12" v-for="" v-bind:key=""> -->
                <b>Affiliated Third:</b> 
                <select v-model="d.is_affiliated_third" :disabled="isDisabled">
                  <option value=1>Yes</option>
                  <option value=0>No</option>
                </select> - <input v-model="d.affiliated_third" :disabled="isDisabled"> <br>
                <b>Affiliated Fourth:</b> 
                <select v-model="d.is_affiliated_fourth" :disabled="isDisabled">
                  <option value=1>Yes</option>
                  <option value=0>No</option>
                </select> - <input v-model="d.affiliated_fourth" :disabled="isDisabled"> <br>
                <b>Found Guilty:</b> 
                <select v-model="d.is_found_guilty" :disabled="isDisabled">
                  <option value=1>Yes</option>
                  <option value=0>No</option>
                </select> - <input v-model="d.found_guilty" :disabled="isDisabled"> <br>
                <b>Criminally Charged:</b> 
                <select v-model="d.is_criminally_charged" :disabled="isDisabled">
                  <option value=1>Yes</option>
                  <option value=0>No</option>
                </select><br>
                <b>Criminally Charged Date:</b> <input v-model="d.criminally_charged_date" :disabled="isDisabled"> <br>
                <b>Criminally Charged Status:</b> <input v-model="d.criminally_charged_status" :disabled="isDisabled"> <br>
                <b>Convicted:</b> 
                <select v-model="d.is_convicted" :disabled="isDisabled">
                  <option value=1>Yes</option>
                  <option value=0>No</option>
                </select> - <input v-model="d.convicted" :disabled="isDisabled"> <br>
                <b>Separated Service:</b> 
                <select v-model="d.is_separated_service" :disabled="isDisabled">
                  <option value=1>Yes</option>
                  <option value=0>No</option>
                </select> - <input v-model="d.separated_service" :disabled="isDisabled"> <br>
                <b>Candidate:</b> 
                <select v-model="d.is_candidate" :disabled="isDisabled">
                  <option value=1>Yes</option>
                  <option value=0>No</option>
                </select> - <input v-model="d.candidate" :disabled="isDisabled"> <br>
                <b>Resigned:</b> 
                <select v-model="d.is_resigned" :disabled="isDisabled">
                  <option value=1>Yes</option>
                  <option value=0>No</option>
                </select> - <input v-model="d.resigned" :disabled="isDisabled"> <br>
                <b>Immigrant:</b> 
                <select v-model="d.is_immigrant" :disabled="isDisabled">
                  <option value=1>Yes</option>
                  <option value=0>No</option>
                </select> - <input v-model="d.immigrant" :disabled="isDisabled"> <br>
                <b>Indigenous:</b> 
                <select v-model="d.is_indigenous" :disabled="isDisabled">
                  <option value=1>Yes</option>
                  <option value=0>No</option>
                </select><br>
                <b>Disabled:</b> 
                <select v-model="d.is_disabled" :disabled="isDisabled">
                  <option value=1>Yes</option>
                  <option value=0>No</option>
                </select><br>
                <b>Solo:</b> 
                <select v-model="d.is_solo" :disabled="isDisabled">
                  <option value=1>Yes</option>
                  <option value=0>No</option>
                </select><br>
                <b>Government ID Type:</b> <input v-model="d.government_id_type" :disabled="isDisabled"> <br>
                <b>Government ID Number:</b> <input v-model="d.government_id_number" :disabled="isDisabled"> <br>
                <b>Issued Date:</b> <input v-model="d.issued_date" :disabled="isDisabled"> <br>
                <b>Issued Place:</b> <input v-model="d.issued_place" :disabled="isDisabled"> <br>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-collapse>
    <br>
    <!-- References -->
    <b-collapse class="card" :open.sync="isOpen9">
      <div slot="trigger" slot-scope="props" class="card-header" role="button" @click.prevent="activeTab(9)">
        <p class="card-header-title has-background-primary has-text-white">
          References
        </p>
        <a class="card-header-icon has-background-primary has-text-white">
          <b-icon
              :icon="props.open ? 'menu-down' : 'menu-up'">
          </b-icon>
        </a>
      </div>
      <div class="card-content">
        <div class="content columns" style="overflow-x: scroll" :style="{ 'text-transform': capitalize }">
          <div class="column is-12">
            <a class="has-text-success a-actions" v-if="!isDisabled" @click.prevent="d.references.push({ 
              id: null,
              name: '',
              address: '',
              telephone: ''
            })"><i class="fas fa-plus"></i> Add</a> <br>
            <b-table :data="d.references" :narrowed="true">
              <template slot-scope="props">
                <b-table-column label="Name">
                  <input v-model="props.row.name" :disabled="isDisabled">
                </b-table-column>
                <b-table-column label="Address">
                  <input v-model="props.row.address" :disabled="isDisabled">
                </b-table-column>
                <b-table-column label="Telephone">
                  <input v-model="props.row.telephone" :disabled="isDisabled">
                </b-table-column>
                <b-table-column label="Delete" v-if="!isDisabled">
                  <a class="has-text-danger a-actions" @click.prevent="deleteRow('references', props.row)"><i class="fas fa-trash"></i></a>
                </b-table-column>
                
              </template>
              <template slot="empty">
                <b-table-column label="Name">
                </b-table-column>
                <b-table-column label="Address">
                </b-table-column>
                <b-table-column label="Telephone">
                </b-table-column>
                <b-table-column label="Delete" v-if="!isDisabled">
                </b-table-column>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>

export default {
  data() {
    return {
      isLoading: false,
      d: '',
      isGraduate: false,
      isOpen1: true,
      isOpen2: false,
      isOpen3: false,
      isOpen4: false,
      isOpen5: false,
      isOpen6: false,
      isOpen7: false,
      isOpen8: false,
      isOpen9: false,
      lower: {},
      capitalize: {},
      isDisabled: true,
      s_skill: [],
      s_recognition: [],
      s_membership: [],
      m_skill: '',
      m_recognition: '',
      m_membership: '',
      skills: [],
      recognitions: [],
      memberships: [],
    }
  },
  mounted() {
    this.getInformation()
    this.getArray('skill')
    this.getArray('recognition')
    this.getArray('membership')
  },
  computed: {
    f_membership() {
      return this.s_membership.filter((option) => {
        return option.membership
          .toString()
          .toLowerCase()
          .indexOf(this.m_membership.toLowerCase()) >= 0
      })
    },
    f_skill() {
      return this.s_skill.filter((option) => {
        return option.skill
          .toString()
          .toLowerCase()
          .indexOf(this.m_skill.toLowerCase()) >= 0
      })
    },
    f_recognition() {
      return this.s_recognition.filter((option) => {
        return option.recognition
          .toString()
          .toLowerCase()
          .indexOf(this.m_recognition.toLowerCase()) >= 0
      })
    },
  },
  methods: {
    getArray(val) {
      let config = {
        headers: {'Authorization': "Bearer " + this.$store.state.token}
      };
      this.$http.get(`${this.$host}/api/${val}/show`,
        config
      ).then((response) => {
        this['s_'+val] = response.data.data

        console.log(this[val])
      }).catch((error) => {
        console.log(error)
      });
    },
    addMany(val, check) {
      this.getArray(val)
      let id;
      let s = "s_" + val
      let m = "m_" + val
      let table = val + "s"
      let obj = this[s].find(x => x[val] === this[m])

      if (obj != undefined) {
        id = obj.id

        this.d[table].push({
          id: id,
          [val]: this[m]
        })
        this[m] = ''
      }
      else if (obj == undefined) {
        let config = {
          headers: {'Authorization': "Bearer " + this.$store.state.token}
        };
        this.$http.post(`${this.$host}/api/${val}/save/`, {
          [val]: this[m]
          },
          config,
        ).then((response) => {
          let data = response.data.data
          console.log(data)
        }).then(() => {

          let config = {
            headers: {'Authorization': "Bearer " + this.$store.state.token}
          };
          this.$http.get(`${this.$host}/api/${val}/show`,
            config
          ).then((response) => {
            this['s_'+val] = response.data.data

            let id;
            let obj = this[s].find(x => x[val] === this[m])
            id = obj.id

            this.data[table].push({
              id: id,
              [val]: this[m]
            })
            this[m] = ''
            console.log(this[val])
          }).catch((error) => {
            console.log(error)
          });

        }).catch((error) => {
          console.log(error)
        });
      }
      // this.data.skills.push({
      //   skill: this.skill,
      // });
      // this.skill = ''
    },
    pluck(array, key) {
      return array.map(function(item) { return item[key]; });
    },
    deleteOther(id, arr) {
      this.d[arr] = this.d[arr].filter(x => {
        return x.id != id;
      })
    },
    save() {
      let config = {
        headers: {'Authorization': "Bearer " + this.$store.state.token}
      };
      this.$http.put(`${this.$host}/api/employee/update`,
        this.d,
        config,
      ).then((response) => {
        this.$toast.open({
          message: 'Data successfully edited.',
          type: 'is-success',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.getInformation()
        this.isDisabled = true
      }).catch((error) => {
        this.$toast.open({
          message: 'Error saving data.',
          type: 'is-danger',
          position: 'is-bottom-right',
          duration: 4000
        })
      });
    },
    deleteRow(table, obj) {
      this.d[table] = this.d[table].filter(x => {
        return x != obj;
      })
    },
    deleteChild(obj) {
      this.d.children = this.d.children.filter(x => {
        return x != obj;
      })
    },
    cancelPrompt() {
      this.$dialog.confirm({
        title: 'Discard Changes',
        message: 'Are you sure you want to <b>discard</b> your changes? This action cannot be undone.',
        confirmText: 'Discard',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.cancel()
      })
    },
    cancel() {
      this.getInformation()
      this.isDisabled = true
    },
    edit() {
      this.isDisabled = false
    },
    getInformation() {
      this.isLoading = true
      let config = {
        headers: {'Authorization': "Bearer " + this.$store.state.token}
      };

      this.$http.get(`${this.$host}/api/user`,
        config
      ).then((response) => {
        if (response.data.data.employee != null) {
          let data = response.data.data.employee
          this.$editmode.commit('setUpdate', response.data.data.is_update)
          this.d = data
          this.capitalize = 'capitalize !important'
          this.isLoading = false
        }
        else {
          this.isLoading = false
        }
      }).catch((error) => {
        this.isLoading = false
        console.log(error)
      });
    },
    graduated(bool) {
      if (bool == 1) {
        this.isGraduate = true
        return 'Yes'
      }
      else {
        return 'No'
      }
    },
    isTrue(bool) {
      if (bool == 1) {
        return 'Yes'
      }
      else {
        return 'No'
      }
    },
    activeTab(tab) {
      let i = 1
      let active = "isOpen" + tab
      while (i < 10) {
        let value = "isOpen" + i
        if (this[value] != this[active]) {
          this[value] = false
        }
        else {
          
        }
        i++
      }
      
    }
  }
}
</script>

<style scoped>
input {
  border: none;
  border-bottom: 1px solid black;
}
input:disabled {
  background-color: transparent;
  font-weight: bold;
  border: none;
}
input:disabled::-webkit-input-placeholder { /* WebKit browsers */
  color: transparent;
}
input:disabled:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color: transparent;
}
input:disabled::-moz-placeholder { /* Mozilla Firefox 19+ */
  color: transparent;
}
input:disabled:-ms-input-placeholder { /* Internet Explorer 10+ */
  color: transparent;
}
</style>