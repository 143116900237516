<template>
  <div class="box">
    <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
    <b-field grouped group-multiline>
      <b-input v-model="search_payroll" placeholder="Search" expanded/>
      <b-select v-model="perPage">
        <option value="5">5 per page</option>
        <option value="10">10 per page</option>
        <option value="20">20 per page</option>
        <option :value="payroll_list.length">All</option>
      </b-select>
    </b-field>
    <hr style="margin-top: 0px;">
    <b-table
      :data="filterPayroll"
      :striped=true
      :bordered=true
      :hoverable=false
      :paginated="true"
      :per-page="perPage">
      <template slot-scope="props">
        <b-table-column label="Name">
          {{ props.row.name }}
        </b-table-column>
        <b-table-column label="Print">
            <button class="button btn-action is-primary is-small" @click.prevent="openPrintConfig(props.row)"><i class="fa fa-print"></i></button>
        </b-table-column>
      </template>
      <template slot="empty">
        <b-table-column label="Name">
        </b-table-column>
        <b-table-column label="Print">
        </b-table-column>
      </template>
    </b-table>

    <b-modal :active.sync="isPrint" has-modal-card :canCancel=false>
      <transition name="fade">
        <form @submit.prevent="print()">
          <div class="modal-card pos" style="height: 35.625rem; margin-left: 100px;">
            <header class="modal-card-head">
              <p class="modal-card-title">{{ name }}</p>
            </header>
            <section class="modal-card-body">
              <div v-if="this.params.length > 0">
                <b-field label="Employee" v-if="this.params.includes('employee_names')">
                  <b-field>
                    <b-input v-model="employee_name" expanded disabled required>
                    </b-input>
                    <button class="button is-primary" @click.prevent="isSearch = true">
                      <span><i class="fa fa-user"></i></span>
                    </button>
                  </b-field>
                </b-field>
                <b-field label="Payroll Group" v-if="this.params.includes('payroll_group')">
                  <b-select placeholder="Payroll Group" v-model="printParams.payroll_group" expanded @input="getFinalizeDates">
                    <option value="1">Permanent/PA/Temporary/Co-Term </option>
                    <option value="2">Casuals/Contractual </option>
                    <option value="3">Contract of Service </option>
                    <option value="4">Job Order </option>
                  </b-select>
                </b-field>
                <b-field label="Payroll Subgroup" v-if="printParams.payroll_group == '1' && !params.includes('summary')">
                  <b-select placeholder="Payroll Group" v-model="printParams.payroll_subgroup" expanded @input="getFinalizeDates">
                    <option value="" selected>All</option>
                    <option value="1">Presidential Appointee</option>
                    <option value="2">Permanent</option>
                    <option value="3">Co-Terminus </option>
                    <option value="4">Temporary </option>
                  </b-select>
                </b-field>
                <b-field label="Payroll Subgroup" v-if="printParams.payroll_group == '2' && !params.includes('summary')">
                  <b-select placeholder="Payroll Group" v-model="printParams.payroll_subgroup" expanded @input="getFinalizeDates">
                    <option value="" selected>All</option>
                    <option value="9">Casuals</option>
                    <option value="6">Contractual</option>
                  </b-select>
                </b-field>
                <b-field label="Payroll Type" v-if="this.params.includes('payroll_type')">
                  <b-select v-model="printParams.payroll_type" expanded required @input="getFinalizeDates">
                    <option value="Regular">Regular</option>
                    <option value="Other">Other Benefits</option>
                    <option value="Voucher">Voucher</option>
                  </b-select>
                </b-field>
                <b-field label="Payroll Type" v-if="this.params.includes('payroll_type_voucher')">
                  <b-select v-model="printParams.payroll_type" expanded required @input="getFinalizeDates">
                    <option value="Regular">Regular</option>
                    <option value="Voucher">Voucher</option>
                  </b-select>
                </b-field>
                <b-field label="Voucher" v-if="this.params.includes('voucher_selection') && printParams.payroll_type == 'Voucher'">
                  <b-select v-model="printParams.voucher" expanded>
                    <option value="1">Salary</option>
                    <option value="2">Last Salary</option>
                    <option value="3">Cancelled from the Payroll</option>
                    <option value="4">Maternity Leave</option>
                    <option value="5">Terminal Leave</option>
                    <option value="6">Salary Differential from promotion</option>
                    <option value="7">Refund</option>
                  </b-select>
                </b-field>
                <b-field label="Salary" v-if="this.params.includes('voucher_selection') && printParams.payroll_type == 'Voucher' && printParams.voucher == '1'">
                  <b-select v-model="printParams.voucher_salary" expanded>
                    <option value="1">1rst Salary</option>
                    <option value="2">2nd Salary</option>
                    <option value="3">3rd Salary</option>
                    <option value="4">4th Salary</option>
                    <option value="5">5th Salary</option>
                  </b-select>
                </b-field>
                <b-field label="Quarter" v-if="this.params.includes('quarter')">
                  <b-select v-model="printParams.quarter" expanded>
                    <option value="0">All Quarter</option>
                    <option value="1">1rst Quarter</option>
                    <option value="2">2nd Quarter</option>
                    <option value="3">3rd Quarter</option>
                    <option value="4">4th Quarter</option>
                  </b-select>
                </b-field>
                <b-field label="Earning" v-if="printParams.payroll_type == 'Other'">
                  <b-select v-model="printParams.payroll_earning" expanded required @input="getFinalizeDates">
                    <!-- <option value="2">Overtime</option>
                    <option value="3">13th Month Pay and Cash Bonus</option>
                    <option value="4">Year-End Bonus and Cash Bonus</option>
                    <option value="6">Productivity Enhancement Incentive</option>
                    <option value="10">Honoraria</option>
                    <option value="11">Hazard Pay</option>
                    <option value="12">Subsistence and Laundry Allowance</option>
                    <option value="14">VL Monetization</option>
                    <option value="15">SL Monetization</option>
                    <option value="16">Coms Allow</option>
                    <option value="18">Uniform/Clothing Allowance</option>
                    <option value="19">Performance Based-Bonus</option>
                    <option value="20">Collective Negotiation Agreement Incentive</option>
                    <option value="21">Anniversary Bonus</option>
                    <option value="22">Loyalty Incentive Bonus</option>
                    <option value="25">Gratuity Pay</option>
                    <option value="30">Hazard Covid-19</option> -->
                    <option
                      v-for="earning in earnings"
                      :value="earning.id"
                      :key="earning.id">
                      {{ earning.code }}
                    </option>
                    <!-- <option v-for="earning in earnings" :key="earning.id" :value="earning.id">
                      <template v-if="earning.id == '12'">SUB AND LAUND
                      </template>
                      <template v-else>{{ earning.code }}
                      </template>
                      </option> -->
                  </b-select>
                </b-field>

                <b-field label="Mors Particulars" v-if="this.params.includes('mors_particulars') ||printParams.payroll_type == 'Other'">

                  </b-field>
                  <b-input v-model="printParams.id" type="hidden" v-if="this.params.includes('mors_particulars') || printParams.payroll_type == 'Other'"></b-input>
                  <b-field label="Parameters" v-if="this.params.includes('mors_particulars') ||printParams.payroll_type == 'Other'">
                    <!-- <b-input v-model="DVRow.id" type="hidden"></b-input> -->
                    <p>
                      <button class="button is-primary is-small" style="margin-right: 5px; margin-bottom: 5px;" v-for="parameter in parameters" :key="parameter.id" @click.prevent="applyParameterMors(parameter.value)">{{parameter.label}}</button>
                    </p>
                  </b-field>
                  <b-field label="Particulars" v-if="this.params.includes('mors_particulars') ||printParams.payroll_type == 'Other'">
                  <b-input type="textarea"
                    id="ta"
                    v-model="printParams.payroll_mors_particulars"
                    placeholder="Mors Particulars">
                  </b-input>
                </b-field>

                <b-field label="DV Particulars" v-if="this.params.includes('dv_particulars') || printParams.payroll_type == 'Other'">

                  </b-field>
                  <b-input v-model="printParams.id" type="hidden" v-if="this.params.includes('dv_particulars') || printParams.payroll_type == 'Other'"></b-input>
                  <b-field label="Parameters" v-if="this.params.includes('dv_particulars') || printParams.payroll_type == 'Other'">
                    <!-- <b-input v-model="DVRow.id" type="hidden"></b-input> -->
                    <p>
                      <button class="button is-primary is-small" style="margin-right: 5px; margin-bottom: 5px;" v-for="parameter in parameters" :key="parameter.id" @click.prevent="applyParameterDv(parameter.value)">{{parameter.label}}</button>
                    </p>
                  </b-field>
                  <b-field label="Particulars" v-if="this.params.includes('dv_particulars') || printParams.payroll_type == 'Other'">
                    <b-input type="textarea"
                      id="ts"
                      v-model="printParams.payroll_dv_particulars"
                      placeholder="Disbursment Voucher Particulars">
                    </b-input>
                  </b-field>
                <!-- </b-field> -->

                <b-field label="Range" v-if="this.params.includes('range_with_month')">
                  <b-radio v-model="printParams.specific_range" name="range" native-value=0 required>Range</b-radio>
                  <b-radio v-model="printParams.specific_range" name="range" native-value=1>Year</b-radio>
                </b-field>
                <b-field label="Range" v-if="this.params.includes('range_with_month_and_all')">
                  <b-radio v-model="printParams.specific_range" name="range" native-value=0 required>Range</b-radio>
                  <b-radio v-model="printParams.specific_range" name="range" native-value=1>Year</b-radio>
                  <b-radio v-model="printParams.specific_range" name="range" native-value=3 required>All</b-radio>
                </b-field>
                <b-field label="Range" v-if="this.params.includes('range')">
                  <b-radio v-model="printParams.range" name="range" native-value=0 required>All</b-radio>
                  <b-radio v-model="printParams.range" name="range" native-value=1>Year</b-radio>
                </b-field>

                <b-field label="Note" v-if="this.params.includes('dv_note')">
                  <b-input type="textarea"
                      v-model="printParams.payroll_dv_note"
                      placeholder="Disbursment Voucher Notes">
                    </b-input>
                </b-field>
                <b-field label="Deduction" v-if="this.params.includes('deduction_list')">
                  <b-select v-model="printParams.payroll_deduction" expanded required @input="getFinalizeDates">
                    <option v-for="deduction in orderedDeductions" :key="deduction.id" :value="deduction.id">{{ deduction.code }}</option>
                  </b-select>
                </b-field>
                <b-field label="Month" v-if="this.params.includes('month')">
                  <b-select v-model="printParams.month" placeholder="Month" expanded  @input="setDay">
                    <option value="01">January</option>
                    <option value="02">February</option>
                    <option value="03">March</option>
                    <option value="04">April</option>
                    <option value="05">May</option>
                    <option value="06">June</option>
                    <option value="07">July</option>
                    <option value="08">August</option>
                    <option value="09">September</option>
                    <option value="10">October</option>
                    <option value="11">November</option>
                    <option value="12">December</option>
                  </b-select>
                </b-field>
                <b-field label="Year" v-if="this.params.includes('year') || printParams.range == 1 || printParams.specific_range == 1">
                  <b-select
                    v-model="printParams.year"
                    placeholder="Select Year"
                    expanded
                    required
                    @input="setDay"
                  >
                    <option v-for="year in years" :key="year" :value="year">{{year}}</option>
                  </b-select>
                </b-field>
                <b-field label="Day" v-if="(printParams.payroll_group == 4 && !params.includes('summary') && printParams.payroll_type=='Regular' && this.url != 'index-of-payments-to-employees') || (printParams.payroll_group==1 && params.includes('bank'))">
                  <b-select v-model="printParams.day" expanded required @input="getFinalizeDates">
                    <option v-for="day in printParams.days" :key="day" :value="day">
                      {{ day }}
                    </option>
                  </b-select>
                </b-field>
                <b-field label="Funding Source" v-if="($role.state.permissions['run payroll'] || $role.state.permissions['run payroll funding source']) && (this.params.includes('funding_source') || this.url=='index-of-payments-to-employees' || (this.printParams.payroll_group>1))">
                  <b-select v-model="printParams.funding_source" expanded @input="getFinalizeDates">
                    <option value="" selected>All</option>
                    <option v-for="source in sources" :key="source.id" :value="source.id">{{ source.code  + ' - ' + source.description }}</option>
                  </b-select>
                </b-field>

                <b-field grouped group-multiline v-if="printParams.payroll_earning == 2">
                  <b-field label="Start Date" expanded required>
                    <b-field expanded>
                      <b-field expanded>
                        <b-input v-model="start_date.year" type="number" min="1800" max="2100" placeholder="YEAR" expanded required >
                        </b-input>
                      </b-field>
                      <b-field expanded>
                        <b-select v-model="start_date.month" placeholder="MONTH" expanded required>
                          <option v-for="month in dateConfig.monthsFixed" :key="month" :value="month">
                            {{ month }}
                          </option>
                        </b-select>
                      </b-field>
                    </b-field>
                  </b-field>

                  <b-field label="End Date" expanded required>
                    <b-field expanded>
                      <b-field expanded>
                        <b-input v-model="end_date.year" type="number" min="1800" max="2100" placeholder="YEAR" expanded required >
                        </b-input>
                      </b-field>
                      <b-field expanded>
                        <b-select v-model="end_date.month" placeholder="MONTH" expanded required>
                          <option v-for="month in dateConfig.monthsFixed" :key="month" :value="month">
                            {{ month }}
                          </option>
                        </b-select>
                      </b-field>
                    </b-field>
                  </b-field>
                </b-field>

                <b-field grouped group-multiline v-if="printParams.specific_range == 0">
                  <b-field label="Start Date" expanded>
                    <b-field expanded>
                      <b-field expanded>
                        <b-input v-model="printParams.start_year" type="number" min="1800" max="2100" placeholder="YEAR" expanded required >
                        </b-input>
                      </b-field>
                      <b-field expanded>
                        <b-select v-model="printParams.start_month" placeholder="MONTH" expanded required>
                          <option value="01">01</option>
                          <option value="02">02</option>
                          <option value="03">03</option>
                          <option value="04">04</option>
                          <option value="05">05</option>
                          <option value="06">06</option>
                          <option value="07">07</option>
                          <option value="08">08</option>
                          <option value="09">09</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                        </b-select>
                      </b-field>
                      <b-field expanded>
                        <b-input v-model="printParams.start_day" type="number" min="0" max="32" placeholder="DAY" expanded required >
                        </b-input>
                      </b-field>
                    </b-field>
                  </b-field>

                  <b-field label="End Date" expanded>
                    <b-field expanded>
                      <b-field expanded>
                        <b-input v-model="printParams.end_year" type="number" min="1800" max="2100" placeholder="YEAR" expanded required >
                        </b-input>
                      </b-field>
                      <b-field expanded>
                        <b-select v-model="printParams.end_month" placeholder="MONTH" expanded required>
                          <option value="01">01</option>
                          <option value="02">02</option>
                          <option value="03">03</option>
                          <option value="04">04</option>
                          <option value="05">05</option>
                          <option value="06">06</option>
                          <option value="07">07</option>
                          <option value="08">08</option>
                          <option value="09">09</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                        </b-select>
                      </b-field>
                      <b-field expanded>
                        <b-input v-model="printParams.end_day" type="number" min="0" max="32" placeholder="DAY" expanded required >
                        </b-input>
                      </b-field>
                    </b-field>
                  </b-field>
                </b-field>
                <b-field label="Bank" v-if="this.params.includes('bank')">
                  <b-select v-model="printParams.bank" expanded>
                    <option v-for="bank in bank_list" :key="bank" :value="bank">{{bank==null?"No Bank Accounts":bank}}</option>
                  </b-select>
                </b-field>
                <b-field label="Finalized?" v-if="this.params.includes('is_finalize')">
                  <b-radio v-model="printParams.is_finalize" name="Finalize" native-value=1 @input="getFinalizeDates" required>Yes</b-radio>
                  <b-radio v-model="printParams.is_finalize" name="Finalize" native-value=0>No</b-radio>
                </b-field>
                <b-field label="Date Finalized" v-if="printParams.is_finalize==1">
                  <b-select
                    v-model="printParams.finalize_date"
                    placeholder="Select Finalize Date"
                    expanded
                  >
                    <option v-for="(obj,finalize_date) in finalize_dates" :key="finalize_date" :value="finalize_date" >{{finalize_date}} ({{ obj.amount | currencyFormat }}) - {{ obj.funding_source }}</option>
                  </b-select>
                </b-field>

                <b-field label="Select Employees" v-if="this.params.includes('employee_search')">
                  <button class="button is-primary is-small" @click.prevent="selectEmployee()">
                    <span><i class="fa fa-user-plus"></i> Select Employee</span>
                  </button>
                </b-field>

                <div style="overflow: auto; max-height: 10rem;"  v-if="this.params.includes('employee_search')">
                  <b-tag v-for="emp in printParams.checkedRows" :key="emp.id" type="is-primary" closable @close="removeSelected(emp)"
                  style="margin-right: 5px; margin-top:4px;">
                    {{ emp.fullname }}
                  </b-tag>
                </div>

                <b-field label="Select Employees to Exclude" v-if="this.params.includes('employee_search_exclusion')">
                  <button class="button is-primary is-small" @click.prevent="selectEmployee()">
                    <span><i class="fa fa-user-plus"></i> Select Employee</span>
                  </button>
                </b-field>

                <div style="overflow: auto; max-height: 10rem;"  v-if="this.params.includes('employee_search_exclusion')">
                  <b-tag v-for="emp in printParams.checkedRows" :key="emp.id" type="is-primary" closable @close="removeSelected(emp)"
                  style="margin-right: 5px; margin-top:4px;">
                    {{ emp.fullname }}
                  </b-tag>
                </div>

                <b-field label="GSIS Deduction" v-if="this.params.includes('gsis_deduction')">
                  <b-select v-model="printParams.gsis_deduction" expanded>
                    <option value="0">CONTRIBUTION</option>
                    <option value="1">LOANS</option>
                    <option v-for="deduction in filteredGSISDeductions" v-bind:key="deduction.id" :value="deduction.code">{{deduction.code}}</option>
                    <!-- <option value="gsis CONSO LOAN">GSIS CONSOLIDATED LOAN</option>
                    <option value="GSIS POL LOAN">GSIS REGULAR POLICY LOAN</option>
                    <option value="GSIS EDUC LOAN">GSIS EDUCATIONAL ASSISTANCE LOAN</option>
                    <option value="GSIS OPT LOAN">GSIS OPTIONAL POLICY LOAN</option>
                    <option value="GSIS EMERG LOAN">GSIS EMERGENCY LOAN</option>
                    <option value="gsis gfal">GSIS FINANCIAL ASSISTANCE LOAN</option>
                    <option value="gsis ea loan">EDUCATION ASSISTANCE LOAN</option>
                    <option value="GSIS REL">REAL ESTATE LOAN</option>
                    <option value="GSIS MPL">GSIS MULTI PURPOSE LOAN</option>
                    <option value="GSIS Comp Loan">GSIS COMPUTER LOAN</option>
                    <option value="gsis SOS">GSIS SUMMER ONE-MONTH SALARY LOAN</option>
                    <option value="gsis ceap">GSIS College Education Assurance Plan</option>
                    <option value="genesis plus">GSIS Memorial Plan Refundable up to 125% of plan's contract price</option>
                    <option value="genesis flexi">GSIS Genesis Flexi Memorial Plan Refund up to 150% of plan's contract price</option>
                    <option value="genesis special">GSIS Genesis Special Memorial Plan Refund  up to 100% plan's contract price</option>
                    <option value="gsis help">HOME EMERGENCY LOAN PROGRAM</option>
                    <option value="gsis ecard plus">ECARD PLUS</option>
                    <option value="gsis salary loan">SALARY LOAN</option>
                    <option value="gsis cash advance loan">CASH ADVANCE LOAN</option>
                    <option value="GSIS LCH_DCS">LOW COST HOUSING</option>
                    <option value="GSIS STOCK PURCHASE">PURCHASES OF GSIS STOCKS</option>
                    <option value="GSIS EDU Child">EDUCATIONAL ASSISTANCE</option> -->
                  </b-select>
                </b-field>
                <b-field label="PAGIBIG Deduction" v-if="this.params.includes('pagibig_deduction')">
                  <b-select v-model="printParams.pagibig_deduction" expanded>
                    <option value="0">CONTRIBUTION</option>
                    <option v-for="deduction in filteredPAGIBIGDeductions" v-bind:key="deduction.id" :value="deduction.code">{{ deduction.code }}</option>
                    <!-- <option value="PAG-IBIG HOUSING">PAG-IBIG HOUSING</option>
                    <option value="PAG-BIG CALAMITY">PAG-BIG CALAMITY</option>
                    <option value="PAG-IBIG LOAN">PAG-IBIG LOAN</option>
                    <option value="PAG-IBIG MP2">PAG-IBIG MP2</option> -->
                  </b-select>
                </b-field>

                <b-field label="Other Deductions" v-if="this.params.includes('other_loans')">
                  <b-select v-model="printParams.other_deduction" expanded>
                    <option v-for="deduction in filteredOtherDeductions" v-bind:key="deduction.id" :value="deduction.code">{{ deduction.code }}</option>
                    <!-- <option value="PAG-IBIG HOUSING">PAG-IBIG HOUSING</option>
                    <option value="PAG-BIG CALAMITY">PAG-BIG CALAMITY</option>
                    <option value="PAG-IBIG LOAN">PAG-IBIG LOAN</option>
                    <option value="PAG-IBIG MP2">PAG-IBIG MP2</option> -->
                  </b-select>
                </b-field>

                <b-field label="Due Date (mm/yyyy)" v-if="this.params.includes('due_date')">
                  <b-input v-model="printParams.due_date" expanded required>
                  </b-input>
                </b-field>
                <b-field label="Office" v-if="this.params.includes('office')">
                  <b-input v-model="printParams.office" expanded required>
                  </b-input>
                </b-field>
                <b-field label="Name of Claimant" v-if="this.params.includes('name_of_claimant')">
                  <b-input v-model="printParams.name_of_claimant" expanded required>
                  </b-input>
                </b-field>
                <b-field label="Address" v-if="this.params.includes('address')">
                  <b-input v-model="printParams.address" expanded required>
                  </b-input>
                </b-field>
                <b-field label="Taxpayer Authorized Representative" v-if="this.params.includes('taxpayer')">
                  <b-input v-model="printParams.taxpayer" expanded required>
                  </b-input>
                </b-field>
                <b-field label="Title/Position" v-if="this.params.includes('taxpayer')">
                  <b-input v-model="printParams.taxpayer_position" expanded required>
                  </b-input>
                </b-field>
                <b-field label="TIN of Tax Agent (if applicable)" v-if="this.params.includes('taxpayer')">
                  <b-input v-model="printParams.taxpayer_tin" expanded>
                  </b-input>
                </b-field>
                <b-field label="Tax Agent Accreditation No. (if applicable)" v-if="this.params.includes('taxpayer')">
                  <b-input v-model="printParams.taxpayer_accreditation" expanded>
                  </b-input>
                </b-field>
                <div v-if="this.params.includes('prepared_by')">
                  <p class="modal-card-title"><br>Prepared By:</p>
                  <b-field label="Name">
                    <b-autocomplete
                      v-model="printParams.prepared_by.name"
                      :data="filteredSupervisor"
                      field="full_name"
                      @select="option => {printParams.prepared_by.position = option.position}"
                      :open-on-focus=true expanded>
                    </b-autocomplete>
                  </b-field>
                  <b-field label="Position">
                    <b-input
                      v-model="printParams.prepared_by.position"
                      expanded>
                    </b-input>
                  </b-field>
                </div>
                <div v-if="this.params.includes('checked_by')">
                  <p class="modal-card-title"><br>Checked By:</p>
                  <b-field label="Name">
                    <b-autocomplete
                      v-model="printParams.checked_by.name"
                      :data="filteredSupervisor"
                      field="full_name"
                      @select="option => {printParams.checked_by.position = option.position}"
                      :open-on-focus=true expanded required>
                    </b-autocomplete>
                  </b-field>
                  <b-field label="Position">
                    <b-input
                      v-model="printParams.checked_by.position"
                      expanded required>
                    </b-input>
                  </b-field>
                </div>
                <div v-if="this.params.includes('noted_by')">
                  <p class="modal-card-title"><br>Noted By:</p>
                  <b-field label="Name">
                    <b-autocomplete
                      v-model="printParams.noted_by.name"
                      :data="filteredSupervisor"
                      field="full_name"
                      @select="option => {printParams.noted_by.position = option.position}"
                      :open-on-focus=true expanded required>
                    </b-autocomplete>
                  </b-field>
                  <b-field label="Position">
                    <b-input
                      v-model="printParams.noted_by.position"
                      expande required>
                    </b-input>
                  </b-field>
                </div>
                <div v-if="this.params.includes('certified_by')">
                  <p class="modal-card-title"><br>Certified By:</p>
                  <b-field label="Name">
                    <b-autocomplete
                      v-model="printParams.certified_by_name"
                      :data="filteredSupervisor"
                      field="full_name"
                      @select="option => {printParams.certified_by_position = option.position}"
                      :open-on-focus=true expanded required>
                    </b-autocomplete>
                  </b-field>
                  <b-field label="Position">
                    <b-input
                      v-model="printParams.certified_by_position"
                      expanded required>
                    </b-input>
                  </b-field>
                </div>
              </div>

              <div v-if="this.params.includes('certified_by_2')">
                  <p class="modal-card-title"><br>Certified By:</p>
                  <b-field label="Name">
                    <b-autocomplete
                      v-model="printParams.certified_by_2.name"
                      :data="filteredSupervisor"
                      field="full_name"
                      @select="option => {printParams.certified_by_2.position = option.position}"
                      :open-on-focus=true expanded required>
                    </b-autocomplete>
                  </b-field>
                  <b-field label="Position">
                    <b-input
                      v-model="printParams.certified_by_2.position"
                      expande required>
                    </b-input>
                  </b-field>
              </div>

              <!-- old -->
              <div v-if="this.params.includes('old')">
                <b-field label="Month" v-if="this.params.includes('month')">
                  <b-select v-model="printConfig.month" placeholder="Month" expanded>
                    <option value="January">January</option>
                    <option value="February">February</option>
                    <option value="March">March</option>
                    <option value="April">April</option>
                    <option value="May">May</option>
                    <option value="June">June</option>
                    <option value="July">July</option>
                    <option value="August">August</option>
                    <option value="September">September</option>
                    <option value="October">October</option>
                    <option value="November">November</option>
                    <option value="December">December</option>
                  </b-select>
                </b-field>
                <b-field label="Year" v-if="this.params.includes('year')">
                  <b-input v-model="printConfig.year" type="number" min="1800" max="2100" expanded required>
                  </b-input>
                </b-field>
                <b-field label="Payroll Type" v-if="this.params.includes('payroll_type')">
                  <b-select v-model="printConfig.payroll_type" expanded>
                    <option value="Regular">Regular</option>
                    <option value="Special">Special</option>
                  </b-select>
                </b-field>
                <b-field label="Payroll Group" v-if="this.params.includes('payroll_group')">
                  <b-select placeholder="Payroll Group" v-model="printConfig.payroll_group" expanded>
                    <option value="1">Permanent/PA/Temporary/Co-Term </option>
                    <option value="2">Casuals/Contractual </option>
                    <option value="3">Contract of Service </option>
                    <option value="4">Job Order </option>
                  </b-select>
                </b-field>
                <b-field label="Permanent/PA/Temporary/Co-Term" v-if="printConfig.payroll_group == 'permanent' || this.params.includes('permanent')">
                  <b-select placeholder="Payroll Group" v-model="printConfig.permanent" expanded>
                    <option value="presidential">Presidential
                      </option>
                    <option value="permanent">Permanent </option>
                    <option value="coterminus">Co-Terminus </option>
                    <option value="temporary">Temporary </option>
                  </b-select>
                </b-field>
                <b-field label="Funding Source" v-if="this.params.includes('funding_source')">
                  <b-select v-model="printConfig.funding_source" expanded>
                    <option v-for="source in sources" :key="source.id" :value="source.id">{{ source.code }}</option>
                  </b-select>
                </b-field>
              </div>

              <b-field label="Modified Obligation Request And Status" v-if="this.params.includes('mors')">
                  <button class="button is-primary is-small" @click.prevent="selectMors()">
                    <span><i class="fa fa-user-plus"></i> MORS Signatory</span>
                  </button>
                </b-field>

              <div v-if="this.params.includes('dv')">
                <b-field label="Disbursement Voucher">
                 <button class="button is-primary is-small" @click.prevent="selectDv()">
                    <span><i class="fa fa-user-plus"></i> DV Signatory</span>
                  </button>
                </b-field>
                <b-taginput
                    v-model="printParams.attachment"
                    ellipsis
                    icon="file"
                     type="is-info"
                    placeholder="Add Attachments">
                </b-taginput>
              </div>
              <div v-if="this.params.includes('previous_employer')">
                  <b-field label="Previous Employer Name">
                    <b-input
                      v-model="printParams.previous_employer_name"
                      expanded>
                    </b-input>
                  </b-field>
                  <b-field label="Previous Employer Registered Address">
                    <b-input
                      v-model="printParams.previous_employer_address"
                      expanded>
                    </b-input>
                  </b-field>
                  <b-field label="Zip Code">
                    <b-input
                      v-model="printParams.previous_employer_zip"
                      expanded>
                    </b-input>
                  </b-field>
                  <b-field label="Previous Employer Tin">
                    <b-input
                      v-model="printParams.previous_employer_tin"
                      expanded>
                    </b-input>
                  </b-field>
              </div>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" type="submit">Print</button>
              <button class="button is-danger" type="button" @click.prevent="closePrint()">Cancel</button>
              <button v-if="this.id==49" class="button is-success" type="button" @click.prevent="generateTextFile()">Text File</button>
              <button v-if="this.id==25" class="button is-success" type="button" @click.prevent="generateGSISRemmitanceReport()">GSIS Remmitance (XLS)</button>
              <button v-if="this.id==25" class="button is-success" type="button" @click.prevent="generateGSISRetroReport()">GSIS Retro (PDF)</button>
              <button v-if="this.id==50" class="button is-success" type="button" @click.prevent="generateSummaryOfClaimsReport()">Summary of Claims (XLS)</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>

    <b-modal :active.sync="isMorsSignatory" has-modal-card :canCancel=false>
      <transition name="fade">
        <form @submit.prevent="doneMors()">
          <div class="modal-card pos" style="width: 60rem; height: 30.625rem; margin-left: 100px;">
            <header class="modal-card-head">
              <p class="modal-card-title">MORS Signatory</p>
            </header>
            <section class="modal-card-body">
            <div class="columns" style="font-size: 13px">
              <div class="column is-4">
                <b>A. Certified By</b>
                <hr style="margin-top: 10px; margin-bottom: 10px; background-color: grey">

                <b-field label="Name">
                  <b-autocomplete
                    v-model="printParams.mors_signatory.a_certified.name"
                    :data="filterCertifiedA"
                    field="full_name"
                    @select="option => {
                      printParams.mors_signatory.a_certified.position = option.position
                      printParams.mors_signatory.a_certified.division = option.division
                    }"
                    :open-on-focus=true expanded>
                  </b-autocomplete>
                </b-field>
                <b-field label="Position">
                  <b-input
                    v-model="printParams.mors_signatory.a_certified.position"
                    expanded>
                  </b-input>
                </b-field>
                <b-field label="Division">
                  <b-input
                    v-model="printParams.mors_signatory.a_certified.division"
                    expanded>
                  </b-input>
                </b-field>

              </div>
              <div class="column is-4">
                <b>B. Certified By</b>
                <hr style="margin-top: 10px; margin-bottom: 10px; background-color: grey">

                <b-field label="Name">
                  <b-autocomplete
                    v-model="printParams.mors_signatory.b_certified.name"
                    :data="filterCertifiedB"
                    field="full_name"
                    @select="option => {
                      printParams.mors_signatory.b_certified.position = option.position
                      printParams.mors_signatory.b_certified.division = option.division
                    }"
                    :open-on-focus=true expanded>
                  </b-autocomplete>
                </b-field>
                <b-field label="Position">
                  <b-input
                    v-model="printParams.mors_signatory.b_certified.position"
                    expanded>
                  </b-input>
                </b-field>
                <b-field label="Division">
                  <b-input
                    v-model="printParams.mors_signatory.b_certified.division"
                    expanded>
                  </b-input>
                </b-field>

              </div>
              <div class="column is-4">
                <b>Noted By</b>
                <hr style="margin-top: 10px; margin-bottom: 10px; background-color: grey">

                <b-field label="Name">
                  <b-autocomplete
                    v-model="printParams.mors_signatory.noted.name"
                    :data="filterNoted"
                    field="full_name"
                    @select="option => {
                      printParams.mors_signatory.noted.position = option.position
                      printParams.mors_signatory.noted.division = option.division
                    }"
                    :open-on-focus=true expanded>
                  </b-autocomplete>
                </b-field>
                <b-field label="Position">
                  <b-input
                    v-model="printParams.mors_signatory.noted.position"
                    expanded>
                  </b-input>
                </b-field>
                <b-field label="Division">
                  <b-input
                    v-model="printParams.mors_signatory.noted.division"
                    expanded>
                  </b-input>
                </b-field>

              </div>

            </div>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" type="submit">Done</button>
              <!-- <button class="button is-danger" type="button" @click.prevent="closeSelect()">Cancel</button> -->
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>

    <b-modal :active.sync="isDvSignatory" has-modal-card :canCancel=false>
      <transition name="fade">
        <form @submit.prevent="doneDv()">
          <div class="modal-card pos" style="width: 60rem; height: 30.625rem; margin-left: 100px;">
            <header class="modal-card-head">
              <p class="modal-card-title">DV Signatory</p>
            </header>
            <section class="modal-card-body">
            <div class="columns" style="font-size: 13px">
              <div class="column is-4">
                <b>A. Certified By</b>
                <hr style="margin-top: 10px; margin-bottom: 10px; background-color: grey">

                <b-field label="Name">
                  <b-autocomplete
                    v-model="printParams.dv_signatory.a_certified.name"
                    :data="filterDvCertifiedA"
                    field="full_name"
                    @select="option => {
                      printParams.dv_signatory.a_certified.position = option.position
                      printParams.dv_signatory.a_certified.division = option.division
                    }"
                    :open-on-focus=true expanded>
                  </b-autocomplete>
                </b-field>
                <b-field label="Position">
                  <b-input
                    v-model="printParams.dv_signatory.a_certified.position"
                    expanded>
                  </b-input>
                </b-field>
                <b-field label="Division">
                  <b-input
                    v-model="printParams.dv_signatory.a_certified.division"
                    expanded>
                  </b-input>
                </b-field>

              </div>
              <div class="column is-4">
                <b>B. Certified By</b>
                <hr style="margin-top: 10px; margin-bottom: 10px; background-color: grey">

                <b-field label="Name">
                  <b-autocomplete
                    v-model="printParams.dv_signatory.b_certified.name"
                    :data="filterDvCertifiedB"
                    field="full_name"
                    @select="option => {
                      printParams.dv_signatory.b_certified.position = option.position
                      printParams.dv_signatory.b_certified.division = option.division
                    }"
                    :open-on-focus=true expanded>
                  </b-autocomplete>
                </b-field>
                <b-field label="Position">
                  <b-input
                    v-model="printParams.dv_signatory.b_certified.position"
                    expanded>
                  </b-input>
                </b-field>
                <b-field label="Division">
                  <b-input
                    v-model="printParams.dv_signatory.b_certified.division"
                    expanded>
                  </b-input>
                </b-field>

              </div>
              <div class="column is-4">
                <b>Approved for Payment By</b>
                <hr style="margin-top: 10px; margin-bottom: 10px; background-color: grey">

                <b-field label="Name">
                  <b-autocomplete
                    v-model="printParams.dv_signatory.noted.name"
                    :data="filterDvNoted"
                    field="full_name"
                    @select="option => {
                      printParams.dv_signatory.noted.position = option.position
                      printParams.dv_signatory.noted.division = option.division
                    }"
                    :open-on-focus=true expanded>
                  </b-autocomplete>
                </b-field>
                <b-field label="Position">
                  <b-input
                    v-model="printParams.dv_signatory.noted.position"
                    expanded>
                  </b-input>
                </b-field>
                <b-field label="Division">
                  <b-input
                    v-model="printParams.dv_signatory.noted.division"
                    expanded>
                  </b-input>
                </b-field>

              </div>

            </div>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" type="submit">Done</button>
              <!-- <button class="button is-danger" type="button" @click.prevent="closeSelect()">Cancel</button> -->
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>

    <b-modal :active.sync="isSelect" has-modal-card :canCancel=false>
      <transition name="fade">
        <form @submit.prevent="select()">
          <div class="modal-card pos" style="width: 60rem; height: 35.625rem; margin-left: 100px;">
            <header class="modal-card-head">
              <p class="modal-card-title">Select Employees</p>
            </header>
            <section class="modal-card-body">
              <b-field>
                <b-input v-model="search_list" placeholder="Search Employee" expanded/>
              </b-field>
              <b-table
                :data="filterEmployees"
                :striped=true
                :bordered=true
                :hoverable=true
                :paginated="false"
                :per-page="perPage"
                checkable
                :checked-rows.sync="printParams.checkedRows"
                class="table-font">
                <template slot-scope="props">
                  <b-table-column label="Emp. No." width="100">
                    {{ props.row.employee_number }}
                  </b-table-column>
                  <b-table-column label="Full Name">
                    {{ props.row.fullname }}
                  </b-table-column>
                  <b-table-column label="Position">
                    {{ props.row.position }}
                  </b-table-column>
                  <b-table-column label="Division">
                    {{ props.row.division }}
                  </b-table-column>
                  <b-table-column label="Perpetual" v-if="printParams.payroll_earning == 16">
                    <b-checkbox v-model="props.row.checkbox_0" v-if="props.row.is_0 == true" @input="isTrue(props.row)">
                    </b-checkbox>
                  </b-table-column>
                  <b-table-column label="One-Time" v-if="printParams.payroll_earning == 16">
                    <b-checkbox v-model="props.row.checkbox_1" v-if="props.row.is_1 == true" @input="isTrue(props.row)">
                    </b-checkbox>
                  </b-table-column>
                  <b-table-column label="14th Pay" v-if="printParams.payroll_earning == 4">
                    <b-checkbox v-model="props.row.checkbox_0" v-if="props.row.is_0 == true" @input="isTrue(props.row)">
                    </b-checkbox>
                  </b-table-column>
                  <b-table-column label="Cash Bonus" v-if="printParams.payroll_earning == 4">
                    <b-checkbox v-model="props.row.checkbox_1" v-if="props.row.is_1 == true" @input="isTrue(props.row)">
                    </b-checkbox>
                  </b-table-column>
                </template>
                <template slot="empty">

                </template>
              </b-table>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" type="submit">Select</button>
              <!-- <button class="button is-danger" type="button" @click.prevent="closeSelect()">Cancel</button> -->
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>

    <transition name="fade">
      <EmployeeSearch search-title="Employee" @close="close" @view="view_employee" v-if="isSearch" :isReport=true />
    </transition>
  </div>
</template>

<script>
import _ from 'lodash'
import payroll_json from './payroll.json'
import moment from 'moment'
import EmployeeSearch from '../SearchModal.vue'

export default {
  components: {
    EmployeeSearch
  },
  data() {
    return {
      isLoading: false,
      isMorsSignatory: false,
      isDvSignatory: false,
      payroll_setting_id: null,
      employee_id: null,
      employment_status: null,
      search_payroll: '',
      perPage: 5,
      payroll_list: payroll_json,
      isPrint: false,
      printConfig: {},
      finalize_dates: [],
      parameters: [
        {id:1,label:"AMOUNT_TEXT",value:"AMOUNT_TEXT"},
        {id:2,label:"PERIOD",value:"PERIOD"},
        {id:3,label:"PAYROLL GROUP",value:"PAYROLL_GROUP"},
        {id:4,label:"FUNDING SOURCE",value:"FUNDING_SOURCE"},
        {id:5,label:"AMOUNT",value:"AMOUNT"},
        {id:6,label:"YEAR",value:"YEAR"},
        {id:7,label:"EARNINGS",value:"EARNINGS"},
        {id:8, label:"PAYROLL_TYPE",value:"PAYROLL_TYPE"}
      ],
      printParams: {
        bonus_type: null,
        earning: null,
        voucher: null,
        voucher_salary: null,
        deduction: null,
        year: new Date().getFullYear(),
        month: (new Date().getMonth() + 1).toString().padStart(2,'0'),
        day: '',
        is_finalize: 0,
        finalize_date: null,
        bank: '',
        certified_by_name: 'MA. JESUSA PADILLA MENDOZA',
        certified_by_position: 'Chief Administrative Officer',
        previous_employer_name: '',
        previous_employer_address: '',
        previous_employer_tin: '',
        previous_employer_zip: '',
        payroll_type: 'Regular',
        quarter: '',
        dv_particulars: '',
        mors_particulars: '',
        dv_note: '',
        payroll_group: '',
        payroll_subgroup: '',
        funding_source: this.$role.state.funding_source_id,
        mors_signatory: {
          a_certified: {
            name: '',
            position: '',
            division: ''
          },
          b_certified: {
            name: 'PEÑAFRANCIA MORALES ALZONA',
            position: 'OIC Chief',
            division: 'BUDGET DIVISION'
          },
          noted: {
            name: 'CECILIA EVARISTO BIEN',
            position: 'Director',
            division: 'Office of the Director for Finance and Management Service'
          },
        },
        dv_signatory: {
          a_certified: {
            name: '',
            position: '',
            division: ''
          },
          b_certified: {
            name: 'EDNA CACDAC TAPAR',
            position: 'Chief Accountant',
            division: 'Accounting Division'
          },
          noted: {
            name: '',
            position: '',
            division: ''
          },
        },
        prepared_by: {
          name: '',
          position: ''
        },
        checked_by: {
          name: '',
          position: ''
        },
        noted_by: {
          name: '',
          position: ''
        },
        certified_by_2: {
            name: 'EDNA CACDAC TAPAR',
            position: 'Chief Accountant'
        },
        attachment: [],
        checkedRows: [],
      },
      id: 0,
      url: '',
      name: '',
      params: '',
      dateToday: '',
      sources: '',
      earnings: '',
      deductions: [],
      suggest: [],
      bank_list: [],
      isSearch: false,
      employee_name: null,
      employees: [],
      isSelect: false,
      search_list: '',
      start_date: {
        month: null,
        year: 2020,
      },
      end_date: {
        month: null,
        year: 2020,
      }

    }
  },
  mounted() {
    this.config = {
      headers: {'Authorization': "Bearer " + this.$store.state.token}
    };
    this.init()

    this.$http.get(`${this.$host}/api/parameter/service_record/employee`,
      this.config
    ).then((response) => {
      let data = response.data
      this.suggest = data
    }).catch((error) => {
      //console.log(error)
      this.isLoading = false
    });

    this.$http.get(`${this.$host}/api/parameter/banks`,
      this.config
    ).then((response) => {
      this.bank_list = response.data
      this.isLoading = false
    }).catch ((error) => {
      //console.log(error)
      this.isLoading = false
    });

    setTimeout(() => this.getEarnings(), 100)

  },
  filters: {
    currencyFormat: function (value) {
      var cNumber = new Intl.NumberFormat('en-PH',{style:'currency', currency: 'PHP'}).format(value);
      return cNumber;
    },
  },
  computed: {
    filterEmployees() {
      var i;
      var name_re = new RegExp(this.search_list, 'i')
      var data = []
      for (i in this.employees) {
        if ([this.employees[i].employee_number, this.employees[i].fullname, this.employees[i].position, this.employees[i].division].join(' ').match(name_re)) {
          data.push(this.employees[i])
        }
      }
      return data
    },
    years() {
      const year = new Date().getFullYear() + 5;
      return Array.from({length: (year-1970) + 1},(value,index)=>year-index)
    },
    filterPayroll() {
      var i;
      var name_re = new RegExp(this.search_payroll, 'i')
      var data = []
      for (i in this.payroll_list) {
        if ([this.payroll_list[i].name].join(' ').match(name_re)) {
          data.push(this.payroll_list[i])
        }
      }
      return data
    },
    filteredSupervisor() {
      return this.suggest.filter((option) => {
        return option.full_name
          .toLowerCase()
          .indexOf(this.printParams.certified_by_name.toLowerCase()) >= 0
      })
    },
    filterCertifiedA() {
      return this.suggest.filter((option) => {
        return option.full_name
          .toLowerCase()
          .indexOf(this.printParams.mors_signatory.a_certified.name.toLowerCase()) >= 0
      })
    },
    filterCertifiedB() {
      return this.suggest.filter((option) => {
        return option.full_name
          .toLowerCase()
          .indexOf(this.printParams.mors_signatory.b_certified.name.toLowerCase()) >= 0
      })
    },
    filterNoted() {
      return this.suggest.filter((option) => {
        return option.full_name
          .toLowerCase()
          .indexOf(this.printParams.mors_signatory.noted.name.toLowerCase()) >= 0
      })
    },
    filterDvCertifiedA() {
      return this.suggest.filter((option) => {
        return option.full_name
          .toLowerCase()
          .indexOf(this.printParams.dv_signatory.a_certified.name.toLowerCase()) >= 0
      })
    },
    filterDvCertifiedB() {
      return this.suggest.filter((option) => {
        return option.full_name
          .toLowerCase()
          .indexOf(this.printParams.dv_signatory.b_certified.name.toLowerCase()) >= 0
      })
    },
    filterDvNoted() {
      return this.suggest.filter((option) => {
        return option.full_name
          .toLowerCase()
          .indexOf(this.printParams.dv_signatory.noted.name.toLowerCase()) >= 0
      })
    },
    orderedDeductions: function () {
      return _.orderBy(this.deductions, 'code')
    },
    filteredGSISDeductions() {
      let deductions =  this.deductions.filter((deduction) => {
        return deduction.type=='GSIS LOANS' || deduction.type=='GSIS Other Deds.' || deduction.code=='GSIS Retro'
      })

      deductions.sort(function(a,b) {
        //console.log(a,b)
        if (a.code<b.code) return -1
        if (a.code>b.code) return 1
        return 0
      })

      return deductions
    },
    filteredPAGIBIGDeductions() {
      let deductions =  this.deductions.filter((deduction) => {
        return deduction.type=='Pagibig Group'
      })

      deductions.sort(function(a,b) {
        //console.log(a,b)
        if (a.code<b.code) return -1
        if (a.code>b.code) return 1
        return 0
      })

      return deductions
    },
    filteredOtherDeductions() {
      let deductions =  this.deductions.filter((deduction) => {
        return deduction.type=='Loan' || deduction.type=='other insurance'
      })

      deductions.sort(function(a,b) {
        //console.log(a,b)
        if (a.code<b.code) return -1
        if (a.code>b.code) return 1
        return 0
      })

      return deductions
    }
  },

  methods: {
    applyParameterDv(parameter) {
      var textArea = document.getElementById('ts')
      var startPos = textArea.selectionStart,
        endPos = textArea.selectionEnd,
        cursorPos = startPos,
        tmpStr = textArea.value;
      if (parameter === null) {
        return;
      }
      this.printParams.payroll_dv_particulars = tmpStr.substring(0,startPos) + "{" + parameter + "}" + tmpStr.substring(endPos,tmpStr.length);

      setTimeout(() => {
        cursorPos += parameter.length;
        textArea.selectionStart = textArea.selectionEnd = cursorPos;
      }, 10);
    },
    applyParameterMors(parameter) {
      var textArea = document.getElementById('ta')
      var startPos = textArea.selectionStart,
        endPos = textArea.selectionEnd,
        cursorPos = startPos,
        tmpStr = textArea.value;
      if (parameter === null) {
        return;
      }
      this.printParams.payroll_mors_particulars = tmpStr.substring(0,startPos) + "{" + parameter + "}" + tmpStr.substring(endPos,tmpStr.length);

      setTimeout(() => {
        cursorPos += parameter.length;
        textArea.selectionStart = textArea.selectionEnd = cursorPos;
      }, 10);
    },
    getFinalizeDates() {
      var params = {
        params: this.printParams
      }

      if (this.printParams.payroll_group!=4 && !this.params.includes('bank')){
        this.printParams.day = '';
      }
      if (this.printParams.is_finalize==1) {
        var config = Object.assign(this.config,params)
        this.isLoading=true
        this.$http.get(`${this.$host}/api/parameter/payroll/finalize_dates`,
          config,
        ).then((response) => {
          this.finalize_dates = response.data.data;
          this.isLoading=false
        }).catch((error) => {
          this.isLoading=false
        });
      }
    },
    setDay() {
      //console.log(this.printParams)
      this.getFinalizeDates()
      if (this.printParams.year && this.printParams.month) {
        let lastDay = moment(new Date(this.printParams.year, parseInt(this.printParams.month), 0)).format("DD")
        this.printParams.days = [15, Number(lastDay)]
      }
    },
    init() {
      this.isLoading = true
      this.setDay()
      this.getSources()
    },
    getSources() {
      this.$http.get(`${this.$host}/api/payroll/funding-source/show/`,
        this.config
      ).then((response) => {
        let data = response.data
        this.sources = data
        this.getEarnings()
      }).catch((error) => {
        this.isLoading = false
        //console.log(error)
      });
    },
    getEarnings() {
      this.$http.get(`${this.$host}/api/parameter/payroll/run`,
        this.config
      ).then((response) => {
        let data = response.data
        this.earnings = data.earnings
        this.deductions = data.deductions
        this.dateToday = data.date
        this.isLoading = false
      }).catch((error) => {
        this.isLoading = false
        //console.log(error)
      });
    },
    openPrintConfig(obj) {
      this.id = obj.id
      this.name = obj.name
      this.url = obj.url
      this.params = obj.params
      if (this.params.includes('printParams') || this.params.includes('year')) {
        this.printParams.year = this.dateToday.substr(0, 4)
        this.printConfig = this.dateToday.substr(0, 4)
      }
      this.isPrint = true
    },
    generateTextFile() {
      // let checkedRow = JSON.parse(this.printParams.checkedRows[0])
      //console.log(this.printParams.checkedRows[0])
      this.config.params = {
        payroll_group: this.printParams.payroll_group,
        payroll_subgroup: this.printParams.payroll_subgroup,
        month: this.printParams.month,
        day: this.printParams.day,
        year: this.printParams.year,
        payroll_type: this.printParams.payroll_type,
        payroll_earning: this.printParams.payroll_earning,
        funding_source: this.printParams.funding_source,
        bank: this.printParams.bank,
        finalize_date: this.printParams.finalize_date,
        is_finalize: this.printParams.is_finalize,
        checkedRows: this.printParams.checkedRows
      }
      this.responseType = 'blob'
      this.$http.get(`${this.$host}/api/report/payroll/bank/file/`,
        this.config
      ).then((response) => {
        var link = document.createElement("a");
        var now = new Date();
        link.download = 'bank_remmittance_' + now.getFullYear() + (now.getMonth() + 1).toString().padStart('0',2) + now.getDay().toString().padStart('0',2) + '.txt'
        link.href = response.data.url
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }).catch((error) => {
        //console.log(error);
      })
    },
    generateGSISRemmitanceReport() {
      this.isLoading = true

      let config = {
        headers: {'Authorization': "Bearer " + this.$store.state.token}
      };

      this.$http.post(`${this.$host}/api/report/generate/${this.$role.state.id}`,
        {params: this.printParams},
        config
      ).then((response) => {
        this.isLoading = false
        let token = response.data

        let url = `${this.$host}/api/report/gsis-remmittance-report/${token}`
        let win = window.open(url, '_blank')
        win.focus()
      }).catch((error) => {
        //console.log(error)
        this.isLoading = false
      });
    },
    generateGSISRetroReport() {
      this.isLoading = true

      let config = {
        headers: {'Authorization': "Bearer " + this.$store.state.token}
      };

      this.$http.post(`${this.$host}/api/report/generate/${this.$role.state.id}`,
        {params: this.printParams},
        config
      ).then((response) => {
        this.isLoading = false
        let token = response.data

        let url = `${this.$host}/api/report/gsis-retro-report/${token}`
        let win = window.open(url, '_blank')
        win.focus()
      }).catch((error) => {
        //console.log(error)
        this.isLoading = false
      });
    },
    generateSummaryOfClaimsReport() {
      this.isLoading = true

      let config = {
        headers: {'Authorization': "Bearer " + this.$store.state.token}
      };

      this.$http.post(`${this.$host}/api/report/generate/${this.$role.state.id}`,
        {params: this.printParams},
        config
      ).then((response) => {
        this.isLoading = false
        let token = response.data

        let url = `${this.$host}/api/report/summary-of-claims-xls/${token}`
        let win = window.open(url, '_blank')
        win.focus()
      }).catch((error) => {
        //console.log(error)
        this.isLoading = false
      });
    },
    print() {
      if (this.params.includes('old')) {
        let params = ''
        for (let i = 0; i < this.params.length; i++) {
          params += `${this.params[i]}=${this.printConfig[this.params[i]]}`
        }
        this.isLoading = true

        let config = {
          headers: {'Authorization': "Bearer " + this.$store.state.token}
        };

        this.$http.get(`${this.$host}/api/report/generate/${this.$role.state.id}`,
          config
        ).then((response) => {
          this.isLoading = false
          let token = response.data

          let url = `${this.$host}/api/report/${this.url}/${token}?${params}`
          let win = window.open(url, '_blank')
          win.focus()
        }).catch((error) => {
          //console.log(error)
          this.isLoading = false
        });
      }
      else if (this.params.includes('general_payroll')) {
        this.isLoading = true

        let config = {
          headers: {'Authorization': "Bearer " + this.$store.state.token}
        };

        this.$http.post(`${this.$host}/api/report/generate/${this.$role.state.id}`,
          {
            general_payroll: {
              payroll_group: this.printParams.payroll_group,
              month: this.printParams.month,
              day: this.printParams.day,
              year: this.printParams.year,
              payroll_type: this.printParams.payroll_type,
              voucher: this.printParams.voucher,
              voucher_salary: this.printParams.voucher_salary,
              payroll_earning: this.printParams.payroll_earning,
              payroll_mors_particulars: this.printParams.payroll_mors_particulars,
              payroll_dv_particulars: this.printParams.payroll_dv_particulars,
              payroll_subgroup: this.printParams.payroll_subgroup,
              funding_source: this.printParams.funding_source,
              certified_by_name: this.printParams.certified_by_name,
              certified_by_position: this.printParams.certified_by_position,
              previous_employer_name: this.printParams.previous_employer_name,
              previous_employer_address: this.printParams.previous_employer_address,
              previous_employer_tin: this.printParams.previous_employer_tin,
              previous_employer_zip: this.printParams.previous_employer_zip,
              username: this.$role.state.username,
              noted_by: this.printParams.noted_by,
              is_finalize: this.printParams.is_finalize,
              finalize_date: this.printParams.finalize_date,
              mors_signatory: this.printParams.mors_signatory,
              dv_signatory: this.printParams.dv_signatory,
              attachment: this.printParams.attachment,
              address: this.printParams.address,
              office: this.printParams.office,
              name_of_claimant: this.printParams.name_of_claimant
            }
          },
          config
        ).then((response) => {
          this.isLoading = false
          let token = response.data

          let url = `${this.$host}/api/report/${this.url}/${token}`
          let win = window.open(url, '_blank')
          win.focus()
        }).catch((error) => {
          //console.log(error)
          this.isLoading = false
        });
      }
      else {
        this.isLoading = true

        let config = {
          headers: {'Authorization': "Bearer " + this.$store.state.token}
        };

        if(this.url == 'index-of-payments-to-employees'){

          this.config = {
            headers: {'Authorization': "Bearer " + this.$store.state.token}
          };
          var ref_date = '';
          if (this.printParams.day=='')
            ref_date = `${this.printParams.year}-${this.printParams.month}-01`
          else
            ref_date = `${this.printParams.year}-${this.printParams.month}-${this.printParams.day}`
          let url
          let startDate
          let endDate
          if(this.printParams.specific_range == 0){
            startDate = `${this.printParams.start_year}-${this.printParams.start_month}-${this.printParams.start_day}`
            endDate = `${this.printParams.end_year}-${this.printParams.end_month}-${this.printParams.end_day}`
          }

          url = `${this.$host}/api/payroll/list/?report=${this.url}&payroll_type=${this.printParams.payroll_type}&ref_date=${ref_date}&earnings=${this.printParams.payroll_earning}&voucher=${this.printParams.voucher}&voucher_salary=${this.printParams.voucher_salary}&payroll_group=${this.printParams.payroll_group}&funding_source=${this.printParams.funding_source}&payroll_subgroup=${this.printParams.payroll_subgroup}&is_finalize=${this.printParams.is_finalize}&finalize_date=${this.printParams.finalize_date}&specific_range=${this.printParams.specific_range}&year=${this.printParams.year}&start_date=${startDate}&end_date=${endDate}`

          let length = this.printParams.checkedRows.length

          if(length < 1){
            this.$http.get(url,
              this.config
            ).then((response) => {
              let data = response.data
              if(length < 1){
                this.printParams.checkedRows = data
              }
              //
              this.$http.post(`${this.$host}/api/report/generate/${this.$role.state.id}`,
                {params: this.printParams},
                config
              ).then((response) => {
                this.isLoading = false
                let token = response.data.replace(/\s/g, "")
                let link = `${this.$host}/api/report/${this.url}/${token}`
                let win = window.open(link, '_blank')
                // window.open(link, '_blank')
                if(win){
                  //console.log(true)
                  win.focus()
                }else{
                  //console.log(false)
                  var timer = window.setTimeout( function(){ if(win)win.focus(); }, 100 );
                  timer
                }
                // win.focus()
              }).catch((error) => {
                //console.log(error)
                this.isLoading = false
              });
              //
            }).catch((error) => {
              this.isLoading = false
              //console.log(error)
            });
          }else{
            this.$http.post(`${this.$host}/api/report/generate/${this.$role.state.id}`,
              {params: this.printParams},
              config
            ).then((response) => {
              this.isLoading = false
              let token = response.data.replace(/\s/g, "")
              let link = `${this.$host}/api/report/${this.url}/${token}`
              let win = window.open(link, '_blank')
              // window.open(link, '_blank')
              if(win){
                //console.log(true)
                win.focus()
              }else{
                //console.log(false)
                var timer = window.setTimeout( function(){ if(win)win.focus(); }, 100 );
                timer
              }
              // win.focus()
            }).catch((error) => {
              //console.log(error)
              this.isLoading = false
            });
          }
        }else{
          this.$http.post(`${this.$host}/api/report/generate/${this.$role.state.id}`,
              {params: this.printParams},
              config
            ).then((response) => {
              this.isLoading = false
              let token = response.data

              let url = `${this.$host}/api/report/${this.url}/${token}`
              let win = window.open(url, '_blank')
              win.focus()
            }).catch((error) => {
              //console.log(error)
              this.isLoading = false
            });
        }

      }
    },
    closePrint() {
      this.isPrint = false
      this.printConfig = {}
      this.printParams = {
        bonus_type: null,
        earning: null,
        deduction: null,
        year: new Date().getFullYear(),
        month: (new Date().getMonth() + 1).toString().padStart(2,'0'),
        day: '',
        is_finalize: 0,
        finalize_date: null,
        bank: '',
        certified_by_name: 'MA. JESUSA PADILLA MENDOZA',
        certified_by_position: 'Chief Administrative Officer',
        previous_employer_name: '',
        previous_employer_address: '',
        previous_employer_tin: '',
        previous_employer_zip: '',
        payroll_type: 'Regular',
        quarter: '',
        payroll_group: '',
        payroll_subgroup: '',
        funding_source: '',
        mors_signatory: {
          a_certified: {
            name: '',
            position: '',
            division: ''
          },
          b_certified: {
            name: 'PEÑAFRANCIA MORALES ALZONA',
            position: 'OIC Chief',
            division: 'BUDGET DIVISION'
          },
          noted: {
            name: 'CECILIA EVARISTO BIEN',
            position: 'OIC Director',
            division: 'Office of the Director for Finance and Management Service'
          },
        },
        dv_signatory: {
          a_certified: {
            name: '',
            position: '',
            division: ''
          },
          b_certified: {
            name: 'EDNA CACDAC TAPAR',
            position: 'Chief Accountant',
            division: 'Accounting Division'
          },
          noted: {
            name: '',
            position: '',
            division: ''
          },
        },
        prepared_by: {
          name: '',
          position: ''
        },
        checked_by: {
          name: '',
          position: ''
        },
        noted_by: {
          name: '',
          position: ''
        },
        attachment: [],
        checkedRows: [],
      },
      this.employee_name = null
    },
    close() {
      this.isSearch = false
    },
    removeSelected(emp) {
      this.printParams.checkedRows = this.printParams.checkedRows.filter(x => x !== emp)
    },
    selectEmployee() {
      this.getEmployees();
      this.isSelect = true
    },



    getEmployees() {
      this.isLoading = true

      this.config = {
        headers: {'Authorization': "Bearer " + this.$store.state.token}
      };

      var ref_date = '';

      if (this.printParams.day=='')
        ref_date = `${this.printParams.year}-${this.printParams.month}-01`
      else
        ref_date = `${this.printParams.year}-${this.printParams.month}-${this.printParams.day}`

      let lastDay = moment(new Date(this.end_date.year, parseInt(this.end_date.month), 0)).format("DD")
      let start_date =  `${this.start_date.year}-${this.start_date.month}-01`
      let end_date = `${this.end_date.year}-${this.end_date.month}-${lastDay}`
      let url

      let startDate
      let endDate
      if(this.printParams.specific_range == 0){
        startDate = `${this.printParams.start_year}-${this.printParams.start_month}-${this.printParams.start_day}`
        endDate = `${this.printParams.end_year}-${this.printParams.end_month}-${this.printParams.end_day}`
      }

      if (this.printParams.payroll_earning == 2) {
        url = `${this.$host}/api/payroll/list/?report=${this.url}&payroll_type=${this.printParams.payroll_type}&ref_date=${ref_date}&earnings=${this.printParams.payroll_earning}&voucher=${this.printParams.voucher}&voucher_salary=${this.printParams.voucher_salary}&payroll_group=${this.printParams.payroll_group}&funding_source=${this.printParams.funding_source}&status=${this.printParams.payroll_subgroup}&start_date=${start_date}&end_date=${end_date}`
      } else if(this.url == 'landbank') {
        url = `${this.$host}/api/payroll/list/?report=${this.url}&payroll_type=${this.printParams.payroll_type}&ref_date=${ref_date}&earnings=${this.printParams.payroll_earning}&voucher=${this.printParams.voucher}&voucher_salary=${this.printParams.voucher_salary}&payroll_group=${this.printParams.payroll_group}&funding_source=${this.printParams.funding_source}&payroll_subgroup=${this.printParams.payroll_subgroup}&is_finalize=${this.printParams.is_finalize}&finalize_date=${this.printParams.finalize_date}&bank=${this.printParams.bank}`
      }else {
        url = `${this.$host}/api/payroll/list/?report=${this.url}&payroll_type=${this.printParams.payroll_type}&ref_date=${ref_date}&earnings=${this.printParams.payroll_earning}&voucher=${this.printParams.voucher}&voucher_salary=${this.printParams.voucher_salary}&payroll_group=${this.printParams.payroll_group}&funding_source=${this.printParams.funding_source}&payroll_subgroup=${this.printParams.payroll_subgroup}&is_finalize=${this.printParams.is_finalize}&finalize_date=${this.printParams.finalize_date}&specific_range=${this.printParams.specific_range}&year=${this.printParams.year}&start_date=${startDate}&end_date=${endDate}`
      }
      this.$http.get(url,
        this.config
      ).then((response) => {
        let data = response.data
        this.printParams.checkedRows = []
        this.employees = data
        if (this.printParams.payroll_earning == 4 || this.printParams.payroll_earning == 16) {
          this.employees = this.employees.map(x => ({...x, checkbox_0: false, checkbox_1: false}))
        }
        this.isLoading = false
      }).catch((error) => {
        this.isLoading = false
        //console.log(error)
      });
    },
    view_employee(selected) {
      this.payroll_setting_id = selected.payroll_setting_id
      this.employee_id = selected.id
      //this.employment_status = selected.employment_status
      if (selected.name_extension == null) {
        this.employee_name = `${selected.first_name} ${selected.last_name}`
      //   //this.isSearch = false
      }
      else {
        this.employee_name = `${selected.first_name} ${selected.last_name} ${selected.name_extension}`
      }
      if (this.payroll_setting_id != undefined && this.employee_id != undefined) {
        this.isSearch = false
      }
      this.printParams.employee_id = selected.id
      //this.isSearch = false
      //this.close()
    },

    selectMors() {
      this.isMorsSignatory = true;
    },
    doneMors() {
      this.isMorsSignatory = false;
    },

    selectDv() {
      this.isDvSignatory = true;
    },
    doneDv() {
      this.isDvSignatory = false;
    },
    select() {
      // if (this.settings.earning == 4 || this.settings.earning == 16) {
      //   this.printParams.checkedRows = this.employees.filter(x => x.is_0 === true || x.is_1 === true)
      // }
      //console.log(this.printParams.checkedRows)
      this.isSelect = false
    },
  }
}
</script>

<style>

</style>
