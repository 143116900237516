<template>
  <nav class="navbar is-fixed-top has-shadow is-10 has-background-primary" role="navigation" aria-label="main navigation">
    <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
    <div class="navbar-brand">
      <a role="button" class="navbar-burger burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample"
      v-on:click.prevent="showNav = !showNav" v-bind:class="{ 'is-active' : showNav }">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div id="navbarBasicExample" class="navbar-menu" v-bind:class="{ 'is-active' : showNav }">
      <div class="navbar-start">
        <!-- <span class="navbar-item role has-text-white-ter is-hidden-touch">{{ this.$role.state.role }}</span> -->
        <span class="navbar-item role is-hidden-desktop" style="padding-left: 10px;">{{ fullName }} - {{ this.$role.state.role }}</span>
        <hr class="dropdown-divider">
      </div>

      <div class="navbar-end">
        <router-link to="/home" @click.native="showNav = false" active-class="has-text-primary" class="navbar-item is-hidden-desktop">
          Home
        </router-link>
        <router-link to="/employees" @click.native="showNav = false" active-class="has-text-primary" class="navbar-item is-hidden-desktop"
        v-if="$role.state.permissions['search employee']">
          Employee
        </router-link>
        <router-link to="/service-record" @click.native="showNav = false" active-class="has-text-primary" class="navbar-item is-hidden-desktop"
        v-if="$role.state.permissions['search service record']">
          History
        </router-link>
        <router-link to="/schedule" @click.native="showNav = false" active-class="has-text-primary" class="navbar-item is-hidden-desktop"
        v-if="$role.state.permissions['read daily time record'] || $role.state.permissions['search daily time record'] || $role.state.permissions['write daily time record'] ">
          Schedule
        </router-link>
        <router-link to="/requests" @click.native="showNav = false" active-class="has-text-primary" class="navbar-item is-hidden-desktop"
        v-if="$role.state.permissions['apply request']">
          Requests
        </router-link>
        <router-link to="/position" @click.native="showNav = false" active-class="has-text-primary" class="navbar-item is-hidden-desktop"
        v-if="$role.state.permissions['search position']">
          Positions
        </router-link>
        <router-link to="/trainings" @click.native="showNav = false" active-class="has-text-primary" class="navbar-item is-hidden-desktop"
        v-if="$role.state.permissions['read training']">
          Trainings
        </router-link>
        <router-link to="/appraisals" @click.native="showNav = false" active-class="has-text-primary" class="navbar-item is-hidden-desktop"
        v-if="$role.state.permissions['search appraisal']">
          Appraisals
        </router-link>
        <router-link to="/relations" @click.native="showNav = false" active-class="has-text-primary" class="navbar-item is-hidden-desktop"
        v-if="$role.state.permissions['search award'] || $role.state.permissions['search offense']">
          Relations
        </router-link>
        <router-link to="/payroll" @click.native="showNav = false" active-class="has-text-primary" class="navbar-item is-hidden-desktop"
        v-if="$role.state.permissions['read payroll'] || $role.state.permissions['read payroll funding source']">
          Payroll
        </router-link>
        <router-link to="/users" @click.native="showNav = false" active-class="has-text-primary" class="navbar-item is-hidden-desktop"
        v-if="$role.state.permissions['search user']">
          Users
        </router-link>
        <router-link to="/reports" @click.native="showNav = false" active-class="has-text-primary" class="navbar-item is-hidden-desktop"
        v-if="$role.state.permissions['print employee'] || $role.state.permissions['print position'] || $role.state.permissions['print salary grade'] ||
        $role.state.permissions['print security'] || $role.state.permissions['print service record'] || $role.state.permissions['print special dates'] ||
        $role.state.permissions['print activity log'] || $role.state.permissions['print time log'] || $role.state.permissions['print daily time record'] ||
        $role.state.permissions['print user'] || $role.state.permissions['print work schedule template']">
          Reports
        </router-link>
        <router-link to="/settings" @click.native="showNav = false" active-class="has-text-primary" class="navbar-item is-hidden-desktop"
        v-if="($role.state.permissions['write service record'] || $role.state.permissions['write daily time record'] || $role.state.permissions['write employee'] || $role.state.permissions['write payroll'] || $role.state.permissions['write payroll funding source'])">
          Settings
        </router-link>
        <!-- <router-link to="/logs" @click.native="showNav = false" active-class="has-text-primary" class="navbar-item is-hidden-desktop">
          Logs
        </router-link> -->
        <hr class="dropdown-divider">
        <span class="navbar-item has-text-white-ter is-hidden-touch" style="text-transform: uppercase !important;">{{ fullName }}</span>
        <b-dropdown position="is-bottom-left" class="is-hidden-touch">
          <a class="navbar-item has-text-white-ter has-background-primary cog-settings" slot="trigger">
            <!-- <span>Menu</span> -->
            <i class="fas fa-ellipsis-v"></i>
          </a>
          <!-- <b-dropdown-item value="changepass">
            <router-link to="/changepass" class="has-text-black" active-class="">
              <i class="fas fa-unlock-alt has-text-primary"></i>
              &nbsp;Change Password
            </router-link>
          </b-dropdown-item>
          <hr class="dropdown-divider"> -->
          <b-dropdown-item value="logout" @click="logout()">
            <span class="has-text-black">
              <i class="fas fa-sign-out-alt has-text-primary"></i>
              &nbsp;<b>LOGOUT</b>
            </span>
          </b-dropdown-item>
        </b-dropdown>

        <div class="navbar-item has-dropdown is-hoverable is-hidden-desktop">
          <!-- <router-link to="/changepass" class="navbar-item" active-class="">
            <i class="fas fa-unlock-alt has-text-primary"></i>
            &nbsp;Change Password
          </router-link> -->
          <hr class="navbar-divider">
          <a @click.prevent="logout()" class="navbar-item">
            <i class="fas fa-sign-out-alt has-text-primary"></i>
            &nbsp;<b>LOGOUT</b>
          </a>
        </div>

      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {
      showNav: false,
      isLoading: false,
      fullName: '',
      role: ''
    }
  },
  mounted() {
    let config = {
      headers: {'Authorization': "Bearer " + this.$store.state.token}
    };

    this.$http.get(`${this.$host}/api/user`,
      config
    ).then((response) => {
      let data = response.data.data.employee
      let record = response.data.data;
      window.fcWidget.setExternalId(record.employee.employee_number);
      window.fcWidget.user.setFirstName(record.employee.first_name);
      window.fcWidget.user.setLastName(record.employee.last_name);
      window.fcWidget.user.setEmail(record.employee.email);
      window.fcWidget.user.setProperties({
        employee_number: record.employee.employee_number,
        position: record.servicerecord.position,
        office: record.servicerecord.division,
        status_of_engagement: record.servicerecord.employment_status,
        deployment_location: record.employee.group.code
      });
      // //console.log(window.fcWidget);
      let fullName
      if (data.name_extension == "null" || data.name_extension == null) {
        fullName = `${data.first_name} ${data.last_name}`
      }
      else {
        fullName = `${data.first_name} ${data.last_name} ${data.name_extension}`
      }
      this.fullName = fullName
      let currentDate = new Date()
      let lastDayOfTheMonth = new Date(currentDate.getFullYear(), currentDate.getMonth()+1, 0)
      if (currentDate.getDate() == lastDayOfTheMonth.getDate() || currentDate.getDate() < 10) {
        this.$snackbar.open({
          duration: 10000,
          message: 'REMINDER: Check your DTR for the current month. Changes after the end of the month are not allowed.',
          type: 'is-success',
          position: 'is-bottom-right',
          actionText: 'Go',
          queue: false,
          onAction: () => { this.$router.push({ path: 'schedule' }) }
        })
      }
      // if (response.data.data.is_verify == 1) {
      //   this.$snackbar.open({
      //     duration: 10000,
      //     message: 'REMINDER: Check your DTR for if changes are needed before the 10th day of the next month comes.',
      //     type: 'is-success',
      //     position: 'is-bottom-right',
      //     actionText: 'Go',
      //     queue: false,
      //     onAction: () => { this.$router.push({ path: 'schedule' }) }
      //   })
      //   this.$editmode.commit('setVerify', true)
      // }
      // else {
      //   this.$editmode.commit('setVerify', false)
      // }
      // this.role = response.data.data.user.roles
    }).catch((error) => {
      //console.log(error)
    });

    this.role = this.$role.state.role
  },
  methods: {
    logout() {
      if (this.$editmode.state.data) {
        this.$dialog.confirm({
          title: 'Discard Changes',
          message: 'Are you sure you want to <b>discard</b> your changes? This action cannot be undone.',
          confirmText: 'Discard',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: () => { this.$editmode.commit('editing', false), this.theLogout() }
        })
      }
      else {
        this.theLogout()
      }
    },
    theLogout() {
      this.$editmode.commit('setShouldChange', false)
      this.isLoading = true
      // this.$http.post(`${this.$host}/api/logout`,
      //   data,
      //   config,
      // ).then((response) => {
      //     setTimeout(() => {
      //       this.isLoading = false
      //       this.role = ''
      //       this.$role.commit('clearRole')
      //       this.$store.commit('clearToken')
      //       this.$router.push('/')
      //       window.location.reload()
      //       this.$emit('timeout')
      //     }, 1 * 1000)
      // }).catch((error) => {

      // });

      setTimeout(() => {
        this.isLoading = false
        this.role = ''
        this.$role.commit('clearRole')
        this.$store.commit('clearToken')
        this.$router.push('/')
        // this.$toast.open({
        //   message: 'Successfully logged out.',
        //   type: 'is-success',
        //   position: 'is-bottom-right',
        //   duration: 2000
        // })
        window.location.reload()
        this.$emit('timeout')
      }, 1 * 1000)
    }
  }
}
</script>

<style scoped>
.cog-settings {
  transition: all 0.3s;
}

.cog-settings:hover {
  background-color: transparent !important;
  color: #a7a7a7 !important;
}

.navbar {
  box-shadow: 0px 0px 8px #212121 !important;
  z-index: 90000 !important;
}

.role {
   padding-left: 270px;
   font-size: 25px;
}

@media screen and (min-width: 1px) and (max-width: 768px)  {
  .role {
    padding-left: 12px;
  }
}
</style>
