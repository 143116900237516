<template>
  <div>
    <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
    <div class="columns header" style="margin-bottom: 0 !important;">
      <div class="column is-5" style="margin-left: 5px; font-size: 20px; text-transform: uppercase;">
        <b>RELATIONS</b> {{ fullName }}
      </div>
      <div class="column is-auto">
        <button class="button is-primary is-hidden-tablet" style="margin-left: 10px;" @click.prevent="isSearch = true"
        v-if="$role.state.permissions['search award'] || $role.state.permissions['search offense']">
          <i class="fa fa-search"></i> &nbsp;Search Employee
        </button>
        <button class="button is-primary is-pulled-right is-hidden-mobile" style="margin-left: 10px;" @click.prevent="isSearch = true"
        v-if="$role.state.permissions['search award'] || $role.state.permissions['search offense']">
          <i class="fa fa-search"></i> &nbsp;Search Employee
        </button>

        <button class="button is-primary is-hidden-tablet" style="margin-left: 10px;" @click.prevent="() => { isPrint = true, printType = 'awards'}"
          v-if="$role.state.permissions['print award']">
          <i class="fa fa-print"></i> &nbsp;Print Awards
        </button>
        <button class="button is-primary is-hidden-tablet" style="margin-left: 10px;" @click.prevent="() => { isPrint = true,  printType = 'offenses'}"
          v-if="$role.state.permissions['print offense']">
          <i class="fa fa-print"></i> &nbsp;Print Offenses
        </button>
        <button class="button is-primary is-pulled-right is-hidden-mobile" style="margin-left: 10px;" @click.prevent="() => { isPrint = true, printType = 'offenses'}"
          v-if="$role.state.permissions['print offense']">
          <i class="fa fa-print"></i> &nbsp;Print Offenses
        </button>
        <button class="button is-primary is-pulled-right is-hidden-mobile" style="margin-left: 10px;" @click.prevent="() => { isPrint = true, printType = 'awards'}"
          v-if="$role.state.permissions['print award']">
          <i class="fa fa-print"></i> &nbsp;Print Awards
        </button>
      </div>
    </div>
    <hr style="margin-top: 0 !important; margin-bottom: 0 !important;" class="has-background-grey-lighter is-hidden-mobile">
    <br>
    <div class="box" v-if="employee_id && $role.state.permissions['search award']">
      <Awards :employee_id="employee_id" :awards="awards" @update="getData()" />
    </div>
    <div class="box" v-if="employee_id && $role.state.permissions['search offense']">
      <Offense :employee_id="employee_id" :offenses="offenses" @update="getData()" />
    </div>
    <transition name="fade">
      <EmployeeSearch search-title="Employee" @close="close" @view="view_employee" v-if="isSearch" />
    </transition>

    <b-modal :active.sync="isPrint" has-modal-card :canCancel=false>
      <transition name="fade">
        <form action="">
          <div class="modal-card" style="height: 35.625rem; margin-top: 70px !important;">
            <header class="modal-card-head">
              <p class="modal-card-title">Signature Field</p>
            </header>
            <section class="modal-card-body">
              <b-field label="Prepared By">
                <b-autocomplete
                  v-model="prepared_by"
                  :data="filteredName1"
                  field="full_name"
                  @select="option => {position_1 = option.position}"
                  :open-on-focus=false expanded>
                </b-autocomplete>
              </b-field>
              <b-field label="Position">
                <b-input
                  v-model="position_1"
                  expanded>
                </b-input>
              </b-field>
              <b-field label="Noted By">
                <b-autocomplete
                  v-model="noted_by"
                  :data="filteredName2"
                  field="full_name"
                  @select="option => {position_2 = option.position}"
                  :open-on-focus=false expanded>
                </b-autocomplete>
              </b-field>
              <b-field label="Position">
                <b-input
                  v-model="position_2"
                  expanded>
                </b-input>
              </b-field>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" @click.prevent="print()">Print</button>
              <button class="button is-danger" type="button" @click.prevent="isPrint=false">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>
  </div>
</template>

<script>
import EmployeeSearch from '../components/SearchModal.vue'
import Awards from '../components/relations/Awards.vue'
import Offense from '../components/relations/Offense.vue'

export default {
  components: {
    EmployeeSearch,
    Awards,
    Offense
  },
  data() {
    return {
      isLoading: false,
      isSearch: true,
      config: '',
      employee_id: null,
      fullName: null,
      awards: [],
      offenses: [],
      printType: null,
      prepared_by: '',
      noted_by: '',
      position_1: '',
      position_2: '',
      suggest: [],
      isPrint: false
    }
  },
  mounted() {
    this.config = {
      headers: {'Authorization': "Bearer " + this.$store.state.token}
    };

    this.getSuggest()
  },
  computed: {
    filteredName1() {
      return this.suggest.filter((option) => {
        return option.full_name
          .toString()
          .toLowerCase()
          .indexOf(this.prepared_by.toLowerCase()) >= 0
      })
    },
    filteredName2() {
      return this.suggest.filter((option) => {
        return option.full_name
          .toString()
          .toLowerCase()
          .indexOf(this.noted_by.toLowerCase()) >= 0
      })
    }
  },
  methods: {
    getSuggest() {
        this.$http.get(`${this.$host}/api/parameter/service_record/employee`,
        this.config
      ).then((response) => {
        let data = response.data
        this.suggest = data

        let i = 0
        for (i in this.suggest) {
          this.suggest[i].full_name = this.suggest[i].full_name.toUpperCase()
        }
      }).catch((error) => {
        //console.log(error)
      });
    },
    getData() {
      this.isLoading = true

      this.$http.get(`${this.$host}/api/award/search/${this.employee_id}`,
        this.config
      ).then((response) => {
        let data = response.data
        this.awards = data

        this.$http.get(`${this.$host}/api/offense/search/${this.employee_id}`,
          this.config
        ).then((response) => {
          let data = response.data
          this.offenses = data
          this.isLoading = false
        }).catch((error) => {
          //console.log(error)
          this.isLoading = false
        });
      }).catch((error) => {
        //console.log(error)
        this.isLoading = false
      });
    },
    close() {
      if (this.employee_id) {
        this.isSearch = false
      }
    },
    view_employee(selected) {
      this.employee_id = selected.id
      if (selected.name_extension == null) {
        this.fullName = `${selected.first_name} ${selected.last_name}`
      }
      else {
        this.fullName = `${selected.first_name} ${selected.last_name} ${selected.name_extension}`
      }
      if (this.employee_id != undefined) {
        this.isSearch = false
      }
      this.getData()
    },
    print() {
      this.isLoading = true

      this.$http.get(`${this.$host}/api/report/generate/${this.$role.state.id}`,
        this.config
      ).then((response) => {
        this.isLoading = false
        let token = response.data

        let url = `${this.$host}/api/report/${this.printType}/${token}?prepared_by=${this.prepared_by}&position_1=${this.position_1}&noted_by=${this.prepared_by}&position_2=${this.position_2}`
        let win = window.open(url, '_blank')
        win.focus()
        this.isPrint = false
      }).catch((error) => {
        //console.log(error)
        this.isLoading = false
      });
    }
  }
}
</script>

<style>

</style>
