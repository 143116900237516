<template>
  <div class="box">
    <div>
      <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
      <button class="button is-primary" @click.prevent="isPrint = true">
        <span><i class="fa fa-print"></i> Print</span>
      </button>
    </div>
    <b-modal :active.sync="isPrint" has-modal-card :canCancel=false>
      <transition name="fade">
        <form action="">
          <div class="modal-card" style="height: 35.625rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">Report Parameter List</p>
            </header>
            <section class="modal-card-body">
              <b-field label="Division">
                <b-select v-model="division">
                  <option value="all">All</option>
                  <option
                    v-for="division in divisions"
                    :value="division.id"
                    :key="division.id"
                  >
                    {{division.name}}
                  </option>
                </b-select>
              </b-field>
              <b-field label="Date From">
                <b-datepicker
                  placeholder="Date From"
                  v-model="date_from"
                  icon="calendar-today"
                >
                </b-datepicker>
              </b-field>
              <b-field label="Date To">
                <b-datepicker
                  placeholder="Date To"
                  v-model="date_to"
                  icon="calendar-today"
                >
                </b-datepicker>
              </b-field>
              <br><br>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" @click.prevent="print()">Print</button>
              <button class="button is-danger" type="button" @click.prevent="isPrint=false">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      search_query: '',
      division: '',
      isPrint: false,
      divisions: [],
      date_from: new Date(),
      date_to: new Date()
    }
  },
  mounted() {
    let config = {
      headers: {'Authorization': "Bearer " + this.$store.state.token}
    };

    this.$http.get(`${this.$host}/api/parameter/division`,
      config
    ).then((response) => {
      //console.log(response.data);
      let data = response.data
      this.divisions = data
    }).catch((error) => {
      //console.log(error)
    });
  },
  methods: {
    print() {
      this.isLoading = true

      let config = {
        headers: {'Authorization': "Bearer " + this.$store.state.token}
      };

      this.$http.get(`${this.$host}/api/report/generate/${this.$role.state.id}`,
        config
      ).then((response) => {
        this.isLoading = false
        let token = response.data

        let url = `${this.$host}/api/report/negative-vl-sl/${token}?division=${this.division}&date_from=${this.date_from.toLocaleDateString()}&date_to=${this.date_to.toLocaleDateString()}`
        let win = window.open(url, '_blank')
        win.focus()
      }).catch((error) => {
        //console.log(error)
        this.isLoading = false
      });
    }
  }
}
</script>

<style>

</style>
