<template>
  <div>
    <div class="columns header" style="margin-bottom: 0 !important;">
      <div class="column is-8" style="margin-left: 5px; font-size: 20px;">
        <b>{{ selected.username }}</b>
      </div>
      <div class="column is-auto">
        <button v-if="$role.state.permissions['search user']" class="button is-primary is-pulled-right" style="margin-left: 10px;" @click.prevent="searchPrompt()">
          <i class="fa fa-user"></i> &nbsp;Users
        </button>
        <router-link v-if="$role.state.permissions['write user']" to="/users/add" class="button is-success is-pulled-right" @click.prevent="add()">
          <i class="fa fa-plus"></i> &nbsp;New User
        </router-link>
      </div>
    </div>
    <hr style="margin-top: 0 !important; margin-bottom: 0 !important;" class="has-background-grey-lighter">
    <br>
    <transition name="fade">
      <User :user="selected" v-if="!isSearch" :isAdd="isAdd" @reset="setUser" :currentUsername="currentUsername" :currentEmail="currentEmail"/>
      <Search :data="data" :searchLoad="searchLoad" @view="getUser" v-if="isSearch" @close="close"/>
    </transition>
  </div>
</template>

<script>
import Search from '../components/users/Search.vue'
import User from '../components/users/User.vue'

export default {
  components: {
    Search,
    User
  },
  data() {
    return {
      isSearch: true,
      data: {},
      searchLoad: true,
      selected: {},
      isAdd: false,
      currentUsername: null,
      currentEmail: null
    }
  },
  beforeMount() {
    if (this.$editmode.state.fromUserAdd) {
      this.setUser(this.$editmode.state.newUserId)
      this.$editmode.commit('setUserAdd', false)
      this.isSearch = false
    }
    let config = {
      headers: {'Authorization': "Bearer " + this.$store.state.token}
    };

    this.$http.get(`${this.$host}/api/user/search`,
      config
    ).then((response) => {
      let data = response.data.data
      this.data = data
      this.searchLoad = false
    }).catch((error) => {
      //console.log(error)
    });

  },
  methods: {
    searchPrompt() {
      if (this.$editmode.state.data == true) {
        this.$dialog.confirm({
          title: 'Discard Changes',
          message: 'Are you sure you want to <b>discard</b> your changes? This action cannot be undone.',
          confirmText: 'Discard',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: () => this.search()
        })
      }
      else {
        this.search()
      }
    },
    search() {
      this.$editmode.commit('editing', false)
      this.isSearch = true
      this.searchLoad = true
      let config = {
        headers: {'Authorization': "Bearer " + this.$store.state.token}
      };

      this.$http.get(`${this.$host}/api/user/search`,
        config
      ).then((response) => {
        let data = response.data.data
        this.data = data
        this.searchLoad = false
      }).catch((error) => {
        this.searchLoad = false
        //console.log(error)
      });
    },
    close() {
      this.isSearch = false
    },
    getUser(obj) {
      if (obj.id) {
        this.setUser(obj.id)
        this.isSearch = false
      }
    },
    setUser(id) {
      let config = {
        headers: {'Authorization': "Bearer " + this.$store.state.token}
      };

      this.$http.get(`${this.$host}/api/user/search/${id}`,
        config
      ).then((response) => {
        let data = response.data.data
        this.selected = data
        this.currentUsername = data.username
        this.currentEmail = data.email
        this.searchLoad = false
        this.isSearch = false
      }).catch((error) => {
        //console.log(error)
      });
    }
  }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
