<template>
  <div>
  <section class="columns is-fullheight">
    <!-- TODO(LAST): MOBILE ARRANGEMENT
      desktop photo position right
    <div class="container column is-auto is-hidden-mobile">
      <HomeInformation />
    </div>
    <div class="container column is-3 is-hidden-mobile">
      <Photo />  
    </div>

    mobile photo position left (first to display)
    <div class="container column is-3 is-hidden-tablet">
      <Photo />  
    </div>
    <div class="container column is-auto is-hidden-tablet">
    </div> -->

    <HomeInformation />
    <div class="container column is-3">
      <Photo />
      <ActionPane />
    </div>
  </section>

  <section class="columns is-fullheight">
    <!-- <div class="container column is-8">
      <div class="card">
        <div class="card-header"><p class="card-header-title">example chart</p></div>
        <div class="card-content"><div class="content"></div></div>
      </div>
      <br />
    </div>
    <div class="container column is-4">
      <Dashboard header="Number of Employees"/>
    </div> -->
  </section>
  </div>
</template>

<script>
import Dashboard from '../components/Dashboard.vue'
import Photo from '../components/Photo.vue'
import ActionPane from '../components/ActionPane.vue'
import HomeInformation from '../components/HomeInformation.vue'
import EditInformation from '../components/EditInformation.vue'

export default {
  name: 'home',
  components: {
    Dashboard,
    Photo,
    ActionPane,
    HomeInformation,
    EditInformation
  }
}
</script>
