<template>
  <div class="box">
    <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
    <ImportComponent v-if="$role.state.permissions['import template']" />
    <hr>
    <b-table
      v-if="$role.state.permissions['export template']"
      :data="templates"
      :striped=true
      :bordered=true
      :hoverable=false>
      <template slot-scope="props">
        <b-table-column label="Code">
          {{ props.row.code }}
        </b-table-column>
        <b-table-column label="Description">
          {{ props.row.description }}
        </b-table-column>
        <b-table-column label="Download">
          <button class="button btn-action is-primary is-small" @click.prevent="downloadTemplate(props.row.id)"><i class="fa fa-download"></i></button>
        </b-table-column>
      </template>
      <template slot="empty">
        <b-table-column label="Code">
        </b-table-column>
        <b-table-column label="Description">
        </b-table-column>
        <b-table-column label="Download">
        </b-table-column>
      </template>
    </b-table>

    <b-modal :active.sync="isDownload" has-modal-card :canCancel=false>
      <transition name="fade">
        <form @submit.prevent="download()">
          <div class="modal-card pos" style="height: 35.625rem; margin-left: 100px;">
            <header class="modal-card-head">
              <p class="modal-card-title">Download Template</p>
            </header>
            <section class="modal-card-body">
              <!-- <b-datepicker
                placeholder="reference date"
                icon="calendar-today"
                v-model="ref_date"
                :date-formatter="d => d.toLocaleDateString('en-GB')"
                :date-parser="d => parseDate(d)"
                required
                editable>
              </b-datepicker> -->
          <b-field label="Year">
            <b-input v-model="dateSelected.year" type="number" min="1800" max="2100" expanded required>
            </b-input>
          </b-field>
          <b-field label="Month">
            <b-select v-model="dateSelected.month" expanded required>
              <option value="01">January</option>
              <option value="02">February</option>
              <option value="03">April</option>
              <option value="04">March</option>
              <option value="05">May</option>
              <option value="06">June</option>
              <option value="07">July</option>
              <option value="08">August</option>
              <option value="09">September</option>
              <option value="10">October</option>
              <option value="11">November</option>
              <option value="12">December</option>
            </b-select>
          </b-field>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" type="submit">Download</button>
              <button class="button is-danger" type="button" @click.prevent="closeDownload()">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment'
import ImportComponent from './Import.vue'

export default {
  components: {
    ImportComponent
  },
  data() {
    return {
      isLoading: false,
      templates: [],
      isDownload: false,
      download_id: null,
      ref_date: null,
      dateSelected: {},
    }
  },
  mounted() {
    this.config = {
      headers: {'Authorization': "Bearer " + this.$store.state.token}
    };

    this.getTemplates()
  },
  methods: {
    parseDate(d) {
      let parts = d.split("/")
      let dt = new Date(parseInt(parts[2], 10),
        parseInt(parts[1], 10) - 1,
        parseInt(parts[0], 10))
      return dt
    },
    getTemplates() {
      this.isLoading = true

      this.$http.get(`${this.$host}/api/parameter/payroll/template`,
        this.config
      ).then((response) => {
        let data = response.data
        this.templates = data
        this.isLoading = false
      }).catch((error) => {
        this.isLoading = false
        //console.log(error)
      });
    },
    downloadTemplate(id) {
      this.download_id = id
      this.isDownload = true
    },
    download() {
      let date = `${this.dateSelected.year}-${this.dateSelected.month}-01`
      let url = `${this.$host}/api/payroll/earning/template/${this.download_id}?&ref_date=${date}`
      let win = window.open(url, '_blank')
      win.focus()
    },
    closeDownload() {
      this.download_id = null
      this.isDownload = false
    }
  }
}
</script>

<style>

</style>
