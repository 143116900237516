<template>
  <section>
    <b-modal :active.sync="isComponentModalActive" has-modal-card>
      <form action="">
        <div class="modal-card" style="width: 700px; height: 600px;">
          <header class="modal-card-head">
            <p class="modal-card-title">Search Employee</p>
          </header>
          <section class="modal-card-body">
            <b-field label="Search">
              <b-input v-model="search_query.name" />
            </b-field>
            <b-table
              :data="filter"
              :columns="columns"
              :selected.sync="selected"
              focusable>
            </b-table>
          </section>
          <footer class="modal-card-foot">
            <button class="button is-danger" type="button" @click.prevent="$parent.close()">Close</button>
            <button class="button is-primary">Confirm</button>
            <span>Selected: {{ selected }}</span>
          </footer>
        </div>
      </form>
    </b-modal>
  </section>
</template>

<script>
  const ModalForm = {
      props: ['name'],
      template: `
          
      `
  }

  export default {
      components: {
          ModalForm
      },
      computed: {
        filter: function() {
          var i;
          var name_re = new RegExp(this.search_query.name, 'i')
          var data = []
          for (i in this.data) {
            if (this.data[i].name.match(name_re) || this.data[i].role.match(name_re)) {
              data.push(this.data[i])
            }
          }
          return data
        }
      },
      data() {
        var columns = [
          { field: 'id', label: 'ID', width: '40'},
          { field: 'name', label: 'Name', width: '250' },
          { field: 'lang', label: 'Lang' }
        ]

        var data = [
          { id: 1, name: 'Flask', lang: 'Python'},
          { id: 2, name: 'Django', lang: 'Python'},
          { id: 3, name: 'Pyramid', lang: 'Python'},
          { id: 4, name: 'Rocket', lang: 'Rust'},
          { id: 5, name: 'Iron', lang: 'Rust'},
          { id: 6, name: 'Express', lang: 'JavaScript'},
          { id: 7, name: 'Sails.js', lang: 'JavaScript'},
          { id: 8, name: 'Ruby on Rails', lang: 'Ruby'},
          { id: 9, name: 'Sinatra', lang: 'Ruby'},
          { id: 10, name: 'Hanami', lang: 'Ruby'},
        ]

        return {
          selected: '',
          isComponentModalActive: true,
          columns: columns,
          data: data,
          search_query: {
            name: ''
          }
        }
      }

        
  }
</script>