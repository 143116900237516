<template>
  <div class="box">
    <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
    <div>
      <b-field>
        <b-select placeholder="Date" v-model="perDate" @input="getSSS()">
          <option
            v-for="date in dates"
            :value="date"
            :key="date">
            {{ date }}
          </option>
        </b-select>&nbsp;
        <button class="button is-success" @click.prevent="isAddDate = true" v-if="$role.state.permissions['write sss']">
          <span><i class="fa fa-plus"></i> Add Date</span>
        </button>
      </b-field>
    </div>
    <hr>
    <b-field>
      <b-select placeholder="Per Page" v-model="perPage">
        <option value="5">5 per page</option>
        <option value="10">10 per page</option>
        <option value="20">20 per page</option>
        <option value="50">50 per page</option>
      </b-select>&nbsp;
      <button class="button is-success" @click.prevent="addSSS()" v-if="$role.state.permissions['write sss']" :disabled="!perDate">
        <span><i class="fa fa-plus"></i> Add SSS</span>
      </button>&nbsp;
      <button class="button is-danger" @click.prevent="deleteTable()" v-if="$role.state.permissions['write sss']" :disabled="!perDate">
        <span><i class="fa fa-trash"></i> Delete Table</span>
      </button>
    </b-field>
    <b-table
      :data="ssses"
      :striped=true
      :bordered=true
      :hoverable=true
      :paginated="true"
      :per-page="perPage">
      <template slot-scope="props">
        <b-table-column label="Minimum Salary">
          {{ props.row.minimum_salary }}
        </b-table-column>
        <b-table-column label="Maximum Salary">
          {{ props.row.maximum_salary }}
        </b-table-column>
        <b-table-column label="Employee Contribution">
          {{ props.row.employee_contribution }}
        </b-table-column>
        <b-table-column label="Employer Contribution">
          {{ props.row.employer_contribution }}
        </b-table-column>
        <b-table-column label="Additional Employer Contribution">
          {{ props.row.additional_employer_contribution }}
        </b-table-column>
        <b-table-column label="Actions">
          <button class="button btn-action is-primary is-small" @click.prevent="addSSS(props.row)"><i class="fa fa-pen"></i></button>
          <button class="button btn-action is-danger is-small" @click.prevent="deleteSSS(props.row)"><i class="fa fa-trash"></i></button>
        </b-table-column>
      </template>
      <template slot="empty">
        <b-table-column label="Minimum Salary">
        </b-table-column>
        <b-table-column label="Maximum Salary">
        </b-table-column>
        <b-table-column label="Employee Contribution">
        </b-table-column>
        <b-table-column label="Employer Contribution">
        </b-table-column>
        <b-table-column label="Additional Employer Contribution">
        </b-table-column>
        <b-table-column label="Actions">
        </b-table-column>
      </template>
    </b-table>

    <b-modal :active.sync="isAddDate" has-modal-card :canCancel=false>
      <transition name="fade">
        <form action="">
          <div class="modal-card" style="height: 33rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">Add Date</p>
            </header>
            <section class="modal-card-body">
              <b-field label="Date">
                <b-datepicker
                  icon="calendar-today"
                  v-model="date"
                  :date-formatter="d => d.toLocaleDateString('en-GB')"
                  expanded required>
                </b-datepicker>
              </b-field><br><br>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" @click.prevent="saveDate()">Save</button>
              <button class="button is-danger" type="button" @click.prevent="closeDate()">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>

    <b-modal :active.sync="isSave" has-modal-card :canCancel=false>
      <transition name="fade">
        <form @submit.prevent="save()">
          <div class="modal-card" style="height: 33rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">{{ state }} SSS</p>
            </header>
            <section class="modal-card-body">
              <b-field label="Minimum Salary">
                <b-input
                  v-model="sss.minimum_salary"
                  type="number"
                  step="0.01"
                  min="0.01"
                  expanded>
                </b-input>
              </b-field>
              <b-field label="Maximum Salary">
                <b-input
                  v-model="sss.maximum_salary"
                  type="number"
                  step="0.01"
                  min="0.01"
                  expanded>
                </b-input>
              </b-field>
              <b-field label="Employee Contribution">
                <b-input
                  v-model="sss.employee_contribution"
                  type="number"
                  step="0.01"
                  min="0.00"
                  expanded required>
                </b-input>
              </b-field>
              <b-field label="Employer Contribution">
                <b-input
                  v-model="sss.employer_contribution"
                  type="number"
                  step="0.01"
                  min="0.00"
                  expanded required>
                </b-input>
              </b-field>
              <b-field label="Additional Employer Contribution">
                <b-input
                  v-model="sss.additional_employer_contribution"
                  type="number"
                  step="0.01"
                  min="0.00"
                  expanded required>
                </b-input>
              </b-field>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" type="submit">Save</button>
              <button class="button is-danger" type="button" @click.prevent="close()">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  data() {
    return {
      config: '',
      isLoading: false,
      perDate: null,
      dates: [],
      state: '',
      isSave: false,
      isAddDate: false,
      date: new Date(),
      ssses: [],
      sss: {},
      perPage: 5
    }
  },
  mounted() {
    this.config = {
      headers: {'Authorization': "Bearer " + this.$store.state.token}
    };

    this.getDate()
  },
  methods: {
    getDate() {
      this.isLoading = true

      this.$http.get(`${this.$host}/api/parameter/payroll`,
        this.config
      ).then((response) => {
        let data = response.data
        this.dates = data.sss
        this.perDate = this.dates[this.dates.length - 1]

        this.$http.get(`${this.$host}/api/payroll/sss/search/${this.perDate}`,
          this.config
        ).then((response) => {
          let data = response.data
          this.ssses = data.array
          this.isLoading = false
        }).catch((error) => {
          this.isLoading = false
          //console.log(error)
        });
      }).catch((error) => {
        this.isLoading = false
        //console.log(error)
      });
    },
    addDate() {

    },
    saveDate() {
      this.isLoading = true

      this.$http.post(`${this.$host}/api/payroll/sss/save/`,
        {
          effective_date: moment(this.date).format("YYYY-MM-DD"),
          maximum_salary: null,
          minimum_salary: null,
          employee_contribution: 0.00,
          employer_contribution: 0.00,
          additional_employer_contribution: 0.00
        },
        this.config,
      ).then((response) => {
        let data = response.data.data
        this.$toast.open({
          message: 'Data successfully saved.',
          type: 'is-success',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.isLoading = false
        this.closeDate()
      }).catch((error) => {
        this.$toast.open({
          message: 'Error saving data.',
          type: 'is-danger',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.isLoading = false
      });
    },
    closeDate() {
      this.getDate()
      this.isAddDate = false
      this.date = new Date()
    },
    getSSS() {
      this.isLoading = true

      this.$http.get(`${this.$host}/api/payroll/sss/search/${this.perDate}`,
        this.config
      ).then((response) => {
        let data = response.data
        this.ssses = data.array
        this.isLoading = false
      }).catch((error) => {
        this.isLoading = false
        //console.log(error)
      });
    },
    addSSS(obj) {
      if (obj) {
        this.sss = obj
        this.state = 'Edit'
      }
      else {
        this.state = 'Add'
      }

      this.isSave = true
    },
    save() {
      this.isLoading = true

      let id = ''
      if (this.sss.id) {
        id = this.sss.id
      }

      if (parseFloat(this.sss.minimum_salary) > parseFloat(this.sss.maximum_salary)) {
        this.$toast.open({
          message: 'Error: Minimum salary is greater than max salary.',
          type: 'is-danger',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.isLoading = false
      }
      else {
        this.$http.post(`${this.$host}/api/payroll/sss/save/${id}`,
          {
            effective_date: this.perDate,
            maximum_salary: this.sss.maximum_salary,
            minimum_salary: this.sss.minimum_salary,
            employee_contribution: this.sss.employee_contribution,
            employer_contribution: this.sss.employer_contribution,
            additional_employer_contribution: this.sss.additional_employer_contribution
          },
          this.config,
        ).then((response) => {
          let data = response.data.data
          this.$toast.open({
            message: 'Data successfully saved.',
            type: 'is-success',
            position: 'is-bottom-right',
            duration: 4000
          })
          //console.log(data)
          this.isLoading = false
          this.close()
        }).catch((error) => {
          this.$toast.open({
            message: 'Error saving data.',
            type: 'is-danger',
            position: 'is-bottom-right',
            duration: 4000
          })
          this.isLoading = false
        });
      }
    },
    deleteSSS(obj) {
      this.$dialog.confirm({
        title: 'Delete Table',
        message: "Are you sure you want to delete this row? This action cannot be undone.",
        confirmText: 'Delete',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isLoading = true

          this.$http.delete(`${this.$host}/api/payroll/sss/delete/${obj.id}`,
            this.config
          ).then((response) => {
            this.$toast.open({
              message: 'Row deleted.',
              type: 'is-success',
              position: 'is-bottom-right',
              duration: 4000
            })
            this.isLoading = false
            this.getDate()
          }).catch((error) => {
            this.$toast.open({
              message: 'Error deleting row.',
              type: 'is-danger',
              position: 'is-bottom-right',
              duration: 4000
            })
            this.isLoading = false
          });
        }
      })
    },
    deleteTable() {
      this.$dialog.confirm({
        title: 'Delete Table',
        message: "Are you sure you want to delete this table? This action cannot be undone.",
        confirmText: 'Delete',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isLoading = true

          this.$http.delete(`${this.$host}/api/payroll/sss/remove/${this.perDate}`,
            this.config
          ).then((response) => {
            this.$toast.open({
              message: 'SSS table deleted.',
              type: 'is-success',
              position: 'is-bottom-right',
              duration: 4000
            })
            this.isLoading = false
            this.getSSS()
            this.getDate()
          }).catch((error) => {
            this.$toast.open({
              message: 'Error deleting table.',
              type: 'is-danger',
              position: 'is-bottom-right',
              duration: 4000
            })
            this.isLoading = false
          });
        }
      })
    },
    close() {
      this.getSSS()
      this.isSave = false
      this.sss = {

      }
    }
  }
}
</script>

<style>

</style>
