<template>
  <section>
    <b-modal :active.sync="isComponentModalActive" has-modal-card :canCancel="false">
      <form action="">
        <div class="modal-card pos" style="width: 60rem; height: 35.625rem; margin-left: 100px;">
          <header class="modal-card-head">
            <p class="modal-card-title">Users</p>
            <button style="border: none; background-color: transparent; font-size: 1.25rem" class="button has-text-danger" @click.prevent="$emit('close')">
              <span class="icon"><i class="fa fa-times"></i></span>
            </button>
          </header>
          <section class="modal-card-body">
            <b-field label="Search">
              <b-input v-model="search_query" />
            </b-field>
            <b-loading :is-full-page="false" :active.sync="searchLoad" :can-cancel="true"></b-loading>
            <b-table
              :data="filter"
              :selected.sync="selected"
              default-sort="id"
              :paginated="true"
              :per-page="5"
              focusable>
              <template slot-scope="props">
                <!-- <b-table-column field="id" label="ID" sortable numeric centered>
                  {{ props.row.id }}
                </b-table-column> -->
                <b-table-column field="employee_number" label="Employee No.">
                  {{ props.row.employee_number }}
                </b-table-column>
                <b-table-column field="username" label="Username">
                  {{ props.row.username }}
                </b-table-column>
                <b-table-column field="email" label="Email">
                  {{ props.row.email }}
                </b-table-column>
                <b-table-column field="active" label="Active" sortable centered>
                  {{ yesNo(props.row.active) }}
                </b-table-column>
                <b-table-column field="Role" label="Role" sortable centered>
                  {{ props.row.roles[0] }}
                </b-table-column>
                <!-- <b-table-column field="updated_at" label="Updated" sortable centered>
                  {{ yesNo(props.row.updated_at) }}
                </b-table-column> -->
              </template>
            </b-table>
          </section>
          <footer class="modal-card-foot">
            <button class="button is-primary" @click.prevent="$emit('view', selected)">
              Confirm
            </button>
            <!-- <button class="button is-danger" type="button" @click="$emit('close', false)">Close</button>  -->
            <router-link to="/users/add" class="button is-success is-pulled-right" @click="add()" v-if="$role.state.permissions['write user']">
              <i class="fa fa-plus"></i> &nbsp;New User
            </router-link>
            <!-- <span>Selected: {{ selected }}</span> -->
          </footer>
        </div>
      </form>
    </b-modal>
  </section>
</template>

<script>
export default {
  props: ['data', 'searchLoad'],
  data() {
    return {
      selected: {},
      isComponentModalActive: true,
      search_query: ''
    }
  },
  computed: {
    filter: function() {
      var i;
      var name_re = new RegExp(this.search_query, 'i')
      var data = []
      for (i in this.data) {
        if (this.data[i].username.concat(this.data[i].email).concat(this.data[i].roles[0]).match(name_re)) {
          data.push(this.data[i])
        }
      }
      return data
    }
  },
  methods: {
    yesNo(num) {
      if (num == 1) {
        return 'Yes'
      }
      else if (num == 0) {
        return 'No'
      }
      else {
        return ''
      }
    }
  }
}
</script>

<style scoped>
@media screen and (min-width: 1px) and (max-width: 768px)  {
  .modal-card {
    margin-left: 0px !important;
    width: 100% !important;
  }
  .pos {
    width: none;
  }
}
.pos {
  margin-top: 70px !important;
}
.modal-card-head {
  height: 50px;
}
</style>

