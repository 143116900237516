<template>
  <div>
    <div class="columns header" style="margin-bottom: 0 !important;">
      <div class="column is-5" style="margin-left: 5px; font-size: 20px; text-transform: uppercase;">
        <b>TRAININGS</b>
      </div>
      <div class="column is-auto">
        <button v-if="$role.state.permissions['search training']" class="button is-primary is-hidden-tablet" style="margin-left: 10px;" @click.prevent="$router.push('/trainings/manage')">
          <i class="fa fa-bookmark"></i> &nbsp;Manage Trainings
        </button>
        <button v-if="$role.state.permissions['search training']" class="button is-primary is-pulled-right is-hidden-mobile" style="margin-left: 10px;" @click.prevent="$router.push('/trainings/manage')">
          <i class="fa fa-bookmark"></i> &nbsp;Manage Trainings
        </button>
      </div>
    </div>
    <hr style="margin-top: 0 !important; margin-bottom: 0 !important;" class="has-background-grey-lighter is-hidden-mobile">
    <br>
    <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>

    <div class="box">
      <div class="columns">
        <div class="column is-6">
          <FullCalendar style="font-size: 12px;"
          :height="500" @eventClick="handleDateClick" defaultView="dayGridMonth" :plugins="calendarPlugins" :events="events" />
        </div>
        <div class="column is-6">
          <span style="font-size: 22px;">EVENT SELECTED</span><br>
          <b>Program:</b> <span v-if="event.program">{{ event.program.toUpperCase() }}</span><br>
          <b>Date From:</b> {{ event.date_from }}<br>
          <b>Date To:</b> {{ event.date_to }}<br>
          <b>Number of Hours:</b> {{event.number_of_hours}}<br>
          <b>Type of LD:</b> <span v-if="event.type_of_ld">{{ event.type_of_ld.toUpperCase() }}</span><br>
          <b>Sponsored by:</b> <span v-if="event.sponsored_by">{{ event.sponsored_by.toUpperCase() }}</span><br>
          <b>Conducted by:</b> <span v-if="event.conducted_by">{{ event.conducted_by.toUpperCase() }}</span><br>
          <b>Location:</b> <span v-if="event.location">{{ event.location.toUpperCase() }}</span><br>
          <b>Description and Requirements:</b><br>
          <quill-editor
            style="border: none !important; font-size: 24px;"
            v-model="event.description"
            ref="myQuillEditor"
            :options="editorOption"
            disabled>
          </quill-editor>
          <!-- <span v-html="content"></span> -->
          <!-- <br>
          <button class="button is-primary" @click.prevent="apply()">
            Apply (?)
          </button> -->
        </div>
      </div>
    </div>

    <div class="box">
      <div>
        <b>My Trainings</b>
        <b-input v-model="search_query" placeholder="Search" class="is-pulled-right"/>
      </div>
      <div>
        <!-- <button class="button is-primary control" @click.prevent="addTraining()">
          <i class="fa fa-plus"></i> &nbsp;Training
        </button> -->
      </div>
      <br>
      <hr style="margin-top: 0; margin-bottom: 10px;">
      <b-table
        :data="filter"
        :bordered=false
        :hoverable=true
        :paginated="true"
        :per-page="5"
        detailed
        detail-key="id">
        <template slot-scope="props">
          <b-table-column label="Program">
            {{ props.row.program }}
          </b-table-column>
          <b-table-column label="Date From" field="date_from" width="120" sortable>
            {{ props.row.date_from }}
          </b-table-column>
          <b-table-column label="Date To" field="date_to" width="120" sortable>
            {{ props.row.date_to }}
          </b-table-column>
          <b-table-column label="Number of Hours">
            {{ props.row.number_of_hours }}
          </b-table-column>
          <b-table-column label="Type of LD">
            {{ props.row.type_of_ld }}
          </b-table-column>
          <b-table-column label="Sponsored By">
            {{ props.row.sponsored_by }}
          </b-table-column>
          <b-table-column label="Conducted By">
            {{ props.row.conducted_by }}
          </b-table-column>
          <b-table-column label="Location" field="location" sortable>
            {{ props.row.location }}
          </b-table-column>
          <b-table-column label="Proof" width="100">
            <button class="button btn-action is-primary is-small" @click.prevent="attachRequest(props.row.id)"><i class="fa fa-paperclip"></i></button>
          </b-table-column>
        </template>
        <template slot="detail" slot-scope="props">
          <article class="media">
            <div class="media-content">
              <div class="content">
                <p>
                  <b></b> <button v-if="props.row.certificate_of_appearance" class="button is-primary is-small" @click.prevent="openProofCOA(props.row.certificate_of_appearance)"><span>CERTIFICATE OF APPEARANCE</span></button>
                  <b></b> <button v-if="props.row.post_training_report" class="button is-primary is-small" @click.prevent="openProofPTR(props.row.post_training_report)"><span>POST TRAINING REPORT</span></button>
                </p>
              </div>
            </div>
          </article>
        </template>
        <template slot="empty">
          <b-table-column label="Program">
          </b-table-column>
          <b-table-column label="Date From">
          </b-table-column>
          <b-table-column label="Date To">
          </b-table-column>
          <b-table-column label="Number of Hours">
          </b-table-column>
          <b-table-column label="Type of LD">
          </b-table-column>
          <b-table-column label="Sponsored By">
          </b-table-column>
          <b-table-column label="Conducted By">
          </b-table-column>
          <b-table-column label="Location">
          </b-table-column>
          <b-table-column label="Proof">
          </b-table-column>
        </template>
      </b-table>
    </div>

    <b-modal :active.sync="isAddTraining" has-modal-card :canCancel=false>
      <transition name="fade">
        <form action="">
          <div class="modal-card" style="height: 35.625rem; margin-top: 4rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">Training</p>
            </header>
            <section class="modal-card-body">
              <b-field label="Program">
                <b-field>
                  <b-input v-model="training.program.name" expanded required></b-input>
                </b-field>
              </b-field>
              <b-field label="Date From">
                <b-datepicker
                  placeholder="Click to select..."
                  icon="calendar-today"
                  v-model="training.date_from"
                  :date-formatter="d => d.toLocaleDateString('en-GB')"
                  :date-parser="d => parseDate(d)"
                  editable>
                </b-datepicker>
              </b-field>
              <b-field label="Date To">
                <b-datepicker
                  placeholder="Click to select..."
                  icon="calendar-today"
                  v-model="training.date_to"
                  :date-formatter="d => d.toLocaleDateString('en-GB')"
                  :date-parser="d => parseDate(d)"
                  editable>
                </b-datepicker>
              </b-field>
              <b-field label="Number of Hours">
                <b-input v-model="training.number_of_hours"></b-input>
              </b-field>
              <b-field label="Type of LD">
                <b-input v-model="training.type_of_ld"></b-input>
              </b-field>
              <b-field label="Conducted By / Sponsored By">
                <b-input v-model="training.sponsored_by"></b-input>
              </b-field>
              <br>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" @click.prevent="saveTraining()">Save</button>
              <button class="button is-danger" @click.prevent="isAddTraining = false">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>
    <b-modal :active.sync="isAttach" has-modal-card :canCancel=false>
      <transition name="fade">
        <form @submit.prevent="saveAttach()" id="requestForm">
          <div class="modal-card" style="height: 25.625rem; margin-top: 4rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">Attach Proof</p>
            </header>
            <section class="modal-card-body">

              <b-field label="Attach Certificate of Appearance">
                <input type="file" @input="onAttachCOAChange" id="proofCOA" required>
              </b-field>
              <b-field label="Attach Post Training Report">
                <input type="file" @input="onAttachPTRChange" id="proofPTR" required>
              </b-field>
              <span style="font-size: 12px;" v-if="this.$editmode.state.isUpdate"><i>NOTE: <br>&nbsp;&nbsp;&nbsp;- 2 Attachments are required. <br>&nbsp;&nbsp;&nbsp;- Upload the certificate of appearance to both input if the post training report is not available.
              <br>&nbsp;&nbsp;&nbsp;- The training will not be visible in your report if no proofs are attached.</i></span>
              <br>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" type="submit">Save</button>
              <button class="button is-danger" @click.prevent="cancelAttach">Cancel</button>
              <progress class="progress is-primary" :value="this.percent" max="100" v-if="percent != 0" style="max-width: 350px;"></progress>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'

export default {
  components: {
    FullCalendar,
    quillEditor
  },
  data() {
    return {
      config: '',
      calendarPlugins: [ dayGridPlugin ],
      isLoading: false,
      isAddTraining: false,
      trainings: [],
      training: {
        program: {
          id: null,
          name: null
        },
        date_from: new Date(),
        date_to: new Date(),
        number_of_hours: null,
        type_of_ld: null,
        conducted_by: null,
        sponsored_by: null,
        location: null
      },
      events: [],
      event: {
        program: '',
        date_from: null,
        date_to: null,
        number_of_hours: null,
        type_of_ld: '',
        sponsored_by: '',
        conducted_by: '',
        location: '',
        description: '&zwnj;<br>&zwnj;<br>&zwnj;'
      },
      content: '<p><em>This</em> is a <u>sample</u> <strong style="color: rgb(54, 54, 54);">description.</strong> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p><p><br></p><p><strong style="color: rgb(54, 54, 54);">REQUIREMENTS:</strong></p><ul><li>Sample 1</li><li><b>Sample 2</b></li><li><u>Sample 3</u></li></ul>',
      editorOption: {
        "modules": {
          "toolbar": false
        }
      },
      search_query: '',
      isAttach: false,
      attachForm: new FormData(),
      employee_id: null,
      percent: 0,
    }
  },
  mounted() {
    this.config = {
      headers: {'Authorization': "Bearer " + this.$store.state.token}
    };

    this.init()
  },
  computed: {
    filter() {
      var i;
      var name_re = new RegExp(this.search_query, 'i')
      var data = []
      for (i in this.trainings) {
        if ([this.trainings[i].program, this.trainings[i].type_of_ld, this.trainings[i].sponsored_by, this.trainings[i].conducted_by].join(' ').match(name_re)) {
          data.push(this.trainings[i])
        }
      }
      return data
    }
  },
  methods: {
    init() {
      this.loadCalendar()
      this.getTrainings()
    },
    getTrainings() {
      this.isLoading = true
      this.$http.get(`${this.$host}/api/training/employee/show`,
        this.config
      ).then((response) => {
        let data = response.data
        this.trainings = data.training
        this.isLoading = false
      }).catch((error) => {
        //console.log(error)
        this.isLoading = false
      });
    },
    loadCalendar() {
      this.isLoading = true
      this.$http.get(`${this.$host}/api/training/calendar`,
        this.config
      ).then((response) => {
        let data = response.data
        this.events = data
        this.isLoading = false
      }).catch((error) => {
        //console.log(error)
        this.isLoading = false
      });
    },
    addTraining() {
      this.isAddTraining = true
    },
    saveTraining() {
      // validate date should be less than current date
    },
    apply() {
      this.$dialog.alert({
        title: 'HOW TO APPLY',
        type: 'is-info',
        hasIcon: true,
        message: 'Please contact HR to apply for this training.'
      })
    },
    handleDateClick(arg) {
      let obj = arg.event._def.extendedProps
      let id = arg.event._def.publicId

      let config = {
        headers: {
          'Authorization': "Bearer " + this.$store.state.token,
          'Content-Type': 'application/json'
        },
        params: {
          date_from: obj.date_from,
          date_to: obj.date_to,
          number_of_hours: obj.number_of_hours
        }
      }

      this.isLoading = true
      this.$http.get(`${this.$host}/api/training/search/${id}`,
        config
      ).then((response) => {
        let data = response.data
        this.event = data
        this.isLoading = false
        if (this.event.description == null) {
          this.event.description = '&zwnj;<br>&zwnj;<br>&zwnj;'
        }
        // //console.log(this.event)
      }).catch((error) => {
        //console.log(error)
        this.isLoading = false
      });
    },
    saveAttach() {
      this.isLoading = true

      let config = {
          headers: {'Authorization': "Bearer " + this.$store.state.token},
          onUploadProgress: progressEvent => {
            let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
            this.percent = percentCompleted
          }
        }

      this.$http.post(`${this.$host}/api/training/employee/proof/${this.employee_id}`,
        this.attachForm,
        config,
      ).then((response) => {
        this.$toast.open({
          message: 'Data successfully saved.',
          type: 'is-success',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.percent = 0
        this.isLoading = false
        this.cancelAttach()
      }).catch((error) => {
        this.percent = 0
        this.isLoading = false
        let msg = error.response.data.errors[Object.keys(error.response.data.errors)[0]][0]
        if (msg == "The certificate of appearance may not be greater than 15240 kilobytes.") {
          this.$toast.open({
            message: "The certificate of appearance file should not exceed 15mb.",
            type: 'is-danger',
            position: 'is-bottom-right',
            duration: 4000
          })
        }
        else if (msg == 'The post training report may not be greater than 15240 kilobytes.') {
          this.$toast.open({
            message: "The post training report file should not exceed 15mb.",
            type: 'is-danger',
            position: 'is-bottom-right',
            duration: 4000
          })
        }
        else {
          this.$toast.open({
            message: msg,
            type: 'is-danger',
            position: 'is-bottom-right',
            duration: 4000
          })
        }
      });
    },
    cancelAttach() {
      this.init()
      this.isAttach = false
    },
    onAttachCOAChange(e) {
      let file = e.target.files[0]
      this.attachForm.append('certificate_of_appearance', file)
    },
    onAttachPTRChange(e) {
      let file = e.target.files[0]
      this.attachForm.append('post_training_report', file)
    },
    attachRequest(id) {
      this.isAttach = true
      this.employee_id = id
    },
    openProofCOA(proof) {
      let url = this.$host + '/storage/training/certificate/' + proof
      let win = window.open(url, '_blank')
      win.focus()
    },
    openProofPTR(proof) {
      let url = this.$host + '/storage/training/report/' + proof
      let win = window.open(url, '_blank')
      win.focus()
    },
    onEditorBlur(quill) {
      //console.log('editor blur!', quill)
    },
    onEditorFocus(quill) {
      //console.log('editor focus!', quill)
    },
    onEditorReady(quill) {
      //console.log('editor ready!', quill)
    },
    onEditorChange({ quill, html, text }) {
      //console.log('editor change!', quill, html, text)
      this.content = html
    }
  }
}
</script>

<style scoped>

@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';

.ql-container.ql-snow {
  border: none !important;
}

.progress {
  height: 8px !important;
}

.progress::-webkit-progress-value {
  transition: width 0.5s ease;
}

</style>
