<template>
  <div class="box">
    <div class="columns">
      <div class="column is-6">
        <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
        <b-field label="Print Type">
          <b-select
            v-model="printType"
            expanded
          >
            <option value="awards" v-if="$role.state.permissions['print award']">Awards</option>
            <option value="offenses" v-if="$role.state.permissions['print offense']">Offenses</option>
            <option value="suspensions" v-if="$role.state.permissions['print offense']">Suspension</option>
          </b-select>
        </b-field>
        <!-- <b-field label="Employee Name">
          <b-autocomplete
            v-model="employee"
            :data="filterEmployee"
            field="full_name"
            @select="option => {employee_id = option.id}"
            :open-on-focus=false expanded>
          </b-autocomplete>
        </b-field> -->
        <b-field label="Prepared By">
          <b-autocomplete
            v-model="prepared_by"
            :data="filteredName1"
            field="full_name"
            @select="option => {position_1 = option.position}"
            :open-on-focus=false expanded>
          </b-autocomplete>
        </b-field>
        <b-field label="Position">
          <b-input
            v-model="position_1"
            expanded>
          </b-input>
        </b-field>
        <b-field label="Noted By">
          <b-autocomplete
            v-model="noted_by"
            :data="filteredName2"
            field="full_name"
            @select="option => {position_2 = option.position}"
            :open-on-focus=false expanded>
          </b-autocomplete>
        </b-field>
        <b-field label="Position">
          <b-input
            v-model="position_2"
            expanded>
          </b-input>
        </b-field>
        <button class="button is-primary" @click.prevent="print()">
          <span><i class="fa fa-print"></i> Print</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      prepared_by: '',
      noted_by: '',
      position_1: '',
      position_2: '',
      printType: 'awards',
      employee_id: 0,
      employee: '',
      isLoading: false,
      suggest: []
    }
  },
  mounted() {
    this.config = {
      headers: {'Authorization': "Bearer " + this.$store.state.token}
    };

    this.getSuggest()
  },
  computed: {
    filteredName1() {
      return this.suggest.filter((option) => {
        return option.full_name
          .toString()
          .toLowerCase()
          .indexOf(this.prepared_by.toLowerCase()) >= 0
      })
    },
    filteredName2() {
      return this.suggest.filter((option) => {
        return option.full_name
          .toString()
          .toLowerCase()
          .indexOf(this.noted_by.toLowerCase()) >= 0
      })
    },
    filterEmployee() {
      return this.suggest.filter((option) => {
        return option.full_name
          .toString()
          .toLowerCase()
          .indexOf(this.noted_by.toLowerCase()) >= 0
      })
    }
  },
  methods: {
    getSuggest() {
        this.$http.get(`${this.$host}/api/parameter/service_record/employee`,
        this.config
      ).then((response) => {
        let data = response.data
        this.suggest = data

        let i = 0
        for (i in this.suggest) {
          this.suggest[i].full_name = this.suggest[i].full_name.toUpperCase()
        }
      }).catch((error) => {
        //console.log(error)
      });
    },
    print() {
      this.isLoading = true

      this.$http.get(`${this.$host}/api/report/generate/${this.$role.state.id}`,
        this.config
      ).then((response) => {
        this.isLoading = false
        let token = response.data
        var url = ''
        if (this.printType!='suspensions')
          url = `${this.$host}/api/report/${this.printType}/${token}?prepared_by=${this.prepared_by}&position_1=${this.position_1}&noted_by=${this.noted_by}&position_2=${this.position_2}`
        else
          url = `${this.$host}/api/report/offenses/${token}?suspension=true&prepared_by=${this.prepared_by}&position_1=${this.position_1}&noted_by=${this.noted_by}&position_2=${this.position_2}`
        let win = window.open(url, '_blank')
        win.focus()
        this.isPrint = false
      }).catch((error) => {
        //console.log(error)
        this.isLoading = false
      });
    }
  }
}
</script>

<style>

</style>
