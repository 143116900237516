<template>
  <div>
    <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
    <div class="columns header" style="margin-bottom: 0 !important;">
      <div class="column is-5" style="margin-left: 5px; font-size: 20px;">
        <b>PAYROLL</b>
        <b-tag type="is-success is-small" v-if="ot_allowed">OT Allowed: {{ ot_allowed }} hours</b-tag>
      </div>
      <div class="column is-auto">
        <button class="button is-primary is-hidden-tablet" style="margin-left: 10px;" @click.prevent="openEncodeOR()"
        v-if="$role.state.permissions['write payroll'] || $role.state.permissions['write payroll funding source']">
          <i class="fa fa-wallet"></i> &nbsp;Encode OR
        </button>
        <button class="button is-primary is-hidden-tablet" style="margin-left: 10px;" @click.prevent="$router.push('/payroll/finalize')"
        v-if="$role.state.permissions['write payroll'] || $role.state.permissions['write payroll funding source']">
          <i class="fa fa-wallet"></i> &nbsp;Finalize Payroll
        </button>
        <button class="button is-primary is-hidden-tablet" style="margin-left: 10px;" @click.prevent="openRunPayroll()"
        v-if="$role.state.permissions['write payroll'] || $role.state.permissions['write payroll funding source']">
          <i class="fa fa-play"></i> &nbsp;Run Payroll
        </button>
        <button class="button is-primary is-hidden-tablet" style="margin-left: 10px;"
        v-if="$role.state.permissions['write payroll'] || $role.state.permissions['write payroll funding source']"
        @click.prevent="$router.push('/payroll/manage')">
          <i class="fa fa-file-invoice"></i> &nbsp;Manage Payroll
        </button>

        <button class="button is-primary is-pulled-right is-hidden-mobile" style="margin-left: 10px;"
        v-if="$role.state.permissions['write payroll'] || $role.state.permissions['write payroll funding source']"
        @click.prevent="openEncodeOR()">
          <i class="fa fa-file-invoice"></i> &nbsp;Encode OR
        </button>
        <button class="button is-primary is-pulled-right is-hidden-mobile" style="margin-left: 10px;"
        v-if="$role.state.permissions['write payroll'] || $role.state.permissions['write payroll funding source']"
        @click.prevent="$router.push('/payroll/manage')">
          <i class="fa fa-file-invoice"></i> &nbsp;Manage Payroll
        </button>
        <button class="button is-primary is-pulled-right is-hidden-mobile" style="margin-left: 10px;" @click.prevent="openRunPayroll()"
        v-if="$role.state.permissions['write payroll'] || $role.state.permissions['write payroll funding source']">
          <i class="fa fa-play"></i> &nbsp;Run Payroll
        </button>
        <button class="button is-primary is-pulled-right is-hidden-mobile" style="margin-left: 10px;" @click.prevent="$router.push('/payroll/finalize')"
        v-if="$role.state.permissions['write payroll'] || $role.state.permissions['write payroll funding source']">
          <i class="fa fa-wallet"></i> &nbsp;Finalize Payroll
        </button>
      </div>
    </div>
    <hr style="margin-top: 0 !important;" class="has-background-grey-lighter is-hidden-mobile">
    <div class="box">
      <b>Payroll Records</b>
      <hr>
      <b-field grouped group-multiline>
        <b-select placeholder="Per Page" v-model="perPage">
          <option value="5">5 per page</option>
          <option value="10">10 per page</option>
          <option value="20">20 per page</option>
          <option value="50">50 per page</option>
        </b-select>
        <!-- <b-select placeholder="PERIOD">
          <option value=""></option>
        </b-select> -->
        <b-field>
          <b-input placeholder="Year" type="number" @input="changeYear()" v-model="year"></b-input>
        </b-field>
      </b-field>

      <b-table
        :data="ledger"
        :striped=true
        :bordered=true
        :hoverable=true
        :paginated="true"
        :per-page="perPage">
        <template slot-scope="props">
          <b-table-column label="Period">
            {{ props.row.period }}
          </b-table-column>
          <b-table-column label="Type" field="type" sortable>
            {{ props.row.type }}
          </b-table-column>
          <b-table-column label="Gross Pay">
            {{ props.row.gross_pay }}
          </b-table-column>
          <b-table-column label="Total Deductions">
            {{ props.row.total_deductions }}
          </b-table-column>
          <b-table-column label="Net Pay">
            {{ props.row.net_pay }}
          </b-table-column>
          <b-table-column label="Actions">
            <button class="button btn-action is-primary is-small" @click.prevent="viewPayslip(props.row)"><i class="fa fa-receipt"></i></button>
          </b-table-column>
        </template>
        <template slot="empty">
          <b-table-column label="Period">
          </b-table-column>
          <b-table-column label="Type" field="type" sortable>
          </b-table-column>
          <b-table-column label="Gross Pay">
          </b-table-column>
          <b-table-column label="Total Deductions">
          </b-table-column>
          <b-table-column label="Net Pay">
          </b-table-column>
          <b-table-column label="Actions">
          </b-table-column>
        </template>
      </b-table>

      <b-modal :active.sync="isRecords" has-modal-card :canCancel=false>
        <transition name="fade">
          <form>
            <div class="modal-card pos" style="width: 60rem; height: 35.625rem; margin-left: 100px;">
              <header class="modal-card-head">
                <p class="modal-card-title">View Payslip</p>
              </header>
              <section class="modal-card-body">
                <div class="columns is-vcentered is-centered" style="font-size: 13px;">
                  <div class="column is-narrow is-hidden-touch" style="padding: 0px !important;">
                    <img src="/img/dotr-logo.png" width="40"/>
                  </div>
                  <div class="column is-narrow">
                    <div>
                      <span style="text-align: center;"><b>Department of Transporation<br></b></span>
                      <span style="text-align: center;"><b>Employee Pay Slip</b></span>
                    </div>
                  </div>
                </div>
                <hr style="margin-top: 0px; margin-bottom: 10px; background-color: grey">
                <div class="columns" style="font-size: 13px;">
                  <div class="column is-6">
                    <b>Employee Name:</b> {{ payslip.name }} <br>
                    <b>Organization Unit:</b> {{ payslip.division }}
                  </div>
                  <div class="column is-6">
                    <b>Employee Number:</b> {{ payslip.employee_number }} <br>
                    <b>Pay Period:</b> {{ payslip.payroll_period }}
                  </div>
                </div>
                <div class="columns" style="font-size: 13px; min-height: 14.5rem;">
                  <div class="column is-3">
                    <b>Basic Pay</b>
                    <hr style="margin-top: 10px; margin-bottom: 10px; background-color: grey">
                    <table style="border: 0px; width: 100%;">
                      <tr v-for="option in payslip.basic_pay" :key="option.key">
                        <td>{{ option.key }}</td>
                        <td style="text-align: right;">{{ option.value }}</td>
                      </tr>
                    </table>
                  </div>
                  <div class="column is-3">
                    <b>Other Earnings</b>
                    <hr style="margin-top: 10px; margin-bottom: 10px; background-color: grey">
                    <table style="border: 0px; width: 100%;">
                      <tr v-for="option in payslip.other_earnings" :key="option.key">
                        <td>{{ option.key }} <a @click.prevent="showDTR(option.dtr)" v-if="option.dtr"><i class="far fa-calendar"></i></a></td>
                        <td style="text-align: right;">{{ option.value }}</td>
                      </tr>
                    </table>
                  </div>
                  <div class="column is-3">
                    <b>Regular Deductions</b>
                    <hr style="margin-top: 10px; margin-bottom: 10px; background-color: grey">
                    <table style="border: 0px; width: 100%;">
                      <tr v-for="option in payslip.regular_deductions" :key="option.key">
                        <td>{{ option.key }}</td>
                        <td style="text-align: right;">{{ option.value }}</td>
                      </tr>
                    </table>
                  </div>
                  <div class="column is-3">
                    <b>Loan and Other Deductions</b>
                    <hr style="margin-top: 10px; margin-bottom: 10px; background-color: grey">
                    <table style="border: 0px; width: 100%;">
                      <tr v-for="option in payslip.other_deductions" :key="option.key">
                        <td>{{ option.key }} <a @click.prevent="showDTR(option.dtr)" v-if="option.dtr"><i class="far fa-calendar"></i></a></td>
                        <td style="text-align: right;">{{ option.value }}</td>
                      </tr>
                    </table>
                  </div>
                </div>
                <hr style="margin-top: 0px; margin-bottom: 10px; background-color: grey">
                <div class="columns" style="font-size: 13px;">
                  <div class="column is-3" style="padding-bottom: 0rem;">
                    <b>Gross Pay:</b> {{ payslip.gross_pay }}
                  </div>
                  <div class="column is-3" style="padding-bottom: 0rem;">
                    <b>Total Deduction:</b> {{ payslip.total_deductions }}
                  </div>
                  <div class="column is-3" style="padding-bottom: 0rem;">
                  </div>
                  <div class="column is-3" style="padding-bottom: 0rem;">
                    <b>Net Pay:</b> {{ payslip.net_pay }}
                  </div>
                </div>
                <div class="columns" style="font-size: 13px;"  v-if="employment_status<10 || employment_status>11">
                  <div class="column is-3" style="padding-top: 0rem;">
                  </div>
                  <div class="column is-3" style="padding-top: 0rem;">
                    <b>1 - 15th:</b> {{ payslip['1st_half'] }}
                  </div>
                  <div class="column is-3" style="padding-top: 0rem;">
                  </div>
                  <div class="column is-3" style="padding-top: 0rem;">
                    <b>16 - 31st:</b> {{ payslip['2nd_half'] }}
                  </div>
                </div>
                <hr style="margin-top: 0px; margin-bottom: 10px; background-color: grey">
                <div style="font-size: 12px;">
                  <i>*** This is a system-generated report. If issued without alteration, this does not require a signature. <br>
                  Any data subject for correction/adjustment, please notify Payroll-Treasury Division immediately. ***</i>
                </div>

              </section>
              <footer class="modal-card-foot">
                <button class="button is-primary" v-if="$role.state.permissions['print payslip']" @click.prevent="printPS()"><i class="fa fa-print"></i>&nbsp;Print</button>
                <button class="button is-danger" @click.prevent="isRecords = false">Close</button>
              </footer>
            </div>
          </form>
        </transition>
      </b-modal>
    </div>

    <b-modal :active.sync="isShowDTR" has-modal-card :canCancel=false>
      <transition name="fade">
        <form>
          <div class="modal-card pos" style="width: 60rem; height: 35.625rem; margin-left: 100px;">
            <header class="modal-card-head">
              <p class="modal-card-title">DTR</p>
            </header>
            <section class="modal-card-body">
              <b-select v-model="perPageDTR">
                <option value="5">5 per page</option>
                <option value="10">10 per page</option>
                <option value="15">15 per page</option>
                <option value="20">20 per page</option>
                <option value="31">31 per page</option>
              </b-select>
              <b-table
                :data="dtr.record"
                :striped=true
                :bordered=true
                :hoverable=true
                :paginated="true"
                :per-page="perPageDTR">
                <template slot-scope="props">
                  <b-table-column label="Reference Date">
                    {{ props.row.ref_date }}
                  </b-table-column>
                  <b-table-column label="AM IN">
                    {{ props.row.am.in }}
                  </b-table-column>
                  <b-table-column label="AM OUT">
                    {{ props.row.am.out }}
                  </b-table-column>
                  <b-table-column label="PM IN">
                    {{ props.row.pm.in }}
                  </b-table-column>
                  <b-table-column label="PM OUT">
                    {{ props.row.pm.out }}
                  </b-table-column>
                  <b-table-column label="LATE/UT/HD">
                    {{ props.row.difference }}
                  </b-table-column>
                  <b-table-column label="OT">
                    {{ props.row.ot }}
                  </b-table-column>
                  <b-table-column label="Remarks">
                    {{ props.row.remarks }}
                  </b-table-column>
                </template>
                <template slot="empty">
                  <b-table-column label="Period">
                  </b-table-column>
                </template>
              </b-table>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-danger" @click.prevent="closeDTR()">Close</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>

    <b-modal :active.sync="isEncodeOR" has-modal-card :canCancel=false>
      <transition name="fade">
        <form @submit.prevent="encodeOR()">
          <div class="modal-card pos" style="height: 35.625rem; margin-left: 100px;">
            <header class="modal-card-head">
              <p class="modal-card-title">Encode OR</p>
            </header>
            <section class="modal-card-body">
              <b-field label="Payroll Group">
                <b-select placeholder="Payroll Group" v-model="orParams.payroll_group" expanded @input="getFinalizeDates">
                  <option value="1">Permanent/PA/Temporary/Co-Term </option>
                  <option value="2">Casuals/Contractual </option>
                  <option value="3">Contract of Service </option>
                  <option value="4">Job Order </option>
                </b-select>
              </b-field>
              <b-field label="Payroll Subgroup" v-if="orParams.payroll_group == '1'">
                <b-select placeholder="Payroll Group" v-model="orParams.payroll_subgroup" expanded @input="getFinalizeDates">
                  <option value="" selected>All</option>
                  <option value="1">Presidential Appointee</option>
                  <option value="2">Permanent</option>
                  <option value="3">Co-Terminus </option>
                  <option value="4">Temporary </option>
                </b-select>
              </b-field>
              <b-field label="Payroll Subgroup" v-if="orParams.payroll_group == '2'">
                <b-select placeholder="Payroll Group" v-model="orParams.payroll_subgroup" expanded @input="getFinalizeDates">
                  <option value="" selected>All</option>
                  <option value="9">Casuals</option>
                  <option value="6">Contractual</option>
                </b-select>
              </b-field>
              <b-field label="Payroll Type">
                <b-select v-model="orParams.payroll_type" expanded required @input="getFinalizeDates">
                  <option value="salary">REGULAR</option>
                  <option value="special">OTHER BENEFITS</option>
                  <option value="voucher">VOUCHER</option>
                </b-select>
              </b-field>
              <b-field label="Earning" v-if="orParams.payroll_type == 'special'">
                <b-select v-model="orParams.payroll_earning" expanded required @input="getFinalizeDates">
                  <option v-for="earning in earnings" :key="earning.id" :value="earning.id">{{ earning.code }}</option>
                </b-select>
              </b-field>
              <b-field label="Voucher" expanded required v-if="orParams.payroll_type == 'voucher'">
                <b-select v-model="orParams.voucher" expanded required @input="getFinalizeDates">
                  <option v-for="voucher in vouchers" :value="voucher.id" :key="voucher.id">{{ voucher.code }}</option>
                </b-select>
              </b-field>
              <b-field label="Month">
                <b-select v-model="orParams.month" placeholder="Month" expanded  @input="getFinalizeDates">
                  <option value="01">January</option>
                  <option value="02">February</option>
                  <option value="03">March</option>
                  <option value="04">April</option>
                  <option value="05">May</option>
                  <option value="06">June</option>
                  <option value="07">July</option>
                  <option value="08">August</option>
                  <option value="09">September</option>
                  <option value="10">October</option>
                  <option value="11">November</option>
                  <option value="12">December</option>
                </b-select>
              </b-field>
              <b-field label="Year">
                <b-select
                  v-model="orParams.year"
                  placeholder="Select Year"
                  expanded
                  required
                  @input="getFinalizeDates"
                >
                  <option v-for="year in years" :key="year" :value="year">{{year}}</option>
                </b-select>
              </b-field>
              <b-field label="Day" v-if="orParams.payroll_group == 4 && orParams.payroll_type=='salary'">
                <b-select v-model="orParams.day" expanded required @input="getFinalizeDates">
                  <option v-for="day in orParams.days" :key="day" :value="day">
                    {{ day }}
                  </option>
                </b-select>
              </b-field>
              <b-field label="Funding Source" v-if="orParams.payroll_group>1">
                <b-select v-model="orParams.funding_source" expanded @input="getFinalizeDates">
                  <option value="" selected>All</option>
                  <option v-for="source in sources" :key="source.id" :value="source.id">{{ source.code }}</option>
                </b-select>
              </b-field>
              <b-field label="Finalized?">
                <b-radio v-model="orParams.is_finalize" name="Finalized" native-value=1 @input="getFinalizeDates">Yes</b-radio>
                <b-radio v-model="orParams.is_finalize" name="Not yet Finalized" native-value=0>No</b-radio>
              </b-field>
              <b-field label="Date Finalized" v-if="orParams.is_finalize==1">
                <b-select
                  v-model="orParams.finalize_date"
                  placeholder="Select Finalize Date"
                  expanded
                >
                  <option v-for="(obj,finalize_date) in finalize_dates" :key="finalize_date" :value="finalize_date" >{{finalize_date}} ({{ obj.amount | currencyFormat }}) - {{ obj.funding_source }}</option>
                </b-select>
              </b-field>
              <b-field label="Deduction List">
                <b-select
                  v-model="orParams.or_type"
                  placeholder="SELECT DEDUCTIONS"
                  required
                  expanded
                  multiple
                >
                  <option v-for="deduction in filteredDeductions" :key="deduction.id" :value="deduction.code" >{{deduction.code}}</option>
                </b-select>
              </b-field>
              <b-field label="OR Number">
                <b-input v-model="orParams.or_number" expanded>
                </b-input>
              </b-field>
              <b-field label="OR Date">
                <b-datepicker
                  placeholder="Click to select..."
                  icon="calendar-today"
                  v-model="orParams.or_date"
                  :date-formatter="d => d.toLocaleDateString('en-GB')"
                  :date-parser="d => parseDate(d)"
                  editable>
                </b-datepicker>
              </b-field>
              <b-field label="ADA/Check Number">
                <b-input v-model="orParams.check_number" expanded>
                </b-input>
              </b-field>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" type="submit">Run Encode</button>
              <button class="button is-danger" type="button" @click.prevent="isEncodeOR=false">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>

    <b-modal :active.sync="isRunPayroll" has-modal-card :canCancel=false>
      <RunPayroll @refresh="getLedger()"/>
    </b-modal>
  </div>
</template>

<script>
import RunPayroll from '../components/RunPayroll.vue'

export default {
  components: {
    RunPayroll
  },
  data() {
    return {
      orParams : {
        payroll_group: null,
        payroll_subgroup: null,
        payroll_type: null,
        payroll_earning: null,
        voucher: null,
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear(),
        day: null,
        funding_source: null,
        is_finalize: 0,
        finalize_date: null,
        or_number: '',
        or_date: null,
        check_number: ''
      },
      isEncodeOR: false,
      config: '',
      perPage: 5,
      ledger: [],
      payslip: {},
      isRecords: false,
      isRunPayroll: false,
      isLoading: false,
      payroll_id: null,
      isShowDTR: false,
      dtr: {},
      perPageDTR: 5,
      ot_allowed: null,
      year: new Date().getFullYear(),
      employment_status: '',
      finalize_dates: [],
      deductions: []
    }
  },
  mounted() {
    this.config = {
      headers: {'Authorization': "Bearer " + this.$store.state.token}
    };
    this.getLedger()
    this.getSources()
    this.getEarnings()
    // this.getPayrollSettings()
  },
  computed : {
    years() {
      const year = new Date().getFullYear() + 5;
      return Array.from({length: (year-1970) + 1},(value,index)=>year-index)
    },
    filteredDeductions() {
      let deductions =  this.deductions.filter((deduction) => {
        return deduction.type=='Loan' || deduction.type=='GSIS LOANS' || deduction.type=='other insurance' || deduction.type=='GSIS Other Deds.' || deduction.type=='Pagibig Group'
      })
      deductions.push({id:'GSIS Contri',code:'GSIS Contribution',type:'Contribution'})
      deductions.push({id:'PHILHEALTH Contri',code:'PHILHEALTH Contribution',type:'Contribution'})
      deductions.push({id:'PAG-IBIG Contri',code:'PAGIBIG Contribution',type:'Contribution'})
      deductions.push({id:'SSS Contri',code:'SSS Contribution',type:'Contribution'})
      deductions.push({id:'Gsis Retro',code:'GSIS Retro',type:'Retro'})
      deductions.push({id:'PhilHealth Retro',code:'PHILHEALTH Retro',type:'Retro'})

      deductions.sort(function(a,b) {
        //console.log(a,b)
        if (a.code<b.code) return -1
        if (a.code>b.code) return 1
        return 0
      })

      return deductions
    }
  },
  methods: {
    changeYear() {
      this.search_query = ''
      if (this.year.length == 4) {
        this.isLoading = true
        this.$http.get(`${this.$host}/api/payroll/search/${this.year}`,
          this.config
        ).then((response) => {
          let data = response.data
          this.ledger = data
          this.isLoading = false
        }).catch((error) => {
          this.isLoading = false
          //console.log(error)
        });
      }
      else if (this.year.length == 0) {
        this.getLedger()
      }
    },
    showDTR(dtr) {
      this.dtr = dtr
      this.isShowDTR = true
    },
    closeDTR() {
      this.dtr = {}
      this.isShowDTR = false
    },
    viewPayslip(payroll) {
      this.payslip = payroll.payslip
      this.payroll_id = payroll.id
      this.employment_status = payroll.employment_status
      this.isRecords = true
    },
    getLedger() {
      this.isLoading = true

      this.$http.get(`${this.$host}/api/payroll/search`,
        this.config
      ).then((response) => {
        let data = response.data
        this.ledger = data
        this.isLoading = false
      }).catch((error) => {
        this.isLoading = false
        //console.log(error)
      });
    },
    getPayrollSettings() {
      this.isLoading = true

      this.$http.get(`${this.$host}/api/payroll/setting/search/${this.payroll_id}`,
        this.config
      ).then((response) => {
        let data = response.data
        if (data) {
          if (data.ot_allowed) {
            this.ot_allowed = data.ot_allowed
          }
        }
      }).catch((error) => {
        this.isLoading = false
        //console.log(error)
      });
    },
    openRunPayroll() {
      this.isRunPayroll = true
    },
    printPS() {
      this.$http.post(`${this.$host}/api/report/generate/${this.payroll_id}`,
        {},
        this.config
      ).then((response) => {
        this.isLoading = false
        let token = response.data

        let url = `${this.$host}/api/report/payroll/payslip/${token}`
        let win = window.open(url, '_blank')
        win.focus()
        this.isPrint = false
      }).catch((error) => {
        //console.log(error)
        this.isLoading = false
      });
    },
    openEncodeOR() {
      this.isEncodeOR = true;
    },
    getSources() {
      this.isLoading = true;
      this.$http.get(`${this.$host}/api/payroll/funding-source/show/`,
        this.config
      ).then((response) => {
        let data = response.data
        this.sources = data
        this.isLoading = false
      }).catch((error) => {
        this.isLoading = false
        //console.log(error)
      });
    },
    getEarnings() {
      this.isLoading = true;
      this.$http.get(`${this.$host}/api/parameter/payroll/run`,
        this.config
      ).then((response) => {
        let data = response.data
        this.earnings = data.earnings
        this.vouchers = data.vouchers
        this.deductions = data.deductions
        this.isLoading = false
      }).catch((error) => {
        this.isLoading = false
        //console.log(error)
      });
    },
    getFinalizeDates() {
      var params = {
        params: this.orParams
      }

      if (this.orParams.payroll_group!=4){
        this.orParams.day = '';
      }
      if (this.orParams.is_finalize==1) {
        var config = Object.assign(this.config,params)
        this.isLoading=true
        this.$http.get(`${this.$host}/api/parameter/payroll/finalize_dates`,
          config,
        ).then((response) => {
          this.finalize_dates = response.data.data;
          this.isLoading=false
        }).catch((error) => {
          this.isLoading=false
        });
      }
    },
    encodeOR() {
      this.isLoading = true
      this.$http.post(`${this.$host}/api/payroll/or`,
        this.orParams,
        this.config
      ).then((response) => {
        this.isLoading = false
        //console.log(response)
        this.$toast.open({
          message: response.data.message,
          type: 'is-success',
          position: 'is-bottom-right',
          duration: 4000
        })
      }).catch((error) => {
        this.isLoading = false
        //console.log(error)
      });
    }
  }
}
</script>

<style>

</style>
