<template>
  <form>
    <div class="modal-card pos" style="width: 60rem; height: 35.625rem; margin-left: 100px;">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ title }} Types</p>
      </header>
      <section class="modal-card-body">
        <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false"></b-loading>
        <button class="button is-success" @click.prevent="addType()" v-if="$role.state.permissions['write payroll'] || $role.state.permissions['write payroll funding source']">
          <span><i class="fa fa-plus"></i> Add {{ title }} Type</span>
        </button>
        <hr>
        <b-table
          :data="filter"
          focusable
          :paginated="true"
          :per-page="5"
          bordered="true">
          <template slot-scope="props">
            <b-table-column label="Code">
              {{ props.row.code }}
            </b-table-column>
            <b-table-column label="Description">
              {{ props.row.description }}
            </b-table-column>
            <b-table-column label="Deductions before tax" v-if="title == 'Deduction'">
              {{ yesNo(props.row.is_mandatory) }}
            </b-table-column>
            <b-table-column label="Actions">
              <button class="button btn-action is-primary is-small" @click.prevent="addType(props.row)"><i class="fa fa-pen"></i></button>
              <button class="button btn-action is-danger is-small" @click.prevent="deleteType(props.row.id)"><i class="fa fa-trash"></i></button>
            </b-table-column>
          </template>
          <template slot="empty">
            <b-table-column label="Code">
            </b-table-column>
            <b-table-column label="Description">
            </b-table-column>
            <b-table-column label="Deductions before tax" v-if="title == 'Deduction'">
            </b-table-column>
            <b-table-column label="Actions">
            </b-table-column>
          </template>
        </b-table>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-danger" type="button" @click.prevent="$parent.close()">Close</button>
      </footer>
    </div>

    <b-modal :active.sync="isSave" has-modal-card :canCancel=false>
      <transition name="fade">
        <form @submit.prevent="$emit('save', type)">
          <div class="modal-card" style="height: 35.625rem; margin-top: 4rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">{{ state }} {{ title }}</p>
            </header>
            <section class="modal-card-body">
              <b-field label="Code">
                <b-input
                  v-model="type.code"
                  :disabled="state == 'Edit'"
                  expanded required>
                </b-input>
              </b-field>
              <b-field label="Description">
                <b-input
                  v-model="type.description"
                  expanded required>
                </b-input>
              </b-field>
              <b-field label="Deductions before tax" v-if="title == 'Deduction'">
                <b-checkbox true-value="1" false-value="0" v-model="type.is_mandatory"></b-checkbox>
              </b-field>
              <br>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" type="submit">Save</button>
              <button class="button is-danger" type="button" @click.prevent="close()">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>
  </form>
</template>

<script>
export default {
  props: ['title', 'types', 'isSave'],
  data() {
    return {
      type: {},
      state: '',
    }
  },
  mounted() {
    this.$emit('getTypes')

    if (this.title == 'Deduction') {
      this.type = {
        is_mandatory: 0
      }
    }
  },
  computed: {
    filter() {
      var i;
      var name_re = new RegExp(this.search_query, 'i')
      var data = []
      for (i in this.types) {
        if ([this.types[i].code, this.types[i].desription].join(' ').match(name_re)) {
          data.push(this.types[i])
        }
      }
      return data
    }
  },
  methods: {
    yesNo(bool) {
      if (bool == 1) {
        return 'YES'
      }
      else if (bool == 0) {
        return 'NO'
      }
      else {
        return 'INVALID'
      }
    },
    addType(obj) {
      if (obj) {
        this.type = obj
        this.state = 'Edit'
      }
      else {
        this.state = 'Add'
      }
      this.isSave = true
    },
    deleteType(id) {
      this.$dialog.confirm({
        title: 'Delete ' + this.title,
        message: "Are you sure you want to delete this row? This action cannot be undone.",
        confirmText: 'Delete',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$emit('delete', id)
        }
      })
    },
    close() {
      this.$emit('getTypes')
      this.isSave = false
      this.type = {}
    },
  }
}
</script>

<style>

</style>
