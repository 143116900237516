<template>
  <div v-if="this.$editmode.state.isUpdate">
    <div class="columns header" style="margin-bottom: 0 !important;">
      <!-- <div class="column is-7" style="margin-left: 5px; font-size: 20px; text-transform: uppercase;">
        Name: <b>{{ name }}</b> <span style="margin-left: 10px;">Department: <b>{{ department }}</b></span>
      </div> -->
      <div class="column is-auto">
        <button class="button is-primary is-pulled-right" style="margin-left: 10px;" @click.prevent="listUsers()" v-if="$role.state.permissions['search employee']">
          <i class="fa fa-search"></i> &nbsp;Search
        </button>
        <button class="button is-success is-pulled-right" style="margin-left: 10px;"  @click.prevent="newEmployee()" v-if="$role.state.permissions['write employee']">
          <i class="fa fa-plus"></i> &nbsp;New Employee
        </button>
        <button class="button is-primary is-pulled-right" @click.prevent="printPDS()" v-if="$role.state.permissions['print employee']">
          <span><i class="fa fa-print"></i> Print</span>
        </button>
      </div>
    </div>
    <EmployeeTabs v-bind:id="id" @isEdit="isEdit" :isAdd="isAdd" v-if="!isSearch" @refreshHeaderName="refreshName"/>
  </div>
</template>

<script>
import EmployeeSearch from '../components/SearchModal.vue'
import EmployeeTabs from '../components/EmployeeTabs.vue'
import EmployeeAdd from './EmployeeAdd.vue'

export default {
  name: 'Employee',
  components: {
    EmployeeSearch,
    EmployeeTabs
  },
  data() {
    return {
      isSearch: false,
      id: 'self',
      name: '',
      edit: false,
      isAdd: false,
      department: '',
      isScrolled: false
    }
  },
  mounted() {
    this.isLoading = true
    let config = {
      headers: {'Authorization': "Bearer " + this.$store.state.token}
    };

    this.$http.get(`${this.$host}/api/user`,
      config
    ).then((response) => {
      if (response.data.data.employee != null) {
        let data = response.data.data.employee
        this.$editmode.commit('setUpdate', response.data.data.is_update)
        this.isLoading = false
      }
      else {
        this.isLoading = false
      }
    }).catch((error) => {
      this.isLoading = false
      //console.log(error)
    });
  },
  methods: {
    scrollPrint() {
      if (window.scrollY != 0) {
        this.isScrolled = true
      }
      else {
        this.isScrolled = false
      }
    },
    isEdit(bool) {
      this.edit = bool
    },
    listUsers() {
      if (this.edit == true) {
        this.$dialog.confirm({
          title: 'Discard Changes',
          message: 'You are currently editing data. Are you sure you want to <b>discard</b> your changes? This action cannot be undone.',
          confirmText: 'Discard',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: () => {this.isSearch = true, this.edit = false}
        })
      }
      else {
        this.isSearch = true
      }
    },
    newEmployee() {
      if (this.edit == true) {
        this.$dialog.confirm({
          title: 'Discard Changes',
          message: 'You are currently editing data. Are you sure you want to <b>discard</b> your changes? This action cannot be undone.',
          confirmText: 'Discard',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: () => {this.$router.push({path: '/employees/add'}), this.edit = false}
        })
      }
      else {
        this.$router.push({path: '/employees/add'})
      }
    },
    refreshName(obj) {
      this.name = obj
    },
    close() {
      if (this.id) {
        this.isSearch = false
      }
    },
    view_employee(selected) {
      if (selected.id == undefined) {

      }
      else {
        if (selected.name_extension == null) {
          this.name = `${selected.first_name} ${selected.last_name}`
        }
        else {
          this.name = `${selected.first_name} ${selected.last_name} ${selected.name_extension}`
        }
        this.department = selected.division
        this.id = selected.id
        this.isSearch = false
      }
    },
    printPDS() {
      let url = `${this.$host}/api/report/pds/${this.$role.state.employee_id}`
      let win = window.open(url, '_blank')
      win.focus()
    }
  }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.scroll-print {
  position: fixed;
  z-index: 3;
  top: 90%;
  left: 87%;
}
@media screen and (min-width: 1px) and (max-width: 768px)  {
  .scroll-print {
    left: 55%
  }
}
</style>
