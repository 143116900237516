<template>
  <div class="box">
    <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
    <div>
      <button class="button is-primary" @click.prevent="assign()" v-if="$role.state.permissions['write payroll'] || $role.state.permissions['write payroll funding source'] || $role.state.permissions['write schedule']">
        <span><i class="fa fa-user-plus"></i> Assign Settings</span>
      </button>
    </div>

    <b-modal :active.sync="isAssign" has-modal-card :canCancel=false>
      <transition name="fade">
        <form @submit.prevent="save()">
          <div class="modal-card pos" style="width: 60rem; height: 35.625rem; margin-left: 100px;">
            <header class="modal-card-head">
              <p class="modal-card-title">Assign Employee Settings</p>
            </header>
            <section class="modal-card-body">
              <b-field label="Selected">
                <button class="button is-primary" @click.prevent="isSelect = true" v-if="$role.state.permissions['write payroll'] || $role.state.permissions['write payroll funding source'] || $role.state.permissions['write schedule']">
                  <span><i class="fa fa-user-plus"></i> Select Employee</span>
                </button>
              </b-field>
              <!-- <b-input type="textarea" v-model="selected" disabled></b-input> -->
              <div style="overflow: auto; max-height: 10rem;">
                <b-tag v-for="emp in checkedRows" :key="emp.id" type="is-primary" closable @close="removeSelected(emp)"
                style="margin-right: 5px; margin-top:4px;">
                  {{ emp.fullname }}
                </b-tag>
              </div>
              <br>
              <b-field>
                <b-checkbox v-model="is_ot"
                  true-value=1
                  false-value=0
                  @input="isOt()">
                  <b>OT Allowed</b>
                </b-checkbox>
              </b-field>
              <b-field>
                <b-field v-if="is_ot == 1">
                  <b-input
                    v-model="setting.ot_allowed"
                    type="number"
                    min="0"
                    expanded required>
                  </b-input>
                </b-field>
              </b-field>
              <b-field>
                <b-checkbox v-model="is_jo"
                  true-value=1
                  false-value=''
                  @input="getList()">
                  <b>Hours Per Month (JO Only)</b>
                </b-checkbox>
              </b-field>
              <b-field>
                <b-field v-if="is_jo == 1">
                  <b-input
                    v-model="setting.hours_per_month"
                    type="number"
                    min="0"
                    expanded required>
                  </b-input>
                </b-field>
              </b-field>
              <b-field>
                <b-checkbox v-model="setting.is_exempted"
                  true-value=1
                  false-value=0>
                  <b>Does Not Use Timekeeping</b>
                </b-checkbox>
              </b-field>
              <b-field>
                <b-checkbox v-model="is_change_schedule"
                  true-value=1
                  false-value=0
                  @input="setSchedule">
                  <b>Change Schedule</b>
                </b-checkbox>
              </b-field>
              <b-field v-if="is_change_schedule==1">
                <b-field label="Code" style="padding-left:10px">
                  <b-select placeholder="Code" v-model="setting.schedule.id" expanded>
                    <option
                      v-for="option in templates"
                      :value="option.id"
                      :key="option.id">
                      {{ option.code }}
                    </option>
                  </b-select>
                </b-field>
                <b-field label="Effective Date" style="padding-left:10px">
                  <b-datepicker
                    placeholder="Effective Date"
                    icon="calendar-today"
                    v-model="setting.schedule.effective_date"
                    editable>
                  </b-datepicker>
                </b-field>
                <b-field label="Type" style="padding-left:10px">
                  <b-select placeholder="Type" v-model="setting.schedule.type" expanded>
                    <option value="Day">Day</option>
                    <option value="Permanent">Permanent</option>
                  </b-select>
                </b-field>
              </b-field>
              <br>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" type="submit">Save</button>
              <button class="button is-danger" type="button" @click.prevent="close()">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>

    <b-modal :active.sync="isSelect" has-modal-card :canCancel=false>
      <transition name="fade">
        <form @submit.prevent="select()">
          <div class="modal-card pos" style="width: 60rem; height: 35.625rem; margin-left: 100px;">
            <header class="modal-card-head">
              <p class="modal-card-title">Assign Settings</p>
            </header>
            <section class="modal-card-body">
              <b-field label="Filters"></b-field>
              <b-field grouped group-multiline>
                <b-select v-model="perPage">
                  <option value="10">10 per page</option>
                  <option value="50">50 per page</option>
                  <option value="100">100 per page</option>
                  <option value="1000">1000 per page</option>
                </b-select>
                <b-input v-model="search_list" placeholder="Search Employee" expanded/>
              </b-field>
              <br>
              <b-table
                :data="filterList"
                :striped=true
                :bordered=true
                :hoverable=true
                :paginated="true"
                :per-page="perPage"
                :checked-rows.sync="checkedRows"
                checkable>
                <template slot-scope="props">
                  <b-table-column label="Emp. No." width="100">
                    {{ props.row.employee_number }}
                  </b-table-column>
                  <b-table-column label="Full Name">
                    {{ props.row.fullname }}
                  </b-table-column>
                  <b-table-column label="Position">
                    {{ props.row.position }}
                  </b-table-column>
                  <b-table-column label="Division">
                    {{ props.row.division }}
                  </b-table-column>
                </template>
                <template slot="empty">

                </template>
              </b-table>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" type="submit">Select</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      config: '',
      isLoading: false,
      isAssign: false,
      isSelect: false,
      setting: {
        is_exempted: 0,
      },
      list: [],
      search_list: '',
      checkedRows: [],
      selectFilter: 'employees',
      sortValue: '',
      perPage: 10,
      sources: [],
      is_jo: '',
      is_ot: 0,
      is_change_schedule: 0,
      templates: [],
    }
  },
  mounted() {
    this.config = {
      headers: {'Authorization': "Bearer " + this.$store.state.token}
    };

    this.getList()
    this.getFundingSource()
    this.getSchedules()
  },
  computed: {
    filterList() {
      var i;
      var name_re = new RegExp(this.search_list, 'i')
      var data = []
      for (i in this.list) {
        if ([this.list[i].employee_number, this.list[i].employee_number, this.list[i].fullname, this.list[i].position, this.list[i].division].join(' ').match(name_re)) {
          data.push(this.list[i])
        }
      }
      return data
    },
    // selected() {
    //   return this.checkedRows.map(x => x == this.checkedRows[0] ? x.fullname : ' ' + x.fullname)
    // }
  },
  methods: {
    getSchedules() {
      this.$http.get(`${this.$host}/api/schedule/all`,
        this.config
      ).then((response) => {
        let data = response.data
        this.templates = data
        ////console.log(data)
      }).catch((error) => {
        ////console.log(error)
      });
    },
    setSchedule() {
      if (this.is_change_schedule==0) {
        delete this.setting.schedule;
      } else {
        this.setting.schedule = {
          id: null,
          effective_date: new Date(),
          type: 'Day'
        }
      }
    },
    assign() {
      this.isAssign = true
    },
    close() {
      this.getList()
      this.getFundingSource()
      this.isAssign = false
    },
    isOt() {
      if (this.is_ot == 0) {
        delete this.setting.ot_allowed
      }
    },
    getList() {
      this.isLoading = true

      this.$http.get(`${this.$host}/api/payroll/setting/list/?sort=${this.sortValue}&is_jo=${this.is_jo}`,
        this.config
      ).then((response) => {
        let data = response.data
        this.list = data
        this.isLoading = false
        this.checkedRows = []
      }).catch((error) => {
        this.isLoading = false
        //console.log(error)
      });
    },
    getFundingSource() {
      this.isLoading = true

      this.$http.get(`${this.$host}/api/payroll/funding-source/show/`,
        this.config
      ).then((response) => {
        let data = response.data
        this.sources = data
        this.checkedRows = []
        this.isLoading = false
      }).catch((error) => {
        this.isLoading = false
        //console.log(error)
      });
    },
    closeSelect() {
      this.isSelect = false
    },
    select() {
      this.isSelect = false
    },
    removeSelected(emp) {
      this.checkedRows = this.checkedRows.filter(x => x !== emp)
    },
    save() {
      this.isLoading = true
      this.setting.payroll_id = this.checkedRows.map(x => x = x.payroll_setting_id)
      // this.seeting.effective_date = this.setting.effective_date.toLocaleDateString()
      this.$http.post(`${this.$host}/api/payroll/setting/assign/`,
        this.setting,
        this.config,
      ).then((response) => {
        let data = response.data
        this.$toast.open({
          message: 'Employee Settings Updated.',
          type: 'is-success',
          position: 'is-bottom-right',
          duration: 4000
        })
        //console.log(data)
        this.isLoading = false
      }).catch((error) => {
        // //console.log(error);
        this.$toast.open({
          message: 'Error saving data.',
          type: 'is-danger',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.isLoading = false
      });
    }
  }
}
</script>

<style>

</style>
