<template>
  <div>
    <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
    <div class="columns header" style="margin-bottom: 0 !important;">
      <div class="column is-5" style="margin-left: 5px; font-size: 20px; text-transform: uppercase;">
        Schedule for: <b>{{ employee.first_name }} {{ employee.middle_name }} {{ employee.last_name }} {{ employee.name_extension }}</b>
      </div>
      <div class="column is-auto">
        <button v-if="$role.state.permissions['write daily time record']" class="button is-primary is-pulled-right is-hidden-mobile" style="margin-left: 10px;" @click.prevent="$router.push({ name: 'holidays' })">
          <i class="fa fa-calendar-day"></i> &nbsp;Special Dates
        </button>
        <button v-if="$role.state.permissions['read work schedule template']" class="button is-primary is-pulled-right is-hidden-mobile" style="margin-left: 10px;" @click.prevent="$router.push('/schedule/templates')">
          <i class="fa fa-clipboard-list"></i> &nbsp;Manage Templates
        </button>
        <button v-if="$role.state.permissions['search daily time record']" class="button is-primary is-pulled-right is-hidden-mobile" style="margin-left: 10px;" @click.prevent="isSearch = true">
          <i class="fa fa-user-tie"></i> &nbsp;Employees
        </button>
        <button class="button is-primary is-pulled-right is-hidden-mobile" style="margin-left: 10px;" @click.prevent="isAttach = true">
          <i class="fa fa-user-tie"></i> &nbsp;Attach Files
        </button>
        <button v-if="$role.state.permissions['write daily time record']" class="button is-primary is-hidden-tablet" @click.prevent="$router.push({ name: 'holidays' })">
          <i class="fa fa-calendar-day"></i> &nbsp;Special Dates
        </button>
        <button v-if="$role.state.permissions['search daily time record']" class="button is-primary is-hidden-tablet" @click.prevent="isSearch = true">
          <i class="fa fa-user-tie"></i> &nbsp;Employees
        </button>
        <button v-if="$role.state.permissions['read work schedule template']" class="button is-primary is-hidden-tablet" @click.prevent="$router.push('/schedule/templates')">
          <i class="fa fa-clipboard-list"></i> &nbsp;Templates
        </button>
        <button class="button is-primary is-pulled-right" @click.prevent="setExempt()" v-if="$role.state.permissions['write schedule']">
          <span><i class="fa fa-cog"></i> Settings</span>
        </button>
      </div>
    </div>
    <hr style="margin-top: 0 !important; margin-bottom: 0 !important;" class="has-background-grey-lighter is-hidden-mobile">
    <br>
    <div class="columns">
      <div class="column is-3">
        <div class="box">
          <div>
            <b-field label="Schedule Today">
            </b-field>
          </div>
          <hr style="margin-bottom: 12px !important;">
          <!-- <b>Effective Date:</b> {{ schedule.effective }} <br>
          <b>Monday:</b> {{ schedule.monday }} <br>
          <b>Tuesday:</b> {{ schedule.tuesday }} <br>
          <b>Wednesday:</b> {{ schedule.wednesday }} <br>
          <b>Thursday:</b> {{ schedule.thursday }} <br>
          <b>Friday:</b> {{ schedule.friday }} <br>
          <b>Saturday:</b> {{ schedule.saturday }} <br>
          <b>Sunday:</b> {{ schedule.sunday }} <br> -->
          <b>Code:</b> {{ schedule.code }} <br>
          <b>Description:</b><br> {{ schedule.description }} <br>
          <b>Time In:</b> {{ schedule.time_in }} <br>
          <b>Lunch Out:</b> {{ schedule.lunch_out }} <br>
          <b>Lunch In:</b> {{ schedule.lunch_in }} <br>
          <b>Time Out:</b> {{ schedule.time_out }} <br>
        </div>
        <!-- <button class="button is-success" @click.prevent="promptVerify" :disabled="!$editmode.state.verify">
          <i class="fa fa-calendar-check"></i> &nbsp;Verify DTR
        </button> -->
        <!-- <button class="button is-primary" @click.prevent="viewLog">
          <i class="fa fa-clipboard-list"></i> &nbsp;Time Log
        </button> -->
      </div>
      <div class="column is-auto">
        <div class="box">
          <div>
            <b-field label="Weekly Timesheet">
            </b-field>
          </div>
          <hr style="margin-bottom: 0 !important;">
          <b-table
          :data="timesheet"
          :hoverable=true>
            <template slot-scope="props">
              <b-table-column label="Date">
                {{ props.row.ref_date }}
              </b-table-column>
              <!-- <b-table-column label="Code">
                {{ props.row.schedule.code }}
              </b-table-column> -->
              <b-table-column label="Time In">
                {{ props.row.time_in.time }}
              </b-table-column>
              <b-table-column label="Lunch Out">
                {{ props.row.lunch_out.time }}
              </b-table-column>
              <b-table-column label="Lunch In">
                {{ props.row.lunch_in.time }}
              </b-table-column>
              <b-table-column label="Time Out">
                {{ props.row.time_out.time }}
              </b-table-column>
              <b-table-column label="Actions">
                <button class="button btn-action is-primary is-small" @click.prevent="editWeekly(props.row)" v-if="$role.state.permissions['write daily time record']"><i class="fa fa-pen"></i></button>
                <button class="button btn-action is-primary is-small" @click.prevent="viewLog(props.row.ref_date)" v-if="$role.state.permissions['write daily time record']"><i class="fa fa-clipboard"></i></button>
                <button class="button btn=action is-primary is-small" @click.prevent="showAttachment(props.row.attachment)" v-if="props.row.attachment != null"><i class="fa fa-file"></i></button>
              </b-table-column>
            </template>
            <template slot="empty">
              <b-table-column label="Date">
              </b-table-column>
              <!-- <b-table-column label="Code">
              </b-table-column> -->
              <b-table-column label="Time In">
              </b-table-column>
              <b-table-column label="Lunch Out">
              </b-table-column>
              <b-table-column label="Lunch In">
              </b-table-column>
              <b-table-column label="Time Out">
              </b-table-column>
              <b-table-column label="Actions">
              </b-table-column>
            </template>
          </b-table>
        </div>
      </div>
    </div>
    <div class="box">
      <div>
        <b-field label="Timesheet History">
        </b-field>
        <div v-if="isAdviceDtrGenerate && $role.state.permissions['write daily time record'] && employee.id != ''" class="warning-box">
            <p><i class="fa fa-exclamation-triangle"></i> DTR of this employee for this month is not fully generated.</p> 
            <button class="button is-primary control" @click.prevent="generateMontlyDTR(perMonth)">
              <i class="fa fa-redo-alt"></i> &nbsp;Generate DTR
            </button>
        </div>
        <div v-if="isAdviceDtrGenerate && !$role.state.permissions['write daily time record'] && employee.id != ''" class="warning-box">
            <p><i class="fa fa-exclamation-triangle"></i> Your daily time record for this month has discrepancy. Please contact HR.</p>
        </div>
        <b-field grouped group-multiline>
          <button class="button is-primary control" @click.prevent="openPrintDTR()">
            <i class="fa fa-print"></i> &nbsp;Print DTR
          </button>
          <button class="button is-success control" @click.prevent="resetFilter">
            <i class="fa fa-redo-alt"></i> &nbsp;Reset Filter
          </button>
          <b-select v-model="perPage">
            <option value="5">5 per page</option>
            <option value="10">10 per page</option>
            <option value="15">15 per page</option>
            <option value="20">20 per page</option>
            <option value="31">31 per page</option>
          </b-select>
          <b-select v-model="perMonth" @input="dtrMonthFilter(perMonth)" placeholder="month">
            <option v-for="monthName in monthNames" :key="monthName" :value="monthName">{{monthName}}</option>
            <!-- <option value="January">January</option>
            <option value="February">February</option>
            <option value="March">March</option>
            <option value="April">April</option>
            <option value="May">May</option>
            <option value="June">June</option>
            <option value="July">July</option>
            <option value="August">August</option>
            <option value="September">September</option>
            <option value="October">October</option>
            <option value="November">November</option>
            <option value="December">December</option> -->
          </b-select>
          <b-datepicker
            placeholder="date from"
            icon="calendar-today"
            v-model="filterFrom"
            @input="dtrRangeFilter"
            :date-formatter="d => d.toLocaleDateString('en-GB')">
          </b-datepicker>
          <b-datepicker
            placeholder="date to"
            icon="calendar-today"
            v-model="filterTo"
            @input="dtrRangeFilter"
            :date-formatter="d => d.toLocaleDateString('en-GB')">
          </b-datepicker>
        </b-field>
      </div>
      <hr>
      <b-table
      :data="history"
      :bordered=true
      :hoverable=true
      :paginated="true"
      :current-page.sync="currentPage"
      :per-page="perPage">
        <template slot-scope="props">
          <b-table-column label="Date">
            {{ props.row.date }}
          </b-table-column>
          <b-table-column label="AM IN">
            {{ props.row.am.in }}
          </b-table-column>
          <b-table-column label="AM OUT">
            {{ props.row.am.out }}
          </b-table-column>
          <b-table-column label="PM IN">
            {{ props.row.pm.in }}
          </b-table-column>
          <b-table-column label="PM OUT">
            {{ props.row.pm.out }}
          </b-table-column>
          <b-table-column label="Late/UT/HD">
            {{ props.row.difference }}
          </b-table-column>
          <b-table-column label="OT">
            {{ props.row.ot }}
          </b-table-column>
          <b-table-column label="Remarks">
            {{ props.row.remarks }}
          </b-table-column>
          <b-table-column label="Actions">
            <button class="button btn-action is-primary is-small" @click.prevent="editDtr(props.row)" v-if="$role.state.permissions['write daily time record']"><i class="fa fa-pen"></i></button>
            <button class="button btn-action is-primary is-small" @click.prevent="viewLog(props.row.id)" v-if="$role.state.permissions['write daily time record']"><i class="fa fa-clipboard"></i></button>
            <button class="button btn=action is-primary is-small" @click.prevent="showAttachment(props.row.attachment)" v-if="props.row.attachment != null"><i class="fa fa-file"></i></button>
          </b-table-column>
        </template>
        <template slot="empty">
          <center><b>NO RECORD</b></center>
        </template>
      </b-table>
      <hr style="margin-top: 0;">
      <b>Total:</b> Difference: <b>{{ total.difference }}</b> Overtime: <b>{{ total.ot }}</b><b> Total Work Hours: </b>{{ total.work_hours }}<br>
      <b>Summary:</b>
      Unfiled Leave: <b>{{ summary.unfiled_leave }}</b>
      &nbsp;Late: <b>{{ summary.late }}</b>
      &nbsp;Undertime: <b>{{ summary.undertime }}</b>
      &nbsp;Total Late/UT/HD in Days: <b>{{ summary.days_of_difference }}</b>
    </div>
    <div class="box">
      <div>
        <b-field label="Schedule History">
        </b-field>
        <button class="button is-success" @click.prevent="isChangeSchedule = true" v-if="$role.state.permissions['write schedule']">
          <span><i class="fa fa-clipboard-list"></i> Change Schedule</span>
        </button>
        <!-- <button class="button is-success control" @click.prevent="isDetach = true">
          <i class="fa fa-calendar-times"></i> &nbsp;Detach a Schedule
        </button> -->
      </div>
      <hr>
      <b-table
      :data="scheduleHistory"
      :bordered=true
      :hoverable=true
      :paginated="true"
      :per-page="5">
        <template slot-scope="props">
          <b-table-column label="Date">
            {{ props.row.date }}
          </b-table-column>
          <b-table-column label="Code">
            {{ props.row.schedule.code }}
          </b-table-column>
          <b-table-column label="Description">
            {{ props.row.schedule.description }}
          </b-table-column>
          <b-table-column label="Actions">
            <button class="button btn-action is-primary is-small" @click.prevent="viewHistory(props.row)"><i class="fa fa-eye"></i></button>
            <button v-if="$role.state.permissions['write schedule']" class="button btn-action is-danger is-small" @click.prevent="detachSchedule(props.row)"><i class="fa fa-calendar-times"></i></button>
          </b-table-column>
        </template>
        <template slot="empty">
          <b-table-column label="Date">
          </b-table-column>
          <b-table-column label="Code">
          </b-table-column>
          <b-table-column label="Description">
          </b-table-column>
          <b-table-column label="Actions">
          </b-table-column>
        </template>
      </b-table>
    </div>
    <b-modal :active.sync="isComponentModalActive" has-modal-card :canCancel=false>
      <transition name="fade">
        <form action="">
          <div class="modal-card" style="height: 35.625rem; margin-top: 4rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">Edit {{ e_schedule.schedule.code }}</p>
            </header>
            <section class="modal-card-body">
              <!-- <b-field>
                <b-checkbox v-model="e_schedule.is_excuse"
                  true-value=1
                  false-value=0>
                </b-checkbox>
                <b-field label="Excuse">
                </b-field>
              </b-field> -->
              <!-- <b-field label="Effective Date">
                <b-field>
                  <b-input v-model="e_schedule.ref_date" expanded disabled></b-input>
                </b-field>
              </b-field>
              <b-field label="Code">
                <b-field>
                  <b-select placeholder="Code" v-model="e_schedule.schedule.code" expanded disabled>
                    <option
                      v-for="option in templates"
                      :value="option.code"
                      :key="option.id">
                      {{ option.code }}
                    </option>
                  </b-select>
                </b-field>
              </b-field> -->
              <b-field v-if="exempt == 1">
                <b-checkbox v-model="is_absent"
                  true-value=1
                  false-value=0
                  @input="isAbsent()">
                  <b>Absent</b>
                </b-checkbox>
              </b-field>
              <b-field label="Time In">
                <b-field>
                  <b-datepicker
                    placeholder="Reference Date"
                    icon="calendar-today"
                    v-model="e_schedule.time_in.date"
                    :date-formatter="d => d.toLocaleDateString('en-GB')">
                  </b-datepicker>
                  <b-input v-model="e_schedule.time_in.time" expanded></b-input>
                </b-field>
              </b-field>
              <b-field label="Lunch Out">
                <b-field>
                  <b-datepicker
                    placeholder="Reference Date"
                    icon="calendar-today"
                    v-model="e_schedule.lunch_out.date"
                    :date-formatter="d => d.toLocaleDateString('en-GB')">
                  </b-datepicker>
                  <b-input v-model="e_schedule.lunch_out.time" expanded></b-input>
                </b-field>
              </b-field>
              <b-field label="Lunch In">
                <b-field>
                  <b-datepicker
                    placeholder="Reference Date"
                    icon="calendar-today"
                    v-model="e_schedule.lunch_in.date"
                    :date-formatter="d => d.toLocaleDateString('en-GB')">
                  </b-datepicker>
                  <b-input v-model="e_schedule.lunch_in.time" expanded></b-input>
                </b-field>
              </b-field>
              <b-field label="Time Out">
                <b-field>
                  <b-datepicker
                    placeholder="Reference Date"
                    icon="calendar-today"
                    v-model="e_schedule.time_out.date"
                    :date-formatter="d => d.toLocaleDateString('en-GB')">
                  </b-datepicker>
                  <b-input v-model="e_schedule.time_out.time" expanded></b-input>
                </b-field>
              </b-field>
              <b-field label="Reason">
                <b-field>
                  <b-input type="textarea" v-model="e_schedule.reason" expanded required ></b-input>
                </b-field>
              </b-field>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" @click.prevent="saveWeekly">Save</button>
              <button class="button is-danger" @click.prevent="cancelWeekly">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>
    <b-modal :active.sync="historyModal" has-modal-card :canCancel=false>
      <transition name="fade">
        <form action="">
          <div class="modal-card" style="height: 35.625rem; margin-top: 4rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">View</p>
              <button style="border: none; background-color: transparent; font-size: 1.25rem" class="button has-text-danger" @click.prevent="historyModal = false">
                <span class="icon"><i class="fa fa-times"></i></span>
              </button>
            </header>
            <section class="modal-card-body">
              <b>Effective Date:</b> {{ v_scheduleHistory.date }} <br>
              <b>Code:</b> {{ v_scheduleHistory.schedule.code }} <br>
              <b>Description:</b> {{ v_scheduleHistory.schedule.description }} <br><br>
              <b>MONDAY</b><br>
              <b>Time In:</b> {{ v_scheduleHistory.schedule.mon_time_in }} <br>
              <b>Lunch Out:</b> {{ v_scheduleHistory.schedule.mon_lunch_out }} <br>
              <b>Lunch In:</b> {{ v_scheduleHistory.schedule.mon_lunch_in }} <br>
              <b>Time Out:</b> {{ v_scheduleHistory.schedule.mon_time_out }} <br><br>
              <b>TUESDAY</b><br>
              <b>Time In:</b> {{ v_scheduleHistory.schedule.tue_time_in }} <br>
              <b>Lunch Out:</b> {{ v_scheduleHistory.schedule.tue_lunch_out }} <br>
              <b>Lunch In:</b> {{ v_scheduleHistory.schedule.tue_lunch_in }} <br>
              <b>Time Out:</b> {{ v_scheduleHistory.schedule.tue_time_out }} <br><br>
              <b>WEDNESDAY</b><br>
              <b>Time In:</b> {{ v_scheduleHistory.schedule.wed_time_in }} <br>
              <b>Lunch Out:</b> {{ v_scheduleHistory.schedule.wed_lunch_out }} <br>
              <b>Lunch In:</b> {{ v_scheduleHistory.schedule.wed_lunch_in }} <br>
              <b>Time Out:</b> {{ v_scheduleHistory.schedule.wed_time_out }} <br><br>
              <b>THURSDAY</b><br>
              <b>Time In:</b> {{ v_scheduleHistory.schedule.thu_time_in }} <br>
              <b>Lunch Out:</b> {{ v_scheduleHistory.schedule.thu_lunch_out }} <br>
              <b>Lunch In:</b> {{ v_scheduleHistory.schedule.thu_lunch_in }} <br>
              <b>Time Out:</b> {{ v_scheduleHistory.schedule.thu_time_out }} <br><br>
              <b>FRIDAY</b><br>
              <b>Time In:</b> {{ v_scheduleHistory.schedule.fri_time_in }} <br>
              <b>Lunch Out:</b> {{ v_scheduleHistory.schedule.fri_lunch_out }} <br>
              <b>Lunch In:</b> {{ v_scheduleHistory.schedule.fri_lunch_in }} <br>
              <b>Time Out:</b> {{ v_scheduleHistory.schedule.fri_time_out }} <br><br>
              <b>SATURDAY</b><br>
              <b>Time In:</b> {{ v_scheduleHistory.schedule.sat_time_in }} <br>
              <b>Lunch Out:</b> {{ v_scheduleHistory.schedule.sat_lunch_out }} <br>
              <b>Lunch In:</b> {{ v_scheduleHistory.schedule.sat_lunch_in }} <br>
              <b>Time Out:</b> {{ v_scheduleHistory.schedule.sat_time_out }} <br><br>
              <b>SUNDAY</b><br>
              <b>Time In:</b> {{ v_scheduleHistory.schedule.sun_time_in }} <br>
              <b>Lunch Out:</b> {{ v_scheduleHistory.schedule.sun_lunch_out }} <br>
              <b>Lunch In:</b> {{ v_scheduleHistory.schedule.sun_lunch_in }} <br>
              <b>Time Out:</b> {{ v_scheduleHistory.schedule.sun_time_out }} <br><br>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" @click.prevent="historyModal = false">Close</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>
    <b-modal :active.sync="isChangeSchedule" has-modal-card :canCancel=false>
      <transition name="fade">
        <form action="">
          <div class="modal-card" style="height: 35.625rem; margin-top: 4rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">Schedule</p>
            </header>
            <section class="modal-card-body">
              <b-field label="Code">
                <b-select placeholder="Code" v-model="newSchedule.schedule.id" expanded>
                  <option
                    v-for="option in templates"
                    :value="option.id"
                    :key="option.id">
                    {{ option.code }}
                  </option>
                </b-select>
              </b-field>
              <b-field label="Effective Date">
                <b-datepicker
                  placeholder="Effective Date"
                  icon="calendar-today"
                  v-model="newSchedule.schedule.effective_date"
                  editable>
                </b-datepicker>
              </b-field>
              <b-field label="Type">
                <b-select placeholder="Type" v-model="newSchedule.schedule.type" expanded>
                  <option value="Day">Day</option>
                  <option value="Permanent">Permanent</option>
                </b-select>
              </b-field>
              <br>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" @click.prevent="saveSchedule">Save</button>
              <button class="button is-danger" @click.prevent="isChangeSchedule = false">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>
    <!-- <b-modal :active.sync="isDetach" has-modal-card :canCancel=false>
      <transition name="fade">
        <form action="">
          <div class="modal-card" style="margin-top: 4rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">Schedule</p>
            </header>
            <section class="modal-card-body">
              <b-field label="Effective Date">
                <b-datepicker
                  placeholder="Effective Date"
                  icon="calendar-today"
                  v-model="detach"
                  :date-formatter="d => d.toLocaleDateString('en-GB')">
                </b-datepicker>
              </b-field>
              <br><br><br><br><br><br><br><br>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" @click.prevent="detachSchedule">Delete</button>
              <button class="button is-danger" @click.prevent="cancelDetach">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal> -->
    <b-modal :active.sync="isLogs" has-modal-card :canCancel=false>
      <transition name="fade">
        <form action="">
          <div class="modal-card" style="height: 35.625rem; margin-top: 4rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">Schedule</p>
            </header>
            <section class="modal-card-body">
              <b>Edit Reason:</b> {{ timeLogReason }}<br><br>
              <b-table
              :data="timeLogs"
              :hoverable=true
              bordered>
                <template slot-scope="props">
                  <b-table-column label="ID">
                    {{ props.row.id }}
                  </b-table-column>
                  <b-table-column label="Ref. Date" width=120>
                    {{ props.row.ref_date }}
                  </b-table-column>
                  <b-table-column label="Post Date">
                    {{ props.row.post_date }}
                  </b-table-column>
                  <b-table-column label="Type">
                    {{ props.row.type }}
                  </b-table-column>
                  <b-table-column label="User">
                    {{ props.row.kiosk_name }}
                  </b-table-column>
                  <b-table-column label="Created">
                    {{ props.row.created_at }}
                  </b-table-column>
                  <!-- <b-table-column label="Updated" width=400>
                    {{ props.row.updated_at }}
                  </b-table-column> -->
                </template>
                <template slot="empty">
                  <b-table-column label="ID">
                  </b-table-column>
                  <b-table-column label="Ref. Date">
                  </b-table-column>
                  <b-table-column label="Post Date">
                  </b-table-column>
                  <b-table-column label="Type">
                  </b-table-column>
                  <b-table-column label="User">
                  </b-table-column>
                  <b-table-column label="Created">
                  </b-table-column>
                  <!-- <b-table-column label="Updated">
                  </b-table-column> -->
                </template>
              </b-table>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" @click.prevent="isLogs = false">Close</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>
    <b-modal :active.sync="isPrint" has-modal-card :canCancel=false>
      <transition name="fade">
        <form action="">
          <div class="modal-card" style="margin-top: 4rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">Print</p>
            </header>
            <section class="modal-card-body">
              <!-- <b-field label="Month">
                <b-select v-model="month" placeholder="month" expanded>
                  <option value="January">January</option>
                  <option value="February">February</option>
                  <option value="April">April</option>
                  <option value="March">March</option>
                  <option value="May">May</option>
                  <option value="June">June</option>
                  <option value="July">July</option>
                  <option value="August">August</option>
                  <option value="September">September</option>
                  <option value="October">October</option>
                  <option value="November">November</option>
                  <option value="December">December</option>
                </b-select>
              </b-field>
              <b-field label="Year">
                <b-input v-model="year" maxlength=4 expanded></b-input>
              </b-field> -->
              <b-field label="Supervisor">
                <b-input v-model="supervisor" expanded></b-input>
              </b-field>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" @click.prevent="printDTR()">Print</button>
              <button class="button is-danger" @click.prevent="isPrint = false">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>
    <b-modal :active.sync="isSetExempt" has-modal-card :canCancel=false>
      <transition name="fade">
        <form @submit.prevent="saveExempt()">
          <div class="modal-card" style="max-height: 35.625rem; margin-top: 4rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">Settings</p>
            </header>
            <section class="modal-card-body">
              <b-field label="Applicable Month">
                <b-select v-model="settingsMonth" placeholder="Applicable Month">
                  <option v-for="monthName in monthNames" :key="monthName" :value="monthName">{{monthName}}</option>
                </b-select>
              </b-field>
              <b-field label="Does Not Use Timekeeping">
                <b-radio v-model="exempt_change"
                    name="UseTimeKeeping"
                    native-value="1">
                    Yes
                </b-radio>
                <b-radio v-model="exempt_change"
                    name="UseTimeKeeping"
                    native-value="0">
                    No
                </b-radio>
                <!-- <b-checkbox v-model="exempt_change"
                  true-value=1
                  false-value=0>
                  <b>Does Not Use Timekeeping</b>
                </b-checkbox> -->
              </b-field>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" type="submit">Save</button>
              <button class="button is-danger" type="button" @click.prevent="closeExempt()">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>
    <b-modal :active.sync="isAttach" has-modal-card :canCancel=false>
      <transition name="fade">
        <form @submit.prevent="attachFiles()" id="attachForm">
          <div class="modal-card" style="max-height: 35.625rem; margin-top: 4rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">Attach File</p>
            </header>
            <section class="modal-card-body">
              <b-field label="Reference Date">
                <div class="columns">
                  <div class="column is-half">
                    <b-field label="Date From">
                      <b-input type="date" v-model="attach_from" />
                    </b-field>
                  </div>
                  <div class="column is-half">
                    <b-field label="Date To">
                      <b-input type="date" v-model="attach_to" />
                    </b-field>
                  </div>
                </div>
              </b-field>
              <b-field label="Attach Proof">
                <input type="file" @input="onFileChange" id="attachFile">
              </b-field>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" type="submit">Save</button>
              <button class="button is-danger" type="button" @click.prevent="cancelAttach()">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>
    <SearchModal search-title="Employee" @close="isSearch = false" @view="remount" v-if="isSearch" />
  </div>
</template>

<script>
import SearchModal from '../SearchModal.vue'
import moment from 'moment'
import { log } from 'util';

export default {
  props: ['employee'],
  components: {
    SearchModal
  },
  data() {
    return {
      monthNames: ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
],
      id: '',
      url: '',
      isChangeSchedule: false,
      newSchedule: {
        employee_id: '',
        schedule: {
          id: '',
          effective_date: '',
          type: ''
        }
      },
      isSearch: false,
      isAttach: false,
      timesheet: [],
      history: [],
      // schedule: {
      //   effective: 'September 16, 2019',
      //   monday: '7:00 AM - 5:00 PM',
      //   tuesday: '7:00 AM - 5:00 PM',
      //   wednesday: '7:00 AM - 5:00 PM',
      //   thursday: '7:00 AM - 5:00 PM',
      //   friday: '7:00 AM - 5:00 PM',
      //   saturday: 'N/A',
      //   sunday: 'N/A',
      // },
      config: {},
      employeeInfo: {
        id: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        name_extension: ''
      },
      schedule: {
        code: '',
        description: '',
        time_in: '',
        lunch_out: '',
        lunch_in: '',
        time_out: ''
      },
      scheduleHistory: [{
        date: '',
        schedule: {
          id: '',
          code: '',
          description: '',
          mon_time_in: '',
          mon_lunch_out: '',
          mon_lunch_in: '',
          mon_time_out: '',
          tue_time_in: '',
          tue_lunch_out: '',
          tue_lunch_in: '',
          tue_time_out: '',
          wed_time_in: '',
          wed_lunch_out: '',
          wed_lunch_in: '',
          wed_time_out: '',
          thu_time_in: '',
          thu_lunch_out: '',
          thu_lunch_in: '',
          thu_time_out: '',
          fri_time_in: '',
          fri_lunch_out: '',
          fri_lunch_in: '',
          fri_time_out: '',
          sat_time_in: '',
          sat_lunch_out: '',
          sat_lunch_in: '',
          sat_time_out: '',
          sun_time_in: '',
          sun_lunch_out: '',
          sun_lunch_in: '',
          sun_time_out: '',
          flexi: '',
          created_at: '',
          updated_at: ''
        }
      }],
      v_scheduleHistory: {
        date: '',
        schedule: {
          id: '',
          code: '',
          description: '',
          mon_time_in: '',
          mon_lunch_out: '',
          mon_lunch_in: '',
          mon_time_out: '',
          tue_time_in: '',
          tue_lunch_out: '',
          tue_lunch_in: '',
          tue_time_out: '',
          wed_time_in: '',
          wed_lunch_out: '',
          wed_lunch_in: '',
          wed_time_out: '',
          thu_time_in: '',
          thu_lunch_out: '',
          thu_lunch_in: '',
          thu_time_out: '',
          fri_time_in: '',
          fri_lunch_out: '',
          fri_lunch_in: '',
          fri_time_out: '',
          sat_time_in: '',
          sat_lunch_out: '',
          sat_lunch_in: '',
          sat_time_out: '',
          sun_time_in: '',
          sun_lunch_out: '',
          sun_lunch_in: '',
          sun_time_out: '',
          flexi: '',
          created_at: '',
          updated_at: ''
        }
      },
      isComponentModalActive: false,
      historyModal: false,
      e_schedule: {
        schedule: {
          code: ''
        },
        time_in: '',
        lunch_out: '',
        lunch_in: '',
        time_out: '',
        reason: ''
      },
      isLoading: false,
      templates: [],
      perPage: 5,
      currentPage: 1,
      perMonth: null,
      filterFrom: null,
      filterTo: null,
      total: {},
      summary: {},
      detach: '',
      isDetach: false,
      isNew: false,
      isLogs: false,
      timeLogs: [],
      timeLogReason: '',
      isPrint: false,
      month: '',
      year: '',
      supervisor: '',
      selected: '',
      newSched: {},
      previousSched: {},
      isSetExempt: false,
      exempt: null,
      exempt_change: 0,
      payroll_setting_id: null,
      is_absent: 0,
      attachForm: new FormData(),
      attach_from: null,
      attach_to: null,
      settingsMonth: null,
      isAdviceDtrGenerate: false
    }
  },
  mounted() {
    // this.id = this.employee.id
    this.perMonth = this.monthNames[new Date().getMonth()]
    ////console.log(this.perMonth);
    this.config = {
      headers: {'Authorization': "Bearer " + this.$store.state.token}
    };
    this.isLoading = true

    this.getEmpSchedule()
    this.getEmpScheduleHistory()
    this.getEmpWeekly()
    this.getEmpMonthly()
    this.getSchedules()
  },
  methods: {
    onFileChange(e) {
      let file = e.target.files[0]
      this.attachForm.append('attachment', file)
    },
    attachFiles() {
      this.isLoading = true

      this.attachForm.append('attach_from', this.attach_from)
      this.attachForm.append('attach_to', this.attach_to)

      this.$http.post(`${this.$host}/api/dtr/attach/${this.employeeInfo.id}`,
        this.attachForm,
        this.config,
      ).then((response) => {
        this.$toast.open({
          message: response.data.message,
          type: 'is-success',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.getEmpSchedule()
        this.getEmpScheduleHistory()
        this.getEmpWeekly()
        this.getEmpMonthly()
        this.getSchedules()
        this.isLoading = false
        this.cancelAttach()

      }).catch((error) => {
        this.isLoading = false
        this.$toast.open({
          message: 'Error saving data.',
          type: 'is-danger',
          position: 'is-bottom-right',
          duration: 4000
        })
      });
    },
    cancelAttach() {
      this.isAttach = false
    },
    isAbsent() {

    },
    saveExempt() {
      this.isLoading = true
      // var refDate = null;
      // if (this.perMonth!=null) {
      //   refDate = new Date()
      //   // //console.log(this.monthNames.indexOf(this.perMonth));
      //   refDate.setMonth(this.monthNames.indexOf(this.perMonth)+1,0);
      //   refDate = moment(refDate).format("YYYY-MM-DD")
      // } else {
      //   refDate = moment(this.filterFrom).format("YYYY-MM-DD")
      // }
      // //console.log(refDate);

      let config = {
        headers: {'Authorization': "Bearer " + this.$store.state.token}
      };
      this.$http.post(`${this.$host}/api/payroll/setting/assign/`,
        {
          payroll_id: [this.payroll_setting_id],
          is_exempted: this.exempt_change,
          month: this.settingsMonth
        },
        config
      ).then((response) => {
        let data = response.data
        this.$toast.open({
          message: 'Setting saved.',
          type: 'is-success',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.isLoading = false
        this.closeExempt()
      }).catch((error) => {
        this.$toast.open({
          message: 'Error.',
          type: 'is-danger',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.isLoading = false
        ////console.log(error)
      });
    },
    getExempt() {
      let config = {
        headers: {'Authorization': "Bearer " + this.$store.state.token}
      };
      this.$http.get(`${this.$host}/api/payroll/setting/employee/${this.employeeInfo.id}`,
        config
      ).then((response) => {
        let data = response.data
        this.exempt = data.is_exempted
        this.exempt_change = JSON.parse(JSON.stringify(data.ot_allowed))
        this.remount(this.employee)
        ////console.log(data)
      }).catch((error) => {
        ////console.log(error)
      });
    },
    closeExempt() {
      this.getExempt()
      this.isSetExempt = false
    },
    setExempt() {
      if (this.employeeInfo.id != '' && this.employeeInfo.id) {
        this.isLoading = true

        let config = {
          headers: {'Authorization': "Bearer " + this.$store.state.token}
        };
        this.$http.get(`${this.$host}/api/payroll/setting/employee/${this.employeeInfo.id}`,
          config
        ).then((response) => {
          let data = response.data
          if (data.payroll_setting_id) {
            this.payroll_setting_id = data.payroll_setting_id
          }

          this.exempt = data.is_exempted
          this.exempt_change = JSON.parse(JSON.stringify(data.is_exempted))
          this.isSetExempt = true
          this.isLoading = false
        }).catch((error) => {
          ////console.log(error)
          this.isLoading = false
          this.isSetExempt = false
        });
      }
    },
    promptVerify() {
      this.$dialog.confirm({
        title: 'Verify DTR',
        message: `Verify DTR as <b>correct</b> for the current month?`,
        cancelText: 'Cancel',
        confirmText: 'Verify',
        type: 'is-success',
        hasIcon: true,
        onConfirm: () => this.saveVerify()
      })
    },
    saveVerify() {
      this.$http.post(`${this.$host}/api/dtr/verify`,
        {},
        this.config
      ).then((response) => {
        this.$toast.open({
          message: 'DTR Verified.',
          position: 'is-bottom-right',
          type: 'is-success'
        })
        this.refreshVerifyStatus()
        ////console.log(data)
      }).catch((error) => {
        if (error.errors) {
          this.$toast.open({
            message: 'Error connecting to server.',
            position: 'is-bottom-right',
            type: 'is-danger'
          })
        }
        ////console.log(error)
      });
    },
    refreshVerifyStatus() {
      this.$http.get(`${this.$host}/api/user`,
        this.config
      ).then((response) => {
        if (response.data.data.is_verify == 1) {
          this.$snackbar.open({
            duration: 10000,
            message: 'NOTICE. Please verify your DTR.',
            type: 'is-success',
            position: 'is-bottom-right',
            actionText: 'Go',
            queue: false,
            onAction: () => { this.$router.push({ path: 'schedule' }) }
          })
          this.$editmode.commit('setVerify', true)
        }
        else {
          this.$editmode.commit('setVerify', false)
        }
        // this.role = response.data.data.user.roles
      }).catch((error) => {
        ////console.log(error)
      });
    },
    detachSchedule(obj) {
      let config = {
        headers: {
          'Authorization': "Bearer " + this.$store.state.token,
          'Content-Type': 'application/json'
        },
        params: {
          employee_id: this.employee.id,
          effective_date: moment(obj.effective_date).format("YYYY-MM-DD")
        }
      }
      this.$dialog.confirm({
        title: 'Delete Schedule',
        message: 'Are you sure you want to <b>delete</b> this schedule? This action cannot be undone.',
        confirmText: 'Delete',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$http.delete(`${this.$host}/api/schedule/detach/`,
            config
          ).then((response) => {
            if (response.status === 204) {
              this.$toast.open({
                message: 'Schedule does not exist.',
                type: 'is-danger',
                position: 'is-bottom-right',
                duration: 4000
              })
            }
            else {
              // this.detach = null
              // this.isDetach = false
              this.$toast.open({
                message: 'Schedule successfully detached.',
                type: 'is-success',
                position: 'is-bottom-right',
                duration: 4000
              })
              this.remount(this.employee)
            }
          }).catch((error) => {
            this.history = []
            // this.detach = null
            this.$toast.open({
              message: 'Error.',
              type: 'is-danger',
              position: 'is-bottom-right',
              duration: 4000
            })
          });
        }
      })
    },
    // cancelDetach() {
    //   this.detach = null
    //   this.isDetach = false
    // },
    resetFilter() {
      this.isLoading = true
      this.perMonth = this.monthNames[new Date().getMonth()]
      this.filterFrom = null
      this.filterTo = null

      this.getEmpMonthly()
    },
    dtrMonthFilter(month) {
      this.filterFrom = null
      this.filterTo = null

      this.isLoading = true
      let config = {
        headers: {
          'Authorization': "Bearer " + this.$store.state.token,
          'Content-Type': 'application/json'
        },
        params: {
          month: month
        }
      }
      this.$http.get(`${this.$host}/api/dtr/search/sort/${this.employee.id}`,
        config
      ).then((response) => {
        let data = response.data
        this.history = data.record
        this.url = data.attachmentUrl
        if (data.total) {
          this.total = data.total
          this.summary = data.summary
        }
        else {
          this.total = {}
          this.summary = {}
        }
        this.showAdviceDtrGenerate(month, data.record.length)
        ////console.log(data)
        this.isLoading = false
      }).catch((error) => {
        this.showAdviceDtrGenerate(month, 0)
        this.history = []
        this.isLoading = false
      });
    },
    dtrRangeFilter() {
      if (this.filterFrom && this.filterTo) {
        this.perMonth = null
        let date_from = moment(this.filterFrom).format("YYYY-MM-DD")
        let date_to = moment(this.filterTo).format("YYYY-MM-DD")

        this.isLoading = true
        let config = {
          headers: {
            'Authorization': "Bearer " + this.$store.state.token,
            'Content-Type': 'application/json'
          },
          params: {
            from: date_from,
            to: date_to
          }
        }
        this.$http.get(`${this.$host}/api/dtr/search/sort/${this.employee.id}`,
          config
        ).then((response) => {
          let data = response.data
          this.history = data.record
          if (data.total) {
            this.total = data.total
            this.summary = data.summary
          }
          else {
            this.total = {}
            this.summary = {}
          }
          ////console.log(data)
          this.isLoading = false
        }).catch((error) => {
          this.history = []
          this.isLoading = false
        });
      }
    },
    remount(obj) {
      this.employee = obj
      this.isLoading = true
      this.getEmpSchedule()
      this.getEmpScheduleHistory()
      this.getEmpWeekly()
      this.getEmpMonthly()
      this.getSchedules()
      this.isSearch = false
    },
    generateDtr() {
      this.$http.post(`${this.$host}/api/kiosk/dtr/generate/${this.employee.id}`,
        {},
        this.config
      ).then((response) => {
        this.remount(this.employee)
        ////console.log(data)
      }).catch((error) => {
        ////console.log(error)
      });
    },
    getEmpMonthly() {
      if (this.perMonth) {
        this.dtrMonthFilter(this.perMonth)
      }
      else if (this.filterFrom && this.filterTo) {
        this.dtrRangeFilter()
      }
      else {
        this.$http.get(`${this.$host}/api/dtr/search/sort/${this.employee.id}`,
          this.config
        ).then((response) => {
          let data = response.data
          this.history = data.record
          if (data.length < 1) {
            this.isNew = true
          }
          if (data.total) {
            this.total = data.total
            this.summary = data.summary
            this.isLoading = false
          }
          else {
            this.total = {}
            this.summary = {}
            this.isLoading = false
          }
          ////console.log(data)
        }).catch((error) => {
          ////console.log(error)
          this.isLoading = false
        });
      }
    },
    getEmpWeekly() {
      this.$http.get(`${this.$host}/api/dtr/search/weekly/${this.employee.id}`,
        this.config
      ).then((response) => {
        let data = response.data
        this.timesheet = data.dtr
        ////console.log(data)
      }).catch((error) => {
        ////console.log(error)
      });
    },
    getEmpSchedule() {
      this.$http.get(`${this.$host}/api/schedule/search/now/${this.employee.id}`,
        this.config
      ).then((response) => {
        let data = response.data
        this.employeeInfo = data.employee
        this.schedule = data.now
        ////console.log(data)
      }).catch((error) => {
        ////console.log(error)
      });
    },
    getEmpScheduleHistory() {
      this.$http.get(`${this.$host}/api/schedule/search/history/${this.employee.id}`,
        this.config
      ).then((response) => {
        let data = response.data
        this.scheduleHistory = data.history
        ////console.log(data)
      }).catch((error) => {
        ////console.log(error)
      });
    },
    getSchedules() {
      this.$http.get(`${this.$host}/api/schedule/all`,
        this.config
      ).then((response) => {
        let data = response.data
        this.templates = data
        ////console.log(data)
      }).catch((error) => {
        ////console.log(error)
      });
    },
    saveSchedule() {
      ////console.log(this.newSchedule)
      this.newSchedule.employee_id = this.employee.id
      this.newSchedule.schedule.effective_date = moment(this.newSchedule.schedule.effective_date).format("YYYY-MM-DD")
      this.isLoading = true
      ////console.log(this.newSchedule)
      this.$http.post(`${this.$host}/api/schedule/attach/`,
        this.newSchedule,
        this.config,
      ).then((response) => {
        if (this.isNew) {
          // this.generateDtr()
          this.remount(this.employee)
          this.newSchedule = {
          employee_id: '',
            schedule: {
              id: '',
              effective_date: '',
              type: ''
            }
          }
          this.$toast.open({
            message: 'Schedule Saved.',
            type: 'is-success',
            position: 'is-bottom-right',
            duration: 4000
          })
        }
        else {
          this.$toast.open({
            message: 'Schedule Saved.',
            type: 'is-success',
            position: 'is-bottom-right',
            duration: 4000
          })
          this.newSchedule = {
            employee_id: '',
            schedule: {
              id: '',
              effective_date: '',
              type: ''
            }
          }
          this.remount(this.employee)
        }
      }).catch((error) => {
        this.$toast.open({
          message: 'Error.',
          type: 'is-danger',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.newSchedule = {
          employee_id: '',
          schedule: {
            id: '',
            effective_date: '',
            type: ''
          }
        }
        this.isLoading = false
        ////console.log(error)
      });
      this.isChangeSchedule = false
    },
    editWeekly(obj) {
      this.e_schedule = obj
      this.isComponentModalActive = true
      this.e_schedule.time_in.date = new Date(this.e_schedule.ref_date)
      this.e_schedule.lunch_out.date = new Date(this.e_schedule.ref_date)
      this.e_schedule.lunch_in.date = new Date(this.e_schedule.ref_date)
      this.e_schedule.time_out.date = new Date(this.e_schedule.ref_date)
      this.previousSched = JSON.parse(JSON.stringify(this.e_schedule))
    },
    cancelWeekly() {
      this.isComponentModalActive = false
      this.remount(this.employee)
    },
    saveWeekly() {
      ////console.log(this.e_schedule)
      this.newSched = JSON.parse(JSON.stringify(this.e_schedule))
      let id = this.e_schedule.id
      let schedule = {
        is_excuse: this.e_schedule.is_excuse,
        code: this.e_schedule.code,
        time_in: moment(this.e_schedule.time_in.date).format("YYYY-MM-DD") + ` ${this.e_schedule.time_in.time}`,
        lunch_out: moment(this.e_schedule.lunch_out.date).format("YYYY-MM-DD") + ` ${this.e_schedule.lunch_out.time}`,
        lunch_in: moment(this.e_schedule.lunch_in.date).format("YYYY-MM-DD") + ` ${this.e_schedule.lunch_in.time}`,
        time_out: moment(this.e_schedule.time_out.date).format("YYYY-MM-DD") + ` ${this.e_schedule.time_out.time}`,
        reason: `${this.e_schedule.reason}`
      }
      if (this.exempt == 1) {
        schedule.is_absent = this.is_absent
      }
      if (this.e_schedule.lunch_out.time == null || this.e_schedule.lunch_out.time == '') {
        schedule.lunch_out = null
      }
      if (this.e_schedule.lunch_in.time == null || this.e_schedule.lunch_out.time == '') {
        schedule.lunch_in = null
      }
      if (this.e_schedule.time_out.time == null || this.e_schedule.time_out.time == '') {
        schedule.time_out = null
      }
      if (this.e_schedule.time_in.time == null || this.e_schedule.time_in.time == '') {
        schedule.time_in = null
      }
      if (this.previousSched.time_in.date == this.newSched.time_in.date && this.previousSched.time_in.time == this.newSched.time_in.time) {
        delete schedule.time_in
      }
      if (this.previousSched.lunch_out.date == this.newSched.lunch_out.date && this.previousSched.lunch_out.time == this.newSched.lunch_out.time) {
        delete schedule.lunch_out
      }
      if (this.previousSched.lunch_in.date == this.newSched.lunch_in.date && this.previousSched.lunch_in.time == this.newSched.lunch_in.time) {
        delete schedule.lunch_in
      }
      if (this.previousSched.time_out.date == this.newSched.time_out.date && this.previousSched.time_out.time == this.newSched.time_out.time) {
        delete schedule.time_out
      }
      ////console.log(this.previousSched)
      ////console.log(this.e_schedule)
      this.$http.put(`${this.$host}/api/dtr/update/${id}`,
        schedule,
        this.config,
      ).then((response) => {
        this.$toast.open({
          message: 'Record Edited.',
          type: 'is-success',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.remount(this.employee)
        this.isComponentModalActive = false
      }).catch((error) => {
        this.$toast.open({
          message: 'Error.',
          type: 'is-danger',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.remount(this.employee)
        ////console.log(error)
      });
    },
    viewHistory(obj) {
      this.historyModal = true
      this.v_scheduleHistory = obj
    },
    editDtr(obj) {
      this.$http.get(`${this.$host}/api/dtr/find/${obj.id}`,
        this.config
      ).then((response) => {
        let data = response.data
        ////console.log(data)
        this.editWeekly(data)
      }).catch((error) => {
        ////console.log(error)
      });
    },
    viewLog(date) {
      if (Number.isInteger(date)) {
        this.isLoading = true
        this.$http.get(`${this.$host}/api/dtr/find/${date}`,
          this.config
        ).then((response) => {
          let data = response.data
          date = data.ref_date
          this.getLogs(date)
          this.isLoading = false
        }).catch((error) => {
          ////console.log(error)
          this.isLoading = false
        });
      }
      else {
        this.getLogs(date)
      }
    },
    getLogs(date) {
      let config = {
        headers: {
          'Authorization': "Bearer " + this.$store.state.token,
          'Content-Type': 'application/json'
        },
        params: {
          ref_date: date,
          employee_id: this.employee.id
        }
      }

      this.isLoading = true
      this.$http.get(`${this.$host}/api/time-log/find`,
        config
      ).then((response) => {
        let data = response.data
        this.timeLogs = data["time-log"]
        this.timeLogReason = data.reason
        this.isLogs = true
        ////console.log(data)
        this.isLoading = false
      }).catch((error) => {
        this.$toast.open({
          message: 'No time logs available for this record.',
          type: 'is-danger',
          position: 'is-bottom-right',
          duration: 4000
        })
        ////console.log(error)
        this.isLoading = false
      });
    },
    printDTR() {
      this.isLoading = true

      let config = {
        headers: {'Authorization': "Bearer " + this.$store.state.token}
      };

      this.$http.get(`${this.$host}/api/report/generate/${this.employee.id}`,
        config
      ).then((response) => {
        this.isLoading = false
        let token = response.data
        let url
        if (this.perMonth && this.perMonth != '') {
          url = `${this.$host}/api/report/dtr/${token}?month=${this.perMonth}&supervisor=${this.supervisor}&perPage=${this.perPage}&currentPage=${this.currentPage}`
          let win = window.open(url, '_blank')
          win.focus()
        }
        else if (this.filterFrom && this.filterTo && this.filterFrom != '' && this.filterTo != '') {
          let date_from = moment(this.filterFrom).format("YYYY-MM-DD")
          let date_to = moment(this.filterTo).format("YYYY-MM-DD")

          url = `${this.$host}/api/report/dtr/${token}?from=${date_from}&to=${date_to}&supervisor=${this.supervisor}&perPage=${this.perPage}&currentPage=${this.currentPage}`
          let win = window.open(url, '_blank')
          win.focus()
        }
        else {
          this.$toast.open({
            message: 'No month or date inputted.',
            type: 'is-danger',
            position: 'is-bottom-right',
            duration: 4000
          })
        }

        this.isPrint = false
        }).catch((error) => {
        ////console.log(error)
        this.isLoading = false
      });
    },
    openPrintDTR() {
      this.month = ''
      this.year = ''
      this.supervisor = ''
      this.isPrint = true
      this.$http.get(`${this.$host}/api/parameter/dtr/${this.employee.id}`,
        this.config
      ).then((response) => {
        let data = response.data
        this.supervisor = data.supervisor
        ////console.log(data)
      }).catch((error) => {
        this.$toast.open({
          message: 'No time logs available for this record.',
          type: 'is-danger',
          position: 'is-bottom-right',
          duration: 4000
        })
        ////console.log(error)
      });
    },
    showAttachment(file) {
      window.open(this.url + file,"_blank");
    },
    showAdviceDtrGenerate(month, recordCount){
      const year = new Date().getFullYear()
      const currentMonth = new Date().getMonth()
      const monthIndex = this.monthNames.findIndex(name => name.toLowerCase() === month.toLowerCase())
      const date = new Date(year, monthIndex + 1, 0)
      const days = date.getDate()
      this.isAdviceDtrGenerate = (currentMonth >= monthIndex) ? ((days > recordCount) ? true : false) : false
    },
    generateMontlyDTR(month){
      const year = new Date().getFullYear(); 
      month = (this.monthNames.findIndex(name => name.toLowerCase() === month.toLowerCase())) + 1
      this.$http.post(`${this.$host}/api/cron/dtr/generate/${month}/${year}/${this.employee.id}`,
        {},
        this.config
      ).then((response) => {
        this.remount(this.employee)
      }).catch((error) => {
      });
    }
  }
}
</script>

<style scoped>
  .warning-box {
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem;
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
    margin-top: 1rem;
  }
</style>
