<template>
  <div>
    <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
    <div class="box">
      <div>
        <b-field>
          <!-- <b-field label="Flexi">
          </b-field> -->
          <button class="button is-primary" @click.prevent="isFlexi = true">
            <i class="fa fa-pen"></i> &nbsp;Edit Flexi Time
          </button>
        </b-field>
      </div>
      <hr>
      <b>FLEXI FROM:</b> {{ flexi_from }}<br>
      <b>FLEXI TO:</b> {{ flexi_to }}
    </div>
    <b-modal :active.sync="isFlexi" has-modal-card :canCancel=false>
      <transition name="fade">
        <form action="">
          <div class="modal-card" style="margin-top: 4rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">Schedule</p>
            </header>
            <section class="modal-card-body">
              <b-field label="Flexi From">
                <b-input v-model="flexi_from" expanded></b-input>
              </b-field>
              <b-field label="Flexi To">
                <b-input v-model="flexi_to" expanded></b-input>
              </b-field>
              <br><br><br><br><br><br><br><br>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" @click.prevent="saveFlexiFrom">Save</button>
              <button class="button is-danger" @click.prevent="cancelFlexi">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: true,
      config: {},
      flexi_from: '',
      flexi_to: '',
      lunch_hour: '',
      isFlexi: false
    }
  },
  mounted() {
    this.config = {
      headers: {'Authorization': "Bearer " + this.$store.state.token}
    };
    this.getSettings()
  },
  methods: {
    getSettings() {
      this.$http.get(`${this.$host}/api/setting/show`,
        this.config
      ).then((response) => {
        let data = response.data
        this.flexi_from = data[2].hash_value
        this.flexi_to = data[3].hash_value
        this.isLoading = false
      }).catch((error) => {
        this.isLoading = false
        //console.log(error)
      });
    },
    saveFlexiFrom() {
      if (!this.flexi_from.match(/^(?:2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]$/) ||
      !this.flexi_to.match(/^(?:2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]$/)) {
        this.$toast.open({
          message: 'Invalid Time.',
          type: 'is-danger',
          position: 'is-bottom-right',
          duration: 4000
        })
      }
      else {
        this.isLoading = true
        this.$http.put(`${this.$host}/api/setting/update/3`,
          { hash_value: this.flexi_from },
          this.config
        ).then((response) => {
          this.saveFlexiTo()
          // //console.log(data)
        }).catch((error) => {
          //console.log(error)
          this.isLoading = false
        });
      }
    },
    saveFlexiTo() {
      this.$http.put(`${this.$host}/api/setting/update/4`,
        { hash_value: this.flexi_to },
        this.config
      ).then((response) => {
        // //console.log(data)
        this.isFlexi = false
        this.getSettings()
      }).catch((error) => {
        //console.log(error)
        this.isLoading = false
      });
    },
    cancelFlexi() {
      this.isLoading = true
      this.getSettings()
      this.isFlexi = false
    }
  }
}
</script>

<style>

</style>
