<template>
  <div>
    <div class="columns header" style="margin-bottom: 0 !important;">
      <div class="column is-5" style="margin-left: 5px; font-size: 20px;">
        <b>REQUESTS</b>
      </div>
      <div class="column is-auto" v-if="$role.state.permissions['search leave ledger']">
        <button class="button is-primary is-pulled-right is-hidden-mobile" style="margin-left: 10px;" @click.prevent="$router.push('/requests/manage')">
          <i class="fa fa-clipboard-list"></i> &nbsp;Manage Employee Requests
        </button>
        <button class="button is-primary is-hidden-tablet" @click.prevent="$router.push('/requests/manage')">
          <i class="fa fa-clipboard-list"></i> &nbsp;Manage Employee Requests
        </button>
        <button class="button is-primary is-pulled-right is-hidden-mobile" style="margin-left: 10px;" @click.prevent="isGetCheckboxEmployees()">
          <i class="fa fa-clipboard-list"></i> &nbsp;Add LWOP
        </button>
        <button class="button is-primary is-hidden-tablet" @click.prevent="isGetCheckboxEmployees()">
          <i class="fa fa-clipboard-list"></i> &nbsp;Add LWOP
        </button>
      </div>
    </div>
    <hr style="margin-top: 0 !important; margin-bottom: 0 !important;" class="has-background-grey-lighter is-hidden-mobile">
    <br>
    <div>
      <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
      <ApproveList @refresh="init" v-if="is_approver" :forApproval="forApproval" :url="url" :is_approver="is_approver" :requestTypes="requestTypes" status="request"/>
      <div class="box">
        <b-field label="My Requests"></b-field>
        <b-field grouped group-multiline>
          <button class="button is-success control" @click.prevent="isComponentModalActive = true">
            <i class="fa fa-exchange-alt"></i> &nbsp;New Request
          </button>
          <button class="button is-primary control" @click.prevent="init()">
            <i class="fa fa-redo-alt"></i> &nbsp;Reset Filters
          </button>
        </b-field>
        <b-field grouped group-multiline>
          <!-- <b-select placeholder="Filter">
            <option value="All">All</option>
            <option value="Requested">Requested</option>
            <option value="Approved">Approved</option>
            <option value="Disapproved">Disapproved</option>
            <option value="Cancelled">Cancelled</option>
            <option value="Manual">Manual</option>
            <option value="Automatic">Automatic</option>
          </b-select> -->
          <b-select placeholder="Per Page" v-model="perPage">
            <option value="5">5 per page</option>
            <option value="10">10 per page</option>
            <option value="20">20 per page</option>
            <option value="31">31 per page</option>
          </b-select>
          <b-select placeholder="Request Type" v-model="requestType" @input="getRequestsByType(requestType)" style="width: 200px;">
            <option
              @input="getCredit"
              v-for="option in allowedRequestTypes"
              :value="option.id"
              :key="option.id">
              {{ option.name }}
            </option>
          </b-select>
          <b-select v-model="perMonth" @input="dtrMonthFilter(perMonth)" placeholder="month">
            <option value="January">January</option>
            <option value="February">February</option>
            <option value="March">March</option>
            <option value="April">April</option>
            <option value="May">May</option>
            <option value="June">June</option>
            <option value="July">July</option>
            <option value="August">August</option>
            <option value="September">September</option>
            <option value="October">October</option>
            <option value="November">November</option>
            <option value="December">December</option>
          </b-select>
          <b-datepicker
            style="width: 150px;"
            placeholder="date from"
            icon="calendar-today"
            v-model="filterFrom"
            @input="dtrRangeFilter"
            :date-formatter="d => d.toLocaleDateString('en-GB')">
          </b-datepicker>
          <b-datepicker
            style="width: 150px;"
            placeholder="date to"
            icon="calendar-today"
            v-model="filterTo"
            @input="dtrRangeFilter"
            :date-formatter="d => d.toLocaleDateString('en-GB')">
          </b-datepicker>
          <!-- <b-select placeholder="Status" @input="statusFilter(byStatus)" v-model="byStatus">
            <option value="request">Request</option>
            <option value="approved">Approved</option>
            <option value="disapproved">Disapproved</option>
            <option value="cancelled">Cancelled</option>
            <option value="automatic">Automatic</option>
            <option value="manual">Manual</option>
          </b-select> -->
        </b-field>
        <hr style="margin-top: 0; margin-bottom: 10px;">
        <div style="text-align: left; margin-bottom: 10px;">
          <b>BALANCE</b>
        </div>
        <div class="columns">
          <div class="column is-3 has-text-white">
            <div class="shadow">
              <div style="background-color: #d32f2f; padding: 5px 10px 0 10px;"><b>Vacation Leave</b></div>
              <div style="background-color: #d32f2f; font-size: 40px; text-align: center; padding: 5px 10px 10px 10px;">{{ balance['VL'] }} </div>
            </div>
          </div>

          <div class="column is-3 has-text-white">
            <div class="shadow">
              <div style="background-color: #C2185B; padding: 5px 10px 0 10px;"><b>Sick Leave</b></div>
              <div style="background-color: #C2185B; font-size: 40px; text-align: center; padding: 5px 10px 10px 10px;">{{ balance['SL'] }} </div>
            </div>
          </div>

          <div class="column is-3 has-text-white">
            <div class="shadow">
              <div style="background-color: #512DA8; padding: 5px 10px 0 10px;"><b>Forced Leave</b></div>
              <div style="background-color: #512DA8; font-size: 40px; text-align: center; padding: 5px 10px 10px 10px;">{{ balance['FL'] }} </div>
            </div>
          </div>

          <div class="column is-3 has-text-white">
            <div class="shadow">
              <div style="background-color: #0288D1; padding: 5px 10px 0 10px;"><b>Special Privilege Leave</b></div>
              <div style="background-color: #0288D1; font-size: 40px; text-align: center; padding: 5px 10px 10px 10px;">{{ balance['SPL'] }} </div>
            </div>
          </div>
        </div>
        <!-- <b>Requests:</b> -->
        <b-table
        :data="ledger"
        :bordered=false
        :hoverable=true
        :paginated="true"
        :per-page="perPage"
        detailed
        detail-key="id">
          <template slot-scope="props">
            <b-table-column label="Date Filed" :class="{greyed: props.row.is_counted == 0}" field="date_filed" sortable>
              <b-tag type="is-success is-small" v-if="props.row.is_new">New</b-tag> {{ props.row.date_filed }}
            </b-table-column>
            <b-table-column label="Request" :class="{greyed: props.row.is_counted == 0}">
              {{ props.row.name }}
            </b-table-column>
            <b-table-column label="From" :class="{greyed: props.row.is_counted == 0}" field="from" sortable>
              {{ props.row.from }}
            </b-table-column>
            <b-table-column label="To" :class="{greyed: props.row.is_counted == 0}">
              {{ props.row.to }}
            </b-table-column>
            <b-table-column label="Credit" :class="{green: props.row.credit > 0, red: props.row.credit < 0, greyed: props.row.is_counted == 0}">
              {{ props.row.credit }}
            </b-table-column>
            <b-table-column label="Status">
              <span style="margin-left: 18px;" v-html="setStatus(props.row.status)"></span>
            </b-table-column>
            <b-table-column label="Remarks" :class="{greyed: props.row.is_counted == 0}">
              {{ props.row.raw.remarks }}
            </b-table-column>
            <b-table-column label="Actions" width="150">
              <button v-if="props.row.raw.request_type_id != 14 && props.row.raw.request_type_id != 16 && props.row.status != 'system' && $role.state.permissions['print leave ledger']" class="button btn-action is-primary is-small" @click.prevent="printLeave(props.row.id)"><i class="fa fa-print"></i></button>
              <button v-if="props.row.status != 'approved' && props.row.status != 'cancelled' && props.row.status != 'manual' && props.row.status != 'disapproved'"
              class="button btn-action is-primary is-small" @click.prevent="attachRequest(props.row.id)"><i class="fa fa-paperclip"></i></button>
              <button v-if="props.row.status != 'cancelled' && props.row.status != 'manual' && props.row.status != 'disapproved' && props.row.status != 'system'"
              class="button btn-action is-danger is-small" @click.prevent="cancelPrompt(props.row.id)"><i class="fa fa-times"></i></button>
            </b-table-column>
          </template>
          <template slot="detail" slot-scope="props">
            <article class="media">
              <div class="media-content">
                <div class="content">
                  <p>
                    <b>Reason:</b> {{ props.row.raw.reason=='wfh'?'Work From Home': props.row.raw.reason }}<br>
                    <!-- <b>Remarks:</b> {{ props.row.raw.remarks }}<br> -->
                    <span v-if="props.row.raw.venue"><b>Venue:</b> {{ props.row.raw.venue }}<br></span>
                    <b>1st Approver:</b> <span v-html="setStatusFull(props.row.raw.status_approver_1)"></span><br>
                    <b>2nd Approver:</b> <span v-html="setStatusFull(props.row.raw.status_approver_2)"></span><br>
                    <b>Final Approver:</b> <span v-html="setStatusFull(props.row.raw.status_approver_3)"></span><br>
                    <b>Proof:</b> <button v-if="props.row.raw.proof" class="button is-primary is-small" @click.prevent="openProof(props.row.raw.proof)"><span>ATTACHMENT LINK</span></button><br>
                    <b>Proof Type:</b> {{ props.row.raw.proof_type }}
                    <!-- <b>Approver 4:</b> {{ setStatusFull(props.row.raw.status_approver_4) }}<br> -->
                  </p>
                </div>
              </div>
            </article>
          </template>
          <template slot="empty">
            <b-table-column label="Date Filed" field="date_filed" sortable>
            </b-table-column>
            <b-table-column label="Request">
            </b-table-column>
            <b-table-column label="From" field="from" sortable>
            </b-table-column>
            <b-table-column label="To">
            </b-table-column>
            <b-table-column label="Credit">
            </b-table-column>
            <b-table-column label="Status">
            </b-table-column>
            <b-table-column label="Remarks">
            </b-table-column>
            <b-table-column label="Actions">
            </b-table-column>
          </template>
        </b-table>
        <div style="font-size: 15px;">
        <b>Legend:</b>
          <span style="color: orange; margin-right: 5px; margin-left: 5px;"><i class="far fa-clock"></i> Requested</span>
          <span style="color: green; margin-right: 5px;"><i class="fas fa-check"></i> Approved</span>
          <span style="color: red; margin-right: 5px;"><i class="fas fa-times"></i> Disapproved</span>
          <span style="color: red; margin-right: 5px;"><i class="fas fa-ban"></i> Cancelled</span>
          <span style="color: #0D47A1; margin-right: 5px;"><i class="fas fa-bullseye"></i> Automatic</span>
          <span style="color: #0D47A1; margin-right: 5px;"><i class="fas fa-wrench"></i> Manual</span>
          <span style="color: purple; margin-right: 5px;"><i class="fas fa-cog"></i> System Generated</span>
        </div>
        <!-- <b-table
        :data="balances"
        :bordered=false
        :hoverable=true
        detailed
        detail-key="id">
          <template slot-scope="props">
            <b-table-column>
              <b>{{ props.row.balance }}</b>
            </b-table-column>
          </template>
          <template slot="detail">
            <article class="media">
              <div class="media-content">
                <div class="content">
                  <p>
                    <b>VL:</b> {{ balance['VL'] }}<br>
                    <b>SL:</b> {{ balance['SL'] }}<br>
                    <b>FL:</b> {{ balance['FL'] }}<br>
                    <b>SEL:</b>: {{ balance['SEL'] }}
                    <b>Balance:</b> 204 days 2 hours 30 minutes (216.35)
                  </p>
                </div>
              </div>
            </article>
          </template>
          <template slot="empty">
            <b-table-column label="">
            </b-table-column>
          </template>
        </b-table> -->
      </div>
    </div>
    <b-modal :active.sync="isComponentModalActive" has-modal-card :canCancel=false>
      <transition name="fade">
        <form @submit.prevent="save()" id="requestForm">
          <div class="modal-card" style="height: 35.625rem; margin-top: 4rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">Request</p>
            </header>
            <section class="modal-card-body">
              <b-field label="Request Type">
                <b-field>
                  <b-select placeholder="Request Type" v-model="newLeaves.request_type_id" @input="getCredit" expanded>
                    <!-- eslint-disable -->
                    <option
                      v-for="option in allowedRequestTypes"
                      :value="option.id"
                      :key="option.id">
                      {{ option.name }}
                    </option>
                    <!-- eslint-enable -->
                  </b-select>
                </b-field>
              </b-field>
              <b-field><b>Current Balance:</b> {{ currentBalance }} days</b-field>
              <b-field label="From" v-if="newLeaves.request_type_id != 14 && newLeaves.request_type_id != 17">
                <b-field>
                  <b-datepicker
                    icon="calendar-today"
                    v-model="newLeaves.from_date"
                    :date-formatter="d => d.toLocaleDateString('en-GB')"
                    @input="getCredit"
                    expanded>
                  </b-datepicker>
                  <b-autocomplete v-if="isTime"
                    v-model="newLeaves.from_time"
                    :data="filteredTimeFrom"
                    :open-on-focus=true expanded
                    placeholder="TIME"
                    @input="getCredit">
                  </b-autocomplete>
                </b-field>
              </b-field>
              <b-field label="To" v-if="newLeaves.request_type_id != 14 && newLeaves.request_type_id != 17">
                <b-field>
                  <b-datepicker
                    icon="calendar-today"
                    v-model="newLeaves.to_date"
                    :date-formatter="d => d.toLocaleDateString('en-GB')"
                    @input="getCredit"
                    expanded>
                  </b-datepicker>
                  <b-autocomplete v-if="isTime"
                    v-model="newLeaves.to_time"
                    :data="filteredTimeTo"
                    :open-on-focus=true expanded
                    placeholder="TIME"
                    @input="getCredit">
                  </b-autocomplete>
                </b-field>
              </b-field>
              <b-field v-if="(newLeaves.request_type_id != 14 && newLeaves.request_type_id != 16 && newLeaves.request_type_id != 17) || !isTime">
                <b-checkbox v-model="isHalfDay"
                  true-value=1
                  false-value=0
                  :disabled="halfDay"
                  @input="getCredit">
                </b-checkbox>
                <b-field label="Half Day">
                </b-field>
              </b-field>
              <b-field label="Year" v-if="newLeaves.request_type_id == 14 || newLeaves.request_type_id == 17">
                <b-select
                  v-model="year"
                  placeholder="Select Year"
                  expanded
                  @input="setDate()"
                >
                  <option v-for="year in years" :key="year" :value="year">{{year}}</option>
                </b-select>
              </b-field>
              <b-field label="Month" v-if="newLeaves.request_type_id == 14 || newLeaves.request_type_id == 17">
                <b-select v-model="month" placeholder="Month" @input="setDate()" expanded>
                  <option value="5">May</option>
                  <option value="11">November</option>
                </b-select>
              </b-field>
              <b-field label="Percentage" v-if="newLeaves.request_type_id == 14 || newLeaves.request_type_id == 17">
                <b-select
                  v-model="percentage"
                  placeholder="Select Percentage"
                  expanded
                  @input="getCredit"
                >
                  <option v-for="percent in percentages" :key="percent" :value="percent">{{percent}}</option>
                </b-select>
              </b-field>
              <span style="color: red" v-html="validationStatus"></span>
              <b-field label="Credit">
                <b-input v-model="newLeaves.credit" v-if="newLeaves.request_type_id!=14 && newLeaves.request_type_id!=17" disabled></b-input>
                <b-input v-model="newLeaves.credit" v-if="newLeaves.request_type_id==14 || newLeaves.request_type_id==17" @input="computePercentage()"></b-input>
              </b-field>
              <b-field label="Reason" v-if="newLeaves.request_type_id != 15 && newLeaves.request_type_id != 14 && newLeaves.request_type_id != 17">
                <b-input type="textarea" v-model="newLeaves.reason" required></b-input>
              </b-field>
              <b-field label="Reason" v-if="newLeaves.request_type_id == 15">
              </b-field>
              <div v-if="newLeaves.request_type_id == 15">
                <div class="field">
                  <b-radio v-model="newLeaves.reason" native-value="seminar">Seminar/Training/Conference/Meeting</b-radio>
                </div>
                <div class="field">
                  <b-radio v-model="newLeaves.reason" native-value="document">Document Tracking/Pick-up/Delivery</b-radio>
                </div>
                <div class="field">
                  <b-radio v-model="newLeaves.reason" native-value="passport">Renewal of Passport (for official travel/training/scholarship abroad)</b-radio>
                </div>
                <div class="field">
                  <b-radio v-model="newLeaves.reason" native-value="reconciliation">Reconciliation of GSIS/PAG-IBIG Records/Phil Health</b-radio>
                </div>
                <div class="field">
                  <b-radio v-model="newLeaves.reason" native-value="medical">Medical Check-up (for employee concerned only)</b-radio>
                </div>
                <div class="field">
                  <b-radio v-model="newLeaves.reason" native-value="renewal">Renewal of Licenses/Permits of CPAs, Engineers, Lawyers, Medical
                  Practitioners  and Drivers whose Items are under the DOTr-CO Plantilla of
                  Personnel – Filing and Pick- up only and subject to submission of
                  photocopy of license/permit</b-radio>
                </div>
                <div class="field">
                  <b-radio v-model="newLeaves.reason" native-value="wfh">Work From Home</b-radio>
                </div>
                <div class="field">
                  <b-radio v-model="newLeaves.reason" native-value="other">Other</b-radio>
                </div>
                <b-field label="Please specify" v-if="newLeaves.reason == 'other'">
                  <b-input type="textarea" v-model="otherReason" required></b-input>
                </b-field>
              </div><br>
              <b-field label="Venue" v-if="newLeaves.request_type_id == 15">
                <b-input v-model="newLeaves.venue"></b-input>
              </b-field>
              <b-field label="Attach Proof">
                <input type="file" @input="onFileChange" id="proofFile">
              </b-field>
              <b-field>
                <button class="button is-danger is-small" @click.prevent="deleteFile()"><span><i class="fa fa-times"></i> Remove File</span></button>
              </b-field>
              <b-field label="Proof Type">
                <b-autocomplete
                  v-model="newLeaves.proof_type"
                  :data="filteredProofTypes"
                  :open-on-focus=false expanded>
                </b-autocomplete>
              </b-field>
              <!-- <b-field>
                <b-checkbox v-model="newLeaves.is_start"
                  true-value=1
                  false-value=0>
                </b-checkbox>
                <b-field label="Start">
                </b-field>
              </b-field> -->
              <br><br>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" type="submit" v-if="canSave">Request</button>
              <button class="button is-danger" @click.prevent="cancel">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>
    <b-modal :active.sync="isAttach" has-modal-card :canCancel=false>
      <transition name="fade">
        <form action="" id="requestForm">
          <div class="modal-card" style="height: 35.625rem; margin-top: 4rem;">
            <header class="modal-card-head">
              <p class="modal-card-title">Attach Proof</p>
            </header>
            <section class="modal-card-body">
              <b-field label="Attach Proof">
                <input type="file" @input="onAttachChange" id="proofFile">
              </b-field>
              <!-- <b-field>
                <button class="button is-danger is-small" @click.prevent="deleteFile()"><span><i class="fa fa-times"></i> Remove File</span></button>
              </b-field> -->
              <b-field label="Proof Type">
                <b-autocomplete
                  v-model="attach.proof_type"
                  :data="filteredProofTypes2"
                  :open-on-focus=false expanded>
                </b-autocomplete>
              </b-field>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" @click.prevent="saveAttach">Save</button>
              <button class="button is-danger" @click.prevent="cancelAttach">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>
    <b-modal :active.sync="isCancelPrompt" has-modal-card :canCancel=false>
      <transition name="fade">
        <form @submit.prevent="cancelRequest()">
          <div class="modal-card">
            <header class="modal-card-head">
              <p class="modal-card-title">Cancellation Reason</p>
            </header>
            <section class="modal-card-body">
              <b-field label="Reason">
                <b-input
                  v-model="cancellation_reason" required>
                </b-input>
              </b-field>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" type="submit">Cancel</button>
              <button class="button is-danger" type="button" @click="isCancelPrompt = false">Close</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>

    <b-modal :active.sync="isSelect" has-modal-card :canCancel=false>
      <transition name="fade">
        <form @submit.prevent="select()">
          <div class="modal-card pos" style="width: 60rem; height: 35.625rem; margin-left: 100px;">
            <header class="modal-card-head">
              <p class="modal-card-title">Select Employees</p>
            </header>
            <section class="modal-card-body">
              <b-field expanded>
                <b-field label="Start Date" expanded>
                  <b-field expanded>
                    <b-field expanded>
                      <b-input v-model="start_date.year" type="number" min="1800" max="2100" placeholder="YEAR" expanded required @input="isGetCheckboxEmployees()">
                      </b-input>
                    </b-field>
                    <b-field expanded>
                      <b-select v-model="start_date.month" placeholder="MONTH" expanded required @input="isGetCheckboxEmployees()">
                        <option v-for="month in dateConfig.monthsFixed" :key="month.id" :value="month.id">
                          {{ month.name }}
                        </option>
                      </b-select>
                    </b-field>
                  </b-field>
                </b-field>

                <b-field label="End Date" expanded>
                  <b-field expanded>
                    <b-field expanded>
                      <b-input v-model="end_date.year" type="number" min="1800" max="2100" placeholder="YEAR" expanded required @input="isGetCheckboxEmployees()">
                      </b-input>
                    </b-field>
                    <b-field expanded>
                      <b-select v-model="end_date.month" placeholder="MONTH" expanded required @input="isGetCheckboxEmployees()">
                        <option v-for="month in dateConfig.monthsFixed" :key="month.id" :value="month.id">
                          {{ month.name }}
                        </option>
                      </b-select>
                    </b-field>
                  </b-field>
                </b-field>
              </b-field>
              <b-field label="Filters"></b-field>
              <b-field grouped group-multiline>
                <!-- <b-select v-model="perPage">
                  <option value="10">10 per page</option>
                  <option value="50">50 per page</option>
                  <option value="100">100 per page</option>
                  <option value="1000">1000 per page</option>
                </b-select> -->
                <!-- <b-select v-model="selectedStatus" @input="changeStatus()" v-if="settings.payroll_group == 1">
                  <option :value="''">All</option>
                  <option v-for="s in status" :key="s.key" :value="s.key">{{ s.value }}</option>
                </b-select> -->
                <b-input v-model="search_list" placeholder="Search Employee" expanded/>
              </b-field>

              <b-table
                :data="filterEmployees"
                :striped=true
                :bordered=true
                :hoverable=true
                :paginated="false"
                :per-page="perPage"
                :checked-rows.sync="checkedRows"
                :checkable=true
                class="table-font">
                <template slot-scope="props">
                  <b-table-column label="Emp. No." width="100">
                    {{ props.row.employee_number }}
                  </b-table-column>
                  <b-table-column label="Full Name">
                    {{ props.row.fullname }}
                  </b-table-column>
                  <b-table-column label="VL Days">
                    {{ props.row.vl_days }}
                  </b-table-column>
                  <b-table-column label="VL Amount">
                    {{ props.row.vl }}
                  </b-table-column>
                  <b-table-column label="SL Days">
                    {{ props.row.sl_days }}
                  </b-table-column>
                  <b-table-column label="SL Amount">
                    {{ props.row.sl }}
                  </b-table-column>
                  <!-- <b-table-column label="Cancellation" v-if="settings.payroll_type=='salary'">
                    <span v-html="isCancellation(props.row.for_cancellation)"></span>
                    <button class="button btn-action is-primary is-small" @click.prevent="showReason(props.row.for_cancellation)" v-if="props.row.for_cancellation"><i class="fa fa-eye"></i></button>
                  </b-table-column> -->
                </template>
                <template slot="empty">

                </template>
              </b-table>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" type="submit">Submit Lwop</button>
              <button class="button is-danger" type="button" @click.prevent="closeSelect()">Cancel</button>
            </footer>
          </div>
        </form>
      </transition>
    </b-modal>
    <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
  </div>
</template>

<script>
import moment from 'moment'
import EmployeeSearch from '../components/SearchModal.vue'
import ApproveList from '../components/ApproveList.vue'

export default {
  components: {
    EmployeeSearch,
    ApproveList
  },
  data() {
    return {
      percentage: 50,
      year: new Date().getFullYear(),
      month: (new Date().getMonth()+1>5?11:5),
      isLoading: false,
      ledger: [],
      newLeaves: {
        from_time: '',
        to_time: '',
        // is_start: 0,
        proof_type: '',
        proof: null,
        ot_json: '',
      },
      isComponentModalActive: false,
      balances: [
        {
          balance: 'Balance'
        }
      ],
      balance: {},
      requestTypes: [],
      config: '',
      isSearch: true,
      employee_id: '',
      fullName: '',
      perPage: 10,
      requestType: null,
      filterFrom: null,
      filterTo: null,
      perMonth: null,
      printType: null,
      requestType: null,
      byStatus: null,
      canSave: false,
      validationStatus: '',
      isHalfDay: 0,
      halfDay: true,
      halfDayValue: null,
      proofTypes: [],
      requestForm: new FormData(),
      isAttach: false,
      attachForm: new FormData(),
      attach: {
        proof_type: ''
      },
      ledger_id: null,
      isTime: false,
      otherReason: null,
      isCancelPrompt: false,
      cancellation_reason: '',
      cancel_id: null,
      forApproval: [],
      url: '',
      is_approver: false,
      isSelect: false,
      employees: [],
      checkedRows: [],
      search_list: '',
      dateConfig: {
        months: [],
        days: [],
        monthsFixed: [
          {
            id: "01",name:"January"
          },{
            id:"02",name:"February"
          },{
            id:"03",name:"March"
          },{
            id:"04",name:"April"
          },{
            id:"05",name:"May"
          },{
            id:"06",name:"June"
          },{
            id:"07",name:"July"
          },{
            id:"08",name:"August"
          },{
            id:"09",name:"September"
          },{
            id:"10",name:"October"
          },{
            id:"11",name:"November"
          },{
            id:"12",name:"December"
          }],
      },
      start_date: {
        month: null,
        year: new Date().getFullYear(),
      },
      end_date: {
        month: null,
        year: new Date().getFullYear(),
      }
    }
  },
  mounted() {
    // this.newLeaves = new FormData()
    //console.log(this.month);
    this.config = {
      headers: {'Authorization': "Bearer " + this.$store.state.token, 'Content-Type': 'multipart/form-data'}
    };
    this.getRequests()
    this.init()
  },
  computed: {
    filterEmployees() {
      var i;
      var name_re = new RegExp(this.search_list, 'i')
      var data = []
      for (i in this.employees) {
        if ([this.employees[i].employee_number, this.employees[i].fullname, this.employees[i].position, this.employees[i].division].join(' ').match(name_re)) {
          data.push(this.employees[i])
        }
      }
      return data
    },
    allowedRequestTypes() {
      var returnArray = this.requestTypes.slice()
      const index = this.requestTypes.findIndex(v => v.id == 4)
      returnArray.splice(index,index>=0?1:0)
      return returnArray;
    },
    years() {
      const year = new Date().getFullYear() + 5
      return Array.from({length: (year-1970) + 1},(value,index)=>year-index)
    },
    percentages() {
      return Array.from({length: 30},(value,index) => 50+index)
    },
    currentBalance() {
      if (this.newLeaves.request_type_id == 1) {
        return this.balance['VL']
      }
      else if (this.newLeaves.request_type_id == 2) {
        return this.balance['SL']
      }
      else if (this.newLeaves.request_type_id == 3) {
        return this.balance['FL']
      }
      else if (this.newLeaves.request_type_id == 4) {
        return this.balance['AWOL']
      }
      else if (this.newLeaves.request_type_id == 5) {
        return this.balance['SPL']
      }
      else if (this.newLeaves.request_type_id == 6) {
        return this.balance['SOPL']
      }
      else if (this.newLeaves.request_type_id == 7) {
        return this.balance['SEL']
      }
      else if (this.newLeaves.request_type_id == 8) {
        return this.balance['PL']
      }
      else if (this.newLeaves.request_type_id == 9) {
        return this.balance['ML']
      }
      else if (this.newLeaves.request_type_id == 10) {
        return this.balance['RL']
      }
      else if (this.newLeaves.request_type_id == 11) {
        return this.balance['MCW']
      }
      else if (this.newLeaves.request_type_id == 12) {
        return this.balance['STL']
      }
      else if (this.newLeaves.request_type_id == 13) {
        return this.balance['VAWC']
      }
      else if (this.newLeaves.request_type_id == 14) {
        return this.balance['VL']
      }
      else if (this.newLeaves.request_type_id == 15) {
        // return this.balance['OB']
        return 0
      }
      else if (this.newLeaves.request_type_id == 16) {
        return this.balance['OT']
      }
      else if (this.newLeaves.request_type_id == 17) {
        return this.balance['SL']
      }
      else {
        return 0
      }
    },
    filteredTimeFrom() {
      return this.$time.state.time.filter((option) => {
        return option
          .toString()
          .toLowerCase()
          .indexOf(this.newLeaves.from_time.toLowerCase()) >= 0
      })
    },
    filteredTimeTo() {
      return this.$time.state.time.filter((option) => {
        return option
          .toString()
          .toLowerCase()
          .indexOf(this.newLeaves.to_time.toLowerCase()) >= 0
      })
    },
    filteredProofTypes() {
      return this.proofTypes.filter((option) => {
        if (option != undefined) {
          return option
          .toString()
          .toLowerCase()
          .indexOf(this.newLeaves.proof_type.toLowerCase()) >= 0
        }
      })
    },
    filteredProofTypes2() {
      return this.proofTypes.filter((option) => {
        if (option != undefined) {
          return option
          .toString()
          .toLowerCase()
          .indexOf(this.attach.proof_type.toLowerCase()) >= 0
        }
      })
    },

  },
  methods: {
    setDate() {
      //console.log(this.year,this.month)
      this.newLeaves.from_date = this.year + "-" + this.month + "-01"
      this.newLeaves.to_date = this.year + "-" + this.month + "-01"
      // //console.log(this.newLeaves);
    },
    printLeave(id) {
      this.$http.get(`${this.$host}/api/report/generate/${id}`,
        this.config
      ).then((response) => {
        this.isLoading = false
        let token = response.data

        let url = `${this.$host}/api/report/leave-application-form/${token}`
        let win = window.open(url, '_blank')
        win.focus()
        this.isPrintCOE = false
      }).catch((error) => {
        //console.log(error)
        this.isLoading = false
      });
    },
    onAttachChange(e) {
      let file = e.target.files[0]
      this.attachForm.append('proof', file)
    },
    saveAttach() {
      this.isLoading = true

      this.attachForm.append('proof_type', this.attach.proof_type)

      this.$http.post(`${this.$host}/api/leave/proof/${this.ledger_id}`,
        this.attachForm,
        this.config,
      ).then((response) => {
        this.$toast.open({
          message: 'Data successfully saved.',
          type: 'is-success',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.isLoading = false
        this.cancelAttach()
      }).catch((error) => {
        this.isLoading = false
        if (error.message == 'approver are missing!') {
          this.$toast.open({
            message: 'Error. No approvers assigned.',
            type: 'is-danger',
            position: 'is-bottom-right',
            duration: 4000
          })
        }
        else {
          this.$toast.open({
            message: 'Error saving data.',
            type: 'is-danger',
            position: 'is-bottom-right',
            duration: 4000
          })
        }
      });
    },
    cancelAttach() {
      this.init()
      this.isAttach = false
    },
    attachRequest(id) {
      this.isAttach = true
      this.ledger_id = id
    },
    openProof(proof) {
      let url = this.$host + '/storage/proofs/' + proof
      let win = window.open(url, '_blank')
      win.focus()
    },
    onFileChange(e) {
      let file = e.target.files[0]
      this.requestForm.append('proof', file)
    },
    deleteFile() {
      document.getElementById("proofFile").value = null;
      this.requestForm.delete('proof')
    },
    getProofTypes() {
      this.$http.get(`${this.$host}/api/parameter/proof-type/`,
        this.config
      ).then((response) => {
        let data = response.data
        this.proofTypes = data
        delete this.proofTypes[0]
        //console.log(this.proofTypes)
      }).catch((error) => {
        //console.log(error)
      });
    },
    cancelPrompt(id) {
      this.cancel_id = id
      this.isCancelPrompt = true
    },
    cancelRequest() {
      this.$dialog.confirm({
        title: 'Cancel Request',
        message: 'Are you sure you want to <b>cancel</b> your request?',
        confirmText: 'Yes',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isLoading = true
          let config = {
            headers: {'Authorization': "Bearer " + this.$store.state.token}
          };
          this.$http.put(`${this.$host}/api/leave/cancel/${this.cancel_id}`,
            {
              cancellation_reason: this.cancellation_reason
            },
            config
          ).then((response) => {
            this.$toast.open({
              message: 'Request cancelled.',
              type: 'is-success',
              position: 'is-bottom-right',
              duration: 4000
            })
            this.cancellation_reason = ''
            this.cancel_id = null
            this.isCancelPrompt = false
            this.init()
            this.isLoading = false
          }).catch((error) => {
            // if (error.response.data == 'leave cannot be cancelled') {
            //   this.$toast.open({
            //     message: 'Leave cannot be cancelled.',
            //     type: 'is-danger',
            //     position: 'is-bottom-right',
            //     duration: 4000
            //   })
            // }
            this.$toast.open({
              message: 'Error.' + error.response.data,
              type: 'is-danger',
              position: 'is-bottom-right',
              duration: 4000
            })
            this.init()
            this.isLoading = false
          });
        }
      })
    },
    computePercentage() {
      if (this.newLeaves.credit<5) {
        this.validationStatus = "There should be at least 5 leave credits left for this request to be valid."
      } else {
        let percentage = Math.round(((this.newLeaves.credit / this.currentBalance) * 100),0)
        if (percentage<50 || percentage>80) {
          this.validationStatus = "There should not exceed 50% or 80% of your current balance."
        } else {
          this.percentage = percentage;
          this.validationStatus = "";
          this.validateRequest();
        }
      }
    },
    getCredit() {
      if (this.newLeaves.request_type_id == 15 || this.newLeaves.request_type_id == 18 || this.newLeaves.request_type_id == 19) {
        this.isTime = true
      }
      else {
        this.isTime = false
      }
      // //console.log(this.newLeaves);
      if (this.newLeaves.request_type_id==14 || this.newLeaves.request_type_id==17) {
        this.setDate()
        let balance = this.currentBalance;
        let credit = (balance * (this.percentage/100));
        if (balance - credit>=5) {
          this.newLeaves.credit = credit;
          this.validateRequest()
        } else {
          this.newLeaves.credit = null;
          this.validationStatus = "There should be at least 5 leave credits left for this request to be valid.";
        }
      } else if (this.newLeaves.request_type_id == 18 && this.newLeaves.from_date && this.newLeaves.to_date & this.newLeaves.from_time && this.newLeaves.to_time) {
        this.isLoading = true;
        this.computeCredit(this.validateRequest);
      }else if (this.newLeaves.from_date && this.newLeaves.to_date && this.newLeaves.request_type_id) {
        this.isLoading = true
        this.validateRequest();
        if (this.canSave)
          this.getTime()
        else
          this.isLoading = false;
      }
      else {
        this.newLeaves.credit = null
      }
    },
    validateRequest() {
      let request = this.requestTypes[this.newLeaves.request_type_id - 1]
      let requestName = request.name
      let type = request.filing_period_type
      let days = request.filing_period_days
      let min_limit = request.request_limit_min
      let max_limit = request.request_limit_max

      let currentDate = new Date()
      let from = this.newLeaves.from_date
      let to = this.newLeaves.to_date

      let diffLeaveDays = Math.abs(from - to)
      let leaveDays = Math.ceil(diffLeaveDays / (1000 * 60 * 60 * 24))
      // alert(leaveDays)

      if (!min_limit) { // give value if null
        min_limit = 0
      }
      if (!max_limit) { // give value if null
        max_limit = 0
      }

      // if (this.newLeaves.request_type_id==18){
      //   if (thiis.newLeaves.from_date)
      // }

      if (moment(this.newLeaves.from_date).format("YYYY-MM-DD") == moment(this.newLeaves.to_date).format("YYYY-MM-DD")) {
        if (this.newLeaves.request_type_id == 2) {
          this.halfDay = false
        }
        else {
          this.halfDay = true
        }
      }
      else {
        this.halfDay = true
        this.isHalfDay = 0
      }
      if (from > to) { // check if from date is greater than to date
        this.isHalfDay = 0
        this.canSave = false
        this.validationStatus = '<b><i class="fas fa-exclamation-triangle"></i> ERROR:</b> End date is less than start date.'
      }
      // else if (this.newLeaves.request_type_id == 15) {
      //   this.canSave = true
      //   this.validationStatus = ''
      // }
      else if (leaveDays < min_limit - 1 || leaveDays > max_limit - 1 && (min_limit != 0 && max_limit != 0)) { // check if selected dates are not within the limit range of days set.
        if (min_limit == 1 && max_limit == 1) {
          this.validationStatus = '<b>ERROR:</b> Request can only be 1 day.'
        }
        else {
          this.validationStatus = `<b><i class="fas fa-exclamation-triangle"></i> ERROR:</b> Request should be more than or equal to ${min_limit} day/s and less than ${max_limit} day/s.`
        }
        this.isHalfDay = 0
        this.canSave = false
      }
      else if (type === "BEFORE") {
        let diffDays = Math.abs(Math.round((this.newLeaves.from_date - currentDate)/(1000*60*60*24)));
        // //console.log(diffDays,days)
        if (from < currentDate) { // check if from date has already passed the current date
          this.isHalfDay = 0
          this.canSave = false
          this.validationStatus = '<b><i class="fas fa-exclamation-triangle"></i> ERROR:</b> Selected Date has already passed.'
        }
        else if ((diffDays >= days && this.newLeaves.from_date >= currentDate) || days==0) {
          this.canSave = true
          this.validationStatus = ''
        }
        else {
          this.isHalfDay = 0
          this.canSave = false
          this.validationStatus = `<b><i class="fas fa-exclamation-triangle"></i> ERROR:</b> "${requestName}" can only be requested ${days} days ${type} the requested date.`
        }
      }
      else if (type === "AFTER") {
        let diffDays = Math.abs(Math.round((this.newLeaves.from_date - currentDate)/(1000*60*60*24)));
        // //console.log(moment(from).format('L'),moment(currentDate).format('L'));
        if (from > currentDate) { // check if date has not passed the current date
          this.isHalfDay = 0
          this.canSave = false
          this.validationStatus = '<b><i class="fas fa-exclamation-triangle"></i> ERROR:</b> Selected Date has not passed yet.'
        }
        else if ((diffDays <= days && this.newLeaves.from_date <= currentDate) || days==0) {
          this.canSave = true
          this.validationStatus = ''
        }
        else {
          this.isHalfDay = 0
          this.canSave = false
          this.validationStatus = `<b><i class="fas fa-exclamation-triangle"></i> ERROR:</b> "${requestName}" can only be requested  ${days} days ${type} the requested date.`
        }
      }
      else if (type == "WITHIN CUTOFF") {
        this.canSave = true;
        this.validationStatus = "";
      } else if (type === "EITHER") {
       let diffDays = Math.abs(Math.round((this.newLeaves.from_date - currentDate)/(1000*60*60*24)));
       //console.log(diffDays,days)
       if (diffDays>days && days!=0) {
          this.canSave = false
          this.validationStatus = `<b><i class="fas fa-exclamation-triangle"></i> ERROR:</b> "${requestName}" can only be requested ${type} ${days} days BEFORE OR AFTER the requested date.`
       } else {
          this.canSave = true
          this.validationStatus = ''
       }
      }
      else if (this.newLeaves.request_type_id == 14 || this.newLeaves.request_type_id==17 || this.newLeaves.request_type_id==18) {
        this.canSave = true
        this.validationStatus = ''
      }
    },
    getTime() {
      if (this.newLeaves.request_type_id == 18 || this.newLeaves.request_type_id == 15) {
        this.computeCredit(this.validateRequest);
      } else {
        this.$http.get(`${this.$host}/api/leave/schedule/${moment(this.newLeaves.from_date).format("YYYY-MM-DD")}/`,
          this.config
        ).then((response) => {
          let data = response.data
          this.newLeaves.from_time = data.time_in
          if (!this.newLeaves.from_time) {
            this.newLeaves.from_time = '00:00:00'
          }

          this.$http.get(`${this.$host}/api/leave/schedule/${moment(this.newLeaves.to_date).format("YYYY-MM-DD")}/`,
            this.config
          ).then((response) => {
            let data = response.data
            this.newLeaves.to_time = data.time_out
            this.halfDayValue = data.half_day_out
            if (!this.newLeaves.to_time) {
              this.newLeaves.to_time = '00:00:00'
              this.computeCredit(this.validateRequest)
            }
            else {
              this.computeCredit(this.validateRequest)
            }
          }).catch((error) => {
            //console.log(error)
          });

        }).catch((error) => {
          //console.log(error)
        });
      }
    },
    computeCredit(callback) {
       if (this.newLeaves.request_type_id == 14 || this.newLeaves.request_type_id == 17) {
        if (this.newLeaves.credit > (this.currentBalance * .8)) {
          isNegative = true
          this.canSave = false
          this.validationStatus = `<b><i class="fas fa-exclamation-triangle"></i> ERROR:</b> Credit should be less than or equal to 80% of balance.`
        }
        else if (this.newLeaves.credit <= (this.currentBalance * .8)) {
          isNegative = false
        }
      }
      else {
        //console.log(this.newLeaves);
        let config = {
          headers: {
            'Authorization': "Bearer " + this.$store.state.token,
            'Content-Type': 'application/json'
          },
          params: {
            from_date: moment(this.newLeaves.from_date).format("YYYY-MM-DD"),
            from_time: this.newLeaves.from_time,
            to_date: moment(this.newLeaves.to_date).format("YYYY-MM-DD"),
            to_time: this.newLeaves.to_time,
            request_type_id: this.newLeaves.request_type_id,
            is_manual: 0
          }
        }
        this.$http.get(`${this.$host}/api/leave/compute/`,
          config
        ).then((response) => {
          let data = response.data
          let isNegative = false
          // this.newLeaves.credit = data
          this.canSave = true
          this.validationStatus = ''

          if (this.isHalfDay == 1) {
            this.newLeaves.credit = data.credit / 2
            this.newLeaves.ot_json = data.ot_json
          }
          else {
            this.newLeaves.credit = data.credit
            this.newLeaves.ot_json = data.ot_json
          }

          if (this.newLeaves.credit == 0 && this.newLeaves.request_type_id != 15 && this.newLeaves.request_type_id != 16) {
            isNegative = true
            this.canSave = false
            this.validationStatus = `<b><i class="fas fa-exclamation-triangle"></i> ERROR:</b> Credit should be less than 0.`
          }
          else if (this.newLeaves.request_type_id == 1 || this.newLeaves.request_type_id == 2 || this.newLeaves.request_type_id == 16) {
            isNegative = false
          }
          else {
            let diff = this.currentBalance + this.newLeaves.credit
            if (diff >= 0) {
              isNegative = false
            }
            else if (this.newLeaves.request_type_id == 15 || this.newLeaves.request_type_id == 19) {
              this.newLeaves.credit = 0
              isNegative = false
            }
            else {
              isNegative = true
              this.isHalfDay = 0
              this.canSave = false
              this.validationStatus = '<b><i class="fas fa-exclamation-triangle"></i> ERROR:</b> Not enough balance.'
            }
          }

          this.isLoading = false
          if (callback && !isNegative) {
            callback()
          }
        }).catch((error) => {
          //console.log(error.response.data)
          if (error.response.data == 'duplicate request') {
            this.canSave = false
            this.validationStatus = `<b><i class="fas fa-exclamation-triangle"></i> ERROR:</b> "Duplicate request.`
          }
          else {
            this.canSave = false
            this.validationStatus = `<b><i class="fas fa-exclamation-triangle"></i> ERROR:</b> ${error.response.data}.`
          }

          this.isLoading = false
        });
      }
      //console.log(this.newLeaves)
    },
    print() {
      let url = `${this.$host}/api/report/${this.printType}`
      let win = window.open(url, '_blank')
      win.focus()
    },
    init() {
      this.requestType = null
      this.perMonth = null
      this.isLoading = true
      this.getRequestTypes()
      this.getProofTypes()
      // this.getRequests()
    },
    getEmployee(obj) {
      this.employee_id = 0
      if (obj.name_extension == null || obj.name_extension == 'null') {
        this.fullName = `${obj.first_name} ${obj.last_name}`
      }
      else {
        this.fullName = `${obj.first_name} ${obj.last_name} ${obj.name_extension}`
      }
      this.isSearch = false
      this.init()
    },
    closeSearch() {
      this.isSearch = false
    },
    getRequestTypes() {
      this.$http.get(`${this.$host}/api/parameter/request-type/`,
        this.config
      ).then((response) => {
        let data = response.data
        this.requestTypes = data
        // this.getRequests()
      }).catch((error) => {
        //console.log(error)
      });
    },
    getRequests() {
      this.isLoading = true
      this.$http.get(`${this.$host}/api/leave/search/`,
        this.config
      ).then((response) => {
        let data = response.data
        this.balance = data.balance
        this.ledger = data.ledger
        let config = {
          headers: {
            'Authorization': "Bearer " + this.$store.state.token,
            'Content-Type': 'application/json'
          },
          params: {
            requestType: null,
            perMonth: null,
            filterFrom: null,
            filterTo: null,
            status: 'request'
          }
        }
        // this.config.params =
        this.$http.get(`${this.$host}/api/approver/ledger/`,
          config
        ).then((response) => {
          let data = response.data.data
          this.forApproval = data
          this.url = response.data.url
          this.is_approver = response.data.is_approver
          this.isLoading = false
        }).catch((error) => {
          //console.log(error)
          this.isLoading = false
        });
      }).catch((error) => {
        //console.log(error)
        this.isLoading = false
      });
    },
    getRequestsByType(request) {
      this.perMonth = null
      this.filterFrom = null
      this.filterTo = null
      let config = {
        headers: {
          'Authorization': "Bearer " + this.$store.state.token,
          'Content-Type': 'application/json'
        },
        params: {
          request_type_id: request
        }
      }
      this.$http.get(`${this.$host}/api/leave/search/${this.employee_id}`,
        config
      ).then((response) => {
        let data = response.data
        this.balance = data.balance
        this.ledger = data.ledger
        this.isLoading = false
      }).catch((error) => {
        //console.log(error)
        this.isLoading = false
      });
    },
    dtrMonthFilter(month) {
      this.requestType = null
      this.filterFrom = null
      this.filterTo = null
      let config = {
        headers: {
          'Authorization': "Bearer " + this.$store.state.token,
          'Content-Type': 'application/json'
        },
        params: {
          month: month
        }
      }
      this.isLoading = true
      this.$http.get(`${this.$host}/api/leave/search/`,
        config
      ).then((response) => {
        let data = response.data
        this.balance = data.balance
        this.ledger = data.ledger
        this.isLoading = false
      }).catch((error) => {
        //console.log(error)
        this.isLoading = false
      });
    },
    dtrRangeFilter() {
      if (this.filterFrom && this.filterTo) {
        this.requestType = null
        this.perMonth = null
        let config = {
          headers: {
            'Authorization': "Bearer " + this.$store.state.token,
            'Content-Type': 'application/json'
          },
          params: {
            from: moment(this.filterFrom).format("YYYY-MM-DD"),
            to: moment(this.filterTo).format("YYYY-MM-DD"),
          }
        }
        this.isLoading = true
        this.$http.get(`${this.$host}/api/leave/search/${this.employee_id}`,
          config
        ).then((response) => {
          let data = response.data
          this.balance = data.balance
          this.ledger = data.ledger
          this.isLoading = false
        }).catch((error) => {
          //console.log(error)
          this.isLoading = false
        });
      }
    },
    setStatus(status) {
      if (status == 'requested' || status == 'request') {
        return '<span style="color: orange;"><i class="far fa-clock"></i></span>'
      }
      else if (status == 'approved') {
        return '<span style="color: green"><i class="fas fa-check"></i></span>'
      }
      else if (status == 'disapproved') {
        return '<span style="color: red"><i class="fas fa-times"></i></span>'
      }
      else if (status == 'cancelled') {
        return '<span style="color: red"><i class="fas fa-ban"></i></span>'
      }
      else if (status == 'automatic') {
        return '<span style="color: #0D47A1"><i class="fas fa-bullseye"></i></span>'
      }
      else if (status == 'manual') {
        return '<span style="color: #0D47A1"><i class="fas fa-wrench"></i></span>'
      }
      else if (status == 'system') {
        return '<span style="color: purple"><i class="fas fa-cog"></i></span>'
      }
      else {
        return status
      }
    },
    setStatusFull(status) {
      if (status == 'requested' || status == 'request') {
        return '<span style="color: orange;" title="request" aria-label="request">REQUEST <i class="far fa-clock"></i></span>'
      }
      else if (status == 'approved') {
        return '<span style="color: green" title="approved" aria-label="approved">APPROVED <i class="fas fa-check"></i></span>'
      }
      else if (status == 'disapproved') {
        return '<span style="color: red" title="disapproved" aria-label="disapproved">DISAPPROVED <i class="fas fa-times"></i></span>'
      }
      else if (status == 'cancelled') {
        return '<span style="color: red" title="cancelled" aria-label="cancelled">CANCELLED <i class="fas fa-ban"></i></span>'
      }
      else if (status == 'automatic') {
        return '<span style="color: #0D47A1" title="automatic" aria-label="automatic">AUTOMATIC <i class="fas fa-bullseye"></i></span>'
      }
      else if (status == 'manual') {
        return '<span style="color: #0D47A1" title="manual" aria-label="manual">MANUAL <i class="fas fa-wrench"></i></span>'
      }
      else if (status == 'system') {
        return '<span style="color: purple" title="system" aria-label="manual">SYSTEM GENERATED <i class="fas fa-cog"></i></span>'
      }
      else {
        return status
      }
    },
    save() {
      if (!this.newLeaves.id) {
        this.newLeaves.id = null
        this.newLeaves.employee_id = this.employee_id
      }

      if (this.newLeaves.reason == 'other') {
        this.requestForm.append('reason', this.otherReason)
      }
      else if (this.newLeaves.request_type_id==14 || this.newLeaves.request_type==17) {
        this.requestForm.append('reason', this.percentage + "% Leave Balance with letter request");
      } else {
        this.requestForm.append('reason', this.newLeaves.reason)
      }

      if (this.newLeaves.request_type_id == 15) {
        this.requestForm.append('venue', this.newLeaves.venue)
      }

      this.newLeaves.from_date = moment(this.newLeaves.from_date).format("YYYY-MM-DD")
      this.newLeaves.to_date = moment(this.newLeaves.to_date).format("YYYY-MM-DD")
      // //console.log(this.newLeaves);
      this.requestForm.append('from_time', this.newLeaves.from_time)
      this.requestForm.append('to_time', this.newLeaves.to_time)
      this.requestForm.append('from_date', this.newLeaves.from_date)
      this.requestForm.append('to_date', this.newLeaves.to_date)
      // this.requestForm.append('is_start', this.newLeaves.is_start)
      this.requestForm.append('proof_type', this.newLeaves.proof_type)
      this.requestForm.append('credit', this.newLeaves.credit)
      this.requestForm.append('ot_json', this.newLeaves.ot_json)
      this.requestForm.append('request_type_id', this.newLeaves.request_type_id)

      this.isLoading = true
      if (this.newLeaves.request_type_id == 15 && (!this.newLeaves.from_time || !this.newLeaves.to_time)) {
        this.$toast.open({
          message: 'Error. No time inputted.',
          type: 'is-danger',
          position: 'is-bottom-right',
          duration: 4000
        })
        this.isLoading = false
      }
      else {
        this.$http.post(`${this.$host}/api/leave/request/`,
          this.requestForm,
          this.config,
        ).then((response) => {
          this.$toast.open({
            message: 'Data successfully saved.',
            type: 'is-success',
            position: 'is-bottom-right',
            duration: 4000
          })
          this.isLoading = false
          this.cancel()
        }).catch((error) => {
          this.isLoading = false
          if (error.message == 'approver are missing!') {
            this.$toast.open({
              message: 'Error. No approvers assigned.',
              type: 'is-danger',
              position: 'is-bottom-right',
              duration: 4000
            })
          }
          else if (error.response.data) {
            this.$toast.open({
              message: error.response.data,
              type: 'is-danger',
              position: 'is-bottom-right',
              duration: 4000
            })
          }
          else {
            this.$toast.open({
              message: 'Error saving data.',
              type: 'is-danger',
              position: 'is-bottom-right',
              duration: 4000
            })
          }
          // this.isLoading = false
          this.newLeaves.from_date = new Date(this.newLeaves.from_date)
          this.newLeaves.to_date = new Date(this.newLeaves.to_date)
        });
      }
    },
    editRow(obj) {
      this.newLeaves = obj
      this.newLeaves.id = obj.id
      this.newLeaves.from_date = new Date(obj.from.substr(0, 10))
      this.newLeaves.from_time = obj.from.substr(11)
      this.newLeaves.to_date = new Date(obj.to.substr(0, 10))
      this.newLeaves.to_time = obj.from.substr(11)
      this.isComponentModalActive = true
    },
    cancel() {
      this.init()
      this.newLeaves = {
        from_time: '',
        to_time: '',
        // is_start: 0,
        proof_type: ''
      },
      this.requestForm = new FormData()
      this.validationStatus = ''
      this.isHalfDay = 0
      this.halfDay = true
      this.isComponentModalActive = false
      this.newLeaves.credit = null
      this.canSave = false
    },
    isGetCheckboxEmployees(){
      this.isSelect = true;
      if(this.start_date.month != null && this.end_date.month != null){
        this.isLoading = true;
      }

      let config = {
        headers: {'Authorization': "Bearer " + this.$store.state.token},
        onUploadProgress: progressEvent => {
          let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
        }
      }
      this.$http.post(`${this.$host}/api/leave/request/checkbox/`,
        {
          start_date: this.start_date,
          end_date: this.end_date,
        },
        config
      ).then((response) => {
        let data = response.data
        //console.log(data);
        this.checkedRows = []
        this.employees = data
        this.isLoading = false
      }).catch((error) => {
        this.isLoading = false
        //console.log(error)
      });
    },
    select() {

      //console.log(this.checkedRows);
      this.$dialog.confirm({
        title: 'Run Employee Deductions',
        message: "Are you sure you want to <b>run</b> this employees?",
        confirmText: 'Run',
        type: 'is-success',
        hasIcon: false,
        onConfirm: () => {
          this.isLoading = true
          this.percent = 1
          let config = {
            headers: {'Authorization': "Bearer " + this.$store.state.token},
            onUploadProgress: progressEvent => {
              let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
              this.percent = percentCompleted
            }
          }
          //console.log(this.settings);
            this.$http.post(`${this.$host}/api/leave/runEmployeeDeduction/`,
              {
                employee_id: this.checkedRows.map(x => x = {
                  id: x.id
                }),
                start_date: this.start_date,
                end_date: this.end_date
              },
              config,
            ).then((response) => {
              this.$dialog.alert({
                title: 'Success',
                message: "Run employee deductions complete",
                type: 'is-success',
                hasIcon: true,
                icon: 'check',
                iconPack: 'fa'
              })
              this.isLoading = false
              this.percent = 0
            }).catch((error) => {
              this.isLoading = false
              this.percent = 0
              this.$toast.open({
                message: 'Error running employee deductions',
                type: 'is-danger',
                position: 'is-bottom-right',
                duration: 4000
              })
            });
        }
      })
    },
    closeSelect() {
      this.checkedRows = [];
      this.isSelect = false
    },
  }
}
</script>

<style scoped>
  .greyed {
    color: grey !important;
  }
  .red {
    color: red;
  }
  .green {
    color: green;
  }
</style>
