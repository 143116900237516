<template>
  <img :src="url" style="width: 100%;">
</template>

<script>
export default {
  data() {
    return {
      url: '',
      id: ''
    }
  },
  mounted() {
    this.getEmployee()
  },
  methods: {
    getEmployee() {
      let config = {
        headers: {'Authorization': "Bearer " + this.$store.state.token}
      };

      this.$http.get(`${this.$host}/api/user`,
        config
      ).then((response) => {
        if (response.data.data.employee != null) {
          this.id = response.data.data.employee.id
          this.getImg()
        }
        else {
          this.url = '/img/photo.png'
        }
      }).catch((error) => {
        this.isLoading = false
        //console.log(error)
      });
    },
    getImg() {
      let config = {
        headers: {
          'Authorization': "Bearer " + this.$store.state.token
        }
      }
      this.$http.get(`${this.$host}/api/employee/image/${this.id}`,
        config
      ).then((response) => {
        if (response.data) {
          let data = response.data
          this.url = data
        }
      }).catch((error) => {
        this.url = '/img/photo.png'
        // this.$toast.open({
        //   message: 'Error retrieving image.',
        //   type: 'is-danger',
        //   position: 'is-bottom-right',
        //   duration: 4000
        // })
      });
    },
  }
}
</script>

<style scoped>

</style>


