<template>
  <b-modal :active.sync="isComponentModalActive" has-modal-card>
    <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false"></b-loading>
    <form action="">
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">Change Password</p>
        </header>
        <section class="modal-card-body">
          <b-field label="We highly suggest changing your password rather than using the default password.">
          </b-field>
          <b-field label="Old Password">
            <b-input
              type="password"
              password-reveal
              v-model="oldPassword"
              placeholder="Old password"
              required>
            </b-input>
          </b-field>

          <b-field label="New Password">
            <b-input
              type="password"
              password-reveal
              v-model="newPassword"
              placeholder="New password"
              required>
            </b-input>
          </b-field>

          <b-field label="Confirm Password">
            <b-input
              type="password"
              password-reveal
              v-model="confirmPassword"
              placeholder="Confirm new password"
              required>
            </b-input>
          </b-field>
        </section>
        <footer class="modal-card-foot">
          <button class="button is-primary" @click.prevent="changePassword">Change</button>
          <button class="button is-danger" type="button" @click.prevent="close()">Cancel</button>
        </footer>
      </div>
    </form>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      isComponentModalActive: true
    }
  },
  methods: {
    changePassword() {
      this.isLoading = true
      if (this.newPassword != this.confirmPassword) {
        this.$toast.open({
          duration: 4000,
          message: `New password did not match the password confirmation.`,
          position: 'is-bottom-right',
          type: 'is-danger'
        })
        this.isLoading = false
      }
      else if (this.newPassword == this.confirmPassword) {
        let config = {
          headers: {'Authorization': "Bearer " + this.$store.state.token}
        };

        this.$http.post(`${this.$host}/api/user/change_password`,
          {
            "old-password": this.oldPassword,
            "new-password": this.newPassword
          },
          config,
        ).then((response) => {
          if (response.data.message == "Password not match") {
            this.isLoading = false
            this.$toast.open({
              duration: 4000,
              message: `Incorrect old password.`,
              position: 'is-bottom-right',
              type: 'is-danger'
            })
          }
          else if (response.data.message == "Password updated") {
            this.isLoading = false
            this.$toast.open({
              duration: 4000,
              message: `Password successfully changed.`,
              position: 'is-bottom-right',
              type: 'is-success'
            })
            this.close()
          }
          //console.log(data)
        }).catch((error) => {
          this.isLoading = false
          //console.log(error)
        });
      }
      else {
        this.isLoading = false
          this.$toast.open({
            duration: 4000,
            message: `Unknown Error.`,
            position: 'is-bottom-right',
            type: 'is-danger'
          })
      }
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>
